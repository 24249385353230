import * as React from 'react';

// Import MUI components
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

// Import MUI icons
import CloseIcon from '@mui/icons-material/Close';

// Import components
import CandidateNavbar from './CandidateNavbar';
import CandidateAssessment from './CandidateAssessment';
import ProctoringInstructions from './proctor mode components/ProctoringInstructions';
import SystemChecks from './proctor mode components/SystemChecks';
import Declaration from './proctor mode components/Declaration';
import EnvironmentSetup from './proctor mode components/EnvironmentSetup';
import CameraTest from './proctor mode components/CameraTest';

// Import styles
import './CandidateAssessment.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function CandidateProctorMode({testName, duration, cameraEnabled}) {
    const [backToStartAssessment, setBackToStartAssessment] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [isDeclarationChecked, setIsDeclarationChecked] = React.useState(false);

    const handleChange = (event, newValue) => {
        // Prevent tab change if declaration is not checked and trying to go to Environment Setup
        if (newValue === 3 && !isDeclarationChecked) {
            return; // Prevent changing tab
        }
        setValue(newValue);
    };

    const handleClick = () => {
        setBackToStartAssessment(true); // Go back to start assessment page
    };

    if (backToStartAssessment) {
        return <CandidateAssessment />;
    }

    return (
        <Box sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <CandidateNavbar testName={testName}/>
            <div className='below-navbar'>
                <p>You’re about to start this assessment</p>
                <div className='below-navbar-right'>
                    <span>PROCTOR MODE</span>
                    <CloseIcon onClick={handleClick}
                        style={{ fontSize: 'small', margin: '5px', cursor: 'pointer' }}
                    />
                </div>
            </div>
            <hr />
            <Box
                sx={{ flexGrow: 1, display: 'flex' }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab label="Proctoring Instructions" {...a11yProps(0)} />
                    <Tab label="System Checks" {...a11yProps(1)} />
                    <Tab label="Declaration" {...a11yProps(2)} />
                    {/* <Tab label="Camera Testing" {...a11yProps(3)} /> */}
                    <Tab label="Environment Setup" {...a11yProps(3)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <ProctoringInstructions setTabValue={setValue} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SystemChecks setTabValue={setValue} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Declaration
                        setTabValue={setValue}
                        isDeclarationChecked={isDeclarationChecked}
                        setIsDeclarationChecked={setIsDeclarationChecked}
                    />
                </TabPanel>
                {/* <TabPanel value={value} index={3}>
                    <CameraTest/>
                </TabPanel> */}
                <TabPanel value={value} index={3}>
                    <EnvironmentSetup setTabValue={setValue}  testName={testName} duration={duration} cameraEnabled={cameraEnabled}/>
                </TabPanel>
            </Box>
        </Box>
    );
}
