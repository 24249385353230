// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test-details {
    display: flex;
    gap: 13px;
    margin: 10px 0px 20px 0px;
}


.css-14wpvli-MuiTypography-root {
    margin: 0 !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: normal !important;
    font-size: 1.5rem !important;
    letter-spacing: 0.00735em !important;
    text-transform: none !important;
}

.css-3udzj7-MuiTypography-root {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 2.125rem;
    line-height: 1.235;
    letter-spacing: 0.00735em;
    text-transform: none !important;
}

.css-3udzj7-MuiTypography-root, .css-102xtj6-MuiTypography-root{
    font-weight: normal !important;
}

.css-102xtj6-MuiTypography-root{
    text-transform: none !important;
}

`, "",{"version":3,"sources":["webpack://./src/components/skill-assessment/components/TestManagement/TestManagement.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,yBAAyB;AAC7B;;;AAGA;IACI,oBAAoB;IACpB,kEAAkE;IAClE,8BAA8B;IAC9B,4BAA4B;IAC5B,oCAAoC;IACpC,+BAA+B;AACnC;;AAEA;IACI,SAAS;IACT,uDAAuD;IACvD,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,+BAA+B;AACnC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,+BAA+B;AACnC","sourcesContent":[".test-details {\n    display: flex;\n    gap: 13px;\n    margin: 10px 0px 20px 0px;\n}\n\n\n.css-14wpvli-MuiTypography-root {\n    margin: 0 !important;\n    font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif !important;\n    font-weight: normal !important;\n    font-size: 1.5rem !important;\n    letter-spacing: 0.00735em !important;\n    text-transform: none !important;\n}\n\n.css-3udzj7-MuiTypography-root {\n    margin: 0;\n    font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n    font-weight: 400;\n    font-size: 2.125rem;\n    line-height: 1.235;\n    letter-spacing: 0.00735em;\n    text-transform: none !important;\n}\n\n.css-3udzj7-MuiTypography-root, .css-102xtj6-MuiTypography-root{\n    font-weight: normal !important;\n}\n\n.css-102xtj6-MuiTypography-root{\n    text-transform: none !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
