import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Paper,
    DialogActions,
    Avatar,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const CandidateViewProfile = ({
    selectedCandidate,
    setSelectedCandidate,
    totalQuestions,
    totalAttempted,
    calculateCorrectAnswers,
    tests,
    testId,
    handleCandidateDetailDialogClose,
    fetchAddedCandidates,
}) => {
    const [editMode, setEditMode] = useState(null); // Track which question is in edit mode
    const [selectedAnswers, setSelectedAnswers] = useState({}); // Track modified answers
    const [videoUrl, setVideoUrl] = useState(""); // Store fetched video URL
    const [isLoading, setIsLoading] = useState(!!videoUrl);

    useEffect(() => {
        if (videoUrl) {
            const video = document.createElement("video");
            video.src = videoUrl;
            video.onloadeddata = () => setIsLoading(false);
        }
    }, [videoUrl]);

    useEffect(() => {
        const fetchVideo = async () => {
            try {
                const response = await axios.get(`/api/video/get-video-from-drive/${selectedCandidate.recorded_video_id}`, {
                    responseType: "blob", // Ensure the response is a blob for video streaming
                });

                console.log("response from video fetch", response)

                // Create URL from blob and set state
                const url = URL.createObjectURL(response.data);
                console.log("url", url);

                setVideoUrl(url);
            } catch (error) {
                console.error("Error fetching video:", error);
            }
        };

        fetchVideo(); // Call the async function inside useEffect
    }, []); // Empty dependency array to run only once



    if (!selectedCandidate) return null;

    const handleEditClick = (questionId, currentAnswer) => {
        setEditMode(questionId);
        setSelectedAnswers((prevState) => ({
            ...prevState,
            [questionId]: currentAnswer,
        }));
    };

    const handleSaveClick = async (questionId) => {
        try {
            const updatedAnswer = selectedAnswers[questionId];

            // Find the correct test and section
            const correctTest = tests.find((test) => test._id === testId);

            // Find the correct section that contains the question
            const correctSection = correctTest?.sections.find((sec) =>
                sec.problems.some((problem) => problem._id === questionId) // Match by questionId
            );

            const correctQuestion = correctSection?.problems.find((prob) => String(prob._id) === String(questionId));

            // Check correctness of the new answer
            const isCorrect = updatedAnswer === correctQuestion?.correctAnswer;

            // Assign points dynamically
            const updatedPoints = isCorrect ? correctQuestion?.points : 0;

            // Immediately update selectedAnswers for UI reflection
            setSelectedAnswers((prevState) => ({
                ...prevState,
                [questionId]: updatedAnswer,
            }));

            // Create a deep copy of selectedCandidate and update it immutably
            setSelectedCandidate((prevCandidate) => {
                const updatedCandidate = { ...prevCandidate };

                updatedCandidate.testResult.sectionResults = prevCandidate.testResult.sectionResults.map((section) => {
                    // Ensure a deep copy of each section
                    const updatedQuestions = section.questions.map((question) =>
                        question.questionId === questionId
                            ? {
                                ...question,
                                candidateAnswer: updatedAnswer,
                                isCorrect,
                                points: updatedPoints,
                            }
                            : question
                    );

                    return {
                        ...section,
                        questions: updatedQuestions, // New reference for questions
                        sectionScore: updatedQuestions.reduce(
                            (acc, q) => acc + (q.isCorrect ? q.points : 0),
                            0
                        ), // Recalculate section score dynamically
                    };
                });

                return { ...updatedCandidate }; // Force React to detect change
            });

            // Make API request
            await axios.post(
                `/api/admin/skill-assessment/test-management/edit-candidate-score/${selectedCandidate._id}`,
                {
                    questionId,
                    updatedAnswer,
                    inviteToken: selectedCandidate.inviteToken,
                }
            );

            setEditMode(null);
            fetchAddedCandidates();
        } catch (error) {
            console.error("Error saving answer:", error);
        }
    };


    return (
        <Box p={4}>
            {/* Candidate Info */}
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <Avatar>{selectedCandidate.fullName?.charAt(0)}</Avatar>
                </Grid>
                <Grid item>
                    <Typography variant="h5">{selectedCandidate.fullName}</Typography>
                </Grid>
            </Grid>

            {/* Assessment Summary */}
            <Box mt={4}>
                <Typography variant="h6">Assessment Summary</Typography>
                <Paper variant="outlined" sx={{ padding: "10px", marginTop: "10px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography>
                                Total Questions: {totalQuestions(selectedCandidate)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>Attempted: {totalAttempted}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Correct Answers: {calculateCorrectAnswers(selectedCandidate)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Score: {selectedCandidate?.testResult?.securedScore || 0}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>
                                Submit Message:&nbsp;
                                {selectedCandidate.submitReason === "clickedSubmitButton"
                                    ? "clicked submit button"
                                    : selectedCandidate.submitReason === "timeIsUp"
                                        ? "time up"
                                        : selectedCandidate.submitReason === "Your test is submitted due to exceeding the limit of tab switches."
                                            ? "auto submitted"
                                            : "-"}
                            </Typography>
                            <Grid item xs={6}>
                                <Typography>
                                    tabs switched: {selectedCandidate?.tabActivity?.[0]?.switchCount || 0}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            {/* Display Video if Available */}
            <Box mt={4}>
                <Typography variant="h6">
                    Webcam Recording
                </Typography>
                {videoUrl ? (
                    <Box display="flex" justifyContent="center" marginTop="20px">
                        <video width="450" height="300" controls>
                            <source src={videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                ) : (
                    <Typography variant="body1" color="textSecondary">
                        No webcam proctoring option given
                    </Typography>
                )}
            </Box>

            {/* Sections */}
            <Box mt={4}>
                <Typography variant="h6">Solutions</Typography>
                <br />
                {selectedCandidate.testResult?.sectionResults.map((section, sIndex) => {
                    const correctTest = tests.find((test) => test._id === testId);
                    const correctSection = correctTest?.sections.find(
                        (sec) => sec.sectionName === section.sectionName
                    );

                    const totalSectionPoints =
                        correctSection?.problems.reduce(
                            (acc, problem) => acc + problem.points,
                            0
                        ) || 0;

                    return (
                        <Accordion key={sIndex}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6">
                                    {section.sectionName} -
                                    {section.questions.reduce((acc, q) => acc + (q.isCorrect ? q.points : 0), 0)}
                                    /{totalSectionPoints}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    {section.questions.map((question, qIndex) => {
                                        const correctQuestion = correctSection?.problems.find(
                                            (prob) =>
                                                String(prob._id) === String(question.questionId)
                                        );

                                        const isAttempted = question.candidateAnswer !== "unattempted";
                                        const isAnswerCorrect = isAttempted && question.isCorrect;
                                        const isAnswerIncorrect = isAttempted && !question.isCorrect;

                                        const answerInEditMode = selectedAnswers[question.questionId];

                                        return (
                                            <Accordion key={qIndex}>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon
                                                            sx={{
                                                                transition: "transform 0.3s",
                                                                "&.Mui-expanded": { transform: "rotate(180deg)" },
                                                            }}
                                                        />
                                                    }
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        width="100%"
                                                    >
                                                        <Typography>
                                                            {correctQuestion?.name || `Question ${qIndex + 1}`}
                                                        </Typography>
                                                        <Box display="flex" alignItems="center" gap={1}>
                                                            {isAnswerCorrect && (
                                                                <CheckCircleOutlineIcon sx={{ color: "green" }} />
                                                            )}
                                                            {isAnswerIncorrect && (
                                                                <HighlightOffIcon sx={{ color: "red" }} />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Paper
                                                        variant="outlined"
                                                        sx={{
                                                            padding: "10px",
                                                            marginBottom: "10px",
                                                            position: "relative",
                                                        }}
                                                    >
                                                        <Typography>{correctQuestion?.text}</Typography>
                                                        <RadioGroup
                                                            value={
                                                                answerInEditMode !== undefined
                                                                    ? answerInEditMode
                                                                    : question.candidateAnswer
                                                            }
                                                            onChange={(e) => {
                                                                const newAnswer = e.target.value;
                                                                setSelectedAnswers((prevState) => {
                                                                    const updatedState = { ...prevState, [question.questionId]: newAnswer };
                                                                    return updatedState;
                                                                });

                                                                // Also update UI immediately
                                                                setSelectedCandidate((prevCandidate) => {
                                                                    const updatedCandidate = { ...prevCandidate };
                                                                    updatedCandidate.testResult.sectionResults = updatedCandidate.testResult.sectionResults.map((section) => ({
                                                                        ...section,
                                                                        questions: section.questions.map((q) =>
                                                                            q.questionId === question.questionId
                                                                                ? { ...q, candidateAnswer: newAnswer, isCorrect: newAnswer === correctQuestion?.correctAnswer }
                                                                                : q
                                                                        ),
                                                                    }));
                                                                    return updatedCandidate;
                                                                });
                                                            }}

                                                            disabled={editMode !== question.questionId} // Disable if not in edit mode
                                                        >
                                                            {correctQuestion?.options.map((option, index) => (
                                                                <Box
                                                                    key={index}
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Radio disabled={editMode !== question.questionId} // Disable radio button
                                                                                sx={{
                                                                                    "&.Mui-disabled": {
                                                                                        color: "grey", // Prevent color change when disabled
                                                                                    },
                                                                                    "&.Mui-checked": {
                                                                                        color: "#f06161", // Keep the color when checked
                                                                                    }
                                                                                }}
                                                                            />
                                                                        }
                                                                        label={option}
                                                                        value={option}
                                                                        sx={{
                                                                            marginBottom: "10px",
                                                                            "& .Mui-checked": {
                                                                                color: "#f06161",
                                                                            },
                                                                        }}
                                                                    />
                                                                    {option === correctQuestion?.correctAnswer && (
                                                                        <Typography
                                                                            variant="caption"
                                                                            sx={{
                                                                                color: "green",
                                                                                padding: "2px 5px",
                                                                                borderRadius: "5px",
                                                                                fontWeight: "bold",
                                                                            }}
                                                                        >
                                                                            Correct Answer
                                                                        </Typography>
                                                                    )}
                                                                </Box>
                                                            ))}
                                                        </RadioGroup>

                                                        <Typography variant="body2">
                                                            points:{question.points}
                                                        </Typography>

                                                        {/* Edit/Save Button */}
                                                        <Box mt={2} display="flex" justifyContent="flex-end">
                                                            {editMode === question.questionId ? (
                                                                <Button
                                                                    variant="contained"
                                                                    sx={{
                                                                        backgroundColor: "#f06161",
                                                                        color: "#fff",
                                                                    }}
                                                                    onClick={() => handleSaveClick(question.questionId)}
                                                                >
                                                                    Save
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{
                                                                        color: "#f06161",
                                                                        borderColor: "#f06161",
                                                                    }}
                                                                    onClick={() =>
                                                                        handleEditClick(question.questionId, question.candidateAnswer)
                                                                    }
                                                                >
                                                                    Edit
                                                                </Button>
                                                            )}
                                                        </Box>
                                                    </Paper>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>

            {/* Dialog Actions */}
            <DialogActions>
                <Button
                    variant="outlined"
                    component="span"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#f06161",
                        borderColor: "#f06161",
                        marginTop: "10px",
                        "&:hover": {
                            backgroundColor: "rgba(240, 97, 97, 0.1)",
                            borderColor: "#d05353",
                        },
                    }}
                    onClick={handleCandidateDetailDialogClose}
                >
                    Close
                </Button>
            </DialogActions>


        </Box >
    );
};

export default CandidateViewProfile;
