// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    box-shadow: none !important;
    border: 1px solid rgb(99, 99, 99);
}

.css-1yty4ry-MuiTypography-root{
    text-transform: capitalize !important;
}`, "",{"version":3,"sources":["webpack://./src/components/skill-assessment/components/TestManagement/Candidates.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,iCAAiC;AACrC;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{\n    box-shadow: none !important;\n    border: 1px solid rgb(99, 99, 99);\n}\n\n.css-1yty4ry-MuiTypography-root{\n    text-transform: capitalize !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
