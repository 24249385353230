import React, { useState, useRef } from "react";
import axios from "axios";
import {
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  Checkbox,
  FormGroup,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  List,
  ListItem,
} from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./AdvancedSettings.css"; // Add your CSS file here
import UserSnackbar from "../../../snackBars/UserSnackbar";

const AdvancedSettings = ({
  testId,
  data,
  setAdvancedSettingsData,
  setStartTime,
  setEndTime,
  setStartDate,
  setEndDate,
  onSaveComplete,
}) => {
  console.log("data", data);

  const [proctoring, setProctoring] = useState(
    data.proctoring || "noProctoring"
  );
  const [basicProctoringOptions, setBasicProctoringOptions] = useState({
    fullScreen: data?.basicProctoringOptions?.fullScreen || false,
    trackTab: data?.basicProctoringOptions?.trackTab || false,
    restrictCopyPaste: data?.basicProctoringOptions?.restrictCopyPaste || false,
    preventMultiWindow:
      data?.basicProctoringOptions?.preventMultiWindow || false,
    // captureFingerprint: data.basicProctoringOptions.captureFingerprint || false,
  });
  // const [basicProctoringVerdict, setBasicProctoringVerdict] = useState("severe");
  const [browserTolerance, setBrowserTolerance] = useState(
    data.browserTolerance || ""
  );
  const [customTolerance, setCustomTolerance] = useState(
    data.customTolerance || ""
  ); // for custom input value
  const [showWarnings, setShowWarnings] = useState(data.showWarnings || false);
  const [startDate1, setStartDate1] = useState(data.startDate || "");
  const [startTime1, setStartTime1] = useState(data.startTime || "");
  const [endDate1, setEndDate1] = useState(data.endDate || "");
  const [endTime1, setEndTime1] = useState(data.endTime || "");
  const [strictEndTime, setStrictEndTime] = useState(
    data.strictEndTime || false
  );
  const [expiration, setExpiration] = useState(data.expiration || false);
  const [daysAfterInvite, setDaysAfterInvite] = useState(
    data.daysAfterInvite || ""
  );
  const [reportEmails, setReportEmails] = useState(
    data.reportEmails || "screener@infomaticscorp.com"
  );
  const [sendEmailReport, setSendEmailReport] = useState(
    data.sendEmailReport || false
  );
  const [customMessage, setCustomMessage] = useState(data.customMessage || "");
  const [cameraEnabled, setCameraEnabled] = useState(
    data.cameraEnabled || false
  );
  const [sendReminder, setSendReminder] = useState(data.sendReminder || false);
  const [supportEmail, setSupportEmail] = useState(data.supportEmail || "");
  const [supportPhone, setSupportPhone] = useState(data.supportPhone || "");
  const [redirectURL, setRedirectURL] = useState(
    data.redirectURL || "screener.infomaticscorp.com"
  );
  const [shuffleOptions, setShuffleOptions] = useState(
    data.shuffleOptions || false
  );
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  //refs
  const startDate1Ref = useRef(null);
  const startTime1Ref = useRef(null);
  const endDate1Ref = useRef(null);
  const endTime1Ref = useRef(null);
  const reportEmailsRef = useRef(null);
  const supportEmailRef = useRef(null);
  const supportPhoneRef = useRef(null);
  const redirectURLRef = useRef(null);
  // const browserToleranceRef = useRef(null);

  const [errors, setErrors] = useState({
    startDate1: "",
    startTime1: "",
    endDate1: "",
    endTime1: "",
    reportEmails: "",
    supportEmail: "",
    supportPhone: "",
    redirectURL: "",
  });

  // Function to scroll to the target element
  const scrollToElement = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth", // You can change this to 'auto' for instant scrolling
        block: "center", // You can change this to 'end' or 'center' to adjust where the element appears on the screen
      });
    }
  };

  // Define `today` inside the component
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

  const handleBasicProctoringOptionChange = (event) => {
    setBasicProctoringOptions({
      ...basicProctoringOptions,
      [event.target.name]: event.target.checked,
    });
  };

  // const handleSave = async () => {
  //   // Validate fields before saving
  //   // if (!startDate1 || !startTime1 || !endDate1 || !endTime1 || !reportEmails) {
  //   //   return;
  //   // }

  //   const allFieldsFilled = checkAllFieldsFilled();
  //   if (!allFieldsFilled) return;

  //   const advancedSettingsData = {
  //     proctoring,
  //     basicProctoringOptions,
  //     browserTolerance,
  //     customTolerance,
  //     showWarnings,
  //     startDate: startDate1,
  //     startTime: startTime1,
  //     endDate: endDate1,
  //     endTime: endTime1,
  //     strictEndTime,
  //     expiration,
  //     daysAfterInvite: parseInt(daysAfterInvite, 10),
  //     reportEmails,
  //     sendEmailReport,
  //     customMessage,
  //     sendReminder,
  //     supportEmail,
  //     supportPhone,
  //     redirectURL,
  //     shuffleOptions,
  //   };

  //   try {
  //     const response = await axios.post(
  //       `/api/admin/skill-assessment/test-management/${testId}/save-advanced-settings`,
  //       { advancedSettings: advancedSettingsData }
  //     );

  //     setStartTime(startTime1);
  //     setEndTime(endTime1);
  //     setStartDate(startDate1);
  //     setEndDate(endDate1);
  //     setOpenSnackbar(true);
  //     setMessage("Advanced Settings saved successfully.");
  //     // onSaveComplete(true);
  //     console.log("Settings saved successfully:", response.data);
  //   } catch (error) {
  //     setOpenSnackbar(true);
  //     setMessage("Error saving Advanced Settings");
  //     // onSaveComplete(false);

  //     console.error("Error saving settings:", error);
  //   }
  // };

  const handleSave = async () => {
    const allFieldsFilled = checkAllFieldsFilled();
    if (!allFieldsFilled) return;

    // Convert date and time to a comparable format
    // const selectedStartDateTime = new Date(`${startDate1}T${startTime1}`);
    // const selectedEndDateTime = new Date(`${endDate1}T${endTime1}`);
    // const currentDateTime = new Date();

    // // Validate start and end time
    // if (selectedStartDateTime >= selectedEndDateTime) {
    //   setOpenSnackbar(true);
    //   setMessage("End date/time must be after start date/time.");
    //   return;
    // }

    // // Ensure the test can only be taken within the given period
    // if (selectedStartDateTime < currentDateTime) {
    //   setOpenSnackbar(true);
    //   setMessage("Start date/time must be in the future.");
    //   return;
    // }

    // Convert date to a comparable format
    const selectedStartDate = new Date(startDate1);
    const selectedEndDate = new Date(endDate1);
    const currentDate = new Date();

    // Reset time to midnight for fair date-only comparison
    selectedStartDate.setHours(0, 0, 0, 0);
    selectedEndDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    // Ensure the start date is not in the past
    if (selectedStartDate < currentDate) {
      setOpenSnackbar(true);
      setMessage("Start date must be today or in the future.");
      return;
    }

    // Ensure the end date is not before the start date
    if (selectedEndDate < selectedStartDate) {
      setOpenSnackbar(true);
      setMessage("End date cannot be before start date.");
      return;
    }

    const advancedSettingsData = {
      proctoring,
      basicProctoringOptions,
      cameraEnabled,
      browserTolerance,
      customTolerance,
      showWarnings,
      startDate: startDate1,
      startTime: startTime1,
      endDate: endDate1,
      endTime: endTime1,
      strictEndTime,
      expiration,
      daysAfterInvite: parseInt(daysAfterInvite, 10),
      reportEmails,
      sendEmailReport,
      customMessage,
      sendReminder,
      supportEmail,
      supportPhone,
      redirectURL,
      shuffleOptions,
    };

    try {
      const response = await axios.post(
        `/api/admin/skill-assessment/test-management/${testId}/save-advanced-settings`,
        { advancedSettings: advancedSettingsData }
      );

      setStartTime(startTime1);
      setEndTime(endTime1);
      setStartDate(startDate1);
      setEndDate(endDate1);
      setOpenSnackbar(true);
      setCameraEnabled(cameraEnabled);
      setMessage("Advanced Settings saved successfully.");
      console.log("Settings saved successfully:", response.data);
    } catch (error) {
      setOpenSnackbar(true);
      setMessage("Error saving Advanced Settings");
      console.error("Error saving settings:", error);
    }
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setBrowserTolerance(selectedValue);
    if (selectedValue !== "custom") {
      setCustomTolerance(""); // Reset custom tolerance when not selecting "Custom"
    }
  };

  const handleCustomChange = (e) => {
    const customValue = e.target.value;
    // Only update if the value is a valid number or leave it empty
    if (customValue === "" || !isNaN(customValue)) {
      setCustomTolerance(customValue);
    }
  };

  //check whether all the fields are filled in schedule interview dialog
  const checkAllFieldsFilled = () => {
    // if (!browserTolerance) {
    //   setErrors({ browserTolerance: "Please enter browser tolerance"});
    //   scrollToElement(browserToleranceRef);
    //   return false;
    // }
    if (!startDate1) {
      setErrors({ startDate1: "Please enter start date" });
      scrollToElement(startDate1Ref);
      return false;
    }
    if (!endDate1) {
      setErrors({ endDate1: "Please enter end date" });
      scrollToElement(endDate1Ref);
      return false;
    }
    if (!startTime1) {
      setErrors({ startTime1: "Please enter start time" });
      scrollToElement(startTime1Ref);
      return false;
    }
    if (!endTime1) {
      setErrors({ endTime1: "Please enter end time" });
      scrollToElement(endTime1Ref);
      return false;
    }
    if (!reportEmails) {
      setErrors({ reportEmails: "Please enter report email" });
      scrollToElement(reportEmailsRef);
      return false;
    }
    if (!supportEmail) {
      setErrors({ supportEmail: "Please enter support email" });
      scrollToElement(supportEmailRef);
      return false;
    }
    if (!supportPhone) {
      setErrors({ supportPhone: "Please enter support phone" });
      scrollToElement(supportPhoneRef);
      return false;
    }
    if (!redirectURL) {
      setErrors({ redirectURL: "Please enter redirect url" });
      scrollToElement(redirectURLRef);
      return false;
    }
    return true;
  };

  return (
    <div className="advancedsettings-container">
      <Box p={3}>
        <Grid container spacing={3}>
          {/* Proctoring Settings */}
          <Grid item xs={12}>
            <Typography variant="h6">Proctoring Settings</Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <Radio
                    checked={proctoring === "noProctoring"}
                    onChange={() => {
                      setProctoring("noProctoring");
                      setCameraEnabled("false");
                    }}
                    sx={{ "&.Mui-checked": { color: "#F06161" } }}
                  />
                }
                label="No Proctoring"
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={proctoring === "basicProctoring"}
                    onChange={() => setProctoring("basicProctoring")}
                    sx={{ "&.Mui-checked": { color: "#F06161" } }}
                  />
                }
                label="Basic Proctoring"
              />
              {proctoring === "basicProctoring" && (
                <Box mt={2}>
                  <FormGroup>
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          checked={basicProctoringOptions.fullScreen}
                          onChange={handleBasicProctoringOptionChange}
                          name="fullScreen"
                          sx={{ "&.Mui-checked": { color: "#F06161" } }}
                        />
                      }
                      label="Enforce full-screen during test"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={basicProctoringOptions.trackTab}
                          onChange={handleBasicProctoringOptionChange}
                          name="trackTab"
                          sx={{ "&.Mui-checked": { color: "#F06161" } }}
                        />
                      }
                      label="Track tab activities"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={basicProctoringOptions.restrictCopyPaste}
                          onChange={handleBasicProctoringOptionChange}
                          name="restrictCopyPaste"
                          sx={{ "&.Mui-checked": { color: "#F06161" } }}
                        />
                      }
                      label="Restrict Copy-Paste"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={basicProctoringOptions.preventMultiWindow}
                          onChange={handleBasicProctoringOptionChange}
                          name="preventMultiWindow"
                          sx={{ "&.Mui-checked": { color: "#F06161" } }}
                        />
                      }
                      label="Prevent Multi-Window test sessions"
                    /> */}

                    {/* <Typography variant="body1" sx={{ margin: "10px 0" }}>
                      Basic proctoring ensures that candidates remain in fullscreen mode, monitors tab activity, and prevents multiple-window test sessions.
                    </Typography> */}

                    <Typography variant="body1" sx={{ margin: "10px 0" }}>
                      Basic proctoring includes the following features:
                    </Typography>
                    <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
                      <ListItem sx={{ display: "list-item", padding: 0 }}>
                        Ensure candidates remain in fullscreen mode.
                      </ListItem>
                      <ListItem sx={{ display: "list-item", padding: 0 }}>
                        Monitor tab activity.
                      </ListItem>
                      <ListItem sx={{ display: "list-item", padding: 0 }}>
                        Restrict copy-paste functionality.
                      </ListItem>
                      <ListItem sx={{ display: "list-item", padding: 0 }}>
                        Prevent multiple-window test sessions.
                      </ListItem>
                    </List>

                    {/* Enable Camera Toggle */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cameraEnabled}
                          onChange={() => setCameraEnabled(!cameraEnabled)}
                          sx={{ "&.Mui-checked": { color: "#F06161" } }}
                        />
                      }
                      label="Enable Camera"
                    />
                    {/* <FormControlLabel
                      control={<Checkbox checked={basicProctoringOptions.captureFingerprint} onChange={handleBasicProctoringOptionChange} name="captureFingerprint" sx={{  '&.Mui-checked': { color: "#F06161" } }} />}
                      label="Capture browser fingerprint"
                    /> */}
                  </FormGroup>

                  <Box mt={2}>
                    <Typography variant="body1">Browser tolerance</Typography>
                    <Select
                      fullWidth
                      value={browserTolerance}
                      // ref={browserToleranceRef}
                      // className={`${errors.browserTolerance && "input-field-error"}`}
                      onChange={handleSelectChange}
                      sx={{
                        "& .MuiSelect-icon": { color: "#F06161" },
                        "& .MuiInputLabel-root": { color: "#F06161" },
                      }}
                    >
                      <MenuItem value={0}>Not applicable</MenuItem>
                      <MenuItem value={-1}>Auto submit (No warning)</MenuItem>
                      <MenuItem value={1}>
                        1 warning (Auto submit after 1 warning)
                      </MenuItem>
                      <MenuItem value={2}>
                        2 warnings (Auto submit after 2 warnings)
                      </MenuItem>
                      <MenuItem value={4}>
                        4 warnings (Auto submit after 4 warnings)
                      </MenuItem>
                      <MenuItem value={10}>
                        10 warnings (Auto submit after 10 warnings)
                      </MenuItem>
                      <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                    {/* <span className="text-danger">{errors.browserTolerance}</span> */}

                    {browserTolerance === "custom" && (
                      <TextField
                        label="Enter custom warning count"
                        type="number"
                        fullWidth
                        value={customTolerance} // Controlled by customTolerance state
                        onChange={handleCustomChange}
                        sx={{ marginTop: 2 }}
                      />
                    )}

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showWarnings}
                          onChange={() => setShowWarnings(!showWarnings)}
                          sx={{ "&.Mui-checked": { color: "#F06161" } }}
                        />
                      }
                      label="Show warnings to candidates"
                    />
                  </Box>
                </Box>
              )}
            </FormGroup>
          </Grid>

          {/* Time Settings */}
          <Grid item xs={12}>
            <Typography variant="h6">Time Settings</Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Starting Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={startDate1}
                  ref={startDate1Ref}
                  className={`${errors.startDate1 && "input-field-error"}`}
                  onChange={(e) => {
                    setStartDate1(e.target.value);
                    setErrors({ startDate1: "" });
                  }}
                  inputProps={{
                    min: today, // Restrict selectable dates to today or later
                  }}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "default",
                      },
                      "&:hover fieldset": {
                        borderColor: "default",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F06161",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#F06161",
                      },
                    },
                  }}
                />
                <span className="text-danger">{errors.startDate1}</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Starting Time"
                  type="time"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={startTime1}
                  ref={startTime1Ref}
                  className={`${errors.startTime1 && "input-field-error"}`}
                  onChange={(e) => {
                    setStartTime1(e.target.value);
                    setErrors({ startTime1: "" });
                  }}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "default",
                      },
                      "&:hover fieldset": {
                        borderColor: "default",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F06161",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#F06161",
                      },
                    },
                  }}
                />
                <span className="text-danger">{errors.startTime1}</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Ending Date"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={endDate1}
                  ref={endDate1Ref}
                  className={`${errors.endDate1 && "input-field-error"}`}
                  onChange={(e) => {
                    setEndDate1(e.target.value);
                    setErrors({ endDate1: "" });
                  }}
                  inputProps={{
                    min: startDate1 || today, // Use startDate1 or today as the minimum selectable date
                  }}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "default",
                      },
                      "&:hover fieldset": {
                        borderColor: "default",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F06161",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#F06161",
                      },
                    },
                  }}
                />
                <span className="text-danger">{errors.endDate1}</span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Ending Time"
                  type="time"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={endTime1}
                  ref={endTime1Ref}
                  className={`${errors.endTime1 && "input-field-error"}`}
                  onChange={(e) => {
                    setEndTime1(e.target.value);
                    setErrors({ endTime1: "" });
                  }}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "default",
                      },
                      "&:hover fieldset": {
                        borderColor: "default",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F06161",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#F06161",
                      },
                    },
                  }}
                />
                <span className="text-danger">{errors.endTime1}</span>
              </Grid>
            </Grid>
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={strictEndTime}
                    onChange={() => setStrictEndTime(!strictEndTime)}
                    sx={{ "&.Mui-checked": { color: "#F06161" } }}
                  />
                }
                label="Strict end time (no flexibility)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={expiration}
                    onChange={() => setExpiration(!expiration)}
                    sx={{ "&.Mui-checked": { color: "#F06161" } }}
                  />
                }
                label="Set expiration for incomplete tests"
              />
              {expiration && (
                <TextField
                  type="number"
                  label="Days after invite"
                  fullWidth
                  value={daysAfterInvite}
                  onChange={(e) => setDaysAfterInvite(e.target.value)}
                  sx={{
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "default",
                      },
                      "&:hover fieldset": {
                        borderColor: "default",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F06161",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      "&.Mui-focused": {
                        color: "#F06161",
                      },
                    },
                  }}
                />
              )}
            </Box>
          </Grid>

          {/* Reporting Settings */}
          <Grid item xs={12}>
            <Typography variant="h6">Reporting Settings</Typography>
            <TextField
              label="Report Emails"
              type="email"
              fullWidth
              value={reportEmails}
              ref={reportEmailsRef}
              className={`${errors.reportEmails && "input-field-error"}`}
              onChange={(e) => {
                setReportEmails(e.target.value);
                setErrors({ reportEmails: "" });
              }}
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
            <span className="text-danger">{errors.reportEmails}</span>

            {/* <FormControlLabel
              control={<Checkbox checked={sendEmailReport} onChange={() => setSendEmailReport(!sendEmailReport)} sx={{ '&.Mui-checked': { color: "#F06161" } }} />}
              label="Send email report to these addresses"
            />
            {sendEmailReport && (
              <TextField
                label="Custom Message"
                fullWidth
                multiline
                rows={4}
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value)}
                sx={{
                  mt: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "default",
                    },
                    "&:hover fieldset": {
                      borderColor: "default",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#F06161",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#F06161",
                    },
                  },
                }}
              />
            )} */}
            {/* <FormControlLabel
              control={<Checkbox checked={sendReminder} onChange={() => setSendReminder(!sendReminder)} sx={{  '&.Mui-checked': { color: "#F06161" } }} />}
              label="Send reminder emails"
            /> */}
          </Grid>

          {/* Support Settings */}
          <Grid item xs={12}>
            <Typography variant="h6">Support Settings</Typography>
            <TextField
              label="Support Email"
              type="email"
              fullWidth
              value={supportEmail}
              ref={supportEmailRef}
              className={`${errors.supportEmail && "input-field-error"}`}
              onChange={(e) => {
                setSupportEmail(e.target.value);
                setErrors({ supportEmail: "" });
              }}
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
            <span className="text-danger">{errors.supportEmail}</span>

            <TextField
              label="Support Phone"
              type="tel"
              fullWidth
              value={supportPhone}
              ref={supportPhoneRef}
              className={`${errors.supportPhone && "input-field-error"}`}
              onChange={(e) => {
                setSupportPhone(e.target.value);
                setErrors({ supportPhone: "" });
              }}
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
            <span className="text-danger">{errors.supportPhone}</span>
          </Grid>

          {/* Miscellaneous Settings */}
          <Grid item xs={12}>
            <Typography variant="h6">Miscellaneous Settings</Typography>
            <TextField
              label="Redirect URL"
              fullWidth
              value={redirectURL}
              ref={redirectURLRef}
              className={`${errors.redirectURL && "input-field-error"}`}
              onChange={(e) => {
                setRedirectURL(e.target.value);
                setErrors({ redirectURL: "" });
              }}
              sx={{
                mt: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
            <span className="text-danger">{errors.redirectURL}</span>
            {/* <FormControlLabel
              control={<Checkbox checked={shuffleOptions} onChange={() => setShuffleOptions(!shuffleOptions)} sx={{  '&.Mui-checked': { color: "#F06161" } }} />}
              label="Shuffle options"
            /> */}
          </Grid>
        </Grid>

        <Box mt={4}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Settings
          </Button>
        </Box>

        {/* Snackbar for feedback */}
        {/* <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar> */}
      </Box>
      {/* Snackbar  */}
      <UserSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
      />
    </div>
  );
};

export default AdvancedSettings;
