import React from "react";
// import CompanyProfile from "../userProfiles/CompanyProfile";
import SkillBars from "./components/SkillBars";

export default function Aptitude (){
    return(
        <div>
           <SkillBars/>
        </div>
    );
};