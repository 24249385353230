import React, { useEffect } from 'react';
import About from './About';
import Testimonial from './Testimonial';
import Footer from './Footer';
import Hero from './Hero';
import Navbar from './Navbar';
import Services from './Services';
import './landing.css';

function LandingPage() {

  // Clearing localStorage on component mount
  useEffect(() => {
    localStorage.clear();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Services />
      <Testimonial />
      <Footer />
    </div>
  );
}

export default LandingPage;
