import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  tabsClasses,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CandidateNavbar from './CandidateNavbar';
import QuestionSideTab from './QuestionSideTab';
import './CandidateQuestionPage.css';
// import { stopRecordingRef } from "./proctor mode components/EnvironmentSetup";

const CandidateQuestionPage = () => {

  const { testId, invitationToken } = useParams();

  const navigate = useNavigate(); // Initialize the useHistory hook
  const location = useLocation();
  const { testName, duration, cameraEnabled } = location.state || {}; // Retrieve testname

  console.log("camera enable", cameraEnabled);



  const [timeRemaining, setTimeRemaining] = useState(() => {
    const savedTime = localStorage.getItem('timeRemaining');
    return savedTime ? parseInt(savedTime, 10) : duration * 60;
  });

  const [timeTaken, setTimeTaken] = useState(() => {
    const savedTimeTaken = localStorage.getItem('timeTaken');
    return savedTimeTaken ? parseInt(savedTimeTaken, 10) : 0;
  });

  const [fullscreenExitCount, setFullscreenExitCount] = useState(() => {
    const savedExitCount = localStorage.getItem('fullscreenExitCount');
    return savedExitCount ? parseInt(savedExitCount, 10) : 0;
  });

  const [tabSwitchCount, setTabSwitchCount] = useState(() => {
    const savedSwitchCount = localStorage.getItem('tabSwitchCount');
    return savedSwitchCount ? parseInt(savedSwitchCount, 10) : 1;
  });

  // Add a new state for storing answers
  const [answers, setAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem('answers');
    return savedAnswers ? JSON.parse(savedAnswers) : {};
  });

  const [questions, setQuestions] = useState({});
  // Add a new state for storing answers
  // const [answers, setAnswers] = useState({});

  const [showQuestionSideTab, setShowQuestionSideTab] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [attemptedQuestions, setAttemptedQuestions] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFullscreenDialog, setOpenFullscreenDialog] = useState(true); // Fullscreen window dialog state
  const [formattedSections, setFormattedSections] = useState([]);

  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [deviceWarning,setDeviceWarning]=useState(false);
  const [permissionWarning, setPermissionWarning] = useState(false);

  const totalQuestions = Object.values(questions).flat().length;

  // const logTabSwitch = async () => {
  //   try {
  //     await axios.post(`/api/candidate/log-tab-switch/${testId}/${invitationToken}`, {
  //       action: 'tab switch',
  //       timestamp: new Date(),
  //       switchCount: tabSwitchCount,
  //     });
  //   } catch (error) {
  //     console.error('Error logging tab switch:', error);
  //   }
  // };

  // Detect tab switching using the Page Visibility API
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.hidden) {
  //       console.log('User switched tabs or minimized the window.');
  //       setTabSwitchCount(prevCount => prevCount + 1); // Increment the switch counter
  //       console.log("switchCount", tabSwitchCount);

  //       logTabSwitch(); // Log the tab switch
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, [tabSwitchCount]); // Depend on tabSwitchCount to trigger logging

  // useEffect(() => {
  //   let lastActivityTime = new Date().getTime();

  //   const handleVisibilityChange = () => {
  //     if (document.hidden || document.visibilityState === 'hidden') {
  //       console.log('User switched tabs, minimized the window, or opened another app.');
  //       setTabSwitchCount(prevCount => prevCount + 1); // Increment the switch counter
  //       console.log("switchCount", tabSwitchCount);
  //       logTabSwitch(); // Log the tab switch
  //     }
  //   };

  //   const handleBlur = () => {
  //     console.log('Window lost focus (blur event).');
  //     setTabSwitchCount(prevCount => prevCount + 1); // Increment the switch counter
  //     console.log("switchCount", tabSwitchCount);
  //       logTabSwitch(); // Log the tab switch
  //   };

  //   const handleFocus = () => {
  //     console.log('Window gained focus (focus event).');
  //     // Reset the activity timer if needed
  //     lastActivityTime = new Date().getTime();

  //   };

  //   // Monitor window blur and focus events
  //   window.addEventListener('blur', handleBlur);
  //   window.addEventListener('focus', handleFocus);

  //   // Monitor page visibility
  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   // Optionally use an interval to track inactivity (e.g., every 5 seconds)
  //   const activityMonitor = setInterval(() => {
  //     const currentTime = new Date().getTime();
  //     const timeSinceLastActivity = currentTime - lastActivityTime;

  //     // Example: If user is inactive for more than 10 seconds, treat it as a switch
  //     if (timeSinceLastActivity > 10000 && !document.hasFocus()) {
  //       console.log('User is inactive or switched windows.');
  //       setTabSwitchCount(prevCount => prevCount + 1); // Increment the switch counter
  //       console.log("switchCount", tabSwitchCount);
  //       logTabSwitch(); // Log the tab switch
  //     }
  //   }, 5000); // Check every 5 seconds

  //   // Cleanup event listeners and interval on component unmount
  //   return () => {
  //     window.removeEventListener('blur', handleBlur);
  //     window.removeEventListener('focus', handleFocus);
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //     clearInterval(activityMonitor);
  //   };
  // }, [tabSwitchCount]);

  const logTabSwitch = async () => {
    try {
      await axios.post(`/api/candidate/log-tab-switch/${testId}/${invitationToken}`, {
        action: 'tab switch',
        timestamp: new Date(),
        switchCount: tabSwitchCount,
      });
    } catch (error) {
      console.error('Error logging tab switch:', error);
    }
  };

  useEffect(() => {
    if (cameraEnabled) {
      startRecording();
      checkDefaultMicAndCameraPermission();
      monitorPermissions();
    }
  }, [cameraEnabled]); // ✅ Depend on cameraEnabled

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.muted = true;
      }

      let chunks = [];
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(chunks, { type: "video/webm" });
        await uploadToBackend(blob);
      };

      mediaRecorderRef.current.start();
      setRecording(true);
      console.log("Recording started...");
    } catch (error) {
      console.error("Error accessing camera:", error);
      // alert("Camera and microphone access is required.");
    }
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      console.log("Recording stopped...");
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  const uploadToBackend = async (blob) => {
    try {
      const formData = new FormData();
      formData.append("video", blob, "recorded_video.webm");
      formData.append("testId", testId); // Add testId
      formData.append("invitationToken", invitationToken); // Add invitationToken

      await axios.post("/api/video/upload-to-drive", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("Upload success");
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };


  const checkDefaultMicAndCameraPermission=async()=>{
    const checkDefaultDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some(device => device.kind === "videoinput");
        const hasMicrophone = devices.some(device => device.kind === "audioinput");
    
        if (!hasCamera || !hasMicrophone) {
          setDeviceWarning(true); // Show warning if missing
        } else {
          setDeviceWarning(false); // Hide warning when available
        }
      } catch (error) {
        console.error("Error checking devices:", error);
      }
    };
    
    // Run check every 3 seconds
    setInterval(checkDefaultDevices, 3000);

  }

  /** ✅ Monitor Camera & Mic Permissions */
  const monitorPermissions = async () => {
    const checkPermissions = async () => {
      const camera = await navigator.permissions.query({ name: "camera" });
      const mic = await navigator.permissions.query({ name: "microphone" });

      if (camera.state === "denied" || mic.state === "denied") {
        setPermissionWarning(true); // Show warning
      } else {
        setPermissionWarning(false); // Hide warning when restored
      }
    };

    // Check every 3 seconds
    setInterval(checkPermissions, 3000);
  };



  // Auto-submit the test if tab switches exceed the limit
  // const handleTabSwitchLimit = async () => {
  //   console.log("switch count=", tabSwitchCount);

  //   // if (tabSwitchCount >= 3) {
  //   //   handleSubmit(false, 'Your test is submitted due to exceeding the limit of tab switches.');
  //   // }
  //   try {
  //     const response = await axios.post(`/api/candidate/get-test-detail/${testId}/${invitationToken}`);
  //     const { AdvancedSettings } = response.data.test; // Adjust destructuring to match axios response structure
  //     console.log("Advanced Settings:", AdvancedSettings.browserTolerance);

  //     if (tabSwitchCount >= AdvancedSettings.browserTolerance) {
  //       handleSubmit(false, 'Your test is submitted due to exceeding the limit of tab switches.');
  //     }
  //   } catch (error) {
  //     console.error("Error fetching test details:", error);
  //   }

  // };

  const handleTabSwitchLimit = async () => {
    // console.log("Switch count=", tabSwitchCount);

    try {
      const response = await axios.post(`/api/candidate/get-test-detail/${testId}/${invitationToken}`);
      const { AdvancedSettings } = response.data.test; // Adjust destructuring to match axios response structure
      console.log("Advanced Settings:", AdvancedSettings);

      // Ensure the function works only when proctoring is "basicProctoring"
      if (AdvancedSettings.proctoring !== "basicProctoring") {
        console.log("Proctoring is disabled or not basicProctoring. No action taken.");
        return;
      }

      // Check if browserTolerance is a number or "custom"
      let tolerance = AdvancedSettings.browserTolerance;

      // If browserTolerance is "custom", use the customTolerance value
      if (tolerance === "custom") {
        tolerance = AdvancedSettings.customTolerance || 0; // Default to 0 if customTolerance is not defined
      }

      // Proceed with the check using the appropriate tolerance value
      if (tabSwitchCount >= tolerance) {
        handleSubmit(false, 'Your test is submitted due to exceeding the limit of tab switches.');
      }
    } catch (error) {
      console.error("Error fetching test details:", error);
    }
  };



  // Auto-submit the test if fullscreen exits exceed the limit
  // const handleFullscreenExitLimit = async () => {
  //   // if (fullscreenExitCount > 3) {
  //   //   handleSubmit(false, 'Your test is submitted due to exceeding the limit of exiting fullscreen.');
  //   // }

  //   try {
  //     const response = await axios.post(`/api/candidate/get-test-detail/${testId}/${invitationToken}`);
  //     const { AdvancedSettings } = response.data.test; // Adjust destructuring to match axios response structure
  //     console.log("Advanced Settings:", AdvancedSettings.browserTolerance);

  //     if (fullscreenExitCount >= AdvancedSettings.browserTolerance) {
  //       handleSubmit(false, 'Your test is submitted due to exceeding the limit of tab switches.');
  //     }
  //   } catch (error) {
  //     console.error("Error fetching test details:", error);
  //   }
  // };

  const handleFullscreenExitLimit = async () => {
    try {
      const response = await axios.post(`/api/candidate/get-test-detail/${testId}/${invitationToken}`);
      const { AdvancedSettings } = response.data.test; // Adjust destructuring to match axios response structure
      console.log("Advanced Settings:", AdvancedSettings);

      // Ensure the function works only when proctoring is "basicProctoring"
      if (AdvancedSettings.proctoring !== "basicProctoring") {
        console.log("Proctoring is disabled or not basicProctoring. No action taken.");
        return;
      }

      // Check if browserTolerance is a number or "custom"
      let tolerance = AdvancedSettings.browserTolerance;

      // If browserTolerance is "custom", use the customTolerance value
      if (tolerance === "custom") {
        tolerance = AdvancedSettings.customTolerance || 0; // Default to 0 if customTolerance is not defined
      }

      // Proceed with the check using the appropriate tolerance value
      if (fullscreenExitCount >= tolerance) {
        handleSubmit(false, 'Your test is submitted due to exceeding the limit of tab switches.');
      }
    } catch (error) {
      console.error("Error fetching test details:", error);
    }
  };



  // useEffect(() => {
  //   localStorage.setItem('questions', JSON.stringify(questions));
  // }, [questions]);



  useEffect(() => {
    localStorage.setItem('timeRemaining', timeRemaining.toString());
  }, [timeRemaining]);

  useEffect(() => {
    localStorage.setItem('timeTaken', timeTaken.toString());
  }, [timeTaken]);

  useEffect(() => {
    localStorage.setItem('answers', JSON.stringify(answers));
  }, [answers]);

  // Sync fullscreenExitCount to localStorage
  useEffect(() => {
    localStorage.setItem('fullscreenExitCount', fullscreenExitCount.toString());
  }, [fullscreenExitCount]);

  // Sync tabSwitchCount to localStorage
  useEffect(() => {
    localStorage.setItem('tabSwitchCount', tabSwitchCount.toString());
  }, [tabSwitchCount]);


  useEffect(() => {
    let lastActivityTime = new Date().getTime();

    // Handle tab switch and increment count
    const handleVisibilityChange = () => {
      if (document.hidden || document.visibilityState === 'hidden') {
        console.log('User switched tabs, minimized the window, or opened another app.');
        setTabSwitchCount(prevCount => {
          const newCount = prevCount + 1;
          logTabSwitch();
          handleTabSwitchLimit();
          return newCount;
        });
      }
    };

    const handleBlur = () => {
      console.log('Window lost focus (blur event).');
      setTabSwitchCount(prevCount => {
        const newCount = prevCount + 1;
        logTabSwitch();
        handleTabSwitchLimit();
        return newCount;
      });
    };

    const handleFocus = () => {
      console.log('Window gained focus (focus event).');
      lastActivityTime = new Date().getTime();
    };

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setFullscreenExitCount(prevCount => {
          const newCount = prevCount + 1;
          handleFullscreenExitLimit();
          setOpenFullscreenDialog(true); // Show the dialog if fullscreen is exited
          return newCount;
        });
      }
    };

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      // clearInterval(activityMonitor);
    };
  }, [tabSwitchCount, fullscreenExitCount]);


  // updating the status of test from pending to ongoing
  // useEffect(() => {
  //   const startTest = async () => {
  //     try {
  //       await axios.post(`/api/candidate/update-status/${testId}/${invitationToken}`, {
  //         status: 'ongoing',
  //       });
  //       console.log("Candidate status updated to ongoing.");
  //     } catch (err) {
  //       console.error("Error updating candidate status to ongoing:", err);
  //     }
  //   };

  //   startTest();
  // }, []); // This runs when the component mounts

  useEffect(() => {
    const getCurrentTime = () => {
      const now = new Date();
      return now.toISOString(); // Converts to an ISO 8601 string, compatible with MongoDB's Date type
    };

    const startTest = async () => {
      try {
        const currentTime = getCurrentTime(); // Get the current time as an ISO string
        await axios.post(`/api/candidate/update-status/${testId}/${invitationToken}`, {
          status: 'ongoing',
          startTime: currentTime, // Send the ISO string for the startTime field
        });
        console.log("Candidate status updated to ongoing.");
      } catch (err) {
        console.error("Error updating candidate status to ongoing:", err);
      }
    };

    startTest();
  }, []); // This runs when the component mounts

  // Start the stopwatch timer
  useEffect(() => {
    const stopwatchId = setInterval(() => {
      setTimeTaken(prevTime => prevTime + 1);
    }, 1000);

    return () => clearInterval(stopwatchId); // Cleanup interval
  }, []);

  // Format time for display
  const formatTimeTaken = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (hrs > 0) {
      return `${hrs}:${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
    } else {
      return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const savedQuestions = localStorage.getItem('questions');
        console.log("saved questions", savedQuestions);

        if (savedQuestions) {
          // Parse and transform saved questions to match the format of formattedSections
          const parsedSavedQuestions = JSON.parse(savedQuestions);

          // Format the saved questions to match the structure of formattedSections
          const formattedSavedSections = Object.keys(parsedSavedQuestions).map(sectionName => {
            const formattedProblems = parsedSavedQuestions[sectionName].map((problem, index) => ({
              _id: problem.questionId,
              name: problem.name,
              type: problem.type,
              text: problem.mcq.question, // Assuming the `question` is stored here
              options: problem.mcq.options,
              points: 0, // Set this to a default or modify if points are stored
              category: '', // Set this as needed
              correctAnswer: problem.mcq.answer,
              difficulty: '', // Set this as needed
              status: problem.status
            }));

            return {
              sectionName: sectionName,
              problems: formattedProblems
            };
          });

          setFormattedSections(formattedSavedSections);
          console.log("formattedSavedSections=", formattedSavedSections);

          // Transform `savedQuestions` into the format for `setQuestions`
          const formattedQuestions = Object.keys(parsedSavedQuestions).reduce((acc, sectionName) => {
            const formattedQuestions = parsedSavedQuestions[sectionName].map((problem, index) => ({
              id: index + 1,
              questionId: problem.questionId,
              name: problem.name,
              type: problem.type,
              status: problem.status,
              image: problem.image,
              mcq: {
                answer: problem.mcq.answer,
                options: problem.mcq.options,
                question: problem.mcq.question
              }
            }));
            acc[sectionName] = formattedQuestions;
            return acc;
          }, {});

          setQuestions(formattedQuestions);
          console.log("formattedQuestions=", formattedQuestions);
        } else {
          const testResponse = await axios.post(`/api/candidate/get-test-detail/${testId}/${invitationToken}`);
          console.log("testResponse = ", testResponse);

          const { sections, AdvancedSettings, candidates } = testResponse.data.test;
          console.log("sections", sections);
          console.log("advanced settings", AdvancedSettings);

          const formattedSections = sections.map(section => {
            const formattedProblems = section.problems.map(problem => ({
              _id: problem._id,
              name: problem.name,
              type: problem.type,
              text: problem.text,
              options: problem.options,
              points: problem.points,
              category: problem.category,
              correctAnswer: problem.correctAnswer,
              difficulty: problem.difficulty,
              status: 'unsolved',
              image: problem.image
            }));

            return {
              sectionName: section.sectionName,
              problems: formattedProblems
            };
          });
          setFormattedSections(formattedSections);
          console.log("formattedSections=", formattedSections);

          const formattedQuestions = sections.reduce((acc, section) => {
            const formattedQuestions = section.problems.map((problem, index) => ({
              id: index + 1,
              questionId: problem._id,
              name: problem.name,
              type: problem.type,
              status: "unsolved",
              image: problem.image,
              mcq: {
                answer: problem.correctAnswer,
                options: problem.options,
                question: problem.text
              }
            }));
            acc[section.sectionName] = formattedQuestions;
            return acc;
          }, {});

          console.log("formattedQuestions=", formattedQuestions);
          setQuestions(formattedQuestions);
        }

      } catch (err) {
        console.log("error ", err);
      }
    };

    // Call the async function
    fetchData();
  }, []);


  useEffect(() => {
    console.log("questions=", questions);
  }, [questions])

  // const handleQuestionAttempted = (section, questionId, option) => {
  //   setQuestions(prevQuestions => {
  //     const updatedQuestions = { ...prevQuestions };
  //     const question = updatedQuestions[section].find(q => q.id === questionId);
  //     if (question) {
  //       question.selectedOption = option;
  //       question.status = option ? 'Attempted' : 'Unsolved';
  //     }
  //     return updatedQuestions;
  //   });
  // };

  const handleQuestionAttempted = (section, questionId, option) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = { ...prevQuestions };
      const question = updatedQuestions[section].find((q) => q.id === questionId);
      if (question) {
        question.selectedOption = option;
        question.status = option ? 'Attempted' : 'Unsolved';
      }
      localStorage.setItem('questions', JSON.stringify(updatedQuestions)); // Save to localStorage
      return updatedQuestions;
    });
  };

  // Handle answer change from QuestionSideTab.js
  const handleAnswerChange = (sectionName, questionId, selectedOption) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: selectedOption,
    }));
  };


  const handleSubmit = async (isTimeUp = false, reason = '') => {
    try {

      // if (stopRecordingRef.current) {
      //   stopRecordingRef.current(); // Stop recording before submission
      //   console.log("Recording stopped before test submission.");
      // }

      console.log("Stopping recording before submission...");
      await stopRecording(); // ✅ Ensuring recording stops before submission

      console.log("answers", answers);

      const timeToSubmit = isTimeUp ? duration * 60 : timeTaken; // If time's up, use total test duration
      const formattedTimeTaken = formatTimeTaken(timeToSubmit);


      const response = await axios.post(`/api/candidate/submit-test-detail/${testId}/${invitationToken}`, {
        submittedAnswers: answers,
        timeTaken: formattedTimeTaken, // Send formatted time
        reason,
      });


      console.log('response of submit', response);

      setOpenDialog(false);

      // Exit fullscreen when the test is submitted
      if (document.fullscreenElement) {
        document.exitFullscreen().catch(err => console.log(err)); // Handles any errors
      }
      //   if(isTimeUp)  history.push('/completed', { isTimeUp: isTimeUp });
      //   else history.push('/completed', { isTimeUp: isTimeUp });
      // } catch (error) {
      //   console.error('Error submitting test:', error);
      // }

      // Exit fullscreen when the test is submitted
      // if (document.fullscreenElement) {
      //   document.exitFullscreen().catch(err => console.log(err)); // Handles any errors
      // }

      // if (isTimeUp) history.push('/completed', { isTimeUp: isTimeUp });
      // else history.push('/completed', { isTimeUp: isTimeUp,  reason });

      localStorage.removeItem('timeRemaining');
      localStorage.removeItem('timeTaken');
      localStorage.removeItem('fullscreenExitCount');
      localStorage.removeItem('tabSwitchCount');
      localStorage.removeItem('questions');
      localStorage.removeItem('answers');

      // navigate('/completed', { state: { isTimeUp, reason } });
      navigate('/completed', { state: { isTimeUp, reason }, replace: true });
    } catch (error) {
      console.error('Error submitting test:', error);
    }

  };


  // Inside the timer useEffect
  useEffect(() => {
    const timerId = setInterval(async () => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerId);

          // Automatically submit the test when time's up
          handleSubmit(true, "timeIsUp"); // Call the submit function
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, [navigate, answers]);

  // Format timeRemaining to HH:MM:SS or MM:SS when rendering
  const formatDuration = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      // Return HH:MM:SS format if there are hours
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      // Return MM:SS format if there are no hours
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
  };

  // Inside the timer useEffect
  // useEffect(() => {
  //   const timerId = setInterval(async() => {
  //     setTimeRemaining((prevTime) => {
  //       if (prevTime <= 1) {
  //         clearInterval(timerId);

  //         history.push('/completed', { isTimeUp: true }); // Indicate time's up submission
  //         return 0;
  //       }
  //       return prevTime - 1;
  //     });
  //   }, 1000);

  //   return () => clearInterval(timerId);
  // }, [history]);

  useEffect(() => {
    const attemptedCount = Object.values(questions)
      .flat()
      .filter((q) => q.status === 'Attempted')
      .length;
    setAttemptedQuestions(attemptedCount);
  }, [questions]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleSolveClick = (section, questionId) => {
    console.log("section", section, questionId);

    setSelectedSection(section);
    setShowQuestionSideTab(true);
    setSelectedQuestionId(questionId);
  };

  const handleSectionChange = (newSection) => {
    setSelectedSection(newSection);
  };

  const handleBackToMenu = () => {
    setShowQuestionSideTab(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Function to enable fullscreen mode
  const enableFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
      document.documentElement.msRequestFullscreen();
    }
    setOpenFullscreenDialog(false); // Close the fullscreen dialog after enabling fullscreen
  };

  // Detect if fullscreen mode is exited
  // const handleFullscreenChange = () => {
  //   if (!document.fullscreenElement) {
  //     setFullscreenExitCount(prevCount => {
  //       const newCount = prevCount + 1;
  //       if (newCount > 3) {
  //         // Auto submit the test if the count exceeds 3
  //         handleSubmit(false, 'Your test is submitted due to exceeding the limit of exiting fullscreen.'); // Automatically submit the test
  //       }
  //       setOpenFullscreenDialog(true); // Show the dialog if fullscreen is exited
  //       return newCount; // Return the updated count
  //     });
  //   }
  // };

  // useEffect(() => {
  //   // Add event listener to detect fullscreen exit
  //   document.addEventListener("fullscreenchange", handleFullscreenChange);

  //   // Clean up event listener on component unmount
  //   return () => {
  //     document.removeEventListener("fullscreenchange", handleFullscreenChange);
  //   };
  // }, []);


  // Display the dialog only when the component first mounts
  useEffect(() => {
    setOpenFullscreenDialog(true);
  }, []);

  if (showQuestionSideTab) {
    return (
      <QuestionSideTab
        initialSection={selectedSection}
        questions={questions}
        setQuestions={setQuestions}
        selectedQuestionId={selectedQuestionId}
        onSectionChange={handleSectionChange}
        onBackToMenu={handleBackToMenu}
        onQuestionAttempted={handleQuestionAttempted}
        onAnswerChange={handleAnswerChange} // Pass answer change handler
        formattedSections={formattedSections}
        testName={testName}
        openFullscreenDialog={openFullscreenDialog}  // Pass the dialog state
        setOpenFullscreenDialog={setOpenFullscreenDialog}  // Pass the function to change the dialog state
        enableFullscreen={enableFullscreen}
        fullscreenExitCount={fullscreenExitCount} // Pass the exit count
      >
        <h3 className="timer">
          <AccessTimeIcon sx={{ fontSize: 'larger' }} /> {formatDuration(timeRemaining)}
        </h3>
      </QuestionSideTab>
    );
  }

  return (
    <Box sx={{ backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'column', height: 'fit-content' }}>
      <CandidateNavbar totalQuestions={totalQuestions} attemptedQuestions={attemptedQuestions} showMeter={true} testName={testName} />
      <h3 className="timer">
        <AccessTimeIcon sx={{ fontSize: 'larger' }} /> {formatDuration(timeRemaining)}
      </h3>

      {(deviceWarning || permissionWarning)  && (
        <div className="permission-warning">
          <div className="warning-box">
            <h2><WarningAmberIcon style={{ fontSize: 40, color: "#f06161" }} /> Camera & Microphone Access Required</h2>
            <p>Please enable camera and microphone permissions to proceed with the assessment.</p>
          </div>
        </div>
      )}


      {/* Stopwatch display */}
      {/* <Typography variant="body1">
        Time Taken: {formatTimeTaken(timeTaken)}
      </Typography> */}

      <Grid container justifyContent="center" sx={{ flexGrow: 1, padding: 2 }}>
        <Grid item xs={12} sm={10} md={8} lg={6} sx={{ backgroundColor: '#ffffff', padding: 3, borderRadius: 1 }}>
          <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
            <Table aria-label="question table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', width: '5%' }}>#</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '25%' }}>Problem Name</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Type</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Status</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', width: '30%' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formattedSections?.map((section) => (

                  <React.Fragment key={section.sectionName}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>{section.sectionName}</TableCell>
                      <TableCell colSpan={3}></TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Typography variant="body2" sx={{ fontStyle: 'italic', textAlign: 'center' }}>
                          Attempted {questions[section.sectionName]?.filter((q) => q.status === 'Attempted').length} out of{' '}
                          {section.problems.length}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {questions[section.sectionName]?.map((question, index) => (
                      <TableRow key={question.id}>
                        <TableCell sx={{ textAlign: 'center' }}>{index + 1}.</TableCell>
                        <TableCell>{question.name}</TableCell>
                        <TableCell>{question.type}</TableCell>
                        <TableCell>
                          <span className={question.status === 'Attempted' ? 'status-update' : 'status-unsolved'}>
                            <InfoIcon sx={{ fontSize: 'small', marginBottom: '3px' }} />
                            {question.status}

                          </span>
                          {question.status === 'Attempted' ? (
                            <CheckCircleIcon sx={{ fontSize: 'large', marginBottom: '3px', color: 'green' }} />
                          ) : ''}
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <Button
                            variant={question.status === 'Attempted' ? 'outlined' : 'contained'}
                            className={question.status === 'Attempted' ? 'updateBtn' : 'solveBtn'}
                            sx={{
                              width: '100%',
                              maxWidth: '120px',
                              '&:hover': { backgroundColor: '#d05050' },
                            }}
                            onClick={() => handleSolveClick(section.sectionName, question.id)}
                          >
                            {question.status === 'Attempted' ? 'UPDATE' : 'SOLVE'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{
          position: 'fixed',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          fontSize: '20px',
          padding: '10px 30px',
          backgroundColor: '#f06161',
          color: 'white',
          '&:hover': { backgroundColor: '#d05050' },
        }}
        onClick={handleOpenDialog}
      >
        SUBMIT ASSESSMENT
      </Button>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent sx={{ padding: 3, overflowWrap: 'normal' }}>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Remaining Time: {formatDuration(timeRemaining)}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            You have attempted {attemptedQuestions} out of {totalQuestions} questions.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            You will not be able to attempt any question or make changes to your solutions once you submit.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end', padding: '16px 24px' }}>
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            sx={{ color: '#f06161', borderColor: '#f06161', '&:hover': { borderColor: '#f06161' } }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => { handleSubmit(false, "clickedSubmitButton") }}
            variant="contained"
            sx={{ backgroundColor: '#f06161', color: 'white', '&:hover': { backgroundColor: '#d05050' } }}
          >
            Submit Assessment
          </Button>
        </DialogActions>
      </Dialog>

      {/* Fullscreen Dialog */}
      <Dialog open={openFullscreenDialog} maxWidth="sm" fullWidth >
        <DialogContent sx={{ height: '10vh' }}>
          <Typography variant="body1" sx={{ fontSize: '1rem' }}>
            This assessment requires your browser window to be in fullscreen mode.
          </Typography>
          {/* <Typography variant="body2" sx={{ mt: 2 }}>
            You have tried to exit fullscreen {fullscreenExitCount} times.
          </Typography> */}
          {/* <Typography variant="body2" sx={{ mt: 2 }}>
            You have tried to switch tabs {tabSwitchCount} times.
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={enableFullscreen}
            sx={{ backgroundColor: '#f06161', color: 'white', '&:hover': { backgroundColor: '#d05050' } }}
          >
            Enable Fullscreen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CandidateQuestionPage;
