import React, { useEffect, useState } from 'react';
import { Grid, TextField, RadioGroup, FormControlLabel, Radio, Button, Box, Typography } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./GeneralSettings.css"
import axios from 'axios';
import UserSnackbar from "../../../snackBars/UserSnackbar";


const GeneralSettings = ({ testId, testName, setTestName, saveTestName, data, setGeneralSettingsData, onSaveComplete, isNewTest }) => {
  console.log("data", data);

  const [duration, setDuration] = useState(data.duration || "30");
  const [cutOff, setCutOff] = useState(data.percentage || "30");
  const [instructions, setInstructions] = useState("");
  const [difficulty, setDifficulty] = useState(data.difficultyLevel || "easy");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // useEffect(() => {
  //   async function getGeneralSettings() {
  //     try {
  //       const generalSettingsResponse = await axios.post(
  //         `/api/admin/skill-assessment/test-management/${testId}/get-general-settings`
  //       );

  //       const data = generalSettingsResponse.data; 
  //       console.log("Received data:", data.settings.testName,data.settings.generalSettings)
  //         // data.settings.generalSettings.difficultyLevel,settings.generalSettings.duration,settings.generalSettings.percentage

  //     } catch (error) {
  //       console.error("Error fetching general settings:", error);
  //     }
  //   }
  //   getGeneralSettings();
  // }, [testId, testName, duration, cutOff, difficulty]);  // Include dependencies here

  useEffect(() => {
    // Update state when data changes
    if (data.duration) {
      setDuration(data.duration);
      setCutOff(data.percentage);
    } else {
      setDuration("30");
      setCutOff("30");
      setDifficulty("easy")
    }
  }, [data]);



  // const handleSave = async () => {
  //   // Save functionality here
  //   const generalSettingsResponse = await axios.post(`/api/admin/skill-assessment/test-management/${testId}/add-general-settings`, { testName, duration, cutOff, difficulty })
  //   saveTestName(); // Save the name when the API call is successful
  //   console.log(generalSettingsResponse.data.test.GeneralSettings);
  //   setGeneralSettingsData(generalSettingsResponse.data.test.GeneralSettings);

  //   setMessage(generalSettingsResponse.data.message);
  //   setOpenSnackbar(true)


  // };

  // Store the default values in the database on component mount

  // useEffect(() => {
  //   const saveInitialSettings = async () => {
  //     try {
  //       const response = await axios.post(`/api/admin/skill-assessment/test-management/${testId}/add-general-settings`, {
  //         testName,
  //         duration,
  //         cutOff,
  //         difficulty,
  //       });
  //       setGeneralSettingsData(response.data.test.GeneralSettings);
  //     } catch (error) {
  //       console.error("Error saving general settings on mount:", error);
  //     }
  //   };

  //   if (data && !data.duration) {
  //     saveInitialSettings(); // Only save if no existing data
  //   }
  // }, [data, testId, testName, duration, cutOff, difficulty, setGeneralSettingsData]);

  const handleSave = async () => {
    try {
      const response = await axios.post(`/api/admin/skill-assessment/test-management/${testId}/add-general-settings`, {
        testName,
        duration,
        cutOff,
        difficulty,
      });
      saveTestName(); // Save the test name when the API call is successful
      setGeneralSettingsData(response.data.test.GeneralSettings);
      setMessage(response.data.message);
      setOpenSnackbar(true);
      // onSaveComplete(true); // Notify the parent component that save is complete
    } catch (error) {
      console.error("Error saving general settings:", error);
      setMessage("Failed to save general settings.");
      setOpenSnackbar(true);
      // onSaveComplete(false); 
    }
  };

  return (
    <div className='generalsettings-container'>

      <Box p={3} display="flex" justifyContent="center">
        <Grid container spacing={3} maxWidth="1100px">
          <Grid item xs={12}>
            <TextField
              label="Test Name"
              fullWidth
              value={testName}
              onChange={(e) => setTestName(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Duration (minutes)"
              type="number"
              fullWidth
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Cut-off Percentage"
              type="number"
              fullWidth
              value={cutOff}
              onChange={(e) => setCutOff(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
          </Grid>
          {/* <Grid item xs={12}>
          <Typography variant="h6">Instructions</Typography>
          <CKEditor
            editor={ClassicEditor}
            data={instructions}
            onChange={(event, editor) => {
              const data = editor.getData();
              setInstructions(data);
            }}
          />
        </Grid> */}
          <Grid item xs={12}>
            <Typography variant="h6">Difficulty Level</Typography>
            <RadioGroup
              row
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
            >
              <FormControlLabel
                value="easy"
                control={<Radio sx={{ '&.Mui-checked': { color: "#F06161" } }} />}
                label="Easy"
              />
              <FormControlLabel
                value="medium"
                control={<Radio sx={{ '&.Mui-checked': { color: "#F06161" } }} />}
                label="Medium"
              />
              <FormControlLabel
                value="hard"
                control={<Radio sx={{ '&.Mui-checked': { color: "#F06161" } }} />}
                label="Hard"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#F06161", color: "#fff" }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Snackbar  */}
      <UserSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
      />

    </div>

  );
};

export default GeneralSettings;
