import React, { useState, useEffect } from "react";
import axios from "axios";

// CSS import
import "../../company admin/CompanyAdmin.css";

//component imports
import CompanyMenu from "../../userProfiles/CompanyMenu";
import SendInvitation from "./SendInvitation";
import InvitationList from "./InvitationList";
import PageNotFound from "../../ErrorHandling/PageNotFound";
import Footer from "../../../Footer/Footer";

const RecruiterInvitation = () => {
    //Account Id and role
    const id = sessionStorage.getItem("id");
    const profileType = sessionStorage.getItem("profileType");
    const companyId = sessionStorage.getItem("companyId")
    //Navbar states
    const [open, setOpen] = useState();
    const [sidebarOpen, setsidebarOpen] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [interacted, setInteracted] = useState("");
    const [croppedImg, setCroppedImg] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [defaultAvatar, setDefaultAvatar] = useState("");

    const [pageName, setPageName] = useState("invitationList");

    const [hasInvitationAccess, setHasInvitationAccess] = useState(true)

    //setting document title
    useEffect(() => {
        document.title = "Screener | Invitations";
    }, []);
    //fetching data for navbar
    useEffect(() => {

        axios.get(`/api/admin/userRole/side-list/${companyId}`)
            .then(res => {
                console.log(res.data[0].profileType)
                let modules = res.data[0].profileType.filter(item => item.roleName.toLowerCase() === profileType.toLowerCase())
                console.log(modules)
                modules = modules[0].modules.filter(item => item.title === "Invitations")
                console.log(modules)
                if (!modules[0].checked) setHasInvitationAccess(false)
            })
            .catch(err => {
                console.log(err)
            })

        if (profileType.toLowerCase() == "recruiter") {
            const { data } = axios.get(`/api/recruiter/${id}`).then((res) => {
                const {
                    defaultAvatar,
                    profileImage,
                    croppedImage,
                    interacted,
                    firstName,
                } = res.data.user;
                setDefaultAvatar(defaultAvatar);
                setProfileImage(profileImage);
                setCroppedImg(croppedImage);
                setInteracted(interacted);
                setCompanyName(firstName);
            });
        } else {
            const { data } = axios.get(`/api/myUsers/${id}`).then((res) => {
                const {
                    defaultAvatar,
                    profileImage,
                    croppedImage,
                    interacted,
                    firstName,
                } = res.data.user;
                setDefaultAvatar(defaultAvatar);
                setProfileImage(profileImage);
                setCroppedImg(croppedImage);
                setInteracted(interacted);
                setCompanyName(firstName);
            });
        }
    }, []);

    return (
        <>
            {hasInvitationAccess ? <div>
                {/* Navbar Component */}
                <CompanyMenu
                    open={open}
                    setOpen={setOpen}
                    sidebarOpen={sidebarOpen}
                    setsidebarOpen={setsidebarOpen}
                    companyName={companyName}
                    croppedImg={croppedImg}
                    profileImage={profileImage}
                    interacted={interacted}
                    defaultAvatar={defaultAvatar}
                />

                {/* displaying component based on condition */}
                {pageName === "invitationList" ?
                    <InvitationList
                        sidebarOpen={sidebarOpen}
                        setsidebarOpen={setsidebarOpen}
                        setPageName={setPageName}
                        pageName={pageName}
                    />
                    : pageName === "sendInvitation" ?
                        <SendInvitation
                            sidebarOpen={sidebarOpen}
                            setPageName={setPageName} />
                        : ""}

                <Footer sidebarOpen={sidebarOpen} />
            </div> : <PageNotFound />}
        </>
    )
}

export default RecruiterInvitation