// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height */
    background-color: #f9f9f9; /* Light background color */
  }
  
  .error-image {
    max-width: 40%;
    height: 50%;
    margin-bottom: 20px;
  }
  
  .return-home {
    text-decoration: none;
    font-size: 18px;
    color: #f06161; /* Matches preferred button color */
    font-weight: bold;
  }
  
  .return-home:hover {
    color: #d04e4e; /* Slightly darker hover effect */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/candidate-assessment/Test-Attempted/TestAttempted.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,gBAAgB;IAC/B,yBAAyB,EAAE,2BAA2B;EACxD;;EAEA;IACE,cAAc;IACd,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,eAAe;IACf,cAAc,EAAE,mCAAmC;IACnD,iBAAiB;EACnB;;EAEA;IACE,cAAc,EAAE,iCAAiC;EACnD","sourcesContent":[".error-page {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; /* Full height */\n    background-color: #f9f9f9; /* Light background color */\n  }\n  \n  .error-image {\n    max-width: 40%;\n    height: 50%;\n    margin-bottom: 20px;\n  }\n  \n  .return-home {\n    text-decoration: none;\n    font-size: 18px;\n    color: #f06161; /* Matches preferred button color */\n    font-weight: bold;\n  }\n  \n  .return-home:hover {\n    color: #d04e4e; /* Slightly darker hover effect */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
