import React, { useState, useEffect } from "react";
import UserSnackbar from "../../../snackBars/UserSnackbar";

import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  Box,
  Radio,
  Tooltip,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
// import AddIcon from '@mui/icons-material/Add';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Subject as SubjectIcon,
  RadioButtonChecked as RadioButtonCheckedIcon,
  GraphicEq as GraphicEqIcon,
  Casino as CasinoIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SkillLibrary from "../Skill-Library/SkillLibrary";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import "./Problems.css";
import axios from "axios";

const Problems = ({
  testId,
  sections,
  setSections,
  isGeneralSettingsSaved,
  setIsGeneralSettingsSaved,
  onSaveComplete,
  isNewTest,
}) => {
  // const [sections, setSections] = useState([
  //   { id: Date.now(), name: "Verbal", questions: [] },
  // ]);
  const navigate = useNavigate(); // Hook for navigation
  const [currentSection, setCurrentSection] = useState(null);
  const [libraryOpen, setLibraryOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [addSectionOpen, setAddSectionOpen] = useState(false);
  const [newSectionName, setNewSectionName] = useState("");
  const [sectionSettings, setSectionSettings] = useState({
    randomizeProblems: false,
    shuffleProblems: false,
    customScoring: false,
  });
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questionDetailsOpen, setQuestionDetailsOpen] = useState(false);
  const [openDetails, setOpenDetails] = useState({}); // To track which questions have their details open
  const [expandedSections, setExpandedSections] = useState({}); // To track which sections are expanded
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Toggle section expand/collapse
  const toggleExpandSection = (sectionId) => {
    setExpandedSections((prevState) => ({
      ...Object.keys(prevState).reduce((acc, id) => {
        acc[id] = false; // Collapse all other sections
        return acc;
      }, {}),
      [sectionId]: !prevState[sectionId],
    }));
  };

  const addSection = () => {
    setAddSectionOpen(true);
  };

  // const handleAddSection = async () => {
  //   if (newSectionName) {
  //     const sectionData = await axios.put(
  //       `/api/admin/skill-assessment/test-management/${testId}/add-section`,
  //       {
  //         sectionName: newSectionName,
  //       }
  //     );
  //     console.log(
  //       sectionData.data.section.id,
  //       sectionData.data.section.sectionName,
  //       sectionData.data.section.problems
  //     );

  //     // setSections([
  //     //   ...sections,
  //     //   { id: sectionData.data.id, name: newSectionName, questions: [] },
  //     // ]);
  //     setSections([
  //       ...sections,
  //       {
  //         id: sectionData.data.section.id,
  //         name: sectionData.data.section.sectionName,
  //         questions: sectionData.data.section.problems,
  //       },
  //     ]);
  //     setNewSectionName("");
  //     setAddSectionOpen(false);
  //   }
  // };

  const handleAddSection = async () => {
    if (!newSectionName.trim()) return; // Prevent empty section names

    // Check if the section already exists
    const sectionExists = sections.some(
      (section) => section.name.toLowerCase() === newSectionName.toLowerCase()
    );

    if (sectionExists) {
      setAddSectionOpen(false);
      setSnackbarMessage("Section already exists");
      setSnackbarOpen(true);
      return;
    }

    try {
      const sectionData = await axios.put(
        `/api/admin/skill-assessment/test-management/${testId}/add-section`,
        { sectionName: newSectionName }
      );

      setSections([
        ...sections,
        {
          id: sectionData.data.section.id,
          name: sectionData.data.section.sectionName,
          questions: sectionData.data.section.problems,
        },
      ]);

      setNewSectionName("");
      setAddSectionOpen(false);
    } catch (error) {
      console.error("Error adding section:", error);
      setSnackbarMessage("Failed to add section");
      setSnackbarOpen(true);
    }
  };

  const handleDeleteSection = async (sectionId) => {
    try {
      const response = await axios.delete(
        `/api/admin/skill-assessment/test-management/${testId}/delete-section/${sectionId}`
      );

      if (response.status === 200) {
        // Remove the section from the frontend state
        setSections(sections.filter((section) => section.id !== sectionId));

        // Show snackbar with success message
        setSnackbarMessage("Section deleted successfully");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error deleting section:", error);
      setSnackbarMessage("Failed to delete section");
      setSnackbarOpen(true);
    }
  };

  const openSettings = (section) => {
    console.log("settings section", section);

    setCurrentSection(section);
    setSettingsOpen(true);
  };

  const openLibrary = (section) => {
    setCurrentSection(section);
    setLibraryOpen(true);
  };

  const handleAddQuestionToSection = async (question) => {
    try {
      const isQuestionAlreadyAdded = sections
        .find((section) => section.id === currentSection.id)
        ?.questions.some((q) => q._id === question._id);

      if (isQuestionAlreadyAdded) {
        setLibraryOpen(false);
        setSnackbarMessage("Question already exists in this section");
        setSnackbarOpen(true);
        return;
      }

      //add the question to the problems array into the  section  of the test collection
      const questionData = await axios.put(
        `/api/admin/skill-assessment/test-management/${testId}/add-question-to-section`,
        { sectionId: currentSection.id, question }
      );

      if (questionData) {
        setSections((prevSections) =>
          prevSections.map((section) =>
            section.id === currentSection.id
              ? {
                  ...section,
                  questions: [...section.questions, questionData.data.question],
                }
              : section
          )
        );

        setLibraryOpen(false);
        setOpenDetails((prevDetails) => ({
          ...prevDetails,
          [questionData.data.question.text]: true, // Open details for the newly added question
        }));
      }
    } catch (error) {
      console.log("error in frontend ", error);
    }
  };

  const handleSettingsChange = (setting) => {
    setSectionSettings({
      ...sectionSettings,
      [setting]: !sectionSettings[setting],
    });
  };

  const handleSectionNameChange = (name) => {
    if (currentSection) {
      setCurrentSection({ ...currentSection, name });
    }
  };

  const saveSectionName = async () => {
    try {
      if (currentSection) {
        console.log("log of current section", currentSection);

        const updatedSectionName = await axios.put(
          `/api/admin/skill-assessment/test-management/${testId}/update-sectionName`,
          { sectionId: currentSection.id, newSectionName: currentSection.name }
        );
        console.log("updates section name : ", updatedSectionName);

        if (updatedSectionName.data.message) {
          setSections(
            sections.map((section) =>
              section.id === currentSection.id
                ? { ...section, name: currentSection.name }
                : section
            )
          );
          setSettingsOpen(false);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (selectedQuestion) {
        console.log("Updated Selected Question:", selectedQuestion);
    }
}, [selectedQuestion]);


  const openQuestionDetails = (question) => {
    console.log("Clicked Question Data:", question); // Log the question object
    setSelectedQuestion(question);
    setQuestionDetailsOpen(true);
    
    
  };

  console.log("sections here ", sections);
  

  const handleDeleteQuestion = async (sectionName, problemId) => {
    try {
      console.log("problem Id", sectionName, problemId);
      // Send delete request to backend
      const response = await axios.delete(
        `/api/admin/skill-assessment/test-management/${testId}/delete-questions-of-section/${sectionName}/problems/${problemId}`
      );

      if (response.status === 200) {
        // Update the state by removing the problem from the correct section
        setSections((prevSections) =>
          prevSections.map((section) =>
            section.name === sectionName
              ? {
                  ...section,
                  questions: section.questions.filter(
                    (question) => question._id !== problemId
                  ),
                }
              : section
          )
        );

        // Show snackbar for success
        setSnackbarMessage("Question removed successfully");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error removing question:", error);
      setSnackbarMessage("Failed to remove question");
      setSnackbarOpen(true);
    }
  };

  const handleBackClick = () => {
    window.location.href = "/admin/skill-assessment/test-management";
  };

  return (
    <>
      <div className="back-button-container">
        <IconButton onClick={handleBackClick}>
          <ArrowBackIcon sx={{ color: "#F06161" }} />
        </IconButton>
      </div>
      <div className="problems-container">
        {sections.map((section) => (
          <div key={section.id} className="section-card">
            <div className="section-header">
              <Typography variant="h6">{section.name}</Typography>
              <div className="section-actions">
                <IconButton onClick={() => openSettings(section)}>
                  <Tooltip title="Edit Settings">
                    <SettingsIcon sx={{ color: "#F06161" }} />
                  </Tooltip>
                </IconButton>

                <IconButton onClick={() => openLibrary(section)}>
                  <Tooltip title="Add Questions">
                    <AddIcon sx={{ color: "#F06161" }} />
                  </Tooltip>
                </IconButton>

                <IconButton onClick={() => handleDeleteSection(section.id)}>
                  <Tooltip title="Delete Section">
                    <DeleteIcon sx={{ color: "#F06161" }} />
                  </Tooltip>
                </IconButton>
                <IconButton onClick={() => toggleExpandSection(section.id)}>
                  {expandedSections[section.id] ? (
                    <Tooltip title="Collapse">
                      <ExpandLessIcon sx={{ color: "#F06161" }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Expand">
                      <ExpandMoreIcon sx={{ color: "#F06161" }} />
                    </Tooltip>
                  )}
                </IconButton>
              </div>
            </div>
            {expandedSections[section.id] && (
              <div className="questions-list">
                {section.questions?.map((question, index) => (
                  <div
                    key={`${section.id}-${index}`}
                    className="question-item1"
                    onClick={() => openQuestionDetails(question)}
                  >
                    {/* Place the X icon in the top right corner */}
                    <div style={{ position: "relative" }}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents the event from propagating to parent click events
                          handleDeleteQuestion(section.name, question._id);
                        }}
                        sx={{
                          position: "absolute",
                          top: -19,
                          right: 5,
                          // color: "#F06161",
                          padding: 0,
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </div>
                    {/* <Typography className="question-text">
                      {question.text}
                    </Typography> */}
                    <Typography
                      variant="h6"
                      className="question-text-library"
                      style={{
                        whiteSpace: "pre-wrap",
                        fontFamily:
                          question.type === "Coding" ? "monospace" : "inherit",
                        backgroundColor:
                          question.type === "Coding" ? "#f5f5f5" : "inherit",
                        padding: question.type === "Coding" ? "8px" : "0",
                        borderRadius: question.type === "Coding" ? "4px" : "0",
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: question.text }}
                      />
                    </Typography>

                    {/* Icons and Question Details Below Text */}
                    <div className="question-details">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <SubjectIcon style={{ fontSize: "1rem" }} />{" "}
                        {question.type}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <RadioButtonCheckedIcon style={{ fontSize: "1rem" }} />{" "}
                        {question.correctAnswer ? "Single" : "Multiple"}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <GraphicEqIcon style={{ fontSize: "1rem" }} />{" "}
                        {question.difficulty}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <CasinoIcon style={{ fontSize: "1rem" }} />{" "}
                        {question.points} points
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        <Button
          variant="contained"
          onClick={addSection}
          className="add-section-button"
        >
          Add Section
        </Button>

        {/* Settings Dialog */}
        <Dialog open={settingsOpen} onClose={() => setSettingsOpen(false)}>
          <DialogTitle>Edit Section Settings</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Section Name"
              variant="outlined"
              value={currentSection?.name || ""}
              onChange={(e) => handleSectionNameChange(e.target.value)}
              sx={{
                marginBottom: "16px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sectionSettings.randomizeProblems}
                  onChange={() => handleSettingsChange("randomizeProblems")}
                />
              }
              label="Problem randomization"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sectionSettings.shuffleProblems}
                  onChange={() => handleSettingsChange("shuffleProblems")}
                />
              }
              label="Enable problem shuffle"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sectionSettings.customScoring}
                  onChange={() => handleSettingsChange("customScoring")}
                />
              }
              label="Enable custom scoring"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setSettingsOpen(false)}
              style={{ color: "#F06161" }}
            >
              Cancel
            </Button>
            <Button
              onClick={saveSectionName}
              style={{ backgroundColor: "#F06161", color: "#fff" }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add Section Dialog */}
        <Dialog open={addSectionOpen} onClose={() => setAddSectionOpen(false)}>
          <DialogTitle>Add New Section</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="New Section Name"
              variant="outlined"
              value={newSectionName}
              onChange={(e) => setNewSectionName(e.target.value)}
              sx={{
                marginBottom: "16px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setAddSectionOpen(false)}
              style={{ color: "#F06161" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddSection}
              style={{ backgroundColor: "#F06161", color: "#fff" }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>

        {/* Library Dialog */}
        <Dialog open={libraryOpen} onClose={() => setLibraryOpen(false)}>
          {/* <DialogTitle>Select Questions</DialogTitle> */}
          <DialogContent>
            <SkillLibrary
              onSelectQuestion={handleAddQuestionToSection}
              showAddQuestionButton={false}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => setLibraryOpen(false)}
              style={{ color: "#F06161" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* Question Details Dialog */}

        <Dialog
          open={questionDetailsOpen}
          onClose={() => setQuestionDetailsOpen(false)}
        >
          {/* <DialogTitle>Question Details</DialogTitle> */}
          <DialogContent>
            {selectedQuestion && (
              <div>
                {/* <Typography variant="h6">{selectedQuestion.text}</Typography> */}
                {/* <Typography
                  variant="h6"
                  style={{
                    whiteSpace: "pre-wrap",
                    fontFamily:
                      selectedQuestion.type === "Coding"
                        ? "monospace"
                        : "inherit",
                    backgroundColor:
                      selectedQuestion.type === "Coding"
                        ? "#f5f5f5"
                        : "inherit",
                    padding: selectedQuestion.type === "Coding" ? "8px" : "0",
                    borderRadius:
                      selectedQuestion.type === "Coding" ? "4px" : "0",
                  }}
                >
                  {selectedQuestion.text}
                </Typography> */}

                <Typography
                  variant="h6"
                  className="question-text-library"
                  style={{
                    whiteSpace: "pre-wrap",
                    fontFamily:
                      selectedQuestion.type === "Coding"
                        ? "monospace"
                        : "inherit",
                    backgroundColor:
                      selectedQuestion.type === "Coding"
                        ? "#f5f5f5"
                        : "inherit",
                    padding: selectedQuestion.type === "Coding" ? "8px" : "0",
                    borderRadius:
                      selectedQuestion.type === "Coding" ? "4px" : "0",
                  }}
                  dangerouslySetInnerHTML={{ __html: selectedQuestion.text }}
                />

                {/* Display question image if available */}
                {selectedQuestion.image && (
                  <img
                    src={selectedQuestion.image}
                    alt="Question"
                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: "8px",
                      marginTop: "10px",
                      boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                    }}
                  />
                )}

                <div className="question-details">
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <SubjectIcon style={{ fontSize: "1rem" }} />{" "}
                    {selectedQuestion.type}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <RadioButtonCheckedIcon style={{ fontSize: "1rem" }} />{" "}
                    {selectedQuestion.correctAnswer ? "Single" : "Multiple"}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <GraphicEqIcon style={{ fontSize: "1rem" }} />{" "}
                    {selectedQuestion.difficulty}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <CasinoIcon style={{ fontSize: "1rem" }} />{" "}
                    {selectedQuestion.points} points
                  </Typography>
                </div>

                {selectedQuestion.type === "MCQ" && (
                  <Box mt={2}>
                    {selectedQuestion.options.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Radio
                            checked={option === selectedQuestion.correctAnswer}
                            //   disabled
                            sx={{
                              "& .MuiSvgIcon-root": {
                                color:
                                  option === selectedQuestion.correctAnswer
                                    ? "#F06161"
                                    : undefined,
                              },
                              "& .MuiRadio-checked": {
                                color: "#F06161",
                              },
                            }}
                          />
                        }
                        label={option}
                        sx={{
                          display: "block", // Ensure labels are displayed vertically
                          marginBottom: "8px", // Add space between options
                        }}
                      />
                    ))}
                  </Box>
                )}

                {selectedQuestion.type === "Coding" && (
                  <Typography mt={2}>{selectedQuestion.code}</Typography>
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setQuestionDetailsOpen(false)}
              style={{ color: "#F06161" }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <UserSnackbar
          openSnackbar={snackbarOpen}
          setOpenSnackbar={setSnackbarOpen}
          message={snackbarMessage}
        />
      </div>
    </>
  );
};

export default Problems;
