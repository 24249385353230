import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, Cell } from 'recharts';

const CustomBarChart = ({ internalDB }) => {
    console.log("Internal db",internalDB)
    const data = internalDB;

    // Define a color mapping based on type
    const colors = {
        "Screeners": "#f06161",
        "Job Seekers": "#3091A2",
        "Converted Users": "#505253"
        
    };

    // Custom tooltip content to control the display format
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { type, value } = payload[0].payload;
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
                    <p className="label">{`${type} : ${value}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="type">
                    <Label value="Internal Users" offset={-15} position="insideBottom" fontSize={14}/>
                </XAxis>
                <YAxis tickCount={7} interval="preserveStart">
                    <Label value="No. of Users" angle={-90} position="insideLeft" fontSize={14}/>
                </YAxis>
                <Tooltip content={<CustomTooltip />} />
                
                {/* Single Bar element with custom colors */}
                <Bar dataKey="value" barSize={180}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[entry.type]} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomBarChart;
