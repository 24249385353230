import React, { useRef, useState } from "react";
import axios from "axios";

const FaceDetection = () => {
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const [recording, setRecording] = useState(false);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true, // Enable audio
      });

      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.muted = true; // Mute the video to avoid feedback
      }

      let chunks = [];
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(chunks, { type: "video/webm" });
        await uploadToBackend(blob);
      };

      mediaRecorderRef.current.start();
      setRecording(true);
      console.log("Recording started...");
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      console.log("Recording stopped...");
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  const uploadToBackend = async (blob) => {
    try {
      const formData = new FormData();
      formData.append("video", blob, "recorded_video.webm");

      const { data } = await axios.post("/api/video/upload-to-drive", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("Upload success:", data);
    } catch (error) {
      console.error("Error uploading video:", error);
    }
  };

  return (
    <div>
      {/* <video ref={videoRef} autoPlay playsInline width="640" height="480" /> */}
      <div>
        {!recording ? (
          <button onClick={startRecording}>Start Recording</button>
        ) : (
          <button onClick={stopRecording}>Stop Recording</button>
        )}
      </div>
    </div>
  );
};

export default FaceDetection;
