import React, { useState, useEffect, useCallback } from "react";
import "./Profile.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import EducationIcon from "@mui/icons-material/SchoolOutlined";
import ExperienceIcon from "@mui/icons-material/WorkOutlineOutlined";
import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import EditIcon from "@mui/icons-material/CreateOutlined";
import AddIcon from "@mui/icons-material/Add";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Phone from "@mui/icons-material/LocalPhoneOutlined";
import Dollar from "@mui/icons-material/AttachMoneyOutlined";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListIcon from "@mui/icons-material/FiberManualRecord";
import profileImg from "./profileImage/demo-profile-img.png";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route, Link,useLocation } from "react-router-dom";

const DemoProfile = () => {
  const location = useLocation();
  // const { id } = location.state.payload;

  document.body.style.overflow = "hidden";

  useEffect(() => {
    document.title = "Screener | Demo Profile";
  }, []);

  return (
    <div
      className="root-div"
      id="restrict-scroll"
      style={{ overflowY: "hidden" }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Card id="main-edit" className="main-card profile-card">
          {/* 1st Grid container */}
          <Grid
            container
            spacing={0}
            direction="row"
            className="profile-card-container"
          >
            <div className="edit-icon-mobile-container">
              <EditIcon className="edit-icon-mobile" />
            </div>
            <Grid
              item
              xs={12}
              sm={12}
              md={2.5}
              lg={2}
              xl={1.5}
              className="container-profile"
            >
              <img src={profileImg} style={{ objectFit: "cover" }} />

              <div className="social-profile-card-container">
                <a href="#" className="social">
                  <i className="fab fa-google-plus-g"></i>
                </a>

                <a href="#" className="social">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href="#" className="social">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7.2}
              xl={7.5}
              className="container-heading-title"
            >
              <div>
                <h2>John Smith</h2>
                <h5>Software Developer</h5>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2.5}
              lg={2.5}
              xl={2.5}
              className="container-heading-buttons"
            >
              <div>
                <button className="view-resume">
                  <Link to="#">
                    <EyeIcon className="eye-icon" />
                    Resume
                  </Link>
                </button>
              </div>

              <div>
                <EditIcon id="main-edit" className="edit-icon" />
              </div>
            </Grid>
          </Grid>

          {/* 2nd Grid container */}
          <Grid
            container
            spacing={0}
            direction="row"
            className="info-container"
            justifyContent="flex-end"
          >
            <Grid
              item
              xs={0}
              sm={0}
              md={0}
              lg={1.8}
              xl={1.2}
              className="container-profile"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4.8}
              lg={3}
              xl={3.1}
              className="about-container"
            >
              <h4>About</h4>
              <p>Tell us about yourself</p>
            </Grid>

            <Grid
              item
              xs={12}
              sm={5.5}
              md={3}
              lg={3}
              xl={3}
              className="info-container1"
            >
              <div className="info-container-height">
                <div className="info-container1-heading">
                  <MailOutlineIcon className="icon" />
                  Email
                </div>
                <p>johnsmith@example.com</p>
              </div>

              <div className="info-container-height">
                <div className="info-container1-heading">
                  <LocationIcon className="icon" />
                  Current Location
                </div>
                <p>
                  Redmond, Washington <br /> United States
                </p>
              </div>

              <div className="info-container-height">
                <div className="info-container1-heading">
                  <ExperienceIcon className="icon" />
                  Total Experience
                </div>

                <p>2 years 5 months</p>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={5.5}
              md={3}
              lg={3}
              xl={3}
              className="info-container2"
            >
              <div className="info-container-height">
                <div className="info-container1-heading">
                  <Phone className="icon" />
                  Phone
                </div>
                <p>+1 123456789</p>
              </div>
              <div className="info-container-height">
                <div className="info-container1-heading">
                  {" "}
                  <EducationIcon className="icon" />
                  Highest Education
                </div>
                <p>
                  Master's in Computer Science <br />
                  Stanford University
                </p>
              </div>

              <div className="info-container-height">
                <div className="info-container1-heading">
                  <Dollar className="icon" />
                  Expected Salary (USD)
                </div>
                <p>$5000 - $10000</p>
              </div>
            </Grid>
          </Grid>
          {/* 3rd container */}
          <Grid
            container
            spacing={1}
            direction="row"
            id="profile-completion-percentage"
            className="profile-progress-bar"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4.5}
              lg={5.8}
              xl={5}
              sx={{ textAlign: "center" }}
              className="container-profile"
            >
              <div id="profile-percentage">
                <div className="profile-progress-bar-heading">
                  Percentage of Profile Completion
                </div>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      className="profile-progress-bar-element"
                      value={100}
                      max={100}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                      100%
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Card>
        <div style={{ position: "relative" }}>
          <div className="last-tour"></div>
        </div>

        <div className="cards-list">
          <Grid className="card-tiles" xs={12}>
            <Card id="skills-edit" className="side-card profile-card">
              {/* 1st Grid container */}
              <Grid
                container
                spacing={1}
                direction="row"
                className="skill-card sub-card"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="skill-card-title"
                >
                  <div className="skill-card-heading">
                    <p>Skills</p>
                  </div>

                  <div className="skill-card-editIcon">
                    <EditIcon className="edit-icon" />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="skills-button"
                >
                  <button className="filled-button">Java</button>
                  <button className="unfilled-button">C++</button>
                  <button className="filled-button">React Js</button>
                  <button className="unfilled-button">Node Js</button>
                </Grid>
              </Grid>
            </Card>
            <Card id="experience-edit" className="middle-card profile-card">
              <Grid
                container
                spacing={1}
                direction="row"
                className="experience-card sub-card"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="experience-card-title"
                >
                  <div className="experience-card-heading">
                    <p>Experience</p>
                  </div>

                  <div className="experience-card-editIcon">
                    <AddIcon className="edit-icon" />
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="experience-list"
                >
                  <List>
                    <div className="experience-list-edit-icon">
                      <ListItem
                        alignItems="flex-start"
                        className="experience-list-items"
                      >
                        <ListItemAvatar className="experience-list-icon">
                          <ListIcon />
                        </ListItemAvatar>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <ListItemText
                              className="list-items"
                              primary="Senior Software Developer"
                              secondary={
                                <React.Fragment>
                                  <div className="job-details">
                                    <div className="company-name">
                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        Microsoft
                                      </Typography>
                                    </div>
                                    <div className="experience-details">
                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        January 2020 - November 2021
                                      </Typography>
                                      <br />

                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        1 year 10 months
                                      </Typography>
                                    </div>
                                  </div>
                                </React.Fragment>
                              }
                            />
                          </div>
                        </div>
                        <EditIcon className="edit-icon-experience" />
                      </ListItem>
                    </div>
                    <Divider
                      variant="inset"
                      component="li"
                      className="list-divider"
                    />
                    <div className="experience-list-edit-icon">
                      <ListItem
                        alignItems="flex-start"
                        className="experience-list-items"
                      >
                        <ListItemAvatar className="experience-list-icon">
                          <ListIcon />
                        </ListItemAvatar>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <ListItemText
                              className="list-items"
                              primary="Software Developer"
                              secondary={
                                <React.Fragment>
                                  <div className="job-details">
                                    <div className="company-name">
                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        Infosys
                                      </Typography>
                                    </div>
                                    <div className="experience-details">
                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        August 2018 - December 2019
                                      </Typography>
                                      <br />

                                      <Typography
                                        // sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        1 year 3 months
                                      </Typography>
                                    </div>
                                  </div>
                                </React.Fragment>
                              }
                            />
                          </div>
                        </div>
                        <EditIcon className="edit-icon-experience" />
                      </ListItem>
                    </div>
                  </List>
                </Grid>
              </Grid>
            </Card>

            <Card id="education-edit" className="middle-card profile-card">
              <Grid
                container
                spacing={1}
                direction="row"
                className="education-card sub-card"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="education-card-title"
                >
                  <div className="education-card-heading">
                    <p>Education</p>
                  </div>

                  <div className="education-card-editIcon">
                    <AddIcon className="edit-icon" />
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="education-list"
                >
                  <List>
                    <div className="education-list-edit-icon">
                      <ListItem
                        alignItems="flex-start"
                        className="education-list-items"
                      >
                        <ListItemAvatar className="education-list-icon">
                          <ListIcon />
                        </ListItemAvatar>
                        <div style={{ display: "flex" }}>
                          <ListItemText
                            className="list-items"
                            primary="Master's in Computer Science"
                            secondary={
                              <React.Fragment>
                                <div className="job-details">
                                  <Typography
                                    // sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Stanford University
                                  </Typography>

                                  <div className="education-details">
                                    <Typography
                                      // sx={{ display: 'inline' }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      August 2019 - June 2022
                                    </Typography>
                                  </div>
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                      </ListItem>
                      <EditIcon className="edit-icon-experience" />
                    </div>
                    <Divider
                      variant="inset"
                      component="li"
                      className="list-divider"
                    />
                    <div className="education-list-edit-icon">
                      <ListItem
                        alignItems="flex-start"
                        className="education-list-items"
                      >
                        <ListItemAvatar className="education-list-icon">
                          <ListIcon />
                        </ListItemAvatar>
                        <div style={{ display: "flex" }}>
                          <ListItemText
                            className="list-items"
                            primary="Bachelor's in Computer Science and Engineering"
                            secondary={
                              <React.Fragment>
                                <div className="job-details">
                                  <Typography
                                    // sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    Boston University
                                  </Typography>

                                  <div className="education-details">
                                    <Typography
                                      // sx={{ display: 'inline' }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      August 2015 - June 2019
                                    </Typography>
                                  </div>
                                </div>
                              </React.Fragment>
                            }
                          />
                        </div>
                      </ListItem>
                      <EditIcon className="edit-icon-experience" />
                    </div>
                  </List>
                </Grid>
              </Grid>
            </Card>

            <Card id="personal-details-edit" className="side-card profile-card">
              {/* 1st Grid container */}
              <Grid
                container
                spacing={1}
                direction="row"
                className="personal-card sub-card"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="personal-card-title"
                >
                  <div className="personal-card-heading">
                    <p>Personal Details</p>
                  </div>

                  <div className="personal-card-editIcon">
                    <EditIcon className="edit-icon" />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="personal-details"
                >
                  <div className="details-block">
                    <div className="personal-info">
                      <p className="personal-info-heading">
                        Birth Date: &nbsp;
                      </p>
                      <p className="personal-info-content">
                        September 13th, 1996
                      </p>
                    </div>

                    <div className="personal-info">
                      <p className="personal-info-heading">Gender: &nbsp;</p>
                      <p className="personal-info-content">Male</p>
                    </div>

                    <div className="personal-info">
                      <p className="personal-info-heading">
                        Nationality: &nbsp;
                      </p>
                      <p className="personal-info-content">American</p>
                    </div>

                    <div className="personal-info">
                      <p className="personal-info-heading">
                        Marital status: &nbsp;{" "}
                      </p>
                      <p className="personal-info-content">Unmarried</p>
                    </div>

                    <div className="personal-info">
                      <p className="personal-info-heading">Address: &nbsp; </p>
                      <p className="personal-info-content">
                        132, My street, Kingston, New York <br />
                        12401
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </div>
      </Box>
    </div>
  );
};

export default DemoProfile;
