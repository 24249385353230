import { jwtDecode as jwt_decode } from "jwt-decode";
import React from "react";
import ReactDOM from "react-dom/client";  // Updated import for React 18
import { logoutUser, setCurrentUser } from "./actions/authActions";
import Root from "./components/Root";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
// import "intro.js/introjs.css";

// Initialize the root for React 18
const root = ReactDOM.createRoot(document.getElementById("root"));

// Check for token to keep user logged in
if (sessionStorage.jwtToken) {
  // Set auth token header auth
  const token = sessionStorage.jwtToken;
  setAuthToken(token);
  
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in seconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/";
  }
}

// Expose store methods for browser console testing
window.getState = store.getState;
window.dispatch = store.dispatch;

// Render the Root component
root.render(<Root store={store} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
