// Importing React and other libraries
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Button from "@mui/material/Button";  // For MUI Buttons
import CssBaseline from "@mui/material/CssBaseline";  // For CSS Baseline
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";  // For MUI Theme Provider
import "bootstrap/dist/css/bootstrap.min.css";  // For Bootstrap styles
import { SnackbarProvider } from "notistack";  // For Snackbars
import { ConfirmProvider } from "material-ui-confirm";  // For Confirmation Dialogs

// Importing your custom theme and components
import theme from "../theme";
import App from "./App";

// Main component
export default ({ store }) => {
    const notistackRef = React.createRef();

    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <MuiThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                preventDuplicate
                ref={notistackRef}
                action={(key) => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}
            >
                <ConfirmProvider>
                    <Provider store={store}>
                        <CssBaseline />
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </Provider>
                </ConfirmProvider>
            </SnackbarProvider>
        </MuiThemeProvider>
    );
};
