import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Highlighter from "react-highlight-words";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { v4 as uuidv4 } from 'uuid';

// mui imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Badge from '@mui/material/Badge';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CircularProgress, TextField, Autocomplete } from '@mui/material';

// icons import
import SalaryIcon from "@mui/icons-material/AccountBalanceWallet";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ViewIcon from "@mui/icons-material/Visibility";
import ResumeIcon from "@mui/icons-material/Description";
import ShareIcon from "@mui/icons-material/Share";
import EmailIcon from "@mui/icons-material/Email";
import NoteAddIcon from '@mui/icons-material/PostAdd';
import ViewNoteIcon from '@mui/icons-material/Comment';
import ExperienceIcon from "@mui/icons-material/Work";
import LocationIcon from "@mui/icons-material/LocationOn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenArrow from "@mui/icons-material/ChevronLeft";
import CloseArrow from "@mui/icons-material/ChevronRight";
import EventIcon from "@mui/icons-material/Event";
import AddIcon from "@mui/icons-material/Add";
import LinkIcon from '@mui/icons-material/Link';

import Zoom from "../images/zoom.svg";
import CompanyMenu from "../../userProfiles/CompanyMenu";
import TableLoader from "../../loaders/TableLoader";
import UserSnackbar from "../../snackBars/UserSnackbar";
import { timezone } from "../../../data-files/timezone";
import Footer from "../../../Footer/Footer";
import ShareProfileModal from "../Internal Users/Modals/ShareProfileModal";
import NothingToShow from '../images/nothingtoshow.svg';
import AddNoteModal from "../Internal Users/Modals/AddNoteModal";
import ViewNotes from "../Internal Users/Modals/ViewNotes";
import RemoveFavoritesDialog from "./RemoveFavoritesDialog";
import CancelInterviewDialog from "./CancelInterviewDialog";

const JITSI_URL = 'https://meet.jit.si';

//MenuItem actions
const actionOptions = [
  {
    icon: <ShareIcon fontSize="1rem" />,
    name: "Share",
  },
  {
    icon: <EmailIcon fontSize="1rem" />,
    name: "Send Mail",
  },
  {
    icon: <NoteAddIcon fontSize="1rem" />,
    name: "Add Note"
  },
  {
    icon: <CloseIcon fontSize="1rem" />,
    name: "Remove Favorites",
  },
  {
    icon: <ViewIcon fontSize="1rem" />,
    name: "View Profile",
  },
  {
    icon: <ResumeIcon fontSize="1rem" />,
    name: "Preview Resume",
  },
  {
    icon: <ScheduleIcon fontSize="1rem" />,
    name: "Schedule Interview",
  }
];

const FavoritesList = () => {

  //time details
  const hoursArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
  const minutesArray = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']
  const meridianArray = ['AM', "PM"]

  const navigate = useNavigate();

  //table headers
  const columns = [
    {
      title: "Name",
      width: "26%",
    },
    {
      title: "Title",
      width: "18%",
    },
    {
      title: "Total Experience",
      width: "20%",
    },
    {
      title: "Expected Salary",
      width: "18%",
    },
    {
      title: "Email Id",
      width: "28%",
    },
    {
      title: "Phone Number",
      width: "18%",
    },
    {
      title: "Skills",
      width: "30%",
    },
    {
      title: "Current Location",
      width: "20%",
    },
    {
      title: "Highest Education",
      width: "20%",
    },
    {
      title: "DOB",
      width: "15%",
    },
    {
      title: "Gender",
      width: "10%",
    },
    {
      title: "Nationality",
      width: "10%",
    },
    {
      title: "Status",
      width: "10%"
    },
    {
      title: "Actions",
      width: "7%",
    },
  ];

  //Session storage values
  const id = sessionStorage.getItem("id");
  const companyId = sessionStorage.getItem("companyId");
  const role = sessionStorage.getItem("role");
  const token = sessionStorage.getItem("jwtToken");

  //Refs
  const meetLinkRef = useRef(null);
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(0);

  //Navbar states
  const [open, setOpen] = useState();
  const [sidebarOpen, setsidebarOpen] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [interacted, setInteracted] = useState("");
  const [croppedImg, setCroppedImg] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [defaultAvatar, setDefaultAvatar] = useState("");
  //loader
  const [loading, setLoading] = useState(true);

  //snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  //pagination states
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  // scheduler States
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false || localStorage.getItem('rightSidebarOpen'));
  const [scheduleClicked, setScheduleClicked] = useState(false);

  const mdValue = rightSidebarOpen ? 6.5 : 5.7;
  const lgValue = rightSidebarOpen ? 6.5 : 5.7;

  //List states
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
  const [allUsers, setAllUsers] = useState([]);

  //filter States
  const [nameFilter, setNameFilter] = useState("");
  const [titleFilter, setTitleFilter] = useState("");
  const [experienceFilter, setExperienceFilter] = useState("");
  const [salaryFilter, setSalaryFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [skillsFilter, setSkillsFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [educationFilter, setEducationFilter] = useState("");
  const [dobFilter, setDobFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [nationalityFilter, setNationalityFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState(localStorage.getItem("role") || "candidate");
  const [viewFilter, setViewFilter] = useState("simpleView");
  const [filter, setFilter] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");

  const [filterArray, setFilterArray] = useState([]);

  //selected profile states
  const [selectedItem, setSelectedItem] = useState();

  //Action dropdown
  const [actionMenu, setActionMenu] = useState(null);
  const openActionMenu = Boolean(actionMenu);

  //Modal states
  const [selectedRole, setSelectedRole] = useState(true); // candidate role selected
  const [selectedView, setSelectedView] = useState(true); //simple view selected

  //Dialog box states
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  //quick scheduler states
  const [zone, setZone] = useState("Asia/Kolkata");
  const [interviewLink, setInterviewLink] = useState("");
  const [customLink, setCustomLink] = useState(false)
  const [meetingPlatform, setMeetingPlatform] = useState("")
  const [time, setTime] = useState("12:15 PM");
  const [date, setDate] = useState("");
  const [interviewTitle, setInterviewTitle] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [screenerName, setScreenerName] = useState("");
  const [candidateDetails, setCandidateDetails] = useState({
    name: '',
    id: "",
    email: ''
  });
  const [screenerDetails, setScreenerDetails] = useState({
    name: '',
    id: "",
    email: ''
  });
  const [linkLoader, setLinkLoader] = useState(false);

  const [clientsList, setClientsList] = useState([])
  const [positionsList, setPositionsList] = useState([])
  //client states
  const [clientName, setClientName] = useState("")
  const [clientDetails, setClientDetails] = useState({ id: '', name: '', email: '' })

  //position states
  const [positionName, setPositionName] = useState("")
  const [positionDetails, setPositionDetails] = useState({ id: '', name: '' })

  //useEffect handlers

  let [customHeight, setCustomHeight] = useState("")
  const [replyToEmail, setReplyToEmail] = useState("")

  //share profile states
  const [profilePublicLink, setProfilePublicLink] = useState("")
  const [includeResume, setIncludeResume] = useState(false)

  //share profile modal states
  const [openShareModal, setOpenShareModal] = useState(false)

  // add note modal state
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openViewNoteModal, setOpenViewNoteModal] = useState(false);
  const [viewNoteUser, setViewNoteUser] = useState();
  const [unreadNotesCount, setUnreadNotesCount] = useState();

  const [hours, setHours] = useState('12');
  const [meridian, setMeridian] = useState('PM')
  const [minutes, setMinutes] = useState('15')
  //filter States


  function timeConvertor(interviewTime) {
    let timeArray = interviewTime.split(':')
    const hour = timeArray[0];
    setHours(hour)
    const min = timeArray[1].slice(0, 2);
    setMinutes(min)
    const mer = timeArray[1].slice(3);
    setMeridian(mer)
  }

  useEffect(() => {
    const localTime = localStorage.getItem('time');
    if (localTime) {
      timeConvertor(localTime)
    }
    if (screenerName == "" && candidateName == "") {
      setRightSidebarOpen(null)
    }
  }, [])

  const shareHandler = (userId, item) => {
    setSelectedItem(item)
    setProfilePublicLink(`https://screener.infomaticscorp.com/profile/share/${roleFilter}/${userId}/${id}`)
    setOpenShareModal(true)
  }

  // Add note handler for each user
  const noteHandler = (item) => {
    setSelectedItem(item)
    setOpenNoteModal(true)
  }

  // View note handler
  const viewNoteHandler = async (user) => {
    axios.post(`/api/admin/note-given-by/${id}/${id}`, user)
      .then(res => {
        setOpenViewNoteModal(true)
        let userObj = { ...user, notes: res.data }
        setViewNoteUser(userObj)
        const newData = { companyId: id, userId: user?._id, loggedProfileId: id }
        axios.post(`/api/admin/update-note-readBy/`, newData)
          .then(res => { })
          .catch(err => console.log(err))
      })
      .catch(err => {
        console.log(err)
      })
  }


  useEffect(() => {
    if (window.pageYOffset < 93) {
      setCustomHeight("")
    } else {
      setCustomHeight("custom-height")
    }
    getAllClients()
  }, [])

  window.addEventListener('scroll', () => {
    if (window.pageYOffset < 93) {
      setCustomHeight("")
    } else {
      setCustomHeight("custom-height")
    }

  })

  useEffect(() => {
    if (open) {
      setRightSidebarOpen(false);
    } else { }
  }, [open])

  useEffect(() => {
    if (localStorage.getItem("candidate") || localStorage.getItem("screener")) {
      localStorage.getItem("title") === null
        ? setInterviewTitle("")
        : setInterviewTitle(localStorage.getItem("title"));
      setScheduleClicked(localStorage.getItem("scheduled"));
      setRightSidebarOpen(localStorage.getItem('rightSidebarOpen'))
      localStorage.getItem("screener")
        ? setScreenerName(localStorage.getItem("screener"))
        : setScreenerName("");

      localStorage.getItem("candidate")
        ? setCandidateName(localStorage.getItem("candidate"))
        : setCandidateName("");

      localStorage.getItem('client') ? setClientName(localStorage.getItem('client')) : setClientName('')
      localStorage.getItem('position') ? setPositionName(localStorage.getItem('position')) : setPositionName('')

      localStorage.getItem("zone")
        ? setZone(localStorage.getItem("zone"))
        : setZone("Asia/Kolkata");
      localStorage.getItem("time")
        ? setTime(localStorage.getItem("time"))
        : setTime("12:15 PM");
      localStorage.getItem("date")
        ? setDate(localStorage.getItem("date"))
        : setDate("");
      localStorage.getItem("candidateDetails")
        ? setCandidateDetails(
          JSON.parse(localStorage.getItem("candidateDetails"))
        )
        : setCandidateDetails({
          name: "",
          id: "",
          email: "",
        });
      localStorage.getItem("screenerDetails")
        ? setScreenerDetails(
          JSON.parse(localStorage.getItem("screenerDetails"))
        )
        : setScreenerDetails({
          name: "",
          id: "",
          email: "",
        });

      localStorage.getItem('clientDetails') ? setClientDetails(JSON.parse(localStorage.getItem('clientDetails'))) : setClientDetails({
        name: "",
        id: '',
        email: ''
      })
      localStorage.getItem('positionDetails') ? setPositionDetails(JSON.parse(localStorage.getItem('positionDetails'))) : setPositionDetails({
        name: "",
        id: ''
      })

      localStorage.getItem("link")
        ? setInterviewLink(localStorage.getItem("link"))
        : setInterviewLink("");
      localStorage.getItem("meetingPlatform")
        ? setMeetingPlatform(localStorage.getItem("meetingPlatform"))
        : setMeetingPlatform("");
    }
  }, []);

  useEffect(() => {
    document.title = "Screener | Favorites";

    if (localStorage.getItem("role") === "candidate") {
      setRoleFilter("candidate");
      setSelectedRole(true);
      if (localStorage.getItem("navigateTo") === "advancedSearch") {
        setSelectedView(false);
        setViewFilter("cardView");
        // setOpen(false);
      } else {
        setViewFilter("simpleView");
        setSelectedView(true);
        // setOpen(true);
      }
    } else if (localStorage.getItem("role") === "screener") {
      setRoleFilter("screener");
      setSelectedRole(false);
      if (localStorage.getItem("navigateTo") === "advancedSearch") {
        setSelectedView(false);
        setViewFilter("cardView");
        // setOpen(false);
      } else {
        setViewFilter("simpleView");
        setSelectedView(true);
        // setOpen(true);
      }
    }
  }, []);


  useEffect(() => {
    localStorage.setItem("title", interviewTitle);
    localStorage.setItem("candidate", candidateName);
    localStorage.setItem("screener", screenerName);
    localStorage.setItem("link", interviewLink);
    localStorage.setItem("meetingPlatform", meetingPlatform);
    localStorage.setItem("date", date);
    localStorage.setItem("time", time);
    localStorage.setItem("zone", zone);
    localStorage.setItem("role", roleFilter);
    localStorage.setItem("candidateDetails", JSON.stringify(candidateDetails));
    localStorage.setItem("screenerDetails", JSON.stringify(screenerDetails));
    localStorage.setItem("scheduled", scheduleClicked);
    localStorage.setItem('rightSidebarOpen', rightSidebarOpen)
    localStorage.setItem('clientDetails', JSON.stringify(clientDetails))
    localStorage.setItem('positionDetails', JSON.stringify(positionDetails))
    localStorage.setItem('client', clientName)
    localStorage.setItem('position', positionName)
  }, [
    screenerName,
    interviewLink,
    date,
    zone,
    time,
    roleFilter,
    candidateName,
    interviewTitle,
    rightSidebarOpen,
    scheduleClicked,
    clientName,
    positionName
  ]);

  //Debouncing
  useEffect(() => {
    const timer = setTimeout(() => setFilter(debouncedTerm), 500);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  //fetching navbar data
  useEffect(() => {
    const { data } = axios.get(`/api/admin/${id}`).then((res) => {
      const {
        defaultAvatar,
        profileImage,
        croppedImage,
        interacted,
        companyName,
        companyEmail
      } = res.data.user;
      setDefaultAvatar(defaultAvatar);
      setProfileImage(profileImage);
      setCroppedImg(croppedImage);
      setInteracted(interacted);
      setCompanyName(companyName);
      setReplyToEmail(companyEmail)
    });
  }, []);

  const clearLocalStorage = () => {
    localStorage.removeItem("title");
    localStorage.removeItem("candidate");
    localStorage.removeItem("screener");
    localStorage.removeItem("link");
    localStorage.removeItem("meetingPlatform");
    localStorage.removeItem("date");
    localStorage.removeItem("time");
    localStorage.removeItem("zone");
    localStorage.removeItem("role");
    localStorage.removeItem("candidateDetails");
    localStorage.removeItem("screenerDetails");
    localStorage.removeItem("navigateTo");
    localStorage.removeItem("scheduled");
    localStorage.removeItem('client')
    localStorage.removeItem('position')
    localStorage.removeItem('clientDetails')
    localStorage.removeItem('positionDetails')
  };

  //mui autocomplete options
  const clientOptions = clientsList?.filter(item => item.status).map((client) => {
    return {
      name: client.companyName,
      label: `${client.companyName} - CLN-${client._id.slice(-5)} - ${client.email}`,
      value: {
        id: client._id,
        email: client.email,
        name: client.companyName,
      },
    };
  });

  const positionOptions = positionsList?.filter(pos => pos.status === "Active").map(pos => {
    return {
      name: pos.position,
      label: `${pos.position} - POS-${pos._id.slice(-5)}`,
      value: {
        id: pos._id,
        name: pos.position
      }
    }
  })

  //get all the clients of the company
  function getAllClients() {
    axios.get(`/api/admin/get-all-clients/${id}`)
      .then(res => {
        const { data } = res
        setClientsList(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function getAllPositions(clientDetail) {
    axios.get(`/api/admin/get-all-positions/${id}`)
      .then(res => {
        let { position } = res.data
        const { id } = clientDetail
        position = position.filter(pos => pos.client.id == id)
        setPositionsList(position)
      })
      .catch(err => {
        console.log(err)
      })
  }

  //set the client name and details
  const handleClientData = (event, newValue) => {
    setClientName(newValue?.name)
    setClientDetails(newValue?.value)
    setPositionName("")
    setPositionDetails({ id: '', name: '' })
    getAllPositions(newValue?.value) //get all the positions of the respective client
  }

  //set the position name and details
  const handlePositionData = (event, newValue) => {
    setPositionName(newValue.name)
    setPositionDetails(newValue.value)
  }

  const handleAutoCompleteTextField = (e) => {
    const { name, value } = e.target
    switch (name) {
      case "client":
        if (value === "") setPositionDetails({ id: '', name: '' }) //delete position details when client name is empty
        setClientName(value)
        setClientDetails({ id: '', name: '', email: '' })
      case "position":
        setPositionName(value)
        setPositionDetails({ id: '', name: '' })
      default:
    }
  }

  const handleScheduleInterview = (firstName, lastName, email, id, status, profile) => {
    if (!status) {
      setRightSidebarOpen(false)
      setOpenSnackbar(true)
      setMessage("Cannot Schedule Interview for inactive users!")
      return;
    }

    if (!profile.primaryPhone) {
      setRightSidebarOpen(false)
      setOpenSnackbar(true)
      setMessage("Profile does not have a phone number!")
      return;
    }
    const fullName = firstName + " " + lastName
    setRightSidebarOpen(true)
    setOpen(false)
    setScheduleClicked(true)
    if (roleFilter === 'candidate') {
      setCandidateName(fullName)
      setCandidateDetails({
        name: fullName,
        email,
        id
      })
    }
    else {
      setScreenerName(fullName)
      setScreenerDetails({
        name: fullName,
        email,
        id
      })
    }
  }

  const cancelInterview = () => {
    setRightSidebarOpen(false);
    setScheduleClicked(false);
    setDialogOpen(false);
    setInterviewTitle("");
    setInterviewLink("");
    setMeetingPlatform("")
    setCandidateName("");
    setScreenerName("");
    setClientName("")
    setPositionName("")
    setZone("Asia/Kolkata");
    setTime("12:15 PM");
    setDate("");
    setCandidateDetails({
      name: "",
      id: "",
      email: "",
    });
    setScreenerDetails({
      name: "",
      id: "",
      email: "",
    });
    setClientDetails({
      name: '', id: '', email: ''
    })
    setPositionDetails({
      name: '', id: ''
    })
    clearLocalStorage();
  };

  //navigator function
  const openArrowHandler = () => {
    setRightSidebarOpen((prev) => !prev);
    setOpen(false);
  };

  const favoriteHandler = (role, navigateTo) => {
    localStorage.setItem("role", role);
    localStorage.setItem("navigateTo", navigateTo);
    navigate("/admin/advanced-search");
  };

  const getInternalUsers = async () => {
    try {
      // fetching notes given to all users in internaldb
      let allUsersNotes = await axios.get(`/api/admin/get-all-notes/${id}`)

      if (localStorage.getItem('role').toLocaleLowerCase() === "screener") {
        // get all the notes of screeners
        let screenerNotes = allUsersNotes.data.filter(user => user.role == "screener");
        const userId = id;
        let screenerData = await axios.get(`/api/admin/favorites/${userId}/${localStorage.getItem('role').toLocaleLowerCase()}/${id}`);
        if (screenerData.data.screener.length > 0) {
          setLoading(false)
          let newUsers = screenerData.data.screener.map(item => {
            let notes = screenerNotes.find(user => user.userId == item._id)
            let skillsList = item.skills.map(el => el.skillName);
            let degree = item.educational_details.map(item => item.degree)
            let stream = item.educational_details.map(item => item.stream)
            if (notes) {
              notes = notes.notes
              notes = notes.map(item => {
                if (item.readBy.includes(userId)) {
                  return { ...item, read: true }
                } else {
                  return { ...item, read: false }
                }
              })
              return { ...item, skillArray: skillsList, degree, stream, notes }
            } else {
              return { ...item, skillArray: skillsList, degree, stream, notes: [] }
            }
          })
          // newUsers = newUsers.filter((item) => item.isFavorite);
          newUsers = newUsers.reverse();
          setAllUsers(newUsers);
          setFilterArray(newUsers);
        } else if (screenerData.data.screener.length === 0) {
          setLoading(false)
          setAllUsers(screenerData.data.screener)
        } else {
          setAllUsers(screenerData.data.screener)
        }
      } else if (localStorage.getItem('role').toLocaleLowerCase() === "candidate") {
        // get all the notes of candidate
        let candidateNotes = allUsersNotes.data.filter(user => user.role == "candidate");
        const userId = id;
        let candidateData = await axios.get(`/api/admin/favorites/${userId}/${localStorage.getItem('role').toLocaleLowerCase()}/${id}`);
        if (candidateData.data.candidate.length > 0) {
          setLoading(false)
          let newUsers = candidateData.data.candidate.map(item => {
            let notes = candidateNotes.find(user => user.userId == item._id)
            let skillsList = item.skills.map(el => el.skillName);
            let degree = item.educational_details.map(item => item.degree)
            let stream = item.educational_details.map(item => item.stream)
            if (notes) {
              notes = notes.notes;
              notes = notes.map(item => {
                if (item.readBy.includes(userId)) {
                  return { ...item, read: true }
                } else {
                  return { ...item, read: false }
                }
              })
              return { ...item, skillArray: skillsList, degree, stream, notes }
            } else {
              return { ...item, skillArray: skillsList, degree, stream, notes: [] }
            }
          })
          newUsers = newUsers.reverse();
          setAllUsers(newUsers);
          setFilterArray(newUsers);
        } else if (candidateData.data.candidate.length === 0) {
          setLoading(false)
          setAllUsers(candidateData.data.candidate)
        }
        else {
          setAllUsers(candidateData.data.candidate)
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //fetch users
  useEffect(() => {
    setPage(0);
    getInternalUsers();

    //set filter variable to empty string when role changes
    setNameFilter("");
    setEmailFilter("");
    setTitleFilter("");
    setExperienceFilter("");
    setSalaryFilter("");
    setPhoneFilter("");
    setSkillsFilter("");
    setEducationFilter("");
    setLocationFilter("");
    setDobFilter("");
    setGenderFilter("");
    setNationalityFilter("");
  }, [roleFilter, openNoteModal]);

  //getting the output after filter (simple search)
  const getFiltered = () => {
    let filtered = filterArray.filter((item, index) => {
      if (nameFilter) {
        return (
          item.firstName?.toLowerCase()
            .includes(nameFilter.toLowerCase().trim()) ||
          item.lastName.toLowerCase().includes(nameFilter.toLowerCase().trim())
        );
      } else return item;
    });
    filtered = filtered.filter((item, index) => {
      if (titleFilter) {
        return item.profileTitle?.toLowerCase()
          .startsWith(titleFilter.toLowerCase().trim());
      } else return item;
    });
    filtered = filtered.filter((item, index) => {
      if (experienceFilter) {
        return item.totalExperience?.toLowerCase()
          .includes(experienceFilter.toLowerCase().trim());
      } else return item;
    });
    filtered = filtered.filter((item, index) => {
      if (salaryFilter) {
        return (
          item.salaryDetail?.salary?.toLowerCase()
            .includes(salaryFilter.toLowerCase().trim()) ||
          item.salaryDetail?.payType?.toLowerCase()
            .includes(salaryFilter.toLowerCase().trim()) ||
          item.salaryDetail?.salary?.toLowerCase()
            .includes(salaryFilter.toLowerCase().trim())
        );
      } else return item;
    });
    filtered = filtered.filter((item, index) => {
      if (emailFilter) {
        return item.email?.toLowerCase()
          .includes(emailFilter.toLowerCase().trim());
      } else return item;
    });
    filtered = filtered.filter((item, index) => {
      if (phoneFilter) {
        return item.primaryPhone?.toLowerCase()
          .includes(phoneFilter.toLowerCase().trim());
      } else return item;
    });
    filtered = filtered.filter((item, index) => {
      if (skillsFilter) {
        return item.skillArray?.find((el) =>
          el?.toLowerCase().includes(skillsFilter.toLowerCase().trim())
        );
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (locationFilter) {
        return (
          item.personal_details.location?.city?.toLowerCase()
            .includes(locationFilter.toLowerCase().trim()) ||
          item.personal_details.location?.state?.toLowerCase()
            .includes(locationFilter.toLowerCase().trim()) ||
          item.personal_details.location?.country?.toLowerCase()
            .includes(locationFilter.toLowerCase().trim())
        );
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (educationFilter) {
        return (
          item.degree?.find((el) =>
            el?.toLowerCase().includes(educationFilter.toLowerCase().trim())
          ) ||
          item.stream?.find((el) =>
            el?.toLowerCase().includes(educationFilter.toLowerCase().trim())
          )
        );
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (dobFilter) {
        return item.personal_details?.dob?.toLowerCase()
          .includes(dobFilter.toLowerCase().trim());
      } else return item;
    });
    filtered = filtered.filter((item, index) => {
      if (genderFilter) {
        return item.personal_details?.gender?.toLowerCase()
          .startsWith(genderFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (nationalityFilter) {
        return item.personal_details.nationality?.toLowerCase()
          .startsWith(nationalityFilter.toLowerCase().trim());
      } else return item;
    });

    setAllUsers(filtered);
  };

  //filter
  useEffect(() => {
    setPage(0);
    getFiltered();
  }, [
    nameFilter,
    emailFilter,
    titleFilter,
    phoneFilter,
    experienceFilter,
    salaryFilter,
    skillsFilter,
    locationFilter,
    educationFilter,
    dobFilter,
    genderFilter,
    nationalityFilter,
  ]);

  //card view search
  const sendFilter = () => {
    const filteredUsers = filterArray.filter((item) => {
      return (
        item.firstName?.toLowerCase().includes(filter.toLowerCase()) ||
        item.lastName?.toLowerCase().includes(filter.toLowerCase()) ||
        item.profileTitle?.toLowerCase().startsWith(filter.toLowerCase()) ||
        item.totalExperience?.toLowerCase()
          .includes(filter.toLowerCase().trim()) ||
        item.salaryDetail.salary?.toLowerCase()
          .includes(filter.toLowerCase().trim()) ||
        item.salaryDetail?.payType?.toLowerCase()
          .includes(filter.toLowerCase().trim()) ||
        item.salaryDetail?.salary?.toLowerCase()
          .includes(filter.toLowerCase().trim()) ||
        item.email?.toLowerCase().includes(filter.toLowerCase().trim()) ||
        item.skillArray?.find((el) =>
          el?.toLowerCase().includes(filter.toLowerCase().trim())
        ) ||
        item.personal_details.location?.city?.toLowerCase()
          .includes(filter.toLowerCase().trim()) ||
        item.personal_details.location?.state?.toLowerCase()
          .includes(filter.toLowerCase().trim()) ||
        item.personal_details.location?.country?.toLowerCase()
          .includes(filter.toLowerCase().trim()) ||
        item.degree?.find((el) =>
          el?.toLowerCase().includes(filter.toLowerCase().trim())
        ) ||
        item.stream?.find((el) =>
          el?.toLowerCase().includes(filter.toLowerCase().trim())
        ) ||
        item.personal_details.dob?.toLowerCase()
          .includes(filter.toLowerCase().trim()) ||
        item.personal_details.gender?.toLowerCase()
          .startsWith(filter.toLowerCase().trim()) ||
        item.personal_details.nationality?.toLowerCase()
          .startsWith(filter.toLowerCase().trim())
      );
    });
    setAllUsers(filteredUsers);
  };

  useEffect(() => {
    setPage(0);
    sendFilter();
  }, [filter, roleFilter]);

  //input field focus handler
  const handleFocus = (e) => {
    e.target.setSelectionRange(cursorPosition, cursorPosition);
  };

  //filter handling
  const filterHandler = (e) => {
    setDebouncedTerm(e.target.value);
    setCursorPosition(e.target.selectionStart);
  };

  //role tab handler
  const handleChangeRoleFilter = (event, role) => {
    if (role === null) {
      setRoleFilter(roleFilter);
    } else {
      setRoleFilter(role);
    }
    if (role == "candidate") {
      setSelectedRole(true);
    } else if (role === "screener") {
      setSelectedRole(false);
    } else {
      setSelectedRole(selectedRole);
    }
  };

  //view tab handler
  const handleChangeViewFilter = (event, viewType) => {
    if (viewType === null) {
      setViewFilter(viewType);
    } else {
      setViewFilter(viewType);
    }
    if (viewType == "simpleView") {
      setSelectedView(true);
    } else if (viewType === "cardView") {
      setSelectedView(false);
    } else {
      setSelectedView(selectedView);
    }
  };

  const copyTextToClipboard = async (text) => {
    setMessage("Link copied to clipboard");
    setOpenSnackbar(true);
  };

  const copyEmailToClipboard = async (text) => {
    setMessage("Email copied to clipboard");
    setOpenSnackbar(true);
  };

  //delete handler
  const removeFavoritesHandler = () => {
    const newData = {
      selectedItem,
      userId: id
    };
    axios
      .post(
        `/api/admin/remove-favorites/${roleFilter}/${id}/${selectedItem._id}`
        , newData)
      .then((res) => {
        getInternalUsers()
        setMessage(res.data.message);
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.log(err);
      });
    setOpenAlertDialog(false);
  };

  //resume viewer
  function debugBase64(base64URL) {
    var win = window.open();
    win.document.write(
      '<iframe src="' +
      base64URL +
      '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
  }

  //profile view handler
  const viewProfileHandler = () => {
    localStorage.setItem("id", id);
    localStorage.setItem("jwtToken", token);
  };

  //dialog handler
  const confirmRemoveFavorites = (item) => {
    setOpenAlertDialog(true);
    setSelectedItem(item);
  };

  //action dropdown hanlders
  const handleMenuClick = (event, item) => {
    setSelectedItem(item);
    setActionMenu(event.currentTarget);
  };

  //close MenuItem
  const handleMenuClose = (condition, item) => {
    if (condition === "Share") {
      shareHandler(item._id, item)
    }
    if (condition == "Add Note") {
      noteHandler(item)
    }
    if (condition === "Remove Favorites") {
      removeFavoritesHandler();
    } else if (condition === "View Profile") {
      viewProfileHandler();
    } else if (condition === "Preview Resume") {
      debugBase64(selectedItem.resume);
    } else if (condition === "Schedule Interview") {
      handleScheduleInterview(selectedItem.firstName, selectedItem.lastName, selectedItem.email, selectedItem._id, selectedItem.accountStatus, selectedItem)
    }
    setActionMenu(null);
  };



  //pagination handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const closeHandler = (role) => {
    if (candidateName && screenerName) {
      if (role === "candidate") {
        setCandidateName("");
      } else {
        setScreenerName("");
      }
    } else {
      setDialogOpen(true);
    }
  };

  const closeArrowHandler = () => {
    setRightSidebarOpen((prev) => !prev);
  };

  const closeAllHandler = () => {
    setDialogOpen(true);
  };

  //roleHandler
  const roleHandler = (roleName, selected) => {
    setRoleFilter(roleName);
    setSelectedRole(selected);
  };

  const generateLinkHandler = () => {
    setLinkLoader(true);
    setMeetingPlatform('Zoom')
    setInterviewLink("Loading...")
    axios
      .get(`/api/schedule/generate`)
      .then((res) => {
        setLinkLoader(false);
        setInterviewLink(res.data.join_url);
        setCustomLink(false)
      })
      .catch((err) => {
        setMessage("Zoom server is busy at the moment.");
        setOpenSnackbar(true);
        setLinkLoader(false);
        setCustomLink(false)
      });
  };

  const generateJitsiLink = () => {
    try {
      setMeetingPlatform("Jitsi")
      const meetId = uuidv4();
      const meetingURL = `${JITSI_URL}/${meetId}`
      console.log(meetingURL)
      setInterviewLink(meetingURL)
    } catch (err) {
      setMessage("Jitsi server is busy at the moment.");
      setOpenSnackbar(true);
    }

  }


  const customLinkHandler = () => {
    setCustomLink(c => true);
    setMeetingPlatform("Custom")
    setInterviewLink('')

    if (meetLinkRef.current) {
      // Set focus using setTimeout to ensure it's focused on the next render cycle
      setTimeout(() => {
        meetLinkRef.current.focus();
      }, 0);
    }
  };

  const scheduleHandler = () => {
    if (
      interviewTitle &&
      interviewLink &&
      candidateName &&
      screenerName &&
      time &&
      zone &&
      date
    ) {
      if (clientName && !clientDetails.id) {
        setMessage("Please choose one among the list of Clients");
        setOpenSnackbar(true)
        return
      }
      else if (positionName && !positionDetails.id) {
        setMessage("Please choose one among the list of Positions");
        setOpenSnackbar(true)
        return
      }
      const newData = {
        candidateDetails,
        companyId,
        id,
        screenerDetails,
        interviewTitle,
        interviewLink,
        meetingPlatform,
        zone,
        date,
        time,
        interviewStatus: "Scheduled",
        scheduledBy: id,
        role: role,
        clientDetails,
        positionDetails,
        rescheduledBy: { id: '', name: '', email: '' },
        declinedBy: { id: '', name: '', email: '' }
      };
      axios
        .post("/api/schedule/scheduleinterview", { newData })
        .then((res) => {
          setMessage("Interview Scheduled successfully!");
          setOpenSnackbar(true);
          cancelInterview();
        })
        .catch((err) => {
          setMessage("Oops... There is some error");
          setOpenSnackbar(true);
        });
    } else {
      //show a dialogue saying there is some error
      setMessage("Please fill all the fields");
      setOpenSnackbar(true);
    }
  };

  //save for later handler
  const saveHandler = () => {
    localStorage.removeItem("title");
    if (!interviewTitle) {
      //error for title
      setMessage("Please fill the interview title");
      setOpenSnackbar(true);
    } else if (!screenerName && !candidateName) {
      setMessage("Please fill either candidate or screener name");
      setOpenSnackbar(true);
    }
    else if (clientName && !clientDetails.id) {
      setMessage("Please choose one among the list of Clients");
      setOpenSnackbar(true)
    }
    else if (positionName && !positionDetails.id) {
      setMessage("Please choose one among the list of Positions");
      setOpenSnackbar(true)
    }
    else {
      const newData = {
        candidateDetails,
        companyId,
        id,
        screenerDetails,
        interviewTitle,
        interviewLink,
        meetingPlatform,
        zone,
        date,
        time,
        interviewStatus: "To Schedule",
        scheduledBy: id,
        role: role,
        clientDetails,
        positionDetails,
        rescheduledBy: { id: '', name: '', email: '' },
        declinedBy: { id: '', name: '', email: '' }
      };

      axios
        .post("/api/schedule/scheduleinterview", { newData })
        .then((res) => {
          cancelInterview();
          setMessage("Interview Saved for Later");
          setOpenSnackbar(true);
        })
        .catch((err) => {
          setMessage("Oops... There is some error");
          setOpenSnackbar(true);
        });
    }
  };

  const dateHandler = (e) => {
    setDate(e.target.value);
  };

  const handleTimeChange = (value, field) => {
    if (field == 'hours') {
      setHours(value)
      setTime(`${value}:${minutes} ${meridian}`)
    } else if (field == 'minutes') {
      setMinutes(value)
      setTime(`${hours}:${value} ${meridian}`)
    } else {
      setMeridian(value)
      setTime(`${hours}:${minutes} ${value}`)
    }
  };

  return (
    <>
      <div>
        {/* Navbar Component */}
        <CompanyMenu
          open={open}
          setOpen={setOpen}
          sidebarOpen={sidebarOpen}
          setsidebarOpen={setsidebarOpen}
          companyName={companyName}
          croppedImg={croppedImg}
          profileImage={profileImage}
          interacted={interacted}
          defaultAvatar={defaultAvatar}
        />

        <div
          style={{
            marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}`,
          }}
        >
          <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
            {/* main card */}
            <Card id="main-edit" className="add-recruiter-form form-card">
              <Grid
                container
                spacing={0}
                className="add-recruiter-card-container"
              >
                <Grid
                  item
                  xs={12}
                  sm={sidebarOpen === "sidebar-open" ? 3 : 4}
                  md={sidebarOpen === "sidebar-open" ? 3 : 4}
                  lg={6}
                  className="container-heading-title admin-table"
                >
                  <h3>
                    Favorites{" "}
                    {` > ${roleFilter === "candidate" ? "Job Seeker" : "Screener"
                      } `}
                  </h3>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={sidebarOpen === "sidebar-open" ? 8 : 7}
                  md={sidebarOpen === "sidebar-open" ? 8 : 7}
                  lg={6}
                  className="container-heading-buttons add-recruiter-button favorites-heading-buttons"
                  style={{ width: "100%" }}
                >
                  <div
                    className="toggle-btn-grp top-card-button-container"
                  >
                    <ToggleButtonGroup
                      value={roleFilter}
                      exclusive
                      onChange={handleChangeRoleFilter}
                      aria-label="Platform"
                    >
                      <ToggleButton
                        value="candidate"
                        selected={selectedRole}
                        className={
                          roleFilter === "candidate"
                            ? "Mui-selected "
                            : "toggle-role-btn"
                        }
                      >
                        Job Seeker
                      </ToggleButton>
                      <ToggleButton
                        selected={!selectedRole}
                        className={
                          roleFilter === "screener"
                            ? "Mui-selected "
                            : "toggle-role-btn"
                        }
                        value="screener"
                      >
                        Screener
                      </ToggleButton>
                    </ToggleButtonGroup>

                    <ToggleButtonGroup
                      value={roleFilter}
                      exclusive
                      onChange={handleChangeViewFilter}
                      aria-label="Platform"
                    >
                      <ToggleButton
                        value="simpleView"
                        selected={selectedView}
                        className={
                          viewFilter === "simpleView"
                            ? "Mui-selected "
                            : "toggle-role-btn"
                        }
                      >
                        Simple View
                      </ToggleButton>
                      <ToggleButton
                        selected={!selectedView}
                        className={
                          viewFilter === "cardView"
                            ? "Mui-selected "
                            : "toggle-role-btn"
                        }
                        value="cardView"
                      >
                        Card View
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </Grid>
              </Grid>

              {/* filter field in card view */}
              {!selectedView ? (
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="profile-progress-bar"
                >
                  <Grid
                    id="profile-completion-percentage"
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    sx={{ textAlign: "center" }}
                    className="container-profile"
                  >
                    <div
                      class="add-recruiter-input-container fav-search-container"
                    >
                      <div class="input-field">
                        <input
                          class="position"
                          type="text"
                          defaultValue={debouncedTerm}
                          onChange={filterHandler}
                          onFocus={handleFocus}
                          placeholder="Search"
                          ref={(el) => (inputRef.current = el)}
                          autoFocus={inputRef.current === document.activeElement}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Card>

            {/* Table view in simple view  */}
            <div className={`${!selectedView && allUsers.length == 0 && !rightSidebarOpen ? "justify-center" : "filter-bottom-flex"}`}>
              {selectedView ? (
                // table view
                <Card
                  id="skills-edit"
                  className=" bs-none table-card"
                  style={{ width: rightSidebarOpen ? '68vw' : '', position: "relative" }}
                >
                  <div className="user-list">
                    <div className="internal-user-list-heading">
                      {columns.map((item, index) => {
                        return (
                          <>
                            <div
                              className="header-filter"
                              style={{
                                width: `${item.width}`,
                              }}
                            >
                              {item.title === "Name" ?
                                <p
                                  className={`alternate-heading-bg ${index % 2 ? "dark" : "light"} ${sidebarOpen == 'sidebar-open' ? "name-heading-no-scroll-sidebar-open" : "name-heading-no-scroll"}`}
                                >
                                  {item.title}
                                </p>
                                :
                                <p
                                  className={`alternate-heading-bg ${index % 2 ? "dark" : "light"}`}
                                >
                                  {item.title}
                                </p>}
                              {item.title === "Name" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={nameFilter}
                                  className={`${sidebarOpen == "sidebar-open" ? "name-filter-sidebar-open" : "name-filter"}`}
                                  onChange={(e) => setNameFilter(e.target.value)}
                                />
                              ) : item.title === "Title" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={titleFilter}
                                  onChange={(e) => setTitleFilter(e.target.value)}
                                />
                              ) : item.title === "Total Experience" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={experienceFilter}
                                  onChange={(e) =>
                                    setExperienceFilter(e.target.value)
                                  }
                                />
                              ) : item.title === "Expected Salary" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={salaryFilter}
                                  onChange={(e) => setSalaryFilter(e.target.value)}
                                />
                              ) : item.title === "Email Id" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={emailFilter}
                                  onChange={(e) => setEmailFilter(e.target.value)}
                                />
                              ) : item.title === "Phone Number" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={phoneFilter}
                                  onChange={(e) => setPhoneFilter(e.target.value)}
                                />
                              ) : item.title === "Skills" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={skillsFilter}
                                  onChange={(e) => setSkillsFilter(e.target.value)}
                                />
                              ) : item.title === "Current Location" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={locationFilter}
                                  onChange={(e) =>
                                    setLocationFilter(e.target.value)
                                  }
                                />
                              ) : item.title === "Highest Education" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={educationFilter}
                                  onChange={(e) =>
                                    setEducationFilter(e.target.value)
                                  }
                                />
                              ) : item.title === "DOB" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={dobFilter}
                                  onChange={(e) => setDobFilter(e.target.value)}
                                />
                              ) : item.title === "Gender" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={genderFilter}
                                  onChange={(e) => setGenderFilter(e.target.value)}
                                />
                              ) : item.title === "Nationality" ? (
                                <input
                                  type="text"
                                  placeholder="Filter"
                                  value={nationalityFilter}
                                  onChange={(e) =>
                                    setNationalityFilter(e.target.value)
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        );
                      })}
                    </div>

                    {loading ? (
                      <TableLoader page="internal-userList" />
                    ) : (
                      <div className="internal-user-details-list">
                        {allUsers.length > 0 ? (
                          allUsers
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((item, index) => {
                              const { city, state, country } = item.personal_details.location;

                              const highestEducation = [...item.educational_details]
                                .sort((a, b) => b.startYear - a.startYear);
                              const locationLength = city?.length + state?.length + country?.length + 4;
                              let nameLength = item.firstName?.length + item.lastName?.length;
                              let unreadCount = item.notes?.length && item.notes?.filter(note => !note.read).length;
                              let skillsCount = item.skills.length;
                              {
                                return (
                                  <>
                                    <div
                                      className={`${skillsCount > 6 ? "fix-height" : ""} internal-users-details ${index % 2
                                        ? ""
                                        : "alternate-recruiterlist-bg"
                                        }`}
                                    >
                                      <div
                                        className={`fix-height internal-user-list-name interview-id ${index % 2
                                          ? `${sidebarOpen == 'sidebar-open' ? "name-no-scroll-even-sidebar-open" : "name-no-scroll-even"}`
                                          : `${sidebarOpen == 'sidebar-open' ? "name-no-scroll-odd-sidebar-open" : "name-no-scroll-odd"}`
                                          }`}
                                      >
                                        <img
                                          src={
                                            item.defaultAvatar
                                              ? profileAvatar
                                              : item.interacted
                                                ? item.croppedImage
                                                : item.profileImage
                                          }
                                          className="internal-user-list-profile-image"
                                        />

                                        {nameLength > 23 ? (
                                          <Tooltip
                                            title={`${item.firstName} ${item.lastName}`}
                                          >
                                            <p>
                                              {item.firstName} {item.lastName}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p>
                                            {item.firstName} {item.lastName}
                                          </p>
                                        )}
                                        {item.notes?.length ? <div className="view-note-icon">
                                          <Tooltip title="View Note" placement="top-end">
                                            {unreadCount > 0 ? <Badge variant="dot" sx={{
                                              '& .MuiBadge-dot': {
                                                backgroundColor: '#f06161',
                                                height: 5,
                                                minWidth: 5
                                              },
                                            }}>
                                              <ViewNoteIcon style={{ fontSize: "0.9rem" }} onClick={() => viewNoteHandler(item)} />
                                            </Badge> : <ViewNoteIcon style={{ fontSize: "0.9rem" }} onClick={() => viewNoteHandler(item)} />}
                                          </Tooltip>
                                        </div> : ""
                                        }
                                      </div>

                                      <div
                                        className="internal-user-list-profileType "
                                        style={{ width: "18%", marginLeft: "11%" }}
                                      >
                                        {item.profileTitle.length > 24 ? (
                                          <Tooltip title={`${item.profileTitle}`}>
                                            {item.profileTitle ? <p>{item.profileTitle}</p> : <p>Not specified</p>}
                                          </Tooltip>
                                        ) : (
                                          <p>{item.profileTitle ? item.profileTitle : 'Not specified'}</p>
                                        )}
                                      </div>

                                      <div
                                        className="internal-user-list-total-experience"
                                        style={{ width: "20%" }}
                                      >
                                        {item.totalExperience ? <p>{item.totalExperience} year(s)</p> : <p>Not specified</p>}
                                      </div>

                                      <div
                                        className="internal-user-list-salary"
                                        style={{ width: "18%" }}
                                      >
                                        {item.salaryDetail.payType ? (
                                          <p>
                                            ${item.salaryDetail.salary}/
                                            {item.salaryDetail.payType}
                                          </p>
                                        ) : (
                                          <p>{item.salaryDetail.salary ? item.salaryDetail.salary : "Not specified"}</p>
                                        )}
                                      </div>

                                      <div
                                        className="internal-user-list-email"
                                        style={{ width: "28%" }}
                                      >
                                        {item.email.length > 24 ? (
                                          <Tooltip title={`${item.email}`}>
                                            <p>{item.email}</p>
                                          </Tooltip>
                                        ) : (
                                          <p>{item.email}</p>
                                        )}
                                      </div>

                                      <div
                                        className="internal-user-list-phone "
                                        style={{ width: "18%" }}
                                      >
                                        <p>+ {item.primaryPhone}</p>
                                      </div>

                                      <div
                                        className="internal-user-list-skills"
                                        style={{ width: "30%" }}
                                      >
                                        <p
                                          style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {item.skills.length ? (item.skills?.map((el, i) => {
                                            const { skillName, yearOfExperience } =
                                              el;

                                            if (yearOfExperience) {
                                              return (
                                                <Tooltip
                                                  title={`${skillName} - ${yearOfExperience} Year(s)`}
                                                >
                                                  <p style={{ marginRight: "3px" }}>
                                                    {skillName}
                                                    {i !=
                                                      item.skills.length - 1 && (
                                                        <span>{", "}</span>
                                                      )}
                                                  </p>
                                                </Tooltip>
                                              );
                                            } else {
                                              return (
                                                <Tooltip title={`${skillName} `}>
                                                  <p style={{ marginRight: "3px" }}>
                                                    {skillName}
                                                    {i !=
                                                      item.skills.length - 1 && (
                                                        <span>{", "}</span>
                                                      )}
                                                  </p>
                                                </Tooltip>
                                              );
                                            }
                                          })) : "Not specified"}
                                        </p>
                                      </div>
                                      <div
                                        className="internal-user-list-location"
                                        style={{ width: "20%" }}
                                      >
                                        {locationLength > 4 ? (locationLength > 24 ? (
                                          <Tooltip
                                            title={`${city}, ${state}, ${country}`}
                                          >
                                            <p>
                                              {city}, {state}, {country}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p>
                                            {city}, {state}, {country}
                                          </p>
                                        )) : <p>Not specified</p>}
                                      </div>
                                      <div
                                        className="internal-user-list-education"
                                        style={{ width: "20%" }}
                                      >
                                        {highestEducation.length ? (highestEducation.map((education, i) => {
                                          if (i == 0) {
                                            const { stream, degree } = education;
                                            const eduLength =
                                              2 + degree?.length + stream?.length;
                                            {
                                              return eduLength > 24 ? (
                                                <Tooltip
                                                  title={`${degree}, ${stream}`}
                                                >
                                                  <p>
                                                    {degree}, {stream}
                                                  </p>
                                                </Tooltip>
                                              ) : eduLength == 2 ? (
                                                <p></p>
                                              ) : (
                                                <p>
                                                  {degree}, {stream}
                                                </p>
                                              );
                                            }
                                          }
                                        })) : <p>Not specified</p>}
                                      </div>
                                      <div
                                        className="internal-user-list-dob"
                                        style={{ width: "14%" }}
                                      >
                                        <p>{item.personal_details.dob || "Not specified"}</p>
                                      </div>
                                      <div
                                        className="internal-user-list-gender"
                                        style={{ width: "11%" }}
                                      >
                                        <p>{item.personal_details.gender || "Not specified"}</p>
                                      </div>
                                      <div
                                        className="internal-user-list-nationality"
                                        style={{ width: "10%" }}
                                      >
                                        <p>{item.personal_details.nationality || "Not specified"}</p>
                                      </div>

                                      <div className="status-button" style={{ width: "9%" }}>
                                        <button className={` ${item.accountStatus ? "Active" : "Frozen"}`}>
                                          {item.accountStatus ? "Active" : "Inactive"}
                                        </button>
                                      </div>
                                      <div
                                        className="internal-user-list-actions"
                                        style={{ width: "5%" }}
                                      >
                                        <IconButton
                                          disableRipple
                                          className="action-dropdown-button"
                                          aria-label="more"
                                          id="long-button"
                                          aria-controls={
                                            openActionMenu ? "long-menu" : undefined
                                          }
                                          aria-expanded={
                                            openActionMenu ? "true" : undefined
                                          }
                                          aria-haspopup="true"
                                          onClick={(e) => handleMenuClick(e, item)}
                                        >
                                          <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                          className="actions-menu-list"
                                          id="long-menu"
                                          MenuListProps={{
                                            "aria-labelledby": "long-button",
                                          }}
                                          anchorEl={actionMenu}
                                          open={openActionMenu}
                                          onClose={() => handleMenuClose("close", selectedItem)}
                                          PaperProps={{
                                            style: {
                                              position: rightSidebarOpen ? 'absolute' : '',
                                              right: rightSidebarOpen ? '18vw' : '',
                                              width: "20ch",
                                              boxShadow:
                                                "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                                            },
                                          }}
                                        >
                                          {actionOptions.map((option) => {
                                            return option.name !==
                                              "View Profile" ? (
                                              option.name === "Preview Resume" ? (
                                                <MenuItem
                                                  key={option}
                                                  onClick={() =>
                                                    selectedItem?.resume &&
                                                    handleMenuClose(option.name, selectedItem)
                                                  }
                                                >
                                                  <div>
                                                    <p>
                                                      <ResumeIcon
                                                        className={
                                                          !selectedItem?.resume &&
                                                          "disabled-icon-resume"
                                                        }
                                                      />
                                                    </p>
                                                    <p>
                                                      {selectedItem?.resume
                                                        ? option.name
                                                        : "No Resume"}
                                                    </p>
                                                  </div>
                                                </MenuItem>
                                              ) : option.name === "Send Mail" ?
                                                (<MenuItem
                                                  key={option}
                                                  onClick={() => handleMenuClose(option.name, selectedItem)}
                                                >
                                                  <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", alignItems: "baseline" }}>
                                                    <a href={`mailto: ${selectedItem?.email}`} style={{ height: "20px", display: "flex", justifyContent: "space-between", gap: "10px" }}>
                                                      <EmailIcon style={{ padding: 0, fontSize: "1.1em" }} fontSize='small' />
                                                      <p>{option.name}</p></a>
                                                  </div>
                                                </MenuItem>) : option.name === "Schedule Interview" ?
                                                  (<MenuItem
                                                    key={option}
                                                    onClick={() => handleMenuClose(option.name, selectedItem)}
                                                  >
                                                    <div>
                                                      <p>{option.icon}</p>
                                                      <p>{option.name}</p>
                                                    </div>
                                                  </MenuItem>) : (
                                                    <MenuItem
                                                      key={option}
                                                      onClick={() => {
                                                        handleMenuClose(option.name, selectedItem)
                                                      }
                                                      }
                                                    >
                                                      <div>
                                                        <p>{option.icon}</p>
                                                        <p>{option.name}</p>
                                                      </div>
                                                    </MenuItem>
                                                  )
                                            ) : (
                                              <a
                                                href={`/admin/internal-db/profile/${roleFilter}/${selectedItem?._id}/${id}`}
                                                target="_blank"
                                              >
                                                <MenuItem
                                                  key={option}
                                                  onClick={() =>
                                                    handleMenuClose(option.name, selectedItem)
                                                  }
                                                >
                                                  <div>
                                                    <p>{option.icon}</p>
                                                    <p>{option.name}</p>
                                                  </div>
                                                </MenuItem>
                                              </a>
                                            );
                                          })}
                                        </Menu>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            })
                        ) : (
                          <p style={{ margin: "10px auto" }}>No Favorites</p>
                        )}
                      </div>
                    )}
                  </div>
                  <TablePagination
                    id="internal-users-list-pagination"
                    component="div"
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    count={allUsers.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    variant="outlined"
                  />
                </Card>
              ) : (
                // Card view
                allUsers.length > 0 ?
                  <div
                    id="main-edit"
                    className=" card-view form-card table-card"
                    style={{ width: "100%" }}
                  >
                    <Grid
                      container
                      spacing={0}
                      className="add-recruiter-card-container favorites-cards-container"
                    >
                      {allUsers.map((item, index) => {
                        const sortedExperience = [...item.job_experience].sort(
                          (a, b) => b.startYear - a.startYear
                        );

                        const sortedEducation = [...item.educational_details].sort(
                          (a, b) => b.startYear - a.startYear
                        );

                        return (
                          <Grid
                            className={`single-card ${rightSidebarOpen ? "custom-width flex-display" : open ? "open-custom-width" : ""}`}
                            item
                            xs={12}
                            sm={5.5}
                            md={mdValue}
                            lg={lgValue}
                            key={index}
                            style={{ width: "100%" }}
                          >
                            <Card
                              id="skills-edit"
                              className=" profile-card"
                            >
                              <Grid
                                container
                                spacing={0}
                                className="favorites-card-content"
                                sx={{ position: "relative" }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  className="container-heading-title"
                                >
                                  <div className="fav-image-container">
                                    <img
                                      style={{
                                        objectFit: "cover",
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "100%",
                                      }}
                                      src={
                                        item.defaultAvatar
                                          ? profileAvatar
                                          : item.interacted
                                            ? item.croppedImage
                                            : item.profileImage
                                      }
                                    />
                                    <h6
                                      style={{
                                        textAlign: "center",
                                        marginBottom: "10px",
                                        color: "#f06161",
                                        marginTop: "10px"
                                      }}
                                    >
                                      <Highlighter
                                        highlightClassName="highlighter"
                                        textToHighlight={item.firstName}
                                        searchWords={[`${filter}`]}
                                      />{" "}
                                      &nbsp;
                                      <Highlighter
                                        highlightClassName="highlighter"
                                        textToHighlight={item.lastName}
                                        searchWords={[`${filter}`]}
                                      />
                                    </h6>

                                    {item.notes?.length ? <div className="view-note-icon">
                                      <Tooltip title="View Note" placement="right">
                                        <ViewNoteIcon style={{ fontSize: "0.9rem" }} onClick={() => viewNoteHandler(item)} />
                                      </Tooltip>
                                    </div> : ""
                                    }
                                  </div>
                                  <div className="personal-info-container">

                                    <p>
                                      <Highlighter
                                        highlightClassName="highlighter"
                                        textToHighlight={item.profileTitle}
                                        searchWords={[`${filter}`]}
                                      />
                                    </p>
                                    {item.primaryPhone ? <p>{item.primaryPhone}</p> : ""}
                                    {item.email.length > 20 ? (
                                      <Tooltip
                                        title={
                                          <>
                                            {item.email}{" "}
                                            <CopyToClipboard text={item.email} onCopy={copyEmailToClipboard}>
                                              <ContentCopyIcon
                                                fontSize="small"
                                                sx={{
                                                  fontSize: "14px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </CopyToClipboard>
                                          </>
                                        }
                                      >
                                        <p>
                                          <Highlighter
                                            highlightClassName="highlighter"
                                            textToHighlight={item.email}
                                            searchWords={[`${filter}`]}
                                          />
                                        </p>
                                      </Tooltip>
                                    ) : (
                                      <p>
                                        <Highlighter
                                          highlightClassName="highlighter"
                                          textToHighlight={item.email}
                                          searchWords={[`${filter}`]}
                                        />
                                      </p>
                                    )}
                                    <div className="status-button align-center" >
                                      <button className={` ${item.accountStatus ? "Active" : "Frozen"}`}>
                                        {item.accountStatus ? "Active" : "Inactive"}
                                      </button>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  className="fav-user-info-container"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={10.7}
                                    lg={10.7}
                                    className="first-container"
                                  >
                                    <div className="top-section">
                                      {item.totalExperience ? (
                                        <div>
                                          <>
                                            <ExperienceIcon
                                              fontSize="small"
                                              style={{
                                                height: "0.7em",
                                                width: "0.8em",
                                                color: "#2c3e50",
                                              }}
                                            />

                                            <p>
                                              <Highlighter
                                                highlightClassName="highlighter"
                                                textToHighlight={
                                                  item.totalExperience + " year(s)"
                                                }
                                                searchWords={[`${filter}`]}
                                              />
                                            </p>
                                          </>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {item.salaryDetail.salary ? (
                                        <div>
                                          {item.salaryDetail.salary ===
                                            "As per industry standards" ? (
                                            <>
                                              <SalaryIcon
                                                fontSize="small"
                                                style={{
                                                  height: "0.7em",
                                                  width: "0.8em",
                                                  color: "#2c3e50",
                                                }}
                                              />
                                              <p>
                                                <Highlighter
                                                  highlightClassName="highlighter"
                                                  textToHighlight={
                                                    item.salaryDetail.salary
                                                  }
                                                  searchWords={[`${filter}`]}
                                                />
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <SalaryIcon
                                                fontSize="small"
                                                style={{
                                                  height: "0.7em",
                                                  width: "0.8em",
                                                  color: "#2c3e50",
                                                }}
                                              />
                                              <p>
                                                <Highlighter
                                                  highlightClassName="highlighter"
                                                  textToHighlight={
                                                    item.salaryDetail.salary + "/"
                                                  }
                                                  searchWords={[`${filter}`]}
                                                />

                                                <Highlighter
                                                  highlightClassName="highlighter"
                                                  textToHighlight={
                                                    item.salaryDetail.payType
                                                  }
                                                  searchWords={[`${filter}`]}
                                                />
                                              </p>
                                            </>
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {item.personal_details.location?.country || item.personal_details.location?.state || item.personal_details.location?.city ?
                                        <div>
                                          <><LocationIcon
                                            fontSize="small"
                                            style={{
                                              height: "0.8em",
                                              width: "0.8em",
                                              color: "#2c3e50",
                                            }}
                                          />
                                            <p>
                                              <Highlighter
                                                highlightClassName="highlighter"
                                                textToHighlight={
                                                  item.personal_details.location
                                                    ?.country + ", "
                                                }
                                                searchWords={[`${filter}`]}
                                              />
                                              <Highlighter
                                                highlightClassName="highlighter"
                                                textToHighlight={
                                                  item.personal_details.location?.state +
                                                  ", "
                                                }
                                                searchWords={[`${filter}`]}
                                              />
                                              <Highlighter
                                                highlightClassName="highlighter"
                                                textToHighlight={
                                                  item.personal_details.location?.city
                                                }
                                                searchWords={[`${filter}`]}
                                              />
                                            </p></>
                                        </div>
                                        : ""}
                                    </div>

                                    <div className="profile-info card-content">
                                      <table>
                                        {sortedExperience[0]?.company ? (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>Current</strong>
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {sortedExperience[0].position +
                                                  " at "}
                                                <Highlighter
                                                  highlightClassName="highlighter"
                                                  textToHighlight={
                                                    sortedExperience[0].company
                                                  }
                                                  searchWords={[`${filter}`]}
                                                />
                                              </p>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>Current</strong>
                                              </p>
                                            </td>
                                          </tr>
                                        )}

                                        {sortedExperience[1]?.company ? (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>Previous</strong>
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {sortedExperience[1].position +
                                                  " at "}
                                                <Highlighter
                                                  highlightClassName="highlighter"
                                                  textToHighlight={
                                                    sortedExperience[1].company
                                                  }
                                                  searchWords={[`${filter}`]}
                                                />
                                              </p>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>Previous</strong>
                                              </p>
                                            </td>
                                          </tr>
                                        )}

                                        {sortedEducation[0]?.degree ? (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>Education</strong>
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {<Highlighter
                                                  highlightClassName="highlighter"
                                                  textToHighlight={
                                                    sortedEducation[0]?.degree + " in "
                                                  }
                                                  searchWords={[`${filter}`]}
                                                />}
                                                <Highlighter
                                                  highlightClassName="highlighter"
                                                  textToHighlight={
                                                    sortedEducation[0]?.stream
                                                  }
                                                  searchWords={[`${filter}`]}
                                                />
                                              </p>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>Education</strong>
                                              </p>
                                            </td>
                                          </tr>
                                        )}

                                        {item.skills?.length > 0 ? (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>Skills</strong>
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                {item.skills?.map((skill, ind) => {
                                                  const { skillName } = skill;

                                                  if (
                                                    ind !==
                                                    item.skills?.length - 1
                                                  ) {
                                                    return (
                                                      <Highlighter
                                                        highlightClassName="highlighter"
                                                        textToHighlight={
                                                          skillName + " | "
                                                        }
                                                        searchWords={[`${filter}`]}
                                                      />
                                                    );
                                                  } else
                                                    return (
                                                      <Highlighter
                                                        highlightClassName="highlighter"
                                                        textToHighlight={skillName}
                                                        searchWords={[`${filter}`]}
                                                      />
                                                    );
                                                })}
                                              </p>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>Skills</strong>
                                              </p>
                                            </td>
                                            <td>
                                              <p></p>
                                            </td>
                                          </tr>
                                        )}

                                        {item.personal_details?.dob ? (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>DOB</strong>
                                              </p>
                                            </td>
                                            <td>
                                              <p>{item.personal_details.dob}</p>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>DOB</strong>
                                              </p>
                                            </td>
                                          </tr>
                                        )}

                                        {item.personal_details.gender ? (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>Gender</strong>
                                              </p>
                                            </td>
                                            <td>
                                              <p>
                                                <Highlighter
                                                  highlightClassName="highlighter"
                                                  textToHighlight={
                                                    item.personal_details.gender
                                                  }
                                                  searchWords={[`${filter}`]}
                                                />
                                              </p>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr>
                                            <td style={{ width: "10px" }}>
                                              <p style={{ color: "#283848" }}>
                                                <strong>Gender</strong>
                                              </p>
                                            </td>
                                            <td>
                                              <p></p>
                                            </td>
                                          </tr>
                                        )}
                                      </table>
                                    </div>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={1}
                                    lg={1}
                                    className="fav-card-action-container"
                                  >
                                    <Tooltip
                                      title="Remove from Favorites"
                                      placement="top-start"
                                    >
                                      <CloseIcon
                                        fontSize="small"
                                        className="remove-favorites-button"
                                        onClick={() => confirmRemoveFavorites(item)}
                                      />
                                    </Tooltip>

                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}

                                      className="fav-side-icon-list"
                                    >
                                      <Tooltip title="Share" placement='right-start'>
                                        <a>
                                          <ShareIcon fontSize='small'
                                            onClick={() => shareHandler(item._id, item)}
                                          />
                                        </a>
                                      </Tooltip>

                                      <Tooltip title="Send Mail" placement='right-start'>
                                        <a href={`mailto: ${item.email}`}>
                                          <EmailIcon fontSize='small' />
                                        </a>
                                      </Tooltip>

                                      <Tooltip title="Add Note" placement='right-start'>
                                        <a>
                                          <NoteAddIcon fontSize='small' onClick={() => noteHandler(item)} />
                                        </a>
                                      </Tooltip>

                                      <Tooltip title="View Profile" placement='right-start'>
                                        <a href={`/admin/internal-db/profile/${roleFilter}/${item._id}/${id}`} target="_blank" >
                                          <ViewIcon fontSize='small' onClick={() => viewProfileHandler(item)} />
                                        </a>
                                      </Tooltip>

                                      <Tooltip title={item.resume ? "Preview Resume" : 'No resume'} placement='right-start'>
                                        <a>
                                          <ResumeIcon style={{ padding: 0 }} fontSize='small' onClick={() => { item.resume && debugBase64(item.resume) }} className={!item.resume && "disabled-icon-resume"} />
                                        </a>
                                      </Tooltip>

                                      <Tooltip title='Schedule Interview' placement='right-start'>
                                        <a>
                                          <ScheduleIcon style={{ padding: 0 }} fontSize='small'
                                            onClick={() => handleScheduleInterview(item.firstName, item.lastName, item.email, item._id, item.accountStatus, item)}
                                          />
                                        </a>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div> :
                  <div
                    id="main-edit"
                    style={{ margin: `${rightSidebarOpen ? "80px 70px 40px 220px " : "80px 70px 40px 70px "}`, height: '100%' }}
                  > <Card
                    id="skills-edit"
                    style={{
                      minWidth: "300px", height: "70%", borderRadius: "10px",
                      boxShadow: "none",
                      fontFamily: "Raleway", backgroundColor: "#faf9f6"
                    }}
                  >
                      <Grid
                        container
                        spacing={0}
                        direction="row"
                        style={{ justifyContent: "center" }}
                      >
                        <Grid
                          item
                          xs={5}
                          md={10}
                          lg={10}
                          className="container-heading-title"
                          sx={{ margin: "15px", display: "flex", justifyContent: "center" }}
                        >
                          <img src={NothingToShow} alt="Result Not found" style={{ width: "400px" }} />
                        </Grid>

                        <Grid
                          container
                          spacing={0}
                          direction="row"
                          style={{ justifyContent: "center" }}
                        >
                          <Grid
                            item
                            xs={5}
                            md={10}
                            lg={10}
                            className="container-heading-title "
                            sx={{ margin: "15px", display: "flex", flexDirection: "column", alignItems: "center" }}
                          >
                            <h5>Nothing to show!</h5>
                            {<p className="nothing-to-show" style={{ width: "max-content" }}>Oops!...It looks like you haven't applied suitable filters.</p>}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </div>
              )}

              {/* quick interview scheduler */}
              <div>
                {!rightSidebarOpen ? (
                  scheduleClicked && (
                    <OpenArrow
                      className="open-arrow"
                      onClick={openArrowHandler}
                    />
                  )
                ) : (
                  <>
                    <div className={`right-sidebar ${customHeight}`} >
                      <CloseArrow
                        className="close-arrow"
                        onClick={closeArrowHandler}
                      />
                      <Grid
                        container
                        spacing={0}
                        direction="row"
                      >
                        <Grid
                          item
                          xs={5}
                          md={12}
                          lg={12}
                          sx={{
                            margin: "15px 0px 10px 10px",
                            display: "flex",
                            gap: "7px",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              alignItems: "flex-start",
                              marginTop: "10px"
                            }}
                          >
                            <strong>
                              <h5 style={{ fontSize: 18 }}>
                                Quick Interview Scheduler
                              </h5>
                            </strong>
                            <EventIcon fontSize="small" style={{ marginLeft: "5px" }} />
                            <Tooltip title="Close">
                              <CloseIcon
                                fontSize="small"
                                onClick={closeAllHandler}
                                className="close-scheduler "
                              />
                            </Tooltip>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        direction="row"
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          className="container-heading-title "
                          sx={{ overflowY: "hidden", height: "70px" }}
                        >
                          <Typography className="scheduler-title">
                            Interview Title
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{
                              padding: "0px 5px 0px 5px",
                              marginBottom: "10px",
                            }}
                            className="right-grid"
                          >
                            <form noValidate>
                              <div className="input-container filter-container">
                                <div class="input-field-modal">
                                  <input
                                    type="text"
                                    placeholder="Ex: Software Developer-Round 1"
                                    value={interviewTitle}
                                    onChange={(e) =>
                                      setInterviewTitle(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                            </form>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          className="container-heading-title "
                        >
                          <Typography className='scheduler-title'>Client</Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ padding: "0px 5px 0px 5px" }}
                            className="right-grid"
                          >
                            <div className="input-container filter-container">
                              <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="combo-box-demo"
                                  options={clientOptions}
                                  getOptionLabel={(option) => option?.label}
                                  inputValue={clientName}
                                  onChange={(event, newValue) => {
                                    handleClientData(event, newValue)
                                  }}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        style={{ backgroundColor: '#eee' }}
                                        name="client"
                                        onChange={(e) => handleAutoCompleteTextField(e)}
                                        {...params}
                                        placeholder="Select Client"
                                      />
                                    );
                                  }}
                                />
                              </div>
                            </div>

                          </Grid>
                        </Grid>

                        {clientDetails.id !== '' &&
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="container-heading-title "
                          >
                            <Typography className='scheduler-title'>Position</Typography>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ padding: "0px 5px 0px 5px" }}
                              className="right-grid"
                            >
                              <div className="input-container filter-container">
                                <div class="input-field-modal right-sidebar-input" style={{ marginBottom: 10 }}>
                                  <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="combo-box-demo"
                                    options={positionOptions}
                                    getOptionLabel={(option) => option?.label}
                                    inputValue={positionName}
                                    onChange={(event, newValue) => {
                                      handlePositionData(event, newValue)
                                    }}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          style={{ backgroundColor: '#eee' }}
                                          name="position"
                                          onChange={(e) => handleAutoCompleteTextField(e)}
                                          {...params}
                                          placeholder="Select Position"
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </Grid>
                          </Grid>}
                        {screenerName &&
                          candidateName &&
                          (roleFilter === "candidate" ||
                            roleFilter === "screener") ? (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="container-heading-title "
                          >
                            <Typography className="scheduler-title">
                              Job Seeker Name
                            </Typography>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ padding: "0px 5px 0px 5px" }}
                              className="right-grid"
                            >
                              <div className="input-container filter-container">
                                <div
                                  class="input-field-modal right-sidebar-input"
                                  style={{ marginBottom: 10 }}
                                >
                                  <input
                                    type="text"
                                    value={candidateName}
                                    onChange={(e) => {
                                      setCandidateName(e.target.value);
                                    }}
                                    disabled
                                  />
                                  {candidateName && (
                                    <CloseIcon
                                      fontSize="small"
                                      className="right-sidebar-clear-input"
                                      onClick={() => closeHandler("candidate")}
                                    />
                                  )}
                                  <button
                                    onClick={() =>
                                      favoriteHandler(
                                        "candidate",
                                        "advancedSearch"
                                      )
                                    }
                                    className="choose-fav-btn"
                                  >
                                    Choose From Job Seekers
                                  </button>
                                </div>

                                <Typography className="scheduler-title">
                                  Screener Name
                                </Typography>
                                <div
                                  class="input-field-modal right-sidebar-input"
                                  style={{ marginBottom: 10 }}
                                >
                                  <input
                                    type="text"
                                    value={screenerName}
                                    onChange={(e) =>
                                      setScreenerName(e.target.value)
                                    }
                                    disabled
                                  />
                                  {screenerName && (
                                    <CloseIcon
                                      className="right-sidebar-clear-input"
                                      fontSize="small"
                                      onClick={() => closeHandler("screener")}
                                    />
                                  )}
                                  <button
                                    onClick={() =>
                                      favoriteHandler(
                                        "screener",
                                        "advancedSearch"
                                      )
                                    }
                                    className="choose-fav-btn"
                                  >
                                    Choose From Screeners
                                  </button>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        ) : candidateName && roleFilter === "screener" ? (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="container-heading-title "
                          >
                            <Typography className="scheduler-title">
                              Job Seeker Name
                            </Typography>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ padding: "0px 5px 0px 5px" }}
                              className="right-grid"
                            >
                              <div className="input-container filter-container">
                                <div
                                  class="input-field-modal right-sidebar-input"
                                  style={{ marginBottom: 10 }}
                                >
                                  <input
                                    type="text"
                                    value={candidateName}
                                    onChange={(e) =>
                                      setCandidateName(e.target.value)
                                    }
                                    disabled
                                  />
                                  {candidateName && (
                                    <CloseIcon
                                      className="right-sidebar-clear-input"
                                      fontSize="small"
                                      onClick={() => closeHandler("candidate")}
                                    />
                                  )}
                                  <button
                                    onClick={() =>
                                      favoriteHandler(
                                        "candidate",
                                        "advancedSearch"
                                      )
                                    }
                                    className="choose-fav-btn"
                                  >
                                    Choose From Job Seekers
                                  </button>
                                </div>

                                <Typography className="scheduler-title">
                                  Screener Name
                                </Typography>
                                <div
                                  class="input-field-modal right-sidebar-input"
                                  style={{ marginBottom: 10 }}
                                >
                                  <input
                                    type="text"
                                    value={screenerName}
                                    onChange={(e) =>
                                      setScreenerName(e.target.value)
                                    }
                                    disabled
                                  />
                                  {screenerName && (
                                    <CloseIcon
                                      className="right-sidebar-clear-input"
                                      fontSize="small"
                                      onClick={() => closeHandler("screener")}
                                    />
                                  )}
                                  <button
                                    onClick={() =>
                                      favoriteHandler(
                                        "screener",
                                        "advancedSearch"
                                      )
                                    }
                                    className="choose-fav-btn"
                                  >
                                    Choose From Screeners
                                  </button>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        ) : screenerName && roleFilter === "screener" ? (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="container-heading-title "
                            sx={{ overflowY: "hidden" }}
                          >
                            <div className="scheduler-buttons">
                              <div
                                style={{ margin: "0px 0px 6px 1px" }}
                                className="add-new-row-button"
                                onClick={() => roleHandler("candidate", true)}
                              >
                                <div>
                                  <AddIcon />
                                </div>
                                <div>
                                  <label className="schedule-label">
                                    Add Job Seeker{" "}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <Typography className="scheduler-title">
                              Screener Name
                            </Typography>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ padding: "0px 5px 0px 5px" }}
                              className="right-grid"
                            >
                              <div className="input-container filter-container">
                                <div
                                  class="input-field-modal right-sidebar-input"
                                  style={{ marginBottom: 10 }}
                                >
                                  <input
                                    type="text"
                                    value={screenerName}
                                    onChange={(e) =>
                                      setScreenerName(e.target.value)
                                    }
                                    disabled
                                  />
                                  {screenerName && (
                                    <CloseIcon
                                      className="right-sidebar-clear-input"
                                      fontSize="small"
                                      onClick={() => closeHandler("screener")}
                                    />
                                  )}
                                  <button
                                    className="choose-fav-btn"
                                    onClick={() =>
                                      favoriteHandler(
                                        "screener",
                                        "advancedSearch"
                                      )
                                    }
                                  >
                                    Choose From Screeners
                                  </button>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        ) : screenerName && roleFilter === "candidate" ? (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="container-heading-title "
                            sx={{ overflowY: "hidden" }}
                          >
                            <Typography className="scheduler-title">
                              Job Seeker Name
                            </Typography>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ padding: "0px 5px 0px 5px" }}
                              className="right-grid"
                            >
                              <div className="input-container filter-container">
                                <div
                                  class="input-field-modal right-sidebar-input"
                                  style={{ marginBottom: 10 }}
                                >
                                  <input
                                    type="text"
                                    value={candidateName}
                                    onChange={(e) =>
                                      setCandidateName(e.target.value)
                                    }
                                    disabled
                                  />
                                  {candidateName && (
                                    <CloseIcon
                                      className="right-sidebar-clear-input"
                                      fontSize="small"
                                      onClick={() => closeHandler("candidate")}
                                    />
                                  )}
                                  <button
                                    onClick={() =>
                                      favoriteHandler(
                                        "candidate",
                                        "advancedSearch"
                                      )
                                    }
                                    className="choose-fav-btn"
                                  >
                                    Choose From Job Seekers
                                  </button>
                                </div>

                                <Typography className="scheduler-title">
                                  Screener Name
                                </Typography>
                                <div
                                  class="input-field-modal right-sidebar-input"
                                  style={{ marginBottom: 10 }}
                                >
                                  <input
                                    type="text"
                                    value={screenerName}
                                    onChange={(e) =>
                                      setScreenerName(e.target.value)
                                    }
                                    disabled
                                  />
                                  {screenerName && (
                                    <CloseIcon
                                      className="right-sidebar-clear-input"
                                      fontSize="small"
                                      onClick={() => closeHandler("screener")}
                                    />
                                  )}
                                  <button
                                    className="choose-fav-btn"
                                    onClick={() =>
                                      favoriteHandler(
                                        "screener",
                                        "advancedSearch"
                                      )
                                    }
                                  >
                                    Choose From Screeners
                                  </button>
                                </div>
                                <div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        ) : screenerName &&
                          candidateName &&
                          (roleFilter === "candidate" ||
                            roleFilter === "screener") ? (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="container-heading-title "
                            sx={{ overflowY: "hidden" }}
                          >
                            <Typography className="scheduler-title">
                              Job Seeker Name
                            </Typography>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ padding: "0px 5px 0px 5px" }}
                              className="right-grid"
                            >
                              <div className="input-container filter-container">
                                <div
                                  class="input-field-modal right-sidebar-input"
                                  style={{ marginBottom: 10 }}
                                >
                                  <input
                                    type="text"
                                    value={candidateName}
                                    onChange={(e) =>
                                      setCandidateName(e.target.value)
                                    }
                                    disabled
                                  />
                                  {candidateName && (
                                    <CloseIcon
                                      className="right-sidebar-clear-input"
                                      fontSize="small"
                                      onClick={() => closeHandler("candidate")}
                                    />
                                  )}
                                  <button
                                    onClick={() =>
                                      favoriteHandler(
                                        "candidate",
                                        "advancedSearch"
                                      )
                                    }
                                    className="choose-fav-btn"
                                  >
                                    Choose From Job Seekers
                                  </button>
                                </div>

                                <Typography className="scheduler-title">
                                  Screener Name
                                </Typography>
                                <div
                                  class="input-field-modal right-sidebar-input"
                                  style={{ marginBottom: 10 }}
                                >
                                  <input
                                    type="text"
                                    value={screenerName}
                                    onChange={(e) =>
                                      setScreenerName(e.target.value)
                                    }
                                    disabled
                                  />
                                  {screenerName && (
                                    <CloseIcon
                                      className="right-sidebar-clear-input"
                                      fontSize="small"
                                      onClick={() => closeHandler("screener")}
                                    />
                                  )}
                                  <button
                                    className="choose-fav-btn"
                                    onClick={() =>
                                      favoriteHandler(
                                        "screener",
                                        "advancedSearch"
                                      )
                                    }
                                  >
                                    Choose From Screeners
                                  </button>
                                </div>
                                <div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        ) : candidateName && roleFilter === "candidate" ? (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            className="container-heading-title "
                            sx={{ overflowY: "hidden" }}
                          >
                            <Typography className="scheduler-title">
                              Job Seeker Name
                            </Typography>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ padding: "0px 5px 0px 5px" }}
                              className="right-grid"
                            >
                              <div className="input-container filter-container">
                                <div
                                  class="input-field-modal right-sidebar-input"
                                  style={{ marginBottom: 10 }}
                                >
                                  <input
                                    type="text"
                                    value={candidateName}
                                    onChange={(e) =>
                                      setCandidateName(e.target.value)
                                    }
                                    disabled
                                  />
                                  {candidateName && (
                                    <CloseIcon
                                      className="right-sidebar-clear-input"
                                      fontSize="small"
                                      onClick={() => closeHandler("candidate")}
                                    />
                                  )}
                                  <button
                                    onClick={() =>
                                      favoriteHandler(
                                        "candidate",
                                        "advancedSearch"
                                      )
                                    }
                                    className="choose-fav-btn"
                                  >
                                    Choose From Job Seekers
                                  </button>
                                </div>

                                <div className="scheduler-buttons">
                                  <div
                                    className="add-new-row-button"
                                    onClick={() => roleHandler("screener", false)}
                                  >
                                    <div>
                                      <AddIcon />
                                    </div>
                                    <div>
                                      <label className="schedule-label">
                                        Add Screener{" "}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        direction="row"
                      >


                        <Grid
                          item
                          xs={5}
                          md={12}
                          lg={12}
                          className="container-heading-title advanced-filter-form"
                          sx={{ margin: "1px 10px 10px 0px" }}
                        >
                          <div style={{ display: 'flex' }}>
                            <div
                              class="input-field-modal"
                              style={{ width: "40%", marginTop: 0 }}
                            >
                              <Typography className="scheduler-title">
                                Interview Date
                              </Typography>
                              <input
                                style={{ height: 35, padding: "5px" }}
                                class="position"
                                type="date"
                                min={new Date().toISOString().split("T")[0]}
                                value={date}
                                onChange={dateHandler}
                                placeholder="mm/dd/yyyy"
                              />
                            </div>
                            <div className="input-date-modal" style={{ width: '60%' }}>
                              <Typography className="scheduler-title">
                                Time Zone
                              </Typography>

                              <select
                                style={{
                                  outline: "none",
                                  marginLeft: "5px",
                                  width: "99%",
                                  padding: "5px 25px 5px 5px"
                                }}
                                value={zone}
                                onChange={(e) => setZone(e.target.value)}
                              >
                                {timezone.map((time) => {
                                  return (
                                    <option value={time.abbr}>{time.value}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          md={12}
                          lg={12}
                          className="container-heading-title advanced-filter-form"
                          sx={{
                            margin: "1px 10px 10px 0px",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <div
                            className="input-date-modal"
                            style={{ width: "100%" }}
                            onClick={() => {
                              if (!date) {
                                setMessage("Please fill the interview date");
                                setOpenSnackbar(true);
                              }
                            }}
                          >
                            <Typography className="scheduler-title">
                              Interview Time
                            </Typography>
                            <div className='quick-select-time'>

                              <select
                                value={hours}
                                onChange={(e) => handleTimeChange(e.target.value, 'hours')}
                                style={{ outline: "none" }}
                                disabled={date ? false : true}
                              >
                                <option selected disabled value="default">
                                  Select Time
                                </option>
                                {hoursArray.map((time) => (
                                  <option key={time} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={minutes}
                                onChange={(e) => handleTimeChange(e.target.value, 'minutes')}
                                style={{ outline: "none" }}
                                disabled={date ? false : true}
                              >
                                <option selected disabled value="default">
                                  Select Time
                                </option>
                                {minutesArray.map((time) => (
                                  <option key={time} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={meridian}
                                onChange={(e) => handleTimeChange(e.target.value, 'merdian')}
                                style={{ outline: "none" }}
                                disabled={date ? false : true}
                              >
                                <option selected disabled value="default">
                                  Select Time
                                </option>
                                {meridianArray.map((time) => (
                                  <option key={time} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          md={12}
                          lg={12}
                          className="container-heading-title advanced-filter-form"
                          sx={{ margin: "1px 10px 10px 0px" }}
                        >
                          <div className="input-date-modal">
                            <div>
                              <div className="scheduler-buttons" style={{ display: 'flex' }}>
                                <div class="input-field mobile-input-field ">
                                  <label className="date-label" style={{ fontSize: "12px" }}>Meeting Link</label>
                                  <div className='generate-link-container' >
                                    <div className={`generate-link-btn ${meetingPlatform == "Jitsi" ? "active" : ""}`} onClick={generateJitsiLink}>
                                      Jitsi Link <LinkIcon className='meet-link-icon' />
                                    </div>

                                    {/* <div className={`generate-link-btn ${meetingPlatform == "Zoom" ? "active" : ""}`} onClick={generateLinkHandler}>
                                      Zoom Link <LinkIcon className='meet-link-icon' />
                                    </div> */}
                                    <div className={`generate-link-btn ${meetingPlatform == "Custom" ? "active" : ""}`} onClick={customLinkHandler}>
                                      Custom Link <LinkIcon className='meet-link-icon' />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {(interviewLink != "" || customLink) && (
                                <div
                                  className="input-container filter-container"
                                  style={{
                                    position: "relative",
                                    background: "#eee",
                                  }}
                                >
                                  <div class="input-field-modal">
                                    <input
                                      disabled={!customLink}
                                      type="text"
                                      ref={meetLinkRef}
                                      value={interviewLink}
                                      onChange={(e) =>
                                        setInterviewLink(e.target.value)
                                      }
                                      style={{ marginLeft: "5px", width: "81%" }}
                                    />
                                  </div>
                                  <ContentCopyIcon
                                    onClick={() =>
                                      copyTextToClipboard(interviewLink)
                                    }
                                    className="meet-link-icons"
                                    fontSize="small"
                                    sx={{
                                      position: "absolute",
                                      fontSize: "14px",
                                      cursor: "pointer",
                                      top: "16px",
                                      right: "9px",
                                      fontSize: "15px",
                                      opacity: "50%",
                                    }}
                                  />
                                  {/* {!customLink && <RefreshIcon
                                    onClick={generateLinkHandler}
                                    className="meet-link-icons"
                                    fontSize="small"
                                    sx={{
                                      position: "absolute",
                                      fontSize: "14px",
                                      cursor: "pointer",
                                      top: "14px",
                                      right: "9px",
                                      fontSize: "18px",
                                      opacity: "50%",
                                    }}
                                  />} */}
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                marginTop: "26px",
                                marginBottom: "20px"
                              }}
                            >
                              <button
                                onClick={saveHandler}
                                style={{
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                  border: "1px solid #f06161",
                                  borderRadius: "5px",
                                  color: "#f06161",
                                  backgroundColor: "white",
                                  fontWeight: "normal"
                                }}
                                className="btn-style outlined-btn outlined-btn-animation"
                              >
                                {" "}
                                Save for Later
                              </button>

                              <button
                                onClick={scheduleHandler}
                                style={{
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                  border: "1px solid #f06161",
                                  borderRadius: "5px",
                                  color: " white",
                                  fontWeight: "normal",
                                  backgroundColor: "#f06161",
                                }}
                                className="btn-style filled-btn filled-btn-animation"
                              >
                                {" "}
                                Schedule Now
                              </button>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Box>
        </div >

        {/* Alert Dialog  */}
        <RemoveFavoritesDialog
          removeFavoritesHandler={removeFavoritesHandler}
          setOpenAlertDialog={setOpenAlertDialog}
          openAlertDialog={openAlertDialog}
        />

        {/* Cancel Interview dialog */}
        <CancelInterviewDialog
          cancelInterview={cancelInterview}
          setDialogOpen={setDialogOpen}
          dialogOpen={dialogOpen}
        />

        {/* Snackbar modal */}
        < UserSnackbar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          message={message}
        />

        <ShareProfileModal
          resume={selectedItem?.resume}
          firstName={selectedItem?.firstName}
          lastName={selectedItem?.lastName}
          role={roleFilter}
          openShareModal={openShareModal}
          setOpenShareModal={setOpenShareModal}
          profilePublicLink={profilePublicLink}
          includeResume={includeResume}
          setIncludeResume={setIncludeResume}
          senderName={companyName}
          replyToEmail={replyToEmail}
        />

        <AddNoteModal
          userId={selectedItem?._id}
          role={roleFilter}
          usersList={allUsers}
          setUsersList={setAllUsers}
          companyId={id}
          openNoteModal={openNoteModal}
          setOpenNoteModal={setOpenNoteModal}
          profileId={id}
          module={'favoritesModule'}
        />

        <ViewNotes
          openViewNoteModal={openViewNoteModal}
          setOpenViewNoteModal={setOpenViewNoteModal}
          user={viewNoteUser}
          setUser={setViewNoteUser}
          companyId={id}
          profileId={id}
          unreadNotesCount={unreadNotesCount}
          setUnreadNotesCount={setUnreadNotesCount}
          getInternalUsers={getInternalUsers}
        />

      </div >
      <Footer sidebarOpen={sidebarOpen} />

    </>
  );
};

export default FavoritesList;
