import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

//MUI imports
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ResendIcon from "@mui/icons-material/Telegram";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

// CSS import
import "../CompanyAdmin.css";

import TableLoader from "../../loaders/TableLoader";
import UserSnackBar from "../../snackBars/UserSnackbar";

const InvitationList = ({ sidebarOpen, setsidebarOpen, setPageName, pageName }) => {
  //Account Id and role
  const id = sessionStorage.getItem("id");
  const companyId = sessionStorage.getItem("companyId");
  const role = sessionStorage.getItem("role");

  const columns = [
    {
      title: "Name",
      width: "26%",
    },
    {
      title: "Email",
      width: "28%",
    },
    {
      title: "Profile Type",
      width: "17%",
    },
    {
      title: "Status",
      width: "17%",
    },
    {
      title: "Action",
      width: "12%",
    },
  ];


  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  //snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  const [sendButtonClicked, setSendButtonClicked] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);

  //Loader
  const [tableLoading, setTableLoading] = useState(true);

  //Form States
  const [filter, setFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("All Statuses");
  const [roleFilter, setRoleFilter] = useState("All Roles");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [allInvites, setAllInvites] = useState([]);
  const [selectedInvite, setSelectdInvite] = useState({});

  //pagination states
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  //Refs
  const inputRef = useRef(null);
  const [inputPosition, setInputPosition] = useState(0)

  //setting document title
  useEffect(() => {
    document.title = "Screener | Invitations";
  }, []);

  //defining input cursor position
  useEffect(() => {
    inputRef.current.selectionStart = inputPosition;
    inputRef.current.selectionEnd = inputPosition;
  }, [debouncedTerm]);


  //getting data from backend
  const getInvites = async () => {
    try {
      const { data } = await axios.get(
        `/api/admin/invitations/${id}/?statusFilter=${statusFilter}&filter=${filter}&roleFilter=${roleFilter}`
      );
      if (data) {
        let invitations = data.filter((item) => item.isDeleted === false);
        setTableLoading(false);
        invitations = invitations.reverse();
        setAllInvites(invitations);
      } else {
        setTableLoading(false);
        setAllInvites(data);
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      setTableLoading(false);
    }
  };
  

  useEffect(() => {
    setPage(0);
    getInvites();
  }, [sendButtonClicked, filter, statusFilter, roleFilter, deleteFlag, pageName]);

  //Debouncing (searching)
  useEffect(() => {
    const timer = setTimeout(() => setFilter(debouncedTerm), 500);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  //changing page 
  const formHandler = () => {
    setPageName("sendInvitation")
    setSendButtonClicked(true);
    document.title = "Screener | Send Invitations";
  };

  //filter handler
  const handleDebouncedTerm = (e) => {
    setDebouncedTerm(e.target.value);
    setInputPosition(e.target.selectionStart);
  }

  //handling focus of input field
  const handleFocus = (e) => {
    e.target.setSelectionRange(inputPosition, inputPosition)
  }

  //Open dialog or snackbar
  const handleResendMail = (invite) => {
    setSelectdInvite(invite);
    if (invite.status === "Pending") {
      setOpenSnackbar(false);
      setOpenDelete(true);
    } else {
      setMessage("Invite already accepted. Cannot re-send invite!");
      setOpenSnackbar(true);
    }
  };

  //re-send mail
  const resendMailHandler = () => {
    setOpenDelete(false);
    axios
      .post("/api/admin/resend/invitation", selectedInvite)
      .then((res) => {
        setMessage("Email sent successfully!");
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //pagination handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //deleteHandler
  const deleteHandler = (item, index) => {
    setOpenDeleteDialog(true);
    setSelectdInvite(item);
  };

  const deleteInvite = () => {
    setOpenDeleteDialog(false);
    setDeleteFlag(!deleteFlag);
    axios.post("/api/admin/delete/invitation", selectedInvite).then((res) => {
    });
  };



  return (
    <div>
      {/* User List Component */}
      <div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}` }}>
        <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
          <Card id="main-edit" className="add-recruiter-form form-card">
            <Grid
              container
              spacing={0}
              // direction="row"
              className="add-recruiter-card-container add-clients-container"
            >
              <Grid
                item
                xs={4}
                sm={4}
                md={6}
                lg={6}
                className="container-heading-title admin-table"
              >
                <h1>Invitation</h1>
              </Grid>
              <Grid
                item
                xs={7}
                sm={7}
                md={5}
                lg={5}
                className="container-heading-buttons add-recruiter-button"
                style={{ width: "100%" }}
              >
                <div style={{ display: "flex", gap: "10px", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
                  <button className="add-recruiter" onClick={formHandler}>
                    Send Invite
                  </button>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
            // className="profile-progress-bar"
            >
              <Grid
                id="profile-completion-percentage"
                item
                xs={12}
                sm={8}
                md={6}
                lg={6}
                sx={{ textAlign: "center" }}
                className="container-profile"
              >
                <form className="experience-edit-form" noValidate>
                  <div class="add-recruiter-input-container myUsers">
                    <div class="input-field">
                      <input
                        class="position"
                        type="text"
                        defaultValue={debouncedTerm}
                        onChange={handleDebouncedTerm}
                        placeholder="Search"
                        onFocus={handleFocus}
                        ref={inputRef}
                        autoFocus={
                          inputRef.current === document.activeElement
                        }
                      />
                    </div>
                    <div className="date-dropdown-picker">
                      {/* Filter based on user status */}
                      <div class="input-field">
                        <select
                          value={statusFilter}
                          onChange={(e) => {
                            setStatusFilter(e.target.value);

                          }}
                        >
                          <option value="All Statuses" selected>
                            All statuses
                          </option>
                          <option value="Accepted">Accepted</option>
                          <option value="Pending">Pending</option>
                        </select>
                      </div>

                      {/* Filter based on user role */}
                      <div class="input-field">
                        <select
                          value={roleFilter}
                          onChange={(e) => {
                            setRoleFilter(e.target.value);
                          }}
                        >
                          <option value="All Roles" selected>
                            All Roles
                          </option>
                          <option value="screener">Screeners</option>
                          <option value="candidate">Candidates</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </Grid>
            </Grid>
          </Card>

          {/* invitation list table */}
          <Card
            id="skills-edit"
            className="add-recruiter-form-container"
            style={{ boxShadow: "none", borderRadius: "2px" }}
          >
            <div className="recruiter-list">
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="recruiter-list-heading"
              >
                {columns.map((item, index) => {
                  return (
                    <h6
                      style={{
                        width: `${item.width}`,
                        background: index % 2 ? "rgba(240, 97, 97, 37%)" : "rgba(240, 97, 97, 17%)",
                      }}
                    >
                      {item.title}
                    </h6>
                  );
                })}
              </Grid>
              {tableLoading ? (
                <TableLoader page="userList" />
              ) : (
                <div className="recruiter-details-list">
                  {allInvites.length > 0 ? (
                    allInvites
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        let nameLength =
                          item.firstName.length + item.lastName.length;
                        {
                          return (
                            <>
                              <div
                                className={` recruiter-details ${index % 2
                                  ? ""
                                  : "alternate-recruiterlist-bg"
                                  }`}
                              >
                                <div className="invitation-list-name"
                                  style={{ width: "27%" }}
                                >
                                  {nameLength > 23 ? (
                                    <Tooltip
                                      title={`${item.firstName} ${item.lastName}`}
                                    >
                                      <p>
                                        {item.firstName} {item.lastName}
                                      </p>
                                    </Tooltip>
                                  ) : (
                                    <p>
                                      {item.firstName} {item.lastName}
                                    </p>
                                  )}
                                </div>

                                <div className="invitation-list-email "
                                  style={{ width: "33%" }}>
                                  {item.email.length > 24 ? (
                                    <Tooltip title={`${item.email}`}>
                                      <p>{item.email}</p>
                                    </Tooltip>
                                  ) : (
                                    <p>{item.email}</p>
                                  )}
                                </div>

                                <div className="invitation-list-role"
                                  style={{ width: "18%" }}>
                                  {item.role.length > 24 ? (
                                    <Tooltip title={`${item.role}`}>
                                      <p>{item.role}</p>
                                    </Tooltip>
                                  ) : (
                                    <p>{item.role}</p>
                                  )}
                                </div>

                                <div className="invitation-status-button invites-status-button">
                                  <button className={` ${item.status}`}>
                                    {item.status}
                                  </button>
                                </div>

                                <div className=" invitation-action-buttons">
                                  <div className="action-button-icon">
                                    <Tooltip title="Resend Mail">
                                      <ResendIcon
                                        fontSize="small"
                                        onClick={() => {
                                          handleResendMail(item);
                                        }}
                                      />
                                    </Tooltip>
                                  </div>

                                  <div className="action-button-icon">
                                    <Tooltip title="Delete">
                                      <DeleteIcon
                                        fontSize="small"
                                        onClick={() =>
                                          deleteHandler(item, index)
                                        }
                                      />
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      })
                  ) : (
                    <p>No Invitees</p>
                  )}
                </div>
              )}
            </div>
            <TablePagination
              id="recruiter-list-pagination"
              component="div"
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={allInvites.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              variant="outlined"
            />
          </Card>
        </Box>

        {/* Resend Invitation dialog */}
        <Dialog
          open={openDelete}
          onClose={openDelete}
          className="experience-edit-dialog"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="dialog-content">
            <Grid container spacing={0} justifyContent="center">
              <Grid
                xs={11.5}
                sm={11.5}
                md={11.5}
                lg={11.5}
                className="experience-delete-grid"
              >
                <div className="delete-dialog-content">
                  <h5>Are you sure ?</h5>
                  <p>{`Are you sure you want to resend invitation to this ${selectedInvite.role} ?`}</p>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <div className="button-container delete-btn-container">
            <button className="warning-btn-style filled-btn filled-btn-animation" onClick={() => setOpenDelete(false)}>
              {" "}
              Cancel
            </button>
            <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={resendMailHandler}>
              {" "}
              Confirm
            </button>
          </div>
        </Dialog>

        {/* Delete Dialog */}
        <Dialog
          open={openDeleteDialog}
          onClose={openDeleteDialog}
          className="experience-edit-dialog"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="dialog-content">
            <Grid container spacing={0} justifyContent="center">
              <Grid
                xs={11.5}
                sm={11.5}
                md={11.5}
                lg={11.5}
                className="experience-delete-grid"
              >
                <div className="delete-dialog-content">
                  <h5>Are you sure ?</h5>
                  <p>{`Are you sure you want to delete this ${selectedInvite.role} ?`}</p>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <div className="button-container delete-btn-container">
            <button
              className="warning-btn-style filled-btn filled-btn-animation"
              onClick={() => setOpenDeleteDialog(false)}
            >
              {" "}
              Cancel
            </button>
            <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={deleteInvite}>
              {" "}
              Confirm
            </button>
          </div>
        </Dialog>
      </div>

      {/* SnavkBar component */}
      <UserSnackBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
      />

    </div>
  );
};

export default InvitationList;
