import React, { useState } from "react";

const Services = () => {
  const [img1] = useState(
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1669876985/Landing%20Page/img-6-min_lan84t.jpg"
  );
  const [img2] = useState(
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1669876986/Landing%20Page/img-7-min_lpjb1n.jpg"
  );
  const [img3] = useState(
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1669876985/Landing%20Page/img-5-min_vtuzms.jpg"
  );

  return (
    <section className="section" id="services">
      <div className="container">
        <div className="container-heading">
          <h6 className="xs-font mb-0">What we are good at.</h6>
          <h3 className="section-title mb-4">Services</h3>
        </div>
        <div className="row text-center">
          <div className="col-lg-4">
            <a href="#" className="card border-0 text-dark">
              <img className="card-img-top" src={img1} alt="Sourcing Candidates" />
              <span className="card-body">
                <h4 className="title mt-4">Sourcing Candidates</h4>
                <p className="xs-font">
                  Screener has a track record of connecting job seekers to their
                  desired jobs.
                </p>
              </span>
            </a>
          </div>
          <div className="col-lg-4">
            <a href="#" className="card border-0 text-dark">
              <img className="card-img-top" src={img2} alt="Interview Tracking" />
              <span className="card-body">
                <h4 className="title mt-4">Interview Tracking</h4>
                <p className="xs-font">
                  The job seekers, screeners, and recruitment team can easily
                  keep track of interviews.
                </p>
              </span>
            </a>
          </div>
          <div className="col-lg-4">
            <a href="#" className="card border-0 text-dark">
              <img className="card-img-top" src={img3} alt="Professional Screeners" />
              <span className="card-body">
                <h4 className="title mt-4">Professional Screeners</h4>
                <p className="xs-font">
                  Around 2000+ expert and professional screeners to screen the
                  job seekers.
                </p>
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
