import * as React from 'react';

// Import MUI components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

// Import MUI icons
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Declaration = ({ setTabValue, isDeclarationChecked, setIsDeclarationChecked }) => {
    const [checked, setChecked] = React.useState(isDeclarationChecked);

    React.useEffect(() => {
        setChecked(isDeclarationChecked);
    }, [isDeclarationChecked]);
 
    const handleClickNext = () => {
        setTabValue(3); // Change the tab to "Environment Setup"
    };

    const handleClickPrevious = () => {
        setTabValue(1); // Change the tab to "System Checks"
    };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
        setIsDeclarationChecked(isChecked); // Update parent state
    };

    return (
        <>
            <div className='proctor-screen'>
                <div className='heading'>
                    <h5>Declaration</h5>
                    <hr style={{ marginTop: '25px' }} />
                </div>
                <h6
                    style={{ position: 'relative', left: '15px', top: '5px' }}
                >
                    Please agree to continue
                </h6>
                <div className='declaration'>
                    <Checkbox
                        {...label}
                        checked={checked}
                        onChange={handleCheckboxChange}
                        sx={{
                            '&.Mui-checked': {
                                color: '#F06161',
                            },
                        }}
                    />
                    <p
                        onClick={() => {
                          const newChecked = !checked;
                          setChecked(newChecked);
                          setIsDeclarationChecked(newChecked); // Ensure parent state is also updated
                        }}
                        style={{ cursor: 'pointer' }} // Optional: Change cursor to pointer to indicate clickability
                    >
                        I hereby declare that I have not impersonated, or allowed myself to be impersonated by any
                        person for the purposes of this assessment. All work submitted in this assessment
                        will be my own work and does not involve plagiarism or teamwork
                    </p>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', width: '300px' }}>
                    <Button
                        variant='contained'
                        className='proctor-previous-button'
                        onClick={handleClickPrevious}
                    >
                        <ArrowLeftIcon style={{ position: 'relative', right: '3px' }} />
                        Previous
                    </Button>
                    <Button
                        variant='contained'
                        className='proctor-next-button'
                        style={{ position: 'relative', left: '20px' }}
                        onClick={handleClickNext}
                        disabled={!checked}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </>
    );
};

export default Declaration;
