import React, { useEffect, useState, useRef, useDebugValue } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import * as XLSX from "xlsx";
import moment from "moment-timezone";
import { v4 as uuidv4 } from 'uuid';

// Mui imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import { CircularProgress } from '@mui/material';
import { TextField } from '@mui/material';

import { Autocomplete } from '@mui/material';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// icon imports
import RefreshIcon from "@mui/icons-material/Refresh";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddIcon from "@mui/icons-material/Add";
import ResendIcon from "@mui/icons-material/Telegram";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/PostAdd";
import ViewNoteIcon from "@mui/icons-material/Assignment";
import ChangeProfileIcon from "@mui/icons-material/PublishedWithChanges";
import FavoriteIcon from "@mui/icons-material/Star";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ViewIcon from "@mui/icons-material/Visibility";
import ResumeIcon from "@mui/icons-material/Description";
import ShareIcon from "@mui/icons-material/Share";
import EmailIcon from "@mui/icons-material/Email";
import OpenArrow from "@mui/icons-material/ChevronLeft";
import CloseArrow from "@mui/icons-material/ChevronRight";
import EventIcon from "@mui/icons-material/Event";
import ActiveIcon from "@mui/icons-material/AirplanemodeActive";
import InactiveIcon from "@mui/icons-material/AirplanemodeInactive";
import LinkIcon from '@mui/icons-material/Link';

import CompanyMenu from "../../userProfiles/CompanyMenu";
import TableLoader from "../../loaders/TableLoader";
import AddMultipleUsers from "./AddMultipleUsers";
import UserSnackbar from "../../snackBars/UserSnackbar";
import { timezone } from "../../../data-files/timezone";
import PageNotFound from "../../ErrorHandling/PageNotFound";
import AddUsers from "./Add Users/AddUsers";
import Footer from "../../../Footer/Footer";
import EditUsers from "./Add Users/EditUsers";
import ShareProfileModal from "./Modals/ShareProfileModal";
import AddNoteModal from "./Modals/AddNoteModal";
import ViewNotes from "./Modals/ViewNotes";
import CancelInterview from "./Modals/CancelInterview";
import DeleteUser from "./Modals/DeleteUser";
import ParseResume from "./Add Users/ParseResume";


//time details
const hoursArray = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
const minutesArray = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];
const meridianArray = ["AM", "PM"];



// action menu
const actionOptions = [
  {
    icon: <ShareIcon fontSize="1rem" />,
    name: "Share",
  },
  {
    icon: <EmailIcon fontSize="1rem" />,
    name: "Send Mail",
  },
  {
    icon: <NoteAddIcon fontSize="1rem" />,
    name: "Add Note",
  },
  {
    icon: <FavoriteIcon fontSize="1rem" />,
    name: "Add to Favorites",
  },
  {
    icon: <ViewIcon fontSize="1rem" />,
    name: "View Profile",
  },
  {
    icon: <ResumeIcon fontSize="1rem" />,
    name: "Preview Resume",
  },
  {
    icon: <ScheduleIcon fontSize="1rem" />,
    name: "Schedule Interview",
  },
  {
    icon: <InactiveIcon fontSize="1rem" />,
    name: "Deactivate",
  },
  {
    icon: <ChangeProfileIcon fontSize="1rem" />,
    name: "Change Profile",
  },
  {
    icon: <DeleteIcon fontSize="1rem" />,
    name: "Delete",
  },
];

const JITSI_URL = 'https://meet.jit.si';

//userList settings
const UsersList = () => {
  const columns = [
    {
      title: "Name",
      width: "27%",
    },
    {
      title: "Title",
      width: "18%",
    },
    {
      title: "Total Experience",
      width: "20%",
    },
    {
      title: "Expected Salary",
      width: "18%",
    },
    {
      title: "Email Id",
      width: "28%",
    },
    {
      title: "Phone Number",
      width: "18%",
    },
    {
      title: "Skills",
      width: "30%",
    },
    {
      title: "Current Location",
      width: "20%",
    },
    {
      title: "Highest Education",
      width: "20%",
    },
    {
      title: "DOB",
      width: "15%",
    },
    {
      title: "Gender",
      width: "10%",
    },
    {
      title: "Nationality",
      width: "10%",
    },
    {
      title: "Status",
      width: "10%",
    },
    {
      title: "Actions",
      width: "7%",
    },
  ];

  const convertedTableColumns = [
    {
      title: "Name",
      width: "26%",
    },
    {
      title: "Email Id",
      width: "28%",
    },
    {
      title: "Phone Number",
      width: "25%",
    },
    {
      title: "Status",
      width: "18%",
    },
    {
      title: "Actions",
      width: "10%",
    },
  ];

  //push for navigation
  const navigate = useNavigate();

  const meetLinkRef = useRef(null);

  //Session storage values
  const id = sessionStorage.getItem("id");
  const companyId = sessionStorage.getItem("companyId");
  const role = sessionStorage.getItem("role");
  const token = sessionStorage.getItem("jwtToken");

  // shre email body
  const [replyToEmail, setReplyToEmail] = useState("");

  // template file link for adding multiple user
  const templateFileLink =
    "https://res.cloudinary.com/df2qsnyox/raw/upload/v1694681923/Excel%20Sheet/Add_Users_Template.xlsx";
  const [fileName, setFileName] = useState("");
  let columnHeaders = []; //to store the headings of the excel file

  //Navbar states
  const [open, setOpen] = useState();
  const [sidebarOpen, setsidebarOpen] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [interacted, setInteracted] = useState("");
  const [croppedImg, setCroppedImg] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [defaultAvatar, setDefaultAvatar] = useState("");
  const [dragging, setDragging] = useState("");

  // loading states
  const [loading, setLoading] = useState(true);
  const [linkLoader, setLinkLoader] = useState(false);

  //Dialog box states
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  // scheduler States
  const [rightSidebarOpen, setRightSidebarOpen] = useState(
    false || localStorage.getItem("rightSidebarOpen")
  );
  const [scheduleClicked, setScheduleClicked] = useState(false);

  //quick scheduler states
  const [zone, setZone] = useState("Asia/Kolkata");
  const [interviewLink, setInterviewLink] = useState("");
  const [customLink, setCustomLink] = useState(false);
  const [meetingPlatform, setMeetingPlatform] = useState("")
  const [time, setTime] = useState("12:15 PM");
  const [date, setDate] = useState("");
  const [interviewTitle, setInterviewTitle] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [screenerName, setScreenerName] = useState("");
  const [candidateDetails, setCandidateDetails] = useState({
    name: "",
    id: "",
    email: "",
  });
  const [screenerDetails, setScreenerDetails] = useState({
    name: "",
    id: "",
    email: "",
  });

  //snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  //pagination states
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  //Modal States
  const [mode, setMode] = useState("");
  const [addUserClicked, setAddUserClicked] = useState(false);
  const [refreshCount, setRefreshCount] = useState(1);
  const [addMultipleUsersClicked, setAddMultipleUsersClicked] = useState(false);
  const [roleFilter, setRoleFilter] = useState("candidate");
  const [selectedRole, setSelectedRole] = useState(true);

  //page rendering
  const [viewProfile, setViewProfile] = useState(false);

  //time states
  const [hours, setHours] = useState("12");
  const [meridian, setMeridian] = useState("PM");
  const [minutes, setMinutes] = useState("15");
  //List states
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
  const [allUsers, setAllUsers] = useState([]);
  const [convertedUsers, setConvertedUsers] = useState([]);

  //filter States
  const [nameFilter, setNameFilter] = useState("");
  const [titleFilter, setTitleFilter] = useState("");
  const [experienceFilter, setExperienceFilter] = useState("");
  const [salaryFilter, setSalaryFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [skillsFilter, setSkillsFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [educationFilter, setEducationFilter] = useState("");
  const [dobFilter, setDobFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [nationalityFilter, setNationalityFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [tableView, setTableView] = useState("internalUsers");
  const [internalUsersSelected, setInternalUsersSelected] = useState(true);

  const [clientsList, setClientsList] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  //client states
  const [clientName, setClientName] = useState("");
  const [clientDetails, setClientDetails] = useState({
    id: "",
    name: "",
    email: "",
  });

  //position states
  const [positionName, setPositionName] = useState("");
  const [positionDetails, setPositionDetails] = useState({ id: "", name: "" });

  //selected profile states
  const [selectedItem, setSelectedItem] = useState();
  const [selectedUserId, setSelectedUserId] = useState();

  //Action dropdown
  const [actionMenu, setActionMenu] = useState(null);
  const openActionMenu = Boolean(actionMenu);

  const fileRef = useRef(null); // file input element
  const [finalDisplay, setFinalDisplay] = useState([]);
  let [filterArray, setFilterArray] = useState([]);

  const [hasInternalDbAccess, setHasInternalDbAccess] = useState(true);

  //share profile states
  const [profilePublicLink, setProfilePublicLink] = useState("");
  const [includeResume, setIncludeResume] = useState(false);

  //share profile modal states
  const [openShareModal, setOpenShareModal] = useState(false);

  const [resumeParseClicked, setResumeParseClicked] = useState(false)

  // add note modal state
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openViewNoteModal, setOpenViewNoteModal] = useState(false);

  const [viewNoteUser, setViewNoteUser] = useState();
  const [unreadNotesCount, setUnreadNotesCount] = useState();

  let [customHeight, setCustomHeight] = useState("");

  function timeConvertor(interviewTime) {
    let timeArray = interviewTime.split(":");
    const hour = timeArray[0];
    setHours(hour);
    const min = timeArray[1].slice(0, 2);
    setMinutes(min);
    const mer = timeArray[1].slice(3);
    setMeridian(mer);
  }

  // function mouseGrabbing(){
  //   return  document.querySelector(".skills-scroll").style.cursor = 'grabbing'
  // }

  // function mouseDefault(){
  //   return  document.querySelector(".skills-scroll").style.cursor = 'default'
  // }

  // // useEffect(()=>{
  //   const skills=document.querySelector(".skills-scroll")
  //   skills.addEventListener("mousedown",mouseGrabbing)

  //   skills.addEventListener("mouseup",mouseDefault)
  // // },[])

  useEffect(() => {
    // get local time from local storage
    const localTime = localStorage.getItem("time");
    if (localTime) {
      timeConvertor(localTime);
    }

    // set the custom height for right side bar if scrolled down
    if (window.pageYOffset < 93) {
      setCustomHeight("");
    } else {
      setCustomHeight("custom-height");
    }
    getAllClients();
  }, []);

  window.addEventListener("scroll", () => {
    if (window.pageYOffset < 93) {
      setCustomHeight("");
    } else {
      setCustomHeight("custom-height");
    }
  });

  //set the page number to 0 and filter the result whenever filter is applied
  useEffect(() => {
    setPage(0);
    getFiltered();
  }, [
    nameFilter,
    emailFilter,
    titleFilter,
    phoneFilter,
    experienceFilter,
    salaryFilter,
    skillsFilter,
    locationFilter,
    educationFilter,
    dobFilter,
    genderFilter,
    nationalityFilter,
    statusFilter,
  ]);

  // function copy the text to clipboard
  const copyTextToClipboard = async (text) => {
    navigator.clipboard.writeText(text);
    setMessage("Email copied to clipboard");
    setOpenSnackbar(true);
  };

  //function to open the right side bar when clicked on arrow button
  const openArrowHandler = () => {
    setRightSidebarOpen((prev) => !prev);
    setOpen(false);
  };

  // function to close right side bar
  const closeAllHandler = () => {
    setDialogOpen(true);
  };

  const closeArrowHandler = () => {
    setRightSidebarOpen((prev) => !prev);
  };

  // function to remove the choosen screener or candidate in right side bar
  const closeHandler = (role) => {
    if (candidateName && screenerName) {
      if (role === "candidate") {
        setCandidateName("");
      } else {
        setScreenerName("");
      }
    } else {
      setDialogOpen(true);
    }
  };

  // function to generate meeting link
  const generateLinkHandler = () => {
    setLinkLoader(true);
    setMeetingPlatform('Zoom')
    setInterviewLink("Loading...")
    axios
      .get(`/api/schedule/generate`)
      .then((res) => {
        setLinkLoader(false);
        setInterviewLink(res.data.join_url);
        setCustomLink(false);
      })
      .catch((err) => {
        setMessage("Zoom server is busy at the moment.");
        setOpenSnackbar(true);
        setLinkLoader(false);
        setCustomLink(false);
        console.log(err);
      });
  };

  const generateJitsiLink = () => {
    try {
      setMeetingPlatform("Jitsi")
      const meetId = uuidv4();
      const meetingURL = `${JITSI_URL}/${meetId}`
      console.log(meetingURL)
      setInterviewLink(meetingURL)
    } catch (err) {
      setMessage("Jitsi server is busy at the moment.");
      setOpenSnackbar(true);
    }
  }

  const customLinkHandler = () => {
    setCustomLink(c => true);
    setMeetingPlatform("Custom")
    setInterviewLink('')

    if (meetLinkRef.current) {
      // Set focus using setTimeout to ensure it's focused on the next render cycle
      setTimeout(() => {
        meetLinkRef.current.focus();
      }, 0);
    }
  };

  //roleHandler
  const roleHandler = (roleName, selected) => {
    setRoleFilter(roleName);
    setSelectedRole(selected);
  };

  const dateHandler = (e) => {
    setDate(e.target.value);
  };

  const handleTimeChange = (value, field) => {
    if (field == "hours") {
      setHours(value);
      setTime(`${value}:${minutes} ${meridian}`);
    } else if (field == "minutes") {
      setMinutes(value);
      setTime(`${hours}:${value} ${meridian}`);
    } else {
      setMeridian(value);
      setTime(`${hours}:${minutes} ${value}`);
    }
  };

  //save for later handler
  const saveHandler = () => {
    localStorage.removeItem("title");
    if (!interviewTitle) {
      //error for title
      setMessage("Please fill the interview title");
      setOpenSnackbar(true);
    } else if (!screenerName && !candidateName) {
      setMessage("Please fill either candidate or screener name");
      setOpenSnackbar(true);
    } else if (clientName && !clientDetails.id) {
      setMessage("Please choose one among the list of Clients");
      setOpenSnackbar(true);
    } else if (positionName && !positionDetails.id) {
      setMessage("Please choose one among the list of Positions");
      setOpenSnackbar(true);
    } else {
      const newData = {
        candidateDetails,
        companyId,
        id,
        screenerDetails,
        interviewTitle,
        interviewLink,
        meetingPlatform,
        zone,
        date,
        time,
        interviewStatus: "To Schedule",
        scheduledBy: id,
        role: role,
        clientDetails,
        positionDetails,
        rescheduledBy: { id: "", name: "", email: "" },
        declinedBy: { id: "", name: "", email: "" },
      };

      axios
        .post("/api/schedule/scheduleinterview", { newData })
        .then((res) => {
          cancelInterview();
          setMessage("Interview Saved for Later");
          setOpenSnackbar(true);
        })
        .catch((err) => {
          setMessage("Oops... There is some error");
          setOpenSnackbar(true);
        });
    }
  };

  const scheduleHandler = () => {
    if (
      interviewTitle &&
      interviewLink &&
      candidateName &&
      screenerName &&
      time &&
      zone &&
      date
    ) {
      if (clientName && !clientDetails.id) {
        setMessage("Please choose one among the list of Clients");
        setOpenSnackbar(true);
        return;
      } else if (positionName && !positionDetails.id) {
        setMessage("Please choose one among the list of Positions");
        setOpenSnackbar(true);
        return;
      }
      const newData = {
        candidateDetails,
        companyId,
        id,
        screenerDetails,
        interviewTitle,
        interviewLink,
        meetingPlatform,
        zone,
        date,
        time,
        interviewStatus: "Scheduled",
        scheduledBy: id,
        role: role,
        clientDetails,
        positionDetails,
        rescheduledBy: { id: "", name: "", email: "" },
        declinedBy: { id: "", name: "", email: "" },
      };
      axios
        .post("/api/schedule/scheduleinterview", { newData })
        .then((res) => {
          setMessage("Interview Scheduled successfully!");
          setOpenSnackbar(true);
          cancelInterview();
        })
        .catch((err) => {
          setMessage("Oops... There is some error");
          setOpenSnackbar(true);
        });
    } else {
      //show a dialogue saying there is some error
      setMessage("Please fill all the fields");
      setOpenSnackbar(true);
    }
  };

  const cancelInterview = () => {
    setRightSidebarOpen(false);
    setScheduleClicked(false);
    setDialogOpen(false);
    setInterviewTitle("");
    setInterviewLink("");
    setMeetingPlatform("")
    setCandidateName("");
    setScreenerName("");
    setZone("Asia/Kolkata");
    setTime("12:15 PM");
    setDate("");
    setCandidateDetails({
      name: "",
      id: "",
      email: "",
    });
    setScreenerDetails({
      name: "",
      id: "",
      email: "",
    });
    clearLocalStorage();
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("title");
    localStorage.removeItem("candidate");
    localStorage.removeItem("screener");
    localStorage.removeItem("link");
    localStorage.removeItem("meetingPlatform");
    localStorage.removeItem("date");
    localStorage.removeItem("time");
    localStorage.removeItem("zone");
    localStorage.removeItem("role");
    localStorage.removeItem("candidateDetails");
    localStorage.removeItem("screenerDetails");
    localStorage.removeItem("navigateTo");
    localStorage.removeItem("scheduled");
    localStorage.removeItem("client");
    localStorage.removeItem("position");
    localStorage.removeItem("clientDetails");
    localStorage.removeItem("positionDetails");
  };

  //conversion of date in the excel sheet to normal format(JS)
  const ExcelDateToJSDate = (date) => {
    const jsDate = new Date((date - 25569) * 86400 * 1000);
    const momentObj = moment.tz(jsDate, "Asia/Kolkata");
    let formatedDate = momentObj._d.toISOString().substring(0, 10);
    return formatedDate;
  };

  //getting the output after filtered
  const getFiltered = (array) => {
    let filtered = [];
    if (array) {
      filtered = array.filter((item, index) => {
        if (nameFilter) {
          return (
            item.firstName
              .toLowerCase()
              .includes(nameFilter.toLowerCase().trim()) ||
            item.lastName
              ?.toLowerCase()
              .includes(nameFilter.toLowerCase().trim())
          );
        } else return item;
      });
    } else {
      filtered = filterArray.filter((item, index) => {
        if (nameFilter) {
          return (
            item.firstName
              .toLowerCase()
              .includes(nameFilter.toLowerCase().trim()) ||
            item.lastName
              ?.toLowerCase()
              .includes(nameFilter.toLowerCase().trim())
          );
        } else return item;
      });
    }

    filtered = filtered.filter((item, index) => {
      if (titleFilter) {
        return item.profileTitle
          .toLowerCase()
          .startsWith(titleFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (experienceFilter) {
        if (item.totalExperience) {
          return item.totalExperience
            .toLowerCase()
            .includes(experienceFilter.toLowerCase().trim());
        } else {
          return item.calculatedExp
            .toLowerCase()
            .includes(experienceFilter.toLowerCase().trim());
        }
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (salaryFilter) {
        return (
          item.salaryDetail.salary
            .toLowerCase()
            .includes(salaryFilter.toLowerCase().trim()) ||
          item.salaryDetail.payType
            .toLowerCase()
            .includes(salaryFilter.toLowerCase().trim()) ||
          item.salaryDetail.salary
            .toLowerCase()
            .includes(salaryFilter.toLowerCase().trim())
        );
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (emailFilter) {
        return item.email
          .toLowerCase()
          .includes(emailFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (phoneFilter) {
        return item.primaryPhone
          .toLowerCase()
          .includes(phoneFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (skillsFilter) {
        return item.skillArray.find((el) =>
          el.toLowerCase().includes(skillsFilter.toLowerCase().trim())
        );
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (locationFilter) {
        return (
          item.personal_details.location.city
            .toLowerCase()
            .includes(locationFilter.toLowerCase().trim()) ||
          item.personal_details.location.state
            .toLowerCase()
            .includes(locationFilter.toLowerCase().trim()) ||
          item.personal_details.location.country
            .toLowerCase()
            .includes(locationFilter.toLowerCase().trim())
        );
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (educationFilter) {
        return (
          item.degree.find((el) =>
            el?.toLowerCase().includes(educationFilter.toLowerCase().trim())
          ) ||
          item.stream.find((el) =>
            el?.toLowerCase().includes(educationFilter.toLowerCase().trim())
          )
        );
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (dobFilter) {
        return item.personal_details.dob
          .toLowerCase()
          .includes(dobFilter.toLowerCase().trim());
      } else return item;
    });
    filtered = filtered.filter((item, index) => {
      if (genderFilter) {
        return item.personal_details.gender
          .toLowerCase()
          .startsWith(genderFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (nationalityFilter) {
        return item.personal_details.nationality
          .toLowerCase()
          .startsWith(nationalityFilter.toLowerCase().trim());
      } else return item;
    });

    filtered = filtered.filter((item, index) => {
      if (statusFilter) {
        return item.requestStatus
          .toLowerCase()
          .startsWith(statusFilter.toLowerCase().trim());
      } else return item;
    });

    if (tableView == "internalUsers") {
      setAllUsers(filtered);
    } else {
      setConvertedUsers(filtered);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("candidate") || localStorage.getItem("screener")) {
      localStorage.getItem("title") === null
        ? setInterviewTitle("")
        : setInterviewTitle(localStorage.getItem("title"));
      setScheduleClicked(localStorage.getItem("scheduled"));
      setRightSidebarOpen(localStorage.getItem("rightSidebarOpen"));
      localStorage.getItem("screener")
        ? setScreenerName(localStorage.getItem("screener"))
        : setScreenerName("");

      localStorage.getItem("candidate")
        ? setCandidateName(localStorage.getItem("candidate"))
        : setCandidateName("");

      localStorage.getItem("zone")
        ? setZone(localStorage.getItem("zone"))
        : setZone("Asia/Kolkata");
      localStorage.getItem("time")
        ? setTime(localStorage.getItem("time"))
        : setTime("12:15 PM");
      localStorage.getItem("date")
        ? setDate(localStorage.getItem("date"))
        : setDate("");
      localStorage.getItem("candidateDetails")
        ? setCandidateDetails(
          JSON.parse(localStorage.getItem("candidateDetails"))
        )
        : setCandidateDetails({
          name: "",
          id: "",
          email: "",
        });
      localStorage.getItem("screenerDetails")
        ? setScreenerDetails(
          JSON.parse(localStorage.getItem("screenerDetails"))
        )
        : setScreenerDetails({
          name: "",
          id: "",
          email: "",
        });

      localStorage.getItem("client")
        ? setClientName(localStorage.getItem("client"))
        : setClientName("");
      localStorage.getItem("position")
        ? setPositionName(localStorage.getItem("position"))
        : setPositionName("");

      localStorage.getItem("clientDetails")
        ? setClientDetails(JSON.parse(localStorage.getItem("clientDetails")))
        : setClientDetails({
          name: "",
          id: "",
          email: "",
        });
      localStorage.getItem("positionDetails")
        ? setPositionDetails(
          JSON.parse(localStorage.getItem("positionDetails"))
        )
        : setPositionDetails({
          name: "",
          id: "",
        });

      localStorage.getItem("link")
        ? setInterviewLink(localStorage.getItem("link"))
        : setInterviewLink("");

      localStorage.getItem("meetingPlatform")
        ? setMeetingPlatform(localStorage.getItem("meetingPlatform"))
        : setMeetingPlatform("");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("title", interviewTitle);
    localStorage.setItem("candidate", candidateName);
    localStorage.setItem("screener", screenerName);
    localStorage.setItem("link", interviewLink);
    localStorage.setItem("meetingPlatform", meetingPlatform);
    localStorage.setItem("date", date);
    localStorage.setItem("time", time);
    localStorage.setItem("zone", zone);
    localStorage.setItem("role", roleFilter);
    localStorage.setItem("candidateDetails", JSON.stringify(candidateDetails));
    localStorage.setItem("screenerDetails", JSON.stringify(screenerDetails));
    localStorage.setItem("scheduled", scheduleClicked);
    localStorage.setItem("rightSidebarOpen", rightSidebarOpen);
    localStorage.setItem("navigateTo", "simpleSearch");
    localStorage.setItem("clientDetails", JSON.stringify(clientDetails));
    localStorage.setItem("positionDetails", JSON.stringify(positionDetails));
    localStorage.setItem("client", clientName);
    localStorage.setItem("position", positionName);
  }, [
    screenerName,
    interviewLink,
    date,
    zone,
    time,
    roleFilter,
    candidateName,
    interviewTitle,
    rightSidebarOpen,
    scheduleClicked,
    clientName,
    positionName,
  ]);

  useEffect(() => {
    if (open) {
      setRightSidebarOpen(false);
    } else {
    }
  }, [open]);

  //mui autocomplete options
  const clientOptions = clientsList
    ?.filter((item) => item.status)
    .map((client) => {
      return {
        name: client.companyName,
        label: `${client.companyName} - CLN-${client._id.slice(-5)} - ${client.email
          }`,
        value: {
          id: client._id,
          email: client.email,
          name: client.companyName,
        },
      };
    });

  const positionOptions = positionsList
    ?.filter((pos) => pos.status === "Active")
    .map((pos) => {
      return {
        name: pos.position,
        label: `${pos.position} - POS-${pos._id.slice(-5)}`,
        value: {
          id: pos._id,
          name: pos.position,
        },
      };
    });

  //get all the clients of the company
  function getAllClients() {
    axios
      .get(`/api/admin/get-all-clients/${id}`)
      .then((res) => {
        const { data } = res;
        setClientsList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getAllPositions(clientDetail) {
    axios
      .get(`/api/admin/get-all-positions/${id}`)
      .then((res) => {
        let { position } = res.data;
        const { id } = clientDetail;
        position = position.filter((pos) => pos.client.id == id);
        setPositionsList(position);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //set the client name and details
  const handleClientData = (event, newValue) => {
    setClientName(newValue?.name);
    setClientDetails(newValue?.value);
    setPositionName("");
    setPositionDetails({ id: "", name: "" });
    getAllPositions(newValue?.value); //get all the positions of the respective client
  };

  //set the position name and details
  const handlePositionData = (event, newValue) => {
    setPositionName(newValue.name);
    setPositionDetails(newValue.value);
  };

  const handleAutoCompleteTextField = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "client":
        if (value === "") setPositionDetails({ id: "", name: "" }); //delete position details when client name is empty
        setClientName(value);
        setClientDetails({ id: "", name: "", email: "" });
      case "position":
        setPositionName(value);
        setPositionDetails({ id: "", name: "" });
      default:
    }
  };

  //get profile photo and resume using local file path provided in excel file
  const getPhotoResumeFiles = (data) => {
    let fileData = [...data];
    fileData = fileData.map((item) => {
      if (item?.dob) {
        item = { ...item, dob: ExcelDateToJSDate(item.dob) };
      } else {
        item = { ...item, dob: "" };
      }
      if (!item.secondaryPhone) {
        item = { ...item, secondaryPhone: "" };
      }
      if (!item.expectedSalary) {
        item = { ...item, expectedSalary: "" };
      }
      if (!item.payType) {
        item = { ...item, payType: "" };
      }
      if (!item.highestDegree) {
        item = { ...item, highestDegree: "" };
      }
      if (!item.percentage) {
        item = { ...item, percentage: "" };
      }
      if (!item.gender) {
        item = { ...item, gender: "" };
      }
      if (!item.nationality) {
        item = { ...item, nationality: "" };
      }
      if (!item.skills) {
        item = { ...item, skills: [] };
      } else {
        item = { ...item, skills: item.skills?.trim().split(",") };
      }
      item = { ...item, profileImage: profileAvatar };
      return item;
    });
    setFinalDisplay(fileData);
  };

  //getting favorites
  const getFavorites = (userArray, allUsers) => {
    for (let i = 0; i < allUsers.length; i++) {
      if (userArray.length > 0) {
        for (let j = 0; j < userArray.length; j++) {
          if (allUsers[i]._id == userArray[j]._id) {
            const result = { ...allUsers[i], isFavorite: true };
            allUsers[i] = result;
            break;
          } else {
            allUsers[i] = { ...allUsers[i], isFavorite: false };
          }
        }
      } else {
        allUsers[i] = { ...allUsers[i], isFavorite: false };
      }
    }
    return allUsers;
  };

  const handleExcelFileUpload = (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop().toLowerCase(); //get the extension of uploaded file
    const extensionList = [
      "xls",
      "xlt",
      "xla",
      "xlsx",
      "xltx",
      "xlsm",
      "xltm",
      "xlam",
      "xlsb",
      "csv",
    ]; //list of acceptable file extensions

    if (extensionList.includes(fileExtension)) {
      readExcel(file);
    } else {
      fileRef.current.value = null;
      setMessage("Incorrect file type!");
      setOpenSnackbar(true);
    }
  };

  const handleRemoveFile = () => {
    setFileName("");
    setFinalDisplay([]);
  };

  //read data from excel file
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
        const columnObject = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          defval: "",
        });
        columnHeaders = columnObject[0]; //store the headings
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      if (data.length > 0) {
        // check whether all the column headers are present in the excel file
        let conditionArray = [];
        const headingArray = [
          "First Name*",
          "Last Name",
          "Email*",
          "Profile Title",
          "Total Experience",
          "Expected Salary(USD)",
          "Pay Type",
          "Primary Phone*",
          "Secondary Phone",
          "Skills*",
          "City",
          "State",
          "Country",
          "Highest Degree",
          "Percentage",
          "DOB(DD-MM-YYYY)",
          "Gender",
          "Nationality",
        ];
        headingArray.map((heading, index) => {
          if (
            heading === "Expected Salary(USD)" ||
            heading === "Expected Salary(INR)"
          ) {
            conditionArray.unshift(true);
          } else {
            conditionArray.unshift(columnHeaders.includes(heading));
          }
        });
        let condition = conditionArray.includes(false);

        if (condition) {
          setFileName("");
          fileRef.current.value = null;
          setMessage("Please check the format of your file!");
          setOpenSnackbar(true);
        } else {
          setFileName(file.name);
          //replace the object key names
          data.map((item) => {
            item["firstName"] = item["First Name*"];
            delete item["First Name*"];
            item["lastName"] = item["Last Name"];
            delete item["Last Name"];
            item["email"] = item["Email*"];
            delete item["Email*"];
            item["title"] = item["Profile Title"];
            delete item["Profile Title"];
            item["totalExperience"] = item["Total Experience"];
            delete item["Total Experience"];

            if (item["Expected Salary(USD)"] != undefined) {
              item["expectedSalary"] = item["Expected Salary(USD)"];
              delete item["Expected Salary(USD)"];
            }
            if (item["Expected Salary(INR)"] != undefined) {
              item["expectedSalary"] = item["Expected Salary(INR)"];
              delete item["Expected Salary(INR)"];
            }

            item["payType"] = item["Pay Type"];
            delete item["Pay Type"];
            item["primaryPhone"] = item["Primary Phone*"];
            delete item["Primary Phone*"];
            item["secondaryPhone"] = item["Secondary Phone"];
            delete item["Secondary Phone"];
            item["skills"] = item["Skills*"];
            delete item["Skills*"];
            item["city"] = item["City"];
            delete item["City"];
            item["state"] = item["State"];
            delete item["State"];
            item["country"] = item["Country"];
            delete item["Country"];
            item["highestDegree"] = item["Highest Degree"];
            delete item["Highest Degree"];
            item["percentage"] = item["Percentage"];
            delete item["Percentage"];
            // item["fieldOfStudy"] = item["Field of Study"];
            // delete item["Field of Study"];
            item["dob"] = item["DOB(DD-MM-YYYY)"];
            delete item["DOB(DD-MM-YYYY)"];
            item["gender"] = item["Gender"];
            delete item["Gender"];
            item["nationality"] = item["Nationality"];
            delete item["Nationality"];
          });

          let flag = true;
          //check whether the mandatory fields are present
          data.map((item) => {
            if (
              !(
                item.firstName &&
                item.email &&
                item.primaryPhone &&
                item.skills
              )
            ) {
              flag = false;
            }
            return item;
          });

          let emails = [];
          data.map((item) => {
            emails.push(item.email);
          });

          let index = 0;
          let repeatedEmails = [];
          for (let i = 0; i < emails.length - 1; i++) {
            for (let j = i + 1; j < emails.length; j++) {
              if (emails[i] === emails[j]) {
                repeatedEmails[index] = emails[i];
                index++;
              }
            }
          }

          if (repeatedEmails.length > 0) {
            setFileName("");
            fileRef.current.value = null;
            setMessage("Duplicate emails found");
            setOpenSnackbar(true);
            return;
          }

          if (flag) {
            getPhotoResumeFiles(data);
            setOpenSnackbar(false);
          } else {
            setFileName("");
            fileRef.current.value = null;
            setMessage("Please fill all the mandatory fields!");
            setOpenSnackbar(true);
          }
        }
      } else {
        setFileName("");
        fileRef.current.value = null;
        setMessage("Your file is empty!");
        setOpenSnackbar(true);
      }
    });
  };

  useEffect(() => {
    document.title = "Screener | Internal DB";
    if (screenerName == "" && candidateName == "") {
      setRightSidebarOpen(null);
    }
  }, []);

  //fetching navbar data
  useEffect(() => {
    axios.get(`/api/admin/${id}`).then((res) => {
      const {
        defaultAvatar,
        profileImage,
        croppedImage,
        interacted,
        companyName,
        isInternalDB,
        companyEmail,
      } = res.data.user;
      if (isInternalDB) {
        setDefaultAvatar(defaultAvatar);
        setProfileImage(profileImage);
        setCroppedImg(croppedImage);
        setInteracted(interacted);
        setCompanyName(companyName);
        setReplyToEmail(companyEmail);
      } else {
        setHasInternalDbAccess(false);
      }
    });
  }, []);

  const getInternalUsers = async (fetchCondition) => {
    // if (fetchCondition !== "intervalFetch") setLoading(true);
    try {
      const userId = id;

      let { data } = await axios.get(
        `/api/admin/get-internal-users/${localStorage
          .getItem("role")}/${id}/${userId}`
      );

      // if users exists
      if (data.length > 0) {
        // filter converted and normal profiles
        let internalProfiles = data
          .filter((item) => item.isInternalUser)
          .reverse();
        setAllUsers(internalProfiles);
        let otherProfiles = data
          .filter((item) => !item.isInternalUser)
          .reverse();
        setConvertedUsers(otherProfiles);
        if (tableView == "internalUsers") {
          setFilterArray(internalProfiles);
          getFiltered(internalProfiles);
        } else {
          setFilterArray(otherProfiles);
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    getInternalUsers("intervalFetch");
  }, [openNoteModal]);

  useEffect(() => {
    getInternalUsers("");
    getFiltered();
    if (mode == "add") {
      setPage(0);
    }
  }, [roleFilter, addMultipleUsersClicked, tableView, refreshCount]);

  useEffect(() => {
    getFiltered();
  }, [addMultipleUsersClicked, addUserClicked]);

  //Tab handler
  const handleChangeRoleFilter = (event, role) => {
    if (role === null) {
      setRoleFilter(roleFilter);
    } else {
      setRoleFilter(role);
    }
    if (role == "candidate") {
      setSelectedRole(true);
    } else if (role === "screener") {
      setSelectedRole(false);
    } else {
      setSelectedRole(selectedRole);
    }
  };

  const handleTableView = (event, view) => {
    if (view === null) {
      setTableView(tableView);
    } else {
      setTableView(view);
    }
    if (view == "internalUsers") {
      setInternalUsersSelected(true);
    } else if (view === "convertedUsers") {
      setInternalUsersSelected(false);
    } else {
      setInternalUsersSelected(internalUsersSelected);
    }
  };



  //Add recruiter Dialog handler
  const formHandler = () => {
    setAddUserClicked(true);
    setResumeParseClicked(false)
    setMode("add");
    roleFilter === "candidate"
      ? (document.title = "Screener | Add Job Seeker")
      : (document.title = "Screener | Add Screener");
  };

  const editHandler = (itemId) => {
    setAddUserClicked(true);
    setResumeParseClicked(false);
    setMode("edit");
    setSelectedUserId(itemId);
    roleFilter === "candidate"
      ? (document.title = "Screener | Edit Job Seeker")
      : (document.title = "Screener | Edit Screener");
  };

  const addMultipleHandler = () => {
    setAddMultipleUsersClicked(true);
    roleFilter === "candidate"
      ? (document.title = "Screener | Add Multiple Job Seekers")
      : (document.title = "Screener | Add Multiple Screeners");
  };

  const backHandler = () => {
    console.log(viewProfile);
    // setTableView("internalUsers")

    if (!viewProfile) {
      setFileName("");
      setFinalDisplay([]);
      setResumeParseClicked(false);
      setAddUserClicked(false);
      // setMode("")
      setAddMultipleUsersClicked(false);
      document.title = "Screener | Internal DB";
    } else {
      document.title = "Screener | Internal DB";
      setViewProfile(false);
    }
  };

  //pagination handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //resume viewer
  function debugBase64(userId) {
    navigate(`/resume-viewer/${roleFilter}/${userId}`);
  }

  //addToFavoritesHandler
  const addToFavoritesHandler = (item) => {
    const newData = {
      item,
      userId: id,
    };
    axios
      .post(
        `/api/admin/internal-users/favorite/${roleFilter}/${id}/${companyId}`,
        newData
      )
      .then((res) => {
        setMessage(res.data.message);
        setOpenSnackbar(true);
        const userId = id;
        axios
          .get(`/api/admin/favorites/${userId}/${roleFilter}/${id}`)
          .then((data) => {
            const userArray =
              roleFilter === "screener"
                ? data.data.screener
                : data.data.candidate;
            const resulData = getFavorites(userArray, allUsers);
            setAllUsers(resulData);
          });
      })
      .catch((err) => {
        setMessage("Oops!..something went wrong");
        setOpenSnackbar(true);
      });
  };

  const favoriteHandler = (role, navigateTo) => {
    localStorage.setItem("role", role);
    localStorage.setItem("navigateTo", navigateTo);
    navigate("/admin/favorites");
  };

  //profile view handler
  const viewProfileHandler = () => {
    localStorage.setItem("id", id);
    localStorage.setItem("jwtToken", token);
  };

  const shareHandler = (userId) => {
    setProfilePublicLink(
      `https://screener.infomaticscorp.com/profile/share/${roleFilter}/${userId}/${id}`
    );
    setOpenShareModal(true);
  };

  // Add note handler for each user
  const noteHandler = (userId) => {
    setOpenNoteModal(true);
  };

  // View note handler
  const viewNoteHandler = async (user) => {
    axios
      .post(`/api/admin/note-given-by/${id}/${id}`, user)
      .then((res) => {
        setOpenViewNoteModal(true);
        let userObj = { ...user, notes: res.data };
        setViewNoteUser(userObj);
        const newData = {
          companyId: id,
          userId: user?._id,
          loggedProfileId: id,
        };
        axios
          .post(`/api/admin/update-note-readBy/`, newData)
          .then((res) => { })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //delete user
  const deleteUser = () => {
    axios
      .post(
        `/api/admin/delete/internal-users/${roleFilter}/${selectedItem._id}`,
        { companyId: id }
      )
      .then((res) => {
        if (res.status == 201) {
          //popup
          setDeleteDialog(true);
          setDialogMessage("You cannot delete this");
        } else {
          getInternalUsers("");
          setMessage(res.data.message);
          setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //action dropdown hanlders
  const handleMenuClick = (event, item) => {
    setSelectedItem(item);
    setActionMenu(event.currentTarget);
  };

  const handleScheduleInterview = (
    firstName,
    lastName,
    email,
    id,
    status,
    selectedItem
  ) => {
    if (!status) {
      setRightSidebarOpen(false);

      setOpenSnackbar(true);
      setMessage("Cannot Schedule Interview for inactive users!");
      return;
    }
    const fullName = firstName + " " + lastName;
    setRightSidebarOpen(true);
    setOpen(false);
    setScheduleClicked(true);
    if (roleFilter === "candidate") {
      setCandidateName(fullName);
      setCandidateDetails({
        name: fullName,
        email,
        id,
      });
    } else {
      setScreenerName(fullName);
      setScreenerDetails({
        name: fullName,
        email,
        id,
      });
    }
  };

  //function for changing profile
  const handleChangeProfile = async (user) => {
    try {
      const userData = await axios.post(
        `/api/admin/change-profile/${roleFilter}/${id}`,
        { user }
      );

      if (userData.status == 200 && userData.data.message == "") {
        setOpenSnackbar(true);
        setMessage(`Request mail sent to ${roleFilter} `);
        getInternalUsers("");
        getFiltered();
      } else if (
        userData.status == 200 &&
        (userData.data.message == "candidate" ||
          userData.data.message == "screener")
      ) {
        setOpenSnackbar(true);
        setMessage(
          `This ${roleFilter} is already registered as a external user`
        );
        getInternalUsers("");
        getFiltered();
      } else {
        setDeleteDialog(true);
        setDialogMessage("You cannot change the profile of this");
      }
    } catch (err) {
      if (err.response.status == 404) {
        setMessage(err.response.data.message);
        setOpenSnackbar(true);
      }
      console.log(err);
    }
  };

  function handleAccountStatus(account) {
    const { accountStatus } = account;
    axios
      .post("/api/admin/internal-profile/accountStatus", {
        accountStatus: !accountStatus,
        id: account._id,
        roleFilter,
      })
      .then((res) => {
        if (res) {
          getInternalUsers("");
          getFiltered();
          setMessage(res.data.msg);
          setOpenSnackbar(true);
        }
      })
      .catch((err) => console.log(err));
  }

  const handleMenuClose = (condition, item) => {
    if (condition === "Share") {
      shareHandler(item._id);
    }
    if (condition == "Add Note") {
      noteHandler(item._id);
    }
    if (condition === "Delete") {
      deleteUser();
    } else if (condition === "View Profile") {
      viewProfileHandler();
    } else if (condition === "Add to Favorites") {
      addToFavoritesHandler(item);
    } else if (condition === "Preview Resume") {
      debugBase64(selectedItem._id);
    } else if (condition === "Schedule Interview") {
      handleScheduleInterview(
        selectedItem.firstName,
        selectedItem.lastName,
        selectedItem.email,
        selectedItem._id,
        selectedItem.accountStatus,
        selectedItem
      );
    } else if (condition == "Change Profile") {
      handleChangeProfile(item);
    } else if (condition == "Deactivate") {
      handleAccountStatus(item);
    }
    setActionMenu(null);
  };

  return (
    <div>
      {hasInternalDbAccess ? (
        <div>
          {" "}
          {/* Navbar Component */}
          <CompanyMenu
            open={open}
            setOpen={setOpen}
            sidebarOpen={sidebarOpen}
            setsidebarOpen={setsidebarOpen}
            companyName={companyName}
            croppedImg={croppedImg}
            profileImage={profileImage}
            interacted={interacted}
            defaultAvatar={defaultAvatar}
          />
          <div
            style={{
              marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}`,
            }}
          >
            <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
              {/* Top card */}
              <Card id="main-edit" className="add-recruiter-form form-card">
                <Grid
                  container
                  spacing={0}
                  // direction="row"
                  className="add-recruiter-card-container"
                >
                  <Grid
                    item
                    xs={12}
                    sm={sidebarOpen === "sidebar-open" ? 12 : 4}
                    md={sidebarOpen === "sidebar-open" ? 12 : 4}
                    lg={6}
                    className="container-heading-title admin-table"
                  >
                    {!viewProfile ? (
                      <h3>
                        Internal DB{" "}
                        {` > ${roleFilter === "candidate" ? "Job Seeker" : "Screener"
                          } `}
                      </h3>
                    ) : (
                      <h4>Internal DB {` > Profile `}</h4>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={sidebarOpen === "sidebar-open" ? 12 : 7}
                    md={sidebarOpen === "sidebar-open" ? 12 : 7}
                    lg={6}
                    className="container-heading-buttons add-recruiter-button"
                    style={{ width: "100%" }}
                  >
                    <div className="main-button-container">
                      {!viewProfile ? (
                        !addUserClicked && !addMultipleUsersClicked && !resumeParseClicked ? (
                          <>
                            <div className="top-card-button-container">
                              <ToggleButtonGroup
                                value={roleFilter}
                                exclusive
                                onChange={handleChangeRoleFilter}
                                aria-label="Platform"
                                className="toggle-btn-group"
                              >
                                <ToggleButton
                                  value="candidate"
                                  selected={selectedRole}
                                  className={
                                    roleFilter === "candidate"
                                      ? "Mui-selected btn-ripple"
                                      : "toggle-role-btn"
                                  }
                                  sx={{
                                    background: "#5050502e",
                                    color: "#505050",
                                    padding: "3px 7px",
                                    fontFamily: "Raleway",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    border: "none",
                                  }}
                                >
                                  Job Seeker
                                </ToggleButton>
                                <ToggleButton
                                  selected={!selectedRole}
                                  className={
                                    roleFilter === "screener"
                                      ? "Mui-selected "
                                      : "toggle-role-btn"
                                  }
                                  value="screener"
                                  sx={{
                                    background: "#5050502e",
                                    color: "#505050",
                                    padding: "3px 7px",
                                    fontWeight: "600",
                                    border: "none",
                                    fontFamily: "Raleway",
                                    textTransform: "none",
                                  }}
                                >
                                  Screener
                                </ToggleButton>
                              </ToggleButtonGroup>

                              <ToggleButtonGroup
                                value={tableView}
                                exclusive
                                onChange={handleTableView}
                                aria-label="Platform"
                                className="toggle-btn-group"
                              >
                                <ToggleButton
                                  value="internalUsers"
                                  selected={internalUsersSelected}
                                  className={
                                    tableView === "internalUsers"
                                      ? "Mui-selected "
                                      : "toggle-role-btn"
                                  }
                                  sx={{
                                    background: "#5050502e",
                                    color: "#505050",
                                    padding: "3px 7px",
                                    fontFamily: "Raleway",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    border: "none",
                                  }}
                                >
                                  Internal Profiles
                                </ToggleButton>
                                <ToggleButton
                                  selected={!internalUsersSelected}
                                  className={
                                    tableView === "convertedUsers"
                                      ? "Mui-selected "
                                      : "toggle-role-btn"
                                  }
                                  value="convertedUsers"
                                  sx={{
                                    background: "#5050502e",
                                    color: "#505050",
                                    padding: "3px 7px",
                                    fontWeight: "600",
                                    border: "none",
                                    fontFamily: "Raleway",
                                    textTransform: "none",
                                  }}
                                >
                                  Converted Profiles
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </div>

                            <div className="add-user-button-container">
                              <button
                                className="add-recruiter "
                                onClick={() => {
                                  setResumeParseClicked(true)

                                }}
                                style={{
                                  padding: "3px 5px",
                                  fontSize: "12px",
                                  height: "31px",
                                  width: "50%",
                                  marginRight: "0px",
                                }}
                              >
                                {roleFilter === "candidate"
                                  ? "Parse Job Seeker Resume"
                                  : "Parse Screener Resume"}
                              </button>
                              <button
                                className="add-recruiter "
                                onClick={formHandler}
                                style={{
                                  padding: "3px 5px",
                                  fontSize: "12px",
                                  height: "31px",
                                  width: "31%",
                                  marginRight: "0px",
                                }}
                              >
                                {roleFilter === "candidate"
                                  ? "Add Job Seeker"
                                  : "Add Screener"}
                              </button>
                              <button
                                className="add-recruiter "
                                onClick={addMultipleHandler}
                                style={{
                                  padding: "3px 5px",
                                  fontSize: "12px",
                                  height: "31px",
                                  marginRight: "0px",
                                  width: "27%",
                                }}
                              >
                                Add Multiple
                              </button>
                            </div>

                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                            }}
                          >
                            {addMultipleUsersClicked && (
                              <>
                                <div className="add-multiple-upload-csv-container">
                                  {fileName === "" ? (
                                    <label className="resume-file-upload outlined-btn upload-csv-multiple">
                                      <input
                                        type="file"
                                        ref={fileRef}
                                        onChange={handleExcelFileUpload}
                                      />
                                      <FileUploadIcon
                                        fontSize="small"
                                        sx={{
                                          marginRight: "5px",
                                          color: "#F06161",
                                        }}
                                      />
                                      <span>Upload CSV</span>
                                    </label>
                                  ) : (
                                    <div>
                                      <span>
                                        {fileName}{" "}
                                        <CloseIcon
                                          onClick={handleRemoveFile}
                                          fontSize="small"
                                          sx={{
                                            cursor: "pointer",
                                            padding: 0,
                                            margin: "1px",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Tooltip title="The 'Download Template' downloads a CSV file with pre-defined column headings. Fill in the necessary details, save, and upload the same. The columns marked with * are mandatory.">
                                      <InfoOutlinedIcon
                                        fontSize="small"
                                        style={{
                                          color: "#4c4c5d",
                                          fontSize: "16px",
                                        }}
                                      />
                                    </Tooltip>
                                    <a
                                      className="download-template-link"
                                      style={{ width: "135px" }}
                                      href={templateFileLink}
                                      download
                                    >
                                      Download Template
                                      <FileDownloadIcon fontSize="small" />
                                    </a>
                                  </div>
                                </div>
                              </>
                            )}
                            <button
                              className="add-recruiter add-multiple"
                              onClick={backHandler}
                            >
                              Back
                            </button>
                          </div>
                        )
                      ) : (
                        <button
                          className="add-recruiter add-multiple"
                          onClick={backHandler}
                        >
                          Back
                        </button>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Card>

              {/* {
                resumeParseClicked?<ParseResume setResumeParseClicked={setResumeParseClicked} setTableView={setTableView} />
              } */}

              {
                addUserClicked ? (
                  mode == "add" ? (
                    <AddUsers
                      setRefreshCount={setRefreshCount}
                      role={roleFilter}
                      addUserClicked={addUserClicked}
                      setAddUserClicked={setAddUserClicked}
                      allUsers={allUsers}
                      setAllUsers={setAllUsers}
                      mode={mode}
                    />
                  ) : (
                    //Edit
                    <EditUsers
                      setRefreshCount={setRefreshCount}
                      role={roleFilter}
                      addUserClicked={addUserClicked}
                      setAddUserClicked={setAddUserClicked}
                      allUsers={allUsers}
                      setAllUsers={setAllUsers}
                      selectedUserId={selectedUserId}
                      mode={mode}
                    />
                  )
                ) : addMultipleUsersClicked ? (
                  <AddMultipleUsers
                    setFileName={setFileName}
                    setFinalDisplay={setFinalDisplay}
                    finalDisplay={finalDisplay}
                    roleFilter={roleFilter}
                    setAddMultipleUsersClicked={setAddMultipleUsersClicked}
                    setRefreshCount={setRefreshCount}
                  />
                ) : resumeParseClicked ? (<ParseResume setResumeParseClicked={setResumeParseClicked} setTableView={setTableView} />) : tableView == "internalUsers" ? (
                  //normal users Table card
                  <div className="filter-bottom-flex">
                    <Card
                      id="skills-edit"
                      style={{
                        boxShadow: "none",
                        borderRadius: "2px",
                        width: rightSidebarOpen ? "68vw" : "",
                        position: "relative",
                      }}
                      className="table-card"
                    >
                      <div className="user-list">
                        <div className="internal-user-list-heading">
                          {columns.map((item, index) => {
                            return (
                              <>
                                <div
                                  className={`header-filter ${item.title == "Name"
                                    ? "name-header-filter"
                                    : ""
                                    }`}
                                  style={{
                                    width: `${item.width}`,
                                  }}
                                >
                                  {item.title == "Name" ? (
                                    <p
                                      className={`${sidebarOpen == "sidebar-open"
                                        ? "name-heading-no-scroll-sidebar-open"
                                        : "name-heading-no-scroll"
                                        }`}
                                    >
                                      Name
                                    </p>
                                  ) : (
                                    <p
                                      style={{
                                        background:
                                          index % 2
                                            ? "rgba(240, 97, 97, 37%)"
                                            : "rgba(240, 97, 97, 17%)",
                                        color: "#283848",
                                      }}
                                    >
                                      {item.title}
                                    </p>
                                  )}
                                  {item.title === "Name" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      className={`${sidebarOpen == "sidebar-open"
                                        ? "name-filter-sidebar-open"
                                        : "name-filter"
                                        }`}
                                      value={nameFilter}
                                      onChange={(e) =>
                                        setNameFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "Title" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={titleFilter}
                                      onChange={(e) =>
                                        setTitleFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "Total Experience" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={experienceFilter}
                                      onChange={(e) =>
                                        setExperienceFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "Expected Salary" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={salaryFilter}
                                      onChange={(e) =>
                                        setSalaryFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "Email Id" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={emailFilter}
                                      onChange={(e) =>
                                        setEmailFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "Phone Number" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={phoneFilter}
                                      onChange={(e) =>
                                        setPhoneFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "Skills" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={skillsFilter}
                                      onChange={(e) =>
                                        setSkillsFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "Current Location" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={locationFilter}
                                      onChange={(e) =>
                                        setLocationFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "Highest Education" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={educationFilter}
                                      onChange={(e) =>
                                        setEducationFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "DOB" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={dobFilter}
                                      onChange={(e) =>
                                        setDobFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "Gender" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={genderFilter}
                                      onChange={(e) =>
                                        setGenderFilter(e.target.value)
                                      }
                                    />
                                  ) : item.title === "Nationality" ? (
                                    <input
                                      type="text"
                                      placeholder="Filter"
                                      style={{
                                        width: "97%",
                                        padding: "5px ",
                                        margin: "5px 0px 5px 5px",
                                        borderBottom: "1px solid lightgray",
                                        backgroundColor: "white",
                                      }}
                                      value={nationalityFilter}
                                      onChange={(e) =>
                                        setNationalityFilter(e.target.value)
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>

                        {loading ? (
                          <TableLoader page="internal-userList" />
                        ) : (
                          <div className="internal-user-details-list">
                            {allUsers.length > 0 ? (
                              allUsers
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                .map((item, index) => {
                                  const { city, state, country } =
                                    item.personal_details.location;

                                  const highestEducation = [
                                    ...item.educational_details,
                                  ].sort((a, b) => b.startYear - a.startYear);
                                  const locationLength =
                                    city?.length +
                                    state?.length +
                                    country?.length +
                                    4;
                                  let nameLength =
                                    item.firstName.length +
                                    item.lastName?.length;
                                  let unreadCount =
                                    item.notes?.length &&
                                    item.notes?.filter((note) => !note.read)
                                      .length;
                                  let skillsCount = item.skills.length;
                                  {
                                    return (
                                      <>
                                        <div
                                          className={`${skillsCount > 6 ? "fix-height" : ""} internal-users-details ${index % 2
                                            ? ""
                                            : "alternate-recruiterlist-bg"
                                            }`}
                                        >
                                          <div
                                            className={`fix-height internal-user-list-name interview-id ${index % 2
                                              ? `${sidebarOpen ==
                                                "sidebar-open"
                                                ? "name-no-scroll-even-sidebar-open"
                                                : "name-no-scroll-even"
                                              }`
                                              : `${sidebarOpen ==
                                                "sidebar-open"
                                                ? "name-no-scroll-odd-sidebar-open"
                                                : "name-no-scroll-odd"
                                              }`
                                              }`}

                                          >
                                            <img
                                              style={{ objectFit: "cover" }}
                                              src={
                                                item.defaultAvatar
                                                  ? profileAvatar
                                                  : item.interacted
                                                    ? item.croppedImage
                                                    : item.profileImage
                                              }
                                              className="internal-user-list-profile-image"
                                            />

                                            {nameLength > 23 ? (
                                              <Tooltip
                                                title={`${item.firstName} ${item?.lastName}`}
                                              >
                                                <p
                                                  style={{ textAlign: "start" }}
                                                  onClick={() =>
                                                    editHandler(item._id)
                                                  }
                                                >
                                                  {item.firstName}{" "}
                                                  {item?.lastName}
                                                </p>
                                              </Tooltip>
                                            ) : (
                                              <p
                                                style={{ textAlign: "start" }}
                                                onClick={() =>
                                                  editHandler(item._id)
                                                }
                                              >
                                                {item.firstName}{" "}
                                                {item?.lastName}
                                              </p>
                                            )}
                                            {item.notes?.length ? (
                                              <div className="view-note-icon">
                                                <Tooltip
                                                  title="View Note"
                                                  placement="top-end"
                                                >
                                                  {unreadCount > 0 ? (
                                                    <Badge
                                                      variant="dot"
                                                      sx={{
                                                        "& .MuiBadge-dot": {
                                                          backgroundColor:
                                                            "#f06161",
                                                          height: 5,
                                                          minWidth: 5,
                                                        },
                                                      }}
                                                    >
                                                      <ViewNoteIcon
                                                        style={{
                                                          fontSize: "0.9rem",
                                                        }}
                                                        onClick={() =>
                                                          viewNoteHandler(item)
                                                        }
                                                      />
                                                    </Badge>
                                                  ) : (
                                                    <ViewNoteIcon
                                                      style={{
                                                        fontSize: "0.9rem",
                                                      }}
                                                      onClick={() =>
                                                        viewNoteHandler(item)
                                                      }
                                                    />
                                                  )}
                                                </Tooltip>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>

                                          <div
                                            // className="internal-user-list-profileType custom-styles"
                                            className={`${sidebarOpen == "sidebar-open"
                                            ? "internal-user-list-profileType sidebaropen-custom-styles"
                                            : "internal-user-list-profileType custom-styles"
                                            }`}

                                            // style={{
                                            //   width: "18%",
                                            //   marginLeft: "10.5%",
                                            // }}
                                          >
                                            {item?.profileTitle.length > 24 ? (
                                              <Tooltip
                                                title={`${item?.profileTitle}`}
                                              >
                                                <p>{item?.profileTitle}</p>
                                              </Tooltip>
                                            ) : (
                                              <p>{item?.profileTitle}</p>
                                            )}
                                          </div>

                                          <div
                                            className="internal-user-list-total-experience"
                                            style={{ width: "20%" }}
                                          >
                                            {item.totalExperience > 0 ? (
                                              <p>
                                                {item.totalExperience} year(s)
                                              </p>
                                            ) : Number(item.calculatedExp.slice(0, 2)) > 0 ?
                                              <p>
                                                {item.calculatedExp}
                                              </p> :
                                              (
                                                <p>NA</p>
                                              )}
                                          </div>

                                          <div
                                            className="internal-user-list-salary"
                                            style={{ width: "18%" }}
                                          >
                                            {item.salaryDetail.payType ? (
                                              <p>
                                                {item.salaryDetail.salary}/
                                                {item.salaryDetail.payType}
                                              </p>
                                            ) : (
                                              <p>{item.salaryDetail.salary}</p>
                                            )}
                                          </div>

                                          <div
                                            className="internal-user-list-email"
                                            style={{ width: "28%" }}
                                          >
                                            {item.email.length > 24 ? (
                                              <Tooltip title={`${item.email}`}>
                                                <p>{item.email}</p>
                                              </Tooltip>
                                            ) : (
                                              <p>{item.email}</p>
                                            )}
                                          </div>

                                          <div
                                            className="internal-user-list-phone "
                                            style={{ width: "18%" }}
                                          >
                                            <p>+ {item.primaryPhone}</p>
                                          </div>

                                          <div
                                            className="internal-user-list-skills"
                                            style={{ width: "30%" }}
                                          >
                                            <div
                                              className={`skills-scroll ${dragging}`}
                                              onMouseDown={() => { setDragging("dragging") }}
                                              onMouseUp={() => { setDragging("") }}
                                            >
                                              {item.skills.map((el, i) => {
                                                const {
                                                  skillName,
                                                  yearOfExperience,
                                                } = el;
                                                if (yearOfExperience) {
                                                  return (
                                                    <Tooltip
                                                      title={`${skillName} - ${yearOfExperience} Year(s)`}
                                                    >
                                                      <p
                                                        style={{
                                                          marginRight: "3px",
                                                        }}
                                                      >
                                                        {skillName}
                                                        {i !=
                                                          item.skills.length -
                                                          1 && (
                                                            <span>{", "}</span>
                                                          )}
                                                      </p>
                                                    </Tooltip>
                                                  );
                                                } else {
                                                  return (
                                                    <Tooltip
                                                      title={`${skillName} `}
                                                    >
                                                      <p
                                                        style={{
                                                          marginRight: "3px",
                                                        }}
                                                      >
                                                        {skillName}
                                                        {i !=
                                                          item.skills.length -
                                                          1 && (
                                                            <span>{", "}</span>
                                                          )}
                                                      </p>
                                                    </Tooltip>
                                                  );
                                                }
                                              })}
                                            </div>
                                          </div>
                                          <div
                                            className="internal-user-list-location"
                                            style={{ width: "20%" }}
                                          >
                                            {locationLength > 24 ? (
                                              <Tooltip
                                                title={`${city} ${state && city ? "," : ""
                                                  } ${state} ${country && state ? "," : ""
                                                  } ${country}`}
                                              >
                                                <p>
                                                  {city}{" "}
                                                  {state && city ? "," : ""}{" "}
                                                  {state}{" "}
                                                  {country && state ? "," : ""}{" "}
                                                  {country}
                                                </p>
                                              </Tooltip>
                                            ) : (
                                              <p>
                                                {city}{" "}
                                                {state && city ? "," : ""}{" "}
                                                {state}{" "}
                                                {country && state ? "," : ""}{" "}
                                                {country}
                                              </p>
                                            )}
                                          </div>
                                          <div
                                            className="internal-user-list-education"
                                            style={{ width: "20%" }}
                                          >
                                            {highestEducation.map(
                                              (education, i) => {
                                                if (i == 0) {
                                                  const { stream, degree } =
                                                    education;
                                                  const eduLength =
                                                    2 +
                                                    degree?.length +
                                                    stream?.length;
                                                  {
                                                    return eduLength > 24 ? (
                                                      <Tooltip
                                                        title={`${degree} `}
                                                      >
                                                        <p>{degree} </p>
                                                      </Tooltip>
                                                    ) : eduLength == 2 ? (
                                                      <p></p>
                                                    ) : (
                                                      <p>{degree} </p>
                                                    );
                                                  }
                                                }
                                              }
                                            )}
                                          </div>

                                          <div
                                            className="internal-user-list-dob"
                                            style={{ width: "14%" }}
                                          >
                                            <p>{item.personal_details.dob}</p>
                                          </div>

                                          <div
                                            className="internal-user-list-gender"
                                            style={{ width: "11%" }}
                                          >
                                            <p>
                                              {item.personal_details.gender}
                                            </p>
                                          </div>

                                          <div
                                            className="internal-user-list-nationality"
                                            style={{ width: "10%" }}
                                          >
                                            <p>
                                              {item.personal_details.nationality}
                                            </p>
                                          </div>

                                          <div
                                            className="status-button"
                                            style={{ width: "9%" }}
                                          >
                                            <button
                                              className={` ${item.accountStatus
                                                ? "Active"
                                                : "Frozen"
                                                }`}
                                            >
                                              {item.accountStatus
                                                ? "Active"
                                                : "Inactive"}
                                            </button>
                                          </div>

                                          <div
                                            className="internal-user-list-actions"
                                            style={{ width: "5%", gap: "10px" }}
                                          >
                                            <IconButton
                                              disableRipple
                                              className="action-dropdown-button"
                                              aria-label="more"
                                              id="long-button"
                                              aria-controls={
                                                openActionMenu
                                                  ? "long-menu"
                                                  : undefined
                                              }
                                              aria-expanded={
                                                openActionMenu
                                                  ? "true"
                                                  : undefined
                                              }
                                              aria-haspopup="true"
                                              onClick={(e) =>
                                                handleMenuClick(e, item)
                                              }
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                              className="actions-menu-list"
                                              id="long-menu"
                                              MenuListProps={{
                                                "aria-labelledby":
                                                  "long-button",
                                              }}
                                              anchorEl={actionMenu}
                                              open={openActionMenu}
                                              onClose={() =>
                                                handleMenuClose(
                                                  "close",
                                                  selectedItem
                                                )
                                              }
                                              PaperProps={{
                                                style: {
                                                  position: rightSidebarOpen
                                                    ? "absolute"
                                                    : "",
                                                  right: rightSidebarOpen
                                                    ? "18vw"
                                                    : "",
                                                  width: "20ch",
                                                  boxShadow:
                                                    "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                                                },
                                              }}
                                            >
                                              {actionOptions.map((option) => {
                                                return option.name !==
                                                  "View Profile" ? (
                                                  option.name ===
                                                    "Add to Favorites" ? (
                                                    <MenuItem
                                                      key={option}
                                                      onClick={() =>
                                                        handleMenuClose(
                                                          option.name,
                                                          selectedItem
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          gap: "10px",
                                                        }}
                                                      >
                                                        <p>
                                                          <FavoriteIcon
                                                            style={{
                                                              color:
                                                                selectedItem?.isFavorite
                                                                  ? "#f06161"
                                                                  : null,
                                                            }}
                                                            fontSize="small"
                                                          />
                                                        </p>
                                                        <p>
                                                          {selectedItem?.isFavorite
                                                            ? "Remove Favorites"
                                                            : option.name}
                                                        </p>
                                                      </div>
                                                    </MenuItem>
                                                  ) : option.name ===
                                                    "Deactivate" ? (
                                                    <MenuItem
                                                      key={option}
                                                      onClick={() =>
                                                        handleMenuClose(
                                                          option.name,
                                                          selectedItem
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          gap: "10px",
                                                        }}
                                                      >
                                                        <p>
                                                          {selectedItem?.accountStatus ? (
                                                            <InactiveIcon fontSize="small" />
                                                          ) : (
                                                            <ActiveIcon fontSize="small" />
                                                          )}
                                                        </p>
                                                        <p>
                                                          {selectedItem?.accountStatus
                                                            ? option.name
                                                            : "Activate"}
                                                        </p>
                                                      </div>
                                                    </MenuItem>
                                                  ) : option.name ===
                                                    "Preview Resume" ? (
                                                    <MenuItem
                                                      key={option}
                                                      onClick={() =>
                                                        selectedItem?.resume &&
                                                        handleMenuClose(
                                                          option.name,
                                                          selectedItem
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          gap: "10px",
                                                        }}
                                                      >
                                                        <p>
                                                          <ResumeIcon
                                                            style={{
                                                              padding: 0,
                                                            }}
                                                            fontSize="small"
                                                            className={
                                                              !selectedItem?.resume &&
                                                              "disabled-icon-resume"
                                                            }
                                                          />
                                                        </p>
                                                        <p>
                                                          {selectedItem?.resume
                                                            ? option.name
                                                            : "No Resume"}
                                                        </p>
                                                      </div>
                                                    </MenuItem>
                                                  ) : option.name ===
                                                    "Send Mail" ? (
                                                    <MenuItem
                                                      key={option}
                                                      onClick={() =>
                                                        handleMenuClose(
                                                          option.name,
                                                          selectedItem
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          gap: "10px",
                                                          alignItems:
                                                            "baseline",
                                                        }}
                                                      >
                                                        <a
                                                          href={`mailto: ${selectedItem?.email}`}
                                                          style={{
                                                            height: "20px",
                                                            display: "flex",
                                                            justifyContent:
                                                              "space-between",
                                                            gap: "10px",
                                                          }}
                                                        >
                                                          <EmailIcon
                                                            style={{
                                                              padding: 0,
                                                              fontSize: "1.1em",
                                                            }}
                                                            fontSize="small"
                                                          />
                                                          <p>{option.name}</p>
                                                        </a>
                                                      </div>
                                                    </MenuItem>
                                                  ) : option.name ===
                                                    "Schedule Interview" ? (
                                                    <MenuItem
                                                      key={option}
                                                      onClick={() =>
                                                        handleMenuClose(
                                                          option.name,
                                                          selectedItem
                                                        )
                                                      }
                                                    >
                                                      <div>
                                                        <ScheduleIcon
                                                          style={{
                                                            padding: 0,
                                                            fontSize: "1.1em",
                                                          }}
                                                          fontSize="small"
                                                        />
                                                        <p>{option.name}</p>
                                                      </div>
                                                    </MenuItem>
                                                  ) : (
                                                    <MenuItem
                                                      key={option}
                                                      onClick={() =>
                                                        handleMenuClose(
                                                          option.name,
                                                          selectedItem
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          gap: "10px",
                                                        }}
                                                      >
                                                        <p>{option.icon}</p>
                                                        <p>{option.name}</p>
                                                      </div>
                                                    </MenuItem>
                                                  )
                                                ) : (
                                                  <a
                                                    href={`/admin/internal-db/profile/${roleFilter}/${selectedItem?._id}/${id}`}
                                                    target="_blank"
                                                  >
                                                    <MenuItem
                                                      key={option}
                                                      onClick={() =>
                                                        handleMenuClose(
                                                          option.name,
                                                          selectedItem
                                                        )
                                                      }
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          gap: "10px",
                                                        }}
                                                      >
                                                        <p>{option.icon}</p>
                                                        <p>{option.name}</p>
                                                      </div>
                                                    </MenuItem>
                                                  </a>
                                                );
                                              })}
                                            </Menu>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                })
                            ) : (
                              <p>No Users</p>
                            )}
                          </div>
                        )}
                      </div>
                      <TablePagination
                        id="internal-users-list-pagination"
                        component="div"
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        count={allUsers.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        variant="outlined"
                      />
                    </Card>

                    {/* quick interview scheduler */}
                    <div>
                      {!rightSidebarOpen ? (
                        scheduleClicked && (
                          <OpenArrow
                            className="open-arrow"
                            onClick={openArrowHandler}
                          />
                        )
                      ) : (
                        <>
                          <div className={`right-sidebar ${customHeight}`}>
                            <CloseArrow
                              className="close-arrow"
                              onClick={closeArrowHandler}
                            />
                            <Grid container spacing={0} direction="row">
                              <Grid
                                item
                                xs={5}
                                md={12}
                                lg={12}
                                sx={{
                                  margin: "15px 0px 10px 10px",
                                  display: "flex",
                                  gap: "7px",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "3px",
                                    alignItems: "flex-start",
                                    marginTop: "10px",
                                  }}
                                >
                                  <strong>
                                    <h5 style={{ fontSize: 18 }}>
                                      Quick Interview Scheduler
                                    </h5>
                                  </strong>
                                  <EventIcon
                                    fontSize="small"
                                    style={{ marginLeft: "5px" }}
                                  />
                                  <Tooltip title="Close">
                                    <CloseIcon
                                      fontSize="small"
                                      onClick={closeAllHandler}
                                      className="close-scheduler "
                                    />
                                  </Tooltip>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0} direction="row">
                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="container-heading-title "
                                sx={{ overflowY: "hidden", height: "70px" }}
                              >
                                <Typography className="scheduler-title">
                                  Interview Title
                                </Typography>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  sx={{
                                    padding: "0px 5px 0px 5px",
                                    marginBottom: "10px",
                                  }}
                                  className="right-grid"
                                >
                                  <form noValidate>
                                    <div className="input-container filter-container">
                                      <div class="input-field-modal">
                                        <input
                                          type="text"
                                          placeholder="Ex: Software Developer-Round 1"
                                          value={interviewTitle}
                                          onChange={(e) =>
                                            setInterviewTitle(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </form>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="container-heading-title "
                              >
                                <Typography className="scheduler-title">
                                  Client
                                </Typography>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  sx={{ padding: "0px 5px 0px 5px" }}
                                  className="right-grid"
                                >
                                  <div className="input-container filter-container">
                                    <div
                                      class="input-field-modal right-sidebar-input"
                                      style={{ marginBottom: 10 }}
                                    >
                                      <Autocomplete
                                        disablePortal
                                        disableClearable
                                        id="combo-box-demo"
                                        options={clientOptions}
                                        getOptionLabel={(option) =>
                                          option?.label
                                        }
                                        inputValue={clientName}
                                        onChange={(event, newValue) => {
                                          handleClientData(event, newValue);
                                        }}
                                        renderInput={(params) => {
                                          return (
                                            <TextField
                                              style={{
                                                backgroundColor: "#eee",
                                              }}
                                              name="client"
                                              onChange={(e) =>
                                                handleAutoCompleteTextField(e)
                                              }
                                              {...params}
                                              placeholder="Select Client"
                                            />
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>

                              {clientDetails.id !== "" && (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="container-heading-title "
                                >
                                  <Typography className="scheduler-title">
                                    Position
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{ padding: "0px 5px 0px 5px" }}
                                    className="right-grid"
                                  >
                                    <div className="input-container filter-container">
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <Autocomplete
                                          disablePortal
                                          disableClearable
                                          id="combo-box-demo"
                                          options={positionOptions}
                                          getOptionLabel={(option) =>
                                            option?.label
                                          }
                                          inputValue={positionName}
                                          onChange={(event, newValue) => {
                                            handlePositionData(event, newValue);
                                          }}
                                          renderInput={(params) => {
                                            return (
                                              <TextField
                                                style={{
                                                  backgroundColor: "#eee",
                                                }}
                                                name="position"
                                                onChange={(e) =>
                                                  handleAutoCompleteTextField(e)
                                                }
                                                {...params}
                                                placeholder="Select Position"
                                              />
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              )}
                              {screenerName &&
                                candidateName &&
                                (roleFilter === "candidate" ||
                                  roleFilter === "screener") ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="container-heading-title "
                                >
                                  <Typography className="scheduler-title">
                                    Job Seeker Name
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{ padding: "0px 5px 0px 5px" }}
                                    className="right-grid"
                                  >
                                    <div className="input-container filter-container">
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          type="text"
                                          value={candidateName}
                                          onChange={(e) => {
                                            setCandidateName(e.target.value);
                                          }}
                                          disabled
                                        />
                                        {candidateName && (
                                          <CloseIcon
                                            fontSize="small"
                                            className="right-sidebar-clear-input"
                                            onClick={() =>
                                              closeHandler("candidate")
                                            }
                                          />
                                        )}
                                        <button
                                          onClick={() =>
                                            favoriteHandler(
                                              "candidate",
                                              "advancedSearch"
                                            )
                                          }
                                          className="choose-fav-btn"
                                        >
                                          Choose From Favorites
                                        </button>
                                      </div>

                                      <Typography className="scheduler-title">
                                        Screener Name
                                      </Typography>
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          type="text"
                                          value={screenerName}
                                          onChange={(e) =>
                                            setScreenerName(e.target.value)
                                          }
                                          disabled
                                        />
                                        {screenerName && (
                                          <CloseIcon
                                            className="right-sidebar-clear-input"
                                            fontSize="small"
                                            onClick={() =>
                                              closeHandler("screener")
                                            }
                                          />
                                        )}
                                        <button
                                          onClick={() =>
                                            favoriteHandler(
                                              "screener",
                                              "advancedSearch"
                                            )
                                          }
                                          className="choose-fav-btn"
                                        >
                                          Choose From Favorites
                                        </button>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              ) : candidateName && roleFilter === "screener" ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="container-heading-title "
                                >
                                  <Typography className="scheduler-title">
                                    Job Seeker Name
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{ padding: "0px 5px 0px 5px" }}
                                    className="right-grid"
                                  >
                                    <div className="input-container filter-container">
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          type="text"
                                          value={candidateName}
                                          onChange={(e) =>
                                            setCandidateName(e.target.value)
                                          }
                                          disabled
                                        />
                                        {candidateName && (
                                          <CloseIcon
                                            className="right-sidebar-clear-input"
                                            fontSize="small"
                                            onClick={() =>
                                              closeHandler("candidate")
                                            }
                                          />
                                        )}
                                        <button
                                          onClick={() =>
                                            favoriteHandler(
                                              "candidate",
                                              "advancedSearch"
                                            )
                                          }
                                          className="choose-fav-btn"
                                        >
                                          Choose From Favorites
                                        </button>
                                      </div>

                                      <Typography className="scheduler-title">
                                        Screener Name
                                      </Typography>
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          type="text"
                                          value={screenerName}
                                          onChange={(e) =>
                                            setScreenerName(e.target.value)
                                          }
                                          disabled
                                        />
                                        {screenerName && (
                                          <CloseIcon
                                            className="right-sidebar-clear-input"
                                            fontSize="small"
                                            onClick={() =>
                                              closeHandler("screener")
                                            }
                                          />
                                        )}
                                        <button
                                          onClick={() =>
                                            favoriteHandler(
                                              "screener",
                                              "advancedSearch"
                                            )
                                          }
                                          className="choose-fav-btn"
                                        >
                                          Choose From Favorites
                                        </button>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              ) : screenerName && roleFilter === "screener" ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="container-heading-title "
                                  sx={{ overflowY: "hidden" }}
                                >
                                  <div className="scheduler-buttons">
                                    <div
                                      style={{ margin: "0px 0px 6px 1px" }}
                                      className="add-new-row-button"
                                      onClick={() =>
                                        roleHandler("candidate", true)
                                      }
                                    >
                                      <div>
                                        <AddIcon />
                                      </div>
                                      <div>
                                        <label className="schedule-label">
                                          Add Job Seeker{" "}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <Typography className="scheduler-title">
                                    Screener Name
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{ padding: "0px 5px 0px 5px" }}
                                    className="right-grid"
                                  >
                                    <div className="input-container filter-container">
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          type="text"
                                          value={screenerName}
                                          onChange={(e) =>
                                            setScreenerName(e.target.value)
                                          }
                                          disabled
                                        />
                                        {screenerName && (
                                          <CloseIcon
                                            className="right-sidebar-clear-input"
                                            fontSize="small"
                                            onClick={() =>
                                              closeHandler("screener")
                                            }
                                          />
                                        )}
                                        <button
                                          className="choose-fav-btn"
                                          onClick={() =>
                                            favoriteHandler(
                                              "screener",
                                              "advancedSearch"
                                            )
                                          }
                                        >
                                          Choose From Favorites
                                        </button>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              ) : screenerName && roleFilter === "candidate" ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="container-heading-title "
                                  sx={{ overflowY: "hidden" }}
                                >
                                  <Typography className="scheduler-title">
                                    Job Seeker Name
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{ padding: "0px 5px 0px 5px" }}
                                    className="right-grid"
                                  >
                                    <div className="input-container filter-container">
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          type="text"
                                          value={candidateName}
                                          onChange={(e) =>
                                            setCandidateName(e.target.value)
                                          }
                                          disabled
                                        />
                                        {candidateName && (
                                          <CloseIcon
                                            className="right-sidebar-clear-input"
                                            fontSize="small"
                                            onClick={() =>
                                              closeHandler("candidate")
                                            }
                                          />
                                        )}
                                        <button
                                          onClick={() =>
                                            favoriteHandler(
                                              "candidate",
                                              "advancedSearch"
                                            )
                                          }
                                          className="choose-fav-btn"
                                        >
                                          Choose From Favorites
                                        </button>
                                      </div>

                                      <Typography className="scheduler-title">
                                        Screener Name
                                      </Typography>
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          type="text"
                                          value={screenerName}
                                          onChange={(e) =>
                                            setScreenerName(e.target.value)
                                          }
                                          disabled
                                        />
                                        {screenerName && (
                                          <CloseIcon
                                            className="right-sidebar-clear-input"
                                            fontSize="small"
                                            onClick={() =>
                                              closeHandler("screener")
                                            }
                                          />
                                        )}
                                        <button
                                          className="choose-fav-btn"
                                          onClick={() =>
                                            favoriteHandler(
                                              "screener",
                                              "advancedSearch"
                                            )
                                          }
                                        >
                                          Choose From Favorites
                                        </button>
                                      </div>
                                      <div></div>
                                    </div>
                                  </Grid>
                                </Grid>
                              ) : screenerName &&
                                candidateName &&
                                (roleFilter === "candidate" ||
                                  roleFilter === "screener") ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="container-heading-title "
                                  sx={{ overflowY: "hidden" }}
                                >
                                  <Typography className="scheduler-title">
                                    Job Seeker Name
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{ padding: "0px 5px 0px 5px" }}
                                    className="right-grid"
                                  >
                                    <div className="input-container filter-container">
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          type="text"
                                          value={candidateName}
                                          onChange={(e) =>
                                            setCandidateName(e.target.value)
                                          }
                                          disabled
                                        />
                                        {candidateName && (
                                          <CloseIcon
                                            className="right-sidebar-clear-input"
                                            fontSize="small"
                                            onClick={() =>
                                              closeHandler("candidate")
                                            }
                                          />
                                        )}
                                        <button
                                          onClick={() =>
                                            favoriteHandler(
                                              "candidate",
                                              "advancedSearch"
                                            )
                                          }
                                          className="choose-fav-btn"
                                        >
                                          Choose From Favorites
                                        </button>
                                      </div>

                                      <Typography className="scheduler-title">
                                        Screener Name
                                      </Typography>
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          type="text"
                                          value={screenerName}
                                          onChange={(e) =>
                                            setScreenerName(e.target.value)
                                          }
                                          disabled
                                        />
                                        {screenerName && (
                                          <CloseIcon
                                            className="right-sidebar-clear-input"
                                            fontSize="small"
                                            onClick={() =>
                                              closeHandler("screener")
                                            }
                                          />
                                        )}
                                        <button
                                          className="choose-fav-btn"
                                          onClick={() =>
                                            favoriteHandler(
                                              "screener",
                                              "advancedSearch"
                                            )
                                          }
                                        >
                                          Choose From Favorites
                                        </button>
                                      </div>
                                      <div></div>
                                    </div>
                                  </Grid>
                                </Grid>
                              ) : candidateName &&
                                roleFilter === "candidate" ? (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  className="container-heading-title "
                                  sx={{ overflowY: "hidden" }}
                                >
                                  <Typography className="scheduler-title">
                                    Job Seeker Name
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{ padding: "0px 5px 0px 5px" }}
                                    className="right-grid"
                                  >
                                    <div className="input-container filter-container">
                                      <div
                                        class="input-field-modal right-sidebar-input"
                                        style={{ marginBottom: 10 }}
                                      >
                                        <input
                                          type="text"
                                          value={candidateName}
                                          onChange={(e) =>
                                            setCandidateName(e.target.value)
                                          }
                                          disabled
                                        />
                                        {candidateName && (
                                          <CloseIcon
                                            className="right-sidebar-clear-input"
                                            fontSize="small"
                                            onClick={() =>
                                              closeHandler("candidate")
                                            }
                                          />
                                        )}
                                        <button
                                          onClick={() =>
                                            favoriteHandler(
                                              "candidate",
                                              "advancedSearch"
                                            )
                                          }
                                          className="choose-fav-btn"
                                        >
                                          Choose From Favorites
                                        </button>
                                      </div>

                                      <div className="scheduler-buttons">
                                        <div
                                          className="add-new-row-button"
                                          onClick={() =>
                                            roleHandler("screener", false)
                                          }
                                        >
                                          <div>
                                            <AddIcon />
                                          </div>
                                          <div>
                                            <label className="schedule-label">
                                              Add Screener{" "}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </Grid>
                            <Grid container spacing={0} direction="row">
                              <Grid
                                item
                                xs={5}
                                md={12}
                                lg={12}
                                className="container-heading-title advanced-filter-form"
                                sx={{ margin: "1px 10px 10px 0px" }}
                              >
                                <div style={{ display: "flex" }}>
                                  <div
                                    class="input-field-modal"
                                    style={{ width: "40%", marginTop: 0 }}
                                  >
                                    <Typography className="scheduler-title">
                                      Interview Date
                                    </Typography>
                                    <input
                                      style={{ height: 35, padding: "5px" }}
                                      class="position"
                                      type="date"
                                      min={
                                        new Date().toISOString().split("T")[0]
                                      }
                                      value={date}
                                      onChange={dateHandler}
                                      placeholder="mm/dd/yyyy"
                                    />
                                  </div>
                                  <div
                                    className="input-date-modal"
                                    style={{ width: "60%" }}
                                  >
                                    <Typography className="scheduler-title">
                                      Time Zone
                                    </Typography>

                                    <select
                                      style={{
                                        outline: "none",
                                        marginLeft: "5px",
                                        width: "99%",
                                        padding: "5px 25px 5px 5px",
                                      }}
                                      value={zone}
                                      onChange={(e) => setZone(e.target.value)}
                                    >
                                      {timezone.map((time) => {
                                        return (
                                          <option value={time.abbr}>
                                            {time.value}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                md={12}
                                lg={12}
                                className="container-heading-title advanced-filter-form"
                                sx={{
                                  margin: "1px 10px 10px 0px",

                                  alignItems: "center",
                                  justifyContent: "center",

                                  gap: "10px",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  className="input-date-modal"
                                  style={{ width: "100%" }}
                                  onClick={() => {
                                    if (!date) {
                                      setMessage(
                                        "Please fill the interview date"
                                      );
                                      setOpenSnackbar(true);
                                    }
                                  }}
                                >
                                  <Typography className="scheduler-title">
                                    Interview Time
                                  </Typography>
                                  <div className="quick-select-time">
                                    <select
                                      value={hours}
                                      onChange={(e) =>
                                        handleTimeChange(
                                          e.target.value,
                                          "hours"
                                        )
                                      }
                                      style={{ outline: "none" }}
                                      disabled={date ? false : true}
                                    >
                                      <option selected disabled value="default">
                                        Select Time
                                      </option>
                                      {hoursArray.map((time) => (
                                        <option key={time} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                    <select
                                      value={minutes}
                                      onChange={(e) =>
                                        handleTimeChange(
                                          e.target.value,
                                          "minutes"
                                        )
                                      }
                                      style={{ outline: "none" }}
                                      disabled={date ? false : true}
                                    >
                                      <option selected disabled value="default">
                                        Select Time
                                      </option>
                                      {minutesArray.map((time) => (
                                        <option key={time} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                    <select
                                      value={meridian}
                                      onChange={(e) =>
                                        handleTimeChange(
                                          e.target.value,
                                          "merdian"
                                        )
                                      }
                                      style={{ outline: "none" }}
                                      disabled={date ? false : true}
                                    >
                                      <option selected disabled value="default">
                                        Select Time
                                      </option>
                                      {meridianArray.map((time) => (
                                        <option key={time} value={time}>
                                          {time}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                md={12}
                                lg={12}
                                className="container-heading-title advanced-filter-form"
                                sx={{ margin: "1px 10px 10px 0px" }}
                              >
                                <div className="input-date-modal">
                                  <div>
                                    <div
                                      className="scheduler-buttons"
                                      style={{ display: "flex" }}
                                    >
                                      <div class="input-field mobile-input-field ">
                                        <label className="date-label" style={{ fontSize: "12px" }}>Meeting Link</label>
                                        <div className='generate-link-container' >
                                          <div className={`generate-link-btn ${meetingPlatform == "Jitsi" ? "active" : ""}`} onClick={generateJitsiLink}>
                                            Jitsi Link <LinkIcon className='meet-link-icon' />
                                          </div>

                                          {/* <div className={`generate-link-btn ${meetingPlatform == "Zoom" ? "active" : ""}`} onClick={generateLinkHandler}>
                                            Zoom Link <LinkIcon className='meet-link-icon' />
                                          </div> */}
                                          <div className={`generate-link-btn ${meetingPlatform == "Custom" ? "active" : ""}`} onClick={customLinkHandler}>
                                            Custom Link <LinkIcon className='meet-link-icon' />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {(interviewLink != "" || customLink) && (
                                      <div
                                        className="input-container filter-container"
                                        style={{
                                          position: "relative",
                                          background: "#eee",
                                        }}
                                      >
                                        <div class="input-field-modal">
                                          <input
                                            disabled={!customLink}
                                            type="text"
                                            ref={meetLinkRef}
                                            value={interviewLink}
                                            onChange={(e) =>
                                              setInterviewLink(e.target.value)
                                            }
                                            style={{
                                              marginLeft: "5px",
                                              width: "81%",
                                            }}
                                          />
                                        </div>
                                        <ContentCopyIcon
                                          onClick={() =>
                                            copyTextToClipboard(interviewLink)
                                          }
                                          className="meet-link-icons"
                                          fontSize="small"
                                          sx={{
                                            position: "absolute",
                                            fontSize: "14px",
                                            cursor: "pointer",
                                            top: "16px",
                                            right: "9px",
                                            fontSize: "15px",
                                            opacity: "50%",
                                          }}
                                        />
                                        {/* {!customLink && (
                                        <RefreshIcon
                                          onClick={generateLinkHandler}
                                          className="meet-link-icons"
                                          fontSize="small"
                                          sx={{
                                            position: "absolute",
                                            fontSize: "14px",
                                            cursor: "pointer",
                                            top: "14px",
                                            right: "9px",
                                            fontSize: "18px",
                                            opacity: "50%",
                                          }}
                                        />
                                      )} */}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                      marginTop: "26px",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <button
                                      onClick={saveHandler}
                                      style={{
                                        fontSize: "12px",
                                        padding: "5px 10px",
                                        border: "1px solid #f06161",
                                        borderRadius: "5px",
                                        color: "#f06161",
                                        backgroundColor: "white",
                                        fontWeight: "normal",
                                      }}
                                      className="btn-style outlined-btn outlined-btn-animation"
                                    >
                                      {" "}
                                      Save for Later
                                    </button>

                                    <button
                                      onClick={scheduleHandler}
                                      style={{
                                        fontSize: "12px",
                                        padding: "5px 10px",
                                        border: "1px solid #f06161",
                                        borderRadius: "5px",
                                        color: " white",
                                        fontWeight: "normal",
                                        backgroundColor: "#f06161",
                                      }}
                                      className="btn-style filled-btn filled-btn-animation"
                                    >
                                      {" "}
                                      Schedule Now
                                    </button>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : tableView == "convertedUsers" ? (
                  // converted users table
                  <Card
                    id="skills-edit"
                    style={{
                      boxShadow: "none",
                      borderRadius: "2px",
                      width: rightSidebarOpen ? "68vw" : "",
                    }}
                    className="table-card"
                  >
                    <div className="user-list">
                      <div className="converted-user-list-heading">
                        {convertedTableColumns.map((item, index) => {
                          return (
                            <>
                              <div
                                className="header-filter"
                                style={{
                                  width: `${item.width}`,
                                }}
                              >
                                <p
                                  style={{
                                    background:
                                      index % 2
                                        ? "rgba(240, 97, 97, 37%)"
                                        : "rgba(240, 97, 97, 17%)",
                                    color: "#283848",
                                  }}
                                >
                                  {item.title}
                                </p>
                                {item.title === "Name" ? (
                                  <input
                                    type="text"
                                    placeholder="Filter"
                                    style={{
                                      width: "97%",
                                      padding: "5px ",
                                      margin: "5px 0px 5px 5px",
                                      borderBottom: "1px solid lightgray",
                                      backgroundColor: "white",
                                    }}
                                    value={nameFilter}
                                    onChange={(e) =>
                                      setNameFilter(e.target.value)
                                    }
                                  />
                                ) : item.title === "Email Id" ? (
                                  <input
                                    type="text"
                                    placeholder="Filter"
                                    style={{
                                      width: "97%",
                                      padding: "5px ",
                                      margin: "5px 0px 5px 5px",
                                      borderBottom: "1px solid lightgray",
                                      backgroundColor: "white",
                                    }}
                                    value={emailFilter}
                                    onChange={(e) =>
                                      setEmailFilter(e.target.value)
                                    }
                                  />
                                ) : item.title === "Phone Number" ? (
                                  <input
                                    type="text"
                                    placeholder="Filter"
                                    style={{
                                      width: "97%",
                                      padding: "5px ",
                                      margin: "5px 0px 5px 5px",
                                      borderBottom: "1px solid lightgray",
                                      backgroundColor: "white",
                                    }}
                                    value={phoneFilter}
                                    onChange={(e) =>
                                      setPhoneFilter(e.target.value)
                                    }
                                  />
                                ) : item.title === "Status" ? (
                                  <input
                                    type="text"
                                    placeholder="Filter"
                                    style={{
                                      width: "97%",
                                      padding: "5px ",
                                      margin: "5px 0px 5px 5px",
                                      borderBottom: "1px solid lightgray",
                                      backgroundColor: "white",
                                    }}
                                    value={statusFilter}
                                    onChange={(e) =>
                                      setStatusFilter(e.target.value)
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>

                      {loading ? (
                        <TableLoader page="internal-userList" />
                      ) : (
                        <div className="converted-user-details-list">
                          {convertedUsers.length > 0 ? (
                            convertedUsers
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((item, index) => {
                                let nameLength =
                                  item.firstName.length + item.lastName.length;

                                {
                                  return (
                                    <>
                                      <div
                                        className={` internal-users-details ${index % 2
                                          ? ""
                                          : "alternate-recruiterlist-bg"
                                          }`}
                                      >
                                        <div
                                          style={{ width: "25%" }}
                                          className="internal-user-list-name "

                                        >
                                          <img
                                            style={{ objectFit: "cover" }}
                                            src={
                                              item.defaultAvatar
                                                ? profileAvatar
                                                : item.interacted
                                                  ? item.croppedImage
                                                  : item.profileImage
                                            }
                                            className="internal-user-list-profile-image"
                                          />

                                          {nameLength > 23 ? (
                                            <Tooltip
                                              title={`${item.firstName} ${item.lastName}`}
                                            >
                                              <p style={{ textAlign: "start" }}>
                                                {item.firstName} {item.lastName}
                                              </p>
                                            </Tooltip>
                                          ) : (
                                            <p style={{ textAlign: "start" }}>
                                              {item.firstName} {item.lastName}
                                            </p>
                                          )}
                                        </div>

                                        <div
                                          className="internal-user-list-email"
                                          style={{ width: "26%" }}
                                        >
                                          {item.email.length > 24 ? (
                                            <Tooltip title={`${item.email}`}>
                                              <p>{item.email}</p>
                                            </Tooltip>
                                          ) : (
                                            <p>{item.email}</p>
                                          )}
                                        </div>

                                        <div
                                          className="internal-user-list-phone "
                                          style={{ width: "29%" }}
                                        >
                                          <p>+ {item.primaryPhone}</p>
                                        </div>

                                        <div
                                          className="status-button"
                                          style={{ width: "15%" }}
                                        >
                                          <button
                                            className={` ${item.requestStatus}`}
                                          >
                                            {item.requestStatus}
                                          </button>
                                        </div>
                                        <div
                                          className="internal-user-list-actions"
                                          style={{ width: "5%", gap: "10px" }}
                                        >
                                          <Tooltip title="Resend Request">
                                            <IconButton
                                              className="user-role-action-button"
                                              disabled={
                                                item.requestStatus ==
                                                  "Pending" ||
                                                  item.requestStatus == "Accepted"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <ResendIcon
                                                style={{
                                                  color:
                                                    item.requestStatus ==
                                                      "Pending" ||
                                                      item.requestStatus ==
                                                      "Accepted"
                                                      ? ""
                                                      : "#2c3e50",
                                                }}
                                                disabled={true}
                                                fontSize="small"
                                                onClick={() =>
                                                  handleChangeProfile(item)
                                                }
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              })
                          ) : (
                            <p>No Users</p>
                          )}
                        </div>
                      )}
                    </div>
                    <TablePagination
                      id="internal-users-list-pagination"
                      component="div"
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      count={convertedUsers.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      variant="outlined"
                    />
                  </Card>
                ) : null
              }

              {/* Snackbar  */}
              <UserSnackbar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
              />
            </Box>
          </div>

          {/* Cancel Interview */}
          <CancelInterview
            cancelInterview={cancelInterview}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
          />
        </div>
      ) : (
        <PageNotFound />
      )}

      <Footer sidebarOpen={sidebarOpen} />

      <DeleteUser
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
        roleFilter={roleFilter}
        dialogMessage={dialogMessage}
      />

      <ShareProfileModal
        resume={selectedItem?.resume}
        firstName={selectedItem?.firstName}
        lastName={selectedItem?.lastName}
        role={roleFilter}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        profilePublicLink={profilePublicLink}
        includeResume={includeResume}
        setIncludeResume={setIncludeResume}
        senderName={companyName}
        replyToEmail={replyToEmail}
      />

      <AddNoteModal
        userId={selectedItem?._id}
        role={roleFilter}
        usersList={allUsers}
        setUsersList={setAllUsers}
        companyId={id}
        openNoteModal={openNoteModal}
        setOpenNoteModal={setOpenNoteModal}
        profileId={id}
        module={"internalModule"}
      />

      <ViewNotes
        openViewNoteModal={openViewNoteModal}
        setOpenViewNoteModal={setOpenViewNoteModal}
        user={viewNoteUser}
        setUser={setViewNoteUser}
        companyId={id}
        profileId={id}
        unreadNotesCount={unreadNotesCount}
        setUnreadNotesCount={setUnreadNotesCount}
        getInternalUsers={getInternalUsers}
      />
    </div>
  );
};

export default UsersList;
