import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useLocation,useNavigate} from 'react-router-dom';
import Spinner from "../loaders/Spinner";
import ConfirmationModal from "../modals/confirmationModal";
import RedirectModal from "../modals/redirectModal";
import UserSnackbar from "../snackBars/UserSnackbar";


export default function CandidateLinkedinSignupProfile(){
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
   
    const [modalShow, setModalShow] = useState(false);
    const [redirectModal, setRedirectModal] = useState(false);
    const [message, setMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [tempMail, setTempMail] = useState("");
    useEffect(() => {

        async function fetchData() {
            console.log("Called")
    
    
            try {
    
                const headers = {
                    'Content-Type': 'application/json',
                };
                const url = '/api/candidate/linkedin-data'
    
                const grant_type = 'authorization_code'
                const client_id = '78y49finlyr0sb'
                const client_secret = 'EnThqnhmT07A49wV'
    
                const searchParams = new URLSearchParams(location.search);
                const code = searchParams.get('code');
                console.log(code);
    
                // const redirect_uri = "https%3A%2F%2Flocalhost%3A3000%2Fcandidate%2Flinkedin-signup"
                const redirect_uri=`${process.env.REACT_APP_REDIRECT_URL}%2Fcandidate%2Flinkedin-signup`
                
                const res = await axios.get(`${url}?grant_type=${grant_type}&client_id=${client_id}&client_secret=${client_secret}&code=${code}&redirect_uri=${redirect_uri}`, { headers })
                // setEmail(res.data.email)
                console.log("data from backend",res.data);

                const { given_name, family_name, email, email_verified, picture, } = res.data

                const newData = {
                firstName: given_name,
                lastName: family_name,
                verified: email_verified,
                email, profileImage: picture
                }

                const resData=await axios.post("/api/candidate/signup/linkedin",newData);
                console.log("linkedin login data",resData);
               
                if (resData.status == 200) {
                    setRedirectModal(true)
                    setTempMail(res.data.email)
                    setTimeout(() => {
    
                      navigate('/login')
                      //setSomething(false)
                      setRedirectModal(false)
                    }, 2100)
                  }
                
            }
            catch (err) {
                console.log(err)
                setLoading(false)
                setOpenSnackbar(true)
                setMessage(err.response.data.message)
                navigate('/login')
            }
            finally {
                setLoading(false); // Set loading to false when done
            }
        }
    
        fetchData()
    }, [])
     return(
       

        <>
         loading ? (
            <Spinner />
        ) : null

        <UserSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
        />
      <ConfirmationModal
        tempMail={tempMail}
        show={modalShow}
        onHide={() => setModalShow(false)}
       />
      <RedirectModal
        tempMail={tempMail}
        show={redirectModal}
        onHide={() => setModalShow(false)}
      />
      </>

    );
}