import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import "./Profile.css";
//custom pdf viewer
const PdfViewer = () => {
  console.log("entered pdfviewer page");

  const navigate = useNavigate();
  let { id, role, firstName } = useParams(); // fetching id and role from the parameters
  const [resume, setResume] = useState("");

  useEffect(() => {
    if (firstName) document.title = `Screener |  ${firstName}'s Resume`;
  }, [firstName]);

  useEffect(() => {
    const { data } = axios.get(`/api/${role}/${id}`).then((res) => {
      console.log(res.data.user);
      const { resume } = res.data.user;
      setResume(resume);
    });
  }, []);

  console.log("resume === ", resume);

  return (
    <>
      <div className="skills-button">
        <Tooltip title="Back">
          <button className="pdf-button" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon />
          </button>
        </Tooltip>
      </div>

      <div
        style={{
          width: "100vw",
          height: "100vh",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f4f4f4", // Background color to differentiate
        }}
      >
        <object
          className="pdf-preview-component"
          style={{ width: "90%", height: "90%" }} // 90% size of viewport to give padding
          data={resume} // Resume URL passed as prop
          type="application/pdf"
        >
          <iframe
            src={resume}
            width="100%"
            height="100%"
            title="Resume"
          />
        </object>
      </div>

    </>
  );
};

export default PdfViewer;
