import React, { useState, useEffect, useCallback } from "react";
import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import EducationIcon from "@mui/icons-material/SchoolOutlined";
import ExperienceIcon from "@mui/icons-material/WorkOutlineOutlined";
import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import EditIcon from "@mui/icons-material/CreateOutlined";
import AddIcon from "@mui/icons-material/Add";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Phone from "@mui/icons-material/LocalPhoneOutlined";
import Dollar from "@mui/icons-material/AttachMoneyOutlined";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListIcon from "@mui/icons-material/FiberManualRecord";
import axios from "axios";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { Steps } from "intro.js-react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import DemoProfile from "./DemoProfile";
import ProfileNavbar from "./ProfileNavbar";
import Spinner from "../loaders/Spinner";
import ExperienceEditModal from "../modals/ExperienceEditModal";
import EducationEditModal from "../modals/EducationEditModal";
import SkillEditModal from "../modals/SkillEditModal";
import PersonalDetailsModal from "../modals/PersonalDetailsModal";
import MainCardEditModal from "../modals/MainCardEditModal";


// CSS import
import "./Profile.css";
import Footer from "../../Footer/Footer";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PublicIcon from "@mui/icons-material/Public";

const ScreenerProfile = (props) => {
  let totalYearofExperience = 0;
  let totalMonthofExperience = 0;
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileImage, setProfileImage] = useState(profileAvatar);
  const [registeredThrough, setRegisteredThrough] = useState("");

  const [email, setEmail] = useState("");
  const [profileTitle, setProfileTitle] = useState("");
  const [resume, setResume] = useState(null);
  const [phone, setPhone] = useState("");
  const [about, setAbout] = useState("");
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  // const [salaryRange, setSalaryRange] = useState({ from: "", to: "" });
  const [salaryDetail, setSalaryDetail] = useState({ salary: "", payType: "" });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [skills, setSkills] = useState([]);
  const [croppedArea1, setCroppedArea1] = useState({});
  const [croppedImg, setCroppedImg] = useState("");
  const [interacted, setInteracted] = useState();
  const [defaultAvatar, setDefaultAvatar] = useState();
  const [shown, setShown] = useState(false);
  // const [imageRemoved, setImageRemoved]  =  useState(true);
  const [jobExperience, setJobExperience] = useState([]);
  const [educationDetails, setEducationDetails] = useState([]);
  const [personalDetails, setPersonalDetails] = useState({});
  const [imagePublicId, setImagePublicId] = useState("");
  const [resumePublicId, setResumePublicId] = useState("");
  const [editForm, setEditForm] = useState(false);

  // main card edit modal
  const [openMainCardEdit, setopenMainCardEdit] = useState(false);
  const [MainCardEditDetails, setMainCardEditDetails] = useState({});

  const [openExperience, setOpenExperience] = useState(false);
  const [openEditExperience, setOpenEditExperience] = useState(false);
  const [jobArray, setJobArray] = useState([]);
  const [job, setJob] = useState({
    position: "",
    company: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    checked: false,
    currentWorking: false,
  });
  const [expid, setExpid] = useState();
  const [activeDialog, setActiveDialog] = useState("");

  const [linkedinURL, setLinkedinURL] = useState("");
  const [githubURL, setGithubURL] = useState("");
  const [portfolioURL, setPortfolioURL] = useState("");

  // experience edit dialog delete confirmation dialog states(defined here to prevent page refresh on delete)
  const [openDelete, setOpenDelete] = useState(false);

  // education edit dialog delete confirmation dialog states(defined here to prevent page refresh on delete)
  const [openDeleteEd, setOpenDeleteEd] = useState(false);

  const [openEducation, setOpenEducation] = useState(false);
  const [openEditEducation, setOpenEditEducation] = useState(false);
  const [educationArray, setEducationArray] = useState([]);
  const [education, setEducation] = useState({
    school: "",
    degree: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    stream: "",
    checked: false,
    currentWorking: false,
  });
  const [edid, setEdid] = useState();

  const [openSkills, setOpenSkills] = useState(false);

  const [openDetails, setOpenDetails] = useState(false);

  const [profileCompletion, setProfileCompletion] = useState(0);

  const dob = new Date(personalDetails.dob);

  const id = sessionStorage.getItem("id");

  //guidedTour
  const [stepsEnabled, setStepsEnabled] = useState(true);
  const initialStep = 0;
  const [completeTour, setCompleteTour] = useState();

  const steps = [
    {
      element: ".main-edit",
      intro: `Hello <strong>${firstName} ${lastName}</strong>, <br /> Welcome to Screener!<br/> Lets find out how we can help you in less than a minute. `,
    },
    {
      element: ".main-edit",
      intro: `Welcome to your Profile! <br/> Kindly fill in all details so that recruiters can approach you with the right job opportunities.`,
    },
    {
      element: "#main-edit",
      intro: `This section contains your Profile Summary, Profile Picture, Resume, Current Location, and Expected Salary. <br/>Fill in these details by clicking on the edit button.`,
      position: "top",
    },
    {
      element: "#skills-edit",
      intro: `Let us know your best skills so that we can find the best job role for you.<br/>Fill in your skills by clicking on the edit button.`,
      position: "right",
    },
    {
      element: "#experience-edit",
      intro: `Add your work history here. <br/><b>Pro Tip: Total Experience is calculated based on your work history.</b> <br/> So don't miss out on anything 😉<br/>Fill in your Work Experience by clicking on the add button.`,
    },
    {
      element: "#education-edit",
      intro: `Let us know what you majored in, or where you went to high school. Every bit counts 😄<br/>
      Fill in your Education details by clicking on the add button.`,
      position: "left",
    },
    {
      element: "#personal-details-edit",
      intro: `A few more details about you, so we can send you a birthday card 😃<br/>
      Fill in your personal details by clicking on the edit button.
      `,
    },
    {
      element: "#profile-completion-percentage",
      intro: `This meter shows the percentage of your profile completion. <br/><b>Pro tip: Only profiles with 100% completion will be visible to recruiters.</b>
    <br/>
      Fill in all sections to make it 100%. Easy!`,
    },
    {
      element: ".last-tour",
      intro: `That's it, you're all done! <br/> Thank you for choosing Screener.<br/> <br/>Cheers, <br/>Screener Team`,
    },
  ];

  const location = useLocation();

  const options = {
    nextLabel: "Next",
    prevLabel: "Prev",
    skipLabel: "Skip",
    doneLabel: "Done",
    exitOnOverlayClick: false,
    exitOnEsc: false,
    overlayOpacity: 0.5,
    overlayColor: "#000",
    keyboardNavigation: true,
    scrollToElement: true,
    helperElementPadding: 10,
  };

  const onExit = () => {
    setStepsEnabled(false);
  };

  useEffect(() => {
    if (firstName) document.title = `Screener |  ${firstName}'s Profile`;
  }, [firstName]);

  useEffect(() => {
    axios
      .get(`/api/screener/${id}`)
      .then((res) => {
        console.log(res);
        const {
          role,
          firstName,
          lastName,
          profileTitle,
          screenerEmail,
          screenerPhone,
          personal_details,
          about,
          job_experience,
          educational_details,
          skills,
          salaryDetail,
          croppedAreaPixels,
          croppedArea,
          defaultAvatar,
          profileCompletionPercentage,
          profileImage,
          croppedImage,
          interacted,
          croppedImagePublicId,
          resume,
          resumeFormat,
          zoom,
          imagePublicId,
          resumePublicId,
          permissions,
          registeredThrough,
          socialMedia: { linkedin, github, portfolio },
        } = res.data.user;

        setCroppedImg(croppedImage);
        setRole(role);
        setProfileImage(profileImage);
        setFirstName(firstName);
        setLastName(lastName);
        setProfileTitle(profileTitle);
        setPhone(screenerPhone);
        setEmail(screenerEmail);
        setAbout(about);
        setZoom(zoom);
        setSalaryDetail(salaryDetail);
        setCroppedAreaPixels(croppedAreaPixels);
        setCroppedArea1(croppedArea);
        setDefaultAvatar(defaultAvatar);
        setResume(resume);
        setResumePublicId(resumePublicId);
        setImagePublicId(imagePublicId);
        setCompleteTour(permissions.isTourCompleted);
        setInteracted(interacted);
        setRegisteredThrough(registeredThrough);
        setLinkedinURL(linkedin);
        setGithubURL(github);
        setPortfolioURL(portfolio);

        setMainCardEditDetails({
          id: id,
          firstName: firstName,
          lastName: lastName,
          profileTitle: profileTitle,
          about: about,
          email: screenerEmail,
          phone: screenerPhone,
          profileTitle: profileTitle,
          resume: resume,
          resumeFormat: resumeFormat,
          profileImg: profileImage,
          salaryDetail: salaryDetail,
          croppedAreaPixels: croppedAreaPixels,
          croppedArea: croppedArea,
          defaultAvatar: defaultAvatar,
          zoom: zoom,
          interacted: interacted,
          croppedImagePublicId: croppedImagePublicId,
          resumePublicId: resumePublicId,
          imagePublicId: imagePublicId,
          linkedinURL: linkedin,
          githubURL: github,
          portfolioURL: portfolio,
        });

        setCity(personal_details.location.city);
        setState(personal_details.location.state);
        setCountry(personal_details.location.country);
        setSkills(skills);
        setJobExperience(job_experience);
        setEducationDetails(educational_details);
        setPersonalDetails(personal_details);
        setIsLoading(false);
        setProfileCompletion(profileCompletionPercentage);
        setJobArray(job_experience);
        setEducationArray(educational_details);
      })
      .catch((error) => console.log(error));
  }, [
    openMainCardEdit,
    openEditExperience,
    openExperience,
    openSkills,
    openEditEducation,
    openEducation,
    openDetails,
    openDelete,
    openDeleteEd,
  ]);

  useEffect(() => {
    async function fetchData() {
      console.log("Called");

      try {
        const headers = {
          "Content-Type": "application/json",
        };
        const url = "/get-user-details/";

        const grant_type = "authorization_code";
        const client_id = "78y49finlyr0sb";
        const client_secret = "EnThqnhmT07A49wV";

        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("code");

        const redirect_uri =
          "https%3A%2F%2Flocalhost%3A3000%2Fscreener%2Fprofile";

        const res = await axios.get(
          `${url}?grant_type=${grant_type}&client_id=${client_id}&client_secret=${client_secret}&code=${code}&redirect_uri=${redirect_uri}`,
          { headers }
        );
        // setEmail(res.data.email)
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);

  console.log(registeredThrough);
  const startArray = [];
  const endArray = [];
  let count = 0;

  // main card modal handlers
  const mainCardEditOpenHandler = () => {
    setopenMainCardEdit(true);
  };

  const mainCardEditCloseHandler = () => {
    setopenMainCardEdit(false);
  };

  const experienceOpenHandler = () => {
    setOpenExperience(true);
    setActiveDialog("Add");
  };

  const educationOpenHandler = () => {
    setOpenEducation(true);
    setActiveDialog("Add");
  };

  const skillsOpenHandler = () => {
    setOpenSkills(true);
  };

  //Edit Experience
  const experienceEditOpenHandler = (index) => {
    const job = highestExperience.filter((item, i) => {
      if (index === i) return item;
    });

    //Edit Experience

    setExpid(job[0]._id);
    setJob({
      position: job[0].position,
      company: job[0].company,
      startMonth: job[0].startMonth,
      startYear: job[0].startYear,
      endMonth: job[0].endMonth,
      endYear: job[0].endYear,
      checked: job[0].checked,
      currentWorking: job[0].currentWorking,
    });
    setOpenEditExperience(true);
    setActiveDialog("Edit");
    setEditForm(true);
  };
  const handleExperience = (id) => {
    const job = highestExperience.filter((item, i) => {
      if (item._id === id) return item;
    });
    setExpid(job[0]._id);
    setJob({
      position: job[0].position,
      company: job[0].company,
      startMonth: job[0].startMonth,
      startYear: job[0].startYear,
      endMonth: job[0].endMonth,
      endYear: job[0].endYear,
      checked: job[0].checked,
      currentWorking: job[0].currentWorking,
    });
    setOpenEditExperience(true);
    setActiveDialog("Edit");
    setEditForm(true);
  };
  const totalExperience = (startDate, endDate) => {
    let yearDifference, monthDifference, remainingMonths;
    let totalMonths = 0;
    let totalYears = 0;
    let startMonth = startDate.getMonth();
    let endMonth = endDate.getMonth() + 1;
    yearDifference = endDate.getFullYear() - startDate.getFullYear();
    monthDifference = startMonth - endMonth;

    totalMonths = yearDifference * 12 - monthDifference;

    totalYears = Math.floor(totalMonths / 12);
    remainingMonths = totalMonths % 12;
    const final = remainingMonths
      ? totalYears
        ? `${totalYears}` +
          (totalYears === 1 ? " year " : " years ") +
          `${remainingMonths}` +
          (remainingMonths === 1 ? " month" : " months")
        : `${remainingMonths} ` +
          (remainingMonths === 1 ? " month " : " months ")
      : `${totalYears} ` + (totalYears === 1 ? " year " : " years ");

    return final;
  };

  const checkMonth = (month) => {
    var convertedMonth;
    switch (month) {
      case "00":
        convertedMonth = "January";
        return convertedMonth;
        break;
      case "01":
        convertedMonth = "February";
        return convertedMonth;
        break;
      case "02":
        convertedMonth = "March";
        return convertedMonth;
        break;
      case "03":
        convertedMonth = "April";
        return convertedMonth;
        break;
      case "04":
        convertedMonth = "May";
        return convertedMonth;
        break;
      case "05":
        convertedMonth = "June";
        return convertedMonth;
        break;
      case "06":
        convertedMonth = "July";
        return convertedMonth;
        break;
      case "07":
        convertedMonth = "August";
        return convertedMonth;
        break;
      case "08":
        convertedMonth = "September";
        return convertedMonth;
        break;
      case "09":
        convertedMonth = "October";
        return convertedMonth;
        break;
      case "10":
        convertedMonth = "November";
        return convertedMonth;
        break;
      case "11":
        convertedMonth = "December";
        return convertedMonth;
        break;
    }
  };

  //Edit Education
  const educationEditOpenHandler = (index) => {
    const education = highestEducation.filter((item, i) => {
      if (index === i) return item;
    });

    setEdid(education[0]._id);
    setEducation({
      school: education[0].school,
      degree: education[0].degree,
      startMonth: education[0].startMonth,
      startYear: education[0].startYear,
      endMonth: education[0].endMonth,
      endYear: education[0].endYear,
      stream: education[0].stream,
      checked: education[0].checked,
      currentWorking: education[0].currentWorking,
    });

    setOpenEditEducation(true);
    setEditForm(true);
    setActiveDialog("Edit");
  };

  const onCompleteTour = () => {
    axios
      .post(`/api/screener/guidedtour/${id}`, {
        completeTour: true,
      })
      .then(() => window.location.reload(false));
  };

  const detailsOpenHandler = () => {
    setOpenDetails(true);
  };

  const highestEducation = [...educationDetails].sort(
    (a, b) => b.startYear - a.startYear
  );

  const completedEducation = highestEducation.filter((item, index) => {
    if (item.endMonth !== "Present") {
      return item;
    }
  });

  console.log(jobExperience);

  const highestExperience = [...jobExperience].sort((a, b) => {
    if (a.endYear == "present" && b.endYear == "present")
      return b.startYear - a.startYear;
    else if (a.endYear === b.endYear) return b.startYear - a.startYear;
    else if (a.endYear !== b.endYear && a.startYear === b.startYear)
      return b.endYear - a.endYear;
    else if (a.endYear !== b.endYear && a.startYear !== b.startYear)
      return b.endYear - a.endYear;
  });

  highestExperience.map((item) => {
    const newObj = {
      startYear: item.startYear,
      startMonth: item.startMonth,
    };
    startArray.push(newObj);
  });
  highestExperience.map((item) => {
    const newObj = {
      endYear: item.endYear,
      endMonth: item.endMonth,
    };
    endArray.push(newObj);
  });

  const lookup = highestExperience.reduce((a, e) => {
    a[e.company] = ++a[e.company] || 0;
    return a;
  }, {});

  const key = "company";
  const uniqueChars = [
    ...new Map(
      highestExperience.map((item) => [item[key].toLowerCase(), item])
    ).values(),
  ];

  let newArr = [];
  let newObj = {
    company: "",
    companyInfo: [],
  };

  let c = 0;
  for (let i = 0; i < uniqueChars.length; i++) {
    console.log(uniqueChars[i]);
    newObj.company = uniqueChars[i].company;
    for (let j = 0; j < highestExperience.length; j++) {
      if (
        uniqueChars[i].company.toLowerCase() ===
        highestExperience[j].company.toLowerCase()
      ) {
        console.log(uniqueChars[i].company, highestExperience[j].company);
        newObj.companyInfo = [...newObj.companyInfo, highestExperience[j]];
      }
    }
    newArr = [...newArr, newObj];
    newObj = {
      company: "",
      companyInfo: [],
    };
  }

  for (let i = 0; i < endArray.length - 1; i++) {
    if (endArray[i + 1].endYear == startArray[i].startYear) {
      if (endArray[i].endMonth == "Present")
        endArray[i + 1].endMonth = new Date().getMonth() + 1;
      if (endArray[i + 1].endMonth == startArray[i].startMonth) {
        count += 1;
      }
    }
  }

  //mapping job experience
  jobExperience.map((item, index) => {
    totalYearofExperience += item.endYear - item.startYear;
    let monthComparison =
      item.endMonth === "Present"
        ? (item.endMonth = new Date().getMonth())
        : item.endMonth;
    totalMonthofExperience +=
      Number(item.endMonth) - Number(item.startMonth) + 1;
    let counts = totalMonthofExperience / 12;
    totalYearofExperience += Math.floor(counts);
    totalMonthofExperience = totalMonthofExperience - 12 * Math.floor(counts);
  });

  let total = totalMonthofExperience + totalYearofExperience * 12 - count;
  totalMonthofExperience = total % 12;
  totalYearofExperience = Math.trunc(total / 12);

  var degree = "",
    stream = "",
    school = "";

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="root-div">
      <ProfileNavbar
        props={props}
        firstName={firstName}
        croppedImg={croppedImg}
        profileImage={profileImage}
        interacted={interacted}
        defaultAvatar={defaultAvatar}
        registered={registeredThrough}
      />

      <>
        <Box sx={{ flexGrow: 1 }}>
          <Card id="main-edit" className="main-card profile-card">
            {/* 1st Grid container */}
            <Grid
              container
              spacing={0}
              direction="row"
              className="profile-card-container"
            >
              <div className="edit-icon-mobile-container">
                <Tooltip title="Edit">
                  <EditIcon
                    className="edit-icon-mobile"
                    onClick={mainCardEditOpenHandler}
                  />
                </Tooltip>
              </div>
              <Grid
                item
                xs={12}
                sm={12}
                md={2.5}
                lg={2}
                xl={1.5}
                className="container-profile"
                style={{ zIndex: "1" }}
              >
                <img
                  src={
                    defaultAvatar
                      ? profileAvatar
                      : interacted
                      ? croppedImg
                      : profileImage
                  }
                  className="cropper1"
                  style={{
                    borderRadius: "10px",
                    objectFit: "cover",
                    width: "170px",
                    height: "170px",
                  }}
                />

                <div class="social-profile-card-container">
                  {linkedinURL && (
                    <a href={linkedinURL} className="social" target="_blank">
                      <LinkedInIcon fontSize="small" />
                    </a>
                  )}

                  {githubURL && (
                    <a href={githubURL} class="social" target="_blank">
                      <GitHubIcon fontSize="small" />
                    </a>
                  )}

                  {portfolioURL && (
                    <a href={portfolioURL} class="social" target="_blank">
                      <PublicIcon fontSize="small" />
                    </a>
                  )}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={7.2}
                xl={7.5}
                className="container-heading-title"
              >
                <div>
                  <h2>{`${firstName} ${lastName}`}</h2>
                  <h5>{profileTitle}</h5>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2.5}
                lg={2.5}
                className="container-heading-buttons"
              >
                {resume && (
                  <div>
                    <Tooltip title="view resume">
                      <button className="view-resume">
                        <Link to={`/${role}/profile/${firstName}/resume/${id}`}>
                          <EyeIcon className="eye-icon" />
                          Resume
                        </Link>
                      </button>
                    </Tooltip>
                  </div>
                )}
                <div>
                  <Tooltip title="Edit">
                    <EditIcon
                      id="main-edit"
                      data-step="1"
                      className="edit-icon"
                      onClick={mainCardEditOpenHandler}
                    />
                  </Tooltip>
                </div>
              </Grid>
            </Grid>

            {/* 2nd Grid container */}
            <Grid
              container
              spacing={0}
              direction="row"
              className="info-container"
              justifyContent="flex-end"
            >
              <Grid
                item
                xs={0}
                sm={0}
                md={0}
                lg={1.8}
                xl={1.2}
                className="container-profile"
              ></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4.8}
                lg={3}
                xl={3.1}
                className="about-container"
              >
                <h4>About</h4>
                <p>{about || "Tell us about yourself"}</p>
              </Grid>

              <Grid
                item
                xs={12}
                sm={5.5}
                md={3}
                lg={3}
                xl={3}
                className="info-container1"
              >
                <div className="info-container-height">
                  <div className="info-container1-heading">
                    <MailOutlineIcon className="icon" />
                    Email
                  </div>
                  <p>{email}</p>
                </div>

                <div className="info-container-height">
                  <div className="info-container1-heading">
                    <LocationIcon className="icon" />
                    Current Location
                  </div>
                  <p>
                    {city && state && country ? (
                      <>
                        {`${city}, ${state},`}
                        <br />
                        {country}
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>

                <div className="info-container-height">
                  <div className="info-container1-heading">
                    <ExperienceIcon className="icon" />
                    Total Experience
                  </div>

                  <p>
                    {totalYearofExperience || totalMonthofExperience ? (
                      <>
                        {totalYearofExperience !== 0
                          ? totalYearofExperience
                          : ""}{" "}
                        {totalYearofExperience === 1
                          ? " year "
                          : totalYearofExperience === 0
                          ? ""
                          : "years "}{" "}
                        {totalMonthofExperience == 0 ? (
                          ""
                        ) : (
                          <>
                            {totalMonthofExperience}
                            {totalMonthofExperience === 1
                              ? " month "
                              : " months "}
                          </>
                        )}
                      </>
                    ) : (
                      <>{"No Experience"}</>
                    )}
                  </p>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={5.5}
                md={3}
                lg={3}
                xl={3}
                className="info-container2"
              >
                <div className="info-container-height">
                  <div className="info-container1-heading">
                    <Phone className="icon" />
                    Phone
                  </div>
                  {!phone ? (
                    <p
                      onClick={mainCardEditOpenHandler}
                      style={{ color: "red", cursor: "pointer" }}
                    >
                      Please enter your phone number
                    </p>
                  ) : (
                    <p>+{phone}</p>
                  )}
                </div>
                <div className="info-container-height">
                  <div className="info-container1-heading">
                    {" "}
                    <EducationIcon className="icon" />
                    Highest Education
                  </div>
                  <p>
                    {completedEducation.filter((item, index) => {
                      if (index === 0) {
                        degree = item.degree;
                        stream = item.stream;
                        school = item.school;
                      }
                    })}
                    {degree && stream ? (
                      <>
                        {degree} in {stream}
                        <br />
                        {school}
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>

                <div className="info-container-height">
                  <div className="info-container1-heading">
                    <Dollar className="icon" />
                    Expected Salary (USD)
                  </div>
                  <p>
                    {" "}
                    {salaryDetail.salary && salaryDetail.payType ? (
                      <>
                        ${salaryDetail.salary}/{salaryDetail.payType}
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </Grid>
            </Grid>
            {/* 3rd container */}
            <Grid
              container
              spacing={1}
              direction="row"
              className="profile-progress-bar"
            >
              <Grid
                id="profile-completion-percentage"
                item
                xs={12}
                sm={12}
                md={4.5}
                lg={5.8}
                xl={5}
                sx={{ textAlign: "center" }}
                className="container-profile"
              >
                <div className="profile-progress-bar-heading">
                  Percentage of Profile Completion
                </div>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      className="profile-progress-bar-element"
                      value={profileCompletion}
                      max={100}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                      {profileCompletion}%
                    </Typography>
                  </Box>
                </Box>
                {profileCompletion < 100 ? (
                  <div>
                    <InfoOutlinedIcon
                      fontSize="small"
                      style={{ color: "#4c4c5d", fontSize: "16px" }}
                    />{" "}
                    <span style={{ fontSize: "13px" }}>
                      Only profiles with 100% completion will be visible to
                      recruiters.
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Card>

          <div className="cards-list">
            <Grid className="card-tiles" xs={12}>
              <Card id="skills-edit" className="side-card profile-card">
                {/* 1st Grid container */}
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="skill-card sub-card"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="skill-card-title"
                  >
                    <div className="skill-card-heading">
                      <p>Skills</p>
                    </div>

                    <div className="skill-card-editIcon">
                      <Tooltip title="Edit">
                        <EditIcon
                          className="edit-icon"
                          onClick={skillsOpenHandler}
                        />
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="skills-button"
                  >
                    {skills.map((skill, index) => {
                      const custom =
                        index % 2 == 0 ? "filled-button" : "unfilled-button";
                      return (
                        <Tooltip
                          title={
                            skill.yearOfExperience
                              ? `${skill.skillName} - ${skill.yearOfExperience} year(s)`
                              : `${skill.skillName}`
                          }
                        >
                          <button className={custom}>{skill.skillName}</button>
                        </Tooltip>
                      );
                    })}
                  </Grid>
                </Grid>
              </Card>
              <Card id="experience-edit" className="middle-card profile-card">
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="experience-card sub-card"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="experience-card-title"
                  >
                    <div className="experience-card-heading">
                      <p>Experience</p>
                    </div>

                    <div className="experience-card-editIcon">
                      <Tooltip title="Add">
                        <AddIcon
                          onClick={experienceOpenHandler}
                          className="edit-icon"
                        />
                      </Tooltip>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="experience-list"
                  >
                    {newArr.map((job, index) => {
                      const company = job.company.split(" ");

                      for (let i = 0; i < company.length; i++) {
                        company[i] =
                          company[i][0]?.toUpperCase() +
                          company[i]?.substr(1) +
                          " ";
                      }
                      return (
                        <List>
                          {index >= 1 && (
                            <Divider
                              variant="inset"
                              component="li"
                              className="list-divider"
                            />
                          )}
                          <div className="experience-list-edit-icon">
                            <ListItem
                              alignItems="flex-start"
                              className="experience-list-items"
                            >
                              <ListItemAvatar className="experience-list-icon">
                                <SquareRoundedIcon className="list-icon" />
                              </ListItemAvatar>
                              <div
                                style={{
                                  flexGrow: "1",
                                }}
                              >
                                <div>
                                  <ListItemText
                                    className="list-items"
                                    primary={company}
                                    secondary={
                                      <React.Fragment>
                                        {job.companyInfo.map((item, index) => {
                                          if (job.companyInfo.length > 1) {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                                className="experience-main"
                                              >
                                                <div className="job-details">
                                                  <ul className="job-role">
                                                    <li
                                                      className={`${
                                                        index ===
                                                        job.companyInfo.length -
                                                          1
                                                          ? "last-child"
                                                          : ""
                                                      }`}
                                                    >
                                                      <div className="job-title">
                                                        <div className="company-name">
                                                          <Typography
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary"
                                                          >
                                                            {item.position}
                                                          </Typography>
                                                        </div>
                                                      </div>

                                                      <div className="experience-details">
                                                        <Typography
                                                          component="span"
                                                          variant="body2"
                                                          color="text.primary"
                                                        >
                                                          {item.checked ===
                                                          true ? (
                                                            <>
                                                              {checkMonth(
                                                                item.startMonth
                                                              )}{" "}
                                                              {item.startYear} -{" "}
                                                              {"Present"}
                                                            </>
                                                          ) : (
                                                            <>
                                                              {checkMonth(
                                                                item.startMonth
                                                              )}{" "}
                                                              {item.startYear} -{" "}
                                                              {checkMonth(
                                                                item.endMonth
                                                              )}{" "}
                                                              {item.endYear}
                                                            </>
                                                          )}
                                                        </Typography>
                                                        <br />

                                                        <Typography
                                                          component="span"
                                                          variant="body2"
                                                          color="text.primary"
                                                        >
                                                          {item.checked != true
                                                            ? totalExperience(
                                                                new Date(
                                                                  item.startYear,
                                                                  item.startMonth
                                                                ),
                                                                new Date(
                                                                  item.endYear,
                                                                  item.endMonth
                                                                )
                                                              )
                                                            : totalExperience(
                                                                new Date(
                                                                  item.startYear,
                                                                  item.startMonth
                                                                ),
                                                                new Date(
                                                                  item.endYear,
                                                                  new Date().getMonth()
                                                                )
                                                              )}
                                                        </Typography>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="on-hover">
                                                  <Tooltip title="Edit">
                                                    <EditIcon
                                                      className="edit-icon "
                                                      onClick={() =>
                                                        handleExperience(
                                                          item._id
                                                        )
                                                      }
                                                    />
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            );
                                          } else
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                                className="experience-main single-position"
                                              >
                                                <div className="job-details">
                                                  <div className="job-title">
                                                    <div className="company-name">
                                                      <Typography
                                                        // sx={{ display: 'inline' }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                      >
                                                        {item.position}
                                                      </Typography>
                                                    </div>
                                                  </div>

                                                  <div className="experience-details">
                                                    <Typography
                                                      // sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                    >
                                                      {item.checked === true ? (
                                                        <>
                                                          {checkMonth(
                                                            item.startMonth
                                                          )}{" "}
                                                          {item.startYear} -{" "}
                                                          {"Present"}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {checkMonth(
                                                            item.startMonth
                                                          )}{" "}
                                                          {item.startYear} -{" "}
                                                          {checkMonth(
                                                            item.endMonth
                                                          )}{" "}
                                                          {item.endYear}
                                                        </>
                                                      )}
                                                    </Typography>
                                                    <br />

                                                    <Typography
                                                      // sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                    >
                                                      {item.checked != true
                                                        ? totalExperience(
                                                            new Date(
                                                              item.startYear,
                                                              item.startMonth
                                                            ),
                                                            new Date(
                                                              item.endYear,
                                                              item.endMonth
                                                            )
                                                          )
                                                        : totalExperience(
                                                            new Date(
                                                              item.startYear,
                                                              item.startMonth
                                                            ),
                                                            new Date(
                                                              item.endYear,
                                                              new Date().getMonth()
                                                            )
                                                          )}
                                                    </Typography>
                                                  </div>
                                                </div>
                                                <div className="on-hover">
                                                  <Tooltip title="Edit">
                                                    <EditIcon
                                                      style={{
                                                        marginTop: "0px",
                                                      }}
                                                      className="edit-icon"
                                                      onClick={() =>
                                                        handleExperience(
                                                          item._id
                                                        )
                                                      }
                                                    />
                                                  </Tooltip>
                                                </div>
                                              </div>
                                            );
                                        })}
                                      </React.Fragment>
                                    }
                                  />
                                </div>
                                <div></div>
                              </div>
                            </ListItem>
                          </div>
                        </List>
                      );
                    })}
                  </Grid>
                </Grid>
              </Card>

              <Card id="education-edit" className="middle-card profile-card">
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="education-card sub-card"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="education-card-title"
                  >
                    <div className="education-card-heading">
                      <p>Education</p>
                    </div>

                    <div className="education-card-editIcon">
                      <Tooltip title="Add">
                        <AddIcon
                          onClick={educationOpenHandler}
                          className="edit-icon"
                        />
                      </Tooltip>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="education-list"
                  >
                    {highestEducation.map((education, index) => {
                      const {
                        degree,
                        stream,
                        startMonth,
                        startYear,
                        endMonth,
                        endYear,
                        school,
                        checked,
                      } = education;
                      console.log("977", endMonth, endYear);
                      return (
                        <List>
                          {index >= 1 && (
                            <Divider
                              variant="inset"
                              component="li"
                              className="list-divider"
                            />
                          )}
                          <div className="education-list-edit-icon  education-hover">
                            <ListItem
                              alignItems="flex-start"
                              className="education-list-items"
                            >
                              <ListItemAvatar className="education-list-icon">
                                {/* <ListIcon /> */}
                                <SquareRoundedIcon className="list-icon" />
                              </ListItemAvatar>
                              <div style={{ flexGrow: "1" }}>
                                <ListItemText
                                  className="list-items"
                                  primary={`${degree} in ${stream}`}
                                  secondary={
                                    <React.Fragment>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div className="job-details">
                                          <Typography
                                            // sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                          >
                                            {school}
                                          </Typography>

                                          <div className="education-details">
                                            <Typography
                                              // sx={{ display: 'inline' }}
                                              component="span"
                                              variant="body2"
                                              color="text.primary"
                                            >
                                              {checked === true ? (
                                                <>
                                                  {/* {startMonth} {startYear} -{" "}
                                                {endMonth} */}
                                                  {checkMonth(startMonth)}{" "}
                                                  {startYear} - {"Present"}
                                                </>
                                              ) : (
                                                <>
                                                  {checkMonth(startMonth)}{" "}
                                                  {startYear} -{" "}
                                                  {checkMonth(endMonth)}{" "}
                                                  {endYear}
                                                </>
                                              )}
                                            </Typography>
                                          </div>
                                        </div>
                                        <div className="on-hover">
                                          <Tooltip title="Edit">
                                            <EditIcon
                                              className="edit-icon"
                                              onClick={() =>
                                                educationEditOpenHandler(index)
                                              }
                                            />
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  }
                                />
                              </div>
                            </ListItem>
                          </div>
                        </List>
                      );
                    })}
                  </Grid>
                </Grid>
              </Card>

              <Card
                id="personal-details-edit"
                className="side-card profile-card"
              >
                {/* 1st Grid container */}
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  className="personal-card sub-card"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="personal-card-title"
                  >
                    <div className="personal-card-heading">
                      <p>Personal Details</p>
                    </div>

                    <div className="personal-card-editIcon">
                      <Tooltip title="Edit">
                        <EditIcon
                          className="edit-icon"
                          onClick={detailsOpenHandler}
                        />
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="personal-details"
                  >
                    <div className="details-block">
                      {personalDetails.dob ? (
                        <>
                          <div className="personal-info">
                            <p className="personal-info-heading">
                              Birth Date: &nbsp;{" "}
                            </p>
                            <p className="personal-info-content">
                              {dateFormat(dob, "mmmm dS, yyyy")}{" "}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {personalDetails.gender ? (
                        <>
                          <div className="personal-info">
                            <p className="personal-info-heading">
                              Gender: &nbsp;{" "}
                            </p>
                            <p className="personal-info-content">
                              {personalDetails.gender}{" "}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {personalDetails.nationality ? (
                        <>
                          <div className="personal-info">
                            <p className="personal-info-heading">
                              Nationality: &nbsp;{" "}
                            </p>
                            <p className="personal-info-content">
                              {personalDetails.nationality}{" "}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {personalDetails.marital_status ? (
                        <>
                          <div className="personal-info">
                            <p className="personal-info-heading">
                              Marital status: &nbsp;{" "}
                            </p>
                            <p className="personal-info-content">
                              {personalDetails.marital_status}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {city &&
                      state &&
                      country &&
                      personalDetails.location.postalCode ? (
                        <>
                          <div className="personal-info">
                            <p className="personal-info-heading">
                              Current Location: &nbsp;{" "}
                            </p>
                            <p className="personal-info-content">
                              {personalDetails.location.address1 ||
                              personalDetails.location.address2
                                ? personalDetails.location.address1 &&
                                  personalDetails.location.address2
                                  ? personalDetails.location.address1 +
                                    ", " +
                                    personalDetails.location.address2 +
                                    " - " +
                                    personalDetails.location.postalCode
                                  : personalDetails.location.address1
                                  ? personalDetails.location.address1 +
                                    " - " +
                                    personalDetails.location.postalCode
                                  : personalDetails.location.address2
                                  ? personalDetails.location.address2 +
                                    " - " +
                                    personalDetails.location.postalCode
                                  : ""
                                : city +
                                  ", " +
                                  state +
                                  // ", " +
                                  // country +
                                  " - " +
                                  personalDetails.location.postalCode}
                            </p>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </div>
        </Box>
        <MainCardEditModal
          role={role}
          {...MainCardEditDetails}
          setMainCardEditDetails={setMainCardEditDetails}
          setopenMainCardEdit={setopenMainCardEdit}
          open={openMainCardEdit}
          onClose={mainCardEditCloseHandler}
          profileCompletion={profileCompletion}
        />

        <ExperienceEditModal
          role={role}
          open={openExperience}
          activeDialog={activeDialog}
          setOpenExperience={setOpenExperience}
          id={id}
          openEdit={openEditExperience}
          setOpenEditExperience={setOpenEditExperience}
          job={job}
          expid={expid}
          profileCompletion={profileCompletion}
          jobArray={jobArray}
          editForm={editForm}
          setEditForm={setEditForm}
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
        />

        <EducationEditModal
          role={role}
          activeDialog={activeDialog}
          open={openEducation}
          setOpenEducation={setOpenEducation}
          id={id}
          openEdit={openEditEducation}
          setOpenEditEducation={setOpenEditEducation}
          education={education}
          edid={edid}
          profileCompletion={profileCompletion}
          educationArray={educationArray}
          editForm={editForm}
          setEditForm={setEditForm}
          openDeleteEd={openDeleteEd}
          setOpenDeleteEd={setOpenDeleteEd}
        />

        <SkillEditModal
          role={role}
          openSkills={openSkills}
          setOpenSkills={setOpenSkills}
          id={id}
          profileCompletion={profileCompletion}
          skills={skills}
        />
        <PersonalDetailsModal
          role={role}
          openDetails={openDetails}
          setOpenDetails={setOpenDetails}
          profileCompletion={profileCompletion}
          id={id}
          personalDetails={personalDetails}
        />
      </>
      <Footer sidebarOpen={"sidebar-close"} />
    </div>
  );
};

export default ScreenerProfile;
