import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

//external library Imports
import DialogContent from "@mui/material/DialogContent";
import { Grid, stepButtonClasses } from "@mui/material";
import { Dialog } from "@mui/material";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import Tooltip from "@mui/material/Tooltip";
import Cropper from "react-easy-crop";
import PhoneInput from "react-phone-input-2";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
// import TextareaCounter from "react-textarea-counter";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";
import getCroppedImg from "./CropImage";

//import custom textarea component
import TextareaCounter from "../textarea/TextareaCounter";

import SaveProfileSnackBar from "../snackBars/saveProfileSnackbar";
import ErrorMessageSnackbar from "../snackBars/errorMessageSnackbar";
import { getPhonesRegex } from "../auth/PhoneValidation";

// import GoogleDocsViewer from "react-google-docs-viewer";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PublicIcon from "@mui/icons-material/Public";

// CSS import
import "./MainCardEditModal.css";
import "./ExperienceEdit.css";
import  "../company admin/CompanyAdmin.css"

const MainCardEditModal = ({
  role,
  open,
  onClose,
  id,
  firstName,
  lastName,
  about,
  email,
  phone,
  profileTitle,
  profileImg,
  defaultAvatar,
  salaryDetail,
  croppedAreaPixels,
  croppedArea,
  resume,
  setopenMainCardEdit,
  zoom,
  resumePublicId,
  imagePublicId,
  profileCompletion,
  interacted,
  croppedImagePublicId,
  resumeFormat,
  linkedinURL,
  githubURL,
  portfolioURL,
}) => {
  // console.log(linkedinURL, githubURL, portfolioURL);

  // states to store the data received from ScreenerProfile.js
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
  const [interaction, setInteraction] = useState(interacted);
  const [firstName1, setFirstName1] = useState(firstName);
  const [lastName1, setLastName1] = useState(lastName);
  const [about1, setAbout1] = useState(about);
  const [email1, setEmail1] = useState(email);
  const [phone1, setPhone1] = useState(phone);
  const [userRole, setUserRole] = useState(role);

  const [profileTitle1, setProfileTitle1] = useState(profileTitle);
  const [profileImg1, setProfileImg1] = useState(profileImg);
  const [profileImg2, setProfileImg2] = useState(profileImg);
  const [resume1, setResume1] = useState(resume);
  const [newResumeUploaded, setNewResumeUploaded] = useState(false);
  const [zoom1, setZoom1] = useState(zoom);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImage1, setCroppedImage1] = useState(null);
  const [croppedAreaPixels1, setCroppedAreaPixels1] =
    useState(croppedAreaPixels);
  const [croppedArea1, setCroppedArea1] = useState(croppedArea);
  const [isAvatar, setIsAvatar] = useState(defaultAvatar);
  const [defaultProfileAvatar, setDefaultProfileAvatar] =
    useState(profileAvatar);
  const [imagePublicId1, setImagePublicId1] = useState(imagePublicId);
  const [resumePublicId1, setResumePublicId1] = useState(resumePublicId);
  const [sliderValue, setSliderValue] = useState(1);
  const [newImageUploaded, setNewImageUploaded] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);
  const [resumeChanged, setResumeChanged] = useState(false);
  const [resumeFormat1, setResumeFormat1] = useState(resumeFormat);
  const [linkedinURL1, setLinkedinURL1] = useState(linkedinURL);
  const [githubURL1, setGithubURL1] = useState(githubURL);
  const [portfolioURL1, setPortfolioURL1] = useState(portfolioURL);

  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openResumePreview, setOpenResumePreview] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [isNewImg, setIsNewImg] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [salary, setSalary] = useState("");
  const [payType, setPayType] = useState("");
  const [checked, setChecked] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    const x =
      userRole === "candidate"
        ? (setSalary(salaryDetail.salary),
          setPayType(salaryDetail.payType),
          setChecked(salaryDetail.checked))
        : (setSalary(salaryDetail.salary), setPayType(salaryDetail.payType));
  }, []);
  // store the regex of phone number patterns of all countries
  const phones = getPhonesRegex();

  //handle close confirmation dialog
  const onCloseDialog = () => {
    setFirstName1(firstName);
    setLastName1(lastName);
    setAbout1(about);
    setEmail1(email);
    setPhone1(phone);

    setProfileTitle1(profileTitle);
    setProfileImg1(profileImg);
    setResume1(resume);
    setLinkedinURL1(linkedinURL);
    setGithubURL1(githubURL);
    setPortfolioURL1(portfolioURL);

    const y =
      userRole === "candidate"
        ? (setSalary(salaryDetail.salary),
          setPayType(salaryDetail.payType, setChecked(salaryDetail.checked)))
        : (setSalary(salaryDetail.salary), setPayType(salaryDetail.payType));

    setErrors({
      image: "",
      imageSize: "",
      imageType: "",
      resume: "",
      resumeSize: "",
      resumeType: "",
      firstName: "",
      lastName: "",
      profileTitle: "",
      about: "",
      email: "",
      phone: "",
      salaryRange: "",
      salaryDetail: "",
    });
    setOpenCloseDialog(false);
    setopenMainCardEdit(false);
    setNewResumeUploaded(false);
    setResumeChanged(false);
  };

  // handle cancel button press of confirmation dialog
  const onCloseCancel = () => {
    setOpenCloseDialog(false);
    setopenMainCardEdit(true);
  };

  const checkHandler = () => {
    setChecked((prevalue) => !prevalue);

    if (!checked) {
      setErrors({ salaryRange: "" });
      setMinSalary("0");
      setMaxSalary("0");
      setSalary("0");
    }
  };

  // handle close of main dialog
  const onCloseMainDialog = () => {
    setOpenCloseDialog(true);
    setopenMainCardEdit(false);
    setResumeChanged(false);
    setImageChanged(false);
  };

  // handle open of resume preview
  const handleOpenResumePreview = () => {
    setOpenResumePreview(true);
  };

  // handle close of resume preview
  const handleCloseResumePreview = () => {
    setOpenResumePreview(false);
  };

  // called everytime the image is cropped
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels1(croppedAreaPixels);
    setCroppedArea1(croppedArea);
  }, []);

  // error handling
  const [errors, setErrors] = useState({
    imageSize: "",
    imageType: "",
    resume: "",
    resumeSize: "",
    resumeType: "",
    firstName: "",
    lastName: "",
    profileTitle: "",
    about: "",
    email: "",
    phone: "",
    salaryRange: "",
    salaryDetail: "",
  });

  //useRef
  const imageRef = useRef();
  const resumeRef = useRef();

  const firstNameHandler = (e) => {
    setFirstName1(e.target.value);
    setErrors({
      firstName: "",
    });
  };

  const lastNameHandler = (e) => {
    setLastName1(e.target.value);
    setErrors({
      lastName: "",
    });
  };

  const aboutHandler = (e) => {
    setAbout1(e.target.value);
    setErrors({
      about: "",
    });
  };

  const profileTitleHandler = (e) => {
    setProfileTitle1(e.target.value);
    setErrors({
      profileTitle: "",
    });
  };

  //handle salary for screener
  const handleSalary = (e) => {
    const limit = 7;
    setSalary(e.target.value.slice(0, limit));
    setErrors({
      salaryDetail: "",
    });
  };

  const payTypeHandler = (e) => {
    setPayType(e.target.value);
    setErrors({
      salaryDetail: "",
    });
  };

  // console.log(imagePublicId1);
  // handle save button press
  const onSubmit = async (e) => {
    e.preventDefault();

    if (firstName1 == "")
      return setErrors({ firstName: "First name is required!" });

    if (lastName1 == "")
      return setErrors({ lastName: "Last name is required!" });

    if (profileTitle1 == "")
      return setErrors({ profileTitle: "Profile title is required!" });

    if (about1 == "") return setErrors({ about: "About is required!" });

    if (email1 == "") return setErrors({ email: "Email is required!" });

    if (phone1 == "") return setErrors({ phone: "Phone is required!" });
    else if (!phoneIsValid) {
      return setErrors({ phone: "Invalid Phone Number" });
    }

    if (userRole === "candidate") {
      if (checked === false) {
        if (salary == "" || salary == "0") {
          return setErrors({
            salaryDetail: "Salary should be greater than 0!",
          });
        }
        if (payType === "") {
          return setErrors({
            salaryDetail: "Pay Type is required!",
          });
        }
      }
    } else {
      if (salary === "" || salary == "0") {
        return setErrors({
          salaryDetail: "Salary should be greater than zero!",
        });
      }
      if (payType === "") {
        return setErrors({
          salaryDetail: "Pay Type is required!",
        });
      }
    }

    if (!resume1) return setErrors({ resume: "Resume is required!" });

    if (role === "candidate" && profileImg2 === profileAvatar) {
      return setErrors({ image: "Profile Image is required!" });
    }

    if (!resume) profileCompletion += 10;
    setDisableButton(true);
    const formData = new FormData();
    formData.append("id", id);
    formData.append("firstName", firstName1);
    formData.append("lastName", lastName1);
    formData.append("profileTitle", profileTitle1);
    formData.append("about", about1);
    formData.append("email", email1);
    formData.append("phone", phone1);
    {
      const salaryCondition =
        userRole === "candidate"
          ? checked
            ? (formData.append("salary", "As per industry standards"),
              formData.append("payType", ""),
              formData.append("checked", checked))
            : (formData.append("salary", salary),
              formData.append("payType", payType),
              formData.append("checked", checked))
          : (formData.append("salary", salary),
            formData.append("payType", payType));
    }

    formData.append("defaultAvatar", isAvatar);
    formData.append("profileImage", profileImg2);
    formData.append("imagePublicId", imagePublicId);
    formData.append("croppedImage", croppedImage1);
    formData.append("croppedImagePublicId", croppedImagePublicId);
    formData.append("croppedAreaPixels", JSON.stringify(croppedAreaPixels1));
    formData.append("croppedArea", JSON.stringify(croppedArea1));
    formData.append("zoom", Number(zoom1));
    formData.append("resume", resume1);
    formData.append("resumeFormat", resumeFormat1);
    formData.append("resumePublicId", resumePublicId);
    formData.append("profileCompletion", profileCompletion);
    formData.append("interacted", interaction);
    formData.append("resumeChanged", resumeChanged);
    formData.append("imageChanged", imageChanged);
    formData.append("linkedinURL", linkedinURL1);
    formData.append("githubURL", githubURL1);
    formData.append("portfolioURL", portfolioURL1);

    axios
      .post(`/api/${userRole}/profile-main/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res);
        setopenMainCardEdit(false);
        setOpenSnackbar(true);
        setDisableButton(false);
        setImageChanged(false);
        setResumeChanged(false);
      })
      .catch((err) => {
        console.log(err.message);
        setOpenErrorSnackbar(true);
        setDisableButton(false);
        setImageChanged(false);
        setResumeChanged(false);
      });

    setIsNewImg(false);
  };

  // used to get url of newly uploaded image
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  // handle profile image upload
  const handleProfileImageUpload = async (e) => {
    const fileCheck = e.target.files[0];
    const maxSize = 1024 * 1024; //1MB
    if (fileCheck.size > maxSize) {
      setErrors({ imageSize: "Please upload image less than 1MB" });
      imageRef.current.value = null;
    } else if (!fileCheck.type.startsWith("image")) {
      setErrors({ imageType: "Please upload an image file" });
      e.target.files = null;
    } else {
      let imageDataUrl = await readFile(fileCheck);
      setProfileImg1(imageDataUrl);

      setProfileImg2(fileCheck);
      setNewImageUploaded(true);
      setImageChanged(true);
      setErrors({ imageSize: "", imageType: "" });
      setIsAvatar(false);
      setIsNewImg(true);
      setInteraction(false);
      setZoom1(1);
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // used to get cropped image
  const showCroppedImage = useCallback(async () => {
    let profileImgUrl = profileImg1;
    console.log("Cropped Image",croppedAreaPixels1);
    try {
      const croppedImage = await getCroppedImg(
        profileImgUrl,
        croppedAreaPixels1,
        0 //rotation
      );
      let file = dataURLtoFile(croppedImage, "cropped-image");
      setCroppedImage1(file);
    } catch (e) {
      console.log(e);
    }
  }, [croppedAreaPixels1, profileImg1]);

  // handle profile image remove
  const handleRemoveProfileImage = (e) => {
    setProfileImg1(profileAvatar);
    setProfileImg2(profileAvatar);
    setZoom1(1);
    setErrors({ imageSize: "", imageType: "" });
    setIsAvatar(true);
    setNewImageUploaded(false);
    setImageChanged(false);
    imageRef.current.value = null;
    setOpenAlertDialog(false);
  };

  // handle remove image alert dialog
  const handleRemoveImageDialog = () => {
    setOpenAlertDialog(true);
  };
  const onCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  // handle resume upload
  const handleResumeUpload = (e) => {
    const resumeCheck = e.target.files[0];
    const fileTypes = ["doc", "docx", "pdf"];
    let fileExtension = resumeCheck?.name.split(".")[1];
    console.log(fileExtension);
    setErrors({
      resume: "",
    });
    const maxSize = 1024 * 1024 * 1; //1MB

    //resumeCheck.size > maxSize ,
    if (resumeCheck.size > maxSize) {
      setErrors({ resumeSize: "Please upload a file less than 1MB" });
      resumeRef.current.value = null;
    }
    // else if (!resumeCheck.type.includes("pdf")) {
    //   setErrors({ resumeType: "Please upload a pdf file" });
    // }
    else if (!fileTypes.includes(fileExtension)) {
      setErrors({ resumeType: "Please upload a pdf/doc file" });
    } else {
      setNewResumeUploaded(true);
      setResumeChanged(true);
      setResumeFormat1(fileExtension);
      setResume1(e.target.files[0]);
      setErrors({ resumeType: "", resumeSize: "" });
    }
  };

  // handle resume remove
  const handleRemoveResume = () => {
    setResume1(null);
    resumeRef.current.value = null;
    setNewResumeUploaded(false);
    setResumeChanged(false);
  };

  // disable the right and left arrow keys for the slider
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  return (
    <div>
      <div className="main-dialog">
        <Dialog open={open} onClose={onCloseMainDialog} maxWidth="md">
          <DialogContent>
            <Tooltip title="Close">
              <CloseIcon
                className="close-icon"
                onClick={onCloseMainDialog}
                sx={{ cursor: "pointer", padding: 0, margin: "1px" }}
              />
            </Tooltip>
            <Grid container spacing={0} justifyContent="center">
              {/* Left Grid item */}
              <Grid item xs={12} sm={12} md={4} lg={4.3} className="left-grid">
                <div className="left-container">
                  <div className="image-container">
                    <div className="crop-container">
                      <Cropper
                        className="cropper"
                        image={profileImg1}
                        crop={crop}
                        zoom={zoom1}
                        initialCroppedAreaPixels={
                          isNewImg || isAvatar ? null : croppedAreaPixels1
                        }
                        aspect={16 / 16}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onCropSizeChange={showCroppedImage}
                        onInteractionStart={() => {
                          setInteraction(true);
                          showCroppedImage();
                        }}
                        onInteractionEnd={showCroppedImage}
                        onZoomChange={isAvatar ? null : setZoom1}
                        restrictPosition={true}
                        onMediaLoaded={showCroppedImage}
                        objectFit="auto-cover"
                      />
                    </div>
                    <div>
                      <label className="image-upload">
                        <input
                          type="file"
                          onChange={handleProfileImageUpload}
                          accept=".jpg, .png, .jpeg"
                          ref={imageRef}
                        />
                        <span>Upload</span>
                      </label>
                      <button
                        className="image-remove"
                        onClick={handleRemoveImageDialog}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  <div>
                    <span className="text-danger">
                      {errors.imageSize} {errors.imageType} {errors.image}
                    </span>
                  </div>
                  <div className="name-designation">
                    <h3>
                      {firstName} {lastName}
                    </h3>
                    <p>{profileTitle}</p>
                  </div>
                  <div className="upload-resume">
                    <p>Upload Resume*</p>
                    <label className="resume-file-upload">
                      <input
                        type="file"
                        onChange={handleResumeUpload}
                        accept=".pdf, .doc, .docx"
                        ref={resumeRef}
                      />

                      <UploadIcon
                        sx={{ marginRight: "5px", color: "#F06161" }}
                      />
                      <span>Attach</span>
                    </label>
                    {resume1 !== null ? (
                      <div className="resume-name-preview">
                        <div style={{ textAlign: "center" }}>
                          {`${firstName} ${lastName}`}-resume
                        </div>
                        <div style={{ textAlign: "center", marginLeft: "5px" }}>
                          <Tooltip title="Remove">
                            <CloseIcon
                              className="close-icon2"
                              fontSize="small"
                              onClick={(e) => handleRemoveResume(e)}
                            />
                          </Tooltip>
                        </div>
                        <div
                          className="eye-icon"
                          style={{ textAlign: "center", padding: "0px 5px" }}
                        >
                          {resumeFormat1 == "pdf" ? (
                            <Tooltip title="View">
                              <RemoveRedEyeOutlinedIcon
                                fontSize="small"
                                onClick={handleOpenResumePreview}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Download">
                              <a href={resume1} style={{ color: "#2c3e50" }}>
                                <DownloadIcon
                                  fontSize="small"
                                  // onClick={handleOpenResumePreview}
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </Tooltip>
                          )}
                        </div>
                        <div
                          className="download-icon"
                          style={{ textAlign: "center", padding: "0px 5px" }}
                        >
                          <a
                            href={
                              newResumeUploaded
                                ? URL.createObjectURL(resume1)
                                : resume1
                                    .slice(0, 49)
                                    .concat("/fl_attachment")
                                    .concat(resume1.slice(49))
                            }
                            download
                          >
                            <DownloadIcon
                              fontSize="small"
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      <p className="text-danger">
                        {errors.resumeSize} {errors.resumeType} {errors.resume}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="slider-container">
                  <Box sx={{ height: 100 }}>
                    <Slider
                      sx={{
                        '& input[type="range"]': {
                          WebkitAppearance: "slider-vertical",
                        },
                      }}
                      step={0.1}
                      type="range"
                      min={1}
                      max={3}
                      size="small"
                      value={zoom1}
                      orientation="vertical"
                      defaultValue={sliderValue}
                      aria-label="Zoom In"
                      valueLabelDisplay="auto"
                      onKeyDown={preventHorizontalKeyboardNavigation}
                      onChange={
                        isAvatar
                          ? null
                          : (e, zoom) => {
                              setZoom1(zoom);
                              setInteraction(true);
                              // showCroppedImage();
                            }
                      }
                    />
                  </Box>
                  <div>
                    <p>Zoom in</p>
                  </div>
                </div>
              </Grid>
              <Box>
                <Divider orientation="vertical" />
              </Box>

              {/* Right Grid item */}
              <Grid item xs={12} sm={12} md={7} lg={7.5} className="right-grid">
                <div>
                  <form
                    noValidate
                    className="right-grid-form"
                    // onSubmit={onSubmit}
                  >
                    <div className="input-container">
                      <div className="input-field-modal">
                        <label className="date-label">First Name*</label>
                        <input
                          type="text"
                          value={firstName1}
                          onChange={firstNameHandler}
                          placeholder="First Name"
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.firstName}</span>
                      </div>
                      <div className="input-field-modal">
                        <label className="date-label">Last Name*</label>
                        <input
                          className="position"
                          type="text"
                          value={lastName1}
                          onChange={lastNameHandler}
                          placeholder="Last Name"
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.lastName}</span>
                      </div>

                      <div className="input-field-modal">
                        <label className="date-label">Profile Title*</label>
                        <input
                          className="profileTitle"
                          type="text"
                          value={profileTitle1}
                          placeholder="Ex: Software Developer"
                          onChange={profileTitleHandler}
                        />
                      </div>
                      <div>
                        <span className="text-danger">
                          {errors.profileTitle}
                        </span>
                      </div>
                      <div className="input-field-modal textarea__wrapper">
                        <label className="date-label">
                          About* (Maximum of 500 characters)
                        </label>
                        {/* <TextareaCounter
                          className="about"
                          placeholder="Tell something about yourself"
                          countLimit={500}
                          initialValue={about1}
                          minrows={5}
                          maxrows={6}
                          maxLength={500}
                          value={about1}
                          resize="none"
                          onChange={aboutHandler}
                        /> */}

                        <TextareaCounter
                          className="about"
                          maxLength={500}
                          value={about1}
                          placeholder="Tell something about yourself"
                          onChange={aboutHandler}
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.about}</span>
                      </div>
                      <div className="input-field-modal">
                        <label className="date-label">Email*</label>
                        <input
                          className="email"
                          type="email"
                          placeholder="Email Id*"
                          value={email1}
                          disabled={true}
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.email}</span>
                      </div>
                      <div className="input-field-modal">
                        <label className="date-label">Phone*</label>
                        <PhoneInput
                          id="phone"
                          placeholder="Contact No*"
                          name="phone"
                          // ref={phoneRef}
                          className="phone"
                          country={"us"}
                          value={phone}
                          onChange={(phone, country) => {
                            setPhone1(phone);
                            var regex =
                              phones[country.countryCode.toUpperCase()];
                            if (regex != undefined) {
                              if (!regex.test(phone)) {
                                setPhoneIsValid(false);
                                return setErrors({
                                  phone: "Invalid Phone Number",
                                });
                              } else {
                                setPhoneIsValid(true);
                                return setErrors({ phone: "" });
                              }
                            }
                          }}
                        />
                        <div>
                          <span className="text-danger">{errors.phone}</span>
                        </div>
                      </div>
                      <div className="experience-edit-form">
                        <div className="date-dropdown-picker">
                          <div className="input-field">
                            <label className="date-label">Expected Pay*</label>
                            <input
                              className="max-salary"
                              value={salary}
                              type="number"
                              placeholder="Min Salary"
                              onChange={handleSalary}
                              disabled={checked}
                            />
                          </div>
                          <div className="input-field">
                            <label className="date-label">Pay Type*</label>
                            <select
                              value={payType || "default"}
                              onChange={payTypeHandler}
                              disabled={checked}
                            >
                              <option value="default" selected disabled hidden>
                                Pay Type
                              </option>
                              <option value="Hour">Hourly</option>
                              <option value="Week">Weekly</option>
                              <option value="Month">Monthly</option>
                              <option value="Annum">Per Annum</option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <span className="text-danger">
                            {errors.salaryDetail}
                          </span>
                        </div>
                      </div>

                      {userRole === "candidate" && (
                        <div className="input-field">
                          <FormGroup className="input-field-checkbox">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  value={checked}
                                  onClick={checkHandler}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#f06161",
                                    },
                                  }}
                                />
                              }
                              label="As per industry standards"
                            />
                          </FormGroup>
                        </div>
                      )}

                      <div
                        className="input-field-modal"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="links-container">
                          <LinkedInIcon
                            fontSize="small"
                            style={{ color: "#f06161" }}
                          />
                          <input
                            className="email"
                            type="text"
                            placeholder="Enter your Linkedin Profile URL"
                            value={linkedinURL1}
                            onChange={(e) => setLinkedinURL1(e.target.value)}
                          />
                        </div>
                        <div className="links-container">
                          <GitHubIcon
                            fontSize="small"
                            style={{ color: "#f06161" }}
                          />
                          <input
                            className="email"
                            type="text"
                            placeholder="Enter your Github profile URL"
                            value={githubURL1}
                            onChange={(e) => setGithubURL1(e.target.value)}
                          />
                        </div>
                        <div className="links-container">
                          <PublicIcon
                            fontSize="small"
                            style={{ color: "#f06161" }}
                          />
                          <input
                            className="email"
                            type="text"
                            placeholder="Enter your portfolio URL"
                            value={portfolioURL1}
                            onChange={(e) => setPortfolioURL1(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="button-container">
                        <button
                          type="submit"
                          disabled={disableButton}
                          onClick={onSubmit}
                          className="btn-style filled-btn-animation save-button"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>

      {/* Alert Dialog  */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAlertDialog}
        onClose={onCloseAlertDialog}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              item
              xs={11.5}
              sm={11.5}
              md={12}
              lg={12}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure?</h5>
                <p>Do you want remove your profile picture?</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="alert-buttons-container">
          <button
            className="warning-btn-style filled-btn filled-btn-animation"
            onClick={onCloseAlertDialog}
          >
            {" "}
            Cancel
          </button>
          <button
            className="warning-btn-style outlined-btn outlined-btn-animation"
            onClick={handleRemoveProfileImage}
          >
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      {/* Resume Preview Dialog */}
      <Dialog open={openResumePreview} onClose={handleCloseResumePreview}>
        <DialogContent>
          <Tooltip title="Close">
            <CloseIcon
              className="close-icon1"
              onClick={() => {
                setOpenResumePreview(false);
              }}
              sx={{
                cursor: "pointer",
                padding: 0,
                margin: "1px",
              }}
            />
          </Tooltip>
          <Grid
            item
            xs={11.5}
            sm={11.5}
            md={11.5}
            lg={11.5}
            sx={{
              padding: "30px 10px 10px 10px",
              width: "600px",
              height: "650px",
            }}
          >
            <object
              className="pdf-preview-component"
              style={{ width: "550px", height: "550px", margin: "15px" }}
              data={newResumeUploaded ? URL.createObjectURL(resume1) : resume1}
              type="application/pdf"
            >
              <iframe
                src={newResumeUploaded ? URL.createObjectURL(resume1) : resume1}
              ></iframe>
            </object>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Close confimation dialog */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openCloseDialog}
        onClose={onCloseMainDialog}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              item
              xs={11.5}
              sm={11.5}
              md={11.5}
              lg={11.5}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure?</h5>
                <p>Do you want to go back?</p>
                <p>All your current changes will be lost!</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="alert-buttons-container">
          <button
            className="warning-btn-style filled-btn filled-btn-animation"
            onClick={onCloseCancel}
          >
            {" "}
            Cancel
          </button>
          <button
            className="warning-btn-style outlined-btn outlined-btn-animation"
            onClick={onCloseDialog}
          >
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      <SaveProfileSnackBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <ErrorMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
      />
    </div>
  );
};

export default MainCardEditModal;
