import React, { useState } from "react";
import Papa from "papaparse";
import {
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Snackbar,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UserSnackbar from "../../../../snackBars/UserSnackbar";
import "./AddCandidate.css";

// Email validation regex
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const AddCandidate = ({
  testId,
  testName,
  handleDialogClose,
  setMessage,
  setOpenSnackbar,
  setRefreshCandidates,
  fetchAddedCandidates,
}) => {

  const templateFileLink = "https://res.cloudinary.com/df2qsnyox/raw/upload/v1739370261/aptitude-invitation-template_tktoxo.csv";

  const [subject, setSubject] = useState(
    `INFOMATICS INDIA's Invitation to ${testName}`
  );
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [sendEmailNotification, setSendEmailNotification] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [fullNameError, setFullNameError] = useState(false);
  const [helperTextEmail, setHelperTextEmail] = useState("");
  const [helperTextFullName, setHelperTextFullName] = useState("");
  const [csvFile, setCsvFile] = useState(null); // Store the uploaded file

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Email validation
    if (!emailRegex.test(value)) {
      setEmailError(true);
      setHelperTextEmail("Please enter a valid email address");
    } else {
      setEmailError(false);
      setHelperTextEmail("");
    }
  };

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);

    // Full name validation (should not be empty)
    if (value.trim() === "") {
      setFullNameError(true);
      setHelperTextFullName("Full name cannot be empty");
    } else {
      setFullNameError(false);
      setHelperTextFullName("");
    }
  };

  const handleAddCandidate = () => {
    if (email && fullName && !emailError && !fullNameError) {
      setCandidates([...candidates, { email, fullName }]);
      setEmail("");
      setFullName("");
    } else {
      // Optionally, you can display a message to inform the user
      setMessage("Please enter valid email and full name before adding.");
      setOpenSnackbar(true); // Show snackbar for feedback
    }
  };

  const handleDeleteCandidate = (index) => {
    const updatedCandidates = candidates.filter((_, i) => i !== index);
    setCandidates(updatedCandidates);
  };

  const handleEditCandidate = (index) => {
    const candidate = candidates[index];
    setEmail(candidate.email);
    setFullName(candidate.fullName);
    handleDeleteCandidate(index);
  };

  // const handleUploadCSV = async (event) => {
  //   try {
  //     const file = event.target.files[0];

  //     if (!file) {
  //       setMessage("No file selected.");
  //       setOpenSnackbar(true);
  //       return;
  //     }

  //     // Parse the CSV file
  //     Papa.parse(file, {
  //       header: true, // Treat the first row as headers
  //       skipEmptyLines: true,
  //       complete: async (results) => {
  //         const candidates = results.data;

  //         if (candidates.length === 0) {
  //           setMessage("The CSV file is empty.");
  //           setOpenSnackbar(true);
  //           return;
  //         }

  //         try {
  //           // Map through candidates and send invitations
  //           const invitations = candidates.map(async (candidate) => {
  //             const { fullName, email } = candidate;

  //             if (fullName && email) {
  //               try {
  //                 const response = await axios.post(
  //                   `/api/admin/skill-assessment/test-management/${testId}/add-candidate-to-test`,
  //                   { fullName, email, subject }
  //                 );
  //                 console.log(
  //                   "Invitation sent to:",
  //                   fullName,
  //                   email,
  //                   response
  //                 );
  //               } catch (error) {
  //                 console.error(
  //                   `Error sending invitation to ${fullName}:`,
  //                   error
  //                 );
  //               }
  //             } else {
  //               console.warn("Missing fullName or email for candidate:", candidate);
  //             }
  //           });

  //           // Wait for all invitations to complete
  //           await Promise.all(invitations);
  //           setMessage("All invitations sent successfully!");
  //           setOpenSnackbar(true);
  //         } catch (error) {
  //           console.error("Error sending invitations:", error);
  //           setMessage(`Failed to send invitations: ${error.message}`);
  //           setOpenSnackbar(true);
  //         }
  //       },
  //       error: (error) => {
  //         console.error("Error parsing CSV:", error);
  //         setMessage(`Failed to parse CSV: ${error.message}`);
  //         setOpenSnackbar(true);
  //       },
  //     });
  //   } catch (error) {
  //     console.error("Error uploading CSV:", error);
  //     setMessage(`Error uploading CSV: ${error.message}`);
  //     setOpenSnackbar(true);
  //   }
  // };



  // const handleSendInvitation = async () => {
  //   try {
  //     // handleDialogClose();

  //     // If no candidates were added using the + icon but the input fields are filled
  //     if (candidates.length === 0 && email && fullName) {
  //       const invitationResponse = await axios.post(
  //         `/api/admin/skill-assessment/test-management/${testId}/add-candidate-to-test`,
  //         { fullName, email, subject }
  //       );
  //       setMessage(invitationResponse.data.message);
  //       console.log("Invitation sent to:", fullName, email, invitationResponse);
  //     }

  //     // If candidates were added using the + icon, send to all of them
  //     else if (candidates.length > 0) {
  //       const invitations = candidates.map(async (candidate) => {
  //         const { fullName, email } = candidate;
  //         try {
  //           const invitationResponse = await axios.post(
  //             `/api/admin/skill-assessment/test-management/${testId}/add-candidate-to-test`,
  //             { fullName, email, subject }
  //           );
  //           console.log(
  //             "Invitation sent to:",
  //             fullName,
  //             email,
  //             invitationResponse
  //           );
  //         } catch (error) {
  //           console.error(`Error sending invitation to ${fullName}:`, error);
  //           setMessage(
  //             `Error sending to ${candidate.fullName}: ${error.message}`
  //           );
  //         }
  //       });

  //       // Wait for all invitations to be sent
  //       await Promise.all(invitations);
  //       console.log("All invitations sent!");
  //       setMessage("All invitations sent successfully!");
  //     }

  //     // Close the dialog
  //     handleDialogClose();

  //     // Show snackbar after the dialog has closed
  //     setOpenSnackbar(true);
  //   } catch (error) {
  //     console.error("Error sending invitations:", error);
  //     setMessage(`Failed to send invitations: ${error.message}`);
  //     setOpenSnackbar(true);
  //   }
  // };


  const handleUploadCSV = (event) => {
    const file = event.target.files[0];

    if (file) {
      setCsvFile(file); // Store file to display its name

      // Parse the CSV file
      Papa.parse(file, {
        header: true, // Treat the first row as headers
        skipEmptyLines: true,
        complete: (results) => {
          const parsedCandidates = results.data;

          if (parsedCandidates.length === 0) {
            setMessage("The CSV file is empty.");
            setOpenSnackbar(true);
            return;
          }

          setCandidates(parsedCandidates); // Save parsed candidates
          setMessage("CSV file uploaded successfully!");
          // setOpenSnackbar(true);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
          setMessage(`Failed to parse CSV: ${error.message}`);
          setOpenSnackbar(true);
        },
      });
    }
  };

  const handleSendInvitation = async () => {
    try {
      // If no candidates were added using the + icon but the input fields are filled
      if (candidates.length === 0 && email && fullName) {
        const invitationResponse = await axios.post(
          `/api/admin/skill-assessment/test-management/${testId}/add-candidate-to-test`,
          { fullName, email, subject }
        );
        setMessage(invitationResponse.data.message);
        console.log("Invitation sent to:", fullName, email, invitationResponse);
      }

      // If candidates were added manually or via CSV file
      else if (candidates.length > 0) {
        const invitations = candidates.map(async (candidate) => {
          const { fullName, email } = candidate;

          // Ensure both fullName and email exist for each candidate
          if (fullName && email) {
            try {
              const invitationResponse = await axios.post(
                `/api/admin/skill-assessment/test-management/${testId}/add-candidate-to-test`,
                { fullName, email, subject }
              );
              console.log(
                "Invitation sent to:",
                fullName,
                email,
                invitationResponse
              );
            } catch (error) {
              console.error(`Error sending invitation to ${fullName}:`, error);
              setMessage(
                `Error sending to ${candidate.fullName}: ${error.message}`
              );
            }
          } else {
            console.warn(
              "Candidate skipped due to missing fullName or email:",
              candidate
            );
          }
        });

        // Wait for all invitations to complete
        await Promise.all(invitations);
        console.log("All invitations sent!");
        setMessage("All invitations sent successfully!");
      }

      // Close the dialog
      handleDialogClose();
      fetchAddedCandidates();

      // Show snackbar after the dialog has closed
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error sending invitations:", error);
      setMessage(`Failed to send invitations: ${error.message}`);
      setOpenSnackbar(true);
    }
  };


  const handleCancel = () => {
    navigate("/admin/skill-assessment/test-management");
  };

  return (
    <>
      <Box
        sx={{ p: 4, boxShadow: 3, borderRadius: 2, backgroundColor: "#fff" }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{ mb: 3, fontWeight: "bold", textAlign: "center" }}
        >
          Invite Candidates for {testName}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Email Subject"
              fullWidth
              required
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              variant="outlined"
              // sx={{ mb: 2 }}
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={5}>
            <TextField
              label="Email Address"
              fullWidth
              required
              value={email}
              onChange={handleEmailChange} // Use validation handler
              error={emailError} // Show error state
              helperText={helperTextEmail} // Display helper text for error
              variant="outlined"
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              label="Full Name"
              fullWidth
              required
              value={fullName}
              onChange={handleFullNameChange} // Use validation handler
              error={fullNameError} // Show error state
              helperText={helperTextFullName} // Display helper text for error
              variant="outlined"
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&:hover fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              color="primary"
              onClick={handleAddCandidate}
              sx={{
                backgroundColor: "#F06161",
                color: "#fff",
                "&:hover": { backgroundColor: "#d04b4b" },
              }}
            >
              <AddIcon />
            </IconButton>
          </Grid>

          {candidates.length > 0 && (
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                sx={{ boxShadow: 3, borderRadius: 2 }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidates.map((candidate, index) => (
                      <TableRow key={index}>
                        <TableCell>{candidate.email}</TableCell>
                        <TableCell>{candidate.fullName}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            onClick={() => handleEditCandidate(index)}
                            sx={{
                              color: "#F06161",
                              "&:hover": {
                                color: "#d04b4b", // Change this to a darker shade or different color on hover
                              },
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteCandidate(index)}
                            sx={{ color: "#f06161" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendEmailNotification}
                  onChange={(e) => setSendEmailNotification(e.target.checked)}
                  sx={{ "&.Mui-checked": { color: "#F06161" } }}
                />
              }
              label="Send Email Notification"
            />
          </Grid>

          <Grid item xs={12}>
            <input
              type="file"
              accept=".csv"
              onChange={handleUploadCSV}
              style={{ display: "none" }}
              id="csv-upload"
            />
            <label htmlFor="csv-upload">
              <Button
                variant="outlined"
                component="span"
                sx={{
                  marginRight: 2,
                  display: "flex",
                  alignItems: "center",
                  color: "#f06161", // Match text color with your primary color
                  borderColor: "#f06161", // Set border color
                  "&:hover": {
                    backgroundColor: "rgba(240, 97, 97, 0.1)", // Slight red tint on hover
                    borderColor: "#d05353", // Darker border color on hover
                  },
                }}
              >
                <CloudUploadIcon sx={{ marginRight: 1 }} />
                Upload CSV
              </Button>
            </label>

            {/* Display the uploaded file name */}
            {csvFile && (
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                Uploaded File: {csvFile.name}
              </Typography>
            )}

            <Typography variant="body2">
              <a
                className="download-template-link"
                href={templateFileLink}
                download
              >
                Download Template
                <FileDownloadIcon fontSize="small" />
              </a>
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "right" }}>
            <Button
              variant="outlined" sx={{ color: '#f06161', borderColor: '#f06161', '&:hover': { borderColor: '#f06161' }, marginRight: 2 }}
              onClick={handleDialogClose}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSendInvitation}
              disabled={emailError || fullNameError || (!email && !fullName && candidates.length === 0)}
              className="custom-button"
            >
              Send Invitation
            </Button>

          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AddCandidate;
