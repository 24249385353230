import React from "react";

// External imports
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

// CSS imports
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../auth/LoginComponent.css";

// Modal to show email verification message
const SocialRegisterModal = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <img
                width="30"
                height="30"
                src={props.icon === 'google' ? "https://cdn-icons-png.flaticon.com/512/2991/2991148.png" : undefined}
                alt="email-img"
                style={{ margin: "20px auto -6px auto" }}
            />
            {/* Modal body */}
            <Modal.Body>
                <h6 style={{ textAlign: "center" }}>Sign Up as*</h6>

                {/* Radio buttons */}
                <div className="login-radio-container">
                    <FormControl className="login-radio-group" style={{ justifyContent: 'center' }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={props.role}
                            onChange={(e) => props.setRole(e.target.value)}
                        >
                            <FormControlLabel
                                value="candidate"
                                control={
                                    <Radio
                                        size="small"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                            },
                                        }}
                                        required
                                    />
                                }
                                label="Job Seeker"
                            />
                            <FormControlLabel
                                value="screener"
                                control={
                                    <Radio
                                        size="small"
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                fontSize: 18,
                                            },
                                        }}
                                        required
                                    />
                                }
                                label="Screener"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div style={{ textAlign: "center", marginTop: "15px" }}>
                    <Button
                        onClick={() => props.socialMediaHandler(props.icon)}
                        style={{ fontSize: 'smaller', borderRadius: "20px", textAlign: "center" }}
                        className="btn-login"
                    >
                        Submit
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SocialRegisterModal;
