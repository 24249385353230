import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const colorMap = {
  "All Users": "#ff8a8a",
  "Accepted Users": "#6cc1d6",
  "Pending Users": "#555555",
};

const CustomPieChart = ({ invitations }) => {
  console.log("invitations", invitations);
  const data = invitations.map((invitation) => ({
    name: invitation.type,
    value: invitation.value,
    color: colorMap[invitation.type] || "#cccccc", // Default color if not in colorMap
  }));

  // Custom function to render labels inside each segment
  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={13}
      >
        {value}
      </text>
    );
  };

  return invitations[0]?.value > 0 ? (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <PieChart width={250} height={250}>
          <Pie
            data={data}
            cx="60%"
            cy="60%"
            dataKey="value"
            startAngle={180}
            endAngle={90}
            label={renderCustomLabel}
            labelLine={false}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            iconType="square"
            formatter={(value) => (
              <span style={{ color: "#333" }}>{value}</span>
            )}
          />
        </PieChart>
      </ResponsiveContainer>

      {/* Display types below the chart */}
      <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
        {invitations.map((invitation, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", marginRight: 20 }}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundColor: colorMap[invitation.type],
                marginRight: "8px",
                borderRadius: "3px",
              }}
            />
            <span style={{ fontSize: "14px", color: "#333" }}>
              {invitation.type}
            </span>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div style={{ textAlign: "center", padding: "20px" }}>No Invitations</div>
  );
};

export default CustomPieChart;
