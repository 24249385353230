import React from "react";

// Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import avatar1 from "../../images/avatar1.jpg";
import avatar2 from "../../images/avatar2.jpg";
import avatar3 from "../../images/avatar3.jpg";

const Testimonial = () => {
  return (
    <section className="section" id="testimonial">
      <div className="container">
        <div className="container-heading">
          <h6 className="xs-font mb-0">See what our clients have to say</h6>
          <h3 className="section-title">Testimonials</h3>
        </div>
        <div id="owl-testmonial" className="owl-carousel owl-theme mt-4">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval={4000}
            stopOnHover={true}
            swipeable={true}
            showStatus={false}
          >
            <div className="item">
              <div className="textmonial-item">
                <img src={avatar1} className="avatar" alt="person-1" />
                <div className="des">
                  <p>
                    The portal is very user friendly in terms of searching
                    resumes and job postings. Also, they have a good database to
                    search resumes. As far as services are concerned it is
                    excellent! Their turnaround time for resolving any issue is
                    just a few minutes and that is really appreciable. Their
                    Business Development Team is always there to help at any
                    point of time. Thank you so much for all your effort.
                  </p>

                  <div className="line"></div>
                  <div className="lineinfo">
                    <h6 className="name">Emma Re</h6>
                    <h6 className="xs-font">Recruiter</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="textmonial-item">
                <img src={avatar2} className="avatar" alt="person-2" />
                <div className="des">
                  <p>
                    An excellent platform for job seekers to sign-up and get
                    offers from recruiting companies. The UI is very
                    user-friendly and the whole recruitment process was smooth.
                  </p>

                  <div className="line"></div>
                  <div className="lineinfo">
                    <h6 className="name">John Doe</h6>
                    <h6 className="xs-font">Full-Stack Developer</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="textmonial-item">
                <img src={avatar3} className="avatar" alt="person-3" />
                <div className="des">
                  <p>
                    I like the feature that I can source candidates based on
                    location and industry. It has various filters which are
                    helpful to source the right candidates. It shows candidates
                    who have updated their resumes recently. It is user-friendly
                    and allows keyword search.
                  </p>

                  <div className="line"></div>
                  <h6 className="name">Emily Roe</h6>
                  <h6 className="xs-font">Recruiter</h6>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="textmonial-item">
                <img src={avatar3} className="avatar" alt="person-4" />
                <div className="des">
                  <p>
                    I like the feature that I can source candidates based on
                    location and industry. It has various filters which are
                    helpful to source the right candidates. It shows candidates
                    who have updated their resumes recently. It is user-friendly
                    and allows keyword search.
                  </p>

                  <div className="line"></div>
                  <h6 className="name">Emily Roe</h6>
                  <h6 className="xs-font">Recruiter</h6>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  )
}

export default Testimonial
