import React from 'react'

// mui imports
import { Dialog, DialogContent, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

// icon imports
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";

const NotifyConfirmationDialog = ({ handleScheduleInterview, setNotifyDialog, notifyDialog, setOpenScheduleInterview }) => {

    //closeHandler for Dialog
    const closeHandler = () => {
        setNotifyDialog(false);
        setOpenScheduleInterview(true);
    };

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={notifyDialog}
                onClose={setNotifyDialog}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Tooltip title="Close">
                        <CloseIcon
                            className="close-icon"
                            onClick={closeHandler}
                            sx={{
                                cursor: "pointer",
                                padding: 0,
                                margin: "10px 12px 10px 0 !important",
                            }}
                        />
                    </Tooltip>
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5>Did you make any changes?</h5>
                                <p>If so, click on 'Notify' to send an email.</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container">
                    <button
                        className="warning-btn-style filled-btn filled-btn-animation"
                        onClick={(e) => {
                            setNotifyDialog(false);
                            handleScheduleInterview(e, false);
                        }}
                    >
                        {" "}
                        Cancel & Save
                    </button>
                    <button
                        className="warning-btn-style outlined-btn outlined-btn-animation"
                        onClick={(e) => handleScheduleInterview(e, true)}
                    >
                        {" "}
                        Notify
                    </button>
                </div>
            </Dialog>
        </div>
    )
}

export default NotifyConfirmationDialog