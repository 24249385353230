import React, { useState, useRef, useCallback, useEffect } from "react";
import axios from "axios";

//external library Imports
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";
import { Dialog } from "@mui/material";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Cropper from "react-easy-crop";
import PhoneInput from "react-phone-input-2";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import getCroppedImg from "./CropImage";
import { v4 as uuid } from "uuid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getPhonesRegex } from "../auth/PhoneValidation";

// CSS import
import "./MainCardEditModal.css";

import UserSnackbar from "../snackBars/UserSnackbar";

import { moduleList } from "../company admin/settings/ModuleList";

const AddRecruiterModal = ({
  id,
  companyId,
  openAddUserForm,
  setOpenAddUserForm,
  addUserType,
  hasInternalDbAccess
}) => {
  console.log("id from recruiter model",id)

  const compRefId = sessionStorage.getItem("companyId");
  const compId = sessionStorage.getItem("id")
  const role = sessionStorage.getItem("role")

  // array to store profile types
  const [profileTypes, setProfileTypes] = useState([]);
  const [recruiterRolePresent, setRecruiterRolePresent] = useState(false)



  //fetching profile type
  useEffect(() => {

    axios
    .get(`/api/admin/userRole/${id}`)
    .then((res) => {
      let tempArr = [];
      if (res.data.length > 0) {
        tempArr = res.data[0].profileType;
        console.log("tempArr",tempArr);
  
        let recruiterPresent = tempArr.find(
          (item) => item.roleName.toLowerCase() === "recruiter"
        );
        if (recruiterPresent !== undefined) setRecruiterRolePresent(true);
  
        tempArr = tempArr.filter((item) => {
          return item.roleName !== "Recruiter";
        });
        setProfileTypes(tempArr);
      } else {
        setProfileTypes(tempArr);
      }
    })
    .catch((error) => {
      console.error("Error fetching user roles:", error);
    });
  

  }, []);

  // states to store the data received
  //Cropper
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
  const [profileImg1, setProfileImg1] = useState(profileAvatar);
  const [profileImg2, setProfileImg2] = useState(profileAvatar);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImage1, setCroppedImage1] = useState(null);
  const [croppedAreaPixels1, setCroppedAreaPixels1] = useState({
    width: 250,
    height: 250,
    x: 0,
    y: 0,
  });
  const [croppedArea1, setCroppedArea1] = useState();
  const [imagePublicId, setImagePublicId] = useState();
  const [croppedImagePublicId, setCroppedImagePublicId] = useState();

  //Slider
  const [zoom, setZoom] = useState(1);
  const [sliderValue, setSliderValue] = useState(1);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [profileType, setProfileType] = useState("");
  const [isAvatar, setIsAvatar] = useState(true);
  const [interaction, setInteraction] = useState(false);
  const [defaultAvatar, setDefaultAvatar] = useState(true);
  const [isNewImg, setIsNewImg] = useState(false);
  const [newImageUploaded, setNewImageUploaded] = useState(false);

  //variables to make modal reusable for adding users and recruiters
  var userRole = "recruiter";
  var routeType = "add-recruiter";

  //Dialog states
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);

  //Snackbar States
  const [message, setMessage] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false);

  //useRef               
  const imageRef = useRef();

  // store the regex of phone number patterns of all countries
  const phones = getPhonesRegex();
  const [phoneIsValid, setPhoneIsValid] = useState(true);

  // error handling
  const [errors, setErrors] = useState({
    imageSize: "",
    imageType: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    profileType: "",
  });

  // Add User Dialog handler
  const handleCloseAddUser = () => {
    setOpenCloseDialog(true);
    setOpenAddUserForm(false);
  };

  // handle remove image alert dialog
  const handleRemoveImageDialog = () => {
    setOpenAlertDialog(true);
  };

  //handle close confirmation dialog
  const onCloseDialog = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setErrors({
      imageSize: "",
      imageType: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
    });
    setProfileImg1(profileAvatar);
    setOpenCloseDialog(false);
    setOpenAddUserForm(false);
  };

  // handle cancel button press of confirmation dialog
  const onCloseCancel = () => {
    setOpenCloseDialog(false);
    setOpenAddUserForm(true);
  };

  const onCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  // disable the right and left arrow keys for the slider
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  // get url of newly uploaded image
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  // handle profile image upload
  const handleProfileImageUpload = async (e) => {
    const fileCheck = e.target.files[0];
    const maxSize = 1024 * 1024; //1MB
    if (fileCheck.size > maxSize) {
      setErrors({ imageSize: "Please upload image less than 1MB" });
      imageRef.current.value = null;
    } else if (!fileCheck.type.startsWith("image")) {
      setErrors({ imageType: "Please upload an image file" });
      e.target.files = null;
    } else {
      let imageDataUrl = await readFile(fileCheck);
      setProfileImg1(imageDataUrl);
      setProfileImg2(fileCheck);
      setNewImageUploaded(true);
      setErrors({ imageSize: "", imageType: "" });
      setIsAvatar(false);
      setIsNewImg(true);
      setInteraction(false);
      setDefaultAvatar(false);
      setZoom(1);
    }
  };

  // handle profile image remove
  const handleRemoveProfileImage = (e) => {
    setProfileImg1(profileAvatar);
    setProfileImg2(profileAvatar);
    setZoom(1);
    setErrors({ imageSize: "", imageType: "" });
    setIsAvatar(true);
    setNewImageUploaded(false);
    imageRef.current.value = null;
    setDefaultAvatar(true);
    setOpenAlertDialog(false);
  };

  // called everytime the image is cropped
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels1(croppedAreaPixels);
    setCroppedArea1(croppedArea);
  }, []);

  // used to get cropped image
  const showCroppedImage = useCallback(async () => {
    let profileImgUrl = profileImg1;
    try {
      const croppedImage = await getCroppedImg(
        profileImgUrl,
        croppedAreaPixels1,
        0 //rotation
      );
      setCroppedImage1(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels1, profileImg1]);

  //Form Validation handlers
  const firstNameHandler = (e) => {
    setFirstName(e.target.value);
    setErrors({
      firstName: "",
    });
  };

  const lastNameHandler = (e) => {
    setLastName(e.target.value);
    setErrors({
      lastName: "",
    });
  };

  const userChangeHandler = (event, newValue) => {
    setProfileType(newValue.roleName);
    setErrors({ profileType: "" });
  };

  console.log("moduleList",moduleList)

  //add 'Recruiter' user role if not present for company
  const addRecruiterRole = () => {
    const description = ""
    const roleName = "Recruiter"
    let count = 1
    let rows = [...moduleList]

    rows.map(item => {
      if (item.title === "Dashboard" || item.title === "Favorites" || item.title === "Search" || item.title === "Interviews" || item.title === "Clients" || item.title === "Position") {
        // return {...item, checked: true, view: true }
        item.checked = true
        item.edit = true
        item.editChecked = true
        count = count + 1
      }
    })
    if (hasInternalDbAccess) {
      rows.map(item => {
        if (item.title === "Internal DB") {
          item.checked = false
          item.edit = false
          item.view = false
          item.editChecked = false
        }
      })
    }
    //if company doesn't have access to internaldb
    else {
      // filtering modules except internal db
      rows = rows.filter(item => item.title != "Internal DB")
      for (let j = 0; j < rows.length; j++) {
        rows[j].index = j;
      }
    }

    const newData = {
      description,
      roleName,
      rows,
      count,
      compId: id,
      compRefId,
    };
    console.log(newData)
    axios.post("/api/admin/add/userRole", newData)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        if (err.response.status == 404) {
          console.log(err)
        }
      });
  }

  // handle save button press
  const onSubmit = (e) => {
    e.preventDefault();



    if (email === "") {
      return setErrors({
        email: "Email is required",
      });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return setErrors({
        email: "Email is Invalid",
      });
    }
    if (firstName == "")
      return setErrors({ firstName: "First name is required!" });

    if (lastName == "")
      return setErrors({ lastName: "Last name is required!" });

    if (phone === "") {
      return setErrors({ phone: "Phone Number is required" });
    } else if (!phoneIsValid) {
      return setErrors({ phone: "Invalid Phone Number" });
    }

    if (addUserType !== "user" && !recruiterRolePresent) {
      addRecruiterRole()
    }

    if (addUserType === "user" && profileTypes.length > 0) {
      if (addUserType === "user" && profileType == "") {
        return setErrors({
          profileType: "Profile type is required!",
        });
      } else if (
        addUserType === "user" &&
        !profileTypes.find((item) => item.roleName === profileType)
      ) {
        return setErrors({
          profileType: "Please select one among the existing profile types!",
        });
      }
    } else if (addUserType === "user") {
      return setErrors({
        profileType: "No profile types found!",
      });
    }

    let unique_id = uuid();
    const formData = new FormData();

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("role", userRole);
    formData.append("phone", phone);
    formData.append("companyId", companyId);
    formData.append("compId", id);
    formData.append("profileImage", profileImg2);
    formData.append("imagePublicId", imagePublicId);
    formData.append("croppedImage", croppedImage1);
    formData.append("croppedImagePublicId", croppedImagePublicId);
    formData.append("croppedAreaPixels", JSON.stringify(croppedAreaPixels1));
    formData.append("croppedArea", JSON.stringify(croppedArea1));
    formData.append("zoom", Number(zoom));
    formData.append("interacted", interaction);
    formData.append("uniqueId", unique_id);
    formData.append("defaultAvatar", defaultAvatar);

    if (profileType) {
      formData.append("profileType", profileType);
      userRole = "myUsers";
      routeType = "add-user";
    }

    axios
      .post(`/api/${userRole}/${routeType}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log("response from my users",res.data)
        setEmail("");
        setFirstName("");
        setLastName("");
        setPhone("");
        setProfileImg1(profileAvatar);
        setProfileImg2(profileAvatar);
        setZoom(1);
        setSliderValue(1);
        setIsAvatar(true);
        setIsNewImg(false);
        setInteraction(false);
        setNewImageUploaded(false);
        setProfileType("");
        setMessage("Saved successfully!")
        setOpenSnackbar(true);
        setOpenAddUserForm(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 409) {
          setErrors({
            email: "Email already exists!",
          });
        } else {
          setMessage("Something went wrong..")
          setOpenSnackbar(true)
        };
      });
    setIsNewImg(false);
  };

  return (
    <div>
      <div className="main-dialog">
        <Dialog
          open={openAddUserForm}
          onClose={handleCloseAddUser}
          maxWidth="md"
        >
          <DialogContent>
            <Tooltip title="Close">
              <CloseIcon
                className="close-icon"
                onClick={handleCloseAddUser}
                sx={{ cursor: "pointer", padding: 0, margin: "1px" }}
              />
            </Tooltip>

            <Grid container spacing={0} justifyContent="center">
              {/* Left Grid item */}
              <Grid
                item
                xs={12}
                sm={12}
                md={4.5}
                lg={5}
                xl={5}
                className="left-grid"
              >
                <div className="left-container">
                  <div className="image-container">
                    <div className="crop-container">
                      <Cropper
                        className="cropper"
                        image={profileImg1}
                        crop={crop}
                        zoom={zoom}
                        initialCroppedAreaPixels={
                          isNewImg || isAvatar ? null : croppedAreaPixels1
                        }
                        aspect={16 / 16}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onCropSizeChange={showCroppedImage}
                        onInteractionStart={() => {
                          setInteraction(true);
                          showCroppedImage();
                        }}
                        onInteractionEnd={showCroppedImage}
                        onZoomChange={isAvatar ? null : setZoom}
                        restrictPosition={true}
                        onMediaLoaded={showCroppedImage}
                        objectFit="auto-cover"
                      />
                    </div>

                    <div>
                      <label className="image-upload">
                        <input
                          type="file"
                          onChange={handleProfileImageUpload}
                          accept=".jpg, .png, .jpeg"
                          ref={imageRef}
                        />
                        <span>Upload</span>
                      </label>
                      <button
                        className="image-remove"
                        onClick={handleRemoveImageDialog}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  <span className="text-danger">
                    {errors.imageSize} {errors.imageType}
                  </span>
                </div>

                <div className="slider-container">
                  <Box sx={{ height: 100 }}>
                    <Slider
                      sx={{
                        '& input[type="range"]': {
                          WebkitAppearance: "slider-vertical",
                        },
                      }}
                      step={0.1}
                      type="range"
                      min={1}
                      max={3}
                      size="small"
                      value={zoom}
                      orientation="vertical"
                      defaultValue={sliderValue}
                      aria-label="Zoom In"
                      valueLabelDisplay="auto"
                      onKeyDown={preventHorizontalKeyboardNavigation}
                      onChange={
                        isAvatar
                          ? null
                          : (e, zoom) => {
                            setZoom(zoom);
                            setInteraction(true);
                            showCroppedImage();
                          }
                      }
                    />
                  </Box>
                  <div>
                    <p>Zoom in</p>
                  </div>
                </div>
              </Grid>
              <Box>
                <Divider orientation="vertical" />
              </Box>

              {/* Right Grid item */}
              <Grid item xs={12} sm={12} md={7} lg={6.5} className="right-grid">
                <div>
                  <form
                    noValidate
                    className="right-grid-form"
                    onSubmit={onSubmit}
                  >
                    <div class="input-container">
                      <div class="input-field-modal">
                        <label className="date-label">Email*</label>
                        <input
                          className={`${errors.email && "input-field-error"}`}
                          class="email"
                          type="email"
                          placeholder="Email Id*"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors({
                              email: "",
                            });
                          }}
                        />
                      </div>
                      <span className="text-danger">{errors.email}</span>
                      <div class="input-field-modal">
                        <label className="date-label">First Name*</label>
                        <input
                          type="text"
                          value={firstName}
                          className={`${errors.firstName && "input-field-error"}`}
                          onChange={firstNameHandler}
                          placeholder="First Name"
                        />
                      </div>
                      <span className="text-danger">{errors.firstName}</span>
                      <div class="input-field-modal">
                        <label className="date-label">Last Name*</label>
                        <input
                          class="position"
                          type="text"
                          value={lastName}
                          className={`${errors.lastName && "input-field-error"}`}
                          onChange={lastNameHandler}
                          placeholder="Last Name"
                        />
                      </div>
                      <span className="text-danger">{errors.lastName}</span>

                      <div class="input-field-modal">
                        <label className="date-label">Phone*</label>
                        <PhoneInput
                          country={"us"}
                          class="phone"
                          type="number"
                          placeholder="Phone"
                          value={phone}
                          // className={`${errors.phone && "input-field-error"}`}
                          onChange={(phone, country) => {
                            setPhone(phone);
                            var regex =
                              phones[country.countryCode.toUpperCase()];
                            if (regex != undefined) {
                              if (!regex.test(phone)) {
                                setPhoneIsValid(false);
                                return setErrors({
                                  phone: "Invalid Phone Number",
                                });
                              } else {
                                setPhoneIsValid(true);
                                return setErrors({ phone: "" });
                              }
                            }
                          }}
                        />
                      </div>
                      <span className="text-danger">{errors.phone}</span>

                      {addUserType === "user" && (
                        <div className="experience-edit-form">
                          <div className="gender-dropdown users-role-dropdown">
                            <div class="input-field">
                              <label className="date-label">
                                Select User Type
                              </label>
                              <Autocomplete
                                freeSolo={
                                  profileTypes.length > 0 ? true : false
                                }
                                disablePortal
                                id="free-solo-demo"
                                ListboxProps={{ style: { maxHeight: 220 } }}
                                disableClearable={true}
                                onChange={(event, newValue) =>
                                  userChangeHandler(event, newValue)
                                }
                                options={profileTypes}
                                sx={{
                                  width: 300,
                                  "& fieldset": { border: "none" },
                                }}
                                getOptionLabel={(option) => option.roleName.toLowerCase() === "admin" ? "Administrator" : option.roleName.charAt(0).toUpperCase() + option.roleName.slice(1)}
                                renderInput={(params) => (
                                  <TextField
                                    value={profileType}
                                    // className={`${errors.profileType && "input-field-error-userRole"}`}
                                    onChange={(e) => {
                                      setProfileType(e.target.value);
                                      setErrors({
                                        profileType: "",
                                      });
                                    }}
                                    {...params}
                                    margin="normal"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <span className="text-danger">{errors.profileType}</span>

                      <div className="button-container">
                        <button type="submit" className="save-button btn-style filled-btn-animation">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>

      {/* Alert Dialog  */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAlertDialog}
        onClose={onCloseAlertDialog}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              item
              xs={11.5}
              sm={11.5}
              md={12}
              lg={12}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure?</h5>
                <p>Do you want remove your profile picture?</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="alert-buttons-container">
          <button className="filled-btn warning-btn-style filled-btn-animation" onClick={onCloseAlertDialog}>
            {" "}
            Cancel
          </button>
          <button className="outlined-btn warning-btn-style outlined-btn-animation" onClick={handleRemoveProfileImage}>
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      {/* Close confimation dialog */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openCloseDialog}
        onClose={handleCloseAddUser}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              item
              xs={11.5}
              sm={11.5}
              md={12}
              lg={12}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure?</h5>
                <p>Do you want to go back?</p>
                <p>All your current changes will be lost!</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="alert-buttons-container">
          <button className="filled-btn warning-btn-style filled-btn-animation " onClick={onCloseCancel}>
            {" "}
            Cancel
          </button>
          <button className="outlined-btn warning-btn-style outlined-btn-animation" onClick={onCloseDialog}>
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      {/* Snackbar modal */}
      <UserSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
      />
    </div>
  );
};

export default AddRecruiterModal;
