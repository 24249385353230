// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* GeneralSettings.css */
.generalsettings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 57vw;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    /* border-radius: 8px; */
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
`, "",{"version":3,"sources":["webpack://./src/components/skill-assessment/components/TestManagement/GeneralSettings.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,aAAa;IACb,qCAAqC;IACrC,wBAAwB;IACxB,sBAAsB;IACtB,sBAAsB;EACxB","sourcesContent":["/* GeneralSettings.css */\n.generalsettings-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    max-width: 57vw;\n    margin: 0 auto;\n    padding: 20px;\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n    /* border-radius: 8px; */\n    background-color: #fff;\n    border: 1px solid #ddd;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
