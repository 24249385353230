import * as React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Import Grid for columns
import Divider from '@mui/material/Divider'; // Import Divider for the line
import MoreVertIcon from '@mui/icons-material/MoreVert';

//import screener logo
import logo from "../../images/brand.svg";

export default function CandidateNavbar({ totalQuestions, attemptedQuestions, showMeter, testName }) {

    const { testId, invitationToken } = useParams();

    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false); // State for dialog
    const [supportEmail, setSupportEmail] = React.useState("");
    const [supportPhone, setSupportPhone] = React.useState("");
    const [reportEmail, setReportEmail] = React.useState("");

    React.useEffect(() => {
        const fetchData = async () => {
          try {
            const testResponse = await axios.post(`/api/candidate/get-test-detail/${testId}/${invitationToken}`)
            console.log("testNavbar = ", testResponse);

            // console.log("SupportEmail=",testResponse.data.test.AdvancedSettings.supportEmail);
            setSupportEmail(testResponse.data.test.AdvancedSettings.supportEmail)
          

            // console.log("supportPhone=",testResponse.data.test.AdvancedSettings.supportPhone);
            setSupportPhone(testResponse.data.test.AdvancedSettings.supportPhone)

            setReportEmail(testResponse.data.test.AdvancedSettings.reportEmails);
               
                        
          } catch (err) {
           console.log("error ", err);
           
          } 

        };
    
        // Call the async function
        fetchData();
    
      }, []);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    // Function to open dialog
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    // Function to close dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ bgcolor: '#FFFFFF', height: '60px', boxShadow: '0 1px 5px rgba(0, 0, 0, 0.05), 0 1px 5px rgba(0, 0, 0, 0.15) !important' }}>
                <Toolbar sx={{ minHeight: '60px', padding: '0px', display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            component="img"
                            src={logo}
                            alt=""
                            sx={{ width: '45px', height: '45px' }}
                            className="brand-img profile-brand-img"
                        />
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ color: '#F06161', fontSize: '32px', fontWeight: 'bold', marginLeft: '10px' }}>
                            Screener
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: 'rgba(0, 0, 0, 0.7)',
                                fontWeight: 'bold',
                                marginLeft: '10px',
                                marginTop: '5px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: showMeter ? '150px' : '400px',
                            }}
                        >
                            {testName}
                        </Typography>
                    </Box>
                    {showMeter && (
                        <Box sx={{ width: '200px', ml: '60%' }}>
                            <LinearProgress
                                variant="determinate"
                                value={(attemptedQuestions / totalQuestions) * 100}
                                sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    backgroundColor: '#e0e0e0',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: '#F06161',
                                    },
                                }}
                            />
                            <Typography variant="caption" display="block" textAlign="center" sx={{ color: 'black', fontSize: '12px', marginTop: '5px' }}>
                                {`${attemptedQuestions}/${totalQuestions} Questions Attempted`}
                            </Typography>
                        </Box>
                    )}
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'rgba(0, 0, 0, 0.7)',
                            cursor: 'pointer', // Makes the text look clickable
                            marginLeft: showMeter ? '20px' : 'auto',
                            marginRight: '20px',
                            whiteSpace: 'nowrap',
                            fontSize: 'smaller',
                            fontWeight: '600',
                        }}
                        onClick={handleOpenDialog} // Open dialog on click
                    >
                        Help
                    </Typography>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        sx={{ color: 'rgba(0, 0, 0, 0.7)' }}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={handleCloseMenu}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            {/* Dialog component */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
                sx={{ '& .MuiDialog-paper': { height: '200px' } }} // Increased height
            >
                <Typography variant="h5" sx={{ fontSize: '1.3rem' }}> {/* Increase font size */}
                    need help?
                </Typography>
                <DialogContent>
                    <Divider sx={{ my: 1 }} /> {/* Add a line below "Need help?" */}

                    <Box>
                        <Grid container spacing={2} justifyContent="center" alignItems="center" textAlign="center" mt='3%'> {/* Center content */}
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ fontSize:'1rem' }}>
                                <strong>{supportEmail} </strong><br />
                                    Point of contact
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ fontSize:'1rem' }}>
                                    <strong>{reportEmail}</strong><br />                             
                                    Platform support - Email
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ fontSize:'1rem' }}>
                                    <strong>+91 {supportPhone}</strong><br />
                                    Platform support - Phone
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                {/* Uncomment DialogActions if needed */}
                {/* <DialogActions>
                        <Button onClick={handleCloseDialog} sx={{ color: '#f06161' }}>
                            Close
                        </Button>
                     </DialogActions> */}
            </Dialog>

        </Box>
    );
}
