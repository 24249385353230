import React, { useState, useEffect } from 'react';

// import mui components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// import mui icons
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WifiIcon from '@mui/icons-material/Wifi';
import MonitorIcon from '@mui/icons-material/Monitor';
import PublicIcon from '@mui/icons-material/Public';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const SystemChecks = ({ setTabValue }) => {

    const imageAddr = "https://upload.wikimedia.org/wikipedia/commons/3/3a/Bloemen_van_adderwortel_%28Persicaria_bistorta%2C_synoniem%2C_Polygonum_bistorta%29_06-06-2021._%28d.j.b%29.jpg";
    const downloadSize = 7300000; // bytes

    const [internetSpeed, setInternetSpeed] = useState("Loading...");
    const [systemTime, setSystemTime] = useState('');
    const [os, setOs] = useState('');
    const [browser, setBrowser] = useState('');
    const [isInternetSpeedLoaded, setIsInternetSpeedLoaded] = useState(false);
    const [internetSpeedStatus, setInternetSpeedStatus] = useState('');

    // for internet speed
    useEffect(() => {
        const measureConnectionSpeed = () => {
            let startTime, endTime;
            const download = new Image();

            download.onload = function () {
                endTime = new Date().getTime();
                console.log('Image loaded successfully');
                showResults();
            };

            download.onerror = function () {
                console.log('Error loading image');
                setInternetSpeed("Low internet connection");
                setIsInternetSpeedLoaded(false);
                setInternetSpeedStatus('low');
            };

            startTime = new Date().getTime();
            const cacheBuster = "?nnn=" + startTime;
            download.src = imageAddr + cacheBuster;
            console.log('Downloading image:', download.src);

            const showResults = () => {
                const duration = (endTime - startTime) / 1000;
                const bitsLoaded = downloadSize * 8;
                const speedBps = (bitsLoaded / duration).toFixed(2);
                const speedMbps = (speedBps / (1024 * 1024)).toFixed(2);
                setInternetSpeed(`${speedMbps} Mbps`);
                setIsInternetSpeedLoaded(true);
                setInternetSpeedStatus(speedMbps < 5 ? 'low' : 'good');
                console.log('Internet speed:', speedMbps, 'Mbps');
            };
        };

        measureConnectionSpeed();
    }, []);

    // for system date & time, os, browser
    useEffect(() => {
        // Get current system time
        const now = new Date();
        const options = {
            weekday: 'short', // 'Mon'
            day: 'numeric',   // '1'
            month: 'short',   // 'Jul'
            // year: 'numeric',  // '2024'
            hour: '2-digit',  // '20'
            minute: '2-digit',// '15'
        };
        const formattedTime = new Intl.DateTimeFormat('en-US', options).format(now);
        setSystemTime(formattedTime);

        // Determine operating system
        const platform = navigator.platform;
        if (platform.includes('Win')) {
            setOs('Windows');
        } else if (platform.includes('Mac')) {
            setOs('Mac');
        } else if (platform.includes('Linux')) {
            setOs('Linux');
        } else {
            setOs('Unknown OS');
        }

        // Determine browser (check Edge before Chrome)
        const userAgent = navigator.userAgent;
        if (userAgent.includes('Edg')) {
            setBrowser('Edge');
        } else if (userAgent.includes('Chrome')) {
            setBrowser('Chrome');
        } else if (userAgent.includes('Firefox')) {
            setBrowser('Firefox');
        } else if (userAgent.includes('Safari')) {
            setBrowser('Safari');
        } else {
            setBrowser('Unknown Browser');
        }
    }, []);

    const handleClickNext = () => {
        setTabValue(2); // Change the tab to "Declaration"
    };

    const handleClickPrevious = () => {
        setTabValue(0); // Change the tab to "Proctoring Instructions"
    };

    return (
        <>
            <div className='proctor-screen'>
                <div className='heading'>
                    <h5>System Checks</h5>
                    <hr style={{ marginTop: '25px' }} />
                </div>
                <div className="parent-container">
                    <div className="grid-container">
                        <div className="grid-item">
                            <AccessTimeIcon style={{ fontSize: 60, color: 'rgba(0, 0, 0, 0.7)', marginRight: '5px' }} className='aptitude-info-icon' />
                            <div className='grid-info'>
                                <h6>System Time {systemTime && <CheckCircleIcon style={{ color: 'green', fontSize: 'larger' }} />}</h6>
                                <p>{systemTime}</p>

                            </div>
                        </div>
                        <div className="grid-item1">
                            <WifiIcon style={{ fontSize: 60, color: 'rgba(0, 0, 0, 0.7)', marginRight: '5px' }} className='aptitude-info-icon' />
                            <div className='grid-info'>
                                <h6>Internet Speed {isInternetSpeedLoaded && (
                                    internetSpeedStatus === 'low' ?
                                        <CancelIcon style={{ color: 'red', fontSize: 'larger' }} /> :
                                        <CheckCircleIcon style={{ color: 'green', fontSize: 'larger' }} />
                                )}</h6>
                                <p>{internetSpeed}</p>

                            </div>
                        </div>
                        <div className="grid-item">
                            <MonitorIcon style={{ fontSize: 60, color: 'rgba(0, 0, 0, 0.7)', marginRight: '5px' }} className='aptitude-info-icon' />
                            <div className='grid-info'>
                                <h6>Operating System {os && <CheckCircleIcon style={{ color: 'green', fontSize: 'larger' }} />}</h6>
                                <p>{os}</p>

                            </div>
                        </div>
                        <div className="grid-item1">
                            <PublicIcon style={{ fontSize: 60, color: 'rgba(0, 0, 0, 0.7)', marginRight: '5px' }} className='aptitude-info-icon' />
                            <div className='grid-info'>
                                <h6>Browser {browser && <CheckCircleIcon style={{ color: 'green', fontSize: 'larger' }} />}</h6>
                                <p>{browser}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'flex', width: '300px' }}>
                    <Button
                        variant='contained'
                        className='proctor-previous-button'
                        onClick={handleClickPrevious}
                    >
                        <ArrowLeftIcon style={{ position: 'relative', right: '3px' }} />
                        Previous
                    </Button>
                    <Button
                        variant='contained'
                        className='proctor-next-button'
                        style={{ position: 'relative', left: '20px' }}
                        onClick={handleClickNext}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </>
    );
}

export default SystemChecks;
