import axios from "axios";
import { jwtDecode as jwt_decode } from "jwt-decode";
import React from "react";
import setAuthToken from "../utils/setAuthToken";
import { GET_AUTH_ERRORS, SET_CURRENT_USER } from "./authTypes";

// Register User
export const registerUser = (userData, history, enqueueSnackbar) => (
  dispatch
) => {
  axios
    .post("/api/auth/register", userData)
    .then((res) => {
      // Snackbar messages can be DOM elements;
      let regMessage = (
        <span>
          you have succesfully registered {res.data.name}, You will now be
          redirected
        </span>
      );

      enqueueSnackbar(regMessage, {
        variant: "success",
        autoHideDuration: 3000,
      });

      // if (res.data.role === 'screener') {
      //     setTimeout(() => history.push("/"), 3000);
      // } else {
      //     setTimeout(() => history.push("/candidate-register", {email: res.data.email}), 3000);
      // }

      const userInfo = {
        email: res.data.email,
        role: res.data.role,
        name: res.data.name,
      };

      if (res.data.role === "recruiter") {
        setTimeout(() => history.push("/admin/recruiter-list"), 3000);
      } else {
        setTimeout(() => history.push("/register-wizard", userInfo), 3000);
      }
    })
    .catch((err) =>
      dispatch({
        type: GET_AUTH_ERRORS,
        payload: err,
      })
    );
};

export const updateCandidate = (candidateData, history, enqueueSnackbar) => (
  dispatch
) => {
  axios
    .post("/api/auth/candidate-update", candidateData)
    .then((res) => {
      // Snackbar messages can be DOM elements;
      let regMessage = <span>{res.data.successMessage}</span>;

      enqueueSnackbar(regMessage, {
        variant: "success",
        autoHideDuration: 3000,
      });

      setTimeout(() => history.push("/"), 3000);
    })
    .catch((err) =>
      dispatch({
        type: GET_AUTH_ERRORS,
        payload: err,
      })
    );
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/auth/login", userData)
    .then((res) => {
      const { token } = res.data;
      sessionStorage.setItem("jwtToken", token);
      setAuthToken(token);
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_AUTH_ERRORS,
        payload: err.response.data,
      })
    );
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  sessionStorage.removeItem("jwtToken");
  //   localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};