import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

//import mui components
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

//import mui icons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

//component imports 
import CandidateNavbar from './CandidateNavbar';
import CandidateProctorMode from './CandidateProctorMode'

//styles import
import './CandidateAssessment.css';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';



const CandidateAssessment = () => {

    const navigate = useNavigate();

    const { testId, invitationToken } = useParams();
    console.log("id ===", testId, invitationToken);

    const [testName, setTestName] = useState("")
    const [duration, setDuration] = useState(0);
    const [numOfSections, setNumOfSections] = useState(0);
    const [numOfProblems, setNumOfProblems] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [errorMessage, setErrorMessage] = useState(""); // Store error message
    const [cameraEnabled, setCameraEnabled] = useState(false);


    const [showProctorMode, setShowProctorMode] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const testResponse = await axios.post(`/api/candidate/get-test-detail/${testId}/${invitationToken}`)
                console.log("testResponse = ", testResponse);

                if (testResponse.data.error) {
                    setErrorMessage(testResponse.data.error); // Set error message from response
                    return;
                }

                // const { candidates } = testResponse.data.test;
                // console.log("candidates = ", candidates[0].status);

                //comment this code if you want to open the submitted test
                if (testResponse.data.test.candidates[0].status === 'failed' || testResponse.data.test.candidates[0].status === 'passed') {
                    navigate('/already-taken'); // Navigate to a custom "already taken" page
                }

                // console.log("testName=",testResponse.data.test.testName);
                setTestName(testResponse.data.test.testName);

                // console.log("duration=", testResponse.data.test.GeneralSettings.duration);
                setDuration(testResponse.data.test.GeneralSettings.duration)

                // console.log("sections length",testResponse.data.test.sections.length);
                setNumOfSections(testResponse.data.test.sections.length);

                // console.log("start date", testResponse.data.test.AdvancedSettings.startDate);
                setStartDate(testResponse.data.test.AdvancedSettings.startDate)

                setStartTime(testResponse.data.test.AdvancedSettings.startTime)

                // console.log("camera enable", testResponse.data.test.AdvancedSettings.cameraEnabled);
                setCameraEnabled(testResponse.data.test.AdvancedSettings.cameraEnabled)

                // Calculate total number of problems
                const total = testResponse.data.test.sections.reduce((acc, section) => {
                    return acc + section.problems.length;
                }, 0);
                console.log("total questions", total)
                setNumOfProblems(total);

            } catch (err) {
                console.log("error ", err);

                // Check if it's a 403 error and show the specific message
                if (err.response && err.response.status === 403 && err.response.data.error) {
                    setErrorMessage(err.response.data.error);
                }
            }
        };

        // Call the async function
        fetchData();


    }, []);

    const handleClick = () => {
        setShowProctorMode(true);
    };

    if (showProctorMode) {
        return <CandidateProctorMode testName={testName} duration={duration} cameraEnabled = {cameraEnabled} />;
    }

    if (errorMessage) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}>
                <ErrorOutlineOutlinedIcon color="error" sx={{ fontSize: 80 }} />
                <Typography variant="h5" sx={{ mt: 2 }}>
                    {errorMessage}
                </Typography>
                {/* <Button
                    variant="contained"
                    sx={{ mt: 3 }}
                    onClick={() => navigate('/')}
                >
                    Go Back
                </Button> */}
            </Box>
        );
    }

    // Helper function to convert minutes to hours and minutes
    const formatDuration = (minutes) => {
        if (minutes < 60) {
            return `${minutes} mins`;
        } else {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes ? `${remainingMinutes} minutes` : ''}`;
        }
    };


    //to fetch current date
    const currentDate = new Date();
    // Combine the date and time into one string
    const dateTimeString = `${startDate}T${startTime}`;

    // Create a new Date object from the combined string
    const date = new Date(dateTimeString);
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedDate = date.toLocaleDateString('en-US', options);

    //assessment instructions
    const instructions = [
        "This is an online test.",
        "Please make sure that you are using the latest version of the browser. We recommend using Google Chrome.",
        "It's mandatory to disable all the browser extensions and enabled Add-ons or open the assessment in incognito mode.",
        "If you are solving a coding problem, you will either be required to choose a programming language from the options that have been enabled by the administrator or choose your preferred programming language in case no options have been enabled by the administrator. Note: In case you're solving coding problems: All inputs are from STDIN and output to STDOUT.",
        "If test mandates you to use the webcam, please provide the required permissions and access.",
        "To know the results, please contact the administrator.",
        "To refer to the FAQ document, you can click on the HELP button which is present in the top right corner of the test environment."
    ];

    // let startass = {
    //     backgroundColor : "#F06161"
    // }

    return (
        <Box sx={{ backgroundColor: '#f5f5f5', minHeight: 'fitContent' }}>
            <CandidateNavbar testName={testName} />
            <Grid
                container
                justifyContent="center"
                rowGap={2}
            // style={{  backgroundColor:'red' }}
            >
                <Grid item xs={6.6}>
                    <div className='aptitude-title' >
                        <h3 style={{ textAlign: 'center' }}>{testName}</h3>
                    </div>
                </Grid>

                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={2.2}>
                        <div className='aptitude-info'>
                            <AccessTimeIcon style={{ fontSize: 50, color: 'rgba(0, 0, 0, 0.7)' }} className='aptitude-info-icon' />
                            <div className='aptitude-info-content'>
                                <h6>{formatDuration(duration)}</h6>
                                <pre>to take this assessment</pre>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={2.2}>
                        <div className='aptitude-info'>
                            <FolderIcon style={{ fontSize: 50, color: 'rgba(0, 0, 0, 0.7)' }} className='aptitude-info-icon' />
                            <div className='aptitude-info-content'>
                                <h6>{numOfSections}</h6>
                                <pre>sections to be solved</pre>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={2.2}>
                        <div className='aptitude-info'>
                            <DescriptionIcon style={{ fontSize: 50, color: 'rgba(0, 0, 0, 0.7)' }} className='aptitude-info-icon' />
                            <div className='aptitude-info-content'>
                                <h6>{numOfProblems} problems</h6>
                                <pre>problems to be solved</pre>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={6.6}>
                        <div className='aptitude-date' >
                            <CalendarTodayIcon style={{ fontSize: 30, color: 'rgba(0, 0, 0, 0.7)' }} />
                            <p>starts {formattedDate}</p>
                        </div>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={6.6}>
                        <div className='assessment-instructions' >
                            <h3>Assessment Instructions</h3>
                            <hr />
                            <Box component="ol" style={{ paddingLeft: '20px', fontWeight: '600' }}>
                                {instructions.map((instruction, index) => (
                                    <Box component="li"
                                        key={index}
                                        className='list-style'
                                    // style={{ marginBottom: '10px', fontWeight: "600", fontSize: "14px" }}
                                    >
                                        <Typography variant="body1" className='list-style' >{instruction}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </div>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" >
                    <Grid item xs={3} style={{ textAlign: 'center' }}>
                        <Button variant="contained" className='start-button' onClick={handleClick}>
                            <PlayArrowIcon style={{ position: 'relative', right: '5px' }} />
                            Start Assessment
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    );
}

export default CandidateAssessment;