import React, { useEffect, useRef, useState } from "react";

//MUI imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Divider, Tooltip, TextField, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import Chip from "@mui/material/Chip";
import EventIcon from "@mui/icons-material/Event";
import AddIcon from "@mui/icons-material/Add";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";



import Zoom from "../images/zoom.svg";
//MUI Icons
import FilterIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import OpenArrow from "@mui/icons-material/ChevronLeft";
import CloseArrow from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";

import axios from "axios";

import { Country, State, City }  from 'country-state-city';
import Select from "react-select";
import ReactPaginate from "react-paginate";
import Highlighter from "react-highlight-words";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { animateScroll, scrollTo, scroller } from "react-scroll";
import { v4 as uuidv4 } from "uuid";
import { CopyToClipboard } from "react-copy-to-clipboard";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ExperienceIcon from "@mui/icons-material/Work";
import SalaryIcon from "@mui/icons-material/AccountBalanceWallet";
import LocationIcon from "@mui/icons-material/LocationOn";
import ShareIcon from "@mui/icons-material/Share";
import EmailIcon from "@mui/icons-material/Email";
import FavoriteIcon from "@mui/icons-material/Star";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ViewIcon from "@mui/icons-material/Visibility";
import NoteAddIcon from "@mui/icons-material/PostAdd";
import ViewNoteIcon from "@mui/icons-material/Comment";
import Badge from "@mui/material/Badge";
import ResumeIcon from "@mui/icons-material/Description";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LinkIcon from "@mui/icons-material/Link";

import UserSnackBar from "../../snackBars/UserSnackbar";
// import data from "../../modals/skills.json"
import Skills from "../../modals/skills.js";
// import SearchResults from './SearchResults';
import { timezone } from "../../../data-files/timezone";
import { useNavigate } from "react-router-dom";
import CompanyMenu from "../../userProfiles/CompanyMenu";

import NothingToShow from "../images/nothingtoshow.svg";
import Footer from "../../../Footer/Footer";
import evaluateBooleanQuery from "./BooleanSearch";
import ShareProfileModal from "../Internal Users/Modals/ShareProfileModal";
import AddNoteModal from "../Internal Users/Modals/AddNoteModal";
import ViewNotes from "../Internal Users/Modals/ViewNotes";
import CancelInterview from "./Modals/CancelInterview";

const JITSI_URL = "https://meet.jit.si";

const Search = () => {
  //time details
  const hoursArray = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];
  const minutesArray = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
  ];
  const meridianArray = ["AM", "PM"];
  const data = Skills();
  //push for navigation
  const navigate = useNavigate();

  //Session storage values
  const id = sessionStorage.getItem("id");
  const companyId = sessionStorage.getItem("companyId");
  const role = sessionStorage.getItem("role");
  const token = sessionStorage.getItem("jwtToken");

  const resultBodyRef = useRef(null);
  const quickScheduleRef = useRef(null);
  const meetLinkRef = useRef(null);

  //Navbar states
  const [open, setOpen] = useState();
  const [sidebarOpen, setsidebarOpen] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [interacted, setInteracted] = useState("");
  const [croppedImg, setCroppedImg] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [defaultAvatar, setDefaultAvatar] = useState("");
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

  //Loader state
  const [isLoading, setIsLoading] = useState(true);

  const [displayCard, setDisplayCard] = useState(true);
  const [meetingPlatform, setMeetingPlatform] = useState("");

  //snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  //Top card states
  const [selectedRole, setSelectedRole] = useState("candidate");
  const [roleFilter, setRoleFilter] = useState(
    localStorage.getItem("role") || "candidate"
  );
  const [candidateSelected, setCandidateSelected] = useState(
    localStorage.getItem("role") === "screener" ? false : true
  );

  //Accordian states
  const [expandAll, setExpandAll] = useState(false);
  const [usersDB, setUsersDB] = useState(true);
  const [internalDB, setInternalDB] = useState(false);
  const [personalDetailsExpand, setPersonalDetailsExpand] = useState(false);
  const [skillsExpand, setSkillsExpand] = useState(false);
  const [salaryExpand, setSalaryExpand] = useState(false);
  const [locationExpand, setLocationExpand] = useState(false);
  const [experienceExpand, setExperienceExpand] = useState(false);
  const [educationExpand, setEducationExpand] = useState(false);
  const [currentDesignationExpand, setCurrentDesignationExpand] =
    useState(false);
  const [currentCompanyExpand, setCurrentCompanyExpand] = useState(false);
  const [scheduleClicked, setScheduleClicked] = useState(false);
  const [databaseExpand, setDatabaseExpand] = useState(false);

  const [interviewTitle, setInterviewTitle] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [screenerName, setScreenerName] = useState("");
  const [candidateDetails, setCandidateDetails] = useState({
    name: "",
    id: "",
    email: "",
  });
  const [screenerDetails, setScreenerDetails] = useState({
    name: "",
    id: "",
    email: "",
  });
  //filter states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [fromExperience, setFromExperience] = useState("");
  const [toExperience, setToExperience] = useState("");
  const [salary, setSalary] = useState("");
  const [payType, setPayType] = useState("");
  const [degree, setDegree] = useState("");
  const [stream, setStream] = useState("");
  const [skill, setSkill] = useState("");
  const [skillsList, setSkillsList] = useState([]);
  const [booleanSkillsList, setBooleanSkillsList] = useState([]);

  const [value, setValue] = useState("");
  const [clear, setClear] = useState(false);
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [clientsList, setClientsList] = useState([]);
  const [positionsList, setPositionsList] = useState([]);
  // client states
  const [clientName, setClientName] = useState("");
  const [clientDetails, setClientDetails] = useState({
    id: "",
    name: "",
    email: "",
  });

  // position states
  const [positionName, setPositionName] = useState("");
  const [positionDetails, setPositionDetails] = useState({ id: "", name: "" });

  const [replyToEmail, setReplyToEmail] = useState("");

  //suggestion Boolean
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [showPercentage, setShowPercentage] = useState(true);

  const [skillSuggestions, setSkillSuggestions] = useState([]);
  const [finalSuggestions, setFinalSuggestions] = useState([]);

  const [filterPrevState, setfilterPrevState] = useState({
    skills: [],
    designation: "",
    experience: { from: "", to: "" },
    expectedSalary: { salary: "", payType: "" },
    location: { country: "", state: "", city: "" },
    personaldetails: { firstName: "", lastName: "", email: "", gender: "" },
    company: "",
    highesteducation: { degree: "", stream: "" },
  });

  //share profile states
  const [profilePublicLink, setProfilePublicLink] = useState("");
  const [includeResume, setIncludeResume] = useState(false);

  //share profile modal states
  const [openShareModal, setOpenShareModal] = useState(false);

  // add note modal state
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openViewNoteModal, setOpenViewNoteModal] = useState(false);
  const [unreadNotesCount, setUnreadNotesCount] = useState();

  const [viewNoteUser, setViewNoteUser] = useState();
  const [selectedItem, setSelectedItem] = useState({});



  // Function to scroll to the target element
  const scrollToElement = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth", // You can change this to 'auto' for instant scrolling
        block: "center", // You can change this to 'end' or 'center' to adjust where the element appears on the screen
      });
    }
  };

  const shareHandler = (item) => {
    setSelectedItem(item);
    setProfilePublicLink(
      `https://screener.infomaticscorp.com/profile/share/${roleFilter}/${item._id}/${id}`
    );
    setOpenShareModal(true);
  };

  // Add note handler for each user
  const noteHandler = (item) => {
    setSelectedItem(item);
    setOpenNoteModal(true);
  };

  // View note handler
  const viewNoteHandler = async (user) => {
    axios
      .post(`/api/admin/note-given-by/${id}/${id}`, user)
      .then((res) => {
        setOpenViewNoteModal(true);
        let userObj = { ...user, notes: res.data };
        setViewNoteUser(userObj);
        const newData = {
          companyId: id,
          userId: user?._id,
          loggedProfileId: id,
        };
        axios
          .post(`/api/admin/update-note-readBy/`, newData)
          .then((res) => {})
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //mui autocomplete options
  const clientOptions = clientsList?.map((client) => {
    return {
      name: client.companyName,
      label: `${client.companyName} - CLN-${client._id.slice(-5)} - ${
        client.email
      }`,
      value: {
        id: client._id,
        email: client.email,
        name: client.companyName,
      },
    };
  });

  const positionOptions = positionsList?.map((pos) => {
    return {
      name: pos.position,
      label: `${pos.position} - POS-${pos._id.slice(-5)}`,
      value: {
        id: pos._id,
        name: pos.position,
      },
    };
  });

  //get all the clients of the company
  function getAllClients() {
    axios
      .get(`/api/admin/get-all-clients/${id}`)
      .then((res) => {
        const { data } = res;
        setClientsList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getAllPositions(clientDetail) {
    axios
      .get(`/api/admin/get-all-positions/${id}`)
      .then((res) => {
        let { position } = res.data;
        const { id } = clientDetail;
        position = position.filter((pos) => pos.client.id == id);
        setPositionsList(position);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //set the client name and details
  const handleClientData = (event, newValue) => {
    setClientName(newValue?.name);
    setClientDetails(newValue?.value);
    setPositionName("");
    setPositionDetails({ id: "", name: "" });
    getAllPositions(newValue?.value); //get all the positions of the respective client
  };

  //set the position name and details
  const handlePositionData = (event, newValue) => {
    setPositionName(newValue.name);
    setPositionDetails(newValue.value);
  };

  const handleAutoCompleteTextField = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "client":
        if (value === "") setPositionDetails({ id: "", name: "" }); //delete position details when client name is empty
        setClientName(value);
        setClientDetails({ id: "", name: "", email: "" });
      case "position":
        setPositionName(value);
        setPositionDetails({ id: "", name: "" });
      default:
    }
  };

  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));

  const updatedStates = (country) =>
    
     State.getStatesOfCountry(country?.value).map((state) => ({
      label: state.name,
      value: state.isoCode,
      ...state,
    }));
  

    const updatedCities = (country,state) =>
        
         City.getCitiesOfState(country?.value,state?.value).map((city) => ({
          label: city.name,
          value: city.isoCode,
          ...city,
        }));
               

  const [initialLocation, setInitialLocation] = useState({
    country: null,
    state: null,
    city: null,
    countryId: 0,
    stateId: 0,
  });

  //Search body states
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const [highlight, setHighlight] = useState(false);
  //array of users
  const [users, setUsers] = useState([]);
  const resLength = users.length;
  const [filterArray, setFilterArray] = useState([]);

  //errors
  const [errors, setErrors] = useState({
    experience: "",
  });

  //interview schedule states

  const [zone, setZone] = useState("Asia/Kolkata");
  const [interviewLink, setInterviewLink] = useState("");
  const [customLink, setCustomLink] = useState(false);
  const [time, setTime] = useState("12:15 PM");
  const [date, setDate] = useState("");

  //localStorageCLear
  const [isLocalClear, setIsLocalClear] = useState(false);

  //time picker options

  //Loader used while generating link
  const [linkLoader, setLinkLoader] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [favLoader, setFavLoader] = useState({});
  const [hours, setHours] = useState("12");
  const [meridian, setMeridian] = useState("PM");
  const [minutes, setMinutes] = useState("15");
  //filter States

  function timeConvertor(interviewTime) {
    let timeArray = interviewTime.split(":");
    const hour = timeArray[0];
    setHours(hour);
    const min = timeArray[1].slice(0, 2);
    setMinutes(min);
    const mer = timeArray[1].slice(3);
    setMeridian(mer);
  }

  const generateYears = (from, to) => {
    var a = [];
    for (var i = from; i <= to; i++) {
      a.push(i);
    }
    return a;
  };

  //Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;
  const pageCount = Math.ceil(users.length / usersPerPage);
  const offset = currentPage * usersPerPage;
  // let customHeight = "";
  let [customHeight, setCustomHeight] = useState("");

  const [booleanSearch, setBooleanSearch] = useState(false);

  useEffect(() => {
    const localTime = localStorage.getItem("time");
    if (localTime) {
      timeConvertor(localTime);
    }

    axios
      .get("/api/admin/suggestion/get-all-searches")
      .then((res) => {
        setFinalSuggestions(res.data);
      })
      .catch((err) => console.log(err));

    if (window.pageYOffset < 93) {
      setCustomHeight("");
    } else {
      setCustomHeight("custom-height");
    }

    document.title = "Screener | Search";
    setOpen(false);
    setsidebarOpen("sidebar-close");
    getAllClients();
  }, []);

  window.addEventListener("scroll", () => {
    if (window.pageYOffset < 93) {
      setCustomHeight("");
    } else {
      setCustomHeight("custom-height");
    }
  });

  // Search suggestion useEffect
  useEffect(() => {
    setShowSuggestions(false);
  }, [skillsList.length]);

  useEffect(() => {
    setTimeout(() => {
      if (isLocalClear) {
        clearLocalStorage();
      }
    }, 2000);
  }, [isLocalClear]);

  //useEffect handlers
  useEffect(() => {
    if (open) {
      setRightSidebarOpen(false);
    }
  }, [open]);

  useEffect(() => {
    if (localStorage.getItem("candidate") || localStorage.getItem("screener")) {
      localStorage.getItem("title") === null
        ? setInterviewTitle("")
        : setInterviewTitle(localStorage.getItem("title"));
      setRightSidebarOpen(localStorage.getItem("rightSidebarOpen"));
      setScheduleClicked(localStorage.getItem("scheduled"));
      localStorage.getItem("screener")
        ? setScreenerName(localStorage.getItem("screener"))
        : setScreenerName("");
      localStorage.getItem("candidate")
        ? setCandidateName(localStorage.getItem("candidate"))
        : setCandidateName("");
      localStorage.getItem("client")
        ? setClientName(localStorage.getItem("client"))
        : setClientName("");
      localStorage.getItem("position")
        ? setPositionName(localStorage.getItem("position"))
        : setPositionName("");
      localStorage.getItem("zone")
        ? setZone(localStorage.getItem("zone"))
        : setZone("Asia/Kolkata");
      localStorage.getItem("time")
        ? setTime(localStorage.getItem("time"))
        : setTime("12:15 PM");
      localStorage.getItem("date")
        ? setDate(localStorage.getItem("date"))
        : setDate("");

      localStorage.getItem("candidateDetails")
        ? setCandidateDetails(
            JSON.parse(localStorage.getItem("candidateDetails"))
          )
        : setCandidateDetails({
            name: "",
            id: "",
            email: "",
          });
      localStorage.getItem("screenerDetails")
        ? setScreenerDetails(
            JSON.parse(localStorage.getItem("screenerDetails"))
          )
        : setScreenerDetails({
            name: "",
            id: "",
            email: "",
          });
      localStorage.getItem("clientDetails")
        ? setClientDetails(JSON.parse(localStorage.getItem("clientDetails")))
        : setClientDetails({
            name: "",
            id: "",
            email: "",
          });
      localStorage.getItem("positionDetails")
        ? setPositionDetails(
            JSON.parse(localStorage.getItem("positionDetails"))
          )
        : setPositionDetails({
            name: "",
            id: "",
          });
      localStorage.getItem("link")
        ? setInterviewLink(localStorage.getItem("link"))
        : setInterviewLink("");
      localStorage.getItem("link")
        ? setMeetingPlatform(localStorage.getItem("meetingPlatform"))
        : setMeetingPlatform("");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("title", interviewTitle);
    localStorage.setItem("candidate", candidateName);
    localStorage.setItem("screener", screenerName);
    localStorage.setItem("link", interviewLink);
    localStorage.setItem("meetingPlatform", meetingPlatform);
    localStorage.setItem("date", date);
    localStorage.setItem("time", time);
    localStorage.setItem("zone", zone);
    localStorage.setItem("role", roleFilter);
    localStorage.setItem("candidateDetails", JSON.stringify(candidateDetails));
    localStorage.setItem("screenerDetails", JSON.stringify(screenerDetails));
    localStorage.setItem("scheduled", scheduleClicked);
    localStorage.setItem("rightSidebarOpen", rightSidebarOpen);
    localStorage.setItem("clientDetails", JSON.stringify(clientDetails));
    localStorage.setItem("positionDetails", JSON.stringify(positionDetails));
    localStorage.setItem("client", clientName);
    localStorage.setItem("position", positionName);
  }, [
    screenerName,
    interviewLink,
    date,
    zone,
    time,
    roleFilter,
    candidateName,
    interviewTitle,
    rightSidebarOpen,
    scheduleClicked,
    clientName,
    positionName,
  ]);

  //fetching navbar data
  useEffect(() => {
    const { data } = axios.get(`/api/admin/${id}`).then((res) => {
      const {
        defaultAvatar,
        profileImage,
        croppedImage,
        interacted,
        companyName,
        companyEmail,
      } = res.data.user;
      setDefaultAvatar(defaultAvatar);
      setProfileImage(profileImage);
      setCroppedImg(croppedImage);
      setInteracted(interacted);
      setCompanyName(companyName);
      setReplyToEmail(companyEmail);
    });
  }, []);

  function getSuggestions(skillsList) {
    axios
      .post("/api/admin/get-skills-suggestions", { skillsList })
      .then((res) => {
        setShowSuggestions(true);
        setSkillSuggestions(res.data);
      })
      .catch((err) => console.log(err));
  }

  const skillAppendHandler = (skillDetails) => {
    if (skillsList.includes(skillDetails.skill)) {
      //show some error
      setMessage(`${skillDetails.skill} already added`);
      setOpenSnackbar(true);
    } else {
      setSkillsList([...skillsList, skillDetails.skill]);
    }
  };

  //profile view handler
  const viewProfileHandler = (item) => {
    localStorage.setItem("id", id);
    localStorage.setItem("jwtToken", token);
  };

  const scrollToTop = () => {
    animateScroll.scrollTo(0, {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: "search-result-body",
    });
  };

  function debugBase64(base64URL) {
    var win = window.open();
    win.document.write(
      '<iframe src="' +
        base64URL +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
  }

  const getFavorites = (userArray, allUsers) => {
    for (let i = 0; i < allUsers.length; i++) {
      if (userArray.length > 0) {
        for (let j = 0; j < userArray.length; j++) {
          if (allUsers[i]._id == userArray[j]._id) {
            const result = { ...allUsers[i], isFavorite: true };
            allUsers[i] = result;
            break;
          } else {
            allUsers[i] = { ...allUsers[i], isFavorite: false };
          }
        }
      } else {
        allUsers[i] = { ...allUsers[i], isFavorite: false };
      }
    }
    return allUsers;
  };

  const handleScheduleInterview = (firstName, lastName, email, id) => {
    scrollToElement(quickScheduleRef);
    setDisplayCard(false);
    const fullName = firstName + " " + lastName;
    setRightSidebarOpen(true);
    setOpen(false);
    setScheduleClicked(true);
    if (roleFilter === "candidate") {
      setCandidateName(fullName);
      setCandidateDetails({
        name: fullName,
        email,
        id,
      });
    } else {
      setScreenerName(fullName);
      setScreenerDetails({
        name: fullName,
        email,
        id,
      });
    }
  };

  //useEffect to set current Page
  useEffect(() => {
    // scrollFunction()
    scrollToTop();
  }, [currentPage]);

  //favoriteHandler
  const addToFavoriteHandler = (item) => {
    setFavLoader({ ...favLoader, [item._id]: true });
    const newData = {
      item,
      userId: id,
    };
    axios
      .post(
        `/api/admin/internal-users/favorite/${roleFilter}/${id}/${companyId}`,
        newData
      )
      .then((res) => {
        // axios.get(`/api/admin/${roleFilter}s/internal-${roleFilter}s/${id}`).then(res => {

        setMessage(res.data.message);
        setOpenSnackbar(true);
        const userId = id;
        axios
          .get(`/api/admin/favorites/${userId}/${roleFilter}/${id}`)
          .then((data) => {
            let userArray =
              roleFilter === "screener"
                ? data.data.screener
                : data.data.candidate;
            const resultData = getFavorites(userArray, users);
            setUsers(resultData);
            setFavLoader({ ...favLoader, [item._id]: false });
          });
      });
  };

  const getAllUsers = async (fetchCondition) => {
    if (fetchCondition != "intervalFetch") setIsLoading(true);
    let userId = id;
    //fetching screeners and candidate from internal and normal db
    if (usersDB && !internalDB) {
      // get internal and normal users
      let allUsers = await axios.get(
        `/api/admin/get-internal-and-normal-users/${localStorage
          .getItem("role")
          .toLocaleLowerCase()}/${id}/${userId}`
      );
      if (allUsers.data.length) {
        // if (fetchCondition != "intervalFetch")
        setUsers([]);
        allUsers = allUsers.data.reverse();
        setFilterArray(allUsers);
        setIsLoading(false);
      }
    }
    // fetching screeners and candidates from internal db only
    else if (!usersDB && internalDB) {
      let allUsers = await axios.get(
        `/api/admin/get-internal-users/${localStorage
          .getItem("role")
          .toLocaleLowerCase()}/${id}/${userId}`
      );
      if (allUsers.data.length) {
        // if (fetchCondition != "intervalFetch")
        setUsers([]);
        allUsers = allUsers.data
          .filter((cand) => cand.requestStatus !== "Accepted")
          .reverse();
        setFilterArray(allUsers);
        setIsLoading(false);
      }
    }
    // fetching screeners and candidates from normal db
    else if (!usersDB && !internalDB) {
      let allUsers = await axios.get(
        `/api/admin/get-normal-users/${localStorage
          .getItem("role")
          .toLocaleLowerCase()}/${id}/${userId}`
      );
      if (allUsers.data.length) {
        // if (fetchCondition != "intervalFetch")
        setUsers([]);
        allUsers = allUsers.data
          .filter((cand) => cand.requestStatus !== "Accepted")
          .reverse();
        setFilterArray(allUsers);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getAllUsers("");
  }, [roleFilter, usersDB, internalDB]);

  // useEffect(() => {
  //     checkExpandHandler();
  //     resetHandler();
  // }, [roleFilter]);

  //useEffect while running expand
  useEffect(() => {
    checkExpandHandler();
  }, [expandAll, errors.experience]);

  //Tab handler
  const handleChangeRoleFilter = (event, role) => {
    if (role === null) {
      setRoleFilter(roleFilter);
    } else {
      setRoleFilter(role);
    }
    if (role == "candidate") {
      setCandidateSelected(true);
    } else if (role === "screener") {
      setCandidateSelected(false);
    } else {
      setCandidateSelected(candidateSelected);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const copyEmailToClipboard = async () => {
    try {
      setMessage("Email copied to clipboard");
      setOpenSnackbar(true);
    } catch (err) {
      setMessage("Failed to copy Email");
      setOpenSnackbar(true);
    }
  };

  const copyTextToClipboard = async (text) => {
    try {
      setMessage("Link copied to clipboard");
      setOpenSnackbar(true);
    } catch (err) {
      setMessage("Failed to copy Link");
      setOpenSnackbar(true);
    }
  };

  //roleHandler
  const roleHandler = (roleName, selected) => {
    setRoleFilter(roleName);
    setCandidateSelected(selected);
  };

  //favorite handler
  const favoriteHandler = (role, navigateTo) => {
    localStorage.setItem("role", role);
    localStorage.setItem("navigateTo", navigateTo);
    navigate("/admin/favorites");
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("title");
    localStorage.removeItem("candidate");
    localStorage.removeItem("screener");
    localStorage.removeItem("link");
    localStorage.removeItem("meetingPlatform");
    localStorage.removeItem("date");
    localStorage.removeItem("time");
    localStorage.removeItem("zone");
    localStorage.removeItem("role");
    localStorage.removeItem("candidateDetails");
    localStorage.removeItem("screenerDetails");
    localStorage.removeItem("navigateTo");
    localStorage.removeItem("scheduled");
    localStorage.removeItem("client");
    localStorage.removeItem("position");
    localStorage.removeItem("clientDetails");
    localStorage.removeItem("positionDetails");
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  function isValidBooleanQuery(query) {
    const stack = [];
    const quoteStack = [];

    for (let i = 0; i < query.length; i++) {
      const char = query[i];

      if (char === "(") {
        stack.push(char);
      } else if (char === ")") {
        if (stack.length === 0 || stack[stack.length - 1] !== "(") {
          return false;
        }
        stack.pop();
      } else if (char === "'" || char === '"') {
        if (
          quoteStack.length > 0 &&
          quoteStack[quoteStack.length - 1] === char
        ) {
          quoteStack.pop();
        } else {
          quoteStack.push(char);
        }
      }
    }

    return stack.length === 0 && quoteStack.length === 0;
  }

  const handleBooleanSearch = () => {
    setSkillsExpand(true);
    setBooleanSearch((prev) => !prev);
  };

  const usersDbHandler = () => {
    // if (!internalDB) {

    setDatabaseExpand(true);

    setUsersDB((prev) => !prev);
    // } else {
    setInternalDB(false);
    // }
  };

  const internalDbHandler = () => {
    // if (!internalDB) {
    setUsersDB(false);
    // }
    setDatabaseExpand(true);
    setInternalDB((prev) => !prev);
  };

  // const handleSwitchChange = (e) => {
  //     e.stopPropagation();
  // }

  //Accordion fields expand handler
  const expandHandler = (e) => {
    setExpandAll(e.target.checked);
  };

  const checkExpandHandler = () => {
    if (expandAll) {
      setPersonalDetailsExpand(true);
      setSalaryExpand(true);
      setExperienceExpand(true);
      setEducationExpand(true);
      setLocationExpand(true);
      setSkillsExpand(true);
      setCurrentCompanyExpand(true);
      setCurrentDesignationExpand(true);
      setDatabaseExpand(true);
    } else {
      if (errors.experience) {
        setPersonalDetailsExpand(false);
        setSalaryExpand(false);
        setExperienceExpand(true);
        setEducationExpand(false);
        setLocationExpand(false);
        setSkillsExpand(false);
        setCurrentCompanyExpand(false);
        setCurrentDesignationExpand(false);
        setDatabaseExpand(false);
      } else {
        setPersonalDetailsExpand(false);
        setSalaryExpand(false);
        setExperienceExpand(false);
        setEducationExpand(false);
        setLocationExpand(false);
        setSkillsExpand(false);
        setCurrentCompanyExpand(false);
        setCurrentDesignationExpand(false);
        setDatabaseExpand(false);
      }
    }
  };

  // Jitsi meeting link
  const generateJitsiLink = () => {
    try {
      setMeetingPlatform("Jitsi");
      const meetId = uuidv4();
      const meetingURL = `${JITSI_URL}/${meetId}`;
      setInterviewLink(meetingURL);
    } catch (err) {
      setMessage("Jitsi server is busy at the moment.");
      setOpenSnackbar(true);
    }
  };

  // Zoom meeting link
  const generateLinkHandler = () => {
    setLinkLoader(true);
    setMeetingPlatform("Zoom");
    setInterviewLink("Loading...");
    axios
      .get(`/api/schedule/generate`)
      .then((res) => {
        setLinkLoader(false);
        setInterviewLink(res.data.join_url);
        setCustomLink(false);
      })
      .catch((err) => {
        setMessage("Zoom server is busy at the moment.");
        setOpenSnackbar(true);
        setLinkLoader(false);

        setCustomLink(false);
      });
  };

  // Custom link
  const customLinkHandler = () => {
    setMeetingPlatform("Custom");
    setCustomLink((c) => true);
    setInterviewLink("");

    if (meetLinkRef.current) {
      // Set focus using setTimeout to ensure it's focused on the next render cycle
      setTimeout(() => {
        meetLinkRef.current.focus();
      }, 0);
    }
  };

  const handleTimeChange = (value, field) => {
    if (field == "hours") {
      setHours(value);
      setTime(`${value}:${minutes} ${meridian}`);
    } else if (field == "minutes") {
      setMinutes(value);
      setTime(`${hours}:${value} ${meridian}`);
    } else {
      setMeridian(value);
      setTime(`${hours}:${minutes} ${value}`);
    }
  };

  const dateHandler = (e) => {
    setDate(e.target.value);
  };

  //closeHandler Function for handling close states
  const closeHandler = (role) => {
    if (candidateName && screenerName) {
      if (role === "candidate") {
        setCandidateName("");
      } else {
        setScreenerName("");
      }
    } else {
      setDialogOpen(true);
    }
  };

  const closeAllHandler = () => {
    setDialogOpen(true);
  };

  const cancelInterview = () => {
    setDisplayCard(true);
    setRightSidebarOpen(false);
    setScheduleClicked(false);
    setDialogOpen(false);
    setInterviewTitle("");
    setInterviewLink("");
    setMeetingPlatform("");
    setCandidateName("");
    setScreenerName("");
    setClientName("");
    setPositionName("");
    setZone("Asia/Kolkata");
    setTime("12:15 PM");
    setDate("");
    setCandidateDetails({
      name: "",
      id: "",
      email: "",
    });
    setScreenerDetails({
      name: "",
      id: "",
      email: "",
    });
    setClientDetails({
      name: "",
      id: "",
      email: "",
    });
    setPositionDetails({
      name: "",
      id: "",
    });
    clearLocalStorage();
  };

  //interviewSchedulers
  const saveHandler = () => {
    localStorage.removeItem("title");
    if (!interviewTitle) {
      //error for title
      setMessage("Please fill the interview title");
      setOpenSnackbar(true);
    } else if (!screenerName && !candidateName) {
      setMessage("Please fill either candidate or screener name");
      setOpenSnackbar(true);
    } else if (clientName && !clientDetails.id) {
      setMessage("Please choose one among the list of Clients");
      setOpenSnackbar(true);
    } else if (positionName && !positionDetails.id) {
      setMessage("Please choose one among the list of Positions");
      setOpenSnackbar(true);
    } else {
      const newData = {
        candidateDetails,
        companyId,
        id,
        screenerDetails,
        interviewTitle,
        interviewLink,
        meetingPlatform,
        zone,
        date,
        time,
        interviewStatus: "To Schedule",
        scheduledBy: id,
        role: role,
        clientDetails,
        positionDetails,
        rescheduledBy: { id: "", name: "", email: "" },
        declinedBy: { id: "", name: "", email: "" },
        // candidateSelectedDatabaseType,
        // screenerSelectedDatabaseType
      };

      axios
        .post("/api/schedule/scheduleinterview", { newData })
        .then((res) => {
          setIsLocalClear(true);
          cancelInterview();
          setMessage("Interview Saved for Later");
          setOpenSnackbar(true);
        })
        .catch((err) => {
          setMessage("Oops... There is some error");
          setOpenSnackbar(true);
        });
    }
  };

  const scheduleHandler = () => {
    if (
      interviewTitle &&
      interviewLink &&
      candidateName &&
      screenerName &&
      time &&
      zone &&
      date
    ) {
      if (clientName && !clientDetails.id) {
        setMessage("Please choose one among the list of Clients");
        setOpenSnackbar(true);
        return;
      } else if (positionName && !positionDetails.id) {
        setMessage("Please choose one among the list of Positions");
        setOpenSnackbar(true);
        return;
      }
      const newData = {
        candidateDetails,
        companyId,
        id,
        screenerDetails,
        interviewTitle,
        interviewLink,
        meetingPlatform,
        zone,
        date,
        time,
        interviewStatus: "Scheduled",
        scheduledBy: id,
        role: role,
        clientDetails,
        positionDetails,
        rescheduledBy: { id: "", name: "", email: "" },
        declinedBy: { id: "", name: "", email: "" },
        // candidateSelectedDatabaseType,
        // screenerSelectedDatabaseType
      };
      axios
        .post("/api/schedule/scheduleinterview", { newData })
        .then((res) => {
          setMessage("Interview Scheduled successfully!");
          setOpenSnackbar(true);
          cancelInterview();
          setIsLocalClear(true);
        })
        .catch((err) => {
          setMessage("Oops... There is some error");
          setOpenSnackbar(true);
        });
    } else {
      //show a dialogue saying there is some error
      setMessage("Please fill all the fields");
      setOpenSnackbar(true);
    }
  };

  const openArrowHandler = () => {
    console.log("open arrow handler,display cad set to false",openArrowHandler);
    setDisplayCard(false);
    setRightSidebarOpen((prev) => !prev);
    setOpen(false);
  };

  const closeArrowHandler = () => {
    console.log("close arrow handler,display cad set to false",closeArrowHandler);
    setDisplayCard(true);
    setRightSidebarOpen((prev) => !prev);
  };

  //filter form handlers
  const handleSalary = (e) => {
    const limit = 7;
    setSalary(e.target.value.slice(0, limit));
    if (payType == "As per industry standards") {
      setPayType("");
    }
    setHighlight(false);
  };

  //add skill to array
  const addSkill = (e, skill) => {
    e.preventDefault();
    if (skill) {
      setSkillsList([...skillsList, skill]);
      setSkill("");
      setValue("");
      setClear((prev) => !prev);
    }
  };

  //reset button handler
  const resetHandler = () => {
    setUsers(filterArray);
    setFirstName("");
    setLastName("");
    setSkill("");
    setValue("");
    setClear((prev) => !prev);
    setEmail("");
    setGender("");
    setCompany("");
    setDegree("");
    setStream("");
    setFromExperience("");
    setToExperience("");
    setInitialLocation({
      country: null,
      state: null,
      city: null,
      countryId: 0,
      stateId: 0,
    });
    setSkillsList([]);
    setBooleanSkillsList([]);
    setBooleanSearch(false);
    setSalary("");
    setDesignation("");
    setSearchQuery("");
    setPayType("");
    setErrors({
      experience: "",
    });
    setfilterPrevState({
      skills: [],
      designation: "",
      experience: { from: "", to: "" },
      expectedSalary: { salary: "", payType: "" },
      location: { country: "", state: "", city: "" },
      personaldetails: { firstName: "", lastName: "", email: "", gender: "" },
      company: "",
      highesteducation: { degree: "", stream: "" },
    });
    setPersonalDetailsExpand(false);
    setSalaryExpand(false);
    setExperienceExpand(false);
    setEducationExpand(false);
    setLocationExpand(false);
    setSkillsExpand(false);
    setCurrentCompanyExpand(false);
    setCurrentDesignationExpand(false);
    setDatabaseExpand(false);
    setExpandAll(false);
    setUsersDB(true);
    setInternalDB(false);
  };

  function splitQuery(queryString) {
    const pattern = /\(|\)|\bAND\b|\bOR\b|\bNOT\b|"[^"]*"|\b[^()\s]+/g;
    const tokens = queryString.match(pattern);
    return tokens;
  }

  function filterBooleanSkills(queryString) {
    // splitting query based on space , quotes and operators
    if (queryString.length) {
      let query = splitQuery(queryString);
      query = query.map((item) => item.replace(/['"]+/g, ""));
      let newArr = query.filter(
        (ele) =>
          ele.toLowerCase() != "not" &&
          ele.toLowerCase() != "and" &&
          ele.toLowerCase() != "or" &&
          ele != "(" &&
          ele != ")"
      );
      return newArr;
    } else return [];
  }

  useEffect(() => {
    filterHandler("intervalFetch");
  }, [filterArray]);

  //filterHandler
  const filterHandler = (filterCondition) => {
    console.log("filter condition",filterCondition);
    console.log("filter handler");
    if (filterCondition != "intervalFetch") {
      scrollToElement(resultBodyRef);
      handlePageChange({ selected: 0 });
    }

    if (
      !firstName &&
      !lastName &&
      !email &&
      !gender &&
      initialLocation.country === null &&
      !designation &&
      !fromExperience &&
      !toExperience &&
      !salary &&
      !payType &&
      !company &&
      !degree &&
      !stream &&
      skillsList.length === 0 &&
      !searchQuery
    ) {
      if (filterCondition != "intervalFetch") setOpenSnackbar(true);
      setMessage("Please add atleast one search parameter!");
    } else {
      if (booleanSearch) {
        const result = isValidBooleanQuery(searchQuery);
        if (result == 0) {
          if (filterCondition != "intervalFetch") setOpenSnackbar(true);
          setMessage("Please check the query string!");
        }
      }
      setHighlight(true);
      booleanSearch
        ? setfilterPrevState({
            skills: booleanSkillsList,
            designation: designation,
            experience: { from: fromExperience, to: toExperience },
            expectedSalary: { salary: salary, payType: payType },
            location: {
              country: initialLocation.country,
              state: initialLocation.state,
              city: initialLocation.city,
            },
            personaldetails: {
              firstName: firstName,
              lastName: lastName,
              email: email,
              gender: gender,
            },
            company: company,
            highesteducation: { degree: degree, stream: stream },
          })
        : setfilterPrevState({
            skills: skillsList,
            designation: designation,
            experience: { from: fromExperience, to: toExperience },
            expectedSalary: { salary: salary, payType: payType },
            location: {
              country: initialLocation.country,
              state: initialLocation.state,
              city: initialLocation.city,
            },
            personaldetails: {
              firstName: firstName,
              lastName: lastName,
              email: email,
              gender: gender,
            },
            company: company,
            highesteducation: { degree: degree, stream: stream },
          });

      let filtered = filterArray.map((user) => {
        return { ...user, matchPercentage: 100 };
      });

      filtered = filtered.filter(
        (item) => item.accountStatus && item.primaryPhone
      );

      //firstName filter
      firstName &&
        (filtered = filtered
          .filter((item) => {
            if (
              firstName?.toLowerCase().trim() ===
              item.firstName?.toLowerCase().trim()
            ) {
              return true;
            } else if (
              item.firstName
                ?.toLowerCase()
                .includes(firstName?.toLowerCase().trim())
            ) {
              item.matchPercentage -= 10;
              return true;
            }
            return false;
          })
          .reverse());

      // lastName filter
      lastName &&
        (filtered = filtered
          .filter((item) => {
            if (
              lastName?.toLowerCase().trim() ===
              item.lastName?.toLowerCase().trim()
            ) {
              return true;
            } else if (
              item.lastName
                ?.toLowerCase()
                .includes(lastName?.toLowerCase().trim())
            ) {
              item.matchPercentage -= 10;
              return true;
            }
            return false;
          })
          .reverse());

      //email filter
      email &&
        (filtered = filtered
          .filter((item) => {
            if (
              email?.toLowerCase().trim() === item.email?.toLowerCase().trim()
            ) {
              return true;
            } else if (
              item.email?.toLowerCase().includes(email?.toLowerCase().trim())
            ) {
              item.matchPercentage -= 10;
              return true;
            }
            return false;
          })
          .reverse());

      //gender filter
      gender &&
        (filtered = filtered.filter((item) => {
          return (
            gender?.toLowerCase() ===
            item.personal_details.gender?.toLowerCase().trim()
          );
        }));

      //designation filter
      designation &&
        (filtered = filtered
          .filter((item) => {
            if (
              designation?.toLowerCase().trim() ===
              item.profileTitle?.toLowerCase().trim()
            ) {
              return true;
            } else if (
              item.profileTitle
                ?.toLowerCase()
                .includes(designation?.toLowerCase().trim())
            ) {
              item.matchPercentage -= 10;
              return true;
            }
            return false;
          })
          .reverse());

      //company filter
      company &&
        (filtered = filtered.filter((item) => {
          if (
            item.job_experience?.find(
              (exp) =>
                exp.checked &&
                exp.company?.toLowerCase() == company?.toLowerCase().trim()
            )
          ) {
            return true;
          } else if (
            item.job_experience?.find(
              (exp) =>
                exp.checked &&
                exp.company
                  ?.toLowerCase()
                  .includes(company?.toLowerCase().trim())
            )
          ) {
            item.matchPercentage -= 10;
            return true;
          }
          return false;
        }));

      //degree and stream filter
      filtered = filtered.filter((item) => {
        if (degree && stream) {
          if (
            item.educational_details?.find(
              (edu) =>
                edu.degree?.toLowerCase() == degree.toLowerCase().trim() &&
                edu.stream?.toLowerCase() == stream?.toLowerCase().trim()
            )
          ) {
            return true;
          }
          if (
            item.educational_details?.find(
              (edu) => edu.degree?.toLowerCase() == degree.toLowerCase().trim()
            ) &&
            item.educational_details?.find((edu) =>
              edu.stream?.toLowerCase().includes(stream?.toLowerCase().trim())
            )
          ) {
            item.matchPercentage -= 10;
            return true;
          }
          if (
            item.educational_details?.find((edu) =>
              edu.degree?.toLowerCase().includes(degree?.toLowerCase().trim())
            ) &&
            item.educational_details?.find(
              (edu) => edu.stream?.toLowerCase() == stream?.toLowerCase().trim()
            )
          ) {
            item.matchPercentage -= 10;
            return true;
          }
          if (
            item.educational_details?.find((edu) =>
              edu.degree?.toLowerCase().includes(degree?.toLowerCase().trim())
            ) &&
            item.educational_details?.find((edu) =>
              edu.stream?.toLowerCase().includes(stream?.toLowerCase().trim())
            )
          ) {
            item.matchPercentage -= 20;
            return true;
          }
          return false;
        } else if (degree) {
          if (
            item.educational_details?.find(
              (edu) => edu.degree?.toLowerCase() == degree?.toLowerCase().trim()
            )
          ) {
            return true;
          } else if (
            item.educational_details?.find((edu) =>
              edu.degree?.toLowerCase().includes(degree?.toLowerCase().trim())
            )
          ) {
            item.matchPercentage -= 10;
            return true;
          }
          return false;
        } else if (stream) {
          if (
            item.educational_details?.find(
              (edu) => edu.stream?.toLowerCase() == stream?.toLowerCase().trim()
            )
          ) {
            return true;
          } else if (
            item.educational_details?.find((edu) =>
              edu.stream?.toLowerCase().includes(stream?.toLowerCase().trim())
            )
          ) {
            item.matchPercentage -= 10;
            return true;
          }
          return false;
        } else return item;
      });

      //salary and paytype filter
      filtered = filtered.filter((item) => {
        if (salary && payType) {
          if (payType === "As per industry standards") {
            return item.salaryDetail?.salary.includes(payType.trim());
          } else
            return (
              item.salaryDetail?.salary == salary &&
              item.salaryDetail?.payType.includes(payType.trim())
            );
        } else if (salary) {
          return item.salaryDetail?.salary == salary;
        } else if (payType) {
          if (payType === "As per industry standards") {
            return item.salaryDetail?.salary.includes(payType.trim());
          } else return item.salaryDetail?.payType.includes(payType.trim());
        } else return item;
      });

      //from and to experience filter
      filtered = filtered
        .filter((item) => {
          if (fromExperience && toExperience) {
            if (Number(fromExperience) > Number(toExperience)) {
              setErrors((prev) => ({
                ...prev,
                experience:
                  "From experience cannot be greater than To experience",
              }));
              return item;
            } else
              return (
                item?.totalExperience?.charAt(0) >= fromExperience &&
                item?.totalExperience?.charAt(0) <= toExperience
              );
          } else if (fromExperience) {
            return item?.totalExperience?.charAt(0) >= fromExperience;
          } else if (toExperience) {
            return (
              item?.totalExperience?.charAt(0) >= 0 &&
              item?.totalExperience?.charAt(0) <= toExperience
            );
          } else return item;
        })
        .sort((a, b) => a.totalExperience - b.totalExperience);

      //country filter
      initialLocation.country?.label &&
        (filtered = filtered.filter((item) => {
          if (
            item.personal_details.location?.country
              ?.toLowerCase()
              .includes(initialLocation.country.label.toLowerCase().trim())
          ) {
            return true;
          }
          return false;
        }));

      // state filter
      initialLocation.state?.label &&
        (filtered = filtered.filter((item) => {
          if (
            item.personal_details.location?.state
              ?.toLowerCase()
              .includes(initialLocation.state.label.toLowerCase().trim())
          ) {
            return true;
          }
          return false;
        }));

      //city filter
      initialLocation.city?.label &&
        (filtered = filtered.filter((item) => {
          if (
            item.personal_details.location?.city
              ?.toLowerCase()
              .includes(initialLocation.city.label.toLowerCase().trim())
          ) {
            return true;
          }
          return false;
        }));

      // skills filter
      const skillsListLowerCase = skillsList?.map((skill) =>
        skill.toLowerCase().trim()
      );
      const skillsLength = skillsListLowerCase.length;
      {
        !booleanSearch
          ? (filtered = filtered
              .filter((item, index) => {
                if (skillsListLowerCase.length > 0) {
                  const skillsFilter = item.skillArray?.filter((skill) => {
                    return (
                      skillsListLowerCase.indexOf(
                        skill.toLowerCase().trim()
                      ) !== -1
                    );
                  });

                  if (skillsFilter.length > 0) {
                    if (skillsFilter.length !== skillsLength) {
                      item.matchPercentage =
                        item.matchPercentage -
                        2 * Math.ceil(skillsLength / skillsFilter.length);
                    }
                  } else return false;
                }
                return true;
              })
              .sort((a, b) => b.matchPercentage - a.matchPercentage))
          : booleanSearch && searchQuery.length
          ? (filtered = filtered.filter((item) => {
              let result = evaluateBooleanQuery(searchQuery, item.skillArray);
              return result;
            }))
          : (filtered = filtered);
      }
      let booleanSkills = [];
      if (booleanSearch) {
        booleanSkills = filterBooleanSkills(searchQuery);
        setBooleanSkillsList(booleanSkills);
      }

      axios
        .post("/api/admin/search-suggestions", { skillsListLowerCase })
        .then((res) => {})
        .catch((err) => console.log(err));

      if (filtered.length == 0) {
        let filtered = filterArray.map((user) => {
          return { ...user, matchPercentage: 0 };
        });

        // skills filter
        const skillsListLowerCase = finalSuggestions?.map((skill) =>
          skill.toLowerCase().trim()
        );
        const skillsLength = skillsListLowerCase.length;
        filtered = filtered
          .filter((item, index) => {
            if (skillsListLowerCase.length > 0) {
              const skillsFilter = item.skillArray?.filter((skill) => {
                return (
                  skillsListLowerCase.indexOf(skill.toLowerCase().trim()) !== -1
                );
              });

              if (skillsFilter.length > 0) {
                if (skillsFilter.length !== skillsLength) {
                  item.matchPercentage =
                    item.matchPercentage -
                    2 * Math.ceil(skillsLength / skillsFilter.length);
                }
              } else return false;
            }
            return true;
          })
          .sort((a, b) => b.matchPercentage - a.matchPercentage);

        setShowPercentage(false);
        !booleanSearch && getSuggestions(skillsListLowerCase);
        booleanSearch && getSuggestions(booleanSkills);
        setShowSuggestions(true);
        setUsers(filtered);
      } else {
        setShowPercentage(true);
        setUsers(filtered);
        //get other suggestions for the skills
        !booleanSearch && getSuggestions(skillsListLowerCase);
        booleanSearch && getSuggestions(booleanSkills);
        setShowSuggestions(true);
      }
    }
  };

  return (
    <div>
      <CompanyMenu
        open={open}
        setOpen={setOpen}
        sidebarOpen={sidebarOpen}
        setsidebarOpen={setsidebarOpen}
        companyName={companyName}
        croppedImg={croppedImg}
        profileImage={profileImage}
        interacted={interacted}
        defaultAvatar={defaultAvatar}
      />

      <div
        style={{
          marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}`,
        }}
      >
        <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
          {/* Top card */}
          <Card id="main-edit" className="add-recruiter-form form-card">
            <Grid
              container
              spacing={0}
              // direction="row"
              className="add-recruiter-card-container"
            >
              <Grid
                item
                xs={12}
                sm={sidebarOpen === "sidebar-open" ? 5 : 4}
                md={sidebarOpen === "sidebar-open" ? 5 : 4}
                lg={4}
                className="container-heading-title admin-table"
              >
                <h3>
                  Search{" "}
                  {` > ${
                    roleFilter === "candidate" ? "Job Seeker" : "Screener"
                  } `}
                </h3>
              </Grid>
              <Grid
                item
                xs={12}
                sm={sidebarOpen === "sidebar-open" ? 6.5 : 7}
                md={sidebarOpen === "sidebar-open" ? 6.5 : 7}
                lg={6}
                className="container-heading-buttons add-recruiter-button"
              >
                <div className="main-button-container">
                  <div className="top-card-button-container">
                    <ToggleButtonGroup
                      value={roleFilter}
                      exclusive
                      onChange={handleChangeRoleFilter}
                      aria-label="Platform"
                      className="toggle-btn-group"
                    >
                      <ToggleButton
                        value="candidate"
                        selected={candidateSelected}
                        className={
                          roleFilter === "candidate"
                            ? "Mui-selected btn-ripple"
                            : "toggle-role-btn"
                        }
                        sx={{
                          background: "#5050502e",
                          color: "#505050",
                          padding: "3px 7px",
                          // fontSize: "11px",
                          fontFamily: "Raleway",
                          fontWeight: "600",
                          textTransform: "none",
                          border: "none",
                        }}
                      >
                        Job Seeker
                      </ToggleButton>
                      <ToggleButton
                        selected={!candidateSelected}
                        className={
                          roleFilter === "screener"
                            ? "Mui-selected "
                            : "toggle-role-btn"
                        }
                        value="screener"
                        sx={{
                          background: "#5050502e",
                          color: "#505050",
                          padding: "3px 7px",
                          // fontSize: "11px",
                          fontWeight: "600",
                          border: "none",
                          fontFamily: "Raleway",
                          textTransform: "none",
                        }}
                      >
                        Screener
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>

          <div className="filter-bottom-flex search-flex-body">
            {/* Left Section */}
            <Card
              id="main-edit"
              className="filter-form-card table-card"
              style={{
                width: `${open ? "26%" : rightSidebarOpen ? "25%" : "30%"}`,
              }}
            >
              <Grid
                container
                spacing={0}
                direction="row"
                // className="add-recruiter-card-container"
              >
                <Grid
                  item
                  xs={5}
                  sm={sidebarOpen === "sidebar-open" ? 12 : 4.5}
                  md={4}
                  lg={4}
                  className="container-heading-title"
                >
                  <FilterIcon fontSize="small" sx={{ color: "#2c3e50" }} />
                  <h5>Filters</h5>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={sidebarOpen === "sidebar-open" ? 12 : 6}
                  md={7}
                  lg={7}
                  className={`container-heading-title advanced-filter-form ${
                    sidebarOpen === "sidebar-open"
                      ? "align-toggle-left"
                      : "align-toggle-right"
                  }`}
                  style={{
                    margin: "15px 10px 10px 0px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      onChange={expandHandler}
                      value={expandAll}
                      checked={expandAll}
                      control={<Switch />}
                      label="Expand All"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Divider
                sx={{
                  borderColor: "lightgray",
                  borderBottomWidth: "1.5px",
                }}
                id="divider"
              />

              <Grid
                container
                spacing={0}
                direction="row"
                // className="add-recruiter-card-container"
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className="filter-form-body toggle-boolean-search"
                  sx={{ overflowY: "hidden" }}
                >
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    onChange={() => setSkillsExpand(!skillsExpand)}
                    expanded={skillsExpand}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Skills</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "0px 5px 0px 5px" }}
                        className="right-grid"
                      >
                        <FormGroup>
                          <FormControlLabel
                            className="boolean-search-switch"
                            onChange={handleBooleanSearch}
                            value={booleanSearch}
                            control={
                              <Switch size="small" checked={booleanSearch} />
                            }
                            label="Boolean Search"
                          />
                        </FormGroup>
                        <form noValidate onSubmit={addSkill}>
                          <div
                            className="input-container filter-container"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {!booleanSearch && (
                              <Autocomplete
                                sx={{
                                  "& fieldset": { border: "none" },
                                }}
                                value={skillsList}
                                onChange={(event, newValue) => {
                                  setSkillsList(newValue);
                                  setHighlight(false);
                                }}
                                multiple
                                id="tags-filled"
                                options={data.map((option) => option.skill)}
                                freeSolo
                                renderTags={(skillsList, getTagProps) =>
                                  skillsList.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField {...params} variant="filled" />
                                )}
                              />
                            )}
                            {booleanSearch && (
                              <textarea
                                rows="2"
                                className="skills-textarea"
                                value={searchQuery}
                                onChange={(e) => {
                                  setSearchQuery(e.target.value);
                                  setHighlight(false);
                                }}
                              />
                            )}
                          </div>
                        </form>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {/* Designation */}
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    onChange={() =>
                      setCurrentDesignationExpand(!currentDesignationExpand)
                    }
                    expanded={currentDesignationExpand}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Current Designation</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "0px 5px 0px 5px" }}
                        className="right-grid"
                      >
                        <form noValidate onSubmit={handleSubmit}>
                          <div className="input-container filter-container">
                            <div class="input-field-modal">
                              <input
                                type="text"
                                value={designation}
                                onChange={(e) => {
                                  setDesignation(e.target.value);
                                  setHighlight(false);
                                }}
                              />
                            </div>
                          </div>
                        </form>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  {/* Experience */}
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    onChange={() => setExperienceExpand(!experienceExpand)}
                    expanded={experienceExpand}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Total Experience (Years)</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "0px 5px 0px 5px" }}
                        className="right-grid"
                      >
                        <form noValidate onSubmit={handleSubmit}>
                          <div className="split-inputs">
                            <div class="input-field-modal">
                              <input
                                type="number"
                                placeholder="From"
                                value={fromExperience}
                                onChange={(e) => {
                                  setFromExperience(e.target.value);
                                  setErrors({
                                    experience: "",
                                  });
                                  setHighlight(false);
                                }}
                              />
                            </div>
                            <div class="input-field-modal">
                              <input
                                type="number"
                                placeholder="To"
                                class="position"
                                value={toExperience}
                                onChange={(e) => {
                                  setToExperience(e.target.value);
                                  setErrors({
                                    experience: "",
                                  });
                                  setHighlight(false);
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <span className="text-danger">
                              {errors.experience}
                            </span>
                          </div>
                        </form>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  {/* Salary */}
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    onChange={() => setSalaryExpand(!salaryExpand)}
                    expanded={salaryExpand}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Expected Salary (USD)</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "0px 5px 0px 5px" }}
                        className="right-grid"
                      >
                        <form noValidate onSubmit={handleSubmit}>
                          <div className="input-container filter-container">
                            <div className="split-inputs">
                              <div class="input-field-modal">
                                <input
                                  class="max-salary"
                                  value={salary}
                                  type="number"
                                  placeholder="Expected Salary"
                                  onChange={handleSalary}
                                />
                              </div>
                              <div class="input-field-modal">
                                <select
                                  style={{ outline: "none" }}
                                  value={payType || "default"}
                                  onChange={(e) => {
                                    if (
                                      e.target.value ==
                                      "As per industry standards"
                                    ) {
                                      setSalary("");
                                    }
                                    setPayType(e.target.value);
                                    setHighlight(false);
                                  }}
                                >
                                  <option
                                    value="default"
                                    selected
                                    disabled
                                    hidden
                                  >
                                    Pay Type
                                  </option>
                                  <option value="Hour">Hourly</option>
                                  <option value="Week">Weekly</option>
                                  <option value="Month">Monthly</option>
                                  <option value="Annum">Per Annum</option>
                                  <option value="As per industry standards">
                                    As Per Industry Standards
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </form>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  {/* Location */}
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    onChange={() => setLocationExpand(!locationExpand)}
                    expanded={locationExpand}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Location</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "0px 5px 0px 5px" }}
                        className="right-grid"
                      >
                        <form noValidate onSubmit={handleSubmit}>
                          <div className="input-container ">
                          <div class="input-field-modal">
                        <label className="date-label">Country*</label>
                        <Select
                          class="position"
                          type="text"
                          value={initialLocation.country}
                          options={updatedCountries}
                          onChange={(value) => {
                            setInitialLocation(
                              {
                                country: value,
                                countryId: value.isoCode,
                                state: null,
                                city: null,
                              },
                              false
                            );
                            setErrors({
                              country: "",
                            });
                          }}
                          placeholder="Country"
                        />
                        <span className="text-danger">{errors.country}</span>
                      </div>

                      <div class="input-field-modal">
                        <label className="date-label">State*</label>
                        <Select
                          class="position"
                          type="text"
                          placeholder="State"
                          options={updatedStates(
                            initialLocation.country
                              ? initialLocation.country
                              : null
                          )}
                          value={initialLocation.state}
                          onChange={(value) => {
                            setInitialLocation(
                              {
                                ...initialLocation,
                                state: value,
                                stateId: value.isoCode,
                                city: null,
                              },
                              false
                            );
                            setErrors({
                              state: "",
                            });
                          }}
                        />
                        <span className="text-danger">{errors.state}</span>
                      </div>
                      <div class="input-field-modal">
                        <label className="date-label">City*</label>
                        <Select
                          class="position"
                          type="text"
                          options={updatedCities(initialLocation.country
                            ? initialLocation.country
                            : null,
                            initialLocation.state
                              ? initialLocation.state
                              : null
                          )}
                          value={initialLocation.city}
                          onChange={(value) => {
                            setInitialLocation({
                              ...initialLocation,
                              city: value.isoCode,
                            });
                            setErrors({
                              city: "",
                            });
                          }}
                          placeholder="City"
                        />
                        <span className="text-danger">{errors.city}</span>
                      </div>
                          </div>
                        </form>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  {/* Personal Details */}
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    onChange={() =>
                      setPersonalDetailsExpand(!personalDetailsExpand)
                    }
                    expanded={personalDetailsExpand}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Personal Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "0px 5px 0px 5px" }}
                        className="right-grid"
                      >
                        <form noValidate onSubmit={handleSubmit}>
                          <div className="input-container filter-container">
                            <div class="input-field-modal">
                              <input
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                  setHighlight(false);
                                }}
                              />
                            </div>

                            <div class="input-field-modal">
                              <input
                                class="position"
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                  setHighlight(false);
                                }}
                              />
                            </div>

                            <div class="input-field-modal">
                              <input
                                class="email"
                                type="email"
                                placeholder="Email Id*"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  setHighlight(false);
                                }}
                              />
                            </div>

                            <div class="input-field-modal">
                              <select
                                style={{ outline: "none" }}
                                value={gender || "default"}
                                onChange={(e) => {
                                  setGender(e.target.value);
                                  setHighlight(false);
                                }}
                              >
                                <option
                                  value="default"
                                  selected
                                  disabled
                                  hidden
                                >
                                  Gender
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Prefer not to say">
                                  Prefer not to say
                                </option>
                              </select>
                            </div>
                          </div>
                        </form>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  {/* Company */}
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    onChange={() =>
                      setCurrentCompanyExpand(!currentCompanyExpand)
                    }
                    expanded={currentCompanyExpand}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Current Company</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "0px 5px 0px 5px" }}
                        className="right-grid"
                      >
                        <form noValidate onSubmit={handleSubmit}>
                          <div className="input-container filter-container">
                            <div class="input-field-modal">
                              <input
                                type="text"
                                placeholder="Ex: Microsoft"
                                value={company}
                                onChange={(e) => {
                                  setCompany(e.target.value);
                                  setHighlight(false);
                                }}
                              />
                            </div>
                          </div>
                        </form>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  {/* Education */}
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    onChange={() => setEducationExpand(!educationExpand)}
                    expanded={educationExpand}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Highest Education</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "0px 5px 0px 5px" }}
                        className="right-grid"
                      >
                        <form noValidate onSubmit={handleSubmit}>
                          <div className="input-container filter-container">
                            <div className="split-inputs">
                              <div class="input-field-modal">
                                <input
                                  class="max-salary"
                                  value={degree}
                                  type="text"
                                  placeholder="Highest Degree"
                                  onChange={(e) => {
                                    setDegree(e.target.value);
                                    setHighlight(false);
                                  }}
                                />
                              </div>
                              <div class="input-field-modal">
                                <input
                                  class="max-salary"
                                  value={stream}
                                  type="text"
                                  placeholder="Stream"
                                  onChange={(e) => {
                                    setStream(e.target.value);
                                    setHighlight(false);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>

                  {/* Other */}
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    onChange={() => {
                      setDatabaseExpand(!databaseExpand);
                    }}
                    expanded={databaseExpand}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Choose Database</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "0px 10px 10px" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ padding: "0px 5px 0px 5px" }}
                        className="right-grid"
                      >
                        <FormGroup>
                          <FormControlLabel
                            onChange={usersDbHandler}
                            value={usersDB}
                            control={
                              <Switch defaultChecked checked={usersDB} />
                            }
                            label="Infomatics Database"
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormControlLabel
                            onChange={internalDbHandler}
                            value={internalDB}
                            control={<Switch checked={internalDB} />}
                            label="Infomatics Database only"
                          />
                        </FormGroup>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>

              <Divider
                sx={{
                  borderColor: "lightgray",
                  borderBottomWidth: "1.5px",
                }}
                id="divider"
              />

              <Grid
                container
                spacing={0}
                direction="row"
                // className="add-recruiter-card-container"
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  className="filter-form-button-container"
                  sx={{
                    margin: "20px 10px 10px 0px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div className="filter-buttons navigation-btn-container ">
                    <button
                      className="btn-style  outlined-btn-animation outlined-sq-btn"
                      onClick={resetHandler}
                    >
                      {" "}
                      Reset
                    </button>

                    <button
                      className="btn-style filled-sq-btn filled-btn-animation"
                      onClick={() => filterHandler("")}
                    >
                      {" "}
                      <SearchIcon
                        fontSize="small"
                        sx={{ color: "white", height: "0.8em" }}
                      />
                      Search
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Card>

            {/* Search results */}
            <div
              id="search-result-body"
              ref={resultBodyRef}
              className={`search-result-body responsive ${
                displayCard ? "" : "card-body-display"
              }`}
            >
              {isLoading ? (
                <div
                  className={`loader-icon results-loader ${
                    rightSidebarOpen ? "custom-width" : ""
                  }`}
                >
                  <CircularProgress size="3.5rem" />
                </div>
              ) : users.length !== 0 ? (
                <div
                  className={`body-sections ${
                    rightSidebarOpen ? "custom-vw-width" : "custom-width"
                  }`}
                >
                  {/* Logic for component display */}

                  {/* show suggestions for normal skills search */}
                  {showSuggestions && !booleanSearch ? (
                    <div className="suggestion-container">
                      {skillsList.length == 1 && skillSuggestions.length > 0 ? (
                        <p className="suggestions-text">
                          {`People who searched for ${
                            skillsList[0].charAt(0).toUpperCase() +
                            skillsList[0].slice(1)
                          } also searched..`}
                        </p>
                      ) : skillsList.length > 1 &&
                        skillSuggestions.length > 0 ? (
                        <p className="suggestions-text">
                          {`People who searched for ${
                            skillsList[0].charAt(0).toUpperCase() +
                            skillsList[0].slice(1)
                          } and  ${
                            skillsList[1].charAt(0).toUpperCase() +
                            skillsList[1].slice(1)
                          } also searched..`}
                        </p>
                      ) : null}

                      <div className="suggested-skills-container">
                        {skillsList.length > 0 &&
                          skillSuggestions.map((item) => (
                            <button
                              className="skill-suggestion-btn"
                              onClick={() => skillAppendHandler(item)}
                            >
                              {item.skill}
                            </button>
                          ))}
                      </div>
                    </div>
                  ) : (
                    // show suggestions for boolean search
                    showSuggestions &&
                    booleanSearch && (
                      <div className="suggestion-container">
                        {booleanSkillsList.length == 1 &&
                        skillSuggestions.length > 0 ? (
                          <p className="suggestions-text">
                            {`People who searched for ${
                              booleanSkillsList[0].charAt(0).toUpperCase() +
                              booleanSkillsList[0].slice(1)
                            } also searched..`}
                          </p>
                        ) : booleanSkillsList.length > 1 &&
                          skillSuggestions.length > 0 ? (
                          <p className="suggestions-text">
                            {`People who searched for ${
                              booleanSkillsList[0].charAt(0).toUpperCase() +
                              booleanSkillsList[0].slice(1)
                            }, ${
                              booleanSkillsList[1].charAt(0).toUpperCase() +
                              booleanSkillsList[1].slice(1)
                            } also searched..`}
                          </p>
                        ) : null}

                        <div className="suggested-skills-container">
                          {booleanSkillsList.length > 0 &&
                            skillSuggestions.map((item) => (
                              <button className="skill-suggestion-btn remove-cursor">
                                {item.skill}
                              </button>
                            ))}
                        </div>
                      </div>
                    )
                  )}

                  {/* Show below text if there are no matching profiles */}
                  {!showPercentage && (
                    <p className="final-suggestions-text">
                      We couldn't find any matching profiles for your search.
                      Here are some <strong>suggestions</strong>.
                    </p>
                  )}
                  <div className="top-result-section">
                    <p>
                      About{" "}
                      <span
                        style={{
                          color: "#f06161",
                          fontWeight: "bold",
                        }}
                      >
                        {resLength}
                      </span>{" "}
                      out of {filterArray.length} results
                    </p>
                  </div>
                  <div
                    className={`result-cards-section ${
                      rightSidebarOpen ? "" : "inherit-width card-min-width"
                    }`}
                    // style={{ width: `${rightSidebarOpen ? "" : "inherit-width"}`, minWidth: `${rightSidebarOpen ? "" : "card-min-width"}` }}
                  >
                    {users
                      .slice(offset, offset + usersPerPage)
                      .map((item, index) => {
                        const sortedExperience = [...item.job_experience].sort(
                          (a, b) => b.startYear - a.startYear
                        );
                        const sortedEducation = [
                          ...item.educational_details,
                        ].sort((a, b) => b.startYear - a.startYear);

                        let unreadCount =
                          item.notes?.length &&
                          item.notes?.filter((note) => !note.read).length;

                        return (
                          <Card
                            id="skills-edit"
                            className={`result-card ${
                              rightSidebarOpen
                                ? "width-sidebaropen"
                                : "width-sidebarclose"
                            }`}
                            style={{
                              // width: "95%",
                              // minWidth: `${rightSidebarOpen ? "550px" : "675px"}`,
                              margin: `${
                                rightSidebarOpen
                                  ? "0px 0px 40px 0px"
                                  : "0px 0px 40px 0px"
                              }`,
                              borderRadius: "10px",
                              boxShadow:
                                "0 0 0 rgb(0 0 0 / 25%), 0px 2px 6px rgb(0 0 0 / 22%)",
                              fontFamily: "Raleway",
                            }}
                          >
                            <Grid
                              container
                              spacing={0}
                              // direction="row"
                              // className="add-recruiter-card-container"
                              className="favorites-card-content search-card-content"
                              sx={{ position: "relative" }}
                            >
                              {showPercentage && item.matchPercentage && (
                                <div className="match-percentage-div">
                                  <div style={{ color: "#f06161" }}>
                                    <CircularProgressbarWithChildren
                                      value={item.matchPercentage}
                                      strokeWidth={4}
                                    >
                                      <div
                                        className="matching-percentage-text"
                                        style={{
                                          marginTop: -1,
                                          textAlign: "center",
                                        }}
                                      >
                                        <strong>{item.matchPercentage}%</strong>
                                      </div>
                                    </CircularProgressbarWithChildren>
                                  </div>
                                </div>
                              )}

                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={10.5}
                                lg={10.5}
                                className="container-heading-title"
                                // sx={{ margin: "15px 0px" }}
                              >
                                <Grid
                                  container
                                  spacing={0}
                                  // direction="row"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={4}
                                    lg={4}
                                    className="profile-personal-info-section"
                                  >
                                    <div className="fav-image-container">
                                      <img
                                        style={{
                                          objectFit: "cover",
                                          height: "50px",
                                          width: "50px",
                                          borderRadius: "100%",
                                        }}
                                        src={
                                          item.defaultAvatar
                                            ? profileAvatar
                                            : item.interacted
                                            ? item.croppedImage
                                            : item.profileImage
                                        }
                                      />
                                      <h6
                                        style={{
                                          textAlign: "center",
                                          marginBottom: "10px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {highlight ? (
                                          <>
                                            {" "}
                                            <Highlighter
                                              highlightClassName="highlighter"
                                              textToHighlight={item.firstName}
                                              searchWords={[`${firstName}`]}
                                            />{" "}
                                            &nbsp;
                                            <Highlighter
                                              highlightClassName="highlighter"
                                              textToHighlight={item.lastName}
                                              searchWords={[`${lastName}`]}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <Highlighter
                                              highlightClassName="highlighter"
                                              textToHighlight={item.firstName}
                                              searchWords={[
                                                `${filterPrevState.personaldetails.firstName}`,
                                              ]}
                                            />{" "}
                                            &nbsp;
                                            <Highlighter
                                              highlightClassName="highlighter"
                                              textToHighlight={item.lastName}
                                              searchWords={[
                                                `${filterPrevState.personaldetails.lastName}`,
                                              ]}
                                            />
                                          </>
                                        )}
                                      </h6>

                                      {item.notes?.length ? (
                                        <div className="view-note-icon">
                                          <Tooltip
                                            title="View Note"
                                            placement="left"
                                          >
                                            {unreadCount > 0 ? (
                                              <Badge
                                                variant="dot"
                                                sx={{
                                                  "& .MuiBadge-dot": {
                                                    backgroundColor: "#f06161",
                                                    height: 5,
                                                    minWidth: 5,
                                                  },
                                                }}
                                              >
                                                <ViewNoteIcon
                                                  style={{ fontSize: "0.9rem" }}
                                                  onClick={() =>
                                                    viewNoteHandler(item)
                                                  }
                                                />
                                              </Badge>
                                            ) : (
                                              <ViewNoteIcon
                                                style={{ fontSize: "0.9rem" }}
                                                onClick={() =>
                                                  viewNoteHandler(item)
                                                }
                                              />
                                            )}
                                          </Tooltip>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="personal-info-container">
                                      <p>
                                        {highlight ? (
                                          <Highlighter
                                            highlightClassName="highlighter"
                                            textToHighlight={item.profileTitle}
                                            searchWords={[`${designation}`]}
                                          />
                                        ) : (
                                          <Highlighter
                                            highlightClassName="highlighter"
                                            textToHighlight={item.profileTitle}
                                            searchWords={[
                                              `${filterPrevState.designation}`,
                                            ]}
                                          />
                                        )}
                                      </p>
                                      {item.primaryPhone ? (
                                        <p>{item.primaryPhone}</p>
                                      ) : (
                                        ""
                                      )}
                                      {item.email.length > 20 ? (
                                        <Tooltip
                                          title={
                                            <>
                                              {item.email}
                                              <CopyToClipboard
                                                text={item.email}
                                                onCopy={copyEmailToClipboard}
                                              >
                                                <ContentCopyIcon
                                                  fontSize="small"
                                                  sx={{
                                                    fontSize: "14px",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </CopyToClipboard>
                                            </>
                                          }
                                        >
                                          <p>
                                            {highlight ? (
                                              <Highlighter
                                                highlightClassName="highlighter"
                                                textToHighlight={item.email}
                                                searchWords={[`${email}`]}
                                              />
                                            ) : (
                                              <Highlighter
                                                highlightClassName="highlighter"
                                                textToHighlight={item.email}
                                                searchWords={[
                                                  `${filterPrevState.personaldetails.email}`,
                                                ]}
                                              />
                                            )}
                                          </p>
                                        </Tooltip>
                                      ) : (
                                        <p>
                                          {highlight ? (
                                            <Highlighter
                                              highlightClassName="highlighter"
                                              textToHighlight={item.email}
                                              searchWords={[`${email}`]}
                                            />
                                          ) : (
                                            <Highlighter
                                              highlightClassName="highlighter"
                                              textToHighlight={item.email}
                                              searchWords={[
                                                `${filterPrevState.personaldetails.email}`,
                                              ]}
                                            />
                                          )}
                                        </p>
                                      )}
                                    </div>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    // sx={{
                                    //     display: "flex",
                                    //     flexDirection: "column",
                                    //     marginTop: "10px"
                                    // }}
                                    className="fav-user-info-container"
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      md={10.7}
                                      lg={10.7}
                                      className="first-container"
                                      style={{
                                        textAlign: "initial",
                                      }}
                                    >
                                      {(item.totalExperience ||
                                        item.salaryDetail?.salary ||
                                        item.personal_details.location
                                          ?.country ||
                                        item.personal_details.location?.state ||
                                        item.personal_details.location
                                          ?.city) && (
                                        <div className="top-section">
                                          {item.totalExperience ? (
                                            <div>
                                              <>
                                                <ExperienceIcon
                                                  fontSize="small"
                                                  style={{
                                                    height: "0.7em",
                                                    width: "0.8em",
                                                    color: "#2c3e50",
                                                  }}
                                                />
                                                <p>
                                                  {highlight ? (
                                                    <Highlighter
                                                      highlightClassName="highlighter"
                                                      textToHighlight={
                                                        item.totalExperience +
                                                        " year(s)"
                                                      }
                                                      searchWords={generateYears(
                                                        fromExperience,
                                                        toExperience
                                                      )}
                                                    />
                                                  ) : (
                                                    <Highlighter
                                                      highlightClassName="highlighter"
                                                      textToHighlight={
                                                        item.totalExperience +
                                                        " year(s)"
                                                      }
                                                      searchWords={generateYears(
                                                        filterPrevState
                                                          .experience.from,
                                                        filterPrevState
                                                          .experience.to
                                                      )}
                                                    />
                                                  )}
                                                </p>
                                              </>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {item.salaryDetail?.salary ? (
                                            <div>
                                              {item.salaryDetail.salary ===
                                              "As per industry standards" ? (
                                                <>
                                                  <SalaryIcon
                                                    fontSize="small"
                                                    style={{
                                                      height: "0.7em",
                                                      width: "0.8em",
                                                      color: "#2c3e50",
                                                    }}
                                                  />
                                                  <p>
                                                    {highlight ? (
                                                      <Highlighter
                                                        highlightClassName="highlighter"
                                                        textToHighlight={
                                                          item.salaryDetail
                                                            .salary
                                                        }
                                                        searchWords={[
                                                          `${payType}`,
                                                        ]}
                                                      />
                                                    ) : (
                                                      <Highlighter
                                                        highlightClassName="highlighter"
                                                        textToHighlight={
                                                          item.salaryDetail
                                                            .salary
                                                        }
                                                        searchWords={[
                                                          `${filterPrevState.expectedSalary.payType}`,
                                                        ]}
                                                      />
                                                    )}
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <SalaryIcon
                                                    fontSize="small"
                                                    style={{
                                                      height: "0.7em",
                                                      width: "0.8em",
                                                      color: "#2c3e50",
                                                    }}
                                                  />
                                                  <p>
                                                    {highlight ? (
                                                      <>
                                                        <Highlighter
                                                          highlightClassName="highlighter"
                                                          textToHighlight={
                                                            item.salaryDetail
                                                              .salary + "/"
                                                          }
                                                          searchWords={[
                                                            `${salary}`,
                                                          ]}
                                                        />
                                                        <Highlighter
                                                          highlightClassName="highlighter"
                                                          textToHighlight={
                                                            item.salaryDetail
                                                              .payType
                                                          }
                                                          searchWords={[
                                                            `${payType}`,
                                                          ]}
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        <Highlighter
                                                          highlightClassName="highlighter"
                                                          textToHighlight={
                                                            item.salaryDetail
                                                              .salary + "/"
                                                          }
                                                          searchWords={[
                                                            `${filterPrevState.expectedSalary.salary}`,
                                                          ]}
                                                        />
                                                        <Highlighter
                                                          highlightClassName="highlighter"
                                                          textToHighlight={
                                                            item.salaryDetail
                                                              .payType
                                                          }
                                                          searchWords={[
                                                            `${filterPrevState.expectedSalary.payType}`,
                                                          ]}
                                                        />
                                                      </>
                                                    )}
                                                  </p>
                                                </>
                                              )}
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {item.personal_details.location
                                            ?.country ||
                                          item.personal_details.location
                                            ?.state ||
                                          item.personal_details.location
                                            ?.city ? (
                                            <div>
                                              <>
                                                <LocationIcon
                                                  fontSize="small"
                                                  style={{
                                                    height: "0.8em",
                                                    width: "0.8em",
                                                    color: "#2c3e50",
                                                  }}
                                                />
                                                <p>
                                                  {highlight ? (
                                                    <>
                                                      <Highlighter
                                                        highlightClassName="highlighter"
                                                        textToHighlight={
                                                          item.personal_details
                                                            .location?.country +
                                                          ", "
                                                        }
                                                        searchWords={[
                                                          `${initialLocation.country?.label}`,
                                                        ]}
                                                      />
                                                      <Highlighter
                                                        highlightClassName="highlighter"
                                                        textToHighlight={
                                                          item.personal_details
                                                            .location?.state +
                                                          ", "
                                                        }
                                                        searchWords={[
                                                          `${initialLocation.state?.label}`,
                                                        ]}
                                                      />
                                                      <Highlighter
                                                        highlightClassName="highlighter"
                                                        textToHighlight={
                                                          item.personal_details
                                                            .location?.city
                                                        }
                                                        searchWords={[
                                                          `${initialLocation.city?.label}`,
                                                        ]}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <Highlighter
                                                        highlightClassName="highlighter"
                                                        textToHighlight={
                                                          item.personal_details
                                                            .location?.country +
                                                          ", "
                                                        }
                                                        searchWords={[
                                                          `${filterPrevState.location.country?.label}`,
                                                        ]}
                                                      />
                                                      <Highlighter
                                                        highlightClassName="highlighter"
                                                        textToHighlight={
                                                          item.personal_details
                                                            .location?.state +
                                                          ", "
                                                        }
                                                        searchWords={[
                                                          `${filterPrevState.location.state?.label}`,
                                                        ]}
                                                      />
                                                      <Highlighter
                                                        highlightClassName="highlighter"
                                                        textToHighlight={
                                                          item.personal_details
                                                            .location?.city
                                                        }
                                                        searchWords={[
                                                          `${filterPrevState.location.city?.label}`,
                                                        ]}
                                                      />
                                                    </>
                                                  )}
                                                </p>
                                              </>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )}
                                      <div className="profile-info card-content">
                                        <table>
                                          {sortedExperience[0]?.company ? (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>Current</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p>
                                                  {sortedExperience[0]
                                                    .position + " at "}
                                                  {highlight ? (
                                                    <Highlighter
                                                      highlightClassName="highlighter"
                                                      textToHighlight={
                                                        sortedExperience[0]
                                                          .company
                                                      }
                                                      searchWords={[
                                                        `${company}`,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <Highlighter
                                                      highlightClassName="highlighter"
                                                      textToHighlight={
                                                        sortedExperience[0]
                                                          .company
                                                      }
                                                      searchWords={[
                                                        `${filterPrevState.company}`,
                                                      ]}
                                                    />
                                                  )}
                                                </p>
                                              </td>
                                            </tr>
                                          ) : (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>Current</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p></p>
                                              </td>
                                            </tr>
                                          )}

                                          {sortedExperience[1]?.company ? (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>Previous</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p>
                                                  {sortedExperience[1]
                                                    .position + " at "}
                                                  <Highlighter
                                                    highlightClassName="highlighter"
                                                    textToHighlight={
                                                      sortedExperience[1]
                                                        .company
                                                    }
                                                    searchWords={[`${company}`]}
                                                  />
                                                </p>
                                              </td>
                                            </tr>
                                          ) : (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>Previous</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p></p>
                                              </td>
                                            </tr>
                                          )}

                                          {sortedEducation[0]?.degree ? (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>Education</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p>
                                                  {sortedEducation[0].degree +
                                                    " in "}
                                                  {highlight ? (
                                                    <Highlighter
                                                      highlightClassName="highlighter"
                                                      textToHighlight={
                                                        sortedEducation[0]
                                                          .stream
                                                      }
                                                      searchWords={[
                                                        `${stream}`,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <Highlighter
                                                      highlightClassName="highlighter"
                                                      textToHighlight={
                                                        sortedEducation[0]
                                                          .stream
                                                      }
                                                      searchWords={[
                                                        `${filterPrevState.highesteducation.stream}`,
                                                      ]}
                                                    />
                                                  )}
                                                </p>
                                              </td>
                                            </tr>
                                          ) : (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>Education</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p></p>
                                              </td>
                                            </tr>
                                          )}

                                          {item.skills.length > 0 ? (
                                            showPercentage ? (
                                              <tr>
                                                <td style={{ width: "10px" }}>
                                                  <p
                                                    style={{ color: "#283848" }}
                                                  >
                                                    <strong>Skills</strong>
                                                  </p>
                                                </td>
                                                <td style={{ width: "250px" }}>
                                                  <p >
                                                    {item.skills?.map(
                                                      (skill, ind) => {
                                                        const { skillName } =
                                                          skill;
                                                        if (highlight) {
                                                          if (
                                                            ind !==
                                                            item.skills.length -
                                                              1
                                                          ) {
                                                            return (
                                                              <Highlighter
                                                                highlightClassName="highlighter"
                                                                textToHighlight={
                                                                  skillName +
                                                                  " | "
                                                                }
                                                                searchWords={
                                                                  booleanSearch
                                                                    ? booleanSkillsList
                                                                    : skillsList
                                                                }
                                                              />
                                                            );
                                                          } else
                                                            return (
                                                              <Highlighter
                                                                highlightClassName="highlighter"
                                                                textToHighlight={
                                                                  skillName
                                                                }
                                                                searchWords={
                                                                  booleanSearch
                                                                    ? booleanSkillsList
                                                                    : skillsList
                                                                }
                                                              />
                                                            );
                                                        } else {
                                                          if (
                                                            ind !==
                                                            item.skills.length -
                                                              1
                                                          ) {
                                                            return (
                                                              <Highlighter
                                                                highlightClassName="highlighter"
                                                                textToHighlight={
                                                                  skillName +
                                                                  " | "
                                                                }
                                                                searchWords={
                                                                  filterPrevState.skills
                                                                }
                                                              />
                                                            );
                                                          } else
                                                            return (
                                                              <Highlighter
                                                                highlightClassName="highlighter"
                                                                textToHighlight={
                                                                  skillName
                                                                }
                                                                searchWords={
                                                                  filterPrevState.skills
                                                                }
                                                              />
                                                            );
                                                        }
                                                      }
                                                    )}
                                                  </p>
                                                </td>
                                              </tr>
                                            ) : (
                                              <tr>
                                                <td style={{ width: "10px" }}>
                                                  <p
                                                    style={{ color: "#283848" }}
                                                  >
                                                    <strong>Skills </strong>
                                                  </p>
                                                </td>
                                                <td>
                                                  <p>
                                                    {item.skills?.map(
                                                      (skill, ind) => {
                                                        const { skillName } =
                                                          skill;
                                                        if (highlight) {
                                                          if (
                                                            ind !==
                                                            item.skills.length -
                                                              1
                                                          ) {
                                                            return (
                                                              skillName + " | "
                                                            );
                                                          } else
                                                            return skillName;
                                                        } else {
                                                          if (
                                                            ind !==
                                                            item.skills.length -
                                                              1
                                                          ) {
                                                            return (
                                                              skillName + " | "
                                                            );
                                                          } else
                                                            return skillName;
                                                        }
                                                      }
                                                    )}
                                                  </p>
                                                </td>
                                              </tr>
                                            )
                                          ) : (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>Skills</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p></p>
                                              </td>
                                            </tr>
                                          )}

                                          {item.personal_details?.dob ? (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>DOB</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p>
                                                  {item.personal_details.dob}
                                                </p>
                                              </td>
                                            </tr>
                                          ) : (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>DOB</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p></p>
                                              </td>
                                            </tr>
                                          )}

                                          {item.personal_details?.gender ? (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>Gender</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p>
                                                  {highlight ? (
                                                    <Highlighter
                                                      highlightClassName="highlighter"
                                                      textToHighlight={
                                                        item.personal_details
                                                          .gender
                                                      }
                                                      searchWords={[
                                                        `${gender}`,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <Highlighter
                                                      highlightClassName="highlighter"
                                                      textToHighlight={
                                                        item.personal_details
                                                          .gender
                                                      }
                                                      searchWords={[
                                                        `${filterPrevState.personaldetails.gender}`,
                                                      ]}
                                                    />
                                                  )}
                                                </p>
                                              </td>
                                            </tr>
                                          ) : (
                                            <tr>
                                              <td style={{ width: "10px" }}>
                                                <p style={{ color: "#283848" }}>
                                                  <strong>Gender</strong>
                                                </p>
                                              </td>
                                              <td>
                                                <p></p>
                                              </td>
                                            </tr>
                                          )}
                                        </table>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                md={1}
                                lg={1}
                                className="side-icon-list fav-card-action-container"
                              >
                                <Tooltip title="Share" placement="right-start">
                                  <ShareIcon
                                    fontSize="small"
                                    onClick={() => shareHandler(item)}
                                  />
                                </Tooltip>

                                <Tooltip
                                  title="Send Mail"
                                  placement="right-start"
                                >
                                  <a href={`mailto: ${item.email}`}>
                                    <EmailIcon fontSize="small" />
                                  </a>
                                </Tooltip>

                                <Tooltip
                                  title="Add Note"
                                  placement="right-start"
                                >
                                  <a>
                                    <NoteAddIcon
                                      fontSize="small"
                                      onClick={() => noteHandler(item)}
                                    />
                                  </a>
                                </Tooltip>

                                <Tooltip
                                  title={
                                    item.isFavorite
                                      ? "Remove Favorites"
                                      : "Add to Favorites"
                                  }
                                  placement="right-start"
                                >
                                  {favLoader[item._id] ? (
                                    <CircularProgress
                                      style={{
                                        width: "unset",
                                        height: "unset",
                                      }}
                                    />
                                  ) : (
                                    <FavoriteIcon
                                      style={{
                                        color: item.isFavorite
                                          ? "#f06161"
                                          : null,
                                      }}
                                      fontSize="small"
                                      onClick={() => addToFavoriteHandler(item)}
                                    />
                                  )}
                                </Tooltip>

                                <Tooltip
                                  title="View Profile"
                                  placement="right-start"
                                >
                                  <a
                                    href={`/${role}/internal-db/profile/${roleFilter}/${item._id}/${id}`}
                                    target="_blank"
                                  >
                                    <ViewIcon
                                      fontSize="small"
                                      onClick={() => viewProfileHandler(item)}
                                    />
                                  </a>
                                </Tooltip>

                                <Tooltip
                                  title={
                                    item.resume ? "Preview Resume" : "No resume"
                                  }
                                  placement="right-start"
                                >
                                  <ResumeIcon
                                    style={{ padding: 0 }}
                                    fontSize="small"
                                    onClick={() => {
                                      item.resume && debugBase64(item.resume);
                                    }}
                                    className={
                                      !item.resume && "disabled-icon-resume"
                                    }
                                  />
                                </Tooltip>
                                <Tooltip
                                  title="Schedule Interview"
                                  placement="right-start"
                                >
                                  <ScheduleIcon
                                    style={{ padding: 0 }}
                                    fontSize="small"
                                    onClick={() =>
                                      handleScheduleInterview(
                                        item.firstName,
                                        item.lastName,
                                        item.email,
                                        item._id
                                      )
                                    }
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Card>
                        );
                      })}

                    {/* React Pagination  */}

                    <ReactPaginate
                      nextLabel={
                        <ArrowForwardIosIcon
                          style={{ fontSize: 13, width: 13 }}
                        />
                      }
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={
                        <ArrowBackIosIcon style={{ fontSize: 13, width: 13 }} />
                      }
                      pageClassName="page-item"
                      forcePage={currentPage}
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              ) : (  
                <div
                  className={`no-results-body ${
                    rightSidebarOpen ? "custom-width" : ""
                  }`}
                >
                  <Card
                    id="skills-edit"
                    style={{
                      minWidth: "300px",
                      height: "80%",
                      borderRadius: "10px",
                      boxShadow: "none",
                      fontFamily: "Raleway",
                      backgroundColor: "#faf9f6",
                    }}
                  >
                    {/* Logic for component display */}
                    {showSuggestions && (
                      <>
                        {skillsList.length == 1 &&
                        skillSuggestions.length > 0 ? (
                          <p className="suggestion-text">{`People who searched for ${
                            skillsList[0].charAt(0).toUpperCase() +
                            skillsList[0].slice(1)
                          } also searched`}</p>
                        ) : skillsList.length > 1 &&
                          skillSuggestions.length > 0 ? (
                          <p className="suggestion-text">{`People who searched for ${
                            skillsList[0].charAt(0).toUpperCase() +
                            skillsList[0].slice(1)
                          } and  ${
                            skillsList[1].charAt(0).toUpperCase() +
                            skillsList[1].slice(1)
                          } also searched..`}</p>
                        ) : null}
                        <div>
                          {skillsList.length > 0 &&
                            skillSuggestions.map((item) => {
                              return (
                                <button
                                  className="skill-suggestion-btn"
                                  onClick={() => skillAppendHandler(item)}
                                >
                                  {item.skill}
                                </button>
                              );
                            })}
                        </div>
                      </>
                    )}
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      // className="add-recruiter-card-container"
                      style={{ justifyContent: "center" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={10}
                        lg={10}
                        className="container-heading-title"
                        sx={{
                          margin: "15px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={NothingToShow}
                          alt="Result Not found"
                          className="result-not-found-img"
                          style={{ width: "400px" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      // className="add-recruiter-card-container"
                      style={{ justifyContent: "center" }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={10}
                        lg={10}
                        className="container-heading-title nothing-to-show "
                        sx={{
                          margin: "15px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <h5>Nothing to show!</h5>
                        {
                          <p>
                            Oops!...It looks like you haven't applied suitable
                            filters.
                          </p>
                        }
                      </Grid>
                    </Grid>
                  </Card>
                </div>
              )}
            </div>

            {/* Right side bar form */}
            <div>
              {!rightSidebarOpen ? (
                scheduleClicked && (
                  <OpenArrow
                    className="open-arrow"
                    onClick={openArrowHandler}
                  />
                )
              ) : (
                <>
                  <div
                    className={`right-sidebar ${customHeight}`}
                    ref={quickScheduleRef}
                  >
                    <CloseArrow
                      className="close-arrow"
                      onClick={closeArrowHandler}
                    />
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      // className="add-recruiter-card-container"
                    >
                      <Grid
                        item
                        xs={5}
                        md={12}
                        lg={12}
                        sx={{
                          margin: "15px 0px 10px 10px",
                          display: "flex",
                          gap: "7px",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "3px",
                            alignItems: "flex-start",
                            marginTop: "10px",
                          }}
                        >
                          <strong>
                            <h5 style={{ fontSize: 18 }}>
                              Quick Interview Scheduler
                            </h5>
                          </strong>
                          <EventIcon
                            fontSize="small"
                            style={{ marginLeft: "5px" }}
                          />
                          <Tooltip title="Close">
                            <CloseIcon
                              fontSize="small"
                              onClick={closeAllHandler}
                              className="close-scheduler "
                            />
                          </Tooltip>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      // className="add-recruiter-card-container"
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        className="container-heading-title "
                        sx={{ overflowY: "hidden", height: "70px" }}
                      >
                        <Typography className="scheduler-title">
                          Interview Title
                        </Typography>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{
                            padding: "0px 5px 0px 5px",
                            marginBottom: "10px",
                          }}
                          className="right-grid"
                        >
                          <form noValidate>
                            <div className="input-container filter-container">
                              <div class="input-field-modal">
                                <input
                                  type="text"
                                  placeholder="Ex: Software Developer-Round 1"
                                  value={interviewTitle}
                                  onChange={(e) =>
                                    setInterviewTitle(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </form>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        className="container-heading-title "
                      >
                        <Typography className="scheduler-title">
                          Client
                        </Typography>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ padding: "0px 5px 0px 5px" }}
                          className="right-grid"
                        >
                          <div className="input-container filter-container">
                            <div
                              class="input-field-modal right-sidebar-input"
                              style={{ marginBottom: 10 }}
                            >
                              <Autocomplete
                                disablePortal
                                disableClearable
                                id="combo-box-demo"
                                options={clientOptions}
                                getOptionLabel={(option) => option?.label}
                                inputValue={clientName}
                                onChange={(event, newValue) => {
                                  handleClientData(event, newValue);
                                }}
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      style={{ backgroundColor: "#eee" }}
                                      name="client"
                                      onChange={(e) =>
                                        handleAutoCompleteTextField(e)
                                      }
                                      {...params}
                                      placeholder="Select Client"
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>

                      {clientDetails.id !== "" && (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          className="container-heading-title "
                        >
                          <Typography className="scheduler-title">
                            Position
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ padding: "0px 5px 0px 5px" }}
                            className="right-grid"
                          >
                            <div className="input-container filter-container">
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="combo-box-demo"
                                  options={positionOptions}
                                  getOptionLabel={(option) => option?.label}
                                  inputValue={positionName}
                                  onChange={(event, newValue) => {
                                    handlePositionData(event, newValue);
                                  }}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        style={{ backgroundColor: "#eee" }}
                                        name="position"
                                        onChange={(e) =>
                                          handleAutoCompleteTextField(e)
                                        }
                                        {...params}
                                        placeholder="Select Position"
                                      />
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                      {screenerName &&
                      candidateName &&
                      (roleFilter === "candidate" ||
                        roleFilter === "screener") ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          className="container-heading-title "
                        >
                          <Typography className="scheduler-title">
                            Job Seeker Name
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ padding: "0px 5px 0px 5px" }}
                            className="right-grid"
                          >
                            <div className="input-container filter-container">
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <input
                                  type="text"
                                  value={candidateName}
                                  onChange={(e) => {
                                    setCandidateName(e.target.value);
                                  }}
                                  disabled
                                />
                                {candidateName && (
                                  <CloseIcon
                                    fontSize="small"
                                    className="right-sidebar-clear-input"
                                    onClick={() => closeHandler("candidate")}
                                  />
                                )}
                                <button
                                  onClick={() =>
                                    favoriteHandler(
                                      "candidate",
                                      "advancedSearch"
                                    )
                                  }
                                  className="choose-fav-btn"
                                >
                                  Choose From Favorites
                                </button>
                              </div>

                              <Typography className="scheduler-title">
                                Screener Name
                              </Typography>
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <input
                                  type="text"
                                  value={screenerName}
                                  onChange={(e) =>
                                    setScreenerName(e.target.value)
                                  }
                                  disabled
                                />
                                {screenerName && (
                                  <CloseIcon
                                    className="right-sidebar-clear-input"
                                    fontSize="small"
                                    onClick={() => closeHandler("screener")}
                                  />
                                )}
                                <button
                                  onClick={() =>
                                    favoriteHandler(
                                      "screener",
                                      "advancedSearch"
                                    )
                                  }
                                  className="choose-fav-btn"
                                >
                                  Choose From Favorites
                                </button>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      ) : candidateName && roleFilter === "screener" ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          className="container-heading-title "
                        >
                          <Typography className="scheduler-title">
                            Job Seeker Name
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ padding: "0px 5px 0px 5px" }}
                            className="right-grid"
                          >
                            <div className="input-container filter-container">
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <input
                                  type="text"
                                  value={candidateName}
                                  onChange={(e) =>
                                    setCandidateName(e.target.value)
                                  }
                                  disabled
                                />
                                {candidateName && (
                                  <CloseIcon
                                    className="right-sidebar-clear-input"
                                    fontSize="small"
                                    onClick={() => closeHandler("candidate")}
                                  />
                                )}
                                <button
                                  onClick={() =>
                                    favoriteHandler(
                                      "candidate",
                                      "advancedSearch"
                                    )
                                  }
                                  className="choose-fav-btn"
                                >
                                  Choose From Favorites
                                </button>
                              </div>

                              <Typography className="scheduler-title">
                                Screener Name
                              </Typography>
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <input
                                  type="text"
                                  value={screenerName}
                                  onChange={(e) =>
                                    setScreenerName(e.target.value)
                                  }
                                  disabled
                                />
                                {screenerName && (
                                  <CloseIcon
                                    className="right-sidebar-clear-input"
                                    fontSize="small"
                                    onClick={() => closeHandler("screener")}
                                  />
                                )}
                                <button
                                  onClick={() =>
                                    favoriteHandler(
                                      "screener",
                                      "advancedSearch"
                                    )
                                  }
                                  className="choose-fav-btn"
                                >
                                  Choose From Favorites
                                </button>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      ) : screenerName && roleFilter === "screener" ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          className="container-heading-title "
                          sx={{ overflowY: "hidden" }}
                        >
                          <div className="scheduler-buttons">
                            <div
                              style={{ margin: "0px 0px 6px 1px" }}
                              className="add-new-row-button"
                              onClick={() => roleHandler("candidate", true)}
                            >
                              <div>
                                <AddIcon />
                              </div>
                              <div>
                                <label className="schedule-label">
                                  Add Job Seeker{" "}
                                </label>
                              </div>
                            </div>
                          </div>
                          <Typography className="scheduler-title">
                            Screener Name
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ padding: "0px 5px 0px 5px" }}
                            className="right-grid"
                          >
                            <div className="input-container filter-container">
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <input
                                  type="text"
                                  value={screenerName}
                                  onChange={(e) =>
                                    setScreenerName(e.target.value)
                                  }
                                  disabled
                                />
                                {screenerName && (
                                  <CloseIcon
                                    className="right-sidebar-clear-input"
                                    fontSize="small"
                                    onClick={() => closeHandler("screener")}
                                  />
                                )}
                                <button
                                  className="choose-fav-btn"
                                  onClick={() =>
                                    favoriteHandler(
                                      "screener",
                                      "advancedSearch"
                                    )
                                  }
                                >
                                  Choose From Favorites
                                </button>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      ) : screenerName && roleFilter === "candidate" ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          className="container-heading-title "
                          sx={{ overflowY: "hidden" }}
                        >
                          <Typography className="scheduler-title">
                            Job Seeker Name
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ padding: "0px 5px 0px 5px" }}
                            className="right-grid"
                          >
                            <div className="input-container filter-container">
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <input
                                  type="text"
                                  value={candidateName}
                                  onChange={(e) =>
                                    setCandidateName(e.target.value)
                                  }
                                  disabled
                                />
                                {candidateName && (
                                  <CloseIcon
                                    className="right-sidebar-clear-input"
                                    fontSize="small"
                                    onClick={() => closeHandler("candidate")}
                                  />
                                )}
                                <button
                                  onClick={() =>
                                    favoriteHandler(
                                      "candidate",
                                      "advancedSearch"
                                    )
                                  }
                                  className="choose-fav-btn"
                                >
                                  Choose From Favorites
                                </button>
                              </div>

                              <Typography className="scheduler-title">
                                Screener Name
                              </Typography>
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <input
                                  type="text"
                                  value={screenerName}
                                  onChange={(e) =>
                                    setScreenerName(e.target.value)
                                  }
                                  disabled
                                />
                                {screenerName && (
                                  <CloseIcon
                                    className="right-sidebar-clear-input"
                                    fontSize="small"
                                    onClick={() => closeHandler("screener")}
                                  />
                                )}
                                <button
                                  className="choose-fav-btn"
                                  onClick={() =>
                                    favoriteHandler(
                                      "screener",
                                      "advancedSearch"
                                    )
                                  }
                                >
                                  Choose From Favorites
                                </button>
                              </div>
                              <div></div>
                            </div>
                          </Grid>
                        </Grid>
                      ) : screenerName &&
                        candidateName &&
                        (roleFilter === "candidate" ||
                          roleFilter === "screener") ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          className="container-heading-title "
                          sx={{ overflowY: "hidden" }}
                        >
                          <Typography className="scheduler-title">
                            Job Seeker Name
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ padding: "0px 5px 0px 5px" }}
                            className="right-grid"
                          >
                            <div className="input-container filter-container">
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <input
                                  type="text"
                                  value={candidateName}
                                  onChange={(e) =>
                                    setCandidateName(e.target.value)
                                  }
                                  disabled
                                />
                                {candidateName && (
                                  <CloseIcon
                                    className="right-sidebar-clear-input"
                                    fontSize="small"
                                    onClick={() => closeHandler("candidate")}
                                  />
                                )}
                                <button
                                  onClick={() =>
                                    favoriteHandler(
                                      "candidate",
                                      "advancedSearch"
                                    )
                                  }
                                  className="choose-fav-btn"
                                >
                                  Choose From Favorites
                                </button>
                              </div>

                              <Typography className="scheduler-title">
                                Screener Name
                              </Typography>
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <input
                                  type="text"
                                  value={screenerName}
                                  onChange={(e) =>
                                    setScreenerName(e.target.value)
                                  }
                                  disabled
                                />
                                {screenerName && (
                                  <CloseIcon
                                    className="right-sidebar-clear-input"
                                    fontSize="small"
                                    onClick={() => closeHandler("screener")}
                                  />
                                )}
                                <button
                                  className="choose-fav-btn"
                                  onClick={() =>
                                    favoriteHandler(
                                      "screener",
                                      "advancedSearch"
                                    )
                                  }
                                >
                                  Choose From Favorites
                                </button>
                              </div>
                              <div></div>
                            </div>
                          </Grid>
                        </Grid>
                      ) : candidateName && roleFilter === "candidate" ? (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          className="container-heading-title "
                          sx={{ overflowY: "hidden" }}
                        >
                          <Typography className="scheduler-title">
                            Job Seeker Name
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ padding: "0px 5px 0px 5px" }}
                            className="right-grid"
                          >
                            <div className="input-container filter-container">
                              <div
                                class="input-field-modal right-sidebar-input"
                                style={{ marginBottom: 10 }}
                              >
                                <input
                                  type="text"
                                  value={candidateName}
                                  onChange={(e) =>
                                    setCandidateName(e.target.value)
                                  }
                                  disabled
                                />
                                {candidateName && (
                                  <CloseIcon
                                    className="right-sidebar-clear-input"
                                    fontSize="small"
                                    onClick={() => closeHandler("candidate")}
                                  />
                                )}
                                <button
                                  onClick={() =>
                                    favoriteHandler(
                                      "candidate",
                                      "advancedSearch"
                                    )
                                  }
                                  className="choose-fav-btn"
                                >
                                  Choose From Favorites
                                </button>
                              </div>

                              <div className="scheduler-buttons">
                                <div
                                  className="add-new-row-button"
                                  onClick={() => roleHandler("screener", false)}
                                >
                                  <div>
                                    <AddIcon />
                                  </div>
                                  <div>
                                    <label className="schedule-label">
                                      Add Screener{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      // className="add-recruiter-card-container"
                    >
                      <Grid
                        item
                        xs={5}
                        md={12}
                        lg={12}
                        className="container-heading-title advanced-filter-form"
                        sx={{ margin: "1px 10px 10px 0px" }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            class="input-field-modal"
                            style={{ width: "40%", marginTop: 0 }}
                          >
                            <Typography className="scheduler-title">
                              Interview Date
                            </Typography>
                            <input
                              style={{ height: 35, padding: "5px" }}
                              class="position"
                              type="date"
                              min={new Date().toISOString().split("T")[0]}
                              value={date}
                              onChange={dateHandler}
                              placeholder="mm/dd/yyyy"
                            />
                          </div>
                          <div
                            className="input-date-modal"
                            style={{ width: "60%" }}
                          >
                            <Typography className="scheduler-title">
                              Time Zone
                            </Typography>

                            <select
                              style={{
                                outline: "none",
                                marginLeft: "5px",
                                width: "99%",
                                padding: "5px 25px 5px 5px",
                              }}
                              value={zone}
                              onChange={(e) => setZone(e.target.value)}
                            >
                              {timezone.map((time) => {
                                return (
                                  <option value={time.abbr}>
                                    {time.value}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={12}
                        lg={12}
                        className="container-heading-title advanced-filter-form"
                        sx={{
                          margin: "1px 10px 10px 0px",

                          alignItems: "center",
                          justifyContent: "center",

                          gap: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          className="input-date-modal"
                          style={{ width: "100%" }}
                          onClick={() => {
                            if (!date) {
                              setMessage("Please fill the interview date");
                              setOpenSnackbar(true);
                            }
                          }}
                        >
                          <Typography className="scheduler-title">
                            Interview Time
                          </Typography>
                          <div className="quick-select-time">
                            <select
                              value={hours}
                              onChange={(e) =>
                                handleTimeChange(e.target.value, "hours")
                              }
                              style={{ outline: "none" }}
                              disabled={date ? false : true}
                            >
                              <option selected disabled value="default">
                                Select Time
                              </option>
                              {hoursArray.map((time) => (
                                <option key={time} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>
                            <select
                              value={minutes}
                              onChange={(e) =>
                                handleTimeChange(e.target.value, "minutes")
                              }
                              style={{ outline: "none" }}
                              disabled={date ? false : true}
                            >
                              <option selected disabled value="default">
                                Select Time
                              </option>
                              {minutesArray.map((time) => (
                                <option key={time} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>
                            <select
                              value={meridian}
                              onChange={(e) =>
                                handleTimeChange(e.target.value, "merdian")
                              }
                              style={{ outline: "none" }}
                              disabled={date ? false : true}
                            >
                              <option selected disabled value="default">
                                Select Time
                              </option>
                              {meridianArray.map((time) => (
                                <option key={time} value={time}>
                                  {time}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        md={12}
                        lg={12}
                        className="container-heading-title advanced-filter-form"
                        sx={{ margin: "1px 10px 10px 0px" }}
                      >
                        <div className="input-date-modal">
                          <div>
                            <div
                              className="scheduler-buttons"
                              style={{ display: "flex" }}
                            >
                              <div class="input-field mobile-input-field ">
                                <label
                                  className="date-label"
                                  style={{ fontSize: "12px" }}
                                >
                                  Meeting Link
                                </label>
                                <div className="generate-link-container">
                                  <div
                                    className={`generate-link-btn ${
                                      meetingPlatform == "Jitsi" ? "active" : ""
                                    }`}
                                    onClick={generateJitsiLink}
                                  >
                                    Jitsi Link{" "}
                                    <LinkIcon className="meet-link-icon" />
                                  </div>

                                  {/* <div
                                    className={`generate-link-btn ${
                                      meetingPlatform == "Zoom" ? "active" : ""
                                    }`}
                                    onClick={generateLinkHandler}
                                  >
                                    Zoom Link{" "}
                                    <LinkIcon className="meet-link-icon" />
                                  </div> */}
                                  <div
                                    className={`generate-link-btn ${
                                      meetingPlatform == "Custom"
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={customLinkHandler}
                                  >
                                    Custom Link{" "}
                                    <LinkIcon className="meet-link-icon" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {(interviewLink != "" || customLink) && (
                              <div
                                className="input-container filter-container"
                                style={{
                                  position: "relative",
                                  background: "#eee",
                                }}
                              >
                                <div class="input-field-modal">
                                  <input
                                    disabled={!customLink}
                                    type="text"
                                    ref={meetLinkRef}
                                    value={interviewLink}
                                    onChange={(e) =>
                                      setInterviewLink(e.target.value)
                                    }
                                    style={{ marginLeft: "5px", width: "81%" }}
                                  />
                                </div>
                                <ContentCopyIcon
                                  onClick={() =>
                                    copyTextToClipboard(interviewLink)
                                  }
                                  className="meet-link-icons"
                                  fontSize="small"
                                  sx={{
                                    position: "absolute",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                    top: "16px",
                                    right: "9px",
                                    fontSize: "15px",
                                    opacity: "50%",
                                  }}
                                />
                                {/* {!customLink && (
                                                                    <RefreshIcon
                                                                        onClick={refreshHandler}
                                                                        className="meet-link-icons"
                                                                        fontSize="small"
                                                                        sx={{
                                                                            position: "absolute",
                                                                            fontSize: "14px",
                                                                            cursor: "pointer",
                                                                            top: "14px",
                                                                            right: "9px",
                                                                            fontSize: "18px",
                                                                            opacity: "50%",
                                                                        }}
                                                                    />
                                                                )} */}
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              marginTop: "26px",
                              marginBottom: "20px",
                            }}
                          >
                            <button
                              onClick={saveHandler}
                              style={{
                                fontSize: "12px",
                                padding: "5px 10px",
                                border: "1px solid #f06161",
                                borderRadius: "5px",
                                color: "#f06161",
                                backgroundColor: "white",
                                fontWeight: "normal",
                              }}
                              className="btn-style outlined-btn outlined-btn-animation"
                            >
                              {" "}
                              Save for Later
                            </button>

                            <button
                              onClick={scheduleHandler}
                              style={{
                                fontSize: "12px",
                                padding: "5px 10px",
                                border: "1px solid #f06161",
                                borderRadius: "5px",
                                color: " white",
                                fontWeight: "normal",
                                backgroundColor: "#f06161",
                              }}
                              className="btn-style filled-btn filled-btn-animation"
                            >
                              {" "}
                              Schedule Now
                            </button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </>
              )}
            </div>
          </div>
        </Box>
        {/* Snackbar modal */}
        <UserSnackBar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          message={message}
        />{" "}
        {/* Cancel interview  */}
        <CancelInterview
          cancelInterview={cancelInterview}
          setDialogOpen={setDialogOpen}
          dialogOpen={dialogOpen}
        />
        <ShareProfileModal
          resume={selectedItem?.resume}
          firstName={selectedItem?.firstName}
          lastName={selectedItem?.lastName}
          role={roleFilter}
          openShareModal={openShareModal}
          setOpenShareModal={setOpenShareModal}
          profilePublicLink={profilePublicLink}
          includeResume={includeResume}
          setIncludeResume={setIncludeResume}
          senderName={companyName}
          replyToEmail={replyToEmail}
        />
        <AddNoteModal
          userId={selectedItem?._id}
          role={roleFilter}
          usersList={users}
          setUsersList={setUsers}
          setFilterArray={setFilterArray}
          profileId={id}
          companyId={id}
          openNoteModal={openNoteModal}
          setOpenNoteModal={setOpenNoteModal}
          module={"searchModule"}
        />
        <ViewNotes
          openViewNoteModal={openViewNoteModal}
          setOpenViewNoteModal={setOpenViewNoteModal}
          user={viewNoteUser}
          setUser={setViewNoteUser}
          companyId={id}
          profileId={id}
          unreadNotesCount={unreadNotesCount}
          setUnreadNotesCount={setUnreadNotesCount}
          getInternalUsers={getAllUsers}
        />
      </div>

      <Footer sidebarOpen={sidebarOpen} />
    </div>
  );
};

export default Search;
