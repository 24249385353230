import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip, LabelList, ResponsiveContainer } from 'recharts';

const PieChartComponent = ({ clients }) => {
    const colors = ['#ef798a', '#3091A2']; // Define colors for each section
    
    // console.log("clients length",clients.length);
    // console.log("clients",clients);
    
    return (
        clients[1]?.value > 0 ? (
            <div style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}>
                <ResponsiveContainer width="100%" height={380}>
                    <PieChart>
                        <Pie
                            data={clients}
                            dataKey="value"
                            nameKey="type"
                            cx="50%"
                            cy="50%"
                            fill="#8884d8"
                        >
                            {clients.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))}

                            <LabelList
                                dataKey="value"
                                position="outside"
                                offset={10}
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    fill: 'black',
                                }}
                            />
                        </Pie>
                        <Tooltip />
                        <Legend
                        layout="horizontal"
                        verticalAlign="bottom"
                        align="center"
                        iconType="square"
                        formatter={(value) => (
                            <span style={{ color: '#333' }}>{value}</span>
                        )}
                    />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        ) : (
            <div style={{ textAlign: 'center', padding: '20px' }}>No interviews scheduled</div>
        )
    );
};

export default PieChartComponent;
