import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

//component imports
import CompanyMenu from "../userProfiles/CompanyMenu";
import AddRecruiterModal from "../modals/AddRecruiterModal";

//MUI imports
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";

// CSS import
import "./CompanyAdmin.css";

import FreezeModal from "../modals/FreezeModal";
import FrozenMessageSnackbar from "../snackBars/FrozenMessageSnackbar";
import Spinner from "../loaders/Spinner";
import TableLoader from "../loaders/TableLoader";
// Icons
import ViewIcon from "@mui/icons-material/RemoveRedEye";
import FrozenIcon from "@mui/icons-material/AcUnit";
import DeleteIcon from "@mui/icons-material/Delete";
import FireIcon from "@mui/icons-material/Whatshot";
import { ViewEditUserModal } from "../modals/ViewEditUserModal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Footer from "../../Footer/Footer";

const UserList = () => {
  //Account Id and role
  const id = sessionStorage.getItem("id");
  const companyId = sessionStorage.getItem("companyId");
  const role = sessionStorage.getItem("role");

  const columns = [
    {
      title: "Name",
      width: "26%",
    },
    {
      title: "Email",
      width: "28%",
    },
    {
      title: "Role",
      width: "17%",
    },
    {
      title: "Status",
      width: "14.5%",
    },
    {
      title: "Action",
      width: "14.5%",
    },
  ];

  //List States
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
  const [action, setAction] = useState("");

  //Navbar states
  const [open, setOpen] = useState();
  const [sidebarOpen, setsidebarOpen] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [interacted, setInteracted] = useState("");
  const [croppedImg, setCroppedImg] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [defaultAvatar, setDefaultAvatar] = useState("");

  const [openAddUserForm, setOpenAddUserForm] = useState(false);
  const [userList, setUserList] = useState([]);

  //array to store user roles
  const [userRoles, setUserRoles] = useState([]);

  //Loader
  const [tableLoading, setTableLoading] = useState(true);

  //Form States
  const [filter, setFilter] = useState("");
  const [userFilter, setUserFilter] = useState("All statuses");
  const [roleFilter, setRoleFilter] = useState("All roles");

  const [debouncedTerm, setDebouncedTerm] = useState("");

  //pagination states
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  //Edit recruiter form states
  const [selectedUserData, setSelectedUserData] = useState({});

  //Modal states
  const [openEditUserDetails, setOpenEditUserDetails] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  //User Id and role
  const [userId, setUserId] = useState("");

  //SnackBar states
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  //Refs
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(0);

  let arr = [];
  //title
  useEffect(() => {
    document.title = "Screener | My Users";
  }, []);

  useEffect(() => {
    const { data } = axios.get(`/api/admin/${id}`).then((res) => {
      const {
        defaultAvatar,
        profileImage,
        croppedImage,
        interacted,
        companyName,
      } = res.data.user;
      setDefaultAvatar(defaultAvatar);
      setProfileImage(profileImage);
      setCroppedImg(croppedImage);
      setInteracted(interacted);
      setCompanyName(companyName);
    });

    axios
    .get(`/api/admin/userRole/${id}`)
    .then((res) => {
      let arr = [];
      if (res.data.length > 0) {
        arr = res.data[0].profileType;
        arr.push({ roleName: "All roles" });
        arr = arr.filter((item) => item.roleName !== "Recruiter");
        arr = arr.reverse();
        setUserRoles(arr);
      } else {
        arr.push({ roleName: "All Roles" });
        setUserRoles(arr);
      }
    })
    .catch((error) => {
      console.error("Error fetching user roles:", error);
    });
    }, []);

  //Add user Dialog handler
  const handleOpenAddUser = () => {
    setOpenAddUserForm(true);
  };

  const handleOpenUserDetails = (userId) => {
    var arr = userList.filter((item) => {
      return item._id === userId;
    });
    setSelectedUserData(arr[0]);
    setOpenEditUserDetails(true);
  };

  //pagination handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //List handler
  const filterHandler = (e) => {
    setDebouncedTerm(e.target.value);
    setCursorPosition(e.target.selectionStart);
  }

  const handleFocus = (e) => {
    e.target.setSelectionRange(cursorPosition, cursorPosition)
  }
  const freezeHandler = (id, action) => {
    if (action !== "Pending") {
      setAction(action);
      setOpenDelete(true);
      setUserId(id);
    } else {
      setOpenErrorSnackbar(true);
    }
  };

  //Debouncing
  useEffect(() => {
    const timer = setTimeout(() => setFilter(debouncedTerm), 500);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  //post text to backend

  const sendFilter = async () => {
    const { data } = await axios.get(
      `/api/myUsers/myfilteredUsers/${companyId}/?userFilter=${userFilter}&filter=${filter}&roleFilter=${roleFilter}`
    );
    setTableLoading(false);
    const reverseData = data.reverse();
    setUserList(reverseData);
  };

  useEffect(() => {
    setPage(0);
    sendFilter();
  }, [
    filter,
    userFilter,
    roleFilter,
    openDelete,
    openAddUserForm,
    openEditUserDetails,
  ]);
  //Main
  var drawerWidth = 200;
  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    })
  );

  return (
    <div>
      {/* Navbar Component */}
      <CompanyMenu
        open={open}
        setOpen={setOpen}
        sidebarOpen={sidebarOpen}
        setsidebarOpen={setsidebarOpen}
        companyName={companyName}
        croppedImg={croppedImg}
        profileImage={profileImage}
        interacted={interacted}
        defaultAvatar={defaultAvatar}
      />
      {/* User List Component */}
      <div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}` }}>
        <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
          <Card id="main-edit" className="add-recruiter-form form-card">
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent={"space-between"}
            // className="add-recruiter-card-container"
            >
              <Grid
                item
                xs={7}
                sm={4}
                md={4}
                lg={8}
                className="container-heading-title admin-table"
              >
                <h1>Users</h1>
              </Grid>
              <Grid
                item
                xs={4}
                sm={7}
                md={7}
                lg={2}
                className="container-heading-buttons add-recruiter-button"
                style={{ width: "100%" }}
              >
                <div className="add-user-button-container">
                  <button className="add-recruiter" onClick={handleOpenAddUser}>
                    Add User
                  </button>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              style={{ paddingTop: "10px" }}
            // className="profile-progress-bar"
            >
              <Grid
                id="profile-completion-percentage"
                item
                xs={12}
                sm={7}
                md={7}
                sx={{ textAlign: "center" }}
                className="container-profile"
              >
                <form className="experience-edit-form" noValidate>
                  <div class="add-recruiter-input-container myUsers">
                    <div class="input-field">
                      <input
                        class="position"
                        type="text"
                        defaultValue={debouncedTerm}
                        onChange={filterHandler}
                        onFocus={handleFocus}
                        placeholder="Search"
                        ref={el => (inputRef.current = el)}
                        autoFocus={inputRef.current === document.activeElement}
                      />
                    </div>
                    <div className="date-dropdown-picker">
                      {/* Filter based on user status */}
                      <div class="input-field">
                        <select
                          value={userFilter}
                          onChange={(e) => {
                            setUserFilter(e.target.value);
                          }}
                        >
                          <option value="All statuses" selected>
                            All statuses
                          </option>
                          <option value="Pending">Pending</option>
                          <option value="Active">Active</option>
                          <option value="Frozen">Frozen</option>
                        </select>
                      </div>

                      {/* Filter based on user role */}
                      <div class="input-field">
                        <select
                          value={roleFilter}
                          onChange={(e) => {
                            setRoleFilter(e.target.value);
                          }}
                        >
                          {userRoles.map((option) => {
                            return (
                              <option value={option.roleName}>
                                {option.roleName.toLowerCase() === "admin" ? "Administrator" : option.roleName.charAt(0).toUpperCase() + option.roleName.slice(1)}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </Grid>
            </Grid>
          </Card>

          <Card
            id="skills-edit"
            className="add-recruiter-form-container"
            style={{ boxShadow: "none", borderRadius: "2px" }}
          >
            <div className="recruiter-list">
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="recruiter-list-heading"
              >
                {columns.map((item, index) => {
                  return (
                    <h6
                      style={{
                        width: `${item.width}`,
                        background: index % 2 ? "rgba(240, 97, 97, 37%)" : "rgba(240, 97, 97, 17%)"
                      }}
                    >

                      {item.title}
                    </h6>
                  );
                })}
              </Grid>
              {/* <Divider
                sx={{
                  borderStyle: "dotted",
                  borderColor: "darkgray",
                  borderBottomWidth: "1.5px",
                }}
                id="divider"
              /> */}
              {tableLoading ? (
                <TableLoader page="userList" />
              ) : (
                <div className="recruiter-details-list">
                  {userList.length > 0 ? (
                    userList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        let nameLength =
                          item.firstName.length + item.lastName.length;
                        return (
                          <>
                            <div
                              className={` recruiter-details ${index % 2 ? "" : "alternate-recruiterlist-bg"
                                }`}
                            >
                              <div className="user-list-name">
                                <img
                                  style={{ objectFit: "cover" }}
                                  src={
                                    item.defaultAvatar
                                      ? profileAvatar
                                      : item.interacted
                                        ? item.croppedImage
                                        : item.profileImage
                                  }
                                  className="recruiter-list-profile-image"
                                />

                                {nameLength > 23 ? (
                                  <Tooltip
                                    title={`${item.firstName} ${item.lastName}`}
                                  >
                                    <p>
                                      {item.firstName} {item.lastName}
                                    </p>
                                  </Tooltip>
                                ) : (
                                  <p>
                                    {item.firstName} {item.lastName}
                                  </p>
                                )}
                              </div>

                              <div className="user-list-email">
                                {item.userEmail.length > 24 ? (
                                  <Tooltip title={`${item.userEmail}`}>
                                    <p>{item.userEmail}</p>
                                  </Tooltip>
                                ) : (
                                  <p>{item.userEmail}</p>
                                )}
                              </div>
                              <div className="user-list-role">
                                <span
                                  className={
                                    item.profileType.length > 24
                                      ? "hover-text"
                                      : ""
                                  }
                                  data-hover={`${item.profileType}`}
                                >
                                  <p>{item.profileType}</p>
                                </span>
                              </div>
                              <div className="user-status-button">
                                <button className={` ${item.status}`}>
                                  {item.status}
                                </button>
                              </div>
                              <div className="user-action-buttons">
                                <div className="user-action-button-icon">
                                  <Tooltip title="View">
                                    <ViewIcon
                                      fontSize="small"
                                      onClick={() => {
                                        handleOpenUserDetails(item._id);
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                                <div className="user-action-button-icon">
                                  {item.status !== "Frozen" ? (
                                    <Tooltip title="Freeze">
                                      <FrozenIcon
                                        fontSize="small"
                                        onClick={() =>
                                          freezeHandler(item._id, item.status)
                                        }
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Unfreeze">
                                      <FireIcon
                                        fontSize="small"
                                        onClick={() =>
                                          freezeHandler(item._id, item.status)
                                        }
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                                <div className="user-action-button-icon">
                                  <Tooltip title="Delete">
                                    <DeleteIcon
                                      fontSize="small"
                                      onClick={() =>
                                        freezeHandler(item._id, "delete")
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                            {/* <Divider
                              sx={{ borderColor: "lightgray" }}
                              id="divider"
                            /> */}
                          </>
                        );
                      })
                  ) : (
                    <p>No Users</p>
                  )}
                </div>
              )}
            </div>
            <TablePagination
              id="recruiter-list-pagination"
              component="div"
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={userList.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              variant="outlined"
            />
          </Card>
        </Box>
      </div>

      <AddRecruiterModal
        id={id}
        role={role}
        userRoles={userRoles}
        companyId={companyId}
        openAddUserForm={openAddUserForm}
        setOpenAddUserForm={setOpenAddUserForm}
        addUserType="user"
      />
      <FreezeModal
        openDelete={openDelete}
        action={action}
        setOpenDelete={setOpenDelete}
        id={userId}
        role="myUsers"
      />
      <ViewEditUserModal
        openEditUserDetails={openEditUserDetails}
        setOpenEditUserDetails={setOpenEditUserDetails}
        {...selectedUserData}
      />
      <FrozenMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
        message="You cannot freeze a Pending account"
      />

      <Footer sidebarOpen={sidebarOpen} />
    </div>
  );
};

export default UserList;
