import React, { useState } from "react";

// external imports
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

// css imports
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../auth/LoginComponent.css"; // Keep your CSS

// Modal to show email verification message
const LinkedinLoginModal = (props) => {
  const [redirectUri, setRedirectUri] = useState("");

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      sx={{ border: "none", borderRadius: "2rem" }}
    >
      <img
        width="30"
        height="30"
        src={
          props.icon === "linkedin" &&
          "https://cdn-icons-png.flaticon.com/512/145/145807.png"
        }
        alt="email-img"
        style={{ margin: "20px auto -6px auto" }}
      />
      {/* Modal body */}
      <Modal.Body>
        <h6 style={{ textAlign: "center" }}>Sign In as*</h6>

        <div className="login-radio-container">
          <FormControl className="login-radio-group" style={{ justifyContent: "center" }}>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={props.role}
              onChange={(e) => {
                props.setRole(e.target.value);
                // console.log(process.env.REACT_APP_REDIRECT_URL);
                if (e.target.value === "candidate") {
                  setRedirectUri(
                    `${process.env.REACT_APP_REDIRECT_URL}%2Fcandidate%2Flinkedin-login`
                  );
                } else {
                  setRedirectUri(
                    `${process.env.REACT_APP_REDIRECT_URL}%2Fscreener%2Flinkedin-login`
                  );
                }
              }}
            >
              <FormControlLabel
                value="candidate"
                control={
                  <Radio
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#f06161", // Set color when checked
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                      },
                    }}
                    required={true}
                  />
                }
                label="Job Seeker"
              />
              <FormControlLabel
                value="screener"
                control={
                  <Radio
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#f06161", // Set color when checked
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                      },
                    }}
                    required={true}
                  />
                }
                label="Screener"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <Button
            onClick={() => props.socialMediaHandler(props.icon)}
            style={{
              fontSize: "smaller",
              borderRadius: "20px",
              textAlign: "center",
            }}
            className="btn-login"
          >
            <a
              href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=w_member_social%20email%20profile%20openid&client_id=78y49finlyr0sb&client_secret=EnThqnhmT07A49wV&redirect_uri=${redirectUri}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Submit
            </a>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LinkedinLoginModal;
