import React, { useState, useEffect } from 'react'
import { Popper, Box, Card, CardContent, CardActions, Button } from "@mui/material";
import axios from 'axios'
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Notification from './Notification'

const NotificationPopper = ({ open, setAnchorEl, anchorEl, notifications, setNotifications }) => {
    const id = sessionStorage.getItem("id")
    const role = sessionStorage.getItem("role")

    const [notifFilter, setNotifFilter] = useState('all')


    console.log(notifications)

    async function archiveNotifHandler(notif_id) {
        return axios.post(`/api/admin/archive-notif/${id}/${notif_id}`)
            .then(res => {
                const updatedArray = notifications.map(item => {
                    if (item._id == notif_id) {
                        return { ...item, isArchived: true }
                    }
                    return item
                })
                setNotifications(updatedArray)
                return new Promise(function (res, rej) {
                    res("Resolved")
                })
            })
            .catch(err => {
                return new Promise(function (res, rej) {
                    rej(err)
                })
            })
    }
    async function unarchiveNotifHandler(notif_id) {
        return axios.post(`/api/admin/unarchive-notif/${id}/${notif_id}`)
            .then(res => {
                const updatedArray = notifications.map(item => {
                    if (item._id == notif_id) {
                        return { ...item, isArchived: false }
                    }
                    return item
                })
                setNotifications(updatedArray)
                return new Promise(function (res, rej) {
                    res("Resolved")
                })
            })
            .catch(err => {
                return new Promise(function (res, rej) {
                    rej(err)
                })
            })
    }


    async function deleteNotifHandler(notif_id) {
        return axios.post(`/api/${role}/delete-notif/${id}/${notif_id}`)
            .then(res => {
                const updatedArray = notifications.filter(item => item._id !== notif_id)
                setNotifications(updatedArray)
                return new Promise(function (res, rej) {
                    res("Resolved")
                })
            })
            .catch(err => {
                return new Promise(function (res, rej) {
                    rej(err)
                })
                console.log(err)
            })
    }

    function markAllAsReadHandler() {
        axios.post(`/api/${role}/mark-as-read/${id}`, { notifications })
            .then(res => {
                console.log(res)
                let updatedArray = notifications.map(item => {
                    item.read = true;
                    return item
                })
                setNotifications(updatedArray)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleClickAway = () => {
        setAnchorEl(null)
    };

    return (
        <div>
            <Popper open={open} anchorEl={anchorEl} className="popper-component" placement='bottom-end'>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Box sx={{ p: 1, bgcolor: 'background.paper' }} className="notifications-container">
                        <div className="notification-dialog">
                            <h6 className="notification-dialog-heading">Notifications</h6>
                            {notifications.length > 0 ? <button onClick={markAllAsReadHandler} className="mark-read-button">Mark all as read</button> : ""}
                        </div>
                        <div className='notification-container'>
                            <button className={`btn notification-btn ${notifFilter === "all" && "notif-filter-active"}`} onClick={() => setNotifFilter('all')}>All</button>
                            <button className={`btn notification-btn ${notifFilter === "archived" && "notif-filter-active"}`} onClick={() => setNotifFilter('archived')}>Archived</button>
                        </div>
                        <hr className='notification-divider' />
                        {
                            notifications.length > 0 ? (notifications.map((item, index) => {

                                if (notifFilter === 'all') {
                                    if (item.isDeleted || item.isArchived) return null;
                                    return <Notification
                                        role={role}
                                        userId={id}
                                        index={index}
                                        item={item}
                                        formatDistanceToNow={formatDistanceToNow}
                                        deleteNotifHandler={deleteNotifHandler}
                                        archiveNotifHandler={archiveNotifHandler} />
                                }
                                else if (notifFilter == "archived") {
                                    if (item.isDeleted) return null;
                                    if (item.isArchived) {
                                        return <Notification
                                            role={role}
                                            userId={id}
                                            index={index}
                                            item={item}
                                            formatDistanceToNow={formatDistanceToNow}
                                            deleteNotifHandler={deleteNotifHandler}
                                            archiveNotifHandler={archiveNotifHandler}
                                            unarchiveNotifHandler={unarchiveNotifHandler} />
                                    }
                                    return null;
                                }

                            }
                            )) : <p style={{ textAlign: "center", margin: "10px" }}>No notifications</p>
                        }
                    </Box>
                </ClickAwayListener>
            </Popper >
        </div >
    )
}

export default NotificationPopper