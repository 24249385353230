import { RECEIVE_USER_ERRORS, RECEIVE_USER_INFORMATION } from "../actions/userActions";

const initialState = {
    profileCompletionPercentage: 10,
    averageRating: 0,
};

export const sidebarReducer=(state = initialState, action)=> {
    switch (action.type) {
        case RECEIVE_USER_INFORMATION:
            const { profileCompletionPercentage, averageRating } = action.payload.data;

            const percentageChecked = profileCompletionPercentage > 100 ? 100 : Math.floor(profileCompletionPercentage);
            // console.log("In sidebar reducer: " + averageRating);
            return { profileCompletionPercentage: percentageChecked, averageRating: averageRating };
        // case RECEIVE_REVIEWS:
        //     const { Screener_Average_Rating__c } = action.reviews.data[0];

        //     return { profileCompletionPercentage: state.profileCompletionPercentage, averageRating: Screener_Average_Rating__c};
        case RECEIVE_USER_ERRORS:
            return initialState;
        default:
            return state;
    }
}
