import { RECEIVE_INTERVIEW, RECEIVE_INTERVIEWS, RECEIVE_INTERVIEW_ERRORS, RECEIVE_UPDATED_INTERVIEW } from "../actions/Interviews";

// Define the initial state
const initialState = ['No alerts for this user'];

// Define the reducer function
export const interviewReducer = (state = initialState, action) => {
    Object.freeze(state);

    switch (action.type) {
        case RECEIVE_INTERVIEWS:
            if (action.interviews.data.length <= 0) return state;
            return action.interviews.data;
        case RECEIVE_INTERVIEW:
            return [...state, action.interview.data]; // Updated the merge logic
        case RECEIVE_UPDATED_INTERVIEW:
            const updatedInterviews = state.map((interview) => {
                if (interview.Id === action.interview.data.salesforceInterviewRecordId) {
                    interview.Status__c = action.interview.data.status;
                    return interview;
                }
                return interview;
            });
            return updatedInterviews;
        case RECEIVE_INTERVIEW_ERRORS:
            return state;
        default:
            return state;
    }
};

