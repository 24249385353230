import React from "react";
import { Link } from "react-scroll";
import "./landing.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Hero = () => {
  return (
    <header id="home" className="header">
      <div className="overlay"></div>

      <div
        id="header-carousel"
        className="carousel slide carousel-fade"
        data-ride="carousel"
      >
        <div className="container  hero-container">
          <div className="carousel-inner">
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              interval={4000}
              swipeable={true}
              showStatus={false}
              showArrows={false}
              showIndicators={false}
            >
              <div className="carousel-item active">
                <div className="carousel-caption d-none d-md-block">
                  <h1 className="carousel-title">
                    We Make
                    <br />
                    Recruitment Simple.
                  </h1>
                  <Link to="about" smooth={true}>
                    <button className="btn btn-primary btn-rounded">
                      <a
                        className="anchor"
                        href="#some-section"
                        style={{ color: "white" }}
                      >
                        Learn More
                      </a>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="carousel-item active">
                <div className="carousel-caption d-none d-md-block">
                  <h1 className="carousel-title">
                    Re-Discover <br />
                    Recruiting.
                  </h1>
                  <Link to="about" smooth={true}>
                    <button
                      className="btn btn-primary btn-rounded"
                      style={{ color: "white" }}
                    >
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>

      <div className="infos container mb-4 mb-md-2">
        <div className="title">
          <h6 className="subtitle font-weight-normal">Developed By</h6>
          <h5>Infomatics Corp</h5>
          <p className="font-small">
            23465 Rock Haven Way, Suite 100 Dulles, VA 20166
          </p>
        </div>
      </div>
    </header>
  );
};

export default Hero;
