import React, { useEffect, useState, useRef, createRef } from "react";
import UserSnackBar from "../../snackBars/UserSnackbar";
import * as XLSX from "xlsx";
import _, { first } from "lodash";
import axios from "axios";

// MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const SendInvitation = ({ sidebarOpen, setPageName }) => {
  //session storage
  const id = sessionStorage.getItem("id");

  // Excel file template
  const templateFileLink =
    "https://res.cloudinary.com/df2qsnyox/raw/upload/v1673943935/Excel%20Sheet/Invitation%20Template.xlsx";
  let columnHeaders = []; //to store the headings of the excel file

  // form states
  const [role, setRole] = useState("");
  const [fileName, setFileName] = useState("");   //resume
  const [inputFields, setInputFields] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
    },
  ]);
  let arrLength = inputFields.length;

  //refs
  const fileRef = useRef();
  const firstRef = useRef([])
  const lastRef = useRef([])
  const emailRef = useRef([])
  const sendRef = useRef(null)
  //cursor position
  const [firstNamePosition, setFirstNamePosition] = useState([]);
  const [lastNamePosition, setLastNamePosition] = useState([]);
  const [emailPosition, setEmailPosition] = useState([]);

  // dialog states
  const [openDelete, setOpenDelete] = useState(false);

  // already registered users
  const [registeredUsers, setRegisteredUsers] = useState([]);

  //errors
  const [errors, setErrors] = useState({
    role: "",
  });

  const [fieldErrors, setFieldErrors] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
    },
  ]);

  //snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  //getting the registered users
  // useEffect(() => {
  //   fieldErrors.map((item, index) => {
  //     return (item.email = "");
  //   });
  //   if (role === "screener") {
  //     const { data } = axios
  //       .get(`/api/screener/screenerList/screeners`)
  //       .then((res) => {
  //         setRegisteredUsers(res.data);
  //       });
  //   } else if (role === "candidate") {
  //     const { data } = axios
  //       .get(`/api/candidate/candidateList/candidates`)
  //       .then((res) => {
  //         setRegisteredUsers(res.data);
  //       });
  //   }
  // }, [role]);

  useEffect(() => {
    // Clear the email field errors
    fieldErrors.map((item, index) => {
      item.email = "";
      return item; // Ensure map returns the updated array
    });
  
    const fetchData = async () => {
      try {
        if (role === "screener") {
          const { data } = await axios.get(`/api/screener/screenerList/screeners`);
          setRegisteredUsers(data);
        } else if (role === "candidate") {
          const { data } = await axios.get(`/api/candidate/candidateList/candidates`);
          setRegisteredUsers(data);
        }
      } catch (error) {
        console.error("Error fetching registered users:", error);
      }
    };
  
    fetchData();
  }, [role]);
  

  //setting position of cursor in input fields
  useEffect(() => {
    inputFields.forEach((input, index) => {
      firstRef.current[index].selectionStart = firstNamePosition[index];
      firstRef.current[index].selectionEnd = firstNamePosition[index];

      lastRef.current[index].selectionStart = lastNamePosition[index];
      lastRef.current[index].selectionEnd = lastNamePosition[index];

      emailRef.current[index].selectionStart = emailPosition[index];
      emailRef.current[index].selectionEnd = emailPosition[index];
    });
  }, [inputFields]);

  //maintaining focus of input field
  const handleFirstInput = (i, e) => {
    e.preventDefault();
    firstRef.current[i].focus();
  }

  const handleLastInput = (i, e) => {
    e.preventDefault();
    lastRef.current[i].focus();
  }

  const handleEmailInput = (i, e) => {
    e.preventDefault();
    emailRef.current[i].focus();
  }

  //Excel File upload handler
  const handleExcelFileUpload = (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split(".").pop().toLowerCase(); //get the extension of uploaded file
    const extensionList = [
      "xls",
      "xlt",
      "xla",
      "xlsx",
      "xltx",
      "xlsm",
      "xltm",
      "xlam",
      "xlsb",
      "csv",
    ]; //list of acceptable file extensions

    if (extensionList.includes(fileExtension)) {
      readExcel(file);
    } else {
      fileRef.current.value = null;
      setMessage("Incorrect file type!");
      setOpenSnackbar(true);
    }
  };

  //function to retrieve excel data
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
        const columnObject = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          defval: "",
        });
        columnHeaders = columnObject[0]; //store the headings of the excel file
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      //if excel file has values
      if (d.length > 0) {
        let condition =
          columnHeaders.includes("First Name") &&
          columnHeaders.includes("Last Name") &&
          columnHeaders.includes("Email");
        let rows = [];
        if (!condition) {
          setFileName("");
          fileRef.current.value = null;
          setMessage("Please check the format of your file!");
          setOpenSnackbar(true);
        } else {
          setFileName(file.name);
          //replacing the names of keys in the object
          d.map((item) => {
            item["firstName"] = item["First Name"];
            delete item["First Name"];
            item["lastName"] = item["Last Name"];
            delete item["Last Name"];
            item["email"] = item["Email"];
            delete item["Email"];
          });

          let errors = d.map(() => {
            return { firstName: "", lastName: "", email: "" };
          });

          rows = [...d];
          d.map((item, index) => {
            if (item.firstName) {
              rows[index].firstName = item.firstName;
              errors[index].firstName = "";
            } else {
              rows[index].firstName = "";
              errors[index].firstName = "First name is required";
            }
            if (item.lastName) {
              rows[index].lastName = item.lastName;
              errors[index].lastName = "";
            } else {
              rows[index].lastName = "";
              errors[index].lastName = "Last name is required";
            }
            if (item.email) {
              rows[index].email = item.email;
              errors[index].email = "";
            } else {
              rows[index].email = "";
              errors[index].email = "Email is required";
            }
            setInputFields(rows);
            setFieldErrors(errors);
          });
        }
      } else {
        setFileName("");
        fileRef.current.value = null;
        setMessage("Your file is empty!");
        setOpenSnackbar(true);
      }
    });
  };

  //handle file delete
  const handleRemoveFile = () => {
    setInputFields([
      {
        firstName: "",
        lastName: "",
        email: "",
      },
    ]);
    setFieldErrors([
      {
        firstName: "",
        lastName: "",
        email: "",
      },
    ]);
    setFileName("");
    fileRef.current.value = null;
  };

  //page back handler
  const backHandler = () => {
    setPageName("invitationList")
    document.title = "Screener | Invitations";
  };

  //send mail popup
  const saveHandler = () => {
    const id = sessionStorage.getItem("id");
    const companyId = sessionStorage.getItem("companyId");

    const newArray = inputFields.map((item) => {
      return { ...item, role, status: "Pending" };
    });

    // set true for popup
    const newData = {
      role,
      inputFields,
      companyId,
      id,
      newArray,
    };

    sendRef.current.disabled = true //disable the save button

    axios
      .post(`/api/admin/user/invitations`, newData)
      .then((res) => {
        setOpenDelete(false);
        setTimeout(() => {
          setPageName("invitationList")
        }, 2500);

        setOpenSnackbar(true);
        setMessage("Mail sent successfully!");
      })
      .catch((err) => console.log(err));
  };

  //error handling
  const rowHandling = () => {
    if (!role) {
      return setErrors({
        role: "Role is required!",
      });
    }

    let rows = [...inputFields];
    let error = [...fieldErrors];

    rows = rows.map((item, index) => {
      if (item.firstName === "") {
        error[index].firstName = "First Name is required";
      }
      if (item.lastName === "") {
        error[index].lastName = "Last Name is required";
      }
      if (item.email === "") {
        error[index].email = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item.email)
      ) {
        error[index].email = "Enter Valid email address";
      } else {
        error[index].email = "";
      }
    });
    setFieldErrors(error);
  };

  //form handler
  const formReplicationHandler = () => {
    rowHandling(); //to check for errors
    const condition = inputFields.find((item) => {
      if (
        item.firstName === "" ||
        item.lastName === "" ||
        item.email === "" ||
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item.email)
      ) {
        return true;
      } else return false;
    });

    if (condition) {
      return;
    } else {
      arrLength += 1;
      setInputFields([
        ...inputFields,
        {
          firstName: "",
          lastName: "",
          email: "",
        },
      ]);
      setFieldErrors([
        ...fieldErrors,
        {
          firstName: "",
          lastName: "",
          email: "",
        },
      ]);
    }
  };

  //handle delete full row
  const removeInputFields = (index) => {
    arrLength -= 1;
    const rows = [...inputFields];
    rows.splice(index, 1);
    fieldErrors.splice(index, 1);
    setInputFields(rows);
  };

  // handle input field change
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);

    if (name === "firstName") {
      //setting the location of the cursor
      const newCursorPositions = [...firstNamePosition];
      newCursorPositions[index] = evnt.target.selectionStart;
      setFirstNamePosition(newCursorPositions);

      fieldErrors[index].firstName = "";
    } else if (name === "lastName") {
      //setting the location of the cursor
      const newCursorPositions = [...lastNamePosition];
      newCursorPositions[index] = evnt.target.selectionStart;
      setLastNamePosition(newCursorPositions);

      fieldErrors[index].lastName = "";
    } else {
      //setting the location of the cursor
      const newCursorPositions = [...emailPosition];
      newCursorPositions[index] = evnt.target.selectionStart;
      setEmailPosition(newCursorPositions);

      fieldErrors[index].email = "";
    }
  };

  // handle form submit
  const onSubmit = (e) => {
    e.preventDefault();
    if (!role) {
      return setErrors({
        role: "Role is required!",
      });
    }

    let rows = [...inputFields];
    let error = [...fieldErrors];

    rows = rows.map((item, index) => {
      if (item.firstName === "") {
        error[index].firstName = "First Name is required";
        setFieldErrors(error);
      }
      if (item.lastName === "") {
        error[index].lastName = "Last Name is required";
        setFieldErrors(error);
      }
      if (item.email === "") {
        error[index].email = "Email is required";
        setFieldErrors(error);
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item.email)
      ) {
        error[index].email = "Enter Valid email address";
        setFieldErrors(error);
      } else {
        registeredUsers.map((user) => {
          if (role === "screener") {
            if (user.screenerEmail === item.email) {
              error[index].email = "Email already registered";
              setFieldErrors(error);
            }
          } else if (role === "candidate") {
            if (user.candidateEmail === item.email) {
              error[index].email = "Email already registered";
              setFieldErrors(error);
            }
          }
        });
      }
    });

    const condition = fieldErrors.find((item) => {
      return item.firstName !== "" || item.lastName !== "" || item.email !== "";
    });

    if (condition) {
      setOpenDelete(false);
    } else {
      setOpenDelete(true);
    }
  };

  return (
    <div>
      <div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}` }}>

        <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
          <Card id="main-edit" className="add-recruiter-form form-card">
            <Grid
              container
              spacing={0}
              // direction="row"
              className="add-recruiter-card-container add-clients-container"
            >
              <Grid
                item
                xs={4}
                sm={4}
                md={6}
                lg={6}
                className="container-heading-title admin-table"
              >
                <h1>Invitation</h1>
              </Grid>
              <Grid
                item
                xs={7}
                sm={7}
                md={5}
                lg={5}
                className="container-heading-buttons add-recruiter-button"
                style={{ width: "100%" }}
              >
                <div style={{ display: "flex", gap: "10px", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
                  <button className="add-recruiter" onClick={backHandler}>
                    Back
                  </button>
                </div>
              </Grid>
            </Grid>
          </Card>

          {/* Form card */}
          <Card
            id="skills-edit"
            className="add-recruiter-form-container profile-card"
          >
            <div class="input-container">
              <div className="send-invitation-top-card">
                <div class="login-radio-container csv-button-container">
                  <div>
                    <label className="radio-label">User Type*</label>

                    <FormControl class="login-radio-group">
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={role}
                        onChange={(e) => {
                          setRole(e.target.value);
                          setErrors({
                            role: "",
                          });
                        }}
                      >
                        <FormControlLabel
                          value="candidate"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 18,
                                },
                              }}
                              required={true}
                            />
                          }
                          label="Job Seeker"
                        />
                        <FormControlLabel
                          value="screener"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 18,
                                },
                              }}
                              required={true}
                            />
                          }
                          label="Screener"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="button-container csv-action-container">
                    <div className="upload-resume csv-file-container">
                      {fileName === "" ? (
                        ""
                      ) : (
                        <span>
                          {fileName}{" "}
                          <CloseIcon
                            onClick={handleRemoveFile}
                            fontSize="small"
                            sx={{
                              cursor: "pointer",
                              padding: 0,
                              margin: "1px",
                            }}
                          />
                        </span>
                      )}

                      <label
                        style={{
                          display: `${fileName === "" ? "" : "none"}`,
                        }}
                        className="resume-file-upload outlined-btn"
                      >
                        <input
                          type="file"
                          ref={fileRef}
                          onChange={handleExcelFileUpload}
                        />
                        <FileUploadIcon
                          fontSize="small"
                          sx={{ marginRight: "5px", color: "#F06161" }}
                        />
                        <span>Upload CSV</span>
                      </label>
                    </div>

                    <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}>

                      <Tooltip title="The 'Download Template' downloads a CSV file with pre-defined column headings. Fill in the necessary details, save, and upload the same. The columns marked with * are mandatory.">
                        <InfoOutlinedIcon
                          fontSize="small"
                          style={{ color: "#4c4c5d", fontSize: "16px" }}
                        />


                      </Tooltip>
                      <a
                        className="download-template-link"
                        href={templateFileLink}
                        download
                      >
                        Download Template
                        <FileDownloadIcon fontSize="small" />
                      </a>
                    </div>
                  </div>
                </div>
                <div>
                  <span className="text-danger">{errors.role}</span>
                </div>
                {inputFields.map((data, index) => {
                  const { firstName, lastName, email } = data;
                  return (
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "end",
                      }}
                    >
                      <div className="invitation-input-field-container">
                        <div class="input-field">
                          {index === 0 ? (
                            <label className="date-label">
                              First Name*
                            </label>
                          ) : (
                            ""
                          )}
                          <input
                            className={`email`}
                            key={index}
                            ref={el => (firstRef.current[index] = el)}
                            autoFocus={
                              firstRef.current[index] === document.activeElement
                            }
                            onChange={(evnt) => handleChange(index, evnt)}
                            onInput={(e) => handleFirstInput(index, e)}
                            defaultValue={firstName}
                            id="email"
                            type="text"
                            name="firstName"
                            placeholder="First Name*"
                          />
                          <div style={{ height: "20px" }}>
                            <span className="text-danger">
                              {fieldErrors[index]?.firstName}
                            </span>
                          </div>
                        </div>

                        <div class="input-field">
                          {index === 0 ? (
                            <label className="date-label">Last Name*</label>
                          ) : (
                            ""
                          )}
                          <input
                            className="email"
                            autoComplete="email"
                            onChange={(evnt) => handleChange(index, evnt)}
                            onInput={(e) => handleLastInput(index, e)}
                            ref={el => (lastRef.current[index] = el)}
                            autoFocus={
                              lastRef.current[index] === document.activeElement
                            }
                            value={lastName}
                            key={index}
                            id="email"
                            type="text"
                            name="lastName"
                            placeholder="Last Name*"
                          />
                          <div style={{ height: "20px" }}>
                            <span className="text-danger">
                              {fieldErrors[index]?.lastName}
                            </span>
                          </div>
                        </div>

                        <div class="input-field">
                          {index === 0 ? (
                            <label className="date-label">Email ID*</label>
                          ) : (
                            ""
                          )}
                          <input
                            className="email"
                            autoComplete="email"
                            onChange={(evnt) => handleChange(index, evnt)}
                            value={email}
                            key={index}
                            onInput={(e) => handleEmailInput(index, e)}
                            ref={el => (emailRef.current[index] = el)}
                            autoFocus={
                              emailRef.current[index] === document.activeElement
                            }
                            id="email"
                            type="text"
                            name="email"
                            placeholder="Email Id*"
                          />
                          <div style={{ height: "20px" }}>
                            <span className="text-danger">
                              {fieldErrors[index]?.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div style={{ marginBottom: "28px" }}>
                        {inputFields.length !== 1 && (
                          <DeleteIcon
                            fontSize="small"
                            onClick={() => removeInputFields(index)}
                            className="deleteIcon"
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
                <div
                  className="add-new-row-button"
                  onClick={formReplicationHandler}
                >
                  <div>
                    <AddIcon />
                  </div>
                  <div>
                    <label>ADD </label>
                  </div>
                </div>
                <div className="button-container navigation-btn-container next-btn-container" style={{ justifyContent: "flex-end" }}>
                  <button
                    className="btn-style filled-btn filled-btn-animation"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {" "}
                    Send
                  </button>
                </div>
              </div>
            </div>
          </Card>
        </Box>
      </div>

      {/* Send confirmation dialog */}
      <Dialog
        open={openDelete}
        onClose={openDelete}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={11.5}
              sm={11.5}
              md={11.5}
              lg={11.5}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure ?</h5>
                <p>{`Are you sure you want to send email invites to ${role}(s) ?`}</p>
                <p>This process cannot be reverted</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="button-container delete-btn-container">
          <button className="warning-btn-style filled-btn filled-btn-animation" onClick={() => setOpenDelete(false)}>
            {" "}
            Cancel
          </button>
          <button ref={sendRef} className="warning-btn-style outlined-btn outlined-btn-animation" onClick={saveHandler}>
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      <UserSnackBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
      />
    </div >
  );
};

export default SendInvitation;
