import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation, Outlet } from "react-router-dom";


// MUI imports
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import CompanyMenu from "../../userProfiles/CompanyMenu";
import Spinner from "../../loaders/Spinner";
import "../components/SkillBars.css";
import { Route, Routes } from "react-router-dom";
import ViewCandidateDetails from "./CandidateResults/ViewCandidateDetails";

var drawerWidth = 200;

// Main
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const SkillBars = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Determine tab based on URL
    const path = location.pathname;
    if (path.includes("library")) setValue(1);
    else if (path.includes("test-management")) setValue(2);
    else if (path.includes("candidate-results")) setValue(3);
    else setValue(0);
  }, [location.pathname]);

  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

  // UserId and Role
  const id = sessionStorage.getItem("id");
  const role = sessionStorage.getItem("role");

  const [isLoading, setIsLoading] = useState(true);
  const [imageWidth, setImageWidth] = useState("sidebar-close-image");
  const [open, setOpen] = useState(false);
  const [sidebarOpen, setsidebarOpen] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState(null);
  const [defaultAvatar, setDefaultAvatar] = useState();
  const [profileImage, setProfileImage] = useState(profileAvatar);
  const [croppedImg, setCroppedImg] = useState("");
  const [interacted, setInteracted] = useState();
  const [openMainCardEdit, setopenMainCardEdit] = useState(false);
  const [openPocCardEdit, setOpenPocCardEdit] = useState(false);

  const [value, setValue] = useState(
    parseInt(localStorage.getItem("selectedTab") || "0")
  );

  const [MainCardEditDetails, setMainCardEditDetails] = useState({});

  useEffect(() => {
    axios.get(`/api/admin/${id}`).then((res) => {
      const {
        companyName,
        designation,
        companyEmail,
        companyPhone,
        about,
        startYear,
        location,
        croppedAreaPixels,
        croppedArea,
        defaultAvatar,
        profileImage,
        croppedImage,
        interacted,
        croppedImagePublicId,
        zoom,
        imagePublicId,
        profileCompletionPercentage,
        socialMedia: { linkedin, website },
      } = res.data.user;

      setCompanyName(companyName);
      setDefaultAvatar(defaultAvatar);
      setProfileImage(profileImage);
      setCroppedImg(croppedImage);
      setInteracted(interacted);

      setMainCardEditDetails({
        id: id,
        role: role,
        companyName: companyName,
        profileTitle: designation,
        email: companyEmail,
        phone: companyPhone,
        about: about,
        startYear: startYear,
        location: location,
        address: address,
        croppedAreaPixels: croppedAreaPixels,
        croppedArea: croppedArea,
        defaultAvatar: defaultAvatar,
        profileImage: profileImage,
        croppedImg: croppedImg,
        imagePublicId: imagePublicId,
        interacted: interacted,
        croppedImagePublicId: croppedImagePublicId,
        zoom: zoom,
        profileCompletionPercentage: profileCompletionPercentage,
        linkedinURL: linkedin,
        websiteURL: website,
      });

      setIsLoading(false);
    });
  }, [id, role, openMainCardEdit, openPocCardEdit, address]);

  useEffect(() => {
    open
      ? setImageWidth("sidebar-open-image")
      : setImageWidth("sidebar-close-image");
  }, [open]);

  useEffect(() => {
    // Mocked fetch call
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  //title
  useEffect(() => {
    if (companyName) document.title = `Screener | ${companyName} `;
  }, [companyName]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const routes = [
      "/admin/skill-assessment/",
      "/admin/skill-assessment/library",
      "/admin/skill-assessment/test-management",
      "/admin/skill-assessment/candidate-results",
    ];
    navigate(routes[newValue]);
    localStorage.setItem("selectedTab", newValue); // Save selected tab to local storage
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      {(role === "admin" || role === "recruiter") && (
        <>
          <CompanyMenu
            open={open}
            setOpen={setOpen}
            sidebarOpen={sidebarOpen}
            setsidebarOpen={setsidebarOpen}
            companyName={companyName}
            croppedImg={croppedImg}
            profileImage={profileImage}
            interacted={interacted}
            defaultAvatar={defaultAvatar}
          />

          <Box className="options">
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              variant="scrollable" // Enable scrollable tabs
              scrollButtons="auto" // Show scroll buttons when necessary
              TabIndicatorProps={{
                style: {
                  backgroundColor: "#1976d2", // Example: customize tab indicator color
                },
              }}
            >
              <Tab className="tabs" label="Dashboard" />
              <Tab label="Library" />
              <Tab label="Test Management" />
              <Tab label="Candidate Results" />
            </Tabs>
          </Box>

          <Main id={`${sidebarOpen}`}>
            <div style={{ padding: "20px" }}>
              <Outlet/>
            </div>
          </Main>
        </>
      )}
    </>
  );
};

export default SkillBars;
