import React from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

// Mock data for candidate details (replace with actual API data)
const candidateDetails = {
  name: "John Doe",
  timeTaken: "30m",
  score: 15,
  percentage: 50,
  status: "Passed",
  problemsAttempted: 20,
  totalProblems: 20,
  performance: {
    "Linear Algebra": 100,
    "Analytical Reasoning": 100,
    Aptitude: 100,
    Substitution: 100,
    "Sentence Structure": 66.7,
    "Logical Reasoning": 50,
    "Verbal Reasoning": 50,
    "Profit and Loss": 50,
    Discount: 50,
    Synonyms: 50,
  },
};

const CandidateDetails = () => {
  const { id } = useParams();
  // Fetch candidate details based on ID

  return (
    <Box p={4}>
      <Grid container alignItems="center" justifyContent="center" spacing={2} mb={3}>
        <Grid item>
          <Avatar sx={{ bgcolor: "#f06161", width: 56, height: 56 }}>
            {candidateDetails.name.charAt(0)}
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h4" gutterBottom color="#f06161" fontWeight="bold">
            {candidateDetails.name}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ mb: 3 }} />

      <Box mb={3}>
        <Typography variant="h6" color="#f06161" fontWeight="bold">
          Assessment Summary
        </Typography>
        <Paper elevation={3} sx={{ padding: "20px",   }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" gutterBottom>
                <strong>Time Taken:</strong> {candidateDetails.timeTaken}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Score:</strong> {candidateDetails.score} ({candidateDetails.percentage}%)
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Status:</strong>{" "}
                <span
                  style={{
                    color: candidateDetails.status === "Passed" ? "#4caf50" : "#f06161",
                  }}
                >
                  {candidateDetails.status}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" gutterBottom>
                <strong>Problems Attempted:</strong> {candidateDetails.problemsAttempted} out of {candidateDetails.totalProblems}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Box mb={3}>
        <Typography variant="h6" color="#f06161" fontWeight="bold">
          Performance Summary
        </Typography>
        <Paper elevation={3} sx={{ padding: "20px",   }}>
          <List>
            {Object.entries(candidateDetails.performance).map(([subject, score], index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={<Typography fontWeight="bold">{subject}</Typography>}
                  secondary={`${score}%`}
                  secondaryTypographyProps={{
                    color: score >= 75 ? "#4caf50" : "#f06161",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Box>

      <Box mb={3}>
        <Typography variant="h6" color="#f06161" fontWeight="bold">
          Test Events Timeline
        </Typography>
        <Paper elevation={3} sx={{ padding: "20px",   }}>
          <Typography>No activity to show yet.</Typography>
        </Paper>
      </Box>
    </Box>
  );
};

export default CandidateDetails;
