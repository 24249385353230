import React, { useEffect, useState } from 'react'

//MUI Imports
import Slide from "@mui/material/Slide";
import { Box, Card, Grid } from "@mui/material";
import BusinessIcon from '@mui/icons-material/BusinessCenter';
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { TextField, Dialog, DialogContent } from "@mui/material";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";


// import data from './requirement.json'
import Skills from '../../../modals/skills';
import axios from 'axios'

//Snackbar
import UserSnackbar from '../../../snackBars/UserSnackbar';

const EditPositions = ({ setAddPosition, id, selectedPositionId, setAllPositions }) => {
    const data = Skills();
    const companyId = sessionStorage.getItem("companyId");
    // states
    const [positionDetails, setPositionDetails] = useState({
        client: {},
        position: '',
        positionsCount: 1,
        skills: [],
        description: '',
        status: "Active",
        priority: "High",
        rate: ""
    })

    const [allClients, setAllClients] = useState([])
    const [clientName, setClientName] = useState("")

    const [clientDetails, setClientDetails] = useState({})
    const [createdBy, setCreatedBy] = useState({})

    const [skillsList, setSkillsList] = useState([])
    const [status, setStatus] = useState("")

    //snackBar states
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [message, setMessage] = useState("")
    //confimation dialog open state
    const [dialogOpen, setDialogOpen] = useState(false)
    const [error, setError] = useState({
        position: '',
        client: '',
        status: '',
        rate: '',
        skills: '',
    })

    //for fetching all positions
    useEffect(() => {
        axios.get(`/api/admin/get-all-positions/${id}`).then((res) => {
            if (res.data.position.length > 0) {
                let filteredArray = res.data.position.filter(pos => pos._id == selectedPositionId)
                const { status, client, position, skills, createdBy, description, rate, priority, positionsCount } = filteredArray[0]
                // console.log(status, client, position, skills, createdBy, description, rate, priority, positionsCount)

                setSkillsList(skills)
                setClientDetails(client)
                setClientName(client.name)
                setStatus(status)
                setCreatedBy(createdBy)
                setPositionDetails({ ...positionDetails, position, positionsCount, rate, description, priority })
                // setAllClients(res.data);
            }
        })
    }, [])

    //for fetching clients
    useEffect(() => {
        axios.get(`/api/admin/get-all-clients/${id}`).then((res) => {
            if (res.data.length > 0) {
                // const filteredArray = res.data.filter(client => client.status === statusSelected)
                // console.log(filteredArray)
                setAllClients(res.data);
            }
        })
    }, [])

    // handle input field changes
    const handleChangeFormFields = (e) => {
        switch (e.target.name) {
            case "Position":
                setPositionDetails({ ...positionDetails, position: e.target.value })
                setError({})
                break;
            case "clientName":
                setClientName(e.target.value)
                setClientDetails({})
                setError({})
                break;
            case "Rate":
                setPositionDetails({ ...positionDetails, rate: e.target.value })
                setError({})
                break;
            case "Description":
                setPositionDetails({ ...positionDetails, description: e.target.value })
                setError({})
                break;
            case "Status":
                setStatus(e.target.value)
                setError({})
                break;
            default:
        }
    };


    const checkPosition = (e) => {
        e.preventDefault()
        axios.post(`/api/admin/check-position/${id}/${selectedPositionId}`).then(res => {
            console.log(res, "Show Popup ")

            if (!res.data) {

                deleteHandler()

            } else {
                setDialogOpen(true)
            }
        }).catch(err => {
            console.log(err, "Error")
        })
    }

    //delete handler
    const deleteHandler = () => {

        axios.post(`/api/admin/delete-position/${id}/${selectedPositionId}`).then(res => {
            console.log(res, "Edited ")
            setOpenSnackbar(true)
            setMessage(res.data.msg)
            setAllPositions(res.data.finalArr)
            setTimeout(() => {
                setAddPosition(false)
            }, 1000)
        }).catch(err => {
            console.log(err, "Error")
        })
    }

    //edit handler
    const EditHandler = (e) => {
        e.preventDefault()
        //Errors
        if (!positionDetails.position) {
            return setError({ position: 'Position is required' })
        }
        else if (!Object.keys(clientDetails).length) {
            console.log("Check")
            return setError({ client: 'Client Name is required' })
        } else if (!status) {
            return setError({ status: 'Status is required' })
        } else if (!positionDetails.rate) {
            return setError({ rate: 'Pay Rate is required' })
        } else if (!skillsList.length) {
            return setError({ skills: 'Add atleast one skill' })
        }
        else {
            //Send to DB
            console.log("Send to Backend")
            // let createdBy = {
            //     id: id, email: companyEmail,
            //     name: companyName,
            //     createdDate: new Date().toLocaleDateString(),
            //     createdTime: new Date().toLocaleTimeString()
            // }
            let newData = {
                client: clientDetails,
                position: positionDetails.position,
                description: positionDetails.description,
                skills: skillsList,
                createdBy,
                status,
                rate: positionDetails.rate,
                positionsCount: positionDetails.positionsCount,
                priority: positionDetails.priority
            }

            axios.post(`/api/admin/edit-position/${id}/${selectedPositionId}`, newData).then(res => {
                console.log(res, "Edited ")
                setOpenSnackbar(true)
                setMessage(res.data.msg)
                setTimeout(() => {
                    setAddPosition(false)
                }, 1000)
            }).catch(err => {
                console.log(err, "Error")
            })
            console.log(newData, "Inside Submit")
        }
    }



    // console.log(positionDetails, clientDetails, clientName, status, skillsList)

    //MUI autocomplete options
    const clientOptions = allClients?.map(client => {
        return {
            name: client.companyName,
            label: `${client.companyName} - ${client.email}`,
            value: {
                id: client._id,
                email: client.email,
                name: client.companyName
            }
        }
    })

    const statusOption = [
        {
            name: "Active",
            label: "Active",
        },
        {
            name: "Closed",
            label: "Closed",
        }]













    //Submit handler
    // const submitData = () => {
    //     let finalData = {}
    //     if (mode === "Edit") {
    //         finalData = [{
    //             about: about,
    //             id: selectedClientId,
    //             zoom: zoom,
    //             crop: crop,
    //             website: website,
    //             status: status,
    //             isAvatar: isAvatar,
    //             croppedAreaPixels: croppedAreaPixels,
    //             profileImage: profileImage,
    //             croppedImage: croppedImage,
    //             imagePublicId: imagePublicId,
    //             croppedImagePublicId: croppedImagePublicId,
    //             companyName: companyName,
    //             email: email,
    //             phone: phone,
    //             address1: address1,
    //             address2: address2,
    //             city: city,
    //             state: state,
    //             stateId: stateId,
    //             country: country,
    //             countryId: countryId,
    //             postalCode: postalCode,
    //             positionDetails: positionDetails,
    //             interacted: interacted
    //         }]
    //     } else {
    //         finalData = [{
    //             client: about,
    //             zoom: zoom,
    //             crop: crop,
    //             website: website,
    //             status: status,
    //             isAvatar: isAvatar,
    //             croppedAreaPixels: croppedAreaPixels,
    //             profileImage: profileImage,
    //             croppedImage: croppedImage,
    //             imagePublicId: imagePublicId,
    //             croppedImagePublicId: croppedImagePublicId,
    //             companyName: companyName,
    //             email: email,
    //             phone: phone,
    //             address1: address1,
    //             address2: address2,
    //             city: city,
    //             state: state,
    //             stateId: stateId,
    //             country: country,
    //             countryId: countryId,
    //             postalCode: postalCode,
    //             positionDetails: positionDetails,
    //             interacted: interacted
    //         }]
    //     }


    //     const formData = new FormData();
    //     formData.append("finalData", JSON.stringify(finalData))


    //     console.log(formData.get("finalData"))


    //     axios.post(`/api/admin/${mode === "Edit" ? "edit-client" : "add-client"}/${id}/${companyId}`, formData, {
    //         headers: { "Content-Type": "multipart/form-data" },
    //     }).then((res) => {
    //         if (mode === "Edit") {
    //             setMessage("Client Updated Successfully")
    //         } else {
    //             setMessage("Client Added Successfully")
    //         }
    //         setOpenSnackbar(true)
    //         setTimeout(() => {
    //             setAddClient(false)
    //         }, 2000)
    //     }).catch((err) => {
    //         if (err.response.status === 404) {
    //             console.log(err)
    //             setMessage("Client already exists")
    //             setOpenSnackbar(true)
    //         }
    //     })
    // }




    // const handleSubmit = () => {
    //     if (positionDetails.pocEmail) {
    //         if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(positionDetails.pocEmail)) {
    //             return setError({ email: "Email is Invalid" })
    //         }
    //     }
    //     if (!phoneIsValid) {
    //         return setError({
    //             phone: "Invalid Phone Number",
    //         })
    //     }


    //     // setPositionDetails({ ...positionDetails, pocPhone: phone })
    //     let data = { ...clientData, positionDetails }
    //     console.log(data)
    //     setClientData(data)
    //     submitData();
    //     setPreviousPage(false)
    // }




    // const goToPreviousPage = () => {


    //     //store current entered data
    //     const pocObj = {
    //         positionDetails: positionDetails,
    //     }
    //     let data = { ...clientData, ...pocObj }
    //     setClientData(data)
    //     setPreviousPage(true)
    //     if (nextPage) {
    //         setCount(count - 1)
    //     } else {
    //         setCount(count + 1)
    //     }
    // }




    // handle input field change
    const handleChange = (evnt) => {
        const { name, value } = evnt.target;
        switch (name) {
            case 'PocName': setPositionDetails({ ...positionDetails, pocName: value })
                break;
            case 'PocEmail': setPositionDetails({ ...positionDetails, pocEmail: value })
                setError({ email: "" })
                break;
            case 'PocTitle': setPositionDetails({ ...positionDetails, pocTitle: value })
                break;
        }




    };








    console.log(positionDetails, "POCDETAILS")










    return (
        <div>
            <Slide direction={"left"} in={true} timeout={500}>
                <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                    <Card
                        id="main-edit"
                        className=" form-card"
                        style={{
                            margin: "40px 70px",
                            padding: "15px 20px 18px 20px ",
                            boxShadow: "none",
                        }}
                    >


                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={8}
                                md={5}
                                lg={12}
                            >
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <BusinessIcon sx={{ color: "#2c3e50" }} />
                                    <h5 style={{ color: "#283848" }}>Position Details</h5>
                                </div>
                            </Grid>
                            <Grid container spacing={0} justifyContent="flex-end">


                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}


                                >
                                    <div >
                                        <form noValidate >
                                            <Grid
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                sx={{
                                                    display: "flex"
                                                }}
                                            >


                                                <Grid
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}


                                                >
                                                    <div className="input-container" >


                                                        <div className="split-inputs">
                                                            <div className="first-column">
                                                                <div class="input-field-modal">
                                                                    <label className="date-label">Position Title*</label>
                                                                    <input
                                                                        class="company"
                                                                        type="text"
                                                                        name="Position"
                                                                        className={`${error.position && "input-field-error"}`}
                                                                        value={positionDetails.position}
                                                                        onChange={(evnt) => handleChangeFormFields(evnt)}
                                                                        placeholder="Ex: Data Scientist"
                                                                    />
                                                                    <div style={{ height: "20px" }}><span className='text-danger'>{error.position}</span>
                                                                    </div>
                                                                </div>

                                                                <div class="input-field-modal"   >
                                                                    <label className="date-label" style={{ marginBottom: '6px' }}>Client Name*</label>
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        disableClearable
                                                                        id="combo-box-demo"
                                                                        options={clientOptions}
                                                                        getOptionLabel={(option) => option?.label}
                                                                        sx={{ width: 300 }}
                                                                        inputValue={clientName}
                                                                        onChange={(event, newValue) => {
                                                                            setClientName(newValue?.name);
                                                                            setClientDetails(newValue?.value);
                                                                            setError({});
                                                                        }}
                                                                        renderInput={(params) => {
                                                                            return (
                                                                                <TextField
                                                                                    name="clientName"
                                                                                    className={`${error.client && "input-field-error-client"}`}
                                                                                    style={{ width: "125%", background: '#eeee' }}
                                                                                    onChange={handleChangeFormFields}
                                                                                    {...params}
                                                                                    placeholder="Search Clients"
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                    <div style={{ height: "20px" }}><span className='text-danger'>{error.client}</span>
                                                                    </div>
                                                                </div>



                                                                <div class="input-field-modal">
                                                                    <label className="date-label">Status*</label>
                                                                    <select
                                                                        name="Status"
                                                                        style={{ outline: "none" }}
                                                                        defaultValue={status}
                                                                        value={status}
                                                                        className={`${error.status && "input-field-error"}`}
                                                                        onChange={handleChangeFormFields}
                                                                    >
                                                                        <option value="Active">Active</option>
                                                                        <option value="Closed">Closed</option>
                                                                    </select>

                                                                    <div style={{ height: "20px" }}><span className='text-danger'>{error.status}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="split-inputs">
                                                                    <div class="input-field-modal">
                                                                        <label className="date-label">Rate*</label>
                                                                        <input
                                                                            class="company"
                                                                            type="number"
                                                                            name="Rate"
                                                                            className={`${error.rate && "input-field-error"}`}
                                                                            value={positionDetails.rate}
                                                                            onChange={(evnt) => handleChangeFormFields(evnt)}
                                                                            placeholder="Pay Rate"
                                                                        />
                                                                        <div style={{ height: "20px" }}>
                                                                            <span className='text-danger'>{error.rate}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="second-column">
                                                                <div class="input-field-modal"   >
                                                                    <form noValidate>
                                                                        <label className="date-label">Skills*</label>
                                                                        <Autocomplete
                                                                            className='requirements-autocomplete'
                                                                            sx={{
                                                                                "& fieldset": { border: "none" }
                                                                            }}
                                                                            value={skillsList}
                                                                            onChange={(event, newValue) => {
                                                                                setSkillsList(newValue);
                                                                                setError({})
                                                                            }}
                                                                            multiple
                                                                            id="tags-filled"
                                                                            options={data.map((option) => option.skill)}
                                                                            freeSolo
                                                                            renderTags={(skillsList, getTagProps) =>
                                                                                skillsList.map((option, index) => (
                                                                                    <Chip
                                                                                        variant="outlined"
                                                                                        label={option}
                                                                                        {...getTagProps({ index })}
                                                                                    />
                                                                                ))
                                                                            }
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    // multiline
                                                                                    // minRows={2}
                                                                                    className={`${error.skills && "input-field-error-skills"}`}
                                                                                    {...params}
                                                                                    variant="filled"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </form>
                                                                    <div style={{ height: "20px" }}><span className='text-danger'>{error.skills}</span></div>
                                                                </div>

                                                                <div class="input-field-modal"   >
                                                                    <label className="date-label" >Description</label>
                                                                    <textarea
                                                                        placeholder="Description"
                                                                        // initialValue={positionDetails.description}
                                                                        rows={2}
                                                                        name="Description"
                                                                        style={{ fontSize: "12px", width: "100%" }}
                                                                        variant="standard"
                                                                        maxLength={120}
                                                                        value={positionDetails.description}
                                                                        onChange={(evnt) => handleChangeFormFields(evnt)}
                                                                    />
                                                                    <div style={{ height: "20px" }}>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>


                                            </Grid>


                                        </form>
                                    </div>
                                </Grid>


                            </Grid>
                            <div className="button-container" style={{ width: "100%", justifyContent: "space-between" }}>

                                <button className="btn-style outlined-btn outlined-btn-animation"
                                    onClick={checkPosition}
                                >
                                    Delete
                                </button>

                                <button className="btn-style filled-btn filled-btn-animation"
                                    onClick={EditHandler}
                                >
                                    Save
                                </button>


                            </div>
                        </Grid>
                    </Card>
                </Box>
            </Slide>
            {/* Confirmation Dialog */}
            < Dialog
                fullWidth
                maxWidth="xs"
                open={dialogOpen}
                onClose={setDialogOpen}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5 style={{ margin: '10px 0' }}>You cannot delete this position</h5>
                                <p>This position is currently being used in one or more interview(s)</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container" style={{ justifyContent: 'center', marginTop: 0 }}>
                    <button className="warning-btn-style filled-btn filled-btn-animation" onClick={() => {
                        setDialogOpen(false);

                    }}>
                        {" "}
                        Okay
                    </button>
                    {/* <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={deleteHandler}>
                        {" "}
                        Confirm
                    </button> */}
                </div>
            </Dialog >

            <UserSnackbar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />




        </div>
    )
}


export default EditPositions

