// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
    background-color: #F06161; /* Default color */
    color: white; /* Default text color */
  }
  
  .custom-button:hover {
    background-color: #d04b4b; /* Hover color */
  }
  
  .custom-button.Mui-disabled {
    background-color: #d3d3d3 !important; /* Gray color for disabled */
    color: #a9a9a9 !important; /* Light gray text color */
    pointer-events: none; /* Prevent clicking */
  }
  
  .download-template-link {
    font-size: var(--font-size-1-2px) !important;
    color: var(--blue-gray) !important;
  }
  
  .download-template-link:hover {
    transform: scale(1.05);
    color: var(--orange) !important;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/skill-assessment/components/TestManagement/CandidatesDialogs/AddCandidate.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,kBAAkB;IAC7C,YAAY,EAAE,uBAAuB;EACvC;;EAEA;IACE,yBAAyB,EAAE,gBAAgB;EAC7C;;EAEA;IACE,oCAAoC,EAAE,4BAA4B;IAClE,yBAAyB,EAAE,0BAA0B;IACrD,oBAAoB,EAAE,qBAAqB;EAC7C;;EAEA;IACE,4CAA4C;IAC5C,kCAAkC;EACpC;;EAEA;IACE,sBAAsB;IACtB,+BAA+B;IAC/B,eAAe;EACjB","sourcesContent":[".custom-button {\n    background-color: #F06161; /* Default color */\n    color: white; /* Default text color */\n  }\n  \n  .custom-button:hover {\n    background-color: #d04b4b; /* Hover color */\n  }\n  \n  .custom-button.Mui-disabled {\n    background-color: #d3d3d3 !important; /* Gray color for disabled */\n    color: #a9a9a9 !important; /* Light gray text color */\n    pointer-events: none; /* Prevent clicking */\n  }\n  \n  .download-template-link {\n    font-size: var(--font-size-1-2px) !important;\n    color: var(--blue-gray) !important;\n  }\n  \n  .download-template-link:hover {\n    transform: scale(1.05);\n    color: var(--orange) !important;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
