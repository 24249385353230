import React, { useEffect, useState, useRef, useDebugValue } from "react";
import axios from "axios";
import * as XLSX from "xlsx";


import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Dialog, DialogContent, IconButton } from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';
import InActiveIcon from '@mui/icons-material/DomainDisabled';
import ActiveIcon from '@mui/icons-material/Business';
import CompanyMenu from "../../userProfiles/CompanyMenu";
import TableLoader from "../../loaders/TableLoader";
import AddClient from "./Add Client/AddClient";
import UserSnackBar from "../../snackBars/UserSnackbar";
import EditClient from "./Add Client/EditClient";
import PageNotFound from "../../ErrorHandling/PageNotFound";
import Footer from "../../../Footer/Footer";


const ClientsList = () => {
    //table heading
    const columns = [
        {
            title: "Company Name",
            width: "30%",
        },
        {
            title: "POC Name",
            width: "25%",
        },
        {
            title: "POC Email",
            width: "25%",
        },
        {
            title: "POC Phone",
            width: "15%",
        },
        {
            title: "Status",
            width: "15%",
        },
        {
            title: "Action",
            width: "15%",
        },


    ];

    //Account Id and role
    const id = sessionStorage.getItem("id");
    const companyId = sessionStorage.getItem("companyId");  //ref id
    const role = sessionStorage.getItem("role");
    const compId = sessionStorage.getItem("compId");
    const profileType = sessionStorage.getItem("profileType");

    //Navbar states
    const [open, setOpen] = useState();
    const [sidebarOpen, setsidebarOpen] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [interacted, setInteracted] = useState("");
    const [croppedImg, setCroppedImg] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [defaultAvatar, setDefaultAvatar] = useState("");
    let profileAvatar = "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

    //handle states
    const [addClient, setAddClient] = useState(false);
    const [mode, setMode] = useState("");
    const [selectedClientId, setSelectedClientId] = useState("")
    const [selectedClientStatus, setSelectedClientStatus] = useState(null)
    const [selectedClientData, setSelectedClientData] = useState({})

    // filter states
    const [statusFilter, setStatusFilter] = useState("Active");
    const [statusSelected, setStatusSelected] = useState(true);
    const [filter, setFilter] = useState("");


    // array to store list of clients
    const [allClients, setAllClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([])


    // snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("")
    //Loader
    const [tableLoading, setTableLoading] = useState(true);


    //pagination states
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);


    const [dialogOpen, setDialogOpen] = useState(false)

    //Confirmation dialog state
    const [openCloseDialog, setOpenCloseDialog] = useState(false)

    const [hasClientsAccess, setHasClientsAccess] = useState(true)
    const [moduleAccess, setModuleAccess] = useState([])

    useEffect(() => {
        document.title = "Screener | Clients";

        axios.get(`/api/admin/userRole/side-list/${companyId}`)
            .then(res => {
                console.log(res.data[0].profileType)
                let modules = res.data[0].profileType.filter(item => item.roleName.toLowerCase() === profileType.toLowerCase())
                console.log(modules)
                modules = modules[0].modules.filter(item => item.title === "Clients")
                console.log(modules)
                if (!modules[0].checked) setHasClientsAccess(false)
                setModuleAccess(modules[0])
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })

        if (profileType.toLowerCase() == "recruiter") {
            const { data } = axios.get(`/api/recruiter/${id}`).then((res) => {
                const {
                    defaultAvatar,
                    profileImage,
                    croppedImage,
                    interacted,
                    firstName,
                } = res.data.user;
                setDefaultAvatar(defaultAvatar);
                setProfileImage(profileImage);
                setCroppedImg(croppedImage);
                setInteracted(interacted);
                setCompanyName(firstName);
            });
        } else {
            const { data } = axios.get(`/api/myUsers/${id}`).then((res) => {
                const {
                    defaultAvatar,
                    profileImage,
                    croppedImage,
                    interacted,
                    firstName,
                } = res.data.user;
                setDefaultAvatar(defaultAvatar);
                setProfileImage(profileImage);
                setCroppedImg(croppedImage);
                setInteracted(interacted);
                setCompanyName(firstName);
            });
        }
    }, []);


    // fetching all clients
    useEffect(() => {
        const clients = axios.get(`/api/admin/get-all-clients/${compId}`).then((res) => {
            setTableLoading(false);
            console.log(res)
            if (res.data.length > 0) {
                const filteredArray = res.data.filter(client => client.status === statusSelected)
                console.log(filteredArray)
                setAllClients(filteredArray);
                setFilteredClients(res.data)
                if (selectedClientId) {
                    const selectedClient = filteredArray.filter(client => client._id === selectedClientId)
                    console.log(selectedClient[0])
                    setSelectedClientData(selectedClient[0])
                }
            }
        })
    }, [addClient, selectedClientId])


    const checkStatus = async (clientId, status) => {
        setSelectedClientId(clientId)
        setSelectedClientStatus(status)
        await axios.post(`/api/admin/clients/check-client/${clientId}/${compId}`).then(res => {
            console.log(res)
            //check for dialog
            if (res.data) {
                //dialog
                setDialogOpen(true)
            } else {
                //delete
                actionHandler(clientId, status, 'delete')
            }
        }).catch(err => {
            console.log(err)
        })
    }

    //post text to backend
    const sendFilter = async () => {
        const { data } = await axios.get(`/api/admin/get-all-clients/${compId}`)
        if (data.length > 0) {
            let filteredArray = data.filter(client => client.status === statusSelected)


            console.log(filter, filteredArray, "Filter")
            filter && (filteredArray = filteredArray.filter(item => item.companyName.toLowerCase().includes(filter.toLowerCase())))
            console.log(filter, filteredArray, "Filter")


            setAllClients(filteredArray)
        }


    };


    useEffect(() => {
        setPage(0);
        sendFilter();
    }, [statusFilter, filter]);


    //pagination handler
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    // handle to change status filter
    const handleChangeStatus = (event, role) => {
        if (role === null) {
            setStatusFilter(statusFilter)
        } else {
            setStatusFilter(role)
        }
        if (role === "Active") {
            setStatusSelected(true);
        } else if (role === "Inactive") {
            setStatusSelected(false)
        }
    }


    console.log(statusFilter, statusSelected, "Status Filter ")


    // handler to open add client page
    const handleOpenAddClient = () => {
        setAddClient(true)
        setMode("Add");
    }


    const handleOpenEditClient = (itemId) => {
        setAddClient(true);
        setMode("Edit");
        setSelectedClientId(itemId);
    }


    const backHandler = () => {
        setAddClient(false);
        setMode("");
        setSelectedClientId("");
        setSelectedClientData({})
    };


    const actionHandler = (itemId, status, action) => {
        console.log(itemId, status, action, "client to delete")
        if (action === "delete") {
            setSelectedClientId(itemId)
            setSelectedClientStatus(status)
            setOpenCloseDialog(true)
        }
        else {

            axios.post(`/api/admin/clients/${action}/${compId}`, { status, itemId }).then(res => {
                console.log(res)
                const filteredArray = res.data.clientObj.filter(client => client.status === statusSelected)
                console.log(filteredArray)
                setAllClients(filteredArray)
                // setAllClients(res.data.clientObj);
                setFilteredClients(res.data.clientObj)
                setMessage(res.data.msg);
                setOpenSnackbar(true)
            }).catch(err => {
                console.log(err)
            })
        }
    }


    const onCloseDialog = () => {
        console.log(selectedClientId, selectedClientStatus)
        axios.post(`/api/admin/clients/delete/${compId}`, { status: selectedClientStatus, itemId: selectedClientId }).then(res => {
            console.log(res)
            const filteredArray = res.data.clientObj.filter(client => client.status === statusSelected)
            console.log(filteredArray)
            setAllClients(filteredArray)
            // setAllClients(res.data.clientObj);
            setFilteredClients(res.data.clientObj)
            setMessage(res.data.msg);
            setOpenSnackbar(true)
            setOpenCloseDialog(false)
            setSelectedClientId("")
            setSelectedClientStatus(null)
        }).catch(err => {
            console.log(err)
        })
    }



    const onCloseCancel = () => {
        setOpenCloseDialog(false)
        setSelectedClientId("")
        setSelectedClientStatus(null)
    }

    return (
        <div>{hasClientsAccess ?
            <div>
                {/* Navbar Component */}
                <CompanyMenu
                    open={open}
                    setOpen={setOpen}
                    sidebarOpen={sidebarOpen}
                    setsidebarOpen={setsidebarOpen}
                    companyName={companyName}
                    croppedImg={croppedImg}
                    profileImage={profileImage}
                    interacted={interacted}
                    defaultAvatar={defaultAvatar}
                />
                <div style={{ marginLeft: `${sidebarOpen === "sidebar-open" ? "200px" : "0px"}` }}>
                    <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                        <Card id="main-edit" className="add-recruiter-form form-card">
                            <Grid
                                container
                                spacing={0}
                                // direction="row"
                                className="add-recruiter-card-container add-clients-container"


                            >
                                <Grid
                                    item
                                    xs={addClient ? 6 : 12}
                                    sm={4}
                                    md={6}
                                    lg={6}
                                    className="container-heading-title admin-table"
                                >
                                    <h1>Clients</h1>
                                </Grid>
                                <Grid
                                    item
                                    xs={addClient ? 5 : 12}
                                    sm={7}
                                    md={5}
                                    lg={5}
                                    className="container-heading-buttons add-recruiter-button"
                                    style={{ width: "100%" }}
                                >
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>


                                        {addClient ?
                                            <button className="add-recruiter add-multiple" onClick={backHandler}>
                                                Back
                                            </button> :
                                            <div className="clients-top-card-btns">
                                                <div>
                                                    <ToggleButtonGroup
                                                        value={statusFilter}
                                                        exclusive
                                                        onChange={handleChangeStatus}
                                                        aria-label="Platform"
                                                    >
                                                        <ToggleButton
                                                            value="Active"
                                                            selected={statusSelected}
                                                            className={
                                                                statusFilter === "Active"
                                                                    ? "Mui-selected btn-ripple"
                                                                    : "toggle-role-btn"
                                                            }
                                                            sx={{
                                                                background: "#5050502e",
                                                                color: "#505050",
                                                                padding: "3px 7px",
                                                                fontSize: "11px",
                                                                fontFamily: "Raleway",
                                                                fontWeight: "600",
                                                                textTransform: "none",
                                                                border: "none",
                                                            }}
                                                        >
                                                            Active
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            selected={!statusSelected}
                                                            className={
                                                                statusFilter === "Inactive"
                                                                    ? "Mui-selected "
                                                                    : "toggle-role-btn"
                                                            }
                                                            value="Inactive"
                                                            sx={{
                                                                background: "#5050502e",
                                                                color: "#505050",
                                                                padding: "3px 7px",
                                                                fontSize: "11px",
                                                                fontWeight: "600",
                                                                border: "none",
                                                                fontFamily: "Raleway",
                                                                textTransform: "none",
                                                            }}
                                                        >
                                                            Inactive
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                                {moduleAccess.edit && <button
                                                    className="add-recruiter"
                                                    onClick={handleOpenAddClient}
                                                >
                                                    Add Client
                                                </button>}
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                            {addClient ? "" :
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                // className="profile-progress-bar"
                                >
                                    <Grid
                                        id="profile-completion-percentage"
                                        item
                                        xs={12}
                                        sm={5}
                                        md={5}
                                        lg={4}
                                        sx={{ textAlign: "center" }}
                                        className="container-profile"
                                    >
                                        <form className="experience-edit-form" noValidate>
                                            <div class="add-recruiter-input-container">
                                                <div class="input-field">
                                                    <input
                                                        class="position"
                                                        type="text"
                                                        defaultValue={filter}
                                                        onChange={(e) => setFilter(e.target.value)}
                                                        placeholder="Search"
                                                    />
                                                </div>


                                            </div>
                                        </form>
                                    </Grid>
                                </Grid>
                            }
                        </Card>


                        {addClient ?
                            mode === "Add" ?
                                <AddClient setAddClient={setAddClient} mode={mode} /> :
                                <EditClient setAddClient={setAddClient} selectedClientId={selectedClientId} compId={compId} mode={mode} /> :
                            // table view of clients list
                            (<Card
                                id="skills-edit"
                                className="add-recruiter-form-container "
                                style={{ boxShadow: "none", borderRadius: "2px" }}
                            >

                            <div className="position-list">                             
                                <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    className="recruiter-list-heading"
                                >
                                    {columns.map((item, index) => {
                                        return (
                                            <h6
                                                style={{
                                                    width: `${item.width}`,
                                                    background: index % 2 ? "rgba(240, 97, 97, 37%)" : "rgba(240, 97, 97, 17%)",
                                                    color: "#283848",
                                                }}
                                            >
                                                {item.title}
                                            </h6>
                                        );
                                    })}
                                </Grid>
                                {tableLoading ? (
                                    <TableLoader page="subscriberList" />
                                ) : (<div className="recruiter-details-list">
                                    {allClients.length > 0 ? (
                                        allClients
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((item, index) => {
                                                let firstElement = 1;
                                                return (
                                                    <>
                                                        <div


                                                            style={{
                                                                justifyContent: 'normal'
                                                            }}
                                                            className={` recruiter-details ${index % 2
                                                                ? ""
                                                                : "alternate-recruiterlist-bg"
                                                                }`}
                                                        >
                                                            <div className={`${moduleAccess.edit ? "interview-id" : "clients-list-view"} user-role`} style={{ width: '24%', fontWeight: "bold" }}>
                                                                <img
                                                                    style={{ objectFit: "cover" }}
                                                                    src={
                                                                        item.defaultAvatar
                                                                            ? profileAvatar
                                                                            : item.interacted
                                                                                ? item.croppedImage
                                                                                : item.profileImage
                                                                    }
                                                                    className="internal-user-list-profile-image"
                                                                />
                                                                <p style={{ textAlign: 'start' }} onClick={() => { moduleAccess.edit && handleOpenEditClient(item._id) }}>{item.companyName}</p>
                                                            </div>


                                                            <div className="user-role-description " style={{ width: '20%' }}>
                                                                {item.pocDetails.pocName.length > 24 ? (
                                                                    <Tooltip title={`${item.pocDetails.pocName || "NA"}`}>
                                                                        <p >{item.pocDetails.pocName || "Name NA"}</p>
                                                                    </Tooltip>
                                                                ) :
                                                                    <p >{item.pocDetails.pocName || "Not Specified"}</p>
                                                                }
                                                            </div>


                                                            <div className="user-role-description " style={{ width: '20%' }}>
                                                                {item.pocDetails.pocEmail.length > 24 ? (
                                                                    <Tooltip title={`${item.pocDetails.pocEmail || "NA"}`}>
                                                                        <p>{item.pocDetails.pocEmail || "Not Specified"}</p>
                                                                    </Tooltip>
                                                                ) :
                                                                    <p>{item.pocDetails.pocEmail || "Not Specified"}</p>
                                                                }
                                                            </div>




                                                            <div className="user-role-description " style={{ width: '15%' }}>
                                                                <p>{item.pocDetails.pocPhone || "Not Specified"}</p>
                                                            </div>


                                                            <div className="user-role-description " style={{ width: '12%' }}>
                                                                <div className="user-status-button" >
                                                                    <button className={item.status ? 'Active' : "Frozen"}>
                                                                        {item.status ? 'Active' : "Inactive"}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="action-buttons" style={{ marginRight: '0px', justifyContent: 'center', gap: '10px' }}>
                                                                <div className="action-button-icon">
                                                                    {item.status ? (
                                                                        <Tooltip title="Inactive">
                                                                            <IconButton className="user-role-action-button" disabled={!moduleAccess.edit}>
                                                                                <ActiveIcon
                                                                                    style={{ color: moduleAccess.edit ? "#2c3e50" : "" }}
                                                                                    fontSize="small"
                                                                                    onClick={() =>
                                                                                        actionHandler(item._id, item.status, 'status')
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <Tooltip title="Active">
                                                                            <IconButton className="user-role-action-button" disabled={!moduleAccess.edit}>
                                                                                <InActiveIcon
                                                                                    style={{ color: moduleAccess.edit ? "#2c3e50" : "" }}
                                                                                    fontSize="small"
                                                                                    onClick={() =>
                                                                                        actionHandler(item._id, item.status, 'status')
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </div>
                                                                <div className="action-button-icon">
                                                                    <Tooltip title="Delete">
                                                                        <IconButton className="user-role-action-button" disabled={!moduleAccess.edit}>
                                                                            <DeleteIcon
                                                                                style={{ color: moduleAccess.edit ? "#2c3e50" : "" }}
                                                                                fontSize="small"
                                                                                onClick={() => checkStatus(item._id, item.status)}
                                                                            />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </>
                                                );
                                            })
                                    ) : (
                                        <p>No Clients</p>
                                    )}
                                </div>
                                )}
                                </div>   
                                <TablePagination
                                    id="recruiter-list-pagination"
                                    component="div"
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    count={allClients.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    variant="outlined"
                                />
                            </Card>
                            )}
                    </Box>

                    {/* Delete Confirmation Dialog  */}
                    {/* Close confimation dialog */}
                    <Dialog
                        fullWidth
                        maxWidth="xs"
                        open={openCloseDialog}
                        onClose={onCloseCancel}
                        className="experience-edit-dialog"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className="dialog-content">
                            <Grid container spacing={0} justifyContent="center">
                                <Grid
                                    item
                                    xs={11.5}
                                    sm={11.5}
                                    md={11.5}
                                    lg={11.5}
                                    className="experience-delete-grid"
                                >
                                    <div className="delete-message-icon">
                                        <DeleteMessageIcon fontSize="small" />
                                    </div>
                                    <div className="delete-dialog-content">
                                        <h5>Are you sure?</h5>
                                        <p>Do you want to go back?</p>
                                        <p>All your current changes will be lost!</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <div className="alert-buttons-container">
                            <button className="warning-btn-style filled-btn filled-btn-animation" onClick={onCloseCancel}>
                                {" "}
                                Cancel
                            </button>
                            <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={onCloseDialog}>
                                {" "}
                                Confirm
                            </button>
                        </div>
                    </Dialog>

                </div >
                < UserSnackBar
                    openSnackbar={openSnackbar}
                    setOpenSnackbar={setOpenSnackbar}
                    message={message}
                />
            </div > : <PageNotFound />
        }

            < Dialog
                fullWidth
                maxWidth="xs"
                open={dialogOpen}
                onClose={setDialogOpen}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5 style={{ margin: '10px 0' }}>You cannot delete this client</h5>
                                <p>This client is currently being used in one or more postion(s) or interview(s)</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container" style={{ justifyContent: 'center', marginTop: 0 }}>
                    <button className="warning-btn-style filled-btn filled-btn-animation" onClick={() => {
                        setDialogOpen(false);

                    }}>
                        {" "}
                        Okay
                    </button>
                    {/* <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={onCloseDialog}>
                        {" "}
                        Confirm
                    </button> */}
                </div>
            </Dialog >

            <Footer sidebarOpen={sidebarOpen} />
        </div >
    )
}


export default ClientsList

