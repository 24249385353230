import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Label, LabelList, ResponsiveContainer } from 'recharts';

const DonutChart = ({ result, endAngle }) => {

    console.log("result", result);

    const data = result;
    const COLORS = ['#ef798a', '#3091A2'];

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const { type, value } = payload[0].payload;
            return (
                <div style={{
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    padding: '5px',
                    borderRadius: '5px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                }}>
                    <p style={{ margin: 0 }}>{`${type}: ${value}`}</p>
                </div>
            );
        }
        return null;
    };

    const totalValue = data.reduce((acc, entry) => acc + entry.value, 0);

    return (

        result[1]?.value > 0 ? (
            <div style={{ textAlign: 'center', width: '100%', maxWidth: '500px', margin: '0 auto' }}>
                <ResponsiveContainer width="100%" height={170}>
                    <PieChart>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius="60%"
                            outerRadius="80%"
                            fill="#8884d8"
                            dataKey="value"
                            startAngle={180}
                            endAngle={180 + (360 * endAngle) / 3}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label
                                value={totalValue}
                                position="center"
                                style={{
                                    fontSize: '20px',
                                    fill: '#333',
                                }}
                            />
                            <LabelList
                                dataKey="value"
                                position="outside"
                                offset={10}
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    fill: 'black',
                                }}
                            />
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                </ResponsiveContainer>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px',
                    gap: '10px',
                    flexWrap: 'wrap',
                }}>
                    {data.map((item, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                            <div
                                style={{
                                    width: '15px',
                                    height: '15px',
                                    backgroundColor: COLORS[index % COLORS.length],
                                    marginRight: '8px',
                                    borderRadius: '3px',
                                }}
                            />
                            <span style={{ fontSize: '14px', color: '#333' }}>
                                {item.type}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        ) : (
            <div style={{ textAlign: 'center', padding: '60px' }}>No interviews</div>
        )
    );
};

export default DonutChart;





