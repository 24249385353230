import { RECEIVE_USER_ERRORS, RECEIVE_USER_INFORMATION,RECEIVE_ALL_RECRUITER ,UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    UPDATE_PROFILE_RESET,
    RECEIVE_ALL_SCREENER,
    RECEIVE_ALL_CANDIDATE,
    RECEIVE_ALL_INTERVIEW,
    // USER_DETAILS_REQUEST,
    // USER_DETAILS_SUCCESS,
    // USER_DETAILS_FAIL,
} from "../actions/userActions";

const initialState = {}

export const userReducer = (state = initialState, action) => {
    Object.freeze(state);

    switch (action.type) {
        case RECEIVE_USER_INFORMATION:
            if (action.payload === undefined) {
                return state;
            }
            return action.payload.data;
        case RECEIVE_USER_ERRORS:
            return state;
        default:
            return state;
    }
};

export const recruiterReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case RECEIVE_ALL_RECRUITER:
            return {
                users: action.payload,
              };
        default:
            return state;
    }


}

export const allInterviewReducer = (state = { interviews: [] }, action) => {
    switch (action.type) {
        case RECEIVE_ALL_INTERVIEW:
            return {
                interviews: action.payload,
              };
        default:
            return state;
    }


}


export const screenerReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case RECEIVE_ALL_SCREENER:
            return {
                users: action.payload,
              };
        default:
            return state;
    }


}

export const candidateReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case RECEIVE_ALL_CANDIDATE:
            return {
                users: action.payload,
              };
        default:
            return state;
    }


}



export const recruiterprofileReducer = (state = {}, action) => {
    switch(action.type){
       
        // case UPDATE_PROFILE_REQUEST:
       
        //     return{
        //         ...state,
                          
        //     };
        case UPDATE_PROFILE_SUCCESS:
        
            return{
                ...state,      
                isUpdated: action.payload,
                user:action.payload.data
            };
        
        case UPDATE_PROFILE_FAIL:  
            return{
                ...state,
                user:null,
                error: action.payload,
            };
        case UPDATE_PROFILE_RESET: 
            return{
                ...state,
                isUpdated:false,
            };
  
        default:
            return state;
    }
};

export const recruiterDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
    //   case USER_DETAILS_REQUEST:
    //     return { 
    //       ...state,
    //     };
      case RECEIVE_USER_INFORMATION:
        return {
         
          user: action.payload.data,
        };
    //   case USER_DETAILS_FAIL:
    //     return {
      
    //       error: action.payload,
    //     };
  
     
      default:
        return state;
    }
  };




