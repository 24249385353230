import { createStore, applyMiddleware, compose } from "redux";
import {thunk} from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

// Create the Redux store with initial state and middleware
const initialState = {};
const middlewares = [thunk];

// Setup Redux DevTools Extension if available
const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : (f) => f;

// Create the store with middleware and DevTools
const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middlewares), devTools)
);

export default store;
