import React from 'react';
// import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginComponent from '../auth/LoginComponent';

// Define styles using MUI v5 styled API
const StyledDiv = styled('div')(({ theme }) => ({
  title: {
    fontSize: 25,
  },
  pos: {
    fontSize: 25,
  },
  button: {
    padding: theme.spacing(2),
    margin: 10,
    textAlign: 'center',
  },
}));

class Landing extends React.Component {
  render() {
    // const { classes } = this.props; // No longer needed

    return (
      <StyledDiv className="landing-component">
        <GoogleOAuthProvider clientId="458868131518-qshhdo2sc2cfkf704q76fhkctb4pgg53.apps.googleusercontent.com">
          <LoginComponent />
        </GoogleOAuthProvider>
      </StyledDiv>
    );
  }
}

Landing.propTypes = {
  // classes: PropTypes.object.isRequired, // No longer needed
};

export default Landing;
