// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options{
    display: flex;
    justify-content: center;
    margin : 63px 0px 0px 0px;
}

.css-1c726lj-MuiTabs-indicator {

    background-color: #f06161 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/skill-assessment/components/SkillBars.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;;IAEI,oCAAoC;AACxC","sourcesContent":[".options{\n    display: flex;\n    justify-content: center;\n    margin : 63px 0px 0px 0px;\n}\n\n.css-1c726lj-MuiTabs-indicator {\n\n    background-color: #f06161 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
