import React, { useState, useEffect } from "react";
import axios from "axios";
import "../CandidateResults/CandidateResults.css";
import ViewCandidateDetails from "./ViewCandidateDetails";
import TablePagination from "@mui/material/TablePagination";

const CandidateResults = () => {
  const [candidates, setCandidates] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = localStorage.getItem("rowsPerPage");
    return savedRowsPerPage ? parseInt(savedRowsPerPage, 10) : 5;
  });

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axios.post(`/api/admin/skill-assessment/test-management/get-all-test-details`);
        const candidatesData = response.data.flatMap((test) =>
          test.candidates.map((candidate) => ({
            name: candidate.fullName,
            email: candidate.email,
            status: candidate.status,
            score: candidate.testResult?.securedScore || 0,
            CandidateId: candidate._id,
            totalPoints: test.totalPoints,
            testName: test.testName,
            testId: test._id,
          }))
        );
        setCandidates(candidatesData);
        setFilteredCandidates(candidatesData);
      } catch (error) {
        console.error("Error fetching test details:", error);
      }
    };
    fetchTests();
  }, []);

  useEffect(() => {
    const filterCandidates = () => {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const filtered = candidates.filter(
        (candidate) =>
          candidate.name.toLowerCase().includes(lowerSearchTerm) ||
          candidate.email.toLowerCase().includes(lowerSearchTerm) ||
          candidate.status.toLowerCase().includes(lowerSearchTerm) ||
          candidate.testName.toLowerCase().includes(lowerSearchTerm)
      );
      setFilteredCandidates(filtered);
    };
    filterCandidates();
  }, [searchTerm, candidates]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    localStorage.setItem("rowsPerPage", newRowsPerPage);
    setPage(0);
  };

  // Paginate the filtered candidates
  const paginatedCandidates = filteredCandidates.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <div className="test">
      {selectedCandidate ? (
        <ViewCandidateDetails
          candidate={selectedCandidate}
          onClose={() => setSelectedCandidate(null)}
        />
      ) : (
        <>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by name, email, status, or test name..."
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        <br></br>
          <table className="candidate-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Test</th>
                <th>Score</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedCandidates.map((candidate, index) => (
                <tr key={index}>
                  <td>{candidate.name}</td>
                  <td>{candidate.email}</td>
                  <td>{candidate.testName}</td>
                  <td>{candidate.score} / {candidate.totalPoints}</td>
                  <td>{candidate.status}</td>
                  <td>
                    <button
                      className="view-details-btn"
                      onClick={() => setSelectedCandidate(candidate)}
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              <br></br>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredCandidates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </div>
  );
};

export default CandidateResults;
