import React, { useState, useRef, useCallback, useEffect } from "react";
import axios from "axios";

//external library Imports
import DialogContent from "@mui/material/DialogContent";
import { Grid, Switch } from "@mui/material";
import { Dialog } from "@mui/material";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { v4 as uuid } from "uuid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
// import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { FormGroup, FormControlLabel } from "@mui/material";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// CSS import
// import "./MainCardEditModal.css";
import "../../../company admin/CompanyAdmin.css"


import UserSnackbar from "../../../snackBars/UserSnackbar";

const ShareProfileModal = ({ openShareModal, replyToEmail, setOpenShareModal, profilePublicLink, includeResume, setIncludeResume, resume, firstName, lastName, role, senderName }) => {
    console.log(resume)

    const [recipientEmails, setRecipientEmails] = useState([])
    const [ccEmails, setccEmails] = useState([])
    const [bccEmails, setbccEmails] = useState([])

    const [ckText, setCKText] = useState("")
    const [customMail, setCustomMail] = useState(false)

    const [errors, setErrors] = useState({ recipientError: "", ccError: "", bccError: "" })
    const [message, setMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)

    const handleCKEditor = (e, editor) => {
        setCKText(editor.getData())

    }

    // copying link 
    const copyTextToClipboard = async (text) => {
        try {
            setMessage("Link copied to clipboard")
            setOpenSnackbar(true)
        } catch (err) {
            setMessage("Failed to copy link")
            setOpenSnackbar(true)
        }
    }

    const handleCloseShareModal = () => {
        setOpenShareModal(false)
        setccEmails([])
        setbccEmails([])
        setRecipientEmails([])
        setIncludeResume(false)
    }

    const handleSendMail = (e) => {
        e.preventDefault();
        //check whether the email are valid
        console.log("recipientEmails.length",recipientEmails.length)
        let areValid = true;
        if (recipientEmails.length > 0) {
            for (const item of recipientEmails) {

                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item)) {
                    console.log("Issue with recipients email", item)
                    areValid = false;
                    return setErrors({ recipientError: "Please check recipient emails" })
                }
            }

            for (const item of ccEmails) {

                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item)) {
                    console.log("Issue with CC email")
                    areValid = false
                    return setErrors({ ccError: "Please check carbon copy emails" })
                }
            }


            for (const item of bccEmails) {

                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(item)) {
                    console.log("Issue with BCC email")
                    areValid = false
                    return setErrors({ bccError: "Please check blind carbon copy emails" })
                }
            }
        } else {
            areValid = false;
            return setErrors({ recipientError: "Recipient is mandatory" })
        }

        if (areValid) {
            //axios and send emails

            const newData = {
                recipientEmails, ccEmails, replyToEmail, bccEmails, includeResume, resume, profilePublicLink, firstName, lastName, role, senderName, customMail, ckText
            }
            axios.post(`/api/admin/email-profile/`, newData)
                .then(res => {
                    setCKText('')
                    setCustomMail(false)
                    setOpenShareModal(false)
                    setccEmails([])
                    setbccEmails([])
                    setRecipientEmails([])
                    setIncludeResume(false)
                    setErrors({
                        recipientError: "",
                        ccError: "",
                        bccError: ""
                    })
                    setMessage("Profile shared successfully")
                    setOpenSnackbar(true)
                })
                .catch(err => {
                    setMessage("Something went wrong")
                    setOpenSnackbar(true)
                })
            console.log('Send email')
        }

    }

    return (
        <div>
            <Dialog
                // maxWidth="sm"
                // fullWidth
                open={openShareModal}
                onClose={handleCloseShareModal}
                className="experience-edit-dialog timezone-expiry-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                    className="experience-edit-dialog-content"
                >
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="experience-edit-title-grid"
                        >
                            <div>
                                <div className="experience-edit-title">
                                    <p>Share Profile</p>
                                </div>
                                <div className="experience-edit-close">
                                    <Tooltip title="Close">
                                        <CloseIcon onClick={handleCloseShareModal} />
                                    </Tooltip>
                                </div>
                            </div>
                            {/* <Divider className="experience-edit-top-divider" /> */}
                        </Grid>

                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="experience-edit-right-grid"
                        >
                            <div className="experience-edit-form-container">
                                <div className="copy-url-button button-container ">
                                    <CopyToClipboard text={profilePublicLink} onCopy={copyTextToClipboard}>
                                        <button className='filled-btn filled-btn-animation btn-style'>Copy URL</button>
                                    </CopyToClipboard>
                                </div>
                                <div className="divider-container">
                                    <Divider style={{ width: "100px" }} />
                                    <div className="circle"><p>Or</p></div>
                                    <Divider style={{ width: "100px" }} />
                                </div>
                                <form
                                    className="experience-edit-form"
                                    noValidate
                                >
                                    <div class="input-container users-form">
                                        <div class="input-field users-skill email-input">
                                            <label>Enter Recipient Emails</label>
                                            <div class="recipient-email-input-field ">
                                                <Autocomplete
                                                    className="email-autocomplete"
                                                    sx={{
                                                        "& fieldset": { border: "none" }
                                                    }}
                                                    value={recipientEmails}
                                                    onChange={(event, newValue) => {
                                                        setRecipientEmails(newValue);
                                                        setErrors({ recipientError: "" })
                                                    }}
                                                    multiple
                                                    id="tags-filled"
                                                    options={[]}
                                                    freeSolo
                                                    renderTags={(skillsList, getTagProps) =>
                                                        recipientEmails.map((option, index) => (
                                                            <Chip
                                                                variant="outlined"
                                                                label={option}
                                                                {...getTagProps({ index })}
                                                            />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            onChange={(e) => {
                                                                setErrors({ recipientError: "", ccError: "", bccError: "" })
                                                            }}
                                                            {...params}
                                                            className="email-text-field"
                                                            variant="filled"
                                                        />
                                                    )}
                                                />
                                                <span className="text-danger" style={{ height: "20px" }}>
                                                    {errors.recipientError}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="input-field users-skill email-input">
                                            <label>Enter CC Emails</label>
                                            <div class="recipient-email-input-field ">
                                                <Autocomplete
                                                    className="email-autocomplete"
                                                    sx={{
                                                        "& fieldset": { border: "none" }
                                                    }}
                                                    value={ccEmails}
                                                    onChange={(event, newValue) => {
                                                        setccEmails(newValue);
                                                        setErrors({ ccError: "" })
                                                    }}
                                                    multiple
                                                    id="tags-filled"
                                                    options={[]}
                                                    freeSolo
                                                    renderTags={(skillsList, getTagProps) =>
                                                        ccEmails.map((option, index) => (
                                                            <Chip
                                                                variant="outlined"
                                                                label={option}
                                                                {...getTagProps({ index })}
                                                            />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            onChange={(e) => {
                                                                setErrors({ recipientError: "", ccError: "", bccError: "" })
                                                            }}
                                                            {...params}
                                                            className="email-text-field"
                                                            variant="filled"
                                                        />
                                                    )}
                                                />
                                                <span className="text-danger" style={{ height: "20px" }}>
                                                    {errors.ccError}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="input-field users-skill email-input">
                                            <label>Enter BCC Emails</label>
                                            <div class="recipient-email-input-field ">
                                                <Autocomplete
                                                    className="email-autocomplete"
                                                    sx={{
                                                        "& fieldset": { border: "none" }
                                                    }}
                                                    value={bccEmails}
                                                    onChange={(event, newValue) => {
                                                        setbccEmails(newValue);
                                                        setErrors({ bccError: "" })
                                                    }}
                                                    multiple
                                                    id="tags-filled"
                                                    options={[]}
                                                    freeSolo
                                                    renderTags={(skillsList, getTagProps) =>
                                                        bccEmails.map((option, index) => (
                                                            <Chip
                                                                variant="outlined"
                                                                label={option}
                                                                {...getTagProps({ index })}
                                                            />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            onChange={(e) => {
                                                                setErrors({ recipientError: "", ccError: "", bccError: "" })
                                                            }}
                                                            {...params}
                                                            className="email-text-field"
                                                            variant="filled"
                                                        />
                                                    )}
                                                />
                                                <span className="text-danger" style={{ height: "20px" }}>
                                                    {errors.bccError}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="position-checkbox-toggle">
                                            {resume && <FormGroup className="input-field-checkbox">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            sx={{
                                                                [`&, &.${checkboxClasses.checked}`]: {
                                                                    color: '#F06161',
                                                                },
                                                            }}
                                                            // style={{ color: "#F06161", border: "none" }}
                                                            checked={includeResume}
                                                            value={includeResume}
                                                            onClick={(e) => {
                                                                setIncludeResume(e.target.checked)
                                                            }}
                                                        />
                                                    }
                                                    label="Attach Resume"
                                                />
                                            </FormGroup>}
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <FormGroup className="custom-mail-toggle">
                                                    <FormControlLabel
                                                        style={{ marginRight: '6px' }}
                                                        onChange={(e) => setCustomMail(e.target.checked)}
                                                        value={customMail}
                                                        checked={customMail}
                                                        control={
                                                            <Switch />
                                                        }
                                                        label={customMail ? "Custom E-Mail" : "Default E-Mail"}
                                                    />

                                                </FormGroup>
                                                <Tooltip title="The 'Default E-mail' has an email template already written for you. You can also add custom emails by using 'Custom E-mail'.">

                                                    <InfoOutlinedIcon
                                                        fontSize="small"
                                                        style={{ color: "#4c4c5d", fontSize: "14px" }}
                                                    />
                                                </Tooltip>
                                            </div>



                                        </div>

                                        {customMail && <div>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                onChange={handleCKEditor}
                                                data={ckText}
                                                too
                                            // disabled={disabled}
                                            />
                                        </div>
                                        }


                                        <div className="button-container">
                                            <button className="btn-style filled-btn filled-btn-animation"
                                                onClick={handleSendMail}
                                            >
                                                {" "}
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog >

            {/* Snackbar */}
            < UserSnackbar openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} message={message} />
        </div >
    );
};

export default ShareProfileModal;
