import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import {
  Grid,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Avatar,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  TablePagination,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material"; // Importing expand/collapse icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import { useNavigate } from "react-router-dom";
import AddCandidate from "./CandidatesDialogs/AddCandidate";
import UserSnackbar from "../../../snackBars/UserSnackbar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./Candidates.css"
import CandidateViewProfile from "./CandidatesDialogs/CandidateViewProfile";

const Candidates = ({ testId, testName, tests }) => {
  const [candidates, setCandidates] = useState([]);
  const [candidatetime, setCandidatetime] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [candidateDialogOpen, setCandidateDialogOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [sectionOpen, setSectionOpen] = useState({}); // State to manage section collapse/expand
  const [anchorEl, setAnchorEl] = useState(null);
  const [reason, setReason] = useState("");

  // Snackbar state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  // pagination states
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page

  const navigate = useNavigate();

  let isMounted = true; // track if the component is still mounted

  const fetchAddedCandidates = async () => {
    try {
      const response = await axios.post(
        `/api/admin/skill-assessment/test-management/${testId}/get-added-candidates`
      );
      if (isMounted) {
        setCandidates(response.data); // update state only if component is still mounted
      }
    } catch (error) {
      console.error("Error fetching candidates", error);
    }
  };

  useEffect(() => {
    const savedPage = localStorage.getItem("candidatesPage");
    const savedRowsPerPage = localStorage.getItem("candidatesRowsPerPage");
  
    if (savedPage !== null) setPage(parseInt(savedPage, 10));
    if (savedRowsPerPage !== null) setRowsPerPage(parseInt(savedRowsPerPage, 10));
  }, []);


  useEffect(() => {

    fetchAddedCandidates();

    return () => {
      isMounted = false; // cleanup function to mark component as unmounted
    };
  }, [testId]);

  const formatDate = (date) => {
    return date ? format(new Date(date), "p, MMM dd yyyy") : "";
  };

  const statusOrder = { passed: 1, failed: 1, ongoing: 2, pending: 3 };

  const filteredCandidates = candidates
    .filter(
      (candidate) =>
        (candidate.fullName &&
          candidate.fullName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (candidate.email &&
          candidate.email.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    .sort((a, b) => {
      // Sort by status first
      if (statusOrder[a.status] !== statusOrder[b.status]) {
        return statusOrder[a.status] - statusOrder[b.status];
      }
      // If status is the same, sort passed/failed by securedPercentage in descending order
      if (a.status === "passed" || a.status === "failed") {
        return (b.testResult?.securedPercentage || 0) - (a.testResult?.securedPercentage || 0);
      }
      return 0; // Keep the order for ongoing and pending
    });
  

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    localStorage.setItem("candidatesPage", newPage); // Save page number
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
    localStorage.setItem("candidatesRowsPerPage", newRowsPerPage); // Save rows per page
  };

  // Paginate the filtered candidates
  const paginatedCandidates = filteredCandidates.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Open the menu
  const handleClick = (event, candidate) => {
    setAnchorEl(event.currentTarget);
    setSelectedCandidate([candidate]); // Set the clicked candidate
  };

  // Close the menu
  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedCandidate([]); // Clear the selected candidate on menu close
  };

  const handleCandidateClick = (candidate) => {
    console.log("handle candidate Details clicked");

    console.log("candidate", candidate);
    console.log("tests state ", tests);

    setSelectedCandidate(candidate);
    setCandidateDialogOpen(true);
    handleClose();

    console.log("selected candidates", selectedCandidate);
  };

  // Handle the "Download Report" click
  const handleDownloadClick = (candidate) => {
    handleDownloadReport(candidate);
    handleClose(); // Close the menu after action
    setAnchorEl(null);
  };

  const handleDeleteCandidate = async (candidate) => {
    try {
      // Send DELETE request to the backend
      const response = await axios.post(`/api/admin/skill-assessment/test-management/delete-candidate/${candidate._id}`);

      if (response.status === 200) {
        // Update the state to remove the deleted candidate from the list
        setSelectedCandidate((prev) =>
          prev.filter((c) => c._id !== candidate._id)
        );
        setAnchorEl(null);

        fetchAddedCandidates();

        // alert(response.data.message); // Optional: Show a success message
      }
    } catch (error) {
      console.error('Error deleting candidate:', error);
      alert('Failed to delete the candidate. Please try again.');
    }
  };


  const handleDownloadReport = (candidate) => {
    if (!candidate || !candidate.testResult || !tests) return;
  
    // Find the test associated with the candidate
    const candidateTest = tests.find(test => 
      test.candidates.some(c => c.email === candidate.email)
    );
  
    // Fetch total points of the test
    const totalPoints = candidateTest ? candidateTest.totalPoints : "N/A";
  
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text("Candidate Detailed Report", 10, 10);
  
    // Candidate details
    doc.setFontSize(12);
    let startY = 20; // Start Y position for details
  
    doc.text(`Name: ${candidate.fullName}`, 10, startY);
    startY += 10; // Increment for next line
  
    doc.text(`Email: ${candidate.email}`, 10, startY);
    startY += 10; 
  
    doc.text(`Total Score: ${candidate.testResult.securedScore} / ${totalPoints}`, 10, startY);
    startY += 10; 
  
    doc.text(`Secured Percentage: ${candidate.testResult.securedPercentage}%`, 10, startY);
    startY += 10; 
  
    // Iterate over sections in candidate's test result
    candidate.testResult.sectionResults.forEach((section, sIndex) => {
      doc.setFontSize(14);
      doc.text(`${section.sectionName} - Score: ${section.sectionScore}`, 10, startY);
      startY += 10;
  
      // Iterate over questions in the section and match with the tests state to fetch question text
      const sectionQuestions = section.questions.map((q, qIndex) => {
        const question = tests
          .flatMap(test => test.sections)
          .flatMap(section => section.problems)
          .find(problem => problem._id === q.questionId);
  
        return {
          index: qIndex + 1,
          text: question ? question.text : `Question ${qIndex + 1}`,
          candidateAnswer: q.candidateAnswer || "Not Attempted",
          correctAnswer: question ? question.correctAnswer : "N/A",
          isCorrect: q.isCorrect ? "Yes" : "No",
          points: q.points || 0,
        };
      });
  
      // Add question table
      doc.autoTable({
        head: [["#", "Question", "Selected Answer", "Correct Answer", "Correct", "Points"]],
        body: sectionQuestions.map(q => [q.index, q.text, q.candidateAnswer, q.correctAnswer, q.isCorrect, q.points]),
        startY: startY,
        styles: { fontSize: 10 },
      });
  
      startY = doc.previousAutoTable.finalY + 10; // Move Y-position below the table
    });
  
    // Save the PDF
    doc.save(`${candidate.fullName}_Detailed_Report.pdf`);
    setAnchorEl(null);
  };
  

  const handleAddCandidateClick = () => {
    setOpenDialog(true);
  };

  const handleCandidateDialogClose = () => {
    setOpenDialog(false);
  };

  const handleCandidateDetailDialogClose = () => {
    setCandidateDialogOpen(false);
    setSelectedCandidate(null);
  };

  // Logic to calculate total attempted questions
  const totalAttempted = selectedCandidate?.testResult?.sectionResults?.reduce((total, section) => {
    const attemptedCount = section.questions.filter(
      (question) => question.candidateAnswer !== "unattempted"
    ).length;
    return total + attemptedCount;
  }, 0) || 0;

  console.log("Total Attempted Questions:", totalAttempted);

  // Helper function to calculate total questions
  const totalQuestions = (selectedCandidate) => {
    return (
      selectedCandidate?.testResult?.sectionResults?.reduce(
        (acc, section) => acc + section.questions.length,
        0
      ) || 0
    );
  };

  // Helper function to calculate total correct answers
  const calculateCorrectAnswers = (selectedCandidate) => {
    return (
      selectedCandidate?.testResult?.sectionResults?.reduce(
        (acc, section) =>
          acc +
          section.questions.filter((q) => q.isCorrect).length,
        0
      ) || 0
    );
  };

  console.log("filtered candidated", filteredCandidates);


  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Candidates
      </Typography>

      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            label="Search Candidates"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "default",
                },
                "&:hover fieldset": {
                  borderColor: "default",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#F06161",
                },
              },
              "& .MuiInputLabel-root": {
                "&.Mui-focused": {
                  color: "#F06161",
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#F06161", color: "#fff" }}
            onClick={handleAddCandidateClick}
          >
            Add Candidate
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Candidate</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="center">Time</TableCell>
              <TableCell align="center">Total Score</TableCell>
              <TableCell align="center">Percentage</TableCell>
              <TableCell align="right">More Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCandidates.map((candidate) => (
              <TableRow
                key={candidate._id}
                hover
              // onClick={() => handleCandidateClick(candidate)}
              >
                <TableCell>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Avatar>
                        {candidate.fullName
                          ? candidate.fullName.charAt(0)
                          : "N/A"}
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {candidate.fullName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {candidate.email}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatDate(candidate.startTime)}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>{candidate.status}</TableCell>
                <TableCell align="center">
                    {
                      candidate.status === 'pending'
                        ? '-'  // If status is pending, show '-'
                        : candidate.timeTaken
                          ? (() => {
                            const timeParts = candidate.timeTaken.split(':').map(Number);
                            let hours = 0, minutes = 0, seconds = 0;

                            if (timeParts.length === 3) {
                              // Format hh:mm:ss
                              [hours, minutes, seconds] = timeParts;
                            } else if (timeParts.length === 2) {
                              // Format mm:ss (assuming no hours)
                              [minutes, seconds] = timeParts;
                            }

                            // Constructing the output string dynamically
                            const displayTime = [
                              hours > 0 ? `${hours}h` : '',
                              minutes > 0 ? `${minutes}m` : '',
                              seconds > 0 ? `${seconds}s` : ''
                            ].filter(Boolean).join(' '); // Remove empty strings and join

                            return displayTime || '0s'; // Show '0s' if everything is 0
                          })()
                          : '0s' // If timeTaken is undefined or null, return '0s'
                    }
                </TableCell>
                <TableCell align="center">{candidate.testResult?.securedScore ?? "-"}</TableCell>
                <TableCell align="center">
                  {candidate.testResult?.securedPercentage !== undefined
                    ? Math.floor(candidate.testResult.securedPercentage) + "%"
                    : "-"}
                </TableCell>
                <TableCell align="right" key={candidate._id}>
                  <IconButton onClick={(event) => handleClick(event, candidate)}>
                    <MoreVertIcon />
                  </IconButton>

                  {/* Menu for options */}
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => handleCandidateClick(selectedCandidate[0])}>
                      <DescriptionIcon sx={{ mr: 1 }} /> View Profile
                    </MenuItem>
                    <MenuItem onClick={() => handleDownloadClick(selectedCandidate[0])}>
                      <DownloadIcon sx={{ mr: 1 }} /> Download Report
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteCandidate(selectedCandidate[0])}>
                      <DeleteIcon sx={{ mr: 1 }} /> Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={filteredCandidates.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      {/* Dialog for Add Candidate */}
      <Dialog
        open={openDialog}
        onClose={handleCandidateDialogClose}
        fullWidth
        maxWidth="md" // Adjust the size as needed
      >
        <DialogContent>
          <AddCandidate
            testId={testId}
            testName={testName}
            handleDialogClose={handleCandidateDialogClose}
            setMessage={setMessage}
            setOpenSnackbar={setOpenSnackbar}
            fetchAddedCandidates={fetchAddedCandidates}
          />
        </DialogContent>
      </Dialog>

      {/* Dialog for Candidate Details */}
      <Dialog
        open={candidateDialogOpen}
        onClose={handleCandidateDetailDialogClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <CandidateViewProfile
            selectedCandidate={selectedCandidate}
            setSelectedCandidate={setSelectedCandidate}
            totalQuestions={totalQuestions}
            totalAttempted={totalAttempted}
            calculateCorrectAnswers={calculateCorrectAnswers}
            tests={tests}
            testId={testId}
            handleCandidateDetailDialogClose={handleCandidateDetailDialogClose}
            fetchAddedCandidates={fetchAddedCandidates}
          />
        </DialogContent>
      </Dialog>

      {/* Snackbar */}
      <UserSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        setMessage={setMessage}
        message={message}
      />
    </div>
  );
};

export default Candidates;
