import { combineReducers } from "redux";
import {authReducer} from "./authReducer";
import {availabilityReducer} from "./availabilityReducer";
import {errorReducer} from "./errorReducer";
import {homeTabReducer} from "./homeTabReducer";
import {interviewReducer} from "./interviewReducer";
import {positionReducer} from "./positionReducer";
import {reviewReducer} from "./reviewReducer";
import {sidebarReducer} from "./sidebarReducer";
import  { userReducer, recruiterDetailsReducer, recruiterprofileReducer } from "./userReducer";
import {applicationReducer} from "./applicationReducer";
import { recruiterReducer,screenerReducer,candidateReducer,allInterviewReducer } from "./userReducer";


export default combineReducers({
    auth: authReducer,
    user: userReducer,
    homeTab: homeTabReducer,
    positions: positionReducer,
    applications: applicationReducer,
    interviews: interviewReducer,
    reviews: reviewReducer,
    availabilities: availabilityReducer,
    sidebar: sidebarReducer,
    recruiters:recruiterReducer,
    errors: errorReducer,
    recruiterupdate:recruiterprofileReducer,
    getRecruiterDetail:recruiterDetailsReducer,
    screenerlist:screenerReducer,
    candidatelist:candidateReducer,
    allInterviews: allInterviewReducer,
});
