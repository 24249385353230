import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./LoginComponent.css";
import axios from "axios";
import Logo from "../../images/brand.svg";
import BackArrow from "../../images/left-arrow.png";
import arrow from "../../images/backArrow.png";
import ViewIcon from "../../images/view.png";
import HideIcon from "../../images/hide.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import MailIcon from "../../images/mail.png";
import LockIcon from "../../images/lock.png";
import UserIcon from "../../images/user.png";
import PhoneInput from "react-phone-input-2";
import "intl-tel-input/build/css/intlTelInput.css";
import "react-phone-input-2/lib/style.css";
import ConfirmationModal from "../modals/confirmationModal";
import { v4 as uuid } from "uuid";
import PasswordChecklist from "react-password-checklist";
import { getPhonesRegex } from "./PhoneValidation";

const theme = createTheme();

export default function CompanyRegistration() {
  const [passwordImage, setPasswordImage] = useState(ViewIcon);
  const [toggler, setToggler] = useState("password");
  const [firstToggle, setFirstToggle] = useState(true);

  const [confirmPasswordImage, setConfirmPasswordImage] = useState(ViewIcon);
  const [confirmToggler, setCofirmToggler] = useState("password");
  const [firstConfirmToggle, setFirstConfirmToggle] = useState(true);

  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("admin");
  const [companyId, setCompanyId] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [tempMail, setTempMail] = useState("");

  // state used to handle display of password checklist
  const [isPasswordFocussed, setIsPasswordFocussed] = useState(false);

  const [phoneIsValid, setPhoneIsValid] = useState(false);

  // store the regex of phone number patterns of all countries
  const phones = getPhonesRegex();

  let newData = {};
  const [errors, setErrors] = useState({
    companyName: "",
    companyEmail: "",
    companyPhone: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    document.title = "Screener | Company Registration";
  }, []);

  const passwordHandler = () => {
    if (firstToggle) {
      setToggler("text");
      setPasswordImage(HideIcon);
      setFirstToggle(false);
    } else {
      setToggler("password");
      setPasswordImage(ViewIcon);
      setFirstToggle(true);
    }
  };

  const ConfirmPasswordHandler = () => {
    if (firstConfirmToggle) {
      setCofirmToggler("text");
      setConfirmPasswordImage(HideIcon);
      setFirstConfirmToggle(false);
    } else {
      setCofirmToggler("password");
      setConfirmPasswordImage(ViewIcon);
      setFirstConfirmToggle(true);
    }
  };

  //onSubmitHandler

  const onSubmit = (e) => {
    e.preventDefault();

    if (companyName === "") {
      return setErrors({
        companyName: "Company Name is required",
      });
    }

    if (companyEmail === "") {
      return setErrors({
        companyEmail: "Email is required",
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(companyEmail)
    ) {
      return setErrors({
        companyEmail: "Email is Invalid",
      });
    }

    if (companyPhone === "") {
      return setErrors({ companyPhone: "Phone Number is required" });
    } else if (!phoneIsValid) {
      return setErrors({ companyPhone: "Invalid Phone Number" });
    }

    if (password === "") {
      return setErrors({ password: "Password is required" });
    } else if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        password
      )
    ) {
      // return setErrors({
      //   password:
      //     "Your password must be at least 8 characters including a lowercase letter, an uppercase letter, a number, and a special character.",
      // });
      return setIsPasswordFocussed(true);
    }

    if (confirmPassword === "") {
      return setErrors({ confirmPassword: "Confirm Password is required" });
    } else if (confirmPassword !== password) {
      return setErrors({ confirmPassword: "Passwords do not match" });
    }
    let companyCode = "COM";
    let unique_id = uuid();
    let company_id = unique_id.slice(0, 3);

    newData = {
      companyName,
      companyEmail,
      companyPhone,
      password,
      role,
      companyId: `${companyCode}-00${company_id}`,
    };

    axios
      .post(`/api/admin/register`, {
        newData: newData,
      })
      .then((data) => {
        console.log(newData);
        setTempMail(newData.companyEmail);
        setCompanyName("");
        setCompanyPhone("");
        setCompanyEmail("");
        setConfirmPassword("");
        setPassword("");
        setToggler("password");
        setPasswordImage(ViewIcon);
        setConfirmPasswordImage(ViewIcon);
        setCofirmToggler("password");
        setModalShow(true);
        setErrors({
          companyName: "",

          companyEmail: "",
          companyPhone: "",
          password: "",
          confirmPassword: "",
        });
      })
      .catch((err) => {
        if (err.response.status == 409) {
          return setErrors({
            companyEmail: "Email already exists",
          });
        }
      });
  };

  return (
    <div className="login-bg">
      <div className="card-container">
        <ThemeProvider theme={theme}>
          <Grid container component="main" className="main-grid">
            <CssBaseline />

            <Grid
              className="overlay-image-company"
              style={{ backgroundColor: "#2c3e50" }}
              item
              xs={false}
              sm={false}
              md={6}
            >
              <Grid
                item
                //   md={6}
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className="header-grid"
              >
                <div className="left-logo ">
                  {/* <h1 className="welcome-header">Get started with</h1> */}

                  <div className="logo-img">
                    <img className="login-img" src={Logo} />

                    <div className="main-heading right-title">
                      <h1>Screener</h1>
                      <p> Recruitment Solutions</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <div className="back-arrow">
                <RouterLink to="/">
                  <img src={BackArrow} />
                </RouterLink>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              component={Paper}
              elevation={6}
              square
              className="register-form"
            >
              <Box
                sx={{
                  my: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "80vh",
                  position: "relative",
                }}
                className="register-main-box"
              >
                <div className="right-back-arrow">
                  <RouterLink to="/">
                    {/* <a className="right-arrow">&larr;</a> */}
                    <img src={arrow} />
                  </RouterLink>
                </div>
                <div className="register-app-heading">
                  <div className="container-logo">
                    <img className="screener-logo" src={Logo} />

                    <div className="main-heading main-title">
                      <h1>Screener</h1>
                    </div>
                  </div>
                </div>
                <div className="register-form-container">
                  <form
                    noValidate
                    onSubmit={onSubmit}
                    className="form-conatiner"
                  >
                    <div className="register-form-header">
                      <h1 className="company-registation-form-header">
                        Company Registration
                      </h1>
                    </div>
                    {/* <div className="social-card-container">
                <a href="#" className="social">
                <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" className="social">
                <i className="fab fa-google-plus-g"></i>
                </a>
                <a href="#" className="social">
                <i className="fab fa-linkedin-in"></i>
                </a>
                </div>
              <span>or use your account</span> */}
                    <div className="register-input-conatiner">
                      <div className="input-field">
                        <img className="icon-size" src={UserIcon} />

                        <input
                          className="name "
                          type="text"
                          value={companyName}
                          placeholder="Company Name*"
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                            setErrors({
                              companyName: "",
                            });
                            setIsPasswordFocussed(false);
                          }}
                        />
                      </div>
                      <div>
                        <span className="text-danger">
                          {errors.companyName}
                        </span>
                      </div>

                      <div className="input-field">
                        <img className="field-icon" src={MailIcon} />

                        <input
                          className="email marginBottom"
                          type="email"
                          placeholder="Official Email*"
                          value={companyEmail}
                          onChange={(e) => {
                            setCompanyEmail(e.target.value);
                            setErrors({
                              companyEmail: "",
                            });
                            setIsPasswordFocussed(false);
                          }}
                        />
                      </div>
                      <div>
                        <span className="text-danger">
                          {errors.companyEmail}
                        </span>
                      </div>
                      {/* <div className="input-field"> */}
                      <PhoneInput
                        placeholder="Contact No*"
                        className="phone"
                        country={"us"}
                        value={companyPhone}
                        autoFormat={true}
                        onChange={(phone, country) => {
                          setCompanyPhone(phone);
                          setIsPasswordFocussed(false);
                          setErrors({ phone: "" });
                          var regex = phones[country.countryCode.toUpperCase()];
                          if (regex != undefined) {
                            if (!regex.test(phone)) {
                              setPhoneIsValid(false);
                              return setErrors({
                                companyPhone: "Invalid Phone Number",
                              });
                            } else {
                              setPhoneIsValid(true);
                              return setErrors({ phone: "" });
                            }
                          }
                        }}
                      />
                      <div style={{ paddingTop: "7px" }}>
                        <span className="text-danger">
                          {errors.companyPhone}
                        </span>
                      </div>

                      {/* </div> */}
                      <div className="input-field">
                        <img className="field-icon" src={LockIcon} />
                        <input
                          className="password"
                          type={toggler}
                          placeholder="Password*"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setErrors({
                              password: "",
                            });
                            setIsPasswordFocussed(true);
                          }}
                        />
                        <img
                          className="view-icon"
                          src={passwordImage}
                          onClick={passwordHandler}
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.password}</span>
                      </div>
                      {password && isPasswordFocussed ? (
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                            "lowercase",
                          ]}
                          minLength={8}
                          value={password}
                          iconSize={10}
                          className="password-checklist"
                          onChange={(isValid) => { }}
                          messages={{
                            minLength: "Minimum 8 characters",
                            specialChar: "Special characters",
                            number: "Numbers",
                            capital: "Uppercase letters",
                            lowercase: "Lowercase letters",
                          }}
                          invalidColor="#f06161"
                          validColor="#2e8b57"
                        />
                      ) : (
                        ""
                      )}

                      <div className="input-field">
                        <img className="field-icon" src={LockIcon} />
                        <input
                          className="password"
                          type={confirmToggler}
                          placeholder="Re-Enter Password*"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setErrors({
                              confirmPassword: "",
                            });
                            setIsPasswordFocussed(false);
                          }}
                        />
                        <img
                          className="view-icon"
                          src={confirmPasswordImage}
                          onClick={ConfirmPasswordHandler}
                        />
                      </div>
                      <div>
                        <span className="text-danger">
                          {errors.confirmPassword}
                        </span>
                      </div>

                      <div className="login-button-container">
                        <RouterLink to="/login">
                          <p className="display-link-company-register">
                            Already Registered? Login Now
                          </p>
                        </RouterLink>
                        <button className="btn-register">Register</button>
                      </div>
                    </div>
                  </form>
                </div>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
      <ConfirmationModal
        tempMail={tempMail}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}
