import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useLocation,useNavigate } from 'react-router-dom';
import Spinner from "../loaders/Spinner";
import UserSnackbar from "../snackBars/UserSnackbar";



export default function ScreenerLinkedinProfile() {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)


    useEffect(() => {

        async function fetchData() {
            console.log("Called")
    
    
            try {
    
                const headers = {
                    'Content-Type': 'application/json',
                };
                const url = '/api/screener/linkedin-data'
    
                const grant_type = 'authorization_code'
                const client_id = '78y49finlyr0sb'
                const client_secret = 'EnThqnhmT07A49wV'
    
                const searchParams = new URLSearchParams(location.search);
                const code = searchParams.get('code');
                console.log(code);
    
                // const redirect_uri = "https%3A%2F%2Flocalhost%3A3000%2Fscreener%2Flinkedin-login"
                const redirect_uri = `${process.env.REACT_APP_REDIRECT_URL}%2Fscreener%2Flinkedin-login`

                // http://192.168.1.246:3000/screener/linkedin-login
                console.log("redirect uri",redirect_uri);
                
                const res = await axios.get(`${url}?grant_type=${grant_type}&client_id=${client_id}&client_secret=${client_secret}&code=${code}&redirect_uri=${redirect_uri}`, { headers })
                // setEmail(res.data.email)
                console.log("data from backend",res.data);

                //extracting email from response
                const { verified_email, email } = res.data
                const newData = {
                email, verified_email
                }

                const resData=await axios.post("/api/screener/login/linkedin",newData);
                console.log("linkedin login data",resData);

                const { token } = resData.data;
                // const decoded = jwt_decode(token);
                const { id, role } = resData.data.payload;

                sessionStorage.setItem("jwtToken", token);
                sessionStorage.setItem("id", id);
                sessionStorage.setItem("role", role);

                const user = sessionStorage.getItem("jwtToken");
                navigate('/screener/profile', { state: res.data });

            }
            catch (err) {
                console.log(err);
                setLoading(false);
                setOpenSnackbar(true);
                console.log(err.response.data.message);
                setMessage(err.response.data.message);
                navigate('/register');
            }
            finally {
                setLoading(false); // Set loading to false when done
            }
        }
    
        fetchData()
    }, [])
    return(
        <>
        loading ? (
            <Spinner />
        ) : null

        <UserSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
        />
      </>
    );
}
