import React, { useEffect, useState } from "react";

//external libraries
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Country, State, City }  from 'country-state-city';


import SaveProfileSnackBar from "../snackBars/saveProfileSnackbar";
import ErrorMessageSnackbar from "../snackBars/errorMessageSnackbar";

//css import
import "./ExperienceEdit.css";
const PersonalDetailsModal = ({
  role,
  openDetails,
  setOpenDetails,
  id,
  personalDetails,
  profileCompletion,
}) => {

  //city state countries for getting external states, cities and countries
  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));

  const updatedStates = (country) =>
    
     State.getStatesOfCountry(country?.value).map((state) => ({
      label: state.name,
      value: state.isoCode,
      ...state,
    }));
  

    const updatedCities = (country,state) =>
        
         City.getCitiesOfState(country?.value,state?.value).map((city) => ({
          label: city.name,
          value: city.isoCode,
          ...city,
        }));
  let newData = {};

  const [userRole, setUserRole] = useState(role);
  const [dob, setDob] = useState(personalDetails.dob);
  const [gender, setGender] = useState(personalDetails.gender);
  const [nationality, setNationality] = useState(personalDetails.nationality);
  const [address1, setAddress1] = useState(personalDetails.location.address1);
  const [address2, setAddress2] = useState(personalDetails.location.address2);
  const [city, setCity] = useState(personalDetails.location.city);
  const [state, setState] = useState(personalDetails.location.state);
  const [country, setCountry] = useState(personalDetails.location.country);
  const [postalCode, setPostalCode] = useState(
    personalDetails.location.postalCode
  );
  const [initialLocation, setInitialLocation] = useState({
    country: personalDetails.location.country
      ? { label: personalDetails.location.country }
      : null,
    state: personalDetails.location.state
      ? { label: personalDetails.location.state }
      : null,
    city: personalDetails.location.city
      ? { label: personalDetails.location.city }
      : null,
    countryId: personalDetails.location.countryId,
    stateId: personalDetails.location.stateId,
  });
  const [flag, setFlag] = useState();
  const [openCloseDialog, setOpenCloseDialog] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  // fetching data from backend for initial Render
  useEffect(() => {
    setFlag(personalDetails.flag);
  }, [openDetails]);

  //handle close confirmation dialog
  const onClose = () => {
    setDob(personalDetails.dob);
    setGender(personalDetails.gender);
    setNationality(personalDetails.nationality);
    setAddress1(personalDetails.location.address1);
    setAddress2(personalDetails.location.address2);
    setCity("");
    setState("");
    setCountry("");
    setErrors({
      dob: "",
      gender: "",
      nationality: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    });
    setPostalCode(personalDetails.location.postalCode);
    setOpenCloseDialog(false);
    setOpenDetails(false);
  };

  const onCloseCancel = () => {
    setOpenCloseDialog(false);
    setOpenDetails(true);
  };

  // handlePin Change to limit to max of 10m digits
  const handleNumChange = (e) => {
    setErrors({
      postalCode: "",
    });
    const limit = 10;
    setPostalCode(e.target.value.slice(0, limit));
  };

  // error handling
  const [errors, setErrors] = useState({
    dob: "",
    gender: "",
    nationality: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
  });

  //personalDetailsHandler
  const dobHandler = (e) => {
    setDob(e.target.value);
    setErrors({ dob: "" });
  };
  const genderHandler = (e) => {
    setGender(e.target.value);
    setErrors({ gender: "" });
  };
  const nationalityHandler = (e) => {
    setNationality(e.target.value);
    setErrors({ nationality: "" });
  };
  const address1Handler = (e) => {
    setAddress1(e.target.value);
  };
  const address2Handler = (e) => {
    setAddress2(e.target.value);
  };

  const handleCloseDetails = () => {
    setOpenCloseDialog(true);
    setOpenDetails(false);
  };
  //submit function
  const handleSubmit = (e) => {
    e.preventDefault();

    // if form is empty , then error is shown
    if (dob === "") {
      return setErrors({
        dob: "Date of Birth is required",
      });
    }
    if (gender === "") {
      return setErrors({
        gender: "Gender is required",
      });
    }
    if (nationality === "") {
      return setErrors({
        nationality: "Nationality is required",
      });
    }
    if (!initialLocation.country) {
      return setErrors({
        country: "Country is required",
      });
    }
    if (!initialLocation.state) {
      return setErrors({
        state: "State is required",
      });
    }
    if (!initialLocation.city) {
      return setErrors({
        city: "City is required",
      });
    }

    if (postalCode === "") {
      return setErrors({
        postalCode: "Postal Code is required",
      });
    }
    //flag to increment details
    if (flag === false) {
      profileCompletion = profileCompletion + 20;
    }
    newData = {
      dob,
      gender,
      nationality,
      address1,
      address2,
      city: initialLocation.city.label,
      state: initialLocation.state.label,
      country: initialLocation.country.label,
      countryId: initialLocation.countryId,
      stateId: initialLocation.stateId,
      postalCode,
      flag: true,
      profileCompletion,
    };

    axios
      .post(`/api/${userRole}/profile-personal-details/${id}`, { newData })
      .then((res) => {
        setOpenSnackbar(true);
        setOpenDetails(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenErrorSnackbar(true);
      });
  };

  return (
    <div>
      {/* Add Personal details Dialogue */}
      <Dialog
        open={openDetails}
        onClose={handleCloseDetails}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="experience-edit-dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-title-grid"
            >
              <div>
                <div className="experience-edit-title">
                  <p>Personal Details</p>
                </div>
                <div className="experience-edit-close">
                  <Tooltip title="Close">
                    <CloseIcon onClick={handleCloseDetails} />
                  </Tooltip>
                </div>
              </div>
              <Divider className="experience-edit-top-divider" />
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-right-grid"
            >
              <div className="experience-edit-form-container">
                <form className="experience-edit-form" noValidate>
                  <div className="input-container">
                    <div className="input-field">
                      <label className="date-label">Date of Birth*</label>
                      <input
                        className="position"
                        type="date"
                        value={dob}
                        max={new Date().toISOString().split("T")[0]}
                        onChange={dobHandler}
                        placeholder="mm/dd/yyyy"
                      />
                      <span className="text-danger">{errors.dob}</span>
                    </div>

                    <div className=" gender-dropdown">
                      <div className="input-field">
                        <label className="date-label">Gender*</label>
                        <select
                          value={gender || "default"}
                          onChange={genderHandler}
                        >
                          <option value="default" selected disabled hidden>
                            Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Prefer not to say">
                            Prefer not to say
                          </option>
                        </select>
                      </div>
                    </div>

                    <span className="text-danger">{errors.gender}</span>

                    <div className="input-field">
                      <label className="date-label">Nationality*</label>
                      <input
                        className="position"
                        type="text"
                        value={nationality}
                        onChange={nationalityHandler}
                        placeholder="Ex: American"
                      />
                      <span className="text-danger">{errors.nationality}</span>
                    </div>

                    <div className="input-field">
                      <label className="date-label">Address Line1</label>
                      <input
                        className="position"
                        type="text"
                        value={address1}
                        onChange={address1Handler}
                        placeholder="Ex: Door No./Apartment No."
                      />
                    </div>
                    <div className="input-field">
                      <label className="date-label">Address Line2</label>
                      <input
                        className="position"
                        type="text"
                        value={address2}
                        onChange={address2Handler}
                        placeholder="Ex: Street name"
                      />
                    </div>

                    <div class="input-field-modal">
                        <label className="date-label">Country*</label>
                        <Select
                          class="position"
                          type="text"
                          value={initialLocation.country}
                          options={updatedCountries}
                          onChange={(value) => {
                            setInitialLocation(
                              {
                                country: value,
                                countryId: value.isoCode,
                                state: null,
                                city: null,
                              },
                              false
                            );
                            setErrors({
                              country: "",
                            });
                          }}
                          placeholder="Country"
                        />
                        <span className="text-danger">{errors.country}</span>
                      </div>

                      <div class="input-field-modal">
                        <label className="date-label">State*</label>
                        <Select
                          class="position"
                          type="text"
                          placeholder="State"
                          options={updatedStates(
                            initialLocation.country
                              ? initialLocation.country
                              : null
                          )}
                          value={initialLocation.state}
                          onChange={(value) => {
                            setInitialLocation(
                              {
                                ...initialLocation,
                                state: value,
                                stateId: value.isoCode,
                                city: null,
                              },
                              false
                            );
                            setErrors({
                              state: "",
                            });
                          }}
                        />
                        <span className="text-danger">{errors.state}</span>
                      </div>
                      <div class="input-field-modal">
                        <label className="date-label">City*</label>
                        <Select
                          class="position"
                          type="text"
                          options={updatedCities(initialLocation.country
                            ? initialLocation.country
                            : null,
                            initialLocation.state
                              ? initialLocation.state
                              : null
                          )}
                          value={initialLocation.city}
                          onChange={(value) => {
                            setInitialLocation({
                              ...initialLocation,
                              city: value.isoCode,
                            });
                            setErrors({
                              city: "",
                            });
                          }}
                          placeholder="City"
                        />
                        <span className="text-danger">{errors.city}</span>
                      </div>
                    <div className="input-field">
                      <label className="date-label">Postal Code*</label>
                      <input
                        className="position"
                        type="number"
                        value={postalCode}
                        onChange={handleNumChange}
                        placeholder="Ex: 1234567"
                      />
                      <span className="text-danger">{errors.postalCode}</span>
                    </div>
                  </div>
                  <div className="button-container">
                    <button className="btn-style filled-btn filled-btn-animation" onClick={handleSubmit}>
                      {" "}
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Close dialog confimation dialog */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openCloseDialog}
        onClose={handleCloseDetails}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={11.5}
              sm={11.5}
              md={11.5}
              lg={11.5}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure ?</h5>
                <p>Are you sure you want to go back?.</p>
                <p>All your current changes will be lost!</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="button-container delete-btn-container">
          <button className="warning-btn-style filled-btn filled-btn-animation" onClick={onCloseCancel}>
            {" "}
            Cancel
          </button>
          <button className="warning-btn-style outlined-btn outlined-btn-animation" onClick={onClose}>
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      <SaveProfileSnackBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <ErrorMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
      />
    </div>
  );
};

export default PersonalDetailsModal;
