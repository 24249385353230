import { RECEIVE_POSITIONS } from '../actions/Positions';
// import { merge } from 'lodash';

export const positionReducer= (state = [], action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_POSITIONS:
            return Object.assign([], state, action.positions.data);
        default:
            return state;
    }
};