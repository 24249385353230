import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";  // Import useParams
import axios from "axios";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../CandidateResults/ViewCandidateDetails.css";

const ViewCandidateDetails = ({ candidate, onClose }) => {
    const { candidateId } = useParams(); // Extract candidateId from URL
  const [candidateDetails, setCandidateDetails] = useState(null);

//   useEffect(() => {
//     const fetchCandidateDetails = async () => {
//       try {
//         const response = await axios.post(
//           `/api/admin/skill-assessment/test-management/candidate-details/${candidateId}`
//         );
//         setCandidateDetails(response.data);
//       } catch (error) {
//         console.error("Error fetching candidate details:", error);
//       }
//     };
//     fetchCandidateDetails();
//   }, [candidateId]);
  
  useEffect(() => {
    const fetchCandidateDetails = async () => {
      try {
        const response = await axios.post(
          `/api/admin/skill-assessment/test-management/get-candidate-TestResult/${candidate.testId}/${candidate.CandidateId}`
        );
        setCandidateDetails(response.data);
      } catch (error) {
        console.error("Error fetching candidate details:", error);
      }
    };
    fetchCandidateDetails();
  }, [candidate]);

  if (!candidateDetails) {
    return <div>Loading...</div>;
  }

  const sections = candidateDetails?.candidate?.sections || [];
  const testResult = candidateDetails?.candidate?.testResult || {};

  return (
    <div className="candidate-details">
      <button className="close-btn" onClick={onClose}>
        Close
      </button>
      <h4>{candidate.name}'s Test Report</h4>
      <p>Email: {candidate.email}</p>
      <p>Test: {candidateDetails.testName}</p>
      <p>
        Score: {testResult.securedScore} / {candidateDetails.totalPoints}
      </p>

      {sections.map((section, sectionIndex) => (
        <div key={section._id} className="section">
          <div className="section-header-view">
            <h5>
               {section.sectionName}
            </h5>
            <p style={{color:"#fff"}}>
              Section Score:{" "}
              {testResult.sectionResults.find(
                (res) => res.sectionName === section.sectionName
              )?.sectionScore || 0}
            </p>
          </div>

          <div className="questions">
            {section.problems.map((question, qIndex) => {
              const result = testResult.sectionResults.find((res) =>
                res.questions.some((q) => q.questionId === question._id)
              );
              const questionResult = result?.questions.find(
                (q) => q.questionId === question._id
              );

              return (
                <div key={question._id} className="question">
                  <p>
                     {question.text}
                  </p>
                  <p>Difficulty: {question.difficulty}</p>
                  <p>Type: {question.type}</p>

                  <div className="options-details">
                    {question.options.map((option, oIndex) => (
                      <FormControlLabel
                        key={oIndex}
                        value={option}
                        control={
                          <Radio
                            checked={option === questionResult?.candidateAnswer}
                            color={
                              option === question.correctAnswer
                                ? "success"
                                : "default"
                            }
                            // disabled
                          />
                        }
                        label={
                          <span>
                            {option}
                            {option === question.correctAnswer && (
                              <span className="tick-mark">✔</span>
                            )}
                          </span>
                        }
                        className={`option ${
                          option === question.correctAnswer
                            ? "correct-answer"
                            : option === questionResult?.candidateAnswer
                            ? "candidate-answer"
                            : ""
                        }`}
                      />
                    ))}
                  </div>

                  <p
                    className={`answer-status ${
                      question.correctAnswer === questionResult?.candidateAnswer
                        ? "correct"
                        : "incorrect"
                    }`}
                  >
                    {question.correctAnswer === questionResult?.candidateAnswer
                      ? "Correct"
                      : "Incorrect"}
                  </p>
                  <p>
                    Points Awarded:{" "}
                    {question.correctAnswer === questionResult?.candidateAnswer
                      ? questionResult?.points
                      : 0}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ViewCandidateDetails;
