import { useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  TextField,
  Card,
  CardContent,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SubjectIcon from "@mui/icons-material/Subject";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import Problems from "./Problems";
import GeneralSettings from "./GeneralSettings";
import AdvancedSettings from "./AdvancedSettings";
import Candidates from "./Candidates";
import axios from "axios";
import "./TestManagement.css";

// Tab Panels
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const TestManagement = ( ) => {
  const [show, setShow] = useState(false);
  const [testName, setTestName] = useState("");
  const [editedTestName, setEditedTestName] = useState(""); // Temporary edited name
  const [tabIndex, setTabIndex] = useState(0);
  const [showTabs, setShowTabs] = useState(false);
  const [testId, setTestID] = useState("");
  const [sections, setSections] = useState([]);
  const [GeneralSettingsData, setGeneralSettingsData] = useState([]);
  const [AdvancedSettingsData, setAdvancedSettingsData] = useState([]);
  const [CandidatesData, setCandidatesData] = useState([]);
  const [tests, setTests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    () => Number(localStorage.getItem("rowsPerPage")) || 5
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [totalPoints, setTotalPoints] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "" });

  const [searchParams] = useSearchParams();
  const isNewTest = searchParams.get("isNew") === "true"; // Read the flag from query params
  const [isGeneralSettingsSaved, setIsGeneralSettingsSaved] = useState(false); // Default to true for existing tests

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Navigate = useNavigate();

  // Format function to get the desired date and time format
  const formatDateTime = (date, time) => {
    if (!date || !time) return ""; // Return an empty string if either field is missing

    const dateTime = new Date(`${date}T${time}`);
    return isNaN(dateTime.getTime()) // Check if the date is valid
      ? ""
      : dateTime.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        day: "numeric",
        month: "short",
      });
  };


  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axios.post(
          `/api/admin/skill-assessment/test-management/get-all-test-details`
        );
        console.log("Fetched test details:", response.data); // Logging the data
        setTests(response.data.reverse());
      } catch (error) {
        console.error("Error fetching test details:", error);
        alert("Failed to fetch test details. Please try again.");
      }
    };

    fetchTests();
  }, []);

  console.log("tests state", tests);
  console.log("tests state", tests[0]?.totalPoints);
  // console.log("tests state",tests.AdvancedSettings.)

  useEffect(() => {
    // Store the rows per page setting in localStorage
    localStorage.setItem("rowsPerPage", rowsPerPage);
  }, [rowsPerPage]);

  // const handleSubmit = async () => {
  //   if (testName) {
  //     const testData = await axios.post(
  //       "/api/admin/skill-assessment/test-management/create-test",
  //       { testName }
  //     );
  //     console.log("test data", testData);

  //     setTestID(testData.data.test.id);
  //     setSections([
  //       {
  //         name: testData.data.test.sections[0].sectionName,
  //         id: testData.data.test.sections[0].id,
  //         questions: testData.data.test.sections[0].problems
  //           ? testData.data.test.sections[0].problems
  //           : [],
  //       },
  //     ]);
  //     setShowTabs(true);
  //     setShow(false); // Close modal on submit
  //   }
  // };

  // Function to handle cloning a test
  // const handleCloneTest = async (testId) => {
  //   try {
  //     const response = await axios.post(`/api/admin/skill-assessment/test-management/clone-test/${testId}`);
  //     alert("Test cloned successfully!");
  //     // Fetch updated tests after cloning
  //     const updatedTests = await axios.post(`/api/admin/skill-assessment/test-management/get-all-test-details`);
  //     setTests(updatedTests.data); // Update state with new cloned test
  //   } catch (error) {
  //     console.error("Error cloning test:", error);
  //     alert("Failed to clone test. Please try again.");
  //   }
  // };

  // const handleCloneTest = async (testId) => {
  //   try {
  //     // Clone the test on the server
  //     const response = await axios.post(
  //       `/api/admin/skill-assessment/test-management/clone-test/${testId}`
  //     );
  //     // alert("Test cloned successfully!");

  //     // Optimistically update the tests state
  //     const clonedTest = response.data.test; // Assuming your server returns the cloned test
  //     setTests((prevTests) => [...prevTests, clonedTest]); // Add cloned test to the existing list

  //     // Optionally, fetch updated tests to ensure all data is current
  //     // const updatedTests = await axios.post(`/api/admin/skill-assessment/test-management/get-all-test-details`);
  //     // setTests(updatedTests.data);
  //   } catch (error) {
  //     console.error("Error cloning test:", error);
  //     alert("Failed to clone test. Please try again.");
  //   }
  // };

  const handleSubmit = async () => {
    if (!testName.trim()) {
      setSnackbar({ open: true, message: "Test name cannot be empty.", severity: "warning" });
      return;
    }
  
    // Check if test name already exists
    const isDuplicate = tests.some((test) => test.testName.toLowerCase() === testName.toLowerCase());
  
    if (isDuplicate) {
      setSnackbar({ open: true, message: "Test name already exists!", severity: "error" });
      return;
    }
  
    try {
      const testData = await axios.post(
        "/api/admin/skill-assessment/test-management/create-test",
        { testName }
      );
  
      console.log("test data", testData);
  
      setTestID(testData.data.test.id);
      setSections([
        {
          name: testData.data.test.sections[0].sectionName,
          id: testData.data.test.sections[0].id,
          questions: testData.data.test.sections[0].problems || [],
        },
      ]);
      setShowTabs(true);
      setShow(false); // Close modal on submit
  
   
  
      // Success Snackbar
      setSnackbar({ open: true, message: "Test created successfully!", severity: "success" });
    } catch (error) {
      console.error("Error creating test:", error);
      setSnackbar({ open: true, message: "Failed to create test. Please try again.", severity: "error" });
    }
  };

  const handleCloneTest = async (testId) => {
    try {
      // Clone the test on the server
      const response = await axios.post(
        `/api/admin/skill-assessment/test-management/clone-test/${testId}`
      );

      const clonedTest = response.data.test; // Assuming your server returns the cloned test

      // Set the cloned test details in the state
      setTestID(clonedTest._id);
      setTestName(clonedTest.testName);
      setEditedTestName(clonedTest.testName); // Set the name for editing
      setSections(
        clonedTest.sections.map((section) => ({
          id: section._id,
          name: section.sectionName,
          questions: section.problems || [],
        }))
      );

      setGeneralSettingsData(clonedTest.GeneralSettings || {});
      setAdvancedSettingsData(clonedTest.AdvancedSettings || {});
      setCandidatesData(clonedTest.candidates || []);
      setStartDate(clonedTest.AdvancedSettings.startDate);
      setEndDate(clonedTest.AdvancedSettings.endDate);
      setStartTime(clonedTest.AdvancedSettings.startTime);
      setEndTime(clonedTest.AdvancedSettings.endTime);
      setShowTabs(true); // Show tabs after cloning
    } catch (error) {
      console.error("Error cloning test:", error);
      alert("Failed to clone test. Please try again.");
    }
  };

  const handleOpenDialog = (e, testId) => {
    e.stopPropagation(); // Prevent navigation or triggering parent events
    setSelectedTestId(testId);
    setOpenDialog(true);
  };

  const handleCloseDialog = (e) => {
    e.stopPropagation(); // Prevent navigation or triggering parent events
    setOpenDialog(false);
    setSelectedTestId(null);
  };

  const handleSnackbarClose = (e, reason) => {
    if (reason === "clickaway") return; // Ignore clickaway
    if (e?.stopPropagation) e.stopPropagation(); // Stop propagation only if e is defined
    setSnackbar({ open: false, message: "", severity: "" });
  };

  const handleDeleteTest = async (e) => {
    e.stopPropagation(); // Prevent navigation or triggering parent events
    try {
      await axios.delete(
        `/api/admin/skill-assessment/test-management/${selectedTestId}/delete-test`
      );
      setTests((prevTests) => prevTests.filter((test) => test._id !== selectedTestId)); // Remove deleted test from state
      setSnackbar({ open: true, message: "Test deleted successfully!", severity: "success" });
    } catch (error) {
      console.error("Error deleting test:", error);
      setSnackbar({ open: true, message: "Failed to delete test. Please try again.", severity: "error" });
    } finally {
      setOpenDialog(false); // Close dialog after operation
    }
  };

  // const handleDeleteTest = async (testId) => {
  //   const confirmDelete = window.confirm(
  //     "Are you sure you want to delete this test?"
  //   );
  //   if (!confirmDelete) return;

  //   try {
  //     await axios.delete(
  //       `/api/admin/skill-assessment/test-management/${testId}/delete-test`
  //     );
  //     setTests((prevTests) => prevTests.filter((test) => test._id !== testId)); // Remove deleted test from state
  //     alert("Test deleted successfully!");
  //   } catch (error) {
  //     console.error("Error deleting test:", error);
  //     alert("Failed to delete test. Please try again.");
  //   }
  // };

  const handleEachTestClick = async (test) => {
    console.log("test click", test);

    const { startTime, endTime } = test.AdvancedSettings;

    setTestID(test._id); // Use _id from MongoDB
    setTestName(test.testName);
    setEditedTestName(test.testName); // Initialize the edited name
    setShowTabs(true);

    setSections(
      test.sections.map((section) => ({
        id: section._id,
        name: section.sectionName,
        questions: section.problems || [], // Ensure questions are part of the data
      }))
    );
    setGeneralSettingsData(test.GeneralSettings || {}); // Assuming general settings exist
    setAdvancedSettingsData(test.AdvancedSettings || {}); // Assuming advanced settings exist
    setCandidatesData(test.candidates || []); // Assuming candidates list exists
    setStartDate(test.AdvancedSettings.startDate);
    setEndDate(test.AdvancedSettings.endDate);
    setStartTime(test.AdvancedSettings.startTime);
    setEndTime(test.AdvancedSettings.endTime);
  };

  // const handleEachTestClick = async (test) => {
  //   console.log("test click", test);

  //   const { AdvancedSettings = {}, GeneralSettings = {}, sections = [], candidates = [] } = test;

  //   // Safely destructure properties with fallback values
  //   const { startTime, endTime, startDate, endDate } = AdvancedSettings;

  //   setTestID(test._id); // Use _id from MongoDB
  //   setTestName(test.testName);
  //   setEditedTestName(test.testName); // Initialize the edited name
  //   setShowTabs(true);

  //   setSections(
  //     sections.map((section) => ({
  //       id: section._id,
  //       name: section.sectionName,
  //       questions: section.problems || [], // Ensure questions are part of the data
  //     }))
  //   );

  //   setGeneralSettingsData(GeneralSettings); // Assuming general settings exist
  //   setAdvancedSettingsData(AdvancedSettings); // Assuming advanced settings exist
  //   setCandidatesData(candidates); // Assuming candidates list exists
  //   setStartDate(startDate || null); // Fallback to null if undefined
  //   setEndDate(endDate || null); // Fallback to null if undefined
  //   setStartTime(startTime || null); // Fallback to null if undefined
  //   setEndTime(endTime || null); // Fallback to null if undefined
  // };


  console.log("advancedSettingData", AdvancedSettingsData);


  // const handleTabChange = (event, newValue) => {
  //   setTabIndex(newValue);
  //   // Revert edited name to original name when switching tabs without saving
  //   setEditedTestName(testName);
  // };

  const handleTabChange = async (event, newValue) => {
    setTabIndex(newValue);
    setEditedTestName(testName); // Reset edited name when switching tabs
  
    if (newValue === 2) { // If Advanced Settings tab is selected
      try {
        console.log("advanced settings data displayed");
        const response = await axios.post(
          `/api/admin/skill-assessment/test-management/get-advanced-settings/${testId}`
        );
  
        if (response.data) {
          setAdvancedSettingsData(response.data.advancedSettings);
        }
      } catch (error) {
        console.error("Error fetching advanced settings:", error);
      }
    }
  };
  

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const filteredTests = tests.filter((test) =>
    test.testName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastTest = currentPage * rowsPerPage;
  const indexOfFirstTest = indexOfLastTest - rowsPerPage;
  const currentTests = filteredTests.slice(indexOfFirstTest, indexOfLastTest);

  const paginate = (event, value) => {
    setCurrentPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page on rows per page change
  };

  // Calculate and save total points
  useEffect(() => {
    if (sections.length > 0) {
      const calculatedTotalPoints = sections.reduce(
        (total, section) =>
          total +
          section.questions.reduce((sum, question) => sum + question.points, 0),
        0
      );

      setTotalPoints(calculatedTotalPoints); // Store in state
      saveTotalPoints(calculatedTotalPoints); // Send to backend
    }
  }, [sections]);

  const saveTotalPoints = async (totalPoints) => {
    console.log(saveTotalPoints);
    try {
      const response = await axios.put(
        `/api/admin/skill-assessment/test-management/update-test-points/${testId}`,
        { totalPoints }
      );
      console.log("Total points saved:", response.data);
    } catch (error) {
      console.error("Error saving total points:", error);
      alert("Failed to save total points. Please try again.");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      {!showTabs && (
        <Grid
          container
          justifyContent="center"
          spacing={2}
          sx={{ marginBottom: 3 }}
        >
          <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#F06161" }}
              onClick={handleShow}
            >
              Create New Test
            </Button>
          </Grid>

          {/* <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
            <TextField
              label="Search Tests"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={handleSearch}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#F06161",
                  },
                  "&:hover fieldset": {
                    borderColor: "#F06161",
                  },
                },
                marginBottom: 2,
              }}
            />
          </Grid> */}

          <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
            <TextField
              label="Search Tests"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            {currentTests.map((test) => (
              <Card
                key={test._id}
                sx={{
                  marginBottom: 2,
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
                  },
                }}
                onClick={() => {
                  handleEachTestClick(test);
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ cursor: "pointer", color: "#333" }}
                    >
                      {test.testName}
                    </Typography>
                    {/* Clone Icon */}
                    <div
                      style={{
                        display: "flex",
                        gap: "15px",
                        marginRight: "20px",
                      }}
                    >
                      <Tooltip title="Clone Test">
                        <ContentCopyIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCloneTest(test._id);
                          }}
                          sx={{
                            cursor: "pointer",
                            color: "#888",
                            "&:hover": {
                              color: "#f06161",
                            },
                          }}
                        />
                      </Tooltip>
                      {/* <Tooltip title="Delete Test">
                        <DeleteIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTest(test._id);
                          }}
                          sx={{
                            cursor: "pointer",
                            color: "#888",
                            "&:hover": {
                              color: "#f06161",
                            },
                          }}
                        />
                      </Tooltip> */}
                      <Tooltip key={test._id} title="Delete Test">
                        <DeleteIcon
                          onClick={(e) => handleOpenDialog(e, test._id)}
                          sx={{
                            cursor: "pointer",
                            color: "#888",
                            "&:hover": {
                              color: "#f06161",
                            },
                          }}
                        />
                      </Tooltip>

                      {/* Delete Confirmation Dialog */}
                      <Dialog
                        open={openDialog}
                        onClose={(e) => handleCloseDialog(e)}
                        aria-labelledby="delete-dialog-title"
                        aria-describedby="delete-dialog-description"
                        PaperProps={{
                          style: {
                            padding: '24px',
                            borderRadius: '12px',
                            minWidth: '400px',
                          },
                        }}
                      >
                        <DialogContent>
                          <DialogContentText
                            id="delete-dialog-description"
                            style={{
                              fontSize: '1.1rem',
                              textAlign: 'center',
                              marginBottom: '16px',
                            }}
                          >
                            Are you sure you want to delete this test?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '0 16px 16px',
                          }}
                        >
                          <Button
                            onClick={(e) => handleDeleteTest(e)}
                            variant="contained"
                            style={{
                              backgroundColor: '#f06161',
                              color: '#fff',
                              fontWeight: 'bold',
                              borderRadius: '20px',
                            }}
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={(e) => handleCloseDialog(e)}
                            variant="outlined"
                            style={{
                              borderColor: '#f06161',
                              color: '#f06161',
                              fontWeight: 'bold',
                              borderRadius: '20px',
                            }}
                          >
                            No
                          </Button>
                        </DialogActions>
                      </Dialog>

                      {/* Snackbar for Success/Error Messages */}
                      <Snackbar
                        open={snackbar.open}
                        autoHideDuration={3000}
                        onClose={(e, reason) => handleSnackbarClose(e, reason)}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        onClick={(e) => e.stopPropagation()} // Prevent navigation when snackbar is clicked
                      >
                        <Alert
                          onClose={(e) => handleSnackbarClose(e)}
                          severity={snackbar.severity}
                          sx={{ width: "100%" }}
                        >
                          {snackbar.message}
                        </Alert>
                      </Snackbar>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ marginTop: 2 }}
            >
              <Grid item>
                <FormControl variant="outlined" size="small">
                  <InputLabel
                    sx={{
                      "&.Mui-focused": {
                        color: "#F06161", // Change label color when focused
                      },
                    }}
                  >
                    Rows per page
                  </InputLabel>
                  <Select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    label="Rows per page"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "default", // Default border color
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#F06161", // Change border color when hovered
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#F06161", // Border color when focused
                      },
                      "& .MuiSelect-select:focus": {
                        backgroundColor: "transparent", // Prevent changing background color on focus
                      },
                    }}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item>
                <Pagination
                  count={Math.ceil(filteredTests.length / rowsPerPage)}
                  page={currentPage}
                  onChange={paginate}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {showTabs && (
        <Box>
          <Typography variant="h4">{testName}</Typography>
          {/* Icons and Test Details Below Text */}
          <div className="test-details">
            {/* Time of the test */}
            {/* <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: "0.9rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AccessTimeIcon style={{ fontSize: "1rem", marginRight: 4 }} />
              {GeneralSettingsData?.duration} minutes
            </Typography> */}
            {GeneralSettingsData?.duration ? (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  fontSize: "0.9rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AccessTimeIcon style={{ fontSize: "1rem", marginRight: 4 }} />
                {GeneralSettingsData.duration} minutes
              </Typography>
            ) : null}

            {/* Number of sections */}
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: "0.9rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SubjectIcon style={{ fontSize: "1rem", marginRight: 4 }} />
              {sections?.length} sections
            </Typography>

            {/* Test start and end date */}
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: "0.9rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              {(startDate && startTime) || (endDate && endTime) ? (
                <DateRangeIcon style={{ fontSize: "1rem", marginRight: 4 }} />
              ) : null}

              {formatDateTime(startDate, startTime)}
              {startDate && startTime && endDate && endTime ? " - " : ""}
              {formatDateTime(endDate, endTime)}
            </Typography>

            {/* Total points */}
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: "0.9rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <EmojiEventsIcon style={{ fontSize: "1rem", marginRight: 4 }} />
              {totalPoints} points
            </Typography>

            {/* <Typography
              variant="body2"
              color="textSecondary"
              style={{
                fontSize: "0.9rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <EmojiEventsIcon style={{ fontSize: "1rem", marginRight: 4 }} />
              {sections.reduce(
                (total, section) =>
                  total +
                  section.questions.reduce(
                    (sum, question) => sum + question.points,
                    0
                  ),
                0
              )}{" "}
              points
            </Typography> */}
          </div>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="test management tabs"
          >
            <Tab label="Problems" />
            <Tab label="General Settings" />
            <Tab label="Advanced Settings" />
            <Tab label="Candidates" />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <Problems
              testId={testId}
              sections={sections}
              setSections={setSections}
              isGeneralSettingsSaved={isGeneralSettingsSaved}
              setIsGeneralSettingsSaved={setIsGeneralSettingsSaved}
              onSaveComplete={setIsGeneralSettingsSaved}
              isNewTest={isNewTest}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <GeneralSettings
              testId={testId}
              testName={editedTestName}
              setTestName={setEditedTestName} // Pass the temporary state
              saveTestName={() => setTestName(editedTestName)} // Function to save the name
              data={GeneralSettingsData}
              setGeneralSettingsData={setGeneralSettingsData}
              onSaveComplete={setIsGeneralSettingsSaved}
              isNewTest={isNewTest}

            />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <AdvancedSettings
              testId={testId}
              data={AdvancedSettingsData}
              setAdvancedSettingsData={setAdvancedSettingsData}
              setStartTime={setStartTime} // Pass down setStartTime
              setEndTime={setEndTime} // Pass down setEndTime
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              onSaveComplete={setIsGeneralSettingsSaved}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={3}>
            <Candidates
              testId={testId}
              testName={testName}
              tests={tests}
              data={CandidatesData}
            />
          </TabPanel>
        </Box>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTestName">
              <Form.Label>Test Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter test name"
                value={testName}
                onChange={(e) => setTestName(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="contained"
              style={{ backgroundColor: "#F06161",marginTop:"10px" }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TestManagement;
