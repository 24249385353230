import React, { useEffect, useState, useMemo } from 'react'
import { Card, Divider } from '@mui/material'
import axios from 'axios'

function RecruiterInterviews({setSelectedRecruiterId, openInterviews, setOpenInterviews }) {

    const [recruiters, setRecruiters] = useState([])
    const [query, setQuery] = useState("")

    const id = sessionStorage.getItem("id")

    async function fetchRecruiters(query) {
        try {
            console.log(query)
            let result = await axios.get(`/api/admin/get-recruiters/${id}?query=${query}`)
            console.log(result)
            setRecruiters(result.data)
        }
        catch (err) {
            console.log(err)
            setRecruiters([])
        }
    }

    const handleSearch = () => {
        let timer;
        return function (e) {
            let searchTerm = e.target.value
            setQuery(e.target.value)
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
                fetchRecruiters(searchTerm)
            }, 500)
        }
    }

    useEffect(() => {
        fetchRecruiters("")
    }, [])

    const debounceSearch = useMemo(() => handleSearch(), [])

    return (
        <Card className="recruiter-interviews">
            <div className="flex" style={{ padding: "1rem", alignItems: "baseline", gap: "1rem", justifyContent: "space-between" }}>
                <h5 className='mb'>Recruiters</h5>
                <input className="recruiter-search" value={query} onChange={debounceSearch} type="search" placeholder='Search Recruiter by Name or E-mail' />
            </div>

            <div className="recruiters-interviews-list-container">
            <div className="flex recruiter-interviews-heading" style={{ justifyContent: "space-around", width: "79%" }}>
                <h6>Name</h6>
                <h6>E-Mail</h6>
                <h6>No. of Interviews</h6>
            </div>
            <Divider />
            <div className="recruiter-interviews__list">
                {
                    recruiters.map(recruiter => (
                        <div className="recruiter-interviews__row mb">
                            <div className="recruiter-interviews__row__item">
                                {/* <h6>Recruiter Name:&nbsp;</h6> */}
                                <p>{recruiter.firstName} {recruiter.lastName}</p>
                            </div>
                            <div className="recruiter-interviews__row__item">
                                {/* <h6>Recruiter E-mail:&nbsp;</h6> */}
                                <p>{recruiter.recruiterEmail}</p>
                            </div>
                            <div className="recruiter-interviews__row__item">
                                {/* <h6>Total Interviews:&nbsp;</h6> */}
                                <p>{recruiter.noOfInterviews}</p>
                            </div>
                            <div className="recruiter-interviews__row__item">
                                <button
                                    className="view-interviews-btn"
                                    onClick={() => {
                                        setOpenInterviews(true)
                                        setSelectedRecruiterId(recruiter._id)
                                    }}
                                >
                                    View All Interviews
                                </button>
                            </div>
                        </div>
                    ))
                }
            </div>
            </div>

        </Card>
    )
}

export default RecruiterInterviews