import React, { useEffect, useState, useRef, useCallback } from "react";

import axios from "axios";

// MUI Imports
import Box from "@mui/material/Box";

import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Grid } from "@mui/material";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-input-2";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import BusinessIcon from "@mui/icons-material/Business";

//import custom textarea component
import TextareaCounter from "../../../textarea/TextareaCounter";

//cropper imports
import Select from "react-select";
import Cropper from "react-easy-crop";

//other imports
import getCroppedImg from "../../../modals/CropImage";
import { getPhonesRegex } from "../../../auth/PhoneValidation";
import UserSnackbar from "../../../snackBars/UserSnackbar";
import { Country, State, City } from "country-state-city";

const ClientDetails = ({
  clientData,
  setClientData,
  nextPage,
  previousPage,
  setPreviousPage,
  count,
  setCount,
}) => {
  console.log(clientData);
  //useRef
  const imageRef = useRef();
  const id = sessionStorage.getItem("id");

  //default avatar
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

  //states for cropper
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isAvatar, setIsAvatar] = useState(clientData.isAvatar);
  const [isNewImg, setIsNewImg] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [croppedArea, setCroppedArea] = useState();
  const [interaction, setInteraction] = useState(false);
  const [croppedImage, setCroppedImage] = useState(clientData.croppedImage);
  const [profileImage, setProfileImage] = useState(clientData.profileImage);
  const [sliderValue, setSliderValue] = useState(zoom);
  const [imagePublicId, setImagePublicId] = useState(clientData.imagePublicId);
  const [croppedImagePublicId, setCroppedImagePublicId] = useState(
    clientData.croppedImagePublicId
  );

  //states for snackBar
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  //form states
  const [companyName, setCompanyName] = useState(clientData.companyName);
  const [email, setEmail] = useState(clientData.email);
  const [about, setAbout] = useState(clientData.about);
  const [website, setWebsite] = useState(clientData.website);

  const [address1, setAddress1] = useState(clientData.address1);
  const [address2, setAddress2] = useState(clientData.address2);

  console.log(clientData);
  //city state countries for getting external states, cities and countries
  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));

  const updatedStates = (country) =>
    State.getStatesOfCountry(country?.value).map((state) => ({
      label: state.name,
      value: state.isoCode,
      ...state,
    }));

  const updatedCities = (country, state) =>
    City.getCitiesOfState(country?.value, state?.value).map((city) => ({
      label: city.name,
      value: city.isoCode,
      ...city,
    }));

  const [initialLocation, setInitialLocation] = useState({
    country: clientData.country ? { label: clientData.country } : null,
    state: clientData.state ? { label: clientData.state } : null,
    city: clientData.city ? { label: clientData.city } : null,
    countryId: clientData.countryId,
    stateId: clientData.stateId,
  });
  // store the regex of phone number patterns of all countries
  const phones = getPhonesRegex();
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [phone, setPhone] = useState(clientData.phone);
  const [postalCode, setPostalCode] = useState(clientData.postalCode);

  const [errors, setErrors] = useState({
    imageSize: "",
    imageType: "",
    companyName: "",
    email: "",
    phone: "",
    country: "",
  });

  const companyNameRef = useRef(null);
  const emailRef = useRef(null);

  //Dialog box states
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  useEffect(() => {
    setCrop(clientData.crop);
    setZoom(clientData.zoom);
    setCroppedAreaPixels(clientData.croppedAreaPixels);
  }, [count]);

  //Profile Photo handlers
  const onCropChange = (crop) => {
    if (!isAvatar) {
      setCrop(crop);
      setClientData({ ...clientData, crop: crop });
    }
  };

  const onZoomChange = (zoom) => {
    if (isAvatar) {
      return null;
    } else {
      setZoom(zoom);
      setClientData({ ...clientData, zoom: zoom });
    }
  };

  // called everytime the image is cropped
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setCroppedArea(croppedArea);
    setClientData({ ...clientData, croppedAreaPixels: croppedAreaPixels });
  }, []);

  // used to get cropped image
  const showCroppedImage = useCallback(async () => {
    let profileImgUrl = profileImage;
    try {
      const croppedImage = await getCroppedImg(
        profileImgUrl,
        croppedAreaPixels,
        0 //rotation
      );
      setCroppedImage(croppedImage);
    } catch (e) {
      // console.error(e);
    }
  }, [croppedAreaPixels, profileImage]);

  // handle remove image alert dialog
  const handleRemoveImageDialog = () => {
    setOpenAlertDialog(true);
  };

  // handle profile image upload
  const handleProfileImageUpload = async (e) => {
    const fileCheck = e.target.files[0];
    const maxSize = 1024 * 1024; //1MB
    if (fileCheck) {
      if (fileCheck?.size > maxSize) {
        setErrors({ imageSize: "Please upload image less than 1MB" });
        imageRef.current.value = null;
      } else if (!fileCheck?.type.startsWith("image")) {
        setErrors({ imageType: "Please upload an image file" });
        e.target.files = null;
      } else {
        let imageDataUrl = await readFile(fileCheck);
        setProfileImage(imageDataUrl);
        setErrors({ imageSize: "", imageType: "" });
        setIsAvatar(false);
        setIsNewImg(true);
        setInteraction(false);
        setZoom(1);
      }
    }
  };

  // handle profile image remove
  const handleRemoveProfileImage = (e) => {
    setProfileImage(profileAvatar);
    setZoom(1);
    setErrors({ imageSize: "", imageType: "" });
    setIsAvatar(true);
    imageRef.current.value = null;
    setOpenAlertDialog(false);
    setCrop({ x: 0, y: 0 });
  };

  // disable the right and left arrow keys for the slider
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  // used to get url of newly uploaded image
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  //dialog handlers
  const onCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const companyNameHandler = (e) => {
    setCompanyName(e.target.value);
    setErrors({
      companyName: "",
    });
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
    setErrors({
      email: "",
    });
  };

  const aboutHandler = (e) => {
    setAbout(e.target.value);
  };

  const websiteHandler = (e) => {
    setWebsite(e.target.value);
  };

  const address1Handler = (e) => {
    setAddress1(e.target.value);
  };
  const address2Handler = (e) => {
    setAddress2(e.target.value);
  };

  // handlePin Change to limit to max of 10m digits
  const handleNumChange = (e) => {
    setErrors({
      postalCode: "",
    });
    const limit = 10;
    setPostalCode(e.target.value.slice(0, limit));
  };

  // Function to scroll to the target element
  const scrollToElement = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth", // You can change this to 'auto' for instant scrolling
        block: "center", // You can change this to 'end' or 'center' to adjust where the element appears on the screen
      });
    }
  };

  const goToNextPage = () => {
    if (companyName === "") {
      scrollToElement(companyNameRef);
      return setErrors({
        companyName: "Company Name is required",
      });
    }

    if (email === "") {
      scrollToElement(emailRef);
      return setErrors({
        email: "Email is required",
      });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      scrollToElement(emailRef);
      return setErrors({
        email: "Email is Invalid",
      });
    }

    if (!initialLocation.country) {
      return setErrors({
        country: "Country is required",
      });
    }

    if (phone === "") {
      return setErrors({ phone: "Phone Number is required" });
    } else if (!phoneIsValid) {
      return setErrors({ phone: "Invalid Phone Number" });
    }

    // axios.get(`/api/admin/internal-database/${role}/get-all-emails/${email}`)
    //     .then((res) => {
    //         if (res.data.length > 0) {
    //             nextRender = false
    //             setMessage("Email already exists")
    //             setOpenSnackbar(true)
    //         } else {
    //             if (nextPage) {
    //                 setCount(count + 1)
    //             } else {
    //                 setCount(count - 1)
    //             }
    //             setPreviousPage(false)
    //         }
    //     })
    //     .catch((err) => {
    //         if (err.response.status === 404) {
    //             if (nextPage) {
    //                 setCount(count + 1)
    //             } else {
    //                 setCount(count - 1)
    //             }
    //             setPreviousPage(false)
    //         }
    //     })

    setClientData({
      ...clientData,
      profileImage,
      croppedImage,
      croppedAreaPixels,
      croppedArea,
      zoom,
      crop,
      imagePublicId,
      croppedImagePublicId,
      interacted: interaction,
      companyName,
      about,
      website,
      email,
      phone,
      isAvatar,
      address1,
      address2,
      city: initialLocation.city?.label,
      state: initialLocation.state?.label,
      country: initialLocation.country?.label,
      stateId: clientData.stateId,
      countryId: clientData.countryId,
      postalCode,
    });
    if (nextPage) {
      setCount(count + 1);
    } else {
      setCount(count - 1);
    }
    setPreviousPage(false);
  };

  return (
    <div>
      <Slide direction="left" in={true} timeout={500}>
        <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
          <Card
            id="main-edit"
            className=" form-card table-card"
            style={{
              padding: "15px 20px 18px 20px ",
              boxShadow: "none",
            }}
          >
            <Grid
              container
              spacing={0}
              direction="row"
              className="add-recruiter-card-container"
            >
              <Grid item xs={12} sm={7} md={6} lg={6}>
                <div style={{ display: "flex", gap: "10px" }}>
                  <BusinessIcon sx={{ color: "#2c3e50" }} />
                  <h5 style={{ color: "#283848" }}>Client Details</h5>
                </div>
              </Grid>

              <Grid container spacing={0} justifyContent="center">
                {/* Left Grid item */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  sx={{ padding: "0 20px 20px 20px" }}
                  className="left-grid"
                >
                  <div className="left-container" style={{ width: "530px" }}>
                    <div className="image-container">
                      <div className="crop-container">
                        <Cropper
                          className="cropper"
                          image={profileImage}
                          crop={crop}
                          zoom={zoom}
                          initialCroppedAreaPixels={
                            isNewImg || isAvatar ? null : croppedAreaPixels
                          }
                          aspect={16 / 16}
                          onCropChange={(crop) => onCropChange(crop)}
                          onCropComplete={onCropComplete}
                          onCropSizeChange={showCroppedImage}
                          onInteractionStart={() => {
                            setInteraction(true);
                            showCroppedImage();
                          }}
                          onInteractionEnd={showCroppedImage}
                          onZoomChange={(zoom) => onZoomChange(zoom)}
                          restrictPosition={true}
                          onMediaLoaded={showCroppedImage}
                          objectFit="auto-cover"
                        />
                      </div>
                      <div>
                        <label className="image-upload">
                          <input
                            type="file"
                            onChange={handleProfileImageUpload}
                            accept=".jpg, .png, .jpeg"
                            ref={imageRef}
                          />
                          <span>Upload</span>
                        </label>
                        <button
                          className="image-remove"
                          onClick={handleRemoveImageDialog}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                    <div>
                      <span className="text-danger">
                        {errors.imageSize} {errors.imageType} {errors.image}
                      </span>
                    </div>
                    <div className="name-designation">
                      <h3>{companyName} </h3>
                    </div>
                  </div>
                  <div className="slider-container">
                    <Box sx={{ height: 100 }}>
                      <Slider
                        sx={{
                          '& input[type="range"]': {
                            WebkitAppearance: "slider-vertical",
                          },
                        }}
                        step={0.1}
                        type="range"
                        min={1}
                        max={3}
                        // marks
                        size="small"
                        value={zoom}
                        orientation="vertical"
                        defaultValue={sliderValue}
                        aria-label="Zoom In"
                        valueLabelDisplay="auto"
                        onKeyDown={preventHorizontalKeyboardNavigation}
                        onChange={
                          isAvatar
                            ? null
                            : (e, zoom) => {
                                setZoom(zoom);
                                setClientData({ ...clientData, zoom: zoom });
                                setInteraction(true);
                                showCroppedImage();
                              }
                        }
                      />
                    </Box>
                    <div>
                      <p>Zoom in</p>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  sx={{ padding: "0 20px 20px 20px" }}
                  className="right-grid"
                >
                  <form noValidate>
                    <div className="input-container">
                      <div className="split-inputs">
                        <div class="input-field-modal">
                          <label className="date-label">Company Name*</label>
                          <input
                            type="text"
                            ref={companyNameRef}
                            placeholder="Company Name"
                            value={companyName}
                            className={`${
                              errors.companyName && "input-field-error"
                            }`}
                            onChange={companyNameHandler}
                          />
                          <div style={{ height: "20px" }}>
                            <span className="text-danger">
                              {errors.companyName}
                            </span>
                          </div>
                        </div>

                        <div class="input-field-modal">
                          <label className="date-label">Company Email*</label>
                          <input
                            class="email"
                            type="email"
                            ref={emailRef}
                            placeholder="Email Id*"
                            value={email}
                            className={`${errors.email && "input-field-error"}`}
                            onChange={emailHandler}
                          />
                          <div style={{ height: "20px" }}>
                            <span className="text-danger">{errors.email}</span>
                          </div>
                        </div>
                      </div>

                      <div class="input-field-modal textarea__wrapper">
                        <label className="date-label">
                          About (Maximum of 500 characters)
                        </label>

                        <TextareaCounter
                          className="about"
                          maxLength={500}
                          value={about}
                          placeholder="Tell something about yourself"
                          onChange={aboutHandler}
                        />
                      </div>
                      <div class="input-field-modal">
                        <label className="date-label">Website</label>
                        <input
                          class="position"
                          type="text"
                          value={website}
                          onChange={websiteHandler}
                          placeholder="Ex: www.website.com"
                        />
                        <div style={{ height: "20px" }}></div>
                      </div>

                      <div className="split-inputs">
                        <div class="input-field-modal">
                          <label className="date-label">Address Line1</label>
                          <input
                            class="position"
                            type="text"
                            value={address1}
                            onChange={address1Handler}
                            placeholder="Ex: Door No./Apartment No."
                          />
                          <div style={{ height: "20px" }}></div>
                        </div>
                        <div class="input-field-modal">
                          <label className="date-label">Address Line2</label>
                          <input
                            class="position"
                            type="text"
                            value={address2}
                            onChange={address2Handler}
                            placeholder="Ex: Street name"
                          />
                          <div style={{ height: "20px" }}></div>
                        </div>
                      </div>

                      <div className="split-inputs">
                        <div class="input-field-modal">
                          <label className="date-label">Country*</label>
                          <Select
                            class="position"
                            type="text"
                            value={initialLocation.country}
                            options={updatedCountries}
                            onChange={(value) => {
                              setInitialLocation(
                                {
                                  country: value,
                                  countryId: value.isoCode,
                                  state: null,
                                  city: null,
                                },
                                false
                              );
                              setErrors({
                                country: "",
                              });
                            }}
                            placeholder="Country"
                          />
                          <span className="text-danger">{errors.country}</span>
                        </div>

                        <div class="input-field-modal">
                          <label className="date-label">State*</label>
                          <Select
                            class="position"
                            type="text"
                            placeholder="State"
                            options={updatedStates(
                              initialLocation.country
                                ? initialLocation.country
                                : null
                            )}
                            value={initialLocation.state}
                            onChange={(value) => {
                              setInitialLocation(
                                {
                                  ...initialLocation,
                                  state: value,
                                  stateId: value.isoCode,
                                  city: null,
                                },
                                false
                              );
                              setErrors({
                                state: "",
                              });
                            }}
                          />
                          <span className="text-danger">{errors.state}</span>
                        </div>
                        <div class="input-field-modal">
                          <label className="date-label">City*</label>
                          <Select
                            class="position"
                            type="text"
                            options={updatedCities(
                              initialLocation.country
                                ? initialLocation.country
                                : null,
                              initialLocation.state
                                ? initialLocation.state
                                : null
                            )}
                            value={initialLocation.city}
                            onChange={(value) => {
                              setInitialLocation({
                                ...initialLocation,
                                city: value.isoCode,
                              });
                              setErrors({
                                city: "",
                              });
                            }}
                            placeholder="City"
                          />
                          <span className="text-danger">{errors.city}</span>
                        </div>
                      </div>

                      <div className="split-inputs">
                        <div class="input-field-modal">
                          <label className="date-label"> Phone*</label>
                          <PhoneInput
                            id="phone"
                            placeholder="Contact No*"
                            name="phone"
                            class="phone"
                            // className={`${errors.phone && "input-field-error"}`}
                            country={"us"}
                            value={phone}
                            onChange={(phone, country) => {
                              setPhone(phone);
                              var regex =
                                phones[country.countryCode.toUpperCase()];
                              if (regex != undefined) {
                                if (!regex.test(phone)) {
                                  setPhoneIsValid(false);
                                  return setErrors({
                                    phone: "Invalid Phone Number",
                                  });
                                } else {
                                  setPhoneIsValid(true);
                                  return setErrors({ phone: "" });
                                }
                              }
                            }}
                          />
                          <div style={{ height: "20px" }}>
                            <span className="text-danger">{errors.phone}</span>
                          </div>
                        </div>
                        <div class="input-field-modal">
                          <label className="date-label">Postal Code</label>
                          <input
                            class="position"
                            type="number"
                            value={postalCode}
                            onChange={handleNumChange}
                            placeholder="Ex: 1234567"
                          />
                          <div style={{ height: "20px" }}>
                            {/* <span className="text-danger">{errors.postalCode}</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div
                    className="button-container navigation-btn-container next-btn-container"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <button
                      className="btn-style filled-btn filled-btn-animation"
                      onClick={goToNextPage}
                    >
                      {" "}
                      Next
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Slide>

      {/* Alert Dialog  */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAlertDialog}
        onClose={onCloseAlertDialog}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              item
              xs={11.5}
              sm={11.5}
              md={12}
              lg={12}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure?</h5>
                <p>Do you want remove your profile picture?</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="alert-buttons-container">
          <button
            className="warning-btn-style filled-btn filled-btn-animation"
            onClick={onCloseAlertDialog}
          >
            {" "}
            Cancel
          </button>
          <button
            className="warning-btn-style outlined-btn outlined-btn-animation"
            onClick={handleRemoveProfileImage}
          >
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      <UserSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
      />
    </div>
  );
};

export default ClientDetails;
