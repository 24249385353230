import { RECEIVE_ERROR_APPLICATION } from "../actions/applicationActions";
import { GET_AUTH_ERRORS, SET_CURRENT_USER } from "../actions/authTypes";
import { RECEIVE_AVAILABILITIES_ERROR } from "../actions/availability";
import { RECEIVE_INTERVIEW_ERRORS } from "../actions/Interviews";
import { RECEIVE_REVIEW_ERRORS } from "../actions/reviewActions";
import { RECEIVE_USER_ERRORS } from "../actions/userActions";

const initialState = {};

export const errorReducer= (state = initialState, action)=> {
    switch (action.type) {
        case GET_AUTH_ERRORS:
            if (action.payload === undefined){
                return state;
            }
            return action.payload;
        case SET_CURRENT_USER:
            return initialState;
        case RECEIVE_USER_ERRORS:
            console.log(action.payload.response.data);
            if (action.payload.response.data === undefined){
                return state;
            }
            return action.payload.response.data;
        case RECEIVE_ERROR_APPLICATION:
            if (action.error === undefined){
                return state;
            }
            return action.error;
        case RECEIVE_INTERVIEW_ERRORS:
            if (action.errors === undefined){
                return state;
            }
            return action.errors;
        case RECEIVE_REVIEW_ERRORS:
            if (action.errors === undefined){
                return null;
            }
            return action.errors;
        case RECEIVE_AVAILABILITIES_ERROR:
            if (action.errors === undefined){
                return state;
            }
            return action.payload.data.errors;
        default:
            return state;
    }
}
