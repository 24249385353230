import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const GaugeChart = ({ result, chart }) => {
    const [displayText, setDisplayText] = useState("");

    useEffect(() => {
        if (chart === "profileActivity") {
            if (result > 75) {
                setDisplayText("High");
            } else if (result <= 75 && result > 50) {
                setDisplayText("Medium");
            } else if (result <= 50 && result > 25) {
                setDisplayText("Low");
            } else {
                setDisplayText("Very Low");
            }
        } else if (chart === "profileCompletion") {
            setDisplayText(`${result}%`);
        }
    }, [result, chart]);

    const data = [
        { value: result },
        { value: 100 - result },
    ];

    const COLORS = ['#F06161', '#F0F0F0']; // Active and inactive colors

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                    <Pie
                        data={data}
                        startAngle={180}
                        endAngle={0}
                        innerRadius="90%"
                        outerRadius="100%"
                        dataKey="value"
                        stroke="none"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <div
                style={{
                    position: 'absolute',
                    top: '80%',
                    left: '50%',
                    transform: 'translate(-50%, -60%)',
                    fontSize: '24px',
                    color: '#283848',
                    textAlign: 'center',
                }}
            >
                {displayText}
            </div>
        </div>
    );
};

export default GaugeChart;
