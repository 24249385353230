import React from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import "./CandidateQuestionPage.css"

const AssessmentCompletedPage = () => {
  const location = useLocation();
  const { isTimeUp = true, reason = '' } = location.state || {};

  console.log('Received state:', location.state);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Arrange items vertically
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Typography variant="h4" sx={{ textAlign: 'center', padding: 3 }}>
        {reason === 'clickedSubmitButton'
          ? "You've completed this assessment."
          : isTimeUp
            ? "Sorry, your time is up! Your assessment has been submitted."
            : 'Your test was submitted due to exceeding a limit.'}
      </Typography>
      <a
        href="/"
        className="return-home"
        style={{ marginTop: '20px'}}
      >
        Return to Homepage
      </a>
    </Box>
  );
};

export default AssessmentCompletedPage;
