// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: var(--background-color-off-white);
  }
  
  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .spinner {
    width: 65px;
    height: 65px;
    display: inline-block;
    border-width: 4px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color:var(--orange);
    animation: spin 0.7s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/loaders/spinner.css"],"names":[],"mappings":"AAAA;IACI,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,eAAe;IACf,6CAA6C;EAC/C;;EAEA;IACE,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,SAAS;IACT,kBAAkB;IAClB,QAAQ;IACR,gCAAgC;EAClC;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,iBAAiB;IACjB,uCAAuC;IACvC,8BAA8B;IAC9B,oCAAoC;IACpC,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".overlay {\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    background: var(--background-color-off-white);\n  }\n  \n  .overlay__inner {\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    position: absolute;\n  }\n  \n  .overlay__content {\n    left: 50%;\n    position: absolute;\n    top: 50%;\n    transform: translate(-50%, -50%);\n  }\n  \n  .spinner {\n    width: 65px;\n    height: 65px;\n    display: inline-block;\n    border-width: 4px;\n    border-color: rgba(255, 255, 255, 0.05);\n    border-top-color:var(--orange);\n    animation: spin 0.7s infinite linear;\n    border-radius: 100%;\n    border-style: solid;\n  }\n  \n  @keyframes spin {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
