import { RECEIVE_APPLICATION, RECEIVE_APPLICATIONS, RECEIVE_ERROR_APPLICATION } from "../actions/applicationActions";

export const applicationReducer= (state = [], action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_APPLICATION:
            return [...state, action.application.data]
        case RECEIVE_APPLICATIONS:
            return action.applications.data;
        case RECEIVE_ERROR_APPLICATION:
            return state;
        default:
            return state;
    }
}