import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles for Quill
import axios from "axios";
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Pagination,
  Radio,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  Card,
  CardContent,
  Typography,
  IconButton,
  ListItem,
} from "@mui/material";

import FileUploadIcon from "@mui/icons-material/Upload";
import FileDownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SubjectIcon from "@mui/icons-material/Subject";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import CasinoIcon from "@mui/icons-material/Casino";
import "./SkillLibrary.css";

const SkillLibrary = ({ onSelectQuestion, showAddQuestionButton = true }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [category, setCategory] = useState("All");
  const [questions, setQuestions] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [newQuestion, setNewQuestion] = useState({
    name: "",
    type: "MCQ",
    text: "",
    category: "Verbal",
    options: ["", "", "", ""],
    correctAnswer: "",
    points: 0,
    difficulty: "Easy",
    image: "",
  });
  const [page, setPage] = useState(1);
  const questionsPerPage = 5;

  const [fileName, setFileName] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const fileRef = useRef(null);
  const templateFileLink =
    "https://res.cloudinary.com/df2qsnyox/raw/upload/v1726055398/Excel%20Sheet/question_upload_template.xlsx";

  const fetchQuestions = async () => {
    try {
      const response = await axios.post("/api/admin/getQuestion");
      setQuestions(response.data);
      console.log("get question method", response);
      console.log("get question method responsedata ", response.data);
    } catch (error) {
      console.error("Error fetching questions", error);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  // const handleImageUpload = async (event) => {
  //   const file = event.target.files[0];
  //   if (!file) return;

  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("upload_preset", "skill_assessment"); // Set in Cloudinary
  //   formData.append("cloud_name", "df2qsnyox");

  //   try {
  //     const response = await axios.post(
  //       "https://api.cloudinary.com/v1_1/df2qsnyox/image/upload",
  //       formData,
  //       { withCredentials: false } // Ensure credentials are disabled
  //     );
  //     setNewQuestion({ ...newQuestion, image: response.data.secure_url });
  //   } catch (error) {
  //     console.error("Error uploading image", error);
  //   }
  // };

  const handleImageSelection = (event) => {
    // console.log("upload image",event)
    const file = event.target.files[0];
    if (file) {
      setSelectedImageFile(file);
      setNewQuestion((prev) => ({ ...prev, image: URL.createObjectURL(file) })); // Preview before upload
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    console.log("event ", event.target.files[0]);

    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "skill_assessment"); // Cloudinary preset
    formData.append("cloud_name", "df2qsnyox"); // Your Cloudinary cloud name

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/df2qsnyox/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Image upload failed");
      }

      const data = await response.json();
      console.log("Cloudinary response:", data);
      console.log(data.secure_url, "secure url");

      setNewQuestion({ ...newQuestion, image: data.secure_url });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleCSVFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "text/csv") {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result;
        parseCSV(text);
      };
      reader.readAsText(file);
    } else {
      fileRef.current.value = null;
      setMessage("Incorrect file type!");
      setOpenSnackbar(true);
    }
  };

  const parseCSV = (text) => {
    const rows = text.split("\n");
    const questions = rows.map((row) => {
      const [
        name,
        type,
        text,
        category,
        options,
        correctAnswer,
        points,
        difficulty,
      ] = row.split(",");
      return {
        name,
        type,
        text,
        category,
        options: options.split(";"), // assuming options are separated by semicolons
        correctAnswer,
        points: parseInt(points, 10),
        difficulty,
      };
    });
    console.log(questions); // You can now send these questions to your backend
  };

  const handleRemoveFile = () => {
    setFileName("");
    // Clear any data related to the file if needed
  };

  // const handleAddQuestion = async () => {
  //   try {
  //     const response = await axios.post(`/api/admin/question`, newQuestion);
  //     setQuestions((prevQuestions) => [...prevQuestions, response.data]);
  //     setNewQuestion({
  //       name: "",
  //       type: "MCQ",
  //       text: "",
  //       category: "Verbal",
  //       options: ["", "", "", ""],
  //       correctAnswer: "",
  //       points: 0,
  //       difficulty: "Easy",
  //     });
  //     setDialogOpen(false);
  //   } catch (error) {
  //     console.error("Error adding question", error);
  //   }
  // };

  const handleAddQuestion = async () => {
    try {
      let imageUrl = "";

      if (selectedImageFile) {
        const formData = new FormData();
        formData.append("file", selectedImageFile);
        formData.append("upload_preset", "skill_assessment");
        formData.append("cloud_name", "df2qsnyox");

        const response = await fetch(
          `https://api.cloudinary.com/v1_1/df2qsnyox/image/upload`,
          { method: "POST", body: formData }
        );
        console.log("response ", response);

        if (!response.ok) throw new Error("Image upload failed");

        const data = await response.json();
        imageUrl = data.secure_url;
      }

      const questionData = { ...newQuestion, image: imageUrl };

      const response = await axios.post(`/api/admin/question`, questionData);
      setQuestions((prevQuestions) => [...prevQuestions, response.data]);

      // Reset state after adding
      setNewQuestion({
        name: "",
        type: "MCQ",
        text: "",
        category: "Verbal",
        options: ["", "", "", ""],
        correctAnswer: "",
        points: 0,
        difficulty: "Easy",
        image: "",
      });
      setSelectedImageFile(null);
      setDialogOpen(false);
    } catch (error) {
      console.error("Error adding question", error);
    }
  };

  // const handleEditQuestion = async () => {
  //   try {
  //     let imageUrl = newQuestion.image;
  //     console.log("selected image file",selectedImageFile)

  //     if (selectedImageFile) {
  //       const formData = new FormData();
  //       formData.append("file", selectedImageFile);
  //       formData.append("upload_preset", "skill_assessment");
  //       formData.append("cloud_name", "df2qsnyox");

  //       const response = await fetch(
  //         `https://api.cloudinary.com/v1_1/df2qsnyox/image/upload`,
  //         { method: "POST", body: formData }
  //       );
  //       console.log("response ", response);

  //       if (!response.ok) throw new Error("Image upload failed");

  //       const data = await response.json();
  //       imageUrl = data.secure_url;
  //     }

  //     const questionData = { ...newQuestion, image: imageUrl };
  //     const response = await axios.put(
  //       `/api/admin/editQuestion/${editingQuestion._id}`,
  //       questionData
  //     );
  //     setQuestions((prevQuestions) =>
  //       prevQuestions.map((q) =>
  //         q._id === editingQuestion._id ? response.data : q
  //       )
  //     );
  //     setNewQuestion({
  //       name: "",
  //       type: "",
  //       text: "",
  //       category: "Verbal",
  //       options: ["", "", "", ""],
  //       correctAnswer: "",
  //       points: 0,
  //       difficulty: "Easy",
  //       image: "",
  //     });
  //     setDialogOpen(false);
  //     setEditingQuestion(null);
  //   } catch (error) {
  //     console.error("Error editing question", error);
  //   }
  // };

  const handleEditQuestion = async () => {
    try {
      let imageUrl = newQuestion.image; // Keep the existing image
  
      // If a new image is selected, upload it
      if (selectedImageFile) {
        const formData = new FormData();
        formData.append("file", selectedImageFile);
        formData.append("upload_preset", "skill_assessment");
        formData.append("cloud_name", "df2qsnyox");
  
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/df2qsnyox/image/upload`,
          { method: "POST", body: formData }
        );
  
        if (!response.ok) throw new Error("Image upload failed");
        
        const data = await response.json();
        imageUrl = data.secure_url;
      }
  
      // Update question in the database
      const response = await axios.put(`/api/admin/editQuestion/${editingQuestion._id}`, {
        ...newQuestion,
        image: imageUrl, // Retain existing or set new image
      });
  
      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q._id === editingQuestion._id ? response.data : q
        )
      );
  
      // Reset state and close dialog
      setNewQuestion({
        name: "",
        type: "MCQ",
        text: "",
        category: "Verbal",
        options: ["", "", "", ""],
        correctAnswer: "",
        points: 0,
        difficulty: "Easy",
        image: "",
      });
      setSelectedImageFile(null);
      setDialogOpen(false);
      setEditingQuestion(null);
    } catch (error) {
      console.error("Error editing question", error);
    }
  };

  const handleDeleteQuestion = async (id) => {
    try {
      await axios.delete(`/api/admin/deleteQuestion/${id}`);
      setQuestions((prevQuestions) =>
        prevQuestions.filter((question) => question._id !== id)
      );
    } catch (error) {
      console.error("Error deleting question", error);
    }
  };

  const handleAddButtonClick = (question) => {
    onSelectQuestion(question);
  };

  // const handleOpenEditDialog = (question) => {
  //   setEditingQuestion(question);
  //   setNewQuestion(question);
  //   setDialogOpen(true);
  // };

  const handleOpenEditDialog = (question) => {
    setEditingQuestion(question);
    setNewQuestion({
      name: question.name,
      type: question.type,
      text: question.text,
      category: question.category,
      options: question.options,
      correctAnswer: question.correctAnswer,
      points: question.points,
      difficulty: question.difficulty,
      image: question.image || "", // Ensure image is set
    });

    setSelectedImageFile(null); // Reset previous selected file
    setDialogOpen(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setPage(1);
  };

  const handleCancel = () => {
    // setNewQuestion({
    //   name: "",
    //   type: "MCQ",
    //   text: "",
    //   category: "Verbal",
    //   options: ["", "", "", ""],
    //   correctAnswer: "",
    //   points: 0,
    //   difficulty: "Easy",
    // });
    setEditingQuestion(null);
    setDialogOpen(false);
  };

  const filteredQuestions = questions.filter(
    (question) =>
      (category === "All" || question.category === category) &&
      question.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedQuestions = filteredQuestions.slice(
    (page - 1) * questionsPerPage,
    page * questionsPerPage
  );

  // Calculate totals and counts
  const totalQuestions = questions.length;
  const categoryCounts = questions.reduce((counts, question) => {
    counts[question.category] = (counts[question.category] || 0) + 1;
    return counts;
  }, {});

  // Custom styles for the category count
  const categoryStyle = {
    backgroundColor: "#F06161",
    borderRadius: "3rem",
    padding: "0.2rem 0.5rem",
    color: "#fff",
    fontSize: "0.875rem",
    marginLeft: "0.5rem",
  };

  return (
    <div className="library-container">
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Search Questions"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "default",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#F06161",
                },
              },
              "& .MuiInputLabel-root": {
                "&.Mui-focused": {
                  color: "#F06161",
                },
              },
            }}
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Grid>

        {/* Main Category */}

        {/* <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel sx={{ "&.Mui-focused": { color: "#F06161" } }}>
              Category
            </InputLabel>
            <Select
              value={category}
              onChange={handleCategoryChange}
              label="Category"
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#F06161",
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#F06161",
                  },
              }}
              MenuProps={{
                sx: {
                  "& .MuiMenu-paper": {
                    borderColor: "#F06161",
                  },
                },
              }}
            >
              <MenuItem value="All">All ({questions.length})</MenuItem>
              {Object.keys(categoryCounts).map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat} ({categoryCounts[cat]})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel sx={{ "&.Mui-focused": { color: "#F06161" } }}>
              Category
            </InputLabel>
            <Select
              value={category}
              onChange={handleCategoryChange}
              label="Category"
              sx={{
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#F06161",
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#F06161",
                  },
              }}
              MenuProps={{
                sx: {
                  "& .MuiMenu-paper": {
                    borderColor: "#F06161",
                  },
                },
              }}
            >
              <MenuItem value="All">
                All
                <span
                  style={{
                    backgroundColor: "#F06161",
                    borderRadius: "3rem",
                    padding: "0.2rem 0.6rem",
                    color: "#fff",
                    marginLeft: "0.5rem",
                    fontSize: "0.875rem",
                  }}
                >
                  {questions.length}
                </span>
              </MenuItem>
              {Object.keys(categoryCounts).map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                  <span
                    style={{
                      backgroundColor: "#F06161",
                      borderRadius: "3rem",
                      padding: "0.2rem 0.6rem",
                      color: "#fff",
                      marginLeft: "0.5rem",
                      fontSize: "0.875rem",
                    }}
                  >
                    {categoryCounts[cat]}
                  </span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {showAddQuestionButton && (
          <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#F06161" }}
              onClick={() => {
                setNewQuestion({
                  name: "",
                  type: "",
                  text: "",
                  category: "Verbal",
                  options: ["", "", "", ""],
                  correctAnswer: "",
                  points: 0,
                  difficulty: "Easy",
                });
                setDialogOpen(true);
              }}
            >
              Add Question
            </Button>
          </Grid>
        )}

        <Grid item xs={12}>
          <div className="question-list">
            {paginatedQuestions.map((question) => (
              <Card key={question._id} className="question-item">
                <CardContent>
                  <div className="question-header">
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      style={{ fontSize: "0.2 rem" }}
                    >
                      {/* {question.categories.join(", ")} */}
                      {question.category}
                    </Typography>
                    <div className="question-actions">
                      <IconButton
                        onClick={() => handleOpenEditDialog(question)}
                      >
                        <EditIcon sx={{ color: "#F06161" }} />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteQuestion(question._id)}
                      >
                        <DeleteIcon sx={{ color: "#F06161" }} />
                      </IconButton>
                      {onSelectQuestion && (
                        <IconButton
                          onClick={() => handleAddButtonClick(question)}
                        >
                          <AddIcon sx={{ color: "#F06161" }} />
                        </IconButton>
                      )}
                    </div>
                  </div>
                  {/* <Typography variant="h6" className="question-text-library">
                    {question.text}
                  </Typography> */}
                  <Typography
                    variant="h6"
                    className="question-text-library"
                    style={{
                      whiteSpace: "pre-wrap",
                      fontFamily:
                        question.type === "Coding" ? "monospace" : "inherit",
                      backgroundColor:
                        question.type === "Coding" ? "#f5f5f5" : "inherit",
                      padding: question.type === "Coding" ? "8px" : "0",
                      borderRadius: question.type === "Coding" ? "4px" : "0",
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: question.text }} />
                  </Typography>

                  {question.image && (
  <img
    src={question.image}
    alt="Question"
    style={{
      width: "100%",      // Full width inside the container
      maxWidth: "300px",  // Prevent excessive enlargement
      height: "auto",     // Maintain aspect ratio
      objectFit: "contain", // Ensure full image visibility
      borderRadius: "8px", // Optional: rounded corners
      marginTop: "10px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.2)", // Optional: subtle shadow
    }}
  />
)}

                  <div className="question-details">
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <SubjectIcon style={{ fontSize: "1rem" }} />{" "}
                      {question.type}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <RadioButtonCheckedIcon style={{ fontSize: "1rem" }} />{" "}
                      {question.correctAnswer ? "Single" : "Multiple"}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <GraphicEqIcon style={{ fontSize: "1rem" }} />{" "}
                      {question.difficulty}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <CasinoIcon style={{ fontSize: "1rem" }} />{" "}
                      {question.points} points
                    </Typography>
                  </div>

                  <div className="question-options">
                    <RadioGroup>
                      {question.options.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          value={option}
                          control={
                            <Radio
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                            />
                          }
                          label={option}
                          checked={option === question.correctAnswer}
                          sx={{
                            ".MuiRadio-root": {
                              color:
                                option === question.correctAnswer
                                  ? "#F06161"
                                  : "default",
                            },
                          }}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
              count={Math.ceil(filteredQuestions.length / questionsPerPage)}
              page={page}
              onChange={(e, value) => setPage(value)}
              color="primary"
            />
          </Box>
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} onClose={handleCancel}>
        <DialogTitle>
          {editingQuestion ? "Edit Question" : "Add Question"}
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <TextField
              margin="dense"
              label="Question Name"
              fullWidth
              value={newQuestion.name}
              onChange={(e) =>
                setNewQuestion({ ...newQuestion, name: e.target.value })
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F06161",
                  },
                },
                "& .MuiInputLabel-root": {
                  "&.Mui-focused": {
                    color: "#F06161",
                  },
                },
              }}
            />

            <div style={{ width: "100%", marginTop: "10px" }}>
              <label
                style={{
                  fontSize: "14px",
                  color: "#666",
                  marginBottom: "5px",
                  display: "block",
                }}
              >
                Question Text
              </label>
              <ReactQuill
                theme="snow"
                value={newQuestion.text}
                onChange={(value) =>
                  setNewQuestion({ ...newQuestion, text: value })
                }
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link"],
                  ],
                }}
                className="custom-quill-editor"
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageSelection}
              />
              {selectedImageFile ? (
                <img
                  src={URL.createObjectURL(selectedImageFile)}
                  alt="Preview"
                  style={{ width: "100px", marginTop: "10px" , height: "100px"}}
                />
              ) : newQuestion.image ? (
                <img
                  src={newQuestion.image}
                  alt="Existing"
                  style={{ width: "100px", marginTop: "10px" , height: "100px"}}
                />
              ) : null}
            </div>

            <FormControl fullWidth margin="dense">
              <InputLabel sx={{ "&.Mui-focused": { color: "#F06161" } }}>
                Type
              </InputLabel>
              <Select
                value={newQuestion.type}
                onChange={(e) =>
                  setNewQuestion({ ...newQuestion, type: e.target.value })
                }
                label="Type"
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#F06161",
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#F06161",
                    },
                }}
              >
                <MenuItem value="MCQ">MCQ</MenuItem>
                <MenuItem value="Coding">Coding</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <InputLabel sx={{ "&.Mui-focused": { color: "#F06161" } }}>
                Category
              </InputLabel>
              <Select
                value={newQuestion.category}
                onChange={(e) =>
                  setNewQuestion({ ...newQuestion, category: e.target.value })
                }
                label="category"
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#F06161",
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#F06161",
                    },
                }}
              >
                <MenuItem value="Verbal">Verbal</MenuItem>
                <MenuItem value="Quantitative">Quantitative</MenuItem>
                <MenuItem value="Logical">Logical</MenuItem>
                <MenuItem value="Technical">Technical</MenuItem>
                <MenuItem value="Analytical">Analytical</MenuItem>
              </Select>
            </FormControl>

            <Box display="flex" flexDirection="row" alignItems="center">
              <TextField
                margin="dense"
                label="Points"
                value={newQuestion.points}
                onChange={(e) =>
                  setNewQuestion({ ...newQuestion, points: e.target.value })
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "default",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#F06161",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    "&.Mui-focused": {
                      color: "#F06161",
                    },
                  },
                  mr: 2,
                }}
              />
              <FormControl fullWidth margin="dense">
                <InputLabel sx={{ "&.Mui-focused": { color: "#F06161" } }}>
                  Difficulty
                </InputLabel>
                <Select
                  value={newQuestion.difficulty}
                  onChange={(e) =>
                    setNewQuestion({
                      ...newQuestion,
                      difficulty: e.target.value,
                    })
                  }
                  label="Difficulty"
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#F06161",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#F06161",
                      },
                  }}
                >
                  <MenuItem value="Easy">Easy</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Hard">Hard</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {newQuestion.type === "MCQ" && (
              <div>
                {newQuestion.options.map((option, index) => (
                  <TextField
                    key={index}
                    margin="dense"
                    label={`Option ${index + 1}`}
                    fullWidth
                    value={option}
                    onChange={(e) => {
                      const newOptions = [...newQuestion.options];
                      newOptions[index] = e.target.value;
                      setNewQuestion({ ...newQuestion, options: newOptions });
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "default",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#F06161",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-focused": {
                          color: "#F06161",
                        },
                      },
                    }}
                  />
                ))}
                <FormControl fullWidth margin="dense">
                  <InputLabel sx={{ "&.Mui-focused": { color: "#F06161" } }}>
                    Correct Answer
                  </InputLabel>
                  <Select
                    value={newQuestion.correctAnswer}
                    onChange={(e) =>
                      setNewQuestion({
                        ...newQuestion,
                        correctAnswer: e.target.value,
                      })
                    }
                    label="Correct Answer"
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#F06161",
                      },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#F06161",
                        },
                    }}
                  >
                    {newQuestion.options.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            variant="outlined"
            sx={{
              color: "#f06161",
              borderColor: "#f06161",
              "&:hover": { borderColor: "#f06161" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={editingQuestion ? handleEditQuestion : handleAddQuestion}
            color="primary"
            variant="contained"
            style={{ backgroundColor: "#F06161" }}
          >
            {editingQuestion ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SkillLibrary;
