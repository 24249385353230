import React, { useState, useEffect } from "react";

//import mui
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

//import mui icons
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CalculateIcon from "@mui/icons-material/Calculate";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

//import components
import CandidateNavbar from "./CandidateNavbar";

//import styles
import { styled } from "@mui/system";
import "./CandidateQuestionPage.css";

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  margin: theme.spacing(1, 0),
  padding: theme.spacing(2),
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  borderRadius: "4px",
  backgroundColor: "#fff",
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  "&.Mui-checked": {
    color: "#F06161", // Change this to the desired color for checked state
  },
}));

const QuestionSideTab = ({
  initialSection,
  questions,
  setQuestions,
  onSectionChange,
  onBackToMenu,
  selectedQuestionId,
  onQuestionAttempted,
  formattedSections,
  testName,
  onAnswerChange,
  openFullscreenDialog,
  setOpenFullscreenDialog,
  enableFullscreen,
  fullscreenExitCount, // Receiving the exit count as a prop
  children,
}) => {
  console.log("");

  const [currentSection, setCurrentSection] = useState(initialSection);
  const [attemptedQuestions, setAttemptedQuestions] = useState(0);
  const totalQuestions = Object.values(questions).flat().length;

  // Calculate attempted questions based on the questions state
  useEffect(() => {
    const attemptedCount = Object.values(questions)
      .flat()
      .filter((q) => q.status === "Attempted").length;
    setAttemptedQuestions(attemptedCount);
  }, [questions]);

  useEffect(() => {
    const currentSectionElement = document.getElementById(
      `section-${currentSection}`
    );
    if (currentSectionElement) {
      currentSectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentSection]);

  useEffect(() => {
    if (selectedQuestionId) {
      const questionElement = document.getElementById(
        `question-${selectedQuestionId}`
      );
      if (questionElement) {
        questionElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [selectedQuestionId]);

  const handleChange = (event, newValue) => {
    if (newValue === "Main Menu") {
      onBackToMenu();
    } else {
      setCurrentSection(newValue);
      onSectionChange(newValue);
    }
  };

  const handleNextSection = () => {
    const sections = [
      "Main Menu",
      ...formattedSections.map((section) => section.sectionName),
    ];
    console.log("sections=", sections);

    const currentSectionIndex = sections.indexOf(currentSection);
    const nextSectionIndex = currentSectionIndex + 1;
    const nextSection = sections[nextSectionIndex];
    setCurrentSection(nextSection);
    onSectionChange(nextSection);
  };

  // const handleOptionChange = (id, questionId, option) => {
  //     onAnswerChange(currentSection, questionId, option);  // Call the parent's handler
  //     setQuestions(prevQuestions => {
  //         const updatedQuestions = { ...prevQuestions };
  //         const question = updatedQuestions[currentSection].find(q => q.id === id);
  //         if (question) {
  //             if (question.status !== 'Attempted') {
  //                 setAttemptedQuestions(prev => prev + 1);
  //             }
  //             question.selectedOption = option;
  //             question.status = 'Attempted';
  //         }
  //         onQuestionAttempted(currentSection, id, option);
  //         return updatedQuestions;
  //     });
  // };

  // const handleClearOption = (questionId) => {
  //     console.log('Clearing option for question:', questionId);

  //     setQuestions(prevQuestions => {
  //         const updatedQuestions = { ...prevQuestions };
  //         const question = updatedQuestions[currentSection].find(q => q.id === questionId);

  //         if (question && question.status === 'Attempted') {
  //             question.status = 'Unsolved';
  //             setAttemptedQuestions(prev => {
  //                 const newCount = Math.max(prev - 1, 0);
  //                 console.log('Updated attempted questions count:', newCount);
  //                 return newCount;
  //             });
  //         }

  //         onQuestionAttempted(currentSection, questionId, '');
  //         return updatedQuestions;
  //     });
  // };

  const handleOptionChange = (id, questionId, option) => {
    onAnswerChange(currentSection, questionId, option);
    setQuestions((prevQuestions) => {
      const updatedQuestions = { ...prevQuestions };
      const question = updatedQuestions[currentSection].find(
        (q) => q.id === id
      );
      if (question) {
        question.selectedOption = option;
        question.status = "Attempted";
      }
      localStorage.setItem("questions", JSON.stringify(updatedQuestions)); // Save to localStorage
      return updatedQuestions;
    });
  };

  const handleClearOption = (questionId) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = { ...prevQuestions };
      const question = updatedQuestions[currentSection].find(
        (q) => q.id === questionId
      );
      if (question) {
        question.status = "Unsolved";
        delete question.selectedOption;
      }
      localStorage.setItem("questions", JSON.stringify(updatedQuestions)); // Save to localStorage
      return updatedQuestions;
    });
  };

  useEffect(() => {
    const savedQuestions = localStorage.getItem("questions");
    if (savedQuestions) {
      setQuestions(JSON.parse(savedQuestions)); // Restore from localStorage
    }
  }, []);

  const sections = [
    "Main Menu",
    ...formattedSections.map((section) => section.sectionName),
  ];
  const isLastSection = currentSection === sections[sections.length - 1];
  console.log("last section", isLastSection);

  const currentQuestions = questions[currentSection] || [];
  console.log("current quesition", currentQuestions);

  // const sectionIcons = {
  //     Logical: <TipsAndUpdatesIcon />,
  //     Verbal: <QuestionAnswerIcon />,
  //     Quantitative: <CalculateIcon />,
  // };

  // const sectionHeaders = {
  //     Logical: 'Aptitude Test - Logical',
  //     Verbal: 'Aptitude Test - Verbal',
  //     Quantitative: 'Aptitude Test - Quantitative',
  // };

  const sectionHeaders = formattedSections.reduce((acc, section) => {
    acc[section.sectionName] = `Aptitude Test - ${section.sectionName}`;
    return acc;
  }, {});
  console.log("headers = ", sectionHeaders);

  // Detect if fullscreen mode is exited
  const handleFullscreenChange = () => {
    if (!document.fullscreenElement) {
      setOpenFullscreenDialog(true); // Trigger dialog when fullscreen exits
    }
  };

  useEffect(() => {
    // Add event listener to detect fullscreen exit
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  console.log("log of current question", currentQuestions);
  

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <CandidateNavbar
        totalQuestions={totalQuestions}
        attemptedQuestions={attemptedQuestions}
        showMeter={true}
        testName={testName}
      />
      {children}{" "}
      {/* its a props of time remaining passed from CandidateQuestionPage */}
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Tabs
          orientation="vertical"
          value={currentSection}
          onChange={handleChange}
          sx={{
            borderRight: 1,
            borderColor: "divider",
            width: 200,
            position: "fixed",
            top: 62,
            bottom: 0,
            overflowY: "auto",
          }}
        >
          <Tab
            icon={
              <Avatar sx={{ bgcolor: "#f06161" }}>
                <ArrowBackIcon />
              </Avatar>
            }
            value="Main Menu"
            sx={{ justifyContent: "flex-start", textTransform: "none" }}
          />
          {sections.slice(1)?.map((section) => (
            <Tab
              key={section}
              label={section}
              value={section}
              // icon={sectionIcons[section]}
              iconPosition="start"
              sx={{
                textAlign: "center",
                justifyContent: "center",
                textTransform: "none",
                height: "8vh",
              }}
            />
          ))}
        </Tabs>
        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            marginLeft: "200px",
            overflowY: "auto",
            height: "calc(100vh - 62px)",
          }}
        >
          <Paper
            sx={{
              padding: 3,
              width: "100%",
            }}
            id={`section-${currentSection}`}
          >
            {currentSection !== "Main Menu" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  backgroundColor: "#fff",
                }}
              >
                <ContentPasteIcon sx={{ mr: 1 }} />
                <Typography variant="h6">
                  {sectionHeaders[currentSection]}
                </Typography>
              </Box>
            )}
            {currentQuestions?.map((question, index) => (
              <Box
                key={question.id}
                sx={{
                  mb: 4,
                  width: "100%",
                  minHeight: "85vh",
                  display: "flex",
                  flexDirection: "column",
                }}
                id={`question-${question.id}`}
              >
                <Typography variant="h6" sx={{ mb: 1 }}>
                  Question {index + 1}
                </Typography>
                {/* <Typography variant="body1" sx={{ mb: 2 }}>
                                    {question.mcq.question}
                                </Typography> */}
                {/* <Typography
                  variant="body1"
                  sx={{
                    mb: 2,
                    whiteSpace: "pre-wrap",
                    fontFamily:
                      question.type === "Coding" ? "monospace" : "inherit",
                    backgroundColor:
                      question.type === "Coding" ? "#f5f5f5" : "inherit",
                    padding: question.type === "Coding" ? "8px" : "0",
                    borderRadius: question.type === "Coding" ? "4px" : "0",
                  }}
                >
                  {question.mcq.question}
                </Typography> */}

                <Typography
                  variant="body1" // Keep the original variant
                  className="question-text-library"
                  sx={{
                    whiteSpace: "pre-wrap",
                    fontFamily:
                      question.type === "Coding" ? "monospace" : "inherit",
                    backgroundColor:
                      question.type === "Coding" ? "#f5f5f5" : "inherit",
                    padding: question.type === "Coding" ? "8px" : "0",
                    borderRadius: question.type === "Coding" ? "4px" : "0",
                  }}
                >
                  <div
                    style={{
                      fontFamily:
                        question.type === "Coding" ? "monospace" : "inherit",
                      backgroundColor:
                        question.type === "Coding" ? "#f5f5f5" : "inherit",
                      padding: question.type === "Coding" ? "8px" : "0",
                      borderRadius: question.type === "Coding" ? "4px" : "0",
                    }}
                    dangerouslySetInnerHTML={{ __html: question.mcq.question }}
                  />
                </Typography>

                {question.image && (
  <img
    src={question.image}
    alt="Question"
    style={{
      width: "100%",      // Full width inside the container
      maxWidth: "300px",  // Prevent excessive enlargement
      height: "auto",     // Maintain aspect ratio
      objectFit: "contain", // Ensure full image visibility
      borderRadius: "8px", // Optional: rounded corners
      marginTop: "10px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.2)", // Optional: subtle shadow
    }}
  />
)}

                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => handleClearOption(question.id)}
                    sx={{
                      color: "#f06161",
                      borderColor: "#f06161",
                      "&:hover": { borderColor: "#f06161" },
                    }}
                  >
                    Clear
                  </Button>
                </Box>
                <RadioGroup
                  name={`mcq-question-${question.id}`}
                  onChange={(event) =>
                    handleOptionChange(
                      question.id,
                      question.questionId,
                      event.target.value
                    )
                  }
                  value={question.selectedOption || ""} // Ensure this value is correctly set
                >
                  {question.mcq.options?.map((option, i) => (
                    <StyledFormControlLabel
                      key={i}
                      value={option}
                      control={<StyledRadio />}
                      label={option}
                    />
                  ))}
                </RadioGroup>

                {index === currentQuestions.length - 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      mt: 2,
                    }}
                  >
                    {isLastSection ? (
                      <Button
                        variant="contained"
                        onClick={onBackToMenu}
                        sx={{
                          backgroundColor: "#f06161",
                          color: "white",
                          "&:hover": { backgroundColor: "#d05050" },
                        }}
                      >
                        GO TO SUBMISSION PAGE
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={handleNextSection}
                        sx={{
                          backgroundColor: "#f06161",
                          color: "white",
                          "&:hover": { backgroundColor: "#d05050" },
                        }}
                      >
                        NEXT SECTION
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
            ))}
          </Paper>
        </Box>
      </Box>
      {/* Render fullscreen dialog */}
      <Dialog open={openFullscreenDialog} maxWidth="sm" fullWidth>
        <DialogContent sx={{ height: "8vh" }}>
          <Typography variant="body1" sx={{ fontSize: "1rem" }}>
            This assessment requires your browser window to be in fullscreen
            mode.
          </Typography>
          {/* <Typography variant="body2" sx={{ mt: 2 }}>
            You have tried to exit fullscreen {fullscreenExitCount} times.
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={enableFullscreen}
            sx={{
              backgroundColor: "#f06161",
              color: "white",
              "&:hover": { backgroundColor: "#d05050" },
            }}
          >
            Enable Fullscreen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default QuestionSideTab;
