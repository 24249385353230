// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verify-main-container {
	display: flex;
	align-items: center;
	height: 100vh;
	background-color: var(--background-color-off-white);
	font-family: var(--font-family-sans-serif);
  }
  .verify-container {
	height: 70vh;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70vw;
  
	margin: 0 auto;
	position: relative;
  }
  .img-container img {
	height: 300px;
	width: auto;
	margin-bottom: 45px;
  }
  
  .verified-image-container{
	display: flex;
	justify-content: center;
  }
  .email-title {
	font-size: 25px;
	text-align: center;
  }
  .email-paragraph{
	text-align: center;
	font-size: var(--font-size-1-8px);
  }
  
  .verify-btn{
	
	background-color:var(--orange);
	color:white;
	font-family: var(--font-family-sans-serif);
	border:none;
	width:100%;
	padding: 6px 40px;
	font-weight: var(--font-weight-bold);
	font-size: 20px;
	border-radius:20px;
  }
  .verify-btn:hover{
	background-color: #ed3e3e;
	cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/components/auth/EmailVerify.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,aAAa;CACb,mDAAmD;CACnD,0CAA0C;EACzC;EACA;CACD,YAAY;CACZ,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,WAAW;;CAEX,cAAc;CACd,kBAAkB;EACjB;EACA;CACD,aAAa;CACb,WAAW;CACX,mBAAmB;EAClB;;EAEA;CACD,aAAa;CACb,uBAAuB;EACtB;EACA;CACD,eAAe;CACf,kBAAkB;EACjB;EACA;CACD,kBAAkB;CAClB,iCAAiC;EAChC;;EAEA;;CAED,8BAA8B;CAC9B,WAAW;CACX,0CAA0C;CAC1C,WAAW;CACX,UAAU;CACV,iBAAiB;CACjB,oCAAoC;CACpC,eAAe;CACf,kBAAkB;EACjB;EACA;CACD,yBAAyB;CACzB,eAAe;EACd","sourcesContent":[".verify-main-container {\n\tdisplay: flex;\n\talign-items: center;\n\theight: 100vh;\n\tbackground-color: var(--background-color-off-white);\n\tfont-family: var(--font-family-sans-serif);\n  }\n  .verify-container {\n\theight: 70vh;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\twidth: 70vw;\n  \n\tmargin: 0 auto;\n\tposition: relative;\n  }\n  .img-container img {\n\theight: 300px;\n\twidth: auto;\n\tmargin-bottom: 45px;\n  }\n  \n  .verified-image-container{\n\tdisplay: flex;\n\tjustify-content: center;\n  }\n  .email-title {\n\tfont-size: 25px;\n\ttext-align: center;\n  }\n  .email-paragraph{\n\ttext-align: center;\n\tfont-size: var(--font-size-1-8px);\n  }\n  \n  .verify-btn{\n\t\n\tbackground-color:var(--orange);\n\tcolor:white;\n\tfont-family: var(--font-family-sans-serif);\n\tborder:none;\n\twidth:100%;\n\tpadding: 6px 40px;\n\tfont-weight: var(--font-weight-bold);\n\tfont-size: 20px;\n\tborder-radius:20px;\n  }\n  .verify-btn:hover{\n\tbackground-color: #ed3e3e;\n\tcursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
