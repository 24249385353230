import * as React from "react";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import classnames from "classnames";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./LoginComponent.css";
import Logo from "../../images/brand.svg";
import BackArrow from "../../images/left-arrow.png";
// import arrow from "../../images/backArrow.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import MailIcon from "../../images/mail.png";
import LockIcon from "../../images/lock.png";
import UserIcon from "../../images/user.png";
import PhoneInput from "react-phone-input-2";
import "intl-tel-input/build/css/intlTelInput.css";
import ViewIcon from "../../images/view.png";
import HideIcon from "../../images/hide.png";

import axios from "axios";
import jwt_decode from "jwt-decode";
import ForgotPasswordModal from "../modals/ForgotPasswordModal";
// snackbar
import FrozenMessageSnackbar from "../snackBars/FrozenMessageSnackbar";
import UserSnackbar from "../snackBars/UserSnackbar";
import {useNavigate} from 'react-router-dom';

const theme = createTheme();

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [role, setRole] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [tempMail, setTempMail] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false)
  let newData = {};

  const [errors, setErrors] = useState({
    email: "",

    role: "",
  });

  //login submit hanbdler
  const onSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      return setErrors({ email: "Email is required" });
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return setErrors({
        email: "Email is Invalid",
      });
    }

    if (!role) return setErrors({ role: "Please select the role" });

    newData = {
      email: email,

      role: role,
    };
    if (role === "candidate" || role === "screener") {
      axios
        .post(`/api/${role}/forgot-password`, { newData })
        .then((res) => {
          console.log(newData);
          setTempMail(newData.email);
          setEmail("");
          setRole("");
          setErrors({
            email: "",
            role: "",
          });
          setShowModal(true);
          setTimeout(() => {
            navigate("/login");
          }, 2500);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            return setErrors({
              email: err.response.data.message,
            });
          } else if (err.response.status == 500) {
            return setErrors({
              email: err.response.data.message,
            });
          } else if (err.response.status == 409) {
            return setErrors({
              email: err.response.data.message,
            });
          }
          else if (err.response.status == 401) {
            //Dialog Box
            setOpenSnackbar(true)
            setMessage(err.response.data.message)
          }
        });
    } else if (role === "recruiter") {
      axios
        .post(`/api/recruiter/forgot-password`, { newData })
        .then((res) => {
          console.log(newData);
          setTempMail(newData.email);
          setEmail("");
          setRole("");
          setErrors({
            email: "",
            role: "",
          });
          setShowModal(true);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            axios
              .post(`/api/admin/forgot-password`, { newData })
              .then((res) => {
                console.log(newData);
                setTempMail(newData.email);
                setEmail("");
                setRole("");
                setErrors({
                  email: "",
                  role: "",
                });
                setShowModal(true);
              })
              .catch((err) => {
                if (err.response.status == 404) {
                  return setErrors({
                    email: err.response.data.message,
                  });
                } else if (err.response.status == 500) {
                  return setErrors({
                    email: err.response.data.message,
                  });
                } else if (err.response.status == 409) {
                  return setErrors({
                    email: err.response.data.message,
                  });
                }
              });
          } else if (err.response.status == 409) {
            return setErrors({
              email: err.response.data.message,
            });
          } else if (err.response.status == 500) {
            return setErrors({
              email: err.response.data.message,
            });
          } else if (err.response.status == 401) {
            setOpenErrorSnackbar(true);
          }
        });
    }
  };

  // 3, if(r==='candidate') candidate profile api/${role}/login/
  //backend,

  return (
    <div className="login-bg">
      <div className="card-container">
        <ThemeProvider theme={theme}>
          <Grid container component="main" className="main-grid">
            <CssBaseline />

            <Grid className="overlay-image" item xs={false} sm={false} md={6}>
              <Grid
                item
                //   md={6}
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className="header-grid"
              >
                <div class="left-logo ">
                  <div class="logo-img">
                    <img class="login-img" src={Logo} />

                    <div class="main-heading right-title">
                      <h1>Screener</h1>
                    </div>
                  </div>
                </div>
              </Grid>
              <div class="back-arrow">
                <RouterLink to="/">
                  <img src={BackArrow} />
                </RouterLink>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              component={Paper}
              elevation={6}
              square
              className="login-form"
            >
              <Box
                sx={{
                  my: 3,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "80vh",
                  position: "relative",
                }}
                className="main-box"
              >
                <div class="app-heading">
                  <div class="right-back-arrow">
                    <a href="/login">
                      {/* <img src={arrow} /> */}
                      <ArrowBackIcon />
                    </a>
                  </div>
                  <div class="container-logo">
                    <img class="screener-logo" src={Logo} />

                    <div class="main-heading main-title">
                      <h1>Screener</h1>
                    </div>
                  </div>
                </div>
                <div className="login-form-container">
                  <form
                    className="form-conatiner"
                    onSubmit={onSubmit}
                    noValidate
                  >
                    <div class="login-form-header">
                      <h2>Forgot Password</h2>
                    </div>
                    {/* <div class="social-card-container">
                <a href="#" class="social">
                <i class="fab fa-facebook-f"></i>
                </a>
                <a href="#" class="social">
                <i class="fab fa-google-plus-g"></i>
                </a>
                <a href="#" class="social">
                <i class="fab fa-linkedin-in"></i>
                </a>
                </div>
              <span>or use your account</span> */}
                    <div class="login-input-conatiner">
                      <div class="input-field">
                        <img class="field-icon" src={MailIcon} />

                        <input
                          className="email"
                          autoComplete="email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors({
                              email: "",
                            });
                          }}
                          value={email}
                          id="email"
                          type="email"
                          placeholder="Email Id*"
                        />
                      </div>
                      <div>
                        <span className="text-danger">{errors.email}</span>
                      </div>
                      {/* <div class="input-field">
                        <img class="field-icon" src={LockIcon} />
                        <input
                          className="password"
                          type={toggler}
                          placeholder="Password*"
                          autoComplete="current-password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          id="password"
                        />
                        <img
                          class="view-icon"
                          src={passwordImage}
                          onClick={passwordHandler}
                        />

                        <span className="text-danger">{errors.password}</span>
                      </div>

                      <a class="forgot-password" href='/forgot-password'>
                        Forgot Password?
                      </a> */}

                      <div
                        class="login-radio-container"
                        style={{ margin: "10px 0" }}
                      >
                        <label className="radio-label">
                          Forgot Password for*
                        </label>

                        <FormControl class="login-radio-group">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={role}
                            onChange={(e) => {
                              setRole(e.target.value);
                              setErrors({
                                role: "",
                              });
                            }}
                          >
                            <FormControlLabel
                              value="candidate"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 18,
                                    },
                                  }}
                                  required={true}
                                />
                              }
                              label="Job Seeker"
                            />
                            <FormControlLabel
                              value="screener"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 18,
                                    },
                                  }}
                                  required={true}
                                />
                              }
                              label="Screener"
                            />
                            <FormControlLabel
                              value="recruiter"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 18,
                                    },
                                  }}
                                  required={true}
                                />
                              }
                              label="Recruiter"
                            />
                          </RadioGroup>
                        </FormControl>
                        <span className="text-danger">{errors.role}</span>
                      </div>
                      <div className="login-button-container">
                        <button class="btn-login" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
      <ForgotPasswordModal
        show={showModal}
        tempMail={tempMail}
        onHide={() => setShowModal(false)}
      />
      <FrozenMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
        message="Your account has been frozen. Kindly contact your admin"
      />
      <UserSnackbar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
        message={message}
      />
    </div>
  );
};

export default ForgotPassword;
