import React, { useEffect, useState, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";

// Snackbars import
import SaveProfileSnackBar from "../snackBars/saveProfileSnackbar";
import ErrorMessageSnackbar from "../snackBars/errorMessageSnackbar";
import Skills from "./skills.js";

// css import
import "./ExperienceEdit.css";
const SkillEditModal = ({
  role,
  openSkills,
  setOpenSkills,
  id,
  skills,
  profileCompletion,
}) => {
  const [value, setValue] = useState("");
  const [clear, setClear] = useState(false); // passed inside key attribue of autocomplete to clear value on adding
  const [skill, setSkill] = useState("");
  const [experience, setExperience] = useState("");
  const [skillsList, setSkillsList] = useState(skills);

  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errors, setErrors] = useState({
    skill: "",
    skillArray: "",
  });
  const [disableButton, setDisableButton] = useState(false);

  const inputRef = useRef();
  const data = Skills();
  const experienceHandler = (e) => {
    const limit = 2;
    setExperience(e.target.value.slice(0, limit));
  };

  // handle close confirmation dialog
  const onCloseDialog = () => {
    setSkill("");
    setSkillsList(skills);
    setErrors({
      skill: "",
      skillArray: "",
    });
    setOpenCloseDialog(false);
    setOpenSkills(false);
  };

  // handle cancel button press of closeDialog
  const onCloseCancel = () => {
    setOpenCloseDialog(false);
    setOpenSkills(true);
  };

  // handle close of SkillEditModal
  const handleCloseSkills = () => {
    setOpenSkills(false);
    setOpenCloseDialog(true);
  };

  //add a new skill
  const addSkill = (e, skill) => {
    e.preventDefault();
    inputRef.current.focus();
    let comparingArray = skillsList.map((items) => {
      return items.skillName.trim().toLowerCase().replace(/ /g, "");
    });

    if (comparingArray.includes(skill.trim().toLowerCase().replace(/ /g, ""))) {
      return setErrors({
        skill: "Skill already exists",
      });
    }
    if (skill === "") {
      return setErrors({
        skill: "Skills is required",
      });
    }

    //if skill is not empty then it will add to dialog
    if (skill != "") {
      let skillObj = {
        skillName: skill,
        yearOfExperience: experience,
      };
      setSkillsList([...skillsList, skillObj]);
      setErrors({
        skill: "",
      });
      setSkill("");
      setValue("");
      setExperience("");
      setClear((prev) => !prev);
    }
  };

  //function  to delete
  const deleteSkill = (e, index) => {
    e.preventDefault();
    setSkillsList([
      ...skillsList.slice(0, index),
      ...skillsList.slice(index + 1, skillsList.length),
    ]);
  };

  //submit the form
  const onSubmit = (e) => {
    e.preventDefault();

    if (skillsList.length == 0) {
      return setErrors({ skillArray: "Enter atleast one skill" });
    } else {
      if (skills.length === 0) {
        profileCompletion = profileCompletion + 20;        
      }
    }
    setDisableButton(true);

    // if (skills.length === 0) {
    //   profileCompletion = profileCompletion + 20;
    // } else if (skillsList.length === 0) {
    //   profileCompletion = profileCompletion - 20;
    // }

    //axios to post the data
    axios
      .post(`/api/${role}/profile-skills/${id}`, {
        skillsList,
        profileCompletion,
      })
      .then((res) => {
        setSkill("");
        setOpenSkills(false);
        setDisableButton(false);
      })
      .catch((err) => {
        setOpenErrorSnackbar(true);
        setDisableButton(false);
      });
  };

  return (
    <div>
      <Dialog
        open={openSkills}
        onClose={handleCloseSkills}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          className="experience-edit-dialog-content"
          id="skill-dialog-width"
        >
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-title-grid"
            >
              <div>
                <div className="experience-edit-title">
                  <p>Skills</p>
                </div>
                <div className="experience-edit-close">
                  <Tooltip title="Close">
                    <CloseIcon onClick={handleCloseSkills} />
                  </Tooltip>
                </div>
              </div>
              <Divider className="experience-edit-top-divider" />
            </Grid>

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="experience-edit-right-grid"
            >
              <div className="experience-edit-form-container">
                <form
                  className="experience-edit-form"
                  onSubmit={addSkill}
                  noValidate
                >
                  <div className="input-container users-form">
                    <div className="input-field users-skill">
                      <label>Add Skill*</label>
                      <div className="skills-input-field users-skills-experience">
                        <Autocomplete
                          ref={inputRef}
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                          ListboxProps={{ style: { maxHeight: 220 } }}
                          key={clear}
                          freeSolo
                          id="free-solo-demo"
                          value={value}
                          options={skill ? data.map((item) => item.skill) : []}
                          disableClearable={true}
                          onChange={(event, newValue) => {
                            setSkill(newValue);
                          }}
                          renderInput={(params) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <TextField
                                value={skill}
                                onChange={(e) => {
                                  setSkill(e.target.value);
                                  setErrors({
                                    skill: "",
                                  });
                                }}
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                margin="normal"
                              />
                              <div style={{ height: "20px" }}>
                                <span className="text-danger">
                                  {errors.skill}
                                </span>
                              </div>
                            </div>
                          )}
                        />

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <input
                            className="position input-font"
                            type="number"
                            style={{
                              height: "38px",
                              position: "relative",
                              top: "1px",
                              fontSize: "12px",
                            }}
                            placeholder="Years of Experience"
                            value={experience}
                            onChange={experienceHandler}
                          />
                        </div>
                        <div className="button-container">
                          <button
                            className="btn-style outlined-btn"
                            disabled={false}
                            onClick={(e) => addSkill(e, skill)}
                          >
                            {" "}
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="input-field">
                      <div className="skill-button-container">
                        <div className="skills-buttons">
                          {skillsList.map((item, index) => {
                            return (
                              <Tooltip
                                title={
                                  item.yearOfExperience
                                    ? `${item.skillName} - ${item.yearOfExperience} year(s)`
                                    : `${item.skillName}`
                                }
                              >
                                <button
                                  className="outlined"
                                  key={index}
                                  onClick={(e) => deleteSkill(e, index)}
                                >
                                  {item.skillName}
                                  <CloseIcon
                                    fontSize="small"
                                    onClick={(e) => deleteSkill(e, index)}
                                  />
                                </button>
                              </Tooltip>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <span className="text-danger">{errors.skillArray}</span>
                  </div>
                  <div className="button-container">
                    <button
                      type="submit"
                      disabled={disableButton}
                      className="btn-style filled-btn filled-btn-animation"
                      onClick={onSubmit}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* Close dialog confimation dialog */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openCloseDialog}
        onClose={handleCloseSkills}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              xs={11.5}
              sm={11.5}
              md={11.5}
              lg={11.5}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure ?</h5>
                <p>Are you sure you want to go back?.</p>
                <p>All your current changes will be lost!</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="button-container delete-btn-container">
          <button
            className="warning-btn-style filled-btn filled-btn-animation"
            onClick={onCloseCancel}
          >
            {" "}
            Cancel
          </button>
          <button
            className="warning-btn-style outlined-btn outlined-btn-animation"
            onClick={onCloseDialog}
          >
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      {/* Snackbars */}
      <SaveProfileSnackBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <ErrorMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
      />
    </div>
  );
};

export default SkillEditModal;
