// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea-counter {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  textarea {
    resize: none; /* Disable resizing */
    margin-bottom: 8px;
    border: none;
    outline: none;
    padding: 10px;
    margin: 5px 0;
  
    background-color: #eeeeee;
  }
  
  .counter {
    font-size: 0.875rem;
    text-align: end;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/textarea/TextareaCounter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;EACb;;EAEA;IACE,YAAY,EAAE,qBAAqB;IACnC,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,aAAa;;IAEb,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;IACnB,eAAe;EACjB","sourcesContent":[".textarea-counter {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n  }\n  \n  textarea {\n    resize: none; /* Disable resizing */\n    margin-bottom: 8px;\n    border: none;\n    outline: none;\n    padding: 10px;\n    margin: 5px 0;\n  \n    background-color: #eeeeee;\n  }\n  \n  .counter {\n    font-size: 0.875rem;\n    text-align: end;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
