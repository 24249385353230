// import reactLogo from './assets/react.svg'
//import react libraries
import React, { useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import { GoogleOAuthProvider } from "@react-oauth/google";

//import components
import Landing from "./layout/Landing";
import LandingPage from "./landingpage/LandingPage";
import RegisterComponent from "./auth/RegisterComponent";
import CompanyRegistration from "./auth/CompanyRegistration";
import CandidateLinkedinSignupProfile from "./userProfiles/CandidateLinkedinSignupProfile";
import CandidateLinkedinLoginProfile from "./userProfiles/CandidateLinkedinLoginProfile";
import ScreenerLinkedinSignupProfile from "./userProfiles/ScreenerLinkedinSignupProfile";
import ScreenerLinkedinLoginProfile from "./userProfiles/ScreenerLinkedInLoginProfile";
import ForgotPassword from "./auth/ForgotPassword";

import ProtectedRoute from "./ProtectedRoute";

import CandidateProfile from "./userProfiles/CandidateProfile";
import CandidateEmailVerify from "./auth/CandidateEmailVerify";
import ScreenerProfile from "./userProfiles/ScreenerProfile";
import CompanyProfile from "./userProfiles/CompanyProfile";
import RecruiterProfile from "./userProfiles/RecruiterProfile";
import PdfViewer from "./userProfiles/PdfViewer";
import InternalPdfViewer from "./company admin/Internal Users/InternalPdfViewer";
import CandidateDashboard from "./Dashboards/CandidateDashboard";
import ScreenerDashboard from "./Dashboards/ScreenerDashboard";
import AdminDashboard from "./company admin/Dashboard/AdminDashboard";
import UsersList from "./company admin/Internal Users/UsersList";
import FavoritesList from "./company admin/Favorites/FavoritesList";
import Interview from "./company admin/Interviews/Interview";
import Search from "./company admin/Search/Search";

import CandidateInterview from "./Interviews/CandidateInterview";
import ScreenerInterview from "./Interviews/ScreenerInterview";
import RecruiterList from "./company admin/RecruiterList";
import UserList from "./company admin/UserList";
import ClientsList from "./company admin/Clients/ClientsList";
import PositionsList from "./company admin/Positions/PositionsList";
import Invitation from "./company admin/Invitation/Invitation";
import UnderDevelopment from "./ErrorHandling/UnderDevelopment";
import Subscribers from "./company admin/Subscribers/Subscribers";
import MainSettings from "./company admin/settings/MainSettings";
import InternalUsersProfile from "./userProfiles/InternalUsersProfile";

import RecruiterDashboard from "./sidebar components/Dashboard/RecruiterDashboard";
import RecruiterUsersList from "./sidebar components/Internal Users/UsersList";
import RecruiterFavoritesList from "./sidebar components/Favorites/FavoritesList";
import InterviewPage from "./sidebar components/Interviews/Interview";
import RecruiterSearch from "./sidebar components/Search/Search";
import MyRecruiterlist from "./sidebar components/MyRecruiters/RecruiterList";
import MyUserlist from "./sidebar components/MyUsers/UserList";
import RecruiterClientsList from "./sidebar components/Clients/ClientsList";
import RecruiterPositionsList from "./sidebar components/Positions/PositionsList";
import RecruiterInvitation from "./sidebar components/Invitation/Invitation";
import RecruiterMainSettings from "./sidebar components/settings/MainSettings";
import ScreenerEmailVerify from "./auth/ScreenerEmailVerify";
import CompanyEmailVerify from "./auth/CompanyEmailVerify";
import CandidateResetPassword from "./auth/CandidateResetPassword";
import ScreenerResetPassword from "./auth/ScreenerResetPassword";
import AdminResetPassword from "./auth/AdminResetPassword";
import RecruiterResetPassword from "./auth/RecruiterResetPassword";
import RecruiterAddPassword from "./auth/RecruiterAddPassword";
import UserAddPassword from "./auth/UsersAddPassword";
import ScreenerAddPassword from "./auth/ScreenerAddPassword";
import CandidateAddPassword from "./auth/CandidateAddPassword";
import PublicViewProfile from "./userProfiles/PublicViewProfile";

//skillAssessment
import Aptitude from "./skill-assessment/Aptitude";
import SkillLibrary from "./skill-assessment/components/Skill-Library/SkillLibrary";
import CandidateDetails from "./skill-assessment/components/TestManagement/CandidateDetails";
import TestManagement from "./skill-assessment/components/TestManagement/TestManagement";
import SkillBars from "./skill-assessment/components/SkillBars";
import SkillDashboard from "./skill-assessment/components/Skill-Dashboard/SkillDashboard";
import CandidateResults from "./skill-assessment/components/CandidateResults/CandidateResults";
import Problems from "./skill-assessment/components/TestManagement/Problems";
import ViewCandidateDetails from "./skill-assessment/components/CandidateResults/ViewCandidateDetails";

import CandidateAssessment from "./candidate-assessment/CandidateAssessment";
import CandidateQuestionPage from "./candidate-assessment/CandidateQuestionPage";
import AssessmentCompletedPage from "./candidate-assessment/AssessmentCompletedPage";
import TestAttempted from "./candidate-assessment/Test-Attempted/TestAttempted";
import LinkExpired from "./ErrorHandling/LinkExpired";


// import RecruiterSubscribers from "./sidebar components/Subscribers/Subscribers";

function App() {
  const navigate = useNavigate();
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  const logoutTimeoutRef = useRef();
  const user = sessionStorage.getItem("jwtToken");
  // const user = null;
  console.log("user=", user);
  console.log("app component");

  // const [user, setUser] = useState(sessionStorage.getItem("jwtToken"));

  // useEffect(() => {
  //   // Watch for session storage changes
  //   setUser(sessionStorage.getItem("jwtToken"));
  // }, []);

  const clearTimeoutAndRemoveListeners = () => {
    clearTimeout(logoutTimeoutRef.current);
    events.forEach((event) => window.removeEventListener(event, resetTimeout));
  };

  const setTimeoutAndAddListeners = () => {
    logoutTimeoutRef.current = setTimeout(logout, 60 * 20000);
    // logoutTimeoutRef.current = setTimeout(logout, 180000); // 3 minutes
    events.forEach((event) => window.addEventListener(event, resetTimeout));
  };

  const resetTimeout = () => {
    clearTimeoutAndRemoveListeners();
    setTimeoutAndAddListeners();
  };

  const logout = () => {
    // Send a logout request to the API
    sessionStorage.removeItem("jwtToken");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("companyId");
    localStorage.clear();

    // Logout after the session is done
    // window.location.reload(false);
    navigate("/");
  };

  useEffect(() => {
    setTimeoutAndAddListeners();

    return () => {
      clearTimeoutAndRemoveListeners();
    };
  }, []); // Empty dependency array ensures useEffect runs only once on mount

  return (
    <>
      <div className="main-container">
        <GoogleOAuthProvider clientId="458868131518-qshhdo2sc2cfkf704q76fhkctb4pgg53.apps.googleusercontent.com">
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/login" element={<Landing />} />
            <Route
              exact
              path="/register/:fName?/:Email?/:lName?/:roleType?"
              element={<RegisterComponent />}
            />
            //company routes
            <Route
              exact
              path="/company-registration"
              element={<CompanyRegistration />}
            />
            <Route
              exact
              path="/:id/company-verify/:token"
              element={<CompanyEmailVerify />}
            />
            //admin routes
            <Route
              exact
              path="/admin/profile"
              element={
                <ProtectedRoute user={user}>
                  <CompanyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/dashboard"
              element={
                <ProtectedRoute user={user}>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/internal-users"
              element={
                <ProtectedRoute user={user}>
                  <UsersList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/favorites"
              element={
                <ProtectedRoute user={user}>
                  <FavoritesList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/interviews"
              element={
                <ProtectedRoute user={user}>
                  <Interview />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/advanced-search"
              element={
                <ProtectedRoute user={user}>
                  <Search />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/recruiters"
              element={
                <ProtectedRoute user={user}>
                  <RecruiterList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/users"
              element={
                <ProtectedRoute user={user}>
                  <UserList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/clients"
              element={
                <ProtectedRoute user={user}>
                  <ClientsList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/positions"
              element={
                <ProtectedRoute user={user}>
                  <PositionsList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/invite"
              element={
                <ProtectedRoute user={user}>
                  <Invitation />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/stats"
              element={
                <ProtectedRoute user={user}>
                  <UnderDevelopment />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/report"
              element={
                <ProtectedRoute user={user}>
                  <UnderDevelopment />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/payment"
              element={
                <ProtectedRoute user={user}>
                  <UnderDevelopment />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/subscribers"
              element={
                <ProtectedRoute user={user}>
                  <Subscribers />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/admin/settings"
              element={
                <ProtectedRoute user={user}>
                  <MainSettings />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/:id/company/reset-password/:token"
              element={<AdminResetPassword />}
            />
            //candidate routes
            <Route
              exact
              path="/candidate/linkedin-signup/:code?/:state?"
              element={<CandidateLinkedinSignupProfile />}
            />
            <Route
              exact
              path="/candidate/linkedin-login/:code?/:state?"
              element={<CandidateLinkedinLoginProfile />}
            />
            <Route
              exact
              path="/candidate/profile"
              element={
                <ProtectedRoute user={user}>
                  <CandidateProfile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/candidate/dashboard"
              element={
                <ProtectedRoute user={user}>
                  <CandidateDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/candidate/interviews/:interviewId"
              element={
                <ProtectedRoute user={user}>
                  <CandidateInterview />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/:id/candidate-verify/:token"
              element={<CandidateEmailVerify />}
            />
            <Route
              exact
              path="/:id/candidate/reset-password/:token"
              element={<CandidateResetPassword />}
            />
            <Route
              exact
              path="/:id/candidate/add-password/:token"
              element={<CandidateAddPassword />}
            />
            //candidate assessment
            <Route
              path="/assessment/:testId/:invitationToken"
              element={<CandidateAssessment />}
            />
            <Route
              path="/assesment-question/:testId/:invitationToken"
              element={<CandidateQuestionPage />}
            />
            <Route path="/completed" element={<AssessmentCompletedPage />} />
            
            <Route path="/already-taken" element={<TestAttempted />} />

            //screener routes
            <Route
              exact
              path="/screener/linkedin-signup/:code?/:state?"
              element={<ScreenerLinkedinSignupProfile />}
            />
            <Route
              exact
              path="/screener/linkedin-login/:code?/:state?"
              element={<ScreenerLinkedinLoginProfile />}
            />
            <Route
              exact
              path="/:id/screener-verify/:token"
              element={<ScreenerEmailVerify />}
            />
            <Route
              exact
              path="/screener/profile"
              element={
                <ProtectedRoute user={user}>
                  <ScreenerProfile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/screener/dashboard"
              element={
                <ProtectedRoute user={user}>
                  <ScreenerDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/screener/interviews/:interviewId"
              element={
                <ProtectedRoute user={user}>
                  <ScreenerInterview />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/:id/screener/reset-password/:token"
              element={<ScreenerResetPassword />}
            />
            <Route
              exact
              path="/:id/screener/add-password/:token"
              element={<ScreenerAddPassword />}
            />




            <Route
              path="/admin/skill-assessment"
              element={
                <ProtectedRoute user={user}>
                  <SkillBars />
                </ProtectedRoute>
              }
            >
              {/* Nested Routes */}
              <Route index element={<SkillDashboard />} />
              <Route path="library" element={<SkillLibrary />} />
              <Route path="test-management" element={<TestManagement />} />
              <Route path="candidate-results" element={<CandidateResults />} />
              <Route
                path="candidate-results/viewcandidatedetails/:candidateId"
                element={<ViewCandidateDetails />}
              />
            </Route>



            //recruiter routes
            <Route
              exact
              path="/recruiter/profile"
              element={
                <ProtectedRoute user={user}>
                  <RecruiterProfile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/dashboard"
              element={
                <ProtectedRoute user={user}>
                  <RecruiterDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/internal-users"
              element={
                <ProtectedRoute user={user}>
                  <RecruiterUsersList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/favorites"
              element={
                <ProtectedRoute user={user}>
                  <RecruiterFavoritesList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/interviews"
              element={
                <ProtectedRoute user={user}>
                  <InterviewPage />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/advanced-search"
              element={
                <ProtectedRoute user={user}>
                  <RecruiterSearch />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/recruiters"
              element={
                <ProtectedRoute user={user}>
                  <MyRecruiterlist />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/users"
              element={
                <ProtectedRoute user={user}>
                  <MyUserlist />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/clients"
              element={
                <ProtectedRoute user={user}>
                  <RecruiterClientsList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/positions"
              element={
                <ProtectedRoute user={user}>
                  <RecruiterPositionsList />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/invite"
              element={
                <ProtectedRoute user={user}>
                  <RecruiterInvitation />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/stats"
              element={
                <ProtectedRoute user={user}>
                  <UnderDevelopment />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/report"
              element={
                <ProtectedRoute user={user}>
                  <UnderDevelopment />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/recruiter/payment"
              element={
                <ProtectedRoute user={user}>
                  <UnderDevelopment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/recruiter/settings"
              element={
                <ProtectedRoute user={user}>
                  <RecruiterMainSettings />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/:id/recruiter/reset-password/:token"
              element={<RecruiterResetPassword />}
            />
            <Route
              exact
              path="/:id/recruiter/add-password/:token"
              element={<RecruiterAddPassword />}
            />
            //other routes
            <Route
              exact
              path="/:role/profile/:firstName/resume/:id"
              element={
                // <ProtectedRoute user={user}>
                <PdfViewer />
                // </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/resume-viewer/:role/:id"
              element={
                // <ProtectedRoute user={user}>
                <InternalPdfViewer />
                // </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/:role/internal-db/profile/:userRole/:userId/:companyId"
              element={
                // <ProtectedRoute user={user}>
                <InternalUsersProfile />
                // </ProtectedRoute>
              }
            />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route
              exact
              path="/:id/myUsers/add-password/:token"
              element={<UserAddPassword />}
            />
            <Route
              exact
              path="/profile/share/:userRole/:userId/:companyId"
              element={<PublicViewProfile />}
            />
          </Routes>
        </GoogleOAuthProvider>
      </div>
    </>
  );
}

export default App;
