import React from "react";

import ContentLoader from "react-content-loader";
const Loader = (props) => {
  const random = Math.random() * (1 - 0.7) + 0.7;
  console.log(props.page, "loader");
  return (
    <ContentLoader
      height={40}
      width={1090}
      speed={2}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
      {...props}
    >
      {props.page === "recruiterList" && (
        <>
          <rect x="40" y="7.5" rx="100" ry="100" width="30" height="30" />

          <rect x="80" y="15" rx="6" ry="6" width={180} height="12" />
          <rect x="320" y="13" rx="6" ry="6" width={180} height="12" />
          <rect x="710" y="13" rx="6" ry="6" width={90} height="12" />
          <rect x="930" y="13" rx="6" ry="6" width="30" height="15" />
          <rect x="980" y="13" rx="6" ry="6" width="30" height="15" />
          <rect x="1030" y="13" rx="6" ry="6" width="30" height="15" />
        </>
      )}

      {props.page === "userList" && (
        <>
          <rect x="40" y="7.5" rx="100" ry="100" width="30" height="30" />
          <rect x="80" y="15" rx="6" ry="6" width={180} height="12" />
          <rect x="320" y="13" rx="6" ry="6" width={230} height="12" />
          <rect x="630" y="13" rx="6" ry="6" width={90} height="12" />
          <rect x="830" y="13" rx="6" ry="6" width={90} height="12" />
          {/* <rect x="975" y="13" rx="6" ry="6" width="30" height="15" /> */}
          <rect x="1015" y="13" rx="6" ry="6" width="30" height="15" />
          <rect x="1055" y="13" rx="6" ry="6" width="30" height="15" />
        </>
      )}

      {props.page === "userRoleList" && (
        <>
          <rect x="50" y="15" rx="6" ry="6" width={180} height="12" />
          <rect x="340" y="13" rx="6" ry="6" width={210} height="12" />
          <rect x="700" y="13" rx="6" ry="6" width={160} height="12" />
          <rect x="1010" y="13" rx="6" ry="6" width="60" height="15" />
          {/* <rect x="900" y="13" rx="6" ry="6" width="30" height="15" /> */}
        </>
      )}
      {props.page === "internal-userList" && (
        <>
          <rect x="15" y="7.5" rx="100" ry="100" width="25" height="25" />
          <rect x="57" y="15" rx="6" ry="6" width={180} height="12" />
          <rect x="315" y="13" rx="6" ry="6" width={150} height="12" />
          <rect x="518" y="13" rx="6" ry="6" width={110} height="12" />
          {/* <rect x="600" y="13" rx="6" ry="6" width={90} height="12" /> */}
          <rect x="740" y="13" rx="6" ry="6" width={120} height="12" />

          <rect x="940" y="13" rx="6" ry="6" width={180} height="12" />


        </>
      )}
      {props.page === "interview-list" && (
        <>
          <rect x="55" y="15" rx="6" ry="6" width={65} height="12" />
          <rect x="200" y="15" rx="6" ry="6" width={130} height="12" />
          <rect x="430" y="15" rx="6" ry="6" width={140} height="12" />
          <rect x="650" y="15" rx="6" ry="6" width={140} height="12" />
          <rect x="890" y="15" rx="6" ry="6" width={120} height="12" />

        </>
      )}
      {props.page === "subscriberList" && (
        <>
          <rect x="55" y="15" rx="6" ry="6" width={100} height="12" />
          <rect x="230" y="15" rx="6" ry="6" width={200} height="12" />
          <rect x="550" y="15" rx="6" ry="6" width={200} height="12" />
          <rect x="840" y="15" rx="6" ry="6" width={120} height="12" />
          <rect x="1000" y="15" rx="6" ry="6" width={90} height="12" />

        </>
      )}

    </ContentLoader>
  );
};

const TableLoader = ({ page }) => (
  <React.Fragment>
    {Array(5)
      .fill("")
      .map((e, i) => (
        <div>
          <Loader
            page={page}
            key={i}
            style={{
              opacity: Number(2 / i).toFixed(1),
              marginTop: "10px",
            }}
          />
          {/* <Divider sx={{ borderColor: "lightgray" }} id="divider" /> */}
        </div>
      ))}
  </React.Fragment>
);

export default TableLoader;
