import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";

const About = () => {
  return (
    <section className="section" id="about">
      <div className="container">
        <div className="row align-items-center mr-auto">
          <div className="col-md-4">
            <h6 className="xs-font mb-0">Know more.</h6>
            <h3 className="section-title">About</h3>
            <p>
              Screener is an application that focuses on the overall recruitment
              management cycle such as sourcing candidates, finding professional
              interviewers, scheduling interviews, and tracking the same.
            </p>
            <Link smooth={true} to="contact">
              <button className="btn btn-primary btn-rounded">
                Contact Us
              </button>
            </Link>
          </div>
          <div className="col-sm-6 col-md-4 ml-auto">
            <div className="widget">
              <div className="icon-wrapper">
                <i className="fa-solid fa-building"></i>
              </div>
              <div className="infos-wrapper">
                <h4 className="text-primary">250+</h4>
                <p>Recruitment Companies</p>
              </div>
            </div>
            <div className="widget">
              <div className="icon-wrapper">
                <i className="fa-solid fa-users"></i>
              </div>
              <div className="infos-wrapper">
                <h4 className="text-primary">2000+</h4>
                <p>Expert Screeners</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-4">
            <div className="widget">
              <div className="icon-wrapper">
                <i className="fa-regular fa-star"></i>
              </div>
              <div className="infos-wrapper">
                <h4 className="text-primary">30000+</h4>
                <p>Job Seekers</p>
              </div>
            </div>

            <div className="widget">
              <div className="icon-wrapper">
                <i className="fa-regular fa-face-smile"></i>
              </div>
              <div className="infos-wrapper">
                <h4 className="text-primary">98%</h4>
                <p>Successful Placements</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
