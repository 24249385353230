import React from "react";
import "./TestAttempted.css"; // Importing styles
import img from "./test-attempted.jpg"

const ErrorPage = () => {
  return (
    <div className="error-page">
      <img src={img} alt="Error" className="error-image" />
      <a href="/" className="return-home">Return to Homepage</a>
    </div>
  );
};

export default ErrorPage;
