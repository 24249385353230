// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AdvancedSettings.css */
.advancedsettings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 57vw;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    /* border-radius: 8px; */
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  
  /* .MuiFormControlLabel-root {
    color: #333;
  }
  
  .MuiTextField-root {
    margin-bottom: 16px;
  }
  
  .MuiCheckbox-root, .MuiRadio-root {
    color: #F06161;
  }
  
  .MuiButton-containedPrimary {
    background-color: #F06161;
    color: white;
  }
  
  .MuiButton-containedPrimary:hover {
    background-color: #d9534f;
  }
  
  .MuiFormGroup-root {
    margin-bottom: 16px;
  }
  
  .MuiSelect-root {
    margin-bottom: 16px;
  }
  
  .MuiSelect-select {
    color: #333;
  }
  
  .MuiInputLabel-root {
    color: #333;
  }
  
  .MuiInputLabel-root.Mui-focused {
    color: #F06161;
  }
  
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #F06161;
  }
  
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #F06161;
  }
   */

   .input-field-error {
    border: 1px solid var(--crimson) !important;
    box-shadow: 1px 1px 2px var(--dark-red);
  }`, "",{"version":3,"sources":["webpack://./src/components/skill-assessment/components/TestManagement/AdvancedSettings.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,aAAa;IACb,qCAAqC;IACrC,wBAAwB;IACxB,sBAAsB;IACtB,sBAAsB;EACxB;;;EAGA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;IAgDE;;GAED;IACC,2CAA2C;IAC3C,uCAAuC;EACzC","sourcesContent":["/* AdvancedSettings.css */\n.advancedsettings-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    max-width: 57vw;\n    margin: 0 auto;\n    padding: 20px;\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n    /* border-radius: 8px; */\n    background-color: #fff;\n    border: 1px solid #ddd;\n  }\n  \n  \n  /* .MuiFormControlLabel-root {\n    color: #333;\n  }\n  \n  .MuiTextField-root {\n    margin-bottom: 16px;\n  }\n  \n  .MuiCheckbox-root, .MuiRadio-root {\n    color: #F06161;\n  }\n  \n  .MuiButton-containedPrimary {\n    background-color: #F06161;\n    color: white;\n  }\n  \n  .MuiButton-containedPrimary:hover {\n    background-color: #d9534f;\n  }\n  \n  .MuiFormGroup-root {\n    margin-bottom: 16px;\n  }\n  \n  .MuiSelect-root {\n    margin-bottom: 16px;\n  }\n  \n  .MuiSelect-select {\n    color: #333;\n  }\n  \n  .MuiInputLabel-root {\n    color: #333;\n  }\n  \n  .MuiInputLabel-root.Mui-focused {\n    color: #F06161;\n  }\n  \n  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {\n    border-color: #F06161;\n  }\n  \n  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {\n    border-color: #F06161;\n  }\n   */\n\n   .input-field-error {\n    border: 1px solid var(--crimson) !important;\n    box-shadow: 1px 1px 2px var(--dark-red);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
