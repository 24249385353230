import React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon

const FrozenMessageSnackbar = ({
  openErrorSnackbar,
  setOpenErrorSnackbar,
  message,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorSnackbar(false);
  };

  return (
    <Snackbar
      open={openErrorSnackbar}
      autoHideDuration={2000}
      onClose={handleClose}
      message={message}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Button
            style={{ textTransform: "capitalize", color: "#f06161" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </React.Fragment>
      }
    />
  );
};

export default FrozenMessageSnackbar;
