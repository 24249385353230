import { RECEIVE_REVIEWS, RECEIVE_REVIEW_ERRORS } from "../actions/reviewActions";
// import { merge } from 'lodash';

export const reviewReducer=(state = ["There are no reviews"], action) => {
    Object.freeze(state);
    switch (action.type) {
        case RECEIVE_REVIEWS:
            return action.reviews.data;
        case RECEIVE_REVIEW_ERRORS:
            return state;
        default:
            return state;
    }
};
