import * as React from "react";
//External imports
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Logo from "../../images/brand.svg";
import BackArrow from "../../images/left-arrow.png";
import arrow from "../../images/backArrow.png";
import LockIcon from "../../images/lock.png";
import ViewIcon from "../../images/view.png";
import HideIcon from "../../images/hide.png";
import { useParams,useNavigate} from "react-router-dom";
import axios from "axios";
import PageNotFound from "../ErrorHandling/PageNotFound";
import ResetPasswordModal from "../modals/ResetPasswordModal";
import PasswordChecklist from "react-password-checklist";
import UserSnackbar from "../snackBars/UserSnackbar";

//Css imports
import "./LoginComponent.css";
import "intl-tel-input/build/css/intlTelInput.css";
import LinkExpired from "../ErrorHandling/LinkExpired";

const theme = createTheme();

const CandidateAddPassword = () => {
    const navigate = useNavigate();
    //State values
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [tempMail, setTempMail] = useState("");
    const [passwordImage, setPasswordImage] = useState(ViewIcon);
    const [toggler, setToggler] = useState("password");
    const [firstToggle, setFirstToggle] = useState(true);

    const [confirmPasswordImage, setConfirmPasswordImage] = useState(ViewIcon);
    const [confirmToggler, setCofirmToggler] = useState("password");
    const [firstConfirmToggle, setFirstConfirmToggle] = useState(true);

    const [validity, setValidity] = useState();
    const [linkValidity, setLinkValidity] = useState()
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState("Password Created!");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // state used to handle display of password checklist
    const [isPasswordFocussed, setIsPasswordFocussed] = useState(false);
    const param = useParams();
    let newData = {};
    const [errors, setErrors] = useState({
        password: "",
        confirmPassword: "",
    });

    //Password Toggle handler
    const passwordHandler = () => {
        if (firstToggle) {
            setToggler("text");
            setPasswordImage(HideIcon);
            setFirstToggle(false);
        } else {
            setToggler("password");
            setPasswordImage(ViewIcon);
            setFirstToggle(true);
        }
    };

    const ConfirmPasswordHandler = () => {
        if (firstConfirmToggle) {
            setCofirmToggler("text");
            setConfirmPasswordImage(HideIcon);
            setFirstConfirmToggle(false);
        } else {
            setCofirmToggler("password");
            setConfirmPasswordImage(ViewIcon);
            setFirstConfirmToggle(true);
        }
    };

    //Reset password submit handler
    const onSubmit = (e) => {
        e.preventDefault();
        //Error handling
        if (password === "") {
            return setErrors({ password: "Password is required" });
        } else if (
            !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
                password
            )
        ) {
            // return setErrors({
            //   password:
            //     "Your password must be at least 8 characters including a lowercase letter, an uppercase letter, a number, and a special character.",
            // });
            return setIsPasswordFocussed(true)
        }

        if (confirmPassword === "") {
            return setErrors({ confirmPassword: "Confirm Password is required" });
        } else if (confirmPassword !== password) {
            return setErrors({ confirmPassword: "Passwords do not match" });
        }

        newData = {
            password,
            confirmPassword,
        };

        //Backend route
        axios
            .post(`/api/candidate/${param.id}/add-password/${param.token}`, {
                newData: newData,
            })
            .then((response) => {
                setConfirmPassword("");
                setPassword("");
                setPasswordImage(ViewIcon);
                setConfirmPasswordImage(ViewIcon);
                setToggler("password");
                setCofirmToggler("password");
                setErrors({
                    password: "",
                    confirmPassword: "",
                });
                setShowModal(true);
                setTimeout(() => {
                    navigate("/login");
                }, 2500);
            })
            .catch((err) => {
                if (err.response.status == 409) {
                    setMessage("Email Already Exists")
                    setOpenSnackbar(true)
                    setTimeout(() => {
                        navigate("/login");
                    }, 2500);
                }
            });
    };

    const testFunction = async () => {
        const response = await fetch(
            `/api/admin/${param.id}/candidate/add-password/${param.token}`
        );
        if (response.status === 404) {
            setValidity(false);
            setLinkValidity(false)
        } else if (response.status == 200) {
            setValidity(true);
            setLinkValidity(true);
        } else if (response.status == 202 || response.status == 500) {
            setValidity(false)
            setLinkValidity(true)
        }
    };

    useEffect(() => {
        testFunction();
    }, []);

    return (
        <>
            {validity && linkValidity ? (
                <div className="login-bg">
                    <div className="card-container" style={{ height: "70vh" }}>
                        <ThemeProvider theme={theme}>
                            <Grid container component="main" className="main-grid">
                                <CssBaseline />

                                <Grid
                                    className="overlay-image"
                                    item
                                    xs={false}
                                    sm={false}
                                    md={6}
                                >
                                    <Grid
                                        item
                                        //   md={6}
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        className="header-grid"
                                    >
                                        <div class="left-logo ">
                                            <div class="logo-img">
                                                <img class="login-img" src={Logo} />

                                                <div class="main-heading right-title">
                                                    <h1 style={{ fontSize: "60px" }}>Screener</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <div class="back-arrow">
                                        <RouterLink to="/">
                                            <img src={BackArrow} />
                                        </RouterLink>
                                    </div>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    component={Paper}
                                    elevation={6}
                                    square
                                    className="login-form"
                                >
                                    <div class="right-back-arrow">
                                        <RouterLink to="/">
                                            {/* <a class="right-arrow">&larr;</a> */}
                                            <img src={arrow} />
                                        </RouterLink>
                                    </div>
                                    <Box
                                        sx={{
                                            my: 3,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minHeight: "80vh",
                                            position: "relative",
                                        }}
                                        className="main-box"
                                    >
                                        <div class="app-heading">
                                            <div class="container-logo">
                                                <img class="screener-logo" src={Logo} />

                                                <div class="main-heading main-title">
                                                    <h1>Screener</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="login-form-container">
                                            <form
                                                className="form-conatiner"
                                                onSubmit={onSubmit}
                                                noValidate
                                            >
                                                <div class="login-form-header">
                                                    <h1>Create Password</h1>
                                                </div>

                                                <div class="login-input-conatiner">
                                                    <div class="input-field">
                                                        <img class="field-icon" src={LockIcon} />
                                                        <input
                                                            autoComplete="new-password"
                                                            onChange={(e) => {
                                                                setPassword(e.target.value);
                                                                setErrors({
                                                                    password: "",
                                                                });
                                                                setIsPasswordFocussed(true)
                                                            }}
                                                            //onClick={resetFieldPassword}
                                                            value={password}
                                                            class="password"
                                                            //error={isErrors.password.toString()}
                                                            type={toggler}
                                                            id="password"
                                                            name="password"
                                                            placeholder="Password*"
                                                        // className={classnames("password", {
                                                        //   invalid: isErrors.password,
                                                        // })}
                                                        />

                                                        <img
                                                            class="view-icon"
                                                            src={passwordImage}
                                                            onClick={passwordHandler}
                                                        />


                                                    </div>
                                                    <div>
                                                        <span className="text-danger">
                                                            {errors.password}
                                                        </span>
                                                    </div>
                                                    {password && isPasswordFocussed ? <PasswordChecklist
                                                        rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
                                                        minLength={8}
                                                        value={password}
                                                        iconSize={10}
                                                        className="password-checklist"
                                                        onChange={(isValid) => { }}
                                                        messages={{
                                                            minLength: "Minimum 8 characters",
                                                            specialChar: "Special characters",
                                                            number: "Numbers",
                                                            capital: "Uppercase letters",
                                                            lowercase: "Lowercase letters"
                                                        }}
                                                        invalidColor="#f06161"
                                                        validColor="#2e8b57"
                                                    /> : ""}
                                                    <div class="input-field">
                                                        <img class="field-icon" src={LockIcon} />
                                                        <input
                                                            autoComplete="new-password"
                                                            onChange={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                                setErrors({
                                                                    confirmPassword: "",
                                                                });
                                                                setIsPasswordFocussed(false);
                                                            }}
                                                            // onClick={resetFieldconfirmPassword}
                                                            value={confirmPassword}
                                                            // error={isErrors.confirmPassword.toString()}
                                                            class="password"
                                                            id="confirmPassword"
                                                            type={confirmToggler}
                                                            name="confirmPassword"
                                                            placeholder="Re-Enter Password*"
                                                        // className={classnames("password", {
                                                        //   invalid: isErrors.confirmPassword,
                                                        // })}
                                                        />
                                                        <img
                                                            class="view-icon"
                                                            src={confirmPasswordImage}
                                                            onClick={ConfirmPasswordHandler}
                                                        />

                                                    </div>
                                                    <div>
                                                        <span className="text-danger">
                                                            {errors.confirmPassword}
                                                        </span>
                                                    </div>
                                                    <div className="login-button-container">
                                                        <button
                                                            class="btn-login"
                                                            style={{ marginTop: "25px" }}
                                                            type="submit"
                                                        >
                                                            Create Password
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </Box>
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </div>
                    <ResetPasswordModal
                        show={showModal}
                        tempMail={tempMail}
                        message={message}
                        onHide={() => setShowModal(false)}
                    />

                    {/* Snackbar  */}
                    <UserSnackbar
                        openSnackbar={openSnackbar}
                        setOpenSnackbar={setOpenSnackbar}
                        message={message}
                    />
                </div>
            ) : !validity && linkValidity ? (
                <PageNotFound />
                // <LinkExpired />
            ) : !validity && !linkValidity ? (
                <LinkExpired />
            ) : ""}
        </>
    );
};

export default CandidateAddPassword;
