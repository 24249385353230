import * as React from 'react';

//import styles
import '../CandidateAssessment.css';

//import mui components
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

//import mui icons
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

const ProctoringInstructions = ({ setTabValue }) => {
  const handleClickNext = () => {
    setTabValue(1); // Change the tab to "System Checks"
  };

  return (
    <>
      <div className='proctor-screen'> 
        <div className='heading'>
          <h5>Proctoring Instructions</h5>
          <hr style={{ marginTop: '25px' }} />
        </div>
        <div className='instruction-info'>
          <span>
            <InfoIcon />
            <h6>Points to keep in mind during this assessment :-</h6>
          </span>
          <div className='instrution-list'>
            <ul>
              <li>Stay on Fullscreen until the end of assessment</li>
              <li>Do not move out of the Tab or switch window</li>
              <li>Disable system Notifications</li>
            </ul>
          </div>
          <div className='warning'>
            <WarningIcon/>
            <p>Interaction with external pop-ups can lead to auto-submission of the assessment</p>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', width: '300px', justifyContent: 'center' }}>
          <Button
            variant='contained'
            className='proctor-next-button'
            style={{ position: 'relative', left: '20px' }}
            onClick={handleClickNext}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProctoringInstructions;