import React from "react";

// external imports
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

// css imports
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import "../auth/LoginComponent.css"; // Keep your CSS

// Modal to show email verification message
const SocialLoginModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      sx={{ border: "none", borderRadius: "2rem" }}
    >
      <img
        width="30"
        height="30"
        src={
          (props.icon === "google" &&
            "https://cdn-icons-png.flaticon.com/512/2991/2991148.png") ||
          (props.icon === "linkedin" &&
            "https://cdn-icons-png.flaticon.com/512/145/145807.png")
        }
        alt="social-icon"
        style={{ margin: "20px auto -6px auto" }}
      />
      {/* Modal body */}
      <Modal.Body>
        <h6 style={{ textAlign: "center" }}>Sign In as*</h6>

        {/* Radio buttons */}
        <div className="login-radio-container">
          <FormControl
            className="login-radio-group"
            style={{ justifyContent: "center" }}
          >
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={props.role}
              onChange={(e) => {
                props.setRole(e.target.value);
              }}
            >
              <FormControlLabel
                value="candidate"
                control={
                  <Radio
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#f06161", // Change the color when checked
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                      },
                    }}
                  />
                }
                label="Job Seeker"
              />
              <FormControlLabel
                value="screener"
                control={
                  <Radio
                    size="small"
                    sx={{
                      "&.Mui-checked": {
                        color: "#f06161", // Change the color when checked
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: 18,
                      },
                    }}
                  />
                }
                label="Screener"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <Button
            onClick={() => props.socialMediaHandler(props.icon)}
            style={{
              fontSize: "smaller",
              borderRadius: "20px",
              textAlign: "center",
            }}
            className="btn-login"
          >
            Submit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SocialLoginModal;
