import React from 'react';
import axios from "axios";

// MUI imports
import { Dialog, DialogContent, Grid } from "@mui/material";

// icons
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";

const DeleteDialog = ({ setOpenScheduleInterview, setDialogOpen, dialogOpen, handleCloseScheduleInterview, setOpenSnackbar, setMessage, getAllInterviews, id, oldInterviewStatus, interviewId }) => {

    function handleDeleteInterview(e) {
        e.preventDefault();
        const data = { interviewId, oldInterviewStatus };

        axios
            .post(`/api/schedule/delete-interview/${id}`, data)
            .then((res) => {
                setMessage(res.data.message);
                setOpenSnackbar(true);
                setDialogOpen(false);
                handleCloseScheduleInterview();
                getAllInterviews();
            })
            .catch((err) => {
                setMessage(err.data.message);
                setOpenSnackbar(true);
                setDialogOpen(false);
                handleCloseScheduleInterview();
            });
    }

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={dialogOpen}
                onClose={setDialogOpen}
                className="experience-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="dialog-content">
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            item
                            xs={11.5}
                            sm={11.5}
                            md={12}
                            lg={12}
                            className="experience-delete-grid"
                        >
                            <div className="delete-message-icon">
                                <DeleteMessageIcon fontSize="small" />
                            </div>
                            <div className="delete-dialog-content">
                                <h5>Are you sure?</h5>
                                <p>Do you want to delete this interview?</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <div className="alert-buttons-container">
                    <button
                        className="warning-btn-style filled-btn filled-btn-animation"
                        onClick={() => {
                            setDialogOpen(false);
                            setOpenScheduleInterview(true);
                        }}
                    >
                        {" "}
                        Cancel
                    </button>
                    <button
                        className="warning-btn-style outlined-btn outlined-btn-animation"
                        onClick={handleDeleteInterview}
                    >
                        {" "}
                        Confirm
                    </button>
                </div>
            </Dialog>
        </div>
    )
}

export default DeleteDialog