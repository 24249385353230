import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import EducationIcon from "@mui/icons-material/SchoolOutlined";
import ExperienceIcon from "@mui/icons-material/WorkOutlineOutlined";
import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Phone from "@mui/icons-material/LocalPhoneOutlined";
import Dollar from "@mui/icons-material/AttachMoneyOutlined";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import axios from "axios";
import dateFormat from "dateformat";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import Spinner from "../loaders/Spinner";
import PageNotFound from "../ErrorHandling/PageNotFound";
import moment from "moment-timezone";
import CloseIcon from "@mui/icons-material/Close";

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import PublicIcon from '@mui/icons-material/Public';

// CSS import
import "./Profile.css";

const InternalUsersProfile = () => {

    //company id
    let id = localStorage.getItem("id");
    const role = localStorage.getItem("role");

    //fetching role and userid
    const { userRole, userId, companyId } = useParams();

    //Profile Page States
    let profileAvatar =
        "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";
    let degree = "",
        stream = "",
        school = "";
    const [selectedProfile, setSelectedProfile] = useState([]);
    const [activityPoints, setActivityPoints] = useState(0);

    //loader
    const [isLoading, setIsLoading] = useState(true);

    const [openResumePreview, setOpenResumePreview] = useState(false);

    const handleCloseResumePreview = () => {
        setOpenResumePreview(false);
    };

    function getActivityPoints() {
        axios
            .get(`/api/admin/get-activity-points/${userId}/${role}`)
            .then((res) => {
                console.log(res.data);
                setActivityPoints(res.data);
            })
            .catch((err) => {
                console.log(err);
                setActivityPoints(0);
            });
    }

    //fetching user data from backend
    useEffect(() => {
        axios
            .get(`/api/admin/internal-user/${userRole}/${userId}`)
            .then((res) => {
                console.log(res.data);
                if (res.data.length > 0) {
                    setSelectedProfile(res.data[0]);
                    setIsLoading(false);
                } else {
                    axios
                        .get(`/api/${userRole}/${userId}`)
                        .then((res) => {
                            console.log(res.data);
                            let data = { ...res.data.user };
    
                            if (userRole === "candidate") {
                                const { candidateEmail, candidatePhone, ...rest } = data;
                                data = {
                                    email: candidateEmail,
                                    primaryPhone: candidatePhone,
                                    ...rest,
                                };
                            } else {
                                const { screenerEmail, screenerPhone, ...rest } = data;
                                data = {
                                    email: screenerEmail,
                                    primaryPhone: screenerPhone,
                                    ...rest,
                                };
                            }
                            console.log(data);
                            setSelectedProfile(data);
                            setIsLoading(false);
                        })
                        .catch((error) => {
                            console.error("Error fetching user details:", error);
                            setIsLoading(false);
                        });
                }
            })
            .catch((error) => {
                console.error("Error fetching internal user data:", error);
                setIsLoading(false);
            });
    
        getActivityPoints();
    }, []);
    
    console.log(selectedProfile);
    //fetch id from localstorage on tab switching
    const fetchID = () => {
        id = localStorage.getItem("id");
        if (id === null) {
            window.location.reload();
        }
    };

    console.log(activityPoints);
    //deteching tab switching
    useEffect(() => {
        document.title = `Screener | ${selectedProfile?.firstName}'s Profile`;
        const handleVisibilityChange = () => {
            if (document.hidden) {
                // console.log('Tab switched');
            } else {
                fetchID();
            }
        };
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [selectedProfile]);

    //sorting Experience list
    const highestExperience = selectedProfile?.job_experience?.sort(
        (a, b) => b.startYear - a.startYear
    );

    //sorting education list
    const highestEducation = selectedProfile?.educational_details?.sort(
        (a, b) => b.startYear - a.startYear
    );

    //finding recently completed course
    const completedEducation = highestEducation?.filter((item, index) => {
        if (item.endMonth !== "Present") {
            return item;
        }
    });

    const checkMonth = (month) => {
        var convertedMonth;
        switch (month) {
            case "00":
                convertedMonth = "January";
                return convertedMonth;
                break;
            case "01":
                convertedMonth = "February";
                return convertedMonth;
                break;
            case "02":
                convertedMonth = "March";
                return convertedMonth;
                break;
            case "03":
                convertedMonth = "April";
                return convertedMonth;
                break;
            case "04":
                convertedMonth = "May";
                return convertedMonth;
                break;
            case "05":
                convertedMonth = "June";
                return convertedMonth;
                break;
            case "06":
                convertedMonth = "July";
                return convertedMonth;
                break;
            case "07":
                convertedMonth = "August";
                return convertedMonth;
                break;
            case "08":
                convertedMonth = "September";
                return convertedMonth;
                break;
            case "09":
                convertedMonth = "October";
                return convertedMonth;
                break;
            case "10":
                convertedMonth = "November";
                return convertedMonth;
                break;
            case "11":
                convertedMonth = "December";
                return convertedMonth;
                break;
        }
    };

    //resume link
    function debugBase64(resume) {
        setOpenResumePreview(true);
        // var win = window.open();
        // win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }

    //format date
    function formatDate(date) {
        let gmtDate = moment.tz(date, "YYYY-MM-DD hh:mm a", "Asia/Kolkata").utc();
        gmtDate = moment.tz(gmtDate, "MMMM Do YYYY[,] h:mm a", "Asia/Kolkata");
        gmtDate = gmtDate.format("MMMM Do YYYY");
        console.log(gmtDate);
        return gmtDate;
    }

    return (
        <div>
            {id !== null ? (
                isLoading ? (
                    <Spinner />
                ) : (
                    <div
                        className="root-div"
                        id="restrict-scroll"
                        style={{ overflowY: "hidden" }}
                    >
                        <div className="profile-name-display-container">
                            {selectedProfile?.firstName}'s Profile
                        </div>
                        <Box sx={{ flexGrow: 1 }}>
                            <Card
                                id="main-edit"
                                className="main-card profile-card recruiter-profile-card internal-user-profile"

                            >
                                {/* top container grid */}
                                <Grid
                                    container
                                    spacing={0}
                                    direction="row"
                                    className="recruiter profile-card-container"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2.3}
                                        lg={2.3}
                                        xl={1.5}
                                        className="container-profile"
                                        style={{ zIndex: "999" }}
                                    >
                                        <img
                                            src={
                                                selectedProfile?.defaultAvatar
                                                    ? profileAvatar
                                                    : selectedProfile?.interacted
                                                        ? selectedProfile.croppedImage
                                                        : selectedProfile.profileImage
                                            }
                                            style={{
                                                objectFit: "cover",
                                                height: "160px",
                                                width: "160px",
                                                margin: "0 auto",
                                                display: "flex",
                                            }}
                                        />
                                        <div
                                            class="social-profile-card-container"
                                            style={{ width: "100%" }}
                                        >
                                            <a href={selectedProfile?.socialMedia.linkedin} className="social" target="_blank">
                                                {selectedProfile?.socialMedia.linkedin && <LinkedInIcon fontSize="small" style={{ color: "#f06161" }} />}
                                            </a>

                                            <a href={selectedProfile?.socialMedia.github} class="social" target="_blank">
                                                {selectedProfile?.socialMedia.linkedin && <GitHubIcon fontSize="small" style={{ color: "#f06161" }} />}
                                            </a>
                                            <a href={selectedProfile?.socialMedia.portfolio} class="social" target="_blank">
                                                {selectedProfile?.socialMedia.portfolio && <PublicIcon fontSize="small" style={{ color: "#f06161" }} />}
                                            </a>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={5.5}
                                        lg={5.63}
                                        xl={7.1}
                                        className="container-heading-title"
                                    >
                                        <div>
                                            <h2 style={{ fontSize: "26px" }}>
                                                {selectedProfile?.firstName} {selectedProfile?.lastName}
                                            </h2>
                                            <h5
                                                className="internal-user-profile-title"
                                                style={{ fontSize: "1rem" }}
                                            >
                                                {selectedProfile.profileTitle}
                                            </h5>
                                        </div>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3.5}
                                        lg={3.7}
                                        xl={3}
                                        className="container-heading-buttons resume-button"
                                    >
                                        {/* <div style={{ paddingTop: "5px" }}>
                      {activityPoints <= 25 && (
                        <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                          Activity Score:{" "}
                          <strong className="activity-point">Very Low</strong>
                        </p>
                      )}
                      {activityPoints > 25 && activityPoints <= 50 && (
                        <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                          Activity Score:{" "}
                          <strong className="activity-point">Low</strong>
                        </p>
                      )}
                      {activityPoints > 50 && activityPoints <= 75 && (
                        <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                          Activity Score:{" "}
                          <strong className="activity-point">Medium</strong>
                        </p>
                      )}
                      {activityPoints > 75 && activityPoints <= 100 && (
                        <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                          Activity Score:{" "}
                          <strong className="activity-point">High</strong>
                        </p>
                      )}
                    </div> */}
                                        {selectedProfile.resume && (
                                            <div>
                                                <button
                                                    className="view-resume"
                                                    onClick={() => {
                                                        selectedProfile?.resume &&
                                                            debugBase64(selectedProfile.resume);
                                                    }}
                                                >
                                                    <EyeIcon className="eye-icon" />
                                                    Resume
                                                </button>
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* 2nd grid  */}
                                <Grid
                                    container
                                    spacing={0}
                                    direction="row"
                                    justifyContent={"space-between"}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2.3}
                                        lg={2.3}
                                        xl={1.5}
                                        className="container-profile"
                                    ></Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={9.3}
                                        lg={9.5}
                                        xl={10.3}
                                        direction="row"
                                        className="info-container2-internal"
                                    >
                                        <Grid md={4} lg={4} xl={4} className="info-container2">
                                            <div className="info-container-height">
                                                <div className="info-container1-heading">
                                                    <MailOutlineIcon className="icon" />
                                                    Email
                                                </div>
                                                <p style={{ fontSize: "14px" }}>
                                                    {selectedProfile?.email}
                                                </p>
                                            </div>
                                        </Grid>
                                        <Grid md={4} lg={4} xl={4} className="info-container2">
                                            <div className="info-container-height">
                                                <div className="info-container1-heading">
                                                    <Phone className="icon" />
                                                    Phone
                                                </div>
                                                <p style={{ fontSize: "14px" }}>
                                                    +{selectedProfile?.primaryPhone}
                                                </p>
                                            </div>
                                        </Grid>
                                        <Grid md={4} lg={4} xl={4} className="info-container2">
                                            <div className="info-container-height">
                                                <div className="info-container1-heading">
                                                    <LocationIcon className="icon" />
                                                    Current Location
                                                </div>
                                                <p style={{ fontSize: "14px" }}>
                                                    {selectedProfile?.personal_details.location?.city &&
                                                        selectedProfile?.personal_details.location?.state &&
                                                        selectedProfile?.personal_details.location
                                                            ?.country ? (
                                                        <>
                                                            {`${selectedProfile?.personal_details.location?.city}, ${selectedProfile?.personal_details.location?.state},`}
                                                            <br />
                                                            {
                                                                selectedProfile?.personal_details.location
                                                                    ?.country
                                                            }
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* 3rd grid  */}
                                <Grid
                                    container
                                    spacing={0}
                                    direction="row"
                                    justifyContent={"space-between"}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2.4}
                                        lg={2.3}
                                        xl={1.5}
                                        className="container-profile"
                                    ></Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={9.3}
                                        lg={9.5}
                                        xl={10.3}
                                        className="info-container2-internal"
                                    >
                                        <Grid md={4} lg={4} xl={4} className="info-container2">
                                            <div className="info-container-height">
                                                <div className="info-container1-heading">
                                                    <ExperienceIcon className="icon" />
                                                    Total Experience
                                                </div>
                                                {selectedProfile?.totalExperience > 0
                                                    ? <p style={{ fontSize: "14px" }}>
                                                        {selectedProfile?.totalExperience} year(s)
                                                    </p>
                                                    : selectedProfile?.calculatedExp}
                                            </div>
                                        </Grid>
                                        <Grid md={4} lg={4} xl={4} className="info-container2">
                                            <div className="info-container-height">
                                                <div className="info-container1-heading">
                                                    <EducationIcon className="icon" />
                                                    Highest Education
                                                </div>
                                                <div>
                                                    <p style={{ fontSize: "14px" }}>
                                                        {completedEducation?.filter((item, index) => {
                                                            if (index === 0) {
                                                                if (item.degree) {
                                                                    degree = item.degree;
                                                                }
                                                                if (item.stream) {
                                                                    stream = item.stream;
                                                                }
                                                                if (item.school) {
                                                                    school = item.school;
                                                                }
                                                            }
                                                        })}
                                                        {degree && stream ? (
                                                            <>
                                                                {degree} in {stream}
                                                                <br />
                                                                {school}
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid md={4} lg={4} xl={4} className="info-container2">
                                            {(selectedProfile?.salaryDetail?.salary &&
                                                selectedProfile?.salaryDetail?.payType) ||
                                                selectedProfile.salaryDetail.checked ? (
                                                <div className="info-container-height">
                                                    <div className="info-container1-heading">
                                                        <Dollar className="icon" />
                                                        Expected Salary (USD)
                                                    </div>
                                                    <p style={{ fontSize: "14px" }}>
                                                        {" "}
                                                        {selectedProfile?.salaryDetail?.salary &&
                                                            selectedProfile?.salaryDetail?.payType ? (
                                                            <>
                                                                ${selectedProfile?.salaryDetail?.salary}/
                                                                {selectedProfile?.salaryDetail?.payType}
                                                            </>
                                                        ) : selectedProfile?.salaryDetail?.checked ? (
                                                            <>${selectedProfile?.salaryDetail?.salary}</>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </p>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>

                            <div className="cards-list">
                                <Grid className="card-tiles" xs={12}>
                                    {/* Skills List card */}
                                    <Card id="skills-edit" className="side-card profile-card">
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row"
                                            className="skill-card sub-card"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="skill-card-title"
                                            >
                                                <div className="skill-card-heading">
                                                    <p>Skills</p>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="skills-button"
                                            >
                                                {selectedProfile?.skills.map((skill, index) => {
                                                    const custom =
                                                        index % 2 == 0
                                                            ? "filled-button"
                                                            : "unfilled-button";
                                                    return (
                                                        <Tooltip
                                                            title={
                                                                skill.yearOfExperience
                                                                    ? `${skill.skillName} - ${skill.yearOfExperience} year(s)`
                                                                    : `${skill.skillName}`
                                                            }
                                                        >
                                                            <button className={custom}>
                                                                {skill.skillName}
                                                            </button>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Card>

                                    {/* Experience List Card */}
                                    <Card
                                        id="experience-edit"
                                        className="middle-card profile-card"
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row"
                                            className="experience-card sub-card"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="experience-card-title"
                                            >
                                                <div className="experience-card-heading">
                                                    <p>Experience</p>
                                                </div>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="experience-list"
                                            >
                                                {highestExperience.map((job, index) => {
                                                    if (job.company) {
                                                        return (
                                                            <List>
                                                                {index >= 1 && (
                                                                    <Divider
                                                                        variant="inset"
                                                                        component="li"
                                                                        className="list-divider"
                                                                    />
                                                                )}
                                                                <div className="experience-list-edit-icon">
                                                                    <ListItem
                                                                        alignItems="flex-start"
                                                                        className="experience-list-items"
                                                                    >
                                                                        <ListItemAvatar className="experience-list-icon">
                                                                            <SquareRoundedIcon className="list-icon" />
                                                                        </ListItemAvatar>
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <ListItemText
                                                                                    className="list-items"
                                                                                    primary={job.company}
                                                                                    secondary={
                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent:
                                                                                                        "space-between",
                                                                                                    marginLeft: "0px",
                                                                                                }}
                                                                                                className="experience-main"
                                                                                            >
                                                                                                <div>
                                                                                                    <ul
                                                                                                        style={{
                                                                                                            listStyle: "none",
                                                                                                            paddingInlineStart: "0px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <li
                                                                                                            style={{
                                                                                                                fontSize: "13px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {job.position}
                                                                                                        </li>

                                                                                                        <li
                                                                                                            style={{
                                                                                                                fontSize: "13px",
                                                                                                                marginTop: "5px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {job.endMonth ===
                                                                                                                "Present" ? (
                                                                                                                <>
                                                                                                                    {" "}
                                                                                                                    {checkMonth(
                                                                                                                        job.startMonth
                                                                                                                    )}{" "}
                                                                                                                    {job.startYear} -{" "}
                                                                                                                    {"Present"}{" "}
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {" "}
                                                                                                                    {checkMonth(
                                                                                                                        job.startMonth
                                                                                                                    )}{" "}
                                                                                                                    {job.startYear} -{" "}
                                                                                                                    {checkMonth(
                                                                                                                        job.endMonth
                                                                                                                    )}{" "}
                                                                                                                    {job.endYear}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </ListItem>
                                                                </div>
                                                            </List>
                                                        );
                                                    }
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Card>

                                    {/* Education list card */}
                                    <Card
                                        id="education-edit"
                                        className="middle-card profile-card"
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row"
                                            className="education-card sub-card"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="education-card-title"
                                            >
                                                <div className="education-card-heading">
                                                    <p>Education</p>
                                                </div>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="experience-list"
                                            >
                                                {highestEducation.map((edu, index) => {
                                                    if (edu.degree && edu.stream) {
                                                        return (
                                                            <List>
                                                                {index >= 1 && (
                                                                    <Divider
                                                                        variant="inset"
                                                                        component="li"
                                                                        className="list-divider"
                                                                    />
                                                                )}
                                                                <div className="experience-list-edit-icon">
                                                                    <ListItem
                                                                        alignItems="flex-start"
                                                                        className="experience-list-items"
                                                                    >
                                                                        <ListItemAvatar className="experience-list-icon">
                                                                            <SquareRoundedIcon className="list-icon" />
                                                                        </ListItemAvatar>
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <ListItemText
                                                                                    className="list-items"
                                                                                    primary={`${edu.degree} in ${edu.stream}`}
                                                                                    secondary={
                                                                                        <React.Fragment>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: "flex",
                                                                                                    justifyContent:
                                                                                                        "space-between",
                                                                                                    marginLeft: "0px",
                                                                                                }}
                                                                                                className="experience-main"
                                                                                            >
                                                                                                <div>
                                                                                                    <ul
                                                                                                        style={{
                                                                                                            listStyle: "none",
                                                                                                            paddingInlineStart: "0px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <li
                                                                                                            style={{
                                                                                                                fontSize: "13px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {edu.school}
                                                                                                        </li>

                                                                                                        <li
                                                                                                            style={{
                                                                                                                fontSize: "13px",
                                                                                                                marginTop: "5px",
                                                                                                            }}
                                                                                                        >
                                                                                                            {edu.endMonth ===
                                                                                                                "Present" ? (
                                                                                                                <>
                                                                                                                    {" "}
                                                                                                                    {checkMonth(
                                                                                                                        edu.startMonth
                                                                                                                    )}{" "}
                                                                                                                    {edu.startYear} -{" "}
                                                                                                                    {"Present"}{" "}
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    {" "}
                                                                                                                    {checkMonth(
                                                                                                                        edu.startMonth
                                                                                                                    )}{" "}
                                                                                                                    {edu.startYear} -{" "}
                                                                                                                    {checkMonth(
                                                                                                                        edu.endMonth
                                                                                                                    )}{" "}
                                                                                                                    {edu.endYear}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </ListItem>
                                                                </div>
                                                            </List>
                                                        );
                                                    }
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Card>

                                    {/* Personal details card */}
                                    <Card
                                        id="personal-details-edit"
                                        className="side-card profile-card"
                                    >
                                        {/* 1st Grid container */}
                                        <Grid
                                            container
                                            spacing={1}
                                            direction="row"
                                            className="personal-card sub-card"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="personal-card-title"
                                            >
                                                <div className="personal-card-heading">
                                                    <p>Personal Details</p>
                                                </div>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                className="personal-details"
                                            >
                                                <div className="details-block">
                                                    {selectedProfile.personal_details.dob ? (
                                                        <>
                                                            <div className="personal-info">
                                                                <p className="personal-info-heading">
                                                                    Birth Date: &nbsp;{" "}
                                                                </p>
                                                                <p className="personal-info-content">
                                                                    {/* {dateFormat(new Date(selectedProfile.personal_details.dob), "mmmm dS, yyyy")}{" "} */}
                                                                    {formatDate(
                                                                        selectedProfile.personal_details.dob
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {selectedProfile.personal_details.gender ? (
                                                        <>
                                                            <div className="personal-info">
                                                                <p className="personal-info-heading">
                                                                    Gender: &nbsp;{" "}
                                                                </p>
                                                                <p className="personal-info-content">
                                                                    {selectedProfile.personal_details.gender}{" "}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {selectedProfile.personal_details.nationality ? (
                                                        <>
                                                            <div className="personal-info">
                                                                <p className="personal-info-heading">
                                                                    Nationality: &nbsp;{" "}
                                                                </p>
                                                                <p className="personal-info-content">
                                                                    {selectedProfile.personal_details.nationality}{" "}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {selectedProfile.personal_details.location.city &&
                                                        selectedProfile.personal_details.location.state &&
                                                        selectedProfile.personal_details.location.country ? (
                                                        <>
                                                            <div className="personal-info">
                                                                <p className="personal-info-heading">
                                                                    Current Location: &nbsp;{" "}
                                                                </p>
                                                                <p className="personal-info-content">
                                                                    {selectedProfile.personal_details.location
                                                                        .address1 ||
                                                                        selectedProfile.personal_details.location
                                                                            .address2
                                                                        ? selectedProfile.personal_details.location
                                                                            .address1 &&
                                                                            selectedProfile.personal_details.location
                                                                                .address2
                                                                            ? selectedProfile.personal_details
                                                                                .location.address1 +
                                                                            ", " +
                                                                            selectedProfile.personal_details
                                                                                .location.address2 +
                                                                            " - " +
                                                                            selectedProfile.personal_details
                                                                                .location.postalCode
                                                                            : selectedProfile.personal_details
                                                                                .location.address1
                                                                                ? selectedProfile.personal_details
                                                                                    .location.address1 +
                                                                                " - " +
                                                                                selectedProfile.personal_details
                                                                                    .location.postalCode
                                                                                : selectedProfile.personal_details
                                                                                    .location.address2
                                                                                    ? selectedProfile.personal_details
                                                                                        .location.address2 +
                                                                                    " - " +
                                                                                    selectedProfile.personal_details
                                                                                        .location.postalCode
                                                                                    : ""
                                                                        : selectedProfile.personal_details.location
                                                                            .city +
                                                                        ", " +
                                                                        selectedProfile.personal_details.location
                                                                            .state +
                                                                        " - " +
                                                                        selectedProfile.personal_details.location
                                                                            .postalCode}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </div>

                            {/* Resume Preview Dialog */}
                            <Dialog
                                open={openResumePreview}
                                onClose={handleCloseResumePreview}
                            >
                                <DialogContent>
                                    <Tooltip title="Close">
                                        <CloseIcon
                                            className="close-icon1"
                                            onClick={() => {
                                                setOpenResumePreview(false);
                                            }}
                                            sx={{
                                                cursor: "pointer",
                                                padding: 0,
                                                margin: "1px",
                                            }}
                                        />
                                    </Tooltip>
                                    <Grid
                                        className="internal-resume-viewer"
                                        item
                                        xs={11.5}
                                        sm={11.5}
                                        md={11.5}
                                        lg={11.5}
                                        sx={{
                                            padding: "30px 10px 10px 10px",
                                            width: "600px",
                                            height: "650px",
                                        }}
                                    >
                                        <object
                                            className="pdf-preview-component"
                                            style={{ width: "100%", height: "100%" }}
                                            data={selectedProfile.resume} // URL to the resume
                                            type="application/pdf"
                                        >
                                            <iframe
                                                src={selectedProfile.resume}
                                                width="100%"
                                                height="100%"
                                                title="Resume"
                                            />
                                        </object>
                                    </Grid>
                                </DialogContent>
                            </Dialog>
                        </Box>
                    </div>
                )
            ) : (
                <PageNotFound />
            )}
        </div>
    );
};

export default InternalUsersProfile;
