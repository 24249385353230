import React, { useState, useRef, useCallback, useEffect } from "react";
import axios from "axios";

//external library Imports
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";
import { Dialog } from "@mui/material";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// CSS import
// import "./MainCardEditModal.css";
import "../../../company admin/CompanyAdmin.css"

import UserSnackbar from "../../../snackBars/UserSnackbar";

const AddNoteModal = ({ userId, usersList, setUsersList, companyId, profileId, role, openNoteModal, setOpenNoteModal, setFilterArray, module }) => {

    const [ckText, setCKText] = useState("")

    const [message, setMessage] = useState("")
    const [openSnackbar, setOpenSnackbar] = useState(false)

    const handleCKEditor = (e, editor) => {
        setCKText(editor.getData())
    }

    const handleCloseNoteModal = () => {
        setOpenNoteModal(false)
    }

    const handleSave = (e) => {
        e.preventDefault();

        if (ckText == "") {
            setMessage("Please enter the note");
            setOpenSnackbar(true)
        } else {
            const newData = {
                ckText, companyId, userId, profileId, role
            }
            // send newData to backend
            axios.post(`/api/admin/add-note/`, newData).then(res => {
                let updatedUsers = usersList.map(item => {
                    if (item._id == res.data.noteDetails.userId) {
                        let { notes } = res.data.noteDetails;

                        // notes = [...notes, { profileId: profileId, time: new Date().toISOString(), note: ckText }]
                        console.log(notes, "given notes")
                        return { ...item, notes }
                    }
                    return item;
                })
                setUsersList(updatedUsers)
                if (module == "searchModule")
                    setFilterArray(updatedUsers)
                setCKText("");
                setOpenNoteModal(false);
                setMessage("Note added successfully")
                setOpenSnackbar(true)
            }).catch(err => {
                setMessage("Something went wrong")
                setOpenSnackbar(true)
            })
        }
    }

    return (
        <div>
            <Dialog
                open={openNoteModal}
                onClose={handleCloseNoteModal}
                className="experience-edit-dialog timezone-expiry-edit-dialog"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent
                    className="experience-edit-dialog-content"
                >
                    <Grid container spacing={0} justifyContent="center">
                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="experience-edit-title-grid"
                        >
                            <div>
                                <div className="experience-edit-title">
                                    <p>Add Note</p>
                                </div>
                                <div className="experience-edit-close">
                                    <Tooltip title="Close">
                                        <CloseIcon onClick={handleCloseNoteModal} />
                                    </Tooltip>
                                </div>
                            </div>
                            <Divider className="experience-edit-top-divider" />
                        </Grid>

                        <Grid
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="experience-edit-right-grid"
                        >
                            <div className="add-note-container">

                                <form
                                    className="experience-edit-form"
                                    noValidate
                                >
                                    <CKEditor
                                        className="ck-editor-container"
                                        editor={ClassicEditor}
                                        onChange={handleCKEditor}
                                        data={ckText}
                                        too
                                    // disabled={disabled}
                                    />

                                    <div className="button-container">
                                        <button className="btn-style filled-btn filled-btn-animation btn-width"
                                            onClick={handleSave}
                                        >
                                            {" "}
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog >

            {/* Snackbar */}
            < UserSnackbar openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} message={message} />
        </div >
    );
};

export default AddNoteModal;
