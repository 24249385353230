import React, { useState, useRef, useCallback, useEffect } from "react";
import axios from "axios";

//external library Imports
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";
import { Dialog } from "@mui/material";
import Slider from "@mui/material/Slider";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Cropper from "react-easy-crop";
import PhoneInput from "react-phone-input-2";
import DeleteMessageIcon from "@mui/icons-material/ErrorOutlineOutlined";
import TextareaCounter from "../textarea/TextareaCounter";
import Box from "@mui/material/Box";
import getCroppedImg from "./CropImage";
import { Country, State, City }  from 'country-state-city';

import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";

import SaveProfileSnackBar from "../snackBars/saveProfileSnackbar";
import ErrorMessageSnackbar from "../snackBars/errorMessageSnackbar";
import { getPhonesRegex } from "../auth/PhoneValidation";

// CSS import
import "./MainCardEditModal.css";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PublicIcon from "@mui/icons-material/Public";

const CompanyMainCardEdit = ({
  role,
  open,
  id,
  companyName,
  profileTitle,
  about,
  startYear,
  email,
  phone,
  profileImage,
  defaultAvatar,
  croppedAreaPixels,
  croppedArea,
  location,
  zoom,
  imagePublicId,
  setopenMainCardEdit,
  interacted,
  croppedImagePublicId,
  profileCompletionPercentage,
  linkedinURL,
  websiteURL,
}) => {
  //city state countries for getting external states, cities and countries
  const countries = Country.getAllCountries();
  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));

  const updatedStates = (country) =>
    
     State.getStatesOfCountry(country?.value).map((state) => ({
      label: state.name,
      value: state.isoCode,
      ...state,
    }));
  

    const updatedCities = (country,state) =>
        
         City.getCitiesOfState(country?.value,state?.value).map((city) => ({
          label: city.name,
          value: city.isoCode,
          ...city,
        }));
      

  // states to store the data received from ScreenerProfile.js
  let profileAvatar =
    "https://res.cloudinary.com/df2qsnyox/image/upload/v1681133496/Default%20Profile%20Avatar/profile-avatar_ogxozq_ranghl.png";

  const [interaction, setInteraction] = useState(interacted);
  const [companyName1, setCompanyName1] = useState(companyName);
  const [about1, setAbout1] = useState(about);
  const [email1, setEmail1] = useState(email);
  const [phone1, setPhone1] = useState(phone);
  const [userRole, setUserRole] = useState(role);
  const [profileTitle1, setProfileTitle1] = useState(profileTitle);
  const [profileImg1, setProfileImg1] = useState(profileImage);
  const [profileImg2, setProfileImg2] = useState(profileImage);
  const [zoom1, setZoom1] = useState(zoom);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImage1, setCroppedImage1] = useState(null);
  const [croppedAreaPixels1, setCroppedAreaPixels1] =
    useState(croppedAreaPixels);
  const [croppedArea1, setCroppedArea1] = useState(croppedArea);
  const [isAvatar, setIsAvatar] = useState(defaultAvatar);

  const [imagePublicId1, setImagePublicId1] = useState(imagePublicId);
  const [sliderValue, setSliderValue] = useState(1);
  const [newImageUploaded, setNewImageUploaded] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [isNewImg, setIsNewImg] = useState(false);
  const [address, setAddress] = useState(location.address);

  const [city, setCity] = useState(location.city);
  const [state, setState] = useState(location.state);
  const [country, setCountry] = useState(location.country);
  const [postalCode, setPostalCode] = useState(location.postalCode);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(true);

  const [linkedin, setLinkedin] = useState(linkedinURL);
  const [website, setWebsite] = useState(websiteURL);

  // store the regex of phone number patterns of all countries
  const phones = getPhonesRegex();

  //Year Dropdown
  const minOffset = 0;
  const maxOffset = 122;
  const thisYear = new Date().getFullYear();
  const currentYear = thisYear;
  const [startYear1, setStartYear1] = useState(startYear);
  // options for  option value under select
  const options = [];

  for (let i = minOffset; i <= maxOffset; i++) {
    const year = currentYear - i;
    options.push(<option value={year}>{year}</option>);
  }

  const [initialLocation, setInitialLocation] = useState({
    // country: null,
    // state: null,
    // city: null,
    country: location.country ? { label: location.country } : null,
    state: location.state ? { label: location.state } : null,
    city: location.city ? { label: location.city } : null,
    countryId: location.countryId,
    stateId: location.stateId,
  });

  

  //handle close confirmation dialog
  const onCloseDialog = () => {
    setCompanyName1(companyName);
    setAbout1(about);
    setEmail1(email);
    setPhone1(phone);
    setStartYear1(startYear);
    setErrors({
      imageSize: "",
      imageType: "",
      companyName: "",
      profileTitle: "",
      about: "",
      email: "",
      phone: "",
      address: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
      startYear: "",
    });
    setProfileTitle1(profileTitle);
    setProfileImg1(profileImage);
    setOpenCloseDialog(false);
    setopenMainCardEdit(false);
  };

  // handlePin Change to limit to max of 10m digits
  const handleNumChange = (e) => {
    setErrors({
      postalCode: "",
    });
    const limit = 10;
    setPostalCode(e.target.value.slice(0, limit));
  };
  const addressHandler = (e) => {
    setAddress(e.target.value);
    setErrors({ address: "" });
  };

  const startYearHandler = (e) => {
    setStartYear1(e.target.value);
    setErrors({ startYear: "" });
  };

  // handle cancel button press of confirmation dialog
  const onCloseCancel = () => {
    setOpenCloseDialog(false);
    setopenMainCardEdit(true);
  };

  // handle close of main dialog
  const onCloseMainDialog = () => {
    setOpenCloseDialog(true);
    setopenMainCardEdit(false);
  };

  // called everytime the image is cropped
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels1(croppedAreaPixels);
    setCroppedArea1(croppedArea);
  }, []);

  // error handling
  const [errors, setErrors] = useState({
    imageSize: "",
    imageType: "",
    companyName: "",
    profileTitle: "",
    about: "",
    startYear: "",
    email: "",
    phone: "",
    address: "",
    state: "",
    country: "",
    postalCode: "",
    city: "",
  });

  //useRef
  const imageRef = useRef();

  const companyNameHandler = (e) => {
    setCompanyName1(e.target.value);
    setErrors({
      companyName: "",
    });
  };

  const aboutHandler = (e) => {
    setAbout1(e.target.value);
    setErrors({
      about: "",
    });
  };

  const profileTitleHandler = (e) => {
    setProfileTitle1(e.target.value);
    setErrors({
      profileTitle: "",
    });
  };

  // handle save button press
  const onSubmit = async (e) => {
    e.preventDefault();

    if (companyName1 == "")
      return setErrors({ companyName: "Company name is required!" });

    if (profileTitle1 == "")
      return setErrors({ profileTitle: "Tag line is required!" });

    if (about1 == "") return setErrors({ about: "About is required!" });

    if (startYear1 === "")
      return setErrors({ startYear: "Start Year is required!" });

    if (email1 == "") return setErrors({ email: "Email is required!" });

    if (phone1 == "") return setErrors({ phone: "Phone is required!" });
    else if (!phoneIsValid) {
      return setErrors({ phone: "Invalid Phone Number" });
    }

    if (address === "") {
      return setErrors({
        address: "Address is required",
      });
    }

    if (!initialLocation.country) {
      return setErrors({
        country: "Country is required",
      });
    }
    if (!initialLocation.state) {
      return setErrors({
        state: "State is required",
      });
    }
    if (!initialLocation.city) {
      return setErrors({
        city: "City is required",
      });
    }

    if (postalCode === "") {
      return setErrors({
        postalCode: "Postal Code is required",
      });
    }
    const formData = new FormData();
    formData.append("id", id);
    formData.append("companyName", companyName1);
    formData.append("profileTitle", profileTitle1);
    formData.append("about", about1);
    formData.append("startYear", startYear1);
    formData.append("email", email1);
    formData.append("phone", phone1);
    formData.append("defaultAvatar", isAvatar);
    formData.append("profileImage", profileImg2);
    formData.append("imagePublicId", imagePublicId);
    formData.append("croppedImage", croppedImage1);
    formData.append("newImageUploaded", newImageUploaded);
    formData.append("croppedImagePublicId", croppedImagePublicId);
    formData.append("croppedAreaPixels", JSON.stringify(croppedAreaPixels1));
    formData.append("croppedArea", JSON.stringify(croppedArea1));
    formData.append("zoom", Number(zoom1));
    formData.append("profileCompletion", profileCompletionPercentage);
    formData.append("interacted", interaction);
    formData.append("address", address);
    formData.append("postalcode", postalCode);
    formData.append("country", initialLocation.country.label);
    formData.append("state", initialLocation.state.label);
    formData.append("city", initialLocation.city.label);
    formData.append("countryId", initialLocation.countryId);
    formData.append("stateId", initialLocation.stateId);
    formData.append("linkedinURL", linkedin);
    formData.append("websiteURL", website);

    axios
      .post(`/api/${userRole}/profile-main/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res);
        setopenMainCardEdit(false);
        setOpenSnackbar(true);
        setErrors({});
      })
      .catch((err) => {
        console.log(err.message);
        setOpenErrorSnackbar(true);
      });

    setIsNewImg(false);
    
  };

  // used to get url of newly uploaded image
  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  // handle profile image upload
  const handleProfileImageUpload = async (e) => {
    const fileCheck = e.target.files[0];
    const maxSize = 1024 * 1024; //1MB
    if (fileCheck.size > maxSize) {
      setErrors({ imageSize: "Please upload image less than 1MB" });
      imageRef.current.value = null;
    } else if (!fileCheck.type.startsWith("image")) {
      setErrors({ imageType: "Please upload an image file" });
      e.target.files = null;
    } else {
      let imageDataUrl = await readFile(fileCheck);
      setProfileImg1(imageDataUrl);

      setProfileImg2(fileCheck);
      setNewImageUploaded(true);
      setErrors({ imageSize: "", imageType: "" });
      setIsAvatar(false);
      setIsNewImg(true);
      setInteraction(false);
      setZoom1(1);
      // await showCroppedImage();
    }
  };

  // used to get cropped image
  const showCroppedImage = useCallback(async () => {
    let profileImgUrl = profileImg1;
    try {
      const croppedImage = await getCroppedImg(
        profileImgUrl,
        croppedAreaPixels1,
        0 //rotation
      );
      setCroppedImage1(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels1, profileImg1]);

  // handle profile image remove
  const handleRemoveProfileImage = (e) => {
    setProfileImg1(profileAvatar);
    setProfileImg2(profileAvatar);
    setZoom1(1);
    setErrors({ imageSize: "", imageType: "" });
    setIsAvatar(true);
    setNewImageUploaded(false);
    imageRef.current.value = null;
    setOpenAlertDialog(false);
  };

  // handle remove image alert dialog
  const handleRemoveImageDialog = () => {
    setOpenAlertDialog(true);
  };
  const onCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  // disable the right and left arrow keys for the slider
  function preventHorizontalKeyboardNavigation(event) {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  return (
    <div>
      <div className="main-dialog">
        <Dialog open={open} onClose={onCloseMainDialog} maxWidth="md">
          <DialogContent>
            <Tooltip title="Close">
              <CloseIcon
                className="close-icon"
                onClick={onCloseMainDialog}
                sx={{ cursor: "pointer", padding: 0, margin: "1px" }}
              />
            </Tooltip>
            <Grid container spacing={0} justifyContent="center">
              {/* Left Grid item */}
              <Grid item xs={12} sm={12} md={4} lg={4.3} className="left-grid">
                <div className="left-container">
                  <div className="image-container">
                    <div className="crop-container">
                      <Cropper
                        className="cropper"
                        image={profileImg1}
                        crop={crop}
                        zoom={zoom1}
                        initialCroppedAreaPixels={
                          isNewImg || isAvatar ? null : croppedAreaPixels1
                        }
                        aspect={16 / 16}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onCropSizeChange={showCroppedImage}
                        onInteractionStart={() => {
                          setInteraction(true);
                          showCroppedImage();
                        }}
                        onInteractionEnd={showCroppedImage}
                        onZoomChange={isAvatar ? null : setZoom1}
                        restrictPosition={true}
                        onMediaLoaded={showCroppedImage}
                        objectFit="auto-cover"
                      />
                    </div>
                    <div>
                      <label className="image-upload">
                        <input
                          type="file"
                          onChange={handleProfileImageUpload}
                          accept=".jpg, .png, .jpeg"
                          ref={imageRef}
                        />
                        <span>Upload</span>
                      </label>
                      <button
                        className="image-remove"
                        onClick={handleRemoveImageDialog}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  <span className="text-danger">
                    {errors.imageSize} {errors.imageType}
                  </span>
                  <div className="name-designation">
                    <h3>{companyName}</h3>
                    <p>{profileTitle}</p>
                  </div>
                </div>
                <div className="slider-container">
                  <Box sx={{ height: 100 }}>
                    <Slider
                      sx={{
                        '& input[type="range"]': {
                          WebkitAppearance: "slider-vertical",
                        },
                      }}
                      step={0.1}
                      type="range"
                      min={1}
                      max={3}
                      size="small"
                      value={zoom1}
                      orientation="vertical"
                      defaultValue={sliderValue}
                      aria-label="Zoom In"
                      valueLabelDisplay="auto"
                      onKeyDown={preventHorizontalKeyboardNavigation}
                      onChange={
                        isAvatar
                          ? null
                          : (e, zoom) => {
                              setZoom1(zoom);
                              setInteraction(true);
                              showCroppedImage();
                            }
                      }
                    />
                  </Box>
                  <div>
                    <p>Zoom in</p>
                  </div>
                </div>
              </Grid>
              <Box>
                <Divider orientation="vertical" />
              </Box>

              {/* Right Grid item */}
              <Grid item xs={12} sm={12} md={7} lg={7.5} className="right-grid">
                <div>
                  <form
                    noValidate
                    className="right-grid-form"
                    onSubmit={(e) => {
                      onSubmit(e);
                    }}
                  >
                    <div class="input-container">
                      <div class="input-field-modal">
                        <label className="date-label">Company Name*</label>
                        <input
                          type="text"
                          value={companyName1}
                          onChange={companyNameHandler}
                          placeholder="Company Name"
                        />
                      </div>
                      <span className="text-danger">{errors.companyName}</span>

                      <div class="input-field-modal">
                        <label className="date-label">Tag line*</label>
                        <input
                          class="profileTitle"
                          type="text"
                          value={profileTitle1}
                          placeholder="Tag Line"
                          onChange={profileTitleHandler}
                        />
                      </div>
                      <span className="text-danger">{errors.profileTitle}</span>
                      <div class="input-field-modal textarea__wrapper">
                        <label className="date-label">
                          About* (Maximum of 500 characters)
                        </label>
                        {/* <TextareaCounter
                          class="about"
                          placeholder="Tell something about yourself"
                          countLimit={500}
                          initialValue={about1}
                          minrows={5}
                          maxrows={6}
                          maxLength={500}
                          value={about1}
                          resize="none"
                          onChange={aboutHandler}
                        /> */}
                        <TextareaCounter
                          className="about"
                          maxLength={500}
                          value={about1}
                          placeholder="Tell something about yourself"
                          onChange={aboutHandler}
                        />
                      </div>
                      <span className="text-danger">{errors.about}</span>
                      <div className="experience-edit-form">
                        <div className=" gender-dropdown">
                          <div class="input-field">
                            <label className="date-label">
                              In Business Since*
                            </label>
                            <select
                              value={startYear1}
                              onChange={startYearHandler}
                            >
                              {options}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="input-field-modal">
                        <label className="date-label">Email*</label>
                        <input
                          class="email"
                          type="email"
                          placeholder="Email Id*"
                          value={email1}
                          disabled={true}
                        />
                      </div>
                      <span className="text-danger">{errors.email}</span>
                      <div class="input-field-modal">
                        <label className="date-label">Phone*</label>
                        <PhoneInput
                          country={"us"}
                          class="phone"
                          type="number"
                          placeholder="Phone"
                          value={phone1}
                          onChange={(phone, country) => {
                            setPhone1(phone);
                            var regex =
                              phones[country.countryCode.toUpperCase()];
                            if (regex != undefined) {
                              if (!regex.test(phone)) {
                                setPhoneIsValid(false);
                                return setErrors({
                                  phone: "Invalid Phone Number",
                                });
                              } else {
                                setPhoneIsValid(true);
                                return setErrors({ phone: "" });
                              }
                            }
                          }}
                        />
                      </div>
                      <span className="text-danger">{errors.phone}</span>

                      <div class="input-field-modal">
                        <label className="date-label">Address*</label>
                        <input
                          class="position"
                          type="text"
                          value={address}
                          onChange={addressHandler}
                          placeholder="Ex: Street name"
                        />
                        <span className="text-danger">{errors.address}</span>
                      </div>

                      <div class="input-field-modal">
                        <label className="date-label">Country*</label>
                        <Select
                          class="position"
                          type="text"
                          value={initialLocation.country}
                          options={updatedCountries}
                          onChange={(value) => {
                            setInitialLocation(
                              {
                                country: value,
                                countryId: value.isoCode,
                                state: null,
                                city: null,
                              },
                              false
                            );
                            setErrors({
                              country: "",
                            });
                          }}
                          placeholder="Country"
                        />
                        <span className="text-danger">{errors.country}</span>
                      </div>

                      <div class="input-field-modal">
                        <label className="date-label">State*</label>
                        <Select
                          class="position"
                          type="text"
                          placeholder="State"
                          options={updatedStates(
                            initialLocation.country
                              ? initialLocation.country
                              : null
                          )}
                          value={initialLocation.state}
                          onChange={(value) => {
                            setInitialLocation(
                              {
                                ...initialLocation,
                                state: value,
                                stateId: value.isoCode,
                                city: null,
                              },
                              false
                            );
                            setErrors({
                              state: "",
                            });
                          }}
                        />
                        <span className="text-danger">{errors.state}</span>
                      </div>
                      <div class="input-field-modal">
                        <label className="date-label">City*</label>
                        <Select
                          class="position"
                          type="text"
                          options={updatedCities(initialLocation.country
                            ? initialLocation.country
                            : null,
                            initialLocation.state
                              ? initialLocation.state
                              : null
                          )}
                          value={initialLocation.city}
                          onChange={(value) => {
                            setInitialLocation({
                              ...initialLocation,
                              city: value,
                            });
                            setErrors({
                              city: "",
                            });
                          }}
                          placeholder="City"
                        />
                        <span className="text-danger">{errors.city}</span>
                      </div>
                      <div class="input-field-modal">
                        <label className="date-label">Postal Code*</label>
                        <input
                          class="position"
                          type="number"
                          value={postalCode}
                          onChange={handleNumChange}
                          placeholder="Ex: 1234567"
                        />
                        <span className="text-danger">{errors.postalCode}</span>
                      </div>

                      <div
                        className="input-field-modal"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="links-container">
                          <LinkedInIcon
                            fontSize="small"
                            style={{ color: "#f06161" }}
                          />
                          <input
                            class="email"
                            type="text"
                            placeholder="Enter your Linkedin Profile URL"
                            value={linkedin}
                            onChange={(e) => setLinkedin(e.target.value)}
                          />
                        </div>
                        <div className="links-container">
                          <PublicIcon
                            fontSize="small"
                            style={{ color: "#f06161" }}
                          />
                          <input
                            class="email"
                            type="text"
                            placeholder="Enter your Website URL"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="button-container">
                        <button
                          className="btn-style save-button filled-btn-animation"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>

      {/* Alert Dialog  */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAlertDialog}
        onClose={onCloseAlertDialog}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              item
              xs={11.5}
              sm={11.5}
              md={12}
              lg={12}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure?</h5>
                <p>Do you want remove your profile picture?</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="alert-buttons-container">
          <button
            className="warning-btn-style filled-btn filled-btn-animation"
            onClick={onCloseAlertDialog}
          >
            {" "}
            Cancel
          </button>
          <button
            className="warning-btn-style outlined-btn outlined-btn-animation"
            onClick={handleRemoveProfileImage}
          >
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      {/* Close confimation dialog */}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openCloseDialog}
        onClose={onCloseMainDialog}
        className="experience-edit-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-content">
          <Grid container spacing={0} justifyContent="center">
            <Grid
              item
              xs={11.5}
              sm={11.5}
              md={12}
              lg={12}
              className="experience-delete-grid"
            >
              <div className="delete-message-icon">
                <DeleteMessageIcon fontSize="small" />
              </div>
              <div className="delete-dialog-content">
                <h5>Are you sure?</h5>
                <p>Do you want to go back?</p>
                <p>All your current changes will be lost!</p>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <div className="alert-buttons-container">
          <button
            className=" warning-btn-style filled-btn filled-btn-animation"
            onClick={onCloseCancel}
          >
            {" "}
            Cancel
          </button>
          <button
            className=" warning-btn-style outlined-btn outlined-btn-animation"
            onClick={onCloseDialog}
          >
            {" "}
            Confirm
          </button>
        </div>
      </Dialog>

      <SaveProfileSnackBar
        openSnackbar={openSnackbar}
        setOpenSnackbar={setOpenSnackbar}
      />
      <ErrorMessageSnackbar
        openErrorSnackbar={openErrorSnackbar}
        setOpenErrorSnackbar={setOpenErrorSnackbar}
      />
    </div>
  );
};

export default CompanyMainCardEdit;
