import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../Skill-Dashboard/SkillDashboard.css";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { useEffect } from "react";
import axios from "axios";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

function createData(name, testName, scores, date) {
  return { name, testName, scores, date };
}

const SkillDashboard = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = localStorage.getItem("rowsPerPage");
    return savedRowsPerPage ? parseInt(savedRowsPerPage, 10) : 5;
  });
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [rows, setRows] = useState([]);
  const [tests, setTests] = useState([]);
  const [totalTests, setTotalTests] = useState(0);
  const [totalCandidatesAssessed, setTotalCandidatesAssessed] = useState(0);
  const [passingRate, setPassingRate] = useState(0);

  useEffect(() => {
    async function fetchQuestions() {
      try {
        const questions = await axios.post("/api/admin/getQuestion");
        setTotalQuestions(questions.data.length);
      } catch (error) {
        console.error("Failed to fetch questions length", error);
      }
    }

    fetchQuestions();
    // fetchRecentTestResults();
  }, []);

  useEffect(() => {
    // Update rows from tests state instead of fetching from API
    const recentTestResults = [];

    tests.forEach((test) => {
      test.candidates.forEach((candidate) => {
        const securedScore = candidate.testResult?.securedScore ?? "-";

        recentTestResults.push(
          createData(
            candidate.fullName,
            test.testName,
            securedScore,
            // new Date(test.createdAt).toLocaleDateString()
            candidate.startTime
              ? new Date(candidate.startTime).toLocaleDateString("en-US")
              : ""
          )
        );
      });
    });

    // Sort recentTestResults by date (descending)
    recentTestResults.sort((a, b) => {
      if (!a.date) return 1; // Push items without date to the end
      if (!b.date) return -1;
      return new Date(b.date) - new Date(a.date);
    });

    setRows(recentTestResults);
  }, [tests]);

  // console.log("rows",rows)

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axios.post(
          `/api/admin/skill-assessment/test-management/get-all-test-details`
        );
        console.log(
          "Candidates for the first test:",
          response.data[0].candidates
        );

        setTests(response.data);

        // Calculate real data from fetched tests
        const totalTestsCount = response.data.length;
        let totalCandidates = 0;
        let passedCandidates = 0;

        response.data.forEach((test) => {
          totalCandidates += test.candidates.length;
          passedCandidates += test.candidates.filter(
            (candidate) => candidate.status === "passed"
          ).length;
        });

        setTotalTests(totalTestsCount);
        setTotalCandidatesAssessed(totalCandidates);

        const passingRateCalc =
          totalCandidates > 0 ? (passedCandidates / totalCandidates) * 100 : 0;
        setPassingRate(passingRateCalc.toFixed(2)); // round to 2 decimal places
      } catch (error) {
        console.error("Error fetching test details:", error);
        alert("Failed to fetch test details. Please try again.");
      }
    };

    fetchTests();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    localStorage.setItem("rowsPerPage", newRowsPerPage);
    setPage(0);
  };

  console.log("tests state data", tests);
  console.log("totalTests state data", totalTests);
  console.log("totalCandidates state data", totalCandidatesAssessed);
  console.log("passingRate state data", passingRate);

  //   const classes = useStyles();

  return (
    <>
      <div className="dashboard-fullpage">
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card className="cards" variant="outlined">
              <React.Fragment>
                <CardContent>
                  <Typography variant="h5" sx={{ color: "white" }} gutterBottom>
                    Total Tests
                  </Typography>
                  <Typography component="div">All Tests Created</Typography>
                  <br></br>

                  <Typography variant="h5" sx={{ color: "white" }}>
                    {totalTests}
                    <br />
                  </Typography>
                </CardContent>
              </React.Fragment>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card className="cards" variant="outlined">
              <React.Fragment>
                <CardContent>
                  <Typography variant="h5" sx={{ color: "white" }} gutterBottom>
                    Total Questions
                  </Typography>
                  <Typography component="div">Questions in the bank</Typography>
                  <br></br>

                  <Typography variant="h5" sx={{ color: "white" }}>
                    {totalQuestions}
                    <br />
                  </Typography>
                </CardContent>
              </React.Fragment>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card className="cards" variant="outlined">
              <React.Fragment>
                <CardContent>
                  <Typography variant="h5" sx={{ color: "white" }} gutterBottom>
                    Candidates Assessed
                  </Typography>
                  <Typography component="div">
                    Total Candidates Assessed
                  </Typography>
                  <br></br>

                  <Typography variant="h5" sx={{ color: "white" }}>
                    {totalCandidatesAssessed}
                    <br />
                  </Typography>
                </CardContent>
              </React.Fragment>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card className="cards" variant="outlined">
              <React.Fragment>
                <CardContent>
                  <Typography variant="h5" sx={{ color: "white" }} gutterBottom>
                    Passing Rate
                  </Typography>
                  <Typography component="div">Overall Passing Rate</Typography>
                  <br></br>

                  <Typography variant="h5" sx={{ color: "white" }}>
                    {passingRate}%
                    <br />
                  </Typography>
                </CardContent>
              </React.Fragment>
            </Card>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6} md={4} lg={11.7} className="container-box">
            <h4
              style={{
                color: "white",
                margin: "20px 0px 0px 0px",
                textAlign: "center",
                fontSize: "1.6rem",
                fontWeight: "normal",
              }}
            >
              Recent Test Results
            </h4>
            <br />
            <div className="table">
              <TableContainer className="table-container" component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "1.3rem",
                          padding: "30px",
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        Candidate
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "1.3rem",
                          padding: "30px",
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        Test
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "1.3rem",
                          padding: "30px",
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        Score
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "1.3rem",
                          padding: "30px",
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        Date
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* Heading Endsssss */}

                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow
                          className="tableRow"
                          key={row.name}
                          // sx={{
                          //   "&:last-child td, &:last-child th": { border: 0 },
                          // }}
                        >
                          <TableCell
                            className="tablerowcells"
                            component="th"
                            scope="row"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell className="tablerowcells">
                            {row.testName}
                          </TableCell>
                          <TableCell className="tablerowcells">
                            {row.scores}
                          </TableCell>
                          <TableCell className="tablerowcells">
                            {row.date}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <br />
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SkillDashboard;
