import React, { useEffect, useState, useMemo } from 'react'
import { Dialog, DialogContent, Grid, Divider } from "@mui/material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


import axios from 'axios'

const columns = [
    "InterviewTitle",
    "Interview Id",
    "Client",
    "Position",
    "Screener",
    "Candidate",
    "Date",
    "Time",
    "Status",
]

function RecruiterInterviewsModal({ selectedRecruiterId, openInterviews, setOpenInterviews }) {


    const [allInterviews, setAllInterviews] = useState([])
    const [interviews, setInterviews] = useState([])
    console.log(selectedRecruiterId)

    const compId = sessionStorage.getItem("id")
    console.log(compId)

    const [titleFilter, setTitleFilter] = useState("")
    const [idFilter, setIdFilter] = useState("")
    const [clientFilter, setClientFilter] = useState("")
    const [positionFilter, setPositionFilter] = useState("")
    const [screenerFilter, setScreenerFilter] = useState("")
    const [candidateFilter, setCandidateFilter] = useState("")
    const [dateFilter, setDateFilter] = useState("")
    const [timeFilter, setTimeFilter] = useState("")
    const [statusFilter, setStatusFilter] = useState("")




    function fetchInterviewDetails() {
        axios.get(`/api/admin/get-recruiter-interviews/${selectedRecruiterId}/${compId}`)
            .then(res => {
                console.log(res.data)
                setInterviews(res.data)
                setAllInterviews(res.data)
            })
            .catch(err => {
                console.log(err)
                setInterviews([])
            })
    }

    useEffect(() => {
        fetchInterviewDetails()
    }, [selectedRecruiterId])

    useEffect(()=> {
        if(titleFilter=="" && idFilter=="" && clientFilter=="" && positionFilter=="" && screenerFilter=="" && candidateFilter=="" && dateFilter=="" && timeFilter=="" && statusFilter=="") {
            fetchInterviewDetails()
        }else {
            handleFilterInterviews()
        }
    }, [titleFilter, idFilter, clientFilter,positionFilter, screenerFilter, candidateFilter, dateFilter, timeFilter, statusFilter ])

    function handleFilterInterviews() {
        let filtered = JSON.parse(JSON.stringify(allInterviews))

        console.log(filtered)

        filtered = filtered.filter(item => {
            return (item.interviewTitle?.toLowerCase().includes(titleFilter.toLowerCase()) &&
                item.interviewUniqueId?.toLowerCase().includes(idFilter.toLowerCase()) &&
                String(item.clientDetails?.name).toLowerCase().includes(clientFilter.toLowerCase()) &&
                String(item.positionDetails?.name).toLowerCase().includes(positionFilter.toLowerCase()) &&
                item.screener?.name?.toLowerCase().includes(screenerFilter.toLowerCase()) &&
                item.candidate?.name?.toLowerCase().includes(candidateFilter.toLowerCase()) &&
                item.interviewDate?.toLowerCase().includes(dateFilter.toLowerCase()) &&
                item.interviewTime?.toLowerCase().includes(timeFilter.toLowerCase()) &&
                item.interviewStatus?.toLowerCase().includes(statusFilter.toLowerCase())
            )
        })

        console.log(filtered)

        setInterviews(filtered)

    }

    return (
        <Dialog
            open={openInterviews}
            onClose={() => setOpenInterviews(false)}
            className="experience-edit-dialog"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            style={{ width: "1200px !important" }}
        >
            <DialogContent>
                {/* <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className="interviews-container__head mb"
                    style={{ overflowY: "unset" }}
                >
                    <h4>Interviews</h4>
                </Grid> */}
                <Grid container className="interviews-container__main">
                    <Grid
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}>
                        <div className="interviews-container__body">
                            <TableContainer sx={{ maxHeight: 440, fontFamily: "Raleway", border: "1px solid gray", marginRight: "1rem" }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, i) => (
                                                <TableCell
                                                    key={column}
                                                    align="center"
                                                    sx={{
                                                        fontWeight: 600,
                                                        background: `${i % 2 == 0 ? "rgba(240, 97, 97, 0.17)" : "rgba(240, 97, 97, 0.37)"}`,
                                                        width: 400,
                                                        fontFamily: "'Raleway', sans-serif"
                                                    }}
                                                >
                                                    {column}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                // key={column}
                                                align="center"
                                                sx={{
                                                    fontWeight: 600,
                                                    width: 400,
                                                    fontFamily: "'Raleway', sans-serif"
                                                }}
                                            >
                                                <input value={titleFilter}
                                                    onChange={(e) => {
                                                        setTitleFilter(e.target.value)
                                                    }}
                                                    type="search"
                                                    placeholder="Search Title"
                                                />
                                            </TableCell>
                                            <TableCell
                                                // key={column}
                                                align="center"
                                                sx={{
                                                    fontWeight: 600,
                                                    width: 400,
                                                    fontFamily: "'Raleway', sans-serif"
                                                }}
                                            >
                                                <input
                                                    value={idFilter}
                                                    onChange={(e) => {
                                                        setIdFilter(e.target.value)
                                                    }}
                                                    type="search"
                                                    placeholder="Search ID" />
                                            </TableCell>
                                            <TableCell
                                                // key={column}
                                                align="center"
                                                sx={{
                                                    fontWeight: 600,
                                                    width: 400,
                                                    fontFamily: "'Raleway', sans-serif"
                                                }}
                                            >
                                                <input
                                                    value={clientFilter}
                                                    onChange={(e) => {
                                                        setClientFilter(e.target.value)
                                                    }}
                                                    type="search"
                                                    placeholder="Search Client" />
                                            </TableCell>
                                            <TableCell
                                                // key={column}
                                                align="center"
                                                sx={{
                                                    fontWeight: 600,
                                                    width: 400,
                                                    fontFamily: "'Raleway', sans-serif"
                                                }}
                                            >
                                                <input
                                                    onChange={(e) => setPositionFilter(e.target.value)}
                                                    type="search"
                                                    placeholder="Search Position" />
                                            </TableCell>
                                            <TableCell
                                                // key={column}
                                                align="center"
                                                sx={{
                                                    fontWeight: 600,
                                                    width: 400,
                                                    fontFamily: "'Raleway', sans-serif"
                                                }}
                                            >
                                                <input
                                                    value={screenerFilter}
                                                    onChange={(e) => setScreenerFilter(e.target.value)}
                                                    type="search"
                                                    placeholder="Search Screener" />
                                            </TableCell>
                                            <TableCell
                                                // key={column}
                                                align="center"
                                                sx={{
                                                    fontWeight: 600,
                                                    width: 400,
                                                    fontFamily: "'Raleway', sans-serif"
                                                }}
                                            >
                                                <input
                                                    value={candidateFilter}
                                                    onChange={(e) => setCandidateFilter(e.target.value)}
                                                    type="search"
                                                    placeholder="Search Candidate" />
                                            </TableCell>
                                            <TableCell
                                                // key={column}
                                                align="center"
                                                sx={{
                                                    fontWeight: 600,
                                                    width: 400,
                                                    fontFamily: "'Raleway', sans-serif"
                                                }}
                                            >
                                                <input
                                                    value={dateFilter}
                                                    onChange={(e) => setDateFilter(e.target.value)}
                                                    type="search"
                                                    placeholder="Search Date" />
                                            </TableCell>
                                            <TableCell
                                                // key={column}
                                                align="center"
                                                sx={{
                                                    fontWeight: 600,
                                                    width: 400,
                                                    fontFamily: "'Raleway', sans-serif"
                                                }}
                                            >
                                                <input
                                                    value={timeFilter}
                                                    onChange={(e) => setTimeFilter(e.target.value)}
                                                    type="search"
                                                    placeholder="Search Time" />
                                            </TableCell>
                                            <TableCell
                                                // key={column}
                                                align="center"
                                                sx={{
                                                    fontWeight: 600,
                                                    width: 400,
                                                    fontFamily: "'Raleway', sans-serif"
                                                }}
                                            >
                                                <input
                                                    value={statusFilter}
                                                    onChange={(e) => setStatusFilter(e.target.value)}
                                                    type="search"
                                                    placeholder="Search Status" />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {interviews.length > 0 ? <TableBody>
                                        {interviews
                                            .map((row) => {
                                                return (
                                                    <TableRow
                                                        hover role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.code}

                                                    >
                                                        <TableCell style={{ fontFamily: "'Raleway', sans-serif" }} align="center">
                                                            {row.interviewTitle}
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "'Raleway', sans-serif" }} align="center">
                                                            {row.interviewUniqueId}
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "'Raleway', sans-serif" }} align="center">
                                                            {row.clientDetails?.name}
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "'Raleway', sans-serif" }} align="center">
                                                            {row.positionDetails?.name}
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "'Raleway', sans-serif" }} align="center">
                                                            {row.screener.name}
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "'Raleway', sans-serif" }} align="center">
                                                            {row.candidate.name}
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "'Raleway', sans-serif" }} align="center">
                                                            {row.interviewDate}
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "'Raleway', sans-serif" }} align="center">
                                                            {row.interviewTime}
                                                        </TableCell>
                                                        <TableCell style={{ fontFamily: "'Raleway', sans-serif" }} align="center">
                                                            {row.interviewStatus}
                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody> : <p style={{ margin: "1rem" }}>No Interviews</p>}
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default RecruiterInterviewsModal