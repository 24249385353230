import React, { useEffect, useState, useRef } from 'react'


//MUI Imports
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import BusinessIcon from '@mui/icons-material/BusinessCenter';
import { TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

// import data from './requirement.json'
import Skills from '../../../modals/skills';
import axios from 'axios'

//Snackbar
import UserSnackbar from '../../../snackBars/UserSnackbar';


const AddPositions = ({ setAddPosition, companyName, companyEmail }) => {

    const saveRef = useRef(null)

    const data = Skills()

    const id = sessionStorage.getItem("id");
    const companyId = sessionStorage.getItem("companyId");
    const compId = sessionStorage.getItem("compId");
    const profileType = sessionStorage.getItem("profileType");
    // states
    const [positionDetails, setPositionDetails] = useState({
        client: {},
        position: '',
        positionsCount: 1,
        skills: [],
        description: '',
        status: "Active",
        priority: "High",
        rate: ""
    })

    const [allClients, setAllClients] = useState([])
    const [clientName, setClientName] = useState("")

    const [clientDetails, setClientDetails] = useState({})


    const [skillsList, setSkillsList] = useState([])
    const [status, setStatus] = useState("Active")

    //snackBar states
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [message, setMessage] = useState("")


    const [error, setError] = useState({
        position: '',
        client: '',
        status: '',
        rate: '',

        skills: '',
    })


    console.log(status)
    //for fetching all clients
    useEffect(() => {
        const clients = axios.get(`/api/admin/get-all-clients/${id}`).then((res) => {

            console.log(res)
            if (res.data.length > 0) {
                // const filteredArray = res.data.filter(client => client.status === statusSelected)
                // console.log(filteredArray)


                setAllClients(res.data);

            }
        })


    }, [])


    console.log(allClients, skillsList, status)



    // handle input field changes
    const handleChangeFormFields = (e) => {
        switch (e.target.name) {
            case "Position":
                setPositionDetails({ ...positionDetails, position: e.target.value })
                setError({})
                break;
            case "clientName":
                setClientName(e.target.value)
                setClientDetails({})
                setError({})
                break;
            case "Rate":
                setPositionDetails({ ...positionDetails, rate: e.target.value })
                setError({})
                break;
            case "Description":
                setPositionDetails({ ...positionDetails, description: e.target.value })
                setError({})
                break;
            case "Status":
                setStatus(e.target.value)
                setError({})
                break;

            default:


        }
    };


    const submitHandler = (e) => {

        e.preventDefault()
        //Errors
        if (!positionDetails.position) {
            return setError({ position: 'Position is required' })
        }
        else if (!Object.keys(clientDetails).length) {
            console.log("Check")
            return setError({ client: 'Client Name is required' })
        } else if (!status) {
            return setError({ status: 'Status is required' })
        } else if (!positionDetails.rate) {
            return setError({ rate: 'Pay Rate is required' })
        } else if (!skillsList.length) {
            return setError({ skills: 'Add atleast one skill' })
        }

        else {
            saveRef.current.disabled = true //disable the save button
            //Send to DB
            console.log("Send to Backend")
            let createdBy = {
                id: id, email: companyEmail,
                name: companyName,
                createdDate: new Date().toLocaleDateString(),
                createdTime: new Date().toLocaleTimeString()
            }
            let newData = {
                client: clientDetails,
                position: positionDetails.position,
                description: positionDetails.description,
                skills: skillsList,
                createdBy,
                status,
                rate: positionDetails.rate,
                positionsCount: positionDetails.positionsCount,
                priority: positionDetails.priority
            }

            axios.post(`/api/admin/add-position/${id}/${companyId}`, newData).then(res => {
                console.log(res, "Added ")
                setOpenSnackbar(true)
                setMessage(res.data.msg)
                setTimeout(() => {
                    setAddPosition(false)
                }, 1000)
            }).catch(err => {
                console.log(err, "Error")
            })
            console.log(newData, "Inside Submit")
        }

    }



    console.log(positionDetails, clientDetails, clientName, status, skillsList)

    //MUI autocomplete options
    const clientOptions = allClients?.map(client => {
        return {
            name: client.companyName,
            label: `${client.companyName} - ${client.email}`,
            value: {
                id: client._id,
                email: client.email,
                name: client.companyName
            }
        }
    })

    const statusOption = [
        {
            name: "Active",
            label: "Active",
        },
        {
            name: "Closed",
            label: "Closed",
        }]



    // handle input field change
    const handleChange = (evnt) => {
        const { name, value } = evnt.target;
        switch (name) {
            case 'PocName': setPositionDetails({ ...positionDetails, pocName: value })
                break;
            case 'PocEmail': setPositionDetails({ ...positionDetails, pocEmail: value })
                setError({ email: "" })
                break;
            case 'PocTitle': setPositionDetails({ ...positionDetails, pocTitle: value })
                break;
        }




    };


    return (
        <div>
            <Slide direction={"left"} in={true} timeout={500}>
                <Box sx={{ flexGrow: 1, background: "#faf9f6" }}>
                    <Card
                        id="main-edit"
                        className=" form-card table-card"
                        style={{
                            padding: "15px 20px 18px 20px ",
                            boxShadow: "none",
                        }}
                    >
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            className="add-recruiter-card-container"
                        >
                            <Grid
                                item
                                xs={8}
                                md={5}
                                lg={12}
                            >
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <BusinessIcon sx={{ color: "#2c3e50" }} />
                                    <h5 style={{ color: "#283848" }}>Position Details</h5>
                                </div>
                            </Grid>
                            <Grid container spacing={0} justifyContent="flex-end">


                                <Grid
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}


                                >
                                    <div >
                                        <form noValidate >
                                            <Grid
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                sx={{
                                                    display: "flex"
                                                }}
                                            >


                                                <Grid
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}


                                                >
                                                    <div className="input-container" >


                                                        <div className="split-inputs">
                                                            <div className="first-column">
                                                                <div class="input-field-modal">
                                                                    <label className="date-label">Position Title*</label>
                                                                    <input
                                                                        class="company"
                                                                        type="text"
                                                                        name="Position"
                                                                        className={`${error.position && "input-field-error"}`}
                                                                        value={positionDetails.position}
                                                                        onChange={(evnt) => handleChangeFormFields(evnt)}
                                                                        placeholder="Ex: Data Scientist"
                                                                    />
                                                                    <div style={{ height: "20px" }}><span className='text-danger'>{error.position}</span>
                                                                    </div>
                                                                </div>

                                                                <div class="input-field-modal"   >
                                                                    <label className="date-label" style={{ marginBottom: '6px' }}>Client Name*</label>
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        disableClearable
                                                                        id="combo-box-demo"
                                                                        options={clientOptions}
                                                                        getOptionLabel={(option) => option?.label}
                                                                        sx={{ width: 300 }}
                                                                        inputValue={clientName}
                                                                        onChange={(event, newValue) => {
                                                                            setClientName(newValue?.name);
                                                                            setClientDetails(newValue?.value);
                                                                            setError({});
                                                                        }}
                                                                        renderInput={(params) => {
                                                                            return (
                                                                                <TextField
                                                                                    name="clientName"
                                                                                    className={`${error.client && "input-field-error-client"}`}
                                                                                    style={{ width: "125%", background: '#eeee' }}
                                                                                    onChange={handleChangeFormFields}
                                                                                    {...params}
                                                                                    placeholder="Search Clients"
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                    <div style={{ height: "20px" }}><span className='text-danger'>{error.client}</span>
                                                                    </div>
                                                                </div>



                                                                <div class="input-field-modal">
                                                                    <div className="status-filter-tag">
                                                                        <label className="date-label">Status*</label>
                                                                        <select
                                                                            name="Status"
                                                                            style={{ outline: "none" }}
                                                                            value={status}
                                                                            onChange={handleChangeFormFields}
                                                                            className={`${error.status && "input-field-error"}`}
                                                                        >
                                                                            <option
                                                                                value="Active"
                                                                                selected
                                                                            >
                                                                                Active
                                                                            </option>
                                                                            <option value="Closed">Closed</option>
                                                                        </select>
                                                                    </div>
                                                                    <div style={{ height: "20px" }}><span className='text-danger'>{error.status}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="split-inputs">
                                                                    <div class="input-field-modal">
                                                                        <label className="date-label">Pay Rate*</label>
                                                                        <input
                                                                            class="company"
                                                                            type="number"
                                                                            name="Rate"
                                                                            className={`${error.rate && "input-field-error"}`}
                                                                            value={positionDetails.rate}
                                                                            onChange={(evnt) => handleChangeFormFields(evnt)}
                                                                            placeholder="Pay Rate"
                                                                        />
                                                                        <div style={{ height: "20px" }}>
                                                                            <span className='text-danger'>{error.rate}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="second-column">
                                                                <div class="input-field-modal"   >
                                                                    <form noValidate>
                                                                        <label className="date-label">Skills*</label>
                                                                        <Autocomplete
                                                                            className='requirements-autocomplete'
                                                                            sx={{
                                                                                "& fieldset": { border: "none" }
                                                                            }}
                                                                            value={skillsList}
                                                                            onChange={(event, newValue) => {
                                                                                setSkillsList(newValue);
                                                                                setError({})
                                                                            }}
                                                                            multiple
                                                                            id="tags-filled"
                                                                            options={data.map((option) => option.skill)}
                                                                            freeSolo
                                                                            renderTags={(skillsList, getTagProps) =>
                                                                                skillsList.map((option, index) => (
                                                                                    <Chip
                                                                                        variant="outlined"
                                                                                        label={option}
                                                                                        {...getTagProps({ index })}
                                                                                    />
                                                                                ))
                                                                            }
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    // multiline
                                                                                    // minRows={2}
                                                                                    className={`${error.skills && "input-field-error-skills"}`}
                                                                                    {...params}
                                                                                    variant="filled"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </form>
                                                                    <div style={{ height: "20px" }}><span className='text-danger'>{error.skills}</span></div>
                                                                </div>

                                                                <div class="input-field-modal"   >
                                                                    <label className="date-label" >Description</label>
                                                                    <textarea
                                                                        placeholder="Description"
                                                                        // initialValue={positionDetails.description}
                                                                        rows={2}
                                                                        name="Description"
                                                                        style={{ fontSize: "12px", width: "100%" }}
                                                                        variant="standard"
                                                                        maxLength={120}
                                                                        value={positionDetails.description}
                                                                        onChange={(evnt) => handleChangeFormFields(evnt)}
                                                                    />
                                                                    <div style={{ height: "20px" }}>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>


                                            </Grid>


                                        </form>
                                    </div>
                                </Grid>


                            </Grid>
                            <div className="button-container navigation-btn-container next-btn-container" style={{ justifyContent: "flex-end" }}>

                                <button className="btn-style filled-btn filled-btn-animation"
                                    ref={saveRef}
                                    onClick={submitHandler}
                                >
                                    {" "}
                                    Save
                                </button>


                            </div>
                        </Grid>
                    </Card>
                </Box>
            </Slide>


            <UserSnackbar
                openSnackbar={openSnackbar}
                setOpenSnackbar={setOpenSnackbar}
                message={message}
            />




        </div >
    )
}


export default AddPositions

