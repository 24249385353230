import React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon

const UserSnackbar = ({ openSnackbar, setOpenSnackbar, message }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={3500}
      onClose={handleClose}
      message={message}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Button
            style={{ textTransform: "capitalize", color: "#f06161" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </React.Fragment>
      }
    />
  );
};

export default UserSnackbar;
