import React, { useState } from "react";
import PropTypes from "prop-types";
import "./TextareaCounter.css"; // Optional: for styling

// Set default values using function parameters
const TextareaCounter = ({ maxLength = 200, placeholder, value = "" }) => {
  const [text, setText] = useState(value);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div className="textarea-counter">
      <textarea
        value={text}
        onChange={handleChange}
        maxLength={maxLength}
        placeholder={placeholder}
        rows={6}
      />
      {maxLength && (
        <div className="counter">
          {text.length}/{maxLength}
        </div>
      )}
    </div>
  );
};

TextareaCounter.propTypes = {
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default TextareaCounter;
