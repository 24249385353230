import { NEW_REGISTER, SET_CURRENT_USER } from "../actions/authTypes";

import isEmpty from "is-empty";

const initialState = {
    isAuthenticated: false,
    user: {},
    newRegister: false,
};

export const authReducer=  (state = initialState, action)=> {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            };
        case NEW_REGISTER:
            console.log("Someone just registered");
            return {
                ...state,
                newRegister: action.payload,
            };
        default:
            return state;
    }
}
