import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";

// Declare stopRecordingRef globally
// export const stopRecordingRef = { current: null };

const EnvironmentSetup = ({ setTabValue, testName, duration, cameraEnabled }) => {
    const { testId, invitationToken } = useParams();
    const navigate = useNavigate();

    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const streamRef = useRef(null);
    const [recording, setRecording] = useState(false);

    // const startRecording = async () => {
    //     try {
    //         const stream = await navigator.mediaDevices.getUserMedia({
    //             video: true,
    //             audio: true, // Enable audio
    //         });

    //         streamRef.current = stream;
    //         if (videoRef.current) {
    //             videoRef.current.srcObject = stream;
    //             videoRef.current.muted = true; // Mute the video to avoid feedback
    //         }

    //         let chunks = [];
    //         mediaRecorderRef.current = new MediaRecorder(stream, {
    //             mimeType: "video/webm",
    //         });

    //         mediaRecorderRef.current.ondataavailable = (event) => {
    //             chunks.push(event.data);
    //         };

    //         mediaRecorderRef.current.onstop = async () => {
    //             const blob = new Blob(chunks, { type: "video/webm" });
    //             await uploadToBackend(blob);
    //         };

    //         mediaRecorderRef.current.start();
    //         setRecording(true);
    //         console.log("Recording started...");

    //     } catch (error) {
    //         console.error("Error accessing camera:", error);
    //     }
    // };

    // const stopRecording = () => {
    //     if (mediaRecorderRef.current) {
    //         mediaRecorderRef.current.stop();
    //         setRecording(false);
    //         console.log("Recording stopped...");
    //     }

    //     if (streamRef.current) {
    //         streamRef.current.getTracks().forEach((track) => track.stop());
    //         streamRef.current = null;
    //     }

    //     if (videoRef.current) {
    //         videoRef.current.srcObject = null;
    //     }
    // };

    // stopRecordingRef.current = stopRecording;

    // const uploadToBackend = async (blob) => {
    //     try {
    //         const formData = new FormData();
    //         formData.append("video", blob, "recorded_video.webm");
    //         formData.append("testId", testId); // Add testId
    //         formData.append("invitationToken", invitationToken); // Add invitationToken

    //         const { data } = await axios.post("/api/video/upload-to-drive", formData, {
    //             headers: { "Content-Type": "multipart/form-data" },
    //         });

    //         console.log("Upload success:", data);
    //     } catch (error) {
    //         console.error("Error uploading video:", error);
    //     }
    // };

    const handleClickNext = () => {
        // if(cameraEnabled) startRecording();
        navigate(`/assesment-question/${testId}/${invitationToken}`, {
            state: { testName, duration, cameraEnabled }, replace: true
        });
    };

    return (
        <>
            <div className="proctor-screen">
                <div className="heading">
                    <h5>Environment Setup</h5>
                    <hr style={{ marginTop: "25px" }} />
                </div>
                <div className="environment-setup">
                    <div>
                        <p><CheckCircleIcon style={{ color: "green" }} /> Your environment is ready</p>
                        <p><CheckCircleIcon style={{ color: "green" }} /> Provisioning servers for the test</p>
                    </div>
                </div>
            </div>
            <Grid container justifyContent="center">
                <div style={{ display: "flex", width: "300px" }}>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            className="proctor-previous-button"
                            onClick={() => setTabValue(2)}
                        >
                            <ArrowLeftIcon style={{ position: "relative", right: "3px" }} />
                            Previous
                        </Button>
                    </Grid>
                    <Grid item xs={8} style={{ position: "relative", left: "20px" }}>
                        <Button
                            variant="contained"
                            className="proctor-next-button proceed-to-assessment-button"
                            onClick={handleClickNext}
                        >
                            PROCEED TO ASSESSMENT
                        </Button>
                    </Grid>
                </div>
            </Grid>
        </>
    );
};

export default EnvironmentSetup;
