import React, { useState } from "react";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-input-2";
import { getPhonesRegex } from "../auth/PhoneValidation";
import "react-phone-input-2/lib/style.css";

const EntryModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <img width="300" height="200"  src={Logo} alt='email-img'style={{margin:'0 auto'}}/> */}
      {props.icon}

      <Modal.Body>
        <h4 style={{ textAlign: "center" }}>{props.heading}</h4>
        <p style={{ fontSize: "16px", textAlign: "center" }}>
          {props.line1}
          <br />
          {props.line2}
        </p>
        <Button
          onClick={props.onHide}
          style={{ borderRadius: "20px", width: "20%", margin: "0 185px" }}
          className="btn-login"
        >
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};
const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [phone, setPhone] = useState("");
  // const [icon, setIcon] = useState(
  //   <CheckCircleOutlineOutlinedIcon
  //     style={{
  //       margin: "0 auto",
  //       color: "#f06161",
  //       width: "100px",
  //       height: "100px",
  //     }}
  //   />
  // );
  const [heading, setHeading] = useState("");
  const [errors, setErrors] = useState({ phone: "" });
  const [phoneIsValid, setPhoneIsValid] = useState(true);

  // store the regex of phone number patterns of all countries
  const phones = getPhonesRegex();

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("clicked submit button");

    if (!phoneIsValid && phone) {
      console.log("invalid phone");

      return setErrors({ phone: "Invalid phone number" });
    }
    console.log("valid phone");

    axios
      .post("/api/contact/query", {
        email,
        name,
        info,
        phone,
      })
      .then((data) => {
        console.log("Entered then block");

        setHeading("Thank You!");
        setLine1(`Your query has been received.`);
        setLine2("We will contact you soon.");
        setModalShow(true);
        setPhone("");
        setEmail("");
        setName("");
        setInfo("");
      })
      .catch((err) => {
        console.log("Entered catch block");
        console.log("error", err);

        // if (err.response.status === 500) {
        //   setIcon(
        //     <CancelOutlinedIcon
        //       style={{
        //         margin: "0 auto",
        //         color: "#f06161",
        //         width: "100px",
        //         height: "100px",
        //       }}
        //     />
        //   );
        //   setHeading("Oops!");
        //   setLine1(`Your query could not be sent at this moment.`);
        //   setLine2("Kindly try again later.");

        //   setModalShow(true);
        // }
      });

    // const response = await axios.post("http://localhost:5000/api",{ email, name, info, phone });
    // setArray(response.data.fruits);
    // console.log(response.data.fruits);
  };

  const onHide = (e) => {
    e.preventDefault();
    setModalShow(false);
  };

  return (
    <section id="contact" className="section pb-0">
      <div className="container">
        <h6 className="xs-font mb-0">Get in touch</h6>
        <h3 className="section-title mb-5">Contact Us</h3>

        <div className="row align-items-center justify-content-between">
          <div className="col-md-8 col-lg-7">
            <form className="contact-form" onSubmit={onSubmit}>
              <div className="form-row">
                <div className="col form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    required
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrors({ phone: "" });
                    }}
                  />
                </div>
                <div className="col form-group">
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors({ phone: "" });
                    }}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    value={email}
                  />
                </div>
                <div className=" input-field-modal form-group footer-tel">
                  <PhoneInput
                    country={"us"}
                    className="phone"
                    type="number"
                    autoFormat={true}
                    value={phone}
                    placeholder="Phone"
                    required
                    onChange={(phone, country) => {
                      setPhone(phone);
                      setErrors({ phone: "" });
                      var regex = phones[country.countryCode.toUpperCase()];
                      if (regex !== undefined) {
                        if (!regex.test(phone)) {
                          setPhoneIsValid(false);
                          return setErrors({ phone: "Invalid Phone Number" });
                        } else {
                          setPhoneIsValid(true);
                          return setErrors({ phone: "" });
                        }
                      }
                    }}
                  />
                  <div style={{ paddingTop: "7px" }}>
                    <span className="text-danger">{errors.phone}</span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  onChange={(e) => {
                    setInfo(e.target.value);
                    setErrors({ phone: "" });
                  }}
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  className="form-control"
                  placeholder="Your Message"
                  required
                  value={info}
                ></textarea>
              </div>
              <div className="form-group">
                <input
                  style={{ background: "#f06161", color: "white" }}
                  type="submit"
                  className="btn btn-block btn-contact"
                  value="Send Message"
                />
              </div>
            </form>
          </div>
          <div className="col-md-4 d-none d-md-block order-1">
            <ul className="list">
              <li className="list-head">
                <h6>CONTACT INFO</h6>
              </li>
              <li className="list-body">
                <p className="py-2">
                  Contact us and we'll get back to you within 24 hours.
                </p>
                <p className="py-2">
                  <i className="fa-solid fa-location-dot"></i> 23465 Rock Haven
                  Way, Suite 100 Dulles, VA 20166
                </p>
                <p className="py-2">
                  <i className="fa-solid fa-envelope"></i>{" "}
                  info@infomaticscorp.com
                </p>
                <p className="py-2">
                  <i className="fa-solid fa-phone"></i> +1 (855) 324-4636
                </p>
              </li>
            </ul>
          </div>
        </div>

        <footer className="footer mt-5 border-top">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6 text-center text-md-left">
              <p className="mb-0">
                Copyright {new Date().getFullYear()}
                {/* <script>
                document.write(new Date().getFullYear());
              </script> */}
                &copy;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.infomaticscorp.com"
                >
                  Infomatics Corporation
                </a>
              </p>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <div className="social-links">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://infomaticscorp.com"
                  className="link"
                >
                  <i className="fa-solid fa-globe"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/infomatics-corp/"
                  className="link"
                >
                  <i className="fab fa-linkedin-in"> </i>
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <EntryModal
        show={modalShow}
        // icon={icon}
        icon={
          <CheckCircleOutlineOutlinedIcon
            style={{
              margin: "0 auto",
              color: "#f06161",
              width: "100px",
              height: "100px",
            }}
          />
        }
        heading={heading}
        line1={line1}
        line2={line2}
        onHide={onHide}
      />
    </section>
  );
};

export default Footer;
